import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css";
import "./index.css";
import "./assets/css/common.css";
import "./assets/css/pagination.css";
import "./assets/scss/daiwari_edit.scss";
import "notyf/notyf.min.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { axiosPlugin } from "./plugins/axios";
import SlideUpDown from "vue-slide-up-down";
import { VueToggles } from "vue-toggles";
import { GlobalEvents } from "vue-global-events";
import VueLazyLoad from "vue3-lazyload";

// Setup plugin for defaults or `$screens` (optional)
library.add(fas, far);

const app = createApp(App);

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("VueToggles", VueToggles)
  .component("GlobalEvents", GlobalEvents);
app.component("slide-up-down", SlideUpDown);
// Use the components
app
  .use(store)
  .use(router)
  .use(axiosPlugin)
  .use(VueLazyLoad, {
    loading:
      "https://juchu-connect-img.s3.ap-northeast-1.amazonaws.com/common/loading.png",
    error:
      "https://juchu-connect-img.s3.ap-northeast-1.amazonaws.com/common/noimage.png",
    delay: 500,
  })
  .mount("#app");

// 2/15 daiwari_edit.cssの適用箇所を特定のファイルのみにしたい
