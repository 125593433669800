<template>
  <div id="PageBikouTale" v-if="formatOriArrray.length">
    <p class="title">【折省略情報】</p>
    <table class="daiwari-table" :class="sheet">
      <tr>
        <th class="daiwari-table_th daiwari-table_th_page_ori">ページ</th>
        <th class="daiwari-table_th daiwari-table_th_kbn">折区分</th>
        <th class="daiwari-table_th daiwari-table_th_bikou_ori">折備考内容</th>
      </tr>
      <template v-for="(ori, oriIndex) in formatOriArrray" :key="oriIndex">
        <tr>
          <td
            :class="oriIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_page"
          >
            <span v-if="!ori.oriStartPage && !ori.oriEndPage">未設定</span>
            <span v-if="ori.oriStartPage || ori.oriEndPage">
              {{ ori.oriStartPage }}~
              {{ ori.oriEndPage }}
            </span>
          </td>
          <td
            :class="oriIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_tantou"
          >
            {{ ori.ori_kbn }}
          </td>
          <td
            :class="oriIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_bikou_ori"
          >
            {{ ori.ori_bikou }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  name: "DetailOriTable",
  props: {
    props_daiwariData: Object,
    printSize: String,
    detailOriArray: Array,
  },
  data() {
    return {
      ori_array: [],
      // formatOriArrray: [],
      formatSakuhinArray: [],
      limit_ori1: 0,
      character_ori1: 0,
    };
  },
  computed: {
    formatOriArrray() {
      let formatOriArrray = [];
      this.detailOriArray.forEach((ori) => {
        //折の対象ページ範囲を取得(開始ページ,終了ページ)
        const targetOri = this.props_daiwariData.ori_array.find(
          (propsOri) => propsOri.ori_id === ori.ori_id
        );
        let startPage =
          targetOri.sakuhin_array[0].sakuhin_address_computed_array_withFlg[0];
        const oriLastSakuhin =
          targetOri.sakuhin_array[targetOri.sakuhin_array.length - 1];
        const endPage =
          oriLastSakuhin.sakuhin_address_computed_array_withFlg[
            oriLastSakuhin.sakuhin_address_computed_array_withFlg.length - 1
          ];

        //折省略テーブル用にデータ整形
        formatOriArrray.push({
          ori_kbn: ori.ori_kbn,
          ori_bikou: ori.ori_bikou,
          oriStartPage: startPage,
          oriEndPage: endPage,
          ori_id: ori.ori_id,
        });
      });
      //重複した折を削除する
      const result = formatOriArrray.filter(
        (formatOri, index, formatOriArray) =>
          formatOriArray.findIndex((e) => e.ori_id === formatOri.ori_id) ===
          index
      );
      return result;
    },
    sheet() {
      return "sheet_" + this.printSize;
    },
    characterLimit_sakuhinMei() {
      if (this.props_daiwariData.oriMaximumPage === 16) {
        return 14;
      } else {
        return 6;
      }
    },
    characterLimit() {
      if (this.props_daiwariData.oriMaximumPage === 16) {
        return 7;
      } else {
        return 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #PageBikouTale {
    width: 100%;
  }
  .title {
    padding-top: 20px;
  }
  .daiwari-table {
    border-collapse: separate;
    border-right: 1px solid;
    table-layout: fixed;
    width: 100%;
    background: #fff;

    &_td {
      height: 10px;
      border: 1px solid;
      border-top: none;
      border-right: none;
      text-align: center;
      vertical-align: middle;
      &_bikou_ori {
        width: 75%;
      }
      &_page {
        width: 10%;
        min-width: 60px;
      }
    }
    &_th {
      background-color: #ddd;
      padding: 0px 5px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid;
      border-right: none;
      &_bikou {
        &_ori {
          width: 75%;
        }
      }
      &_page {
        min-width: 60px;
        &_ori {
          width: 10%;
          min-width: 60px;
        }
      }
      &_kbn {
        width: 15%;
      }
    }
  }
  .bg-gray {
    background: #efeeee;
  }
  .tantou {
    display: block;
  }

  //印刷用CSS-------------------------------
  @media print {
    //A4の場合...
    .sheet_A4 {
      // width: 792px;
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }
  }
}
</style>
