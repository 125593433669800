<template>
  <button class="BtnGreen_BtnGreen" @click="clickBtn">
    <slot />
  </button>
</template>
<script>
export default {
  name: "BtnWhite",
  methods: {
    clickBtn() {
      this.$emit("clickBtn");
    },
  },
};
</script>

<style scoped lang="scss">
.BtnGreen {
  &_BtnGreen {
    width: 109px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #20c997;
    box-sizing: border-box;
    border-radius: 5px;
    color: #20c997;

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    }
    &:active {
      box-shadow: 0 0;
    }
  }
}
</style>
