import axios from "axios";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import store from "..";
import type { RootState } from "..";

type ConstantDataState = {
  vanStatus: {
    value: number;
    statusName: string;
  }[];
  toritugiList: {
    toritugiCd: string;
    torihikisakiCd: string;
    toritugiMei: string;
  }[];
  syubetuList: {
    hanmotoCd?: number;
    syubetuKbn: number | string;
    syubetu: string;
  }[];
  categoryList: {
    categoryCd: number;
    category: string;
  }[];
  eventList: {
    event: string;
  }[];
  isSales: boolean;
  salesList: {
    salesCd: number;
    salesName: string;
  }[];
  torihikiList: {
    torihikiCd: number;
    torihikiMei: string;
  }[];
  inputUserList: {
    userId: number;
    username: string;
    hanmotoCd: string | null;
  }[];
  zaikoStatusList: {
    zsId: number;
    zsName: string;
  }[];
  eventHenpinDenpyouList: {
    eventMei: string;
  }[];
  eventNouhinDenpyouList: {
    eventMei: string;
  }[];
};

// ストアを永続化しているため、初期化のために以下の形で定義
type GetDefaultState = () => ConstantDataState;
const getDefaultState: GetDefaultState = () => {
  return {
    vanStatus: [
      {
        value: 11,
        statusName: "11:在庫あり",
      },
      {
        value: 12,
        statusName: "12:在庫調整中",
      },
      {
        value: 21,
        statusName: "21:在庫僅少",
      },
      {
        value: 22,
        statusName: "22:重版中",
      },
      {
        value: 32,
        statusName: "32:品切れ",
      },
      {
        value: 33,
        statusName: "33:品切れ・重版未定",
      },
      {
        value: 34,
        statusName: "34:絶版",
      },
      {
        value: 35,
        statusName: "35:専売品",
      },
      {
        value: 36,
        statusName: "36:通販品",
      },
    ],
    toritugiList: [],
    syubetuList: [],
    categoryList: [],
    eventList: [],
    isSales: false,
    salesList: [],
    torihikiList: [],
    inputUserList: [],
    zaikoStatusList: [],
    eventHenpinDenpyouList: [],
    eventNouhinDenpyouList: [],
  };
};
export const state: ConstantDataState = getDefaultState();
const getters: GetterTree<ConstantDataState, RootState> = {
  getVanStatus: (state) => state.vanStatus,
  getTargetVanStatus: (state) => (value: number | string) => {
    const returnVal = state.vanStatus.find((status) => {
      return status.value === value;
    });
    return returnVal?.statusName;
  },
  getToritugiList: (state) => state.toritugiList,
  getSyubetuList: (state) => state.syubetuList,
  getCategoryList: (state) => state.categoryList,
  getEventList: (state) => state.eventList,
  getIsSales: (state) => state.isSales,
  getSalesList: (state) => state.salesList,
  // 注文扱いのみの取引リスト取得
  getTorihikiList: (state) => {
    const returnList = state.torihikiList.map((obj) => Object.assign({}, obj));
    returnList.splice(1, returnList.length - 1);
    return returnList;
  },
  // 全取引リスト取得
  getAllTorihikiList: (state) => {
    const returnList: {
      torihikiCd: number | string;
      torihikiMei: string;
    }[] = state.torihikiList.map((obj) => Object.assign({}, obj));
    //未選択時用の空データを追加
    returnList.unshift({
      torihikiCd: "",
      torihikiMei: "",
    });
    return returnList;
  },
  getInputUserList: (state) => state.inputUserList,
  getZaikoStatusList: (state) => state.zaikoStatusList,
  getEventHenpinDenpyouList: (state) => state.eventHenpinDenpyouList,
  getEventNouhinDenpyouList: (state) => state.eventNouhinDenpyouList,
};

const mutations: MutationTree<ConstantDataState> = {
  // stateを初期化するmutationを定義
  clearAuthData(state) {
    Object.assign(state, getDefaultState());
  },
  setToritugiList(state, payload) {
    state.toritugiList = [...payload];
  },
  setSyubetuList(state, payload) {
    //未選択時用の空データを追加
    const syubetuUnselect = {
      hanmotoCd: null,
      syubetu: "",
      syubetuKbn: "",
    };
    state.syubetuList = [{ ...syubetuUnselect }, ...payload];
  },
  setCategoryList(state, payload) {
    //未選択時用の空データを追加
    const categoryUnselect = {
      categoryCd: "",
      category: "",
    };
    state.categoryList = [{ ...categoryUnselect }, ...payload];
  },
  setEventList(state, payload) {
    state.eventList = [...payload];
  },
  setSalesList(state, payload) {
    // //未選択時用の空データを追加
    // const salesUnselect = {
    //   hanmotoCd: null,
    //   salesCd: "",
    //   salesName: "",
    // };
    state.salesList = [...payload.salesList];
    state.isSales = payload.isSales;
  },
  setTorihikiList(state, payload) {
    state.torihikiList = [...payload];
  },
  setInputUserList(state, payload) {
    state.inputUserList = [...payload];
  },
  setZaikoStatusList(state, payload) {
    state.zaikoStatusList = [...payload];
  },
  setEventHenpinDenpyouList(state, payload) {
    state.eventHenpinDenpyouList = [...payload];
  },
  setEventNouhinDenpyouList(state, payload) {
    state.eventNouhinDenpyouList = [...payload];
  },
};

const actions: ActionTree<ConstantDataState, RootState> = {
  async fetchToritugiList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/toritugi	`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setToritugiList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSyubetuList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/syubetu`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyubetuList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCategoryList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setCategoryList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchEventList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syukko/event`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setEventList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSalesList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/sales`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSalesList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchTorihikiList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/torihiki`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setTorihikiList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchInputUserList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/user`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setInputUserList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchZaikoStatusList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/zaikoStatus`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setZaikoStatusList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchEventHenpinDenpyouList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/eventHenpinDenpyou`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setEventHenpinDenpyouList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchEventNouhinDenpyouList({ commit }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/searchItemList/eventNouhinDenpyou`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setEventNouhinDenpyouList", res.data);
    } catch (error) {
      console.log(error);
    }
  },
};

export const ConstantDataModule: Module<ConstantDataState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
