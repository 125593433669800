import axios from "axios";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import store from "..";
import type { RootState } from "..";

type LoginState = {
  loginUser: {
    hanmotoCd: string;
    role: string;
    userId: number;
    username: string;
    email: string;
  };
  isValidToken: boolean;
};
// ストアを永続化しているため、初期化のために以下の形で定義
type GetDefaultState = () => LoginState;
const getDefaultState: GetDefaultState = () => {
  return {
    loginUser: {
      hanmotoCd: "",
      role: "",
      userId: -1,
      username: "",
      email: "",
    },
    isValidToken: false,
  };
};
export const state: LoginState = getDefaultState();

const getters: GetterTree<LoginState, RootState> = {
  getUserData: (state) => state.loginUser,
  getLoginSyotenName: (state) => state.loginUser.username,
  getLoginSyotenRole: (state) => state.loginUser.role,
  getIsValidToken: (state) => state.isValidToken,
};

const mutations: MutationTree<LoginState> = {
  // stateを初期化するmutationを定義
  clearAuthData(state) {
    Object.assign(state, getDefaultState());
  },
  setLoginUser(
    state,
    payload: {
      accessToken: string;
      hanmotoCd: string;
      role: string;
      userId: number;
      username: string;
      email: string;
    }
  ) {
    state.loginUser.hanmotoCd = payload.hanmotoCd;
    state.loginUser.role = payload.role;
    state.loginUser.userId = payload.userId;
    state.loginUser.username = payload.username;
    state.loginUser.email = payload.email;
    localStorage.setItem("accessToken", payload.accessToken);
  },
  setTokenData(state, payload) {
    if (payload.accessToken) {
      state.isValidToken = true;
    } else {
      state.isValidToken = false;
    }
  },
};

const actions: ActionTree<LoginState, RootState> = {
  async login({ commit }, payload: { email: string; password: string }) {
    try {
      const res = await axios.post(
        `${store.state.baseUrl}/auth/login`,
        payload
      );
      const resData = res.data;
      if (resData.errorMessage) {
        return resData.errorMessage;
      } else {
        const userData = {
          ...resData,
          email: payload.email,
        };
        commit("setLoginUser", userData);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async logout({ commit }) {
    //ストレージ上のトークンとストア上のユーザ情報削除
    localStorage.removeItem("accessToken");
    commit("clearAuthData");
    store.commit("constant/clearAuthData");
  },
  async validateToken({ commit }) {
    try {
      if (localStorage.getItem("accessToken") === null) {
        commit("setTokenData", "");
      } else {
        const res = await axios.post(
          `${store.getters["getBaseUrl"]}/auth/tokenCheck`,
          "",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const resData = res.data;
        commit("setTokenData", resData);
      }
    } catch (e) {
      console.log(e);
    }
  },
};

export const LoginModule: Module<LoginState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
