<template>
  <div
    id="PageBikouTale"
    v-if="detailSakuhinArray.length"
    :class="isCompact ? 'print_display_none' : ''"
  >
    <p class="title">【作品省略情報】</p>
    <table class="daiwari-table" :class="sheet">
      <tr>
        <th class="daiwari-table_th daiwari-table_th_page">ページ</th>
        <th class="daiwari-table_th daiwari-table_th_sakuhin_mei">作品名</th>
        <th class="daiwari-table_th daiwari-table_th_sakuhin_tantou">担当者</th>
        <th class="daiwari-table_th daiwari-table_th_sakuhin_penName">
          ペンネーム
        </th>
        <th class="daiwari-table_th daiwari-table_th_bikou">作品備考内容</th>
      </tr>
      <template
        v-for="(sakuhin, sakuhinIndex) in detailSakuhinArray"
        :key="sakuhinIndex"
      >
        <tr>
          <td
            :class="sakuhinIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_page"
          >
            <span
              v-if="
                sakuhin.startPage === '未設定' && sakuhin.endPage === '未設定'
              "
              >未設定</span
            >
            <span
              v-if="
                sakuhin.startPage !== '未設定' || sakuhin.endPage !== '未設定'
              "
            >
              {{ sakuhin.startPage
              }}<span v-show="sakuhin.startPage !== sakuhin.endPage"
                >~{{ sakuhin.endPage }}</span
              >
            </span>
          </td>
          <td
            :class="sakuhinIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_sakuhin_mei"
          >
            {{ sakuhinDisplayName(sakuhin) }}
          </td>
          <td
            :class="sakuhinIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_tantou"
          >
            <template v-for="(tantou, index) in sakuhin.tantou" :key="index">
              <span class="tantou"
                >{{ tantou.user_last_name }}{{ tantou.user_first_name }}</span
              >
            </template>
          </td>
          <td
            :class="sakuhinIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_penName"
          >
            <p
              v-for="(penName, index) in sakuhin.penName"
              :key="'penName_' + index"
            >
              {{ penName.penName }}
            </p>
          </td>
          <td
            :class="sakuhinIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_bikou"
          >
            {{ sakuhin.sakuhin_bikou }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { is_matched_sakuhinId } from "@/daiwariJS/util";
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";

export default {
  name: "DetailSakuhinTable",
  props: {
    props_daiwariData: Object,
    printSize: String,
    props_detailSakuhinArray: Array,
    isCompact: Boolean,
  },
  data() {
    return {
      detailSakuhinArray: [],
    };
  },
  created() {
    //作品省略テーブルをページ順にソートする
    this.props_daiwariData.ori_array.forEach((ori) => {
      ori.sakuhin_array.forEach((sakuhin) => {
        const target_detailSakuhin = this.props_detailSakuhinArray.find(
          (detailSakuhin) => is_matched_sakuhinId(detailSakuhin, sakuhin)
        );
        if (target_detailSakuhin) {
          //重複している場合は排除
          const isDuplicated = this.detailSakuhinArray.some((detailSakuhin) =>
            is_matched_sakuhinId(detailSakuhin, target_detailSakuhin)
          );
          if (!isDuplicated) {
            this.detailSakuhinArray.push(target_detailSakuhin);
          }
        }
      });
    });
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
    sakuhinDisplayName() {
      return (sakuhin) => {
        return convertSakuhinMeiFull(sakuhin);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #PageBikouTale {
    width: 100%;
  }
  .title {
    padding-top: 20px;
  }
  .daiwari-table {
    border-collapse: separate;
    border-right: 1px solid;
    table-layout: fixed;
    width: 100%;
    background: #fff;

    &_td {
      height: 10px;
      border: 1px solid;
      border-top: none;
      border-right: none;
      text-align: center;
      vertical-align: middle;
      &_bikou {
        width: 60%;
      }
      &_sakuhin_mei {
        width: 30%;
      }
      &_page {
        width: 5%;
        min-width: 60px;
      }
    }
    &_th {
      background-color: #ddd;
      padding: 0px 5px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid;
      border-right: none;
      &_bikou {
        width: 40%;
      }
      &_sakuhin_mei {
        width: 30%;
      }
      &_penName {
        width: 15%;
      }
      &_tantou {
        width: 10%;
      }
      &_page {
        width: 10%;
        min-width: 60px;
      }
    }
  }
  .bg-gray {
    background: #efeeee;
  }
  .tantou {
    display: block;
  }

  //印刷用CSS-------------------------------
  @media print {
    //A4の場合...
    .sheet_A4 {
      // width: 792px;
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }
    .print_display_none {
      display: none;
    }
  }
}
</style>
