import { reactive } from "vue";
import * as xlsx from "xlsx";

export default () => {
  /**
   * メンバ変数的なリアクティブデータです
   */
  const csv = reactive({
    csvData: {},
    fileName: "",
  });
  /**
   * csvデータをセットします
   *
   */
  const setCsv = (csvData, fileName) => {
    csv.csvData = csvData;
    csv.fileName = fileName;
  };
  /**
   * csvデータをゲットします
   */
  const getTimeStamp = () => {
    const dt = new Date();
    const Y = dt.getFullYear();
    const M = ("00" + (dt.getMonth() + 1)).slice(-2);
    const D = ("00" + dt.getDate()).slice(-2);
    return Y + M + D;
  };
  const createCsvFile = () => {
    console.log("csvData:", csv);
    const csvData = csv.csvData;
    let bom = new Uint8Array([0xef, 0xbb, 0xbf]); //文字化け対策
    let blob = new Blob([bom, csvData], { type: "text/csv" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${csv.fileName}_${getTimeStamp()}.csv`;
    link.click();
  };

  /*TODO:アップロードしたExcelファイルを読み込んでJSONに変換する */
  const createJsonData = (FILE) => {
    console.log(FILE);
    const READER = new FileReader();

    READER.onload = function (e) {
      // ブラウザからのファイルリード結果を変数に保存
      let DATA = e.target.result;
      let arr = fixdata(DATA);
      let wb;
      wb = xlsx.read(btoa(arr), {
        type: "base64",
        cellDates: true,
      });

      console.log("Wb:", wb);
      let output = "";
      output = file_to_json(wb);
      return output;
    };

    // FileオブジェクトをArrayBufferとしてメモリ上に読み込む。（読み込んだらonload部分を実行する）
    READER.readAsArrayBuffer(FILE);
  };
  // ファイルの読み込み
  const fixdata = (DATA) => {
    let o = "",
      l = 0,
      w = 10240;
    for (; l < DATA.byteLength / w; ++l)
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(DATA.slice(l * w, l * w + w))
      );
    o += String.fromCharCode.apply(null, new Uint8Array(DATA.slice(l * w)));
    return o;
  };

  // EXCELデータをjsonに変換
  const file_to_json = (workbook) => {
    var result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      var roa = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName], {
        raw: true,
      });
      if (roa.length > 0) {
        result[sheetName] = roa;
      }
    });
    return result;
  };

  return {
    setCsv,
    createCsvFile,
    createJsonData,
    getTimeStamp,
  };
};
