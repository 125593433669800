<template>
  <div id="bikou_overlay">
    <!-- 新規登録 ------------------------------------------------->
    <p class="bikouArea_title">ページ範囲設定</p>
    <p class="bikouArea_subtitle">新規入力</p>
    <div class="bikouArea_container" style="max-height: 39%">
      <div class="bikouArea_content">
        <div class="bikouArea_top">
          <div class="bikouArea_wrapper">
            <!-- ページ範囲 -->
            <p class="bikouArea_label">ページ範囲</p>
            <input
              @change="
                inputFirstPage(
                  $event,
                  'registerErrorInfo',
                  'registerPageRangeInfo'
                )
              "
              :value="registerPageRangeInfo.firstPage"
              :disabled="isLockDaiwari"
              :class="{
                isActive_error:
                  registerErrorInfo.hasNegativeFirstPage ||
                  registerErrorInfo.hasStringFirstPage ||
                  registerErrorInfo.largerThanLastPage,
              }"
            />
            p ~
            <input
              @change="
                inputLastPage(
                  $event,
                  'registerErrorInfo',
                  'registerPageRangeInfo'
                )
              "
              :value="registerPageRangeInfo.lastPage"
              :disabled="isLockDaiwari"
              :class="{
                isActive_error:
                  registerErrorInfo.hasNegativeLastPage ||
                  registerErrorInfo.hasStringLastPage ||
                  registerErrorInfo.smallerThanFirstPage,
              }"
            />
            p
          </div>
          <div class="bikouArea_wrapper">
            <!-- 色付け -->
            <p class="bikouArea_label">色付け</p>
            <select
              :disabled="isLockDaiwari"
              :value="registerPageRangeInfo.color"
              @change="
                selectColor(
                  $event,
                  'registerErrorInfo',
                  'registerPageRangeInfo'
                )
              "
              :style="{ background: registerPageRangeInfo.color }"
            >
              <option value="pink" style="background-color: #ffd5ec"></option>
              <option value="yellow" style="background-color: #ffffaa"></option>
              <option value="green" style="background-color: #e6ffe9"></option>
              <option value="orange" style="background-color: #ffdbc9"></option>
              <option value="purple" style="background-color: #e6e6fa"></option>
            </select>
          </div>
        </div>
        <div class="bikouArea_wrapper">
          <!-- エラーと記入例 -->
          <ul>
            <li
              class="text_error"
              v-show="
                registerErrorInfo.hasNegativeFirstPage ||
                registerErrorInfo.hasNegativeLastPage
              "
            >
              ※0以下の値は設定できません
            </li>
            <li
              class="text_error"
              v-show="
                registerErrorInfo.hasStringFirstPage ||
                registerErrorInfo.hasStringLastPage
              "
            >
              ※半角数値以外は設定出来ません
            </li>
            <li
              class="text_error"
              v-show="registerErrorInfo.largerThanLastPage"
            >
              ※終了ページより大きい値は設定できません
            </li>
            <li
              class="text_error"
              v-show="registerErrorInfo.smallerThanFirstPage"
            >
              ※開始ページより小さい値は設定できません
            </li>
            <li
              class="text_error"
              v-show="registerErrorInfo.largerThanDaiwariTotalPage"
            >
              ※存在しないページ数は設定できません
            </li>
          </ul>
          <p class="text_small">
            カウント対象外ページを設定する場合は<br />「＊＋ページ番号」をご記入ください。例)表1
            → 1
          </p>
        </div>
        <div class="bikouArea_bottom">
          <div class="bikouArea_wrapper">
            <!-- ページ範囲情報 -->
            <p class="bikouArea_label">ページ範囲情報</p>
            <textarea
              :disabled="
                isLockDaiwari || registerPageRangeInfo.color === 'white'
              "
              :class="isLockDaiwari ? 'disable_button--side' : ''"
              :value="registerPageRangeInfo.bikou"
              @input="inputBikou"
            ></textarea>
            <span class="btn_wrapper"
              ><button @click="registerBikou">登録</button></span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 編集 ------------------------------------------------------------->
    <p class="bikouArea_subtitle">編集(※編集ボタン押下で編集可能)</p>
    <p v-show="!editable_page_range_bikou_array.length">
      ページ範囲情報は登録されていません
    </p>
    <div
      class="bikouArea_container"
      style="max-height: 50%"
      v-show="editable_page_range_bikou_array.length"
    >
      <div
        v-for="(bikouObj, index) in editable_page_range_bikou_array"
        :key="index"
        class="bikouArea_content"
        :class="
          isSelectBikouArea(index) ? 'active_bikouArea' : 'inActive_bikouArea'
        "
      >
        <div class="bikouArea_top">
          <div class="bikouArea_wrapper">
            <!-- ページ範囲 -->
            <!-- <input
              @change="editFirstPage($event,index)"
              :value="bikouObj.firstPage"
              :disabled="isLockDaiwari||!isSelectBikouArea(index)"
              :class="{
                isActive_error:
                  editErrorInfo.hasNegativeFirstPage ||
                  editErrorInfo.hasStringFirstPage ||
                  editErrorInfo.largerThanLastPage,
              }"
            /> -->
            <input
              @change="
                inputFirstPage($event, 'editErrorInfo', 'editPageRangeInfo')
              "
              :value="
                isSelectBikouArea(index)
                  ? editPageRangeInfo.firstPage
                  : bikouObj.firstPage
              "
              :disabled="isLockDaiwari || !isSelectBikouArea(index)"
              :class="{
                isActive_error:
                  (editErrorInfo.hasNegativeFirstPage ||
                    editErrorInfo.hasStringFirstPage ||
                    editErrorInfo.largerThanLastPage) &&
                  isSelectBikouArea(index),
              }"
            />
            p ~
            <input
              :value="
                isSelectBikouArea(index)
                  ? editPageRangeInfo.lastPage
                  : bikouObj.lastPage
              "
              @change="
                inputLastPage($event, 'editErrorInfo', 'editPageRangeInfo')
              "
              :disabled="isLockDaiwari || !isSelectBikouArea(index)"
              :class="{
                isActive_error:
                  (editErrorInfo.hasNegativeLastPage ||
                    editErrorInfo.hasStringLastPage ||
                    editErrorInfo.smallerThanFirstPage) &&
                  isSelectBikouArea(index),
              }"
            />
            p
          </div>
          <div class="bikouArea_wrapper">
            <!-- 色付け -->
            <select
              :disabled="isLockDaiwari || !isSelectBikouArea(index)"
              :value="
                isSelectBikouArea(index)
                  ? editPageRangeInfo.color
                  : bikouObj.color
              "
              @change="
                selectColor($event, 'editErrorInfo', 'editPageRangeInfo')
              "
              :class="
                isSelectBikouArea(index)
                  ? editPageRangeInfo.color
                  : bikouObj.color
              "
            >
              <option value="pink" style="background-color: #ffd5ec"></option>
              <option value="yellow" style="background-color: #ffffaa"></option>
              <option value="green" style="background-color: #e6ffe9"></option>
              <option value="orange" style="background-color: #ffdbc9"></option>
              <option value="purple" style="background-color: #e6e6fa"></option>
            </select>
          </div>
        </div>
        <div class="bikouArea_wrapper">
          <!-- エラーと記入例 -->
          <ul>
            <li
              class="text_error"
              v-show="
                (editErrorInfo.hasNegativeFirstPage ||
                  editErrorInfo.hasNegativeLastPage) &&
                isSelectBikouArea(index)
              "
            >
              ※0以下の値は設定できません
            </li>
            <li
              class="text_error"
              v-show="
                (editErrorInfo.hasStringFirstPage ||
                  editErrorInfo.hasStringLastPage) &&
                isSelectBikouArea(index)
              "
            >
              ※半角数値以外は設定出来ません
            </li>
            <li
              class="text_error"
              v-show="
                editErrorInfo.largerThanLastPage && isSelectBikouArea(index)
              "
            >
              ※終了ページより大きい値は設定できません
            </li>
            <li
              class="text_error"
              v-show="
                editErrorInfo.smallerThanFirstPage && isSelectBikouArea(index)
              "
            >
              ※開始ページより小さい値は設定できません
            </li>
            <li
              class="text_error"
              v-show="
                editErrorInfo.largerThanDaiwariTotalPage &&
                isSelectBikouArea(index)
              "
            >
              ※存在しないページ数は設定できません
            </li>
          </ul>
        </div>
        <div class="bikouArea_bottom" :key="index">
          <div class="bikouArea_wrapper">
            <!-- ページ範囲情報 -->
            <textarea
              :disabled="
                isLockDaiwari ||
                registerPageRangeInfo.color === 'white' ||
                !isSelectBikouArea(index)
              "
              :value="
                isSelectBikouArea(index)
                  ? editPageRangeInfo.bikou
                  : bikouObj.bikou
              "
              @input="editBikou($event, index)"
            ></textarea>
            <span class="btn_wrapper">
              <button class="btn_edit" @click="selectBikouArea(index)">
                編集
              </button>
              <button
                class="btn_update"
                @click="updateBikou(index)"
                :disabled="!isSelectBikouArea(index)"
              >
                更新</button
              ><button
                class="btn_delete"
                @click="deleteBikou(index)"
                :disabled="!isSelectBikouArea(index)"
              >
                削除
              </button></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUniqueStr } from "@/daiwariJS/util.js";
//import _ from 'lodash';//オブジェクトの比較用
import { Notyf } from "notyf";
import { getIsDuplicate } from "@/daiwariJS/PageRangeBikou.js";
const notyf = new Notyf({});
export default {
  props: {
    isLockDaiwari: Boolean,
    page_range_bikou_array: Array,
    daiwariAddressArray: Array,
  },
  emits: [
    "setPreviewPageRangeAddress",
    "registerPageRangeBikou",
    "hideOverlay",
    "setPreviewPageRangeAddress",
    "updatePageRangeBikou",
    "deletePageRangeBikou",
  ],
  data() {
    return {
      bikou: this.referenceBikou,
      registerPageRangeInfo: {
        firstPage: "",
        lastPage: "",
        bikou: "",
        bikouId: getUniqueStr(),
        color: "pink",
      },
      editPageRangeInfo: {}, //編集対象の備考
      editable_page_range_bikou_array: [],
      firstPageIndex: null,
      lastPageIndex: null,
      addressArray: [],
      addressIndexArray: [],
      lastPageOfDaiwari: "",
      registerErrorInfo: {
        //登録フォームのバリデーション
        hasNegativeFirstPage: false,
        hasNegativeLastPage: false,
        hasStringFirstPage: false,
        hasStringLastPage: false,
        largerThanLastPage: false,
        smallerThanFirstPage: false,
        largerThanDaiwariTotalPage: false,
      },
      editErrorInfo: {
        //編集フォームのバリデーション
        hasNegativeFirstPage: false,
        hasNegativeLastPage: false,
        hasStringFirstPage: false,
        hasStringLastPage: false,
        largerThanLastPage: false,
        smallerThanFirstPage: false,
        largerThanDaiwariTotalPage: false,
      },
      referenceBikouIndex: null,
    };
  },
  created() {
    //台割の最終ページ(最大ページ)を取得
    let lastPage = 0;
    this.daiwariAddressArray.forEach((address) => {
      if (!String(address).includes("*")) {
        lastPage = address;
      }
    });
    this.lastPageOfDaiwari = lastPage;

    //更新可能な編集用の備考配列を作成
    //更新ボタン押下前の未確定な編集情報は、こちらで保持する
    //更新ボタンを押下したら、編集用の配列を大元の備考配列に置き換える
    this.editable_page_range_bikou_array = JSON.parse(
      JSON.stringify(this.page_range_bikou_array)
    );
  },
  methods: {
    /*共通で使用------------------------------------------- */
    //開始ページ設定
    inputFirstPage(e, errorObj, bikouObj) {
      this[errorObj].hasStringFirstPage = false;
      this[errorObj].hasNegativeFirstPage = false;
      this[errorObj].largerThanLastPage = false;
      this[errorObj].largerThanDaiwariTotalPage = false;
      //先頭に「＊」があれば一旦外す
      const hasAsterisk = e.target.value.slice(0, 1) === `*`;
      const withoutAsteriskValue = hasAsterisk
        ? e.target.value.slice(1)
        : e.target.value;

      //TODO:バリデーション用の関数に切り分ける:
      //Number型に直す
      const targetPage = Number(withoutAsteriskValue)
        ? Number(withoutAsteriskValue)
        : withoutAsteriskValue;

      if (!isNaN(targetPage) && targetPage < 1) {
        //マイナス値チェック
        this[errorObj].hasNegativeFirstPage = true;
      } else if (
        !isNaN(targetPage) &&
        targetPage > this[bikouObj].lastPage &&
        this[bikouObj].lastPage !== ""
      ) {
        // 開始ページが終了ページより大きいのでNG
        this[errorObj].largerThanLastPage = true;
      } else if (isNaN(targetPage)) {
        // 文字列なのでNG
        this[errorObj].hasStringFirstPage = true;
      } else if (!isNaN(targetPage) && targetPage > this.lastPageOfDaiwari) {
        //台割のページ数より大きい値なのでNG
        this[errorObj].largerThanDaiwariTotalPage = true;
      }
      //ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

      //アスタリスクを付け直す
      const withAsteriskValue = hasAsterisk ? `*${targetPage}` : targetPage;
      this[bikouObj].firstPage = withAsteriskValue;

      this.setPreviewPages(errorObj, bikouObj); //ページ範囲のプレビューを表示
    },
    //終了ページ設定
    inputLastPage(e, errorObj, bikouObj) {
      this[errorObj].hasStringLastPage = false;
      this[errorObj].hasNegativeLastPage = false;
      this[errorObj].smallerThanFirstPage = false;
      this[errorObj].largerThanDaiwariTotalPage = false;

      //先頭に「＊」があれば一旦外す
      const hasAsterisk = e.target.value.slice(0, 1) === `*`;
      const withoutAsteriskValue = hasAsterisk
        ? e.target.value.slice(1)
        : e.target.value;

      //TODO:バリデーション用の関数に切り分ける
      //Number型に直す
      const targetPage = Number(withoutAsteriskValue)
        ? Number(withoutAsteriskValue)
        : withoutAsteriskValue;

      if (!isNaN(targetPage) && targetPage < 1) {
        //マイナス値チェック
        this[errorObj].hasNegativeLastPage = true;
      } else if (
        !isNaN(targetPage) &&
        targetPage < this[bikouObj].firstPage &&
        this[bikouObj].firstPage !== ""
      ) {
        // 終了ページが開始ページより小さいのでNG
        this[errorObj].smallerThanFirstPage = true;
      } else if (isNaN(targetPage)) {
        //文字列なのでNG
        this[errorObj].hasStringLastPage = true;
      } else if (!isNaN(targetPage) && targetPage > this.lastPageOfDaiwari) {
        //台割のページ数より大きい値なのでNG
        this[errorObj].largerThanDaiwariTotalPage = true;
      }
      //ーーーーーーーーーーーーーーーーーーーーーーーーー

      //アスタリスクを付け直す(元々＊が付いてない場合は入力値がそのまま返る)
      const withAsteriskValue = hasAsterisk ? `*${targetPage}` : targetPage;
      this[bikouObj].lastPage = withAsteriskValue;

      this.setPreviewPages(errorObj, bikouObj);
    },
    selectColor(e, errorObj, bikouObj) {
      this[bikouObj].color = e.target.value;
      //色が変更されたのでプレビュー更新
      this.setPreviewPages(errorObj, bikouObj);
    },
    //ページ範囲のプレビューを表示する
    setPreviewPages(errorObj, bikouObj) {
      if (this[bikouObj].firstPage === "" || this[bikouObj].lastPage === "") {
        this.addressIndexArray.splice(0);
        return; //未入力箇所があるのでプレビューをリセット
      }
      if (
        this.hasValidationError(errorObj) ||
        this[bikouObj].firstPage > this[bikouObj].lastPage
      ) {
        this.addressIndexArray.splice(0);
        return; //バリデーションエラーがあるのでプレビューをリセット
      } else {
        //プレビュー用の配列を作成
        this.createAddressArray(bikouObj);
        this.$emit(
          "setPreviewPageRangeAddress",
          [...this.addressIndexArray],
          this[bikouObj].color
        );
      }
    },
    hasValidationError(errorObj) {
      return (
        this[errorObj].hasNegativeFirstPage ||
        this[errorObj].hasStringFirstPage ||
        this[errorObj].hasNegativeLastPage ||
        this[errorObj].hasStringLastPage ||
        this[errorObj].smallerThanFirstPage ||
        this[errorObj].largerThanDaiwariTotalPage
      );
    },
    hasBlankField(bikouObj) {
      return (
        this[bikouObj].firstPage === "" ||
        this[bikouObj].lastPage === "" ||
        this[bikouObj].bikou === ""
      );
    },
    hasAddressError(bikouObj) {
      let isDuplicated = false;
      this.createAddressArray(bikouObj); //ページ入力値に基づいてアドレス配列を作成

      //登録済み備考と入力中の備考でのアドレス重複を確認
      this.editable_page_range_bikou_array.forEach((bikou) => {
        if (
          getIsDuplicate(bikou.addressIndexArray, this.addressIndexArray) &&
          bikou.bikouId !== this[bikouObj].bikouId
        ) {
          isDuplicated = true;
        }
      });
      return isDuplicated;
    },
    createAddressArray(bikouObj) {
      this.addressIndexArray.splice(0);
      //firstPageとlastPageに対応する、台割全体のアドレスの位置を取得する
      this.firstPageIndex = this.daiwariAddressArray.findIndex(
        (el) => el === this[bikouObj].firstPage
      );
      this.lastPageIndex = this.daiwariAddressArray.findIndex(
        (el) => el === this[bikouObj].lastPage
      );
      const addressArray = this.daiwariAddressArray.slice(
        this.firstPageIndex,
        this.lastPageIndex + 1
      );

      //備考アドレスの配列を生成
      this.daiwariAddressArray.forEach((add, index) => {
        addressArray.forEach((address) => {
          if (add === address) {
            this.addressIndexArray.push(index);
          }
        });
      });
    },
    /*新規入力フィールド----------------------------*/
    //備考の新規入力
    inputBikou(e) {
      this.registerPageRangeInfo.bikou = e.target.value;
    },
    //備考の登録
    registerBikou() {
      if (this.hasValidationError("registerErrorInfo")) {
        notyf.error("入力エラーがあるため登録できません");
        return;
      }
      if (this.hasBlankField("registerPageRangeInfo")) {
        notyf.error("未入力の項目があります");
        return;
      }
      if (this.hasAddressError("registerPageRangeInfo")) {
        notyf.error("設定済みのページ範囲と重複しているため登録できません");
        return;
      } else {
        //開始ページ・終了ページをもとに作成したアドレス配列をセット
        this.registerPageRangeInfo.addressIndexArray = this.addressIndexArray;
      }

      if (window.confirm("登録してもよろしいですか?")) {
        this.$emit("registerPageRangeBikou", this.registerPageRangeInfo);
        this.$emit("hideOverlay", "pageRageBikou");
        this.$emit(
          "setPreviewPageRangeAddress",
          [],
          this.registerPageRangeInfo.color
        );
      }
    },
    /*編集フィールド----------------------------*/
    selectBikouArea(index) {
      //バリデーション情報をリセット
      this.editErrorInfo.hasNegativeFirstPage = false;
      this.editErrorInfo.hasNegativeLastPage = false;
      this.editErrorInfo.hasStringFirstPage = false;
      this.editErrorInfo.hasStringLastPage = false;
      this.editErrorInfo.largerThanLastPage = false;
      this.editErrorInfo.smallerThanFirstPage = false;

      this.referenceBikouIndex = index;
      this.editPageRangeInfo = {
        ...this.editable_page_range_bikou_array[index],
      };
    },
    isSelectBikouArea(index) {
      return this.referenceBikouIndex === index;
    },
    editBikou(e) {
      this.editPageRangeInfo.bikou = e.target.value;
    },
    updateBikou(index) {
      if (this.hasValidationError("editErrorInfo")) {
        notyf.error("入力エラーがあるため登録できません");
        return;
      }
      if (this.hasBlankField("editPageRangeInfo")) {
        notyf.error("未入力の項目があります");
        return;
      }
      if (this.hasAddressError("editPageRangeInfo")) {
        notyf.error("設定済みのページ範囲と重複しているため登録できません");
        return;
      } else {
        //開始ページ・終了ページをもとに作成したアドレス配列をセット
        this.editPageRangeInfo.addressIndexArray = this.addressIndexArray;
      }

      if (window.confirm("更新してもよろしいですか?")) {
        this.editable_page_range_bikou_array.splice(index, 1, {
          ...this.editPageRangeInfo,
        });
        this.$emit(
          "updatePageRangeBikou",
          { ...this.editPageRangeInfo },
          index
        );
      }
    },
    deleteBikou(index) {
      if (
        window.confirm(
          "削除した備考は復元されません。本当に削除してもよろしいですか?"
        )
      ) {
        this.editable_page_range_bikou_array.splice(index, 1);
        this.$emit("deletePageRangeBikou", index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #bikou_overlay {
    z-index: 5;
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    text-align: center;
    justify-content: right;
    color: #fff;
    padding: 10px;
  }
  input {
    width: 40px;
    height: 30px;
    color: initial;
  }
  textarea {
    margin: 0 auto;
    resize: none;
    width: 100%;
    height: 50px;
    background: #fff;
    color: initial;
  }
  select {
    width: 130px;
    height: 30px;
    &.pink {
      background: #ffd5ec;
    }
    &.yellow {
      background: #ffffaa;
    }
    &.green {
      background: #e6ffe9;
    }
    &.orange {
      background: #ffdbc9;
    }
    &.purple {
      background: #e6e6fa;
    }
  }
  option {
    opacity: 70%;
  }
  ul {
    list-style: none;
  }

  .bikouArea {
    &_top {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &_container {
      display: flex;
      flex-direction: column;
      border: 2px solid white;
      overflow-y: auto;
    }
    &_content {
      background: gray;
      padding: 10px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &_wrapper {
      text-align: left;
    }
    &_title {
      font-size: 18px;
      font-weight: 700;
      height: 5%;
    }
    &_subtitle {
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      text-align: left;
    }
    &_label {
      font-size: 14px;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
  }
  .text {
    &_error {
      color: red;
      font-weight: bold;
      font-size: 12px;
    }
    &_small {
      font-size: 11px;
      display: block;
      line-height: initial;
    }
  }
  .btn_wrapper {
    display: block;
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
  .btn_edit {
    background: white;
    color: #ec7211;
    border: 1px solid #ec7211;
  }
  .btn_delete {
    background: red;
  }
  .isActive_error {
    background: red;
  }
  // 編集フィールドの活性・非活性時のレイアウト
  .active {
    &_bikouArea {
      border: 2px solid white;
      background: white;
      z-index: 100;
      color: black;
      .btn_edit {
        background: rgb(150, 150, 150);
        box-shadow: none;
        color: white;
        border: none;
      }
    }
  }
  .inActive_bikouArea {
    textarea {
      background: #d4cfcf;
    }
    .btn_delete,
    .btn_update {
      background: rgb(150, 150, 150);
      box-shadow: none;
      color: white;
      border: none;
    }
  }
}
</style>
