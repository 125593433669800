<template>
  <div class="nakatoji_line">
    <!-- 左辺 -->
    <div class="nakatoji_sakuhin_left">
      <div class="flex_nakatoji_sahen">
        <template
          v-for="(sahenAddress, sahenIndex) in vmNakatojiHyosiArray[0]
            .sakuhin_address_computed_array_sahen"
        >
          <div
            class="nakatoji_address_sahen"
            v-if="!props_daiwariData.includesPages_doNotCount"
            :key="'sahen_hyosi_address' + sahenIndex"
            :style="{ '--half': oriMaximumPage / 2 }"
            :class="[
              props_selectBarList[1].selected &&
              needsHighLight(
                vmNakatojiHyosiArray[0].bikouArray_hyosiSahen,
                sahenIndex
              )
                ? 'active_bikou'
                : '',
              props_selectBarList[2].selected
                ? color(
                    vmNakatojiHyosiArray[0].sakuhin_index_computed_array_sahen[
                      sahenIndex
                    ]
                  )
                : '',
            ]"
          >
            {{ sahenAddress }}
          </div>
          <div
            class="nakatoji_address_sahen"
            v-if="props_daiwariData.includesPages_doNotCount"
            :key="'sahen_hyosi_address' + sahenIndex"
            :style="{ '--half': oriMaximumPage / 2 }"
            :class="[
              props_selectBarList[1].selected &&
              needsHighLight(
                vmNakatojiHyosiArray[0].bikouArray_hyosiSahen,
                sahenIndex
              )
                ? 'active_bikou'
                : '',
              props_selectBarList[2].selected
                ? color(
                    vmNakatojiHyosiArray[0].sakuhin_index_computed_array_sahen[
                      sahenIndex
                    ]
                  )
                : '',
            ]"
          >
            <span v-show="sahenAddress === 0" class="sakuhin_address_blanc"
              >.</span
            >
            <span v-show="sahenAddress !== 0">{{ sahenAddress }}</span>
          </div>
        </template>
      </div>
      <div class="flex_nakatoji_sahen">
        <div
          v-for="(sahen, sahenIndex) in vmNakatojiHyosiArray[0].sahenArray"
          :key="'sahen_hyosi_' + sahenIndex"
          class="sahen_sakuhin nakatoji_sakuhin"
          :class="[
            props_selectBarList[0].selected
              ? sakuhinSinkouStatus(sahen.sakuhinInfo.sinkou_status)
              : '',
            { nakatoji_sakuhin_onlydaiwari: isCompact || isOnlyDaiwari },
          ]"
          :style="{
            '--half': oriMaximumPage / 2,
            '--width': sahen.sakuhinInfo.total_page,
            height: settingOriHeight,
          }"
        >
          <span class="sakuhin_mei" ref="sakuhin_mei">
            {{ sakuhinDisplayName(sahen.sakuhinInfo) }}
          </span>
          <span
            v-if="
              !isCompact &&
              !isOnlyDaiwari &&
              !(isCustom && !customOutputList.tantou.isSelected)
            "
            class="tantou_mei"
            ref="tantou"
          >
            <span
              v-for="(tantou, index) in sahen.sakuhinInfo.tantou"
              :key="index + '_sahen'"
            >
              {{ tantou.user_last_name }}{{ tantou.user_first_name }}
              <span
                v-show="
                  sahen.sakuhinInfo.tantou.length === 0 ||
                  index !== sahen.sakuhinInfo.tantou.length - 1
                "
                >,</span
              >
            </span>
          </span>
          <span
            v-if="!(isCustom && !customOutputList.sakuhinBiko.isSelected)"
            class="bikou_sakuhin"
            ref="bikou_sakuhin"
            >{{ sahen.sakuhinInfo.sakuhin_bikou }}</span
          >
          <span class="bikou_sakuhin"
            ><span v-if="sahen.sakuhinInfo.divided_page !== 0"
              >{{ sahen.sakuhinInfo.divided_page }}/</span
            >{{ sahen.sakuhinInfo.total_page }}p</span
          >
        </div>
      </div>
    </div>

    <!-- 折 -->
    <div class="nakatoji_ori_container">
      <p>
        {{ vmNakatojiHyosiArray[0].ori_kbn }}
        <span
          v-if="
            !isOnlyDaiwari &&
            !(isCustom && !customOutputList.irokazu.isSelected)
          "
        >
          /{{ vmNakatojiHyosiArray[0].sikisuu }}</span
        >
        <span v-if="!isOnlyDaiwari"
          >/{{
            vmNakatojiHyosiArray[0].sakuhin_address_computed_array_uhen.length +
            vmNakatojiHyosiArray[0].sakuhin_address_computed_array_sahen.length
          }}P</span
        >
      </p>
      <p
        v-if="
          !isCompact &&
          !isOnlyDaiwari &&
          !(isCustom && !customOutputList.yousi.isSelected)
        "
      >
        {{ hankaku(vmNakatojiHyosiArray[0].yousi) }}
      </p>
      <p
        v-if="
          vmNakatojiHyosiArray[0].kakou_array.length &&
          !isCompact &&
          !isOnlyDaiwari &&
          !(isCustom && !customOutputList.kakou.isSelected)
        "
      >
        <span
          v-for="(kakou, index) in vmNakatojiHyosiArray[0].kakou_array"
          :key="index"
        >
          {{ hankaku(kakou.kbn) }}
          <span
            v-show="
              vmNakatojiHyosiArray[0].kakou_array.length === 0 ||
              index !== vmNakatojiHyosiArray[0].kakou_array.length - 1
            "
            >,</span
          >
        </span>
      </p>
      <p
        :class="'ref_' + vmNakatojiHyosiArray[0].ori_kbn"
        class="bikou_ori"
        ref="ref_hyosi_bikou"
      >
        {{ vmNakatojiHyosiArray[0].ori_bikou }}
      </p>
    </div>

    <!-- 右辺 -->
    <div class="nakatoji_sakuhin_right">
      <div class="flex_nakatoji_uhen">
        <template
          v-for="(uhenAddress, uhenIndex) in vmNakatojiHyosiArray[0]
            .sakuhin_address_computed_array_uhen"
        >
          <div
            class="nakatoji_address_uhen"
            v-if="!props_daiwariData.includesPages_doNotCount"
            :key="'uhen_hyosi_address' + uhenIndex"
            :style="{ '--half': oriMaximumPage / 2 }"
            :class="[
              props_selectBarList[1].selected &&
              needsHighLight(
                vmNakatojiHyosiArray[0].bikouArray_hyosiUhen,
                uhenIndex
              )
                ? 'active_bikou'
                : '',
              props_selectBarList[2].selected
                ? color(
                    vmNakatojiHyosiArray[0].sakuhin_index_computed_array_uhen[
                      uhenIndex
                    ]
                  )
                : '',
            ]"
          >
            {{ uhenAddress }}
          </div>
          <div
            class="nakatoji_address_uhen"
            v-if="props_daiwariData.includesPages_doNotCount"
            :key="'uhen_hyosi_address' + uhenIndex"
            :style="{ '--half': oriMaximumPage / 2 }"
            :class="[
              props_selectBarList[1].selected &&
              needsHighLight(
                vmNakatojiHyosiArray[0].bikouArray_hyosiUhen,
                uhenIndex
              )
                ? 'active_bikou'
                : '',
              props_selectBarList[2].selected
                ? color(
                    vmNakatojiHyosiArray[0].sakuhin_index_computed_array_uhen[
                      uhenIndex
                    ]
                  )
                : '',
            ]"
          >
            <span v-show="uhenAddress === 0" class="sakuhin_address_blanc"
              >.</span
            >
            <span v-show="uhenAddress !== 0">{{ uhenAddress }}</span>
          </div>
        </template>
      </div>

      <div class="flex_nakatoji_uhen">
        <div
          v-for="(uhen, uhenIndex) in vmNakatojiHyosiArray[0].uhenArray"
          :key="'uhen_' + uhenIndex"
          class="uhen_sakuhin nakatoji_sakuhin"
          :class="[
            props_selectBarList[0].selected
              ? sakuhinSinkouStatus(uhen.sakuhinInfo.sinkou_status)
              : '',
            { nakatoji_sakuhin_onlydaiwari: isCompact || isOnlyDaiwari },
          ]"
          :style="{
            '--half': oriMaximumPage / 2,
            '--width': uhen.sakuhinInfo.total_page,
            height: settingOriHeight,
          }"
        >
          <span class="sakuhin_mei" ref="uhen_sakuhin_mei">{{
            sakuhinDisplayName(uhen.sakuhinInfo)
          }}</span>
          <span
            v-if="
              !isCompact &&
              !isOnlyDaiwari &&
              !(isCustom && !customOutputList.tantou.isSelected)
            "
            class="tantou_mei"
            ref="uhen_tantou"
          >
            <span
              v-for="(tantou, index) in uhen.sakuhinInfo.tantou"
              :key="index + '_uhen'"
            >
              {{ tantou.user_last_name }}{{ tantou.user_first_name }}
              <span
                v-show="
                  uhen.sakuhinInfo.tantou.length === 0 ||
                  index !== uhen.sakuhinInfo.tantou.length - 1
                "
                >,</span
              >
            </span>
          </span>
          <span
            v-if="!(isCustom && !customOutputList.sakuhinBiko.isSelected)"
            class="bikou_sakuhin"
            ref="uhen_bikou_sakuhin"
            >{{ uhen.sakuhinInfo.sakuhin_bikou }}</span
          >
          <span class="bikou_sakuhin"
            ><span v-if="uhen.sakuhinInfo.divided_page !== 0"
              >{{ uhen.sakuhinInfo.divided_page }}/</span
            >{{ uhen.sakuhinInfo.total_page }}p</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zenkakuToHankaku } from "@/daiwariJS/StringConvertion.js";
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";
import { useVModel } from "vue-composable";

export default {
  name: "NakatojiHyosi",
  props: {
    props_daiwariData: Object,
    nakatojiHyosiArray: Array,
    oriMaximumPage: Number,
    page_range_bikou_array: Array,
    isCompact: Boolean,
    props_selectBarList: Array,
    isOnlyDaiwari: Boolean,
    isCustom: Boolean,
    customOutputList: Object,
  },
  emits: ["format_detailOriArray", "format_detailSakuhinArray"],
  setup(props) {
    const vmNakatojiHyosiArray = useVModel(props, "nakatojiHyosiArray");
    return {
      vmNakatojiHyosiArray,
    };
  },
  created() {
    this.check_hyousiOriRef();
    this.check_hyousiSakuhinRef();
    this.set_pageBikouInfo();
  },
  methods: {
    check_hyousiOriRef() {
      //折省略テーブル用の配列を生成
      this.$nextTick(() => {
        if (
          this.$refs.ref_hyosi_bikou.scrollHeight >
          this.$refs.ref_hyosi_bikou.offsetHeight
        ) {
          this.$emit("format_detailOriArray", true);
        }
      });
    },
    check_hyousiSakuhinRef() {
      this.$nextTick(() => {
        let ref = this.$refs;
        this.$emit("format_detailSakuhinArray", ref);
      });
    },
    set_pageBikouInfo() {
      //備考のハイライト
      const sakuhin_page_bikou_array = JSON.parse(
        JSON.stringify(this.props_daiwariData.sakuhin_page_bikou_array)
      );
      let bikouArray_hyosiSahen = [];
      let bikouArray_hyosiUhen = [];

      this.vmNakatojiHyosiArray[0].sahenArray.forEach((sahenSakuhin) => {
        const targetBikou = sakuhin_page_bikou_array.find(
          (bikou) =>
            bikou.sakuhin_bikouId === sahenSakuhin.sakuhinInfo.sakuhin_bikouId
        );
        bikouArray_hyosiSahen = [
          ...bikouArray_hyosiSahen,
          ...targetBikou.bikou_array,
        ];
      });
      this.vmNakatojiHyosiArray[0].uhenArray.forEach((uhenSakuhin) => {
        const targetBikou = sakuhin_page_bikou_array.find(
          (bikou) =>
            bikou.sakuhin_bikouId === uhenSakuhin.sakuhinInfo.sakuhin_bikouId
        );
        bikouArray_hyosiUhen = [
          ...bikouArray_hyosiUhen,
          ...targetBikou.bikou_array,
        ];
      });

      this.vmNakatojiHyosiArray[0].bikouArray_hyosiSahen =
        bikouArray_hyosiSahen;
      this.vmNakatojiHyosiArray[0].bikouArray_hyosiUhen = bikouArray_hyosiUhen;
    },
  },
  computed: {
    hankaku() {
      return (character) => {
        const character_hankaku = zenkakuToHankaku(character);
        return character_hankaku;
      };
    },
    sakuhinSinkouStatus() {
      //進行ステータスに合わせた色付け
      return (status) => {
        if (status == "Ⅰ.企画立案") {
          return "st_kikaku";
        } else if (status == "Ⅱ.原稿制作") {
          return "st_genkou";
        } else if (status == "Ⅲ.組版・製版") {
          return "st_kumihan";
        } else if (status == "Ⅳ.校了") {
          return "st_kouryo";
        } else if (status == "Ⅴ.印刷") {
          return "st_insatu";
        } else if (status == "Ⅵ.原価登録") {
          return "st_genka";
        }
      };
    },
    needsHighLight() {
      //ハイライトの要不要を判定
      return (bikou_array, addressIndex) => {
        if (bikou_array[addressIndex]) {
          if (bikou_array[addressIndex].bikou !== "") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    pageRangeBikouArray() {
      return this.page_range_bikou_array ? this.page_range_bikou_array : [];
    },
    color() {
      return (addressIndex) => {
        let color = "";
        const targetIndex = addressIndex;
        if (this.pageRangeBikouArray.length === 0) {
          return;
        }
        this.pageRangeBikouArray.forEach((bikou) => {
          if (bikou.addressIndexArray.includes(targetIndex)) {
            color = `bg_${bikou.color}`;
          }
        });
        return color;
      };
    },
    sakuhinDisplayName() {
      return (sakuhin) => {
        return convertSakuhinMeiFull(sakuhin);
      };
    },
    // カスタム時の折の高さ設定
    settingOriHeight() {
      if (this.isCustom) {
        let height = 75;
        if (!this.customOutputList.tantou.isSelected) {
          height -= 15;
        }
        if (!this.customOutputList.sakuhinBiko.isSelected) {
          height -= 15;
        }
        return height + "px";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#daiwari_edit {
  :root {
    --page: 0;
    --maxpage: 0;
    --half: 0;
    --width: 0;
  }

  //ページカウント対象外の場合何も表示させない
  .sakuhin_address_blanc {
    opacity: 0;
  }
  .sakuhin_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 13px;
  }
  .tantou_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .author_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &_null {
      display: block;
      height: 10px;
    }
  }
  .bikou {
    &_ori {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: auto;
    }
    &_sakuhin {
      display: -webkit-box;
      font-size: 8px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 12px;
    }
  }
  .active_bikou {
    border: 2px solid red !important;
    margin: -2px 0;
  }
  //進行ステータス
  .st_kikaku {
    background-color: #ffccff;
  }

  .st_genkou {
    background-color: #ffffaa;
  }

  .st_kumihan {
    background-color: #e6ffe9;
  }

  .st_kouryo {
    background-color: #ffdbc9;
  }

  .st_insatu {
    background-color: #ffd5ec;
  }

  .st_genka {
    background-color: #e6e6fa;
  }

  //ページ範囲備考の背景色
  .bg_pink {
    background-color: #ffd5ec;
    &--preview {
      border: 2px dashed pink;
    }
  }
  .bg_yellow {
    background-color: #ffffaa;
    &--preview {
      border: 2px dashed yellow;
    }
  }
  .bg_green {
    background-color: #e6ffe9;
    &--preview {
      border: 2px dashed green;
    }
  }
  .bg_orange {
    background-color: #ffdbc9;
    &--preview {
      border: 2px dashed orange;
    }
  }
  .bg_purple {
    background-color: #e6e6fa;
    &--preview {
      border: 2px dashed purple;
    }
  }
  // 出力設定用-----------------------------
  // 台割のみ
  .nakatoji_sakuhin.nakatoji_sakuhin_onlydaiwari {
    height: 55px;
  }

  // 中綴じ台割用CSS__________________________

  .nakatoji_line {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
  }

  .nakatoji_sakuhin {
    &_left {
      width: 45%;
      justify-content: right;
    }
    &_right {
      width: 45%;
      justify-content: left;
    }
  }
  .nakatoji_sakuhin {
    width: calc(100% / var(--half) * var(--width));
    font-size: 10px;
    text-align: center;
    height: 75px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .sahen_sakuhin {
    border-left: 1px solid #000;
  }
  .uhen_sakuhin {
    border-right: 1px solid #000;
  }
  .flex_nakatoji_uhen {
    display: flex;
    justify-content: left;
  }
  .flex_nakatoji_sahen {
    display: flex;
    justify-content: right;
  }
  .nakatoji_address_uhen {
    text-align: center;
    font-size: 10px;
    width: calc(100% / var(--half));
    display: inline-block;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
  }
  .nakatoji_address_sahen {
    text-align: center;
    font-size: 10px;
    width: calc(100% / var(--half));
    display: inline-block;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }
  .nakatoji_ori_container {
    width: 85px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
    padding-top: 2px;
    background: #ddd;
    p {
      font-size: 10px;
      line-height: 11px;
    }
  }
}
</style>
