<template>
  <div id="overlay" @click="hideModal">
    <div class="modal" @click="stopEvent">
      <!-- モーダル名 -->
      <div class="modal__header">
        <p class="modal__title">{{ modalTitle }}</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <!-- 取引先検索 -->
        <div class="modal__review__search-container">
          <input
            type="text"
            class="search_box"
            v-model="searchWord"
            @keydown.enter="search"
          />
          <button class="btn_gray" @click="search">検索</button>
        </div>
        <p class="modal__review__search-result" v-if="searched_torihikisaki">
          <span v-if="!searched_torihikisaki.searchWord == ''"
            >「{{ searched_torihikisaki.searchWord }}」の</span
          >
          検索結果({{ searched_torihikisaki.totalRecords }}件)
        </p>
        <p class="modal__review__search-result" v-if="searchResult_nullFlg">
          検索結果はありません
        </p>

        <!-- 取引先一覧表示 -->
        <ul
          class="modal__review__result-container"
          v-if="searched_torihikisaki"
        >
          <template
            v-for="torihikisaki in searched_torihikisaki.torihikisakiList"
          >
            <li
              class="modal__review__result-name"
              v-if="torihikisaki.torihikisakiId !== 0"
              :key="torihikisaki.torihikisakiId"
              @click="add_torihikisaki(torihikisaki)"
            >
              {{ torihikisaki.torihikisakiMei }}
              <span
                class="modal__review__result-penname"
                v-if="torihikisaki.pennameList.length"
                >/ ペンネーム:<span
                  v-for="(penname, index) in torihikisaki.pennameList"
                  :key="index"
                  >{{ penname.penname }}
                  <span v-if="index !== torihikisaki.pennameList.length - 1"
                    >,
                  </span>
                </span></span
              >
            </li>
          </template>
        </ul>
        <!-- ページング -->
        <Paginate
          class="pagenation"
          v-if="searched_torihikisaki"
          :page-count="searched_torihikisaki.totalPage"
          :hide-prev-next="true"
          :page-range="3"
          :margin-pages="1"
          :click-handler="getCurrentPage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-item--link'"
          :active-class="'page-item--active'"
        ></Paginate>

        <!-- 選択済みの取引先を表示 -->
        <ul
          class="modal__review__badge-container"
          v-if="
            modalName === 'modal_torihikisaki' ||
            modalOnModalName === 'modal_torihikisaki' ||
            modalName === 'modal_genka_torihikisaki' ||
            modalOnModalName === 'modal_genka_torihikisaki' ||
            modalName === 'modal_design' ||
            modalName === 'modal_hensyu'
          "
        >
          <li
            class="modal__review__badge-selected"
            v-if="selected_torihikisaki !== ''"
          >
            <span
              class="modal__review__badge-name"
              v-if="selected_torihikisaki.torihikisakiMei"
            >
              {{ selected_torihikisaki.torihikisakiMei }}
            </span>
            <span v-else>{{ selected_torihikisaki }}</span>
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="modal__review__badge-cancel"
              @click="removeTorihikisaki"
            />
          </li>
        </ul>
        <button class="btn_blue" @click="updateTorihikisaki">決定</button>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";
// 台帳の取引先検索がペンネーム検索に変更
// 仕様確定したら不要なコードは削除する
export default {
  components: { Paginate },
  props: {
    modalName: String,
    modalOnModalName: String,
    selectedSakuhin: Object,
    torihikisakiIndex: Number,
    props_torihiki: Object,
    changeTorihikiToDaiwari: Function,
    selectedDesign: String,
    selectedHensyu: String,
    url: String,
    update_genkaIndex: Number,
    editDaiwariGenkaTorihiki: Function,
    daiwariData: Object,
  },
  emits: ["hideModal", "count_timeOut", "updateTorihikisaki"],
  data() {
    return {
      modalTitle: "取引先検索",
      searched_torihikisaki: null,
      selected_torihikisaki: null,
      searchWord: "",
      currentPage: 1,
      clickedPage: 1,
      showPages: 5,
      searchResult_nullFlg: false,
      isPropsTorihiki: false,
    };
  },
  created() {
    if (this.modalName === "modal_design") {
      this.modalTitle = "取引先検索(デザイン)";
      this.selected_torihikisaki = this.selectedDesign;
      this.searchWord = this.selectedDesign;
    } else if (this.modalName === "modal_hensyu") {
      this.modalTitle = "取引先検索(外編・編プロ)";
      this.selected_torihikisaki = this.selectedHensyu;
      this.searchWord = this.selectedHensyu;
    } else if (
      this.modalName === "modal_torihikisaki" ||
      this.modalOnModalName === "modal_torihikisaki"
    ) {
      if (this.props_torihiki) {
        this.isPropsTorihiki = true;
        this.selected_torihikisaki = this.props_torihiki.torihikisaki;
      } else if (this.selectedSakuhin && this.selectedSakuhin.genka_array) {
        this.selected_torihikisaki =
          this.selectedSakuhin.genka_array[this.torihikisakiIndex].torihikisaki;
      }
      //検索ワードに選択済みの取引先名を入力
      if (this.selected_torihikisaki == "") {
        this.searchWord = "";
      } else {
        this.searchWord = this.selected_torihikisaki.torihikisakiMei;
      }
    } else if (
      this.modalName === "modal_genka_torihikisaki" ||
      this.modalOnModalName === "modal_genka_torihikisaki"
    ) {
      // モーダル名がmodal_genka_torihikisakiになるのは台帳登録画面経由のみ（App.vueのopenDaiwariGenkaTorihiki経由）
      this.isPropsTorihiki = true;
      this.selected_torihikisaki =
        this.daiwariData.daiwari_genkaArray[
          this.update_genkaIndex
        ].torihikisaki;
    }
    this.getPageInfo();
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_torihikisaki", this.isPropsTorihiki);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    page_previous() {
      this.currentPage--;
      this.getPageInfo();
    },
    page_next() {
      this.currentPage++;
      this.getPageInfo();
    },
    page_number(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;
        this.getPageInfo();
      }
    },
    getPageInfo() {
      axios
        .get(
          `${this.url}/get_torihikisaki_split?torihikisaki_mei=${this.searchWord}&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.searched_torihikisaki = response.data;
        })
        .catch((error) => {
          alert("取引先データが取得できませんでした\n" + error);
        });
    },
    getCurrentPage(currentPage) {
      this.clickedPage = currentPage;
      this.currentPage = currentPage;
      this.getPageInfo();
    },
    search() {
      this.currentPage = 1;
      this.getPageInfo();
    },
    add_torihikisaki(torihikisaki) {
      torihikisaki = {
        tankaList: torihikisaki.tankaList,
        torihikisakiId: torihikisaki.torihikisakiId,
        torihikisakiMei: torihikisaki.torihikisakiMei,
        penname: torihikisaki.penname,
      };
      this.selected_torihikisaki = torihikisaki;
    },
    removeTorihikisaki() {
      this.selected_torihikisaki = "";
    },
    updateTorihikisaki() {
      // 作品に紐づく原価の場合
      if (this.update_genkaIndex == null) {
        this.$emit("updateTorihikisaki", this.selected_torihikisaki);
        if (this.props_torihiki) {
          this.changeTorihikiToDaiwari();
        } else if (
          this.selectedSakuhin ||
          this.modalName === "modal_design" ||
          this.modalName === "modal_hensyu"
        ) {
          this.$emit("hideModal", "modal_torihikisaki");
        }
        // 作品に紐づかない原価の場合
      } else {
        this.editDaiwariGenkaTorihiki(
          this.selected_torihikisaki,
          this.update_genkaIndex
        );
      }
    },
  },
};
</script>

<style lang="scss" scopled>
#daiwari_edit {
  .modal {
    width: 60%;
    height: 80%;
    &__header {
      height: 10%;
    }
    &__close {
      color: #fff;
    }
  }

  .btn_gray {
    padding: 5px;
  }
}
</style>
