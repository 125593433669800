//英数字の半角変換
export const zenkakuToHankaku_alphaNum = (str) => {
    return str.replace(/[！-～]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}


//カタカナの半角変換
export const zenkakuToHankaku_kana = (str) => {
    var kanaMap = {
        "ガ": "ｶﾞ",
        "ギ": "ｷﾞ",
        "グ": "ｸﾞ",
        "ゲ": "ｹﾞ",
        "ゴ": "ｺﾞ",
        "ザ": "ｻﾞ",
        "ジ": "ｼﾞ",
        "ズ": "ｽﾞ",
        "ゼ": "ｾﾞ",
        "ゾ": "ｿﾞ",
        "ダ": "ﾀﾞ",
        "ヂ": "ﾁﾞ",
        "ヅ": "ﾂﾞ",
        "デ": "ﾃﾞ",
        "ド": "ﾄﾞ",
        "バ": "ﾊﾞ",
        "ビ": "ﾋﾞ",
        "ブ": "ﾌﾞ",
        "ベ": "ﾍﾞ",
        "ボ": "ﾎﾞ",
        "パ": "ﾊﾟ",
        "ピ": "ﾋﾟ",
        "プ": "ﾌﾟ",
        "ペ": "ﾍﾟ",
        "ポ": "ﾎﾟ",
        "ヴ": "ｳﾞ",
        "ヷ": "ﾜﾞ",
        "ヺ": "ｦﾞ",
        "ア": "ｱ",
        "イ": "ｲ",
        "ウ": "ｳ",
        "エ": "ｴ",
        "オ": "ｵ",
        "カ": "ｶ",
        "キ": "ｷ",
        "ク": "ｸ",
        "ケ": "ｹ",
        "コ": "ｺ",
        "サ": "ｻ",
        "シ": "ｼ",
        "ス": "ｽ",
        "セ": "ｾ",
        "ソ": "ｿ",
        "タ": "ﾀ",
        "チ": "ﾁ",
        "ツ": "ﾂ",
        "テ": "ﾃ",
        "ト": "ﾄ",
        "ナ": "ﾅ",
        "ニ": "ﾆ",
        "ヌ": "ﾇ",
        "ネ": "ﾈ",
        "ノ": "ﾉ",
        "ハ": "ﾊ",
        "ヒ": "ﾋ",
        "フ": "ﾌ",
        "ヘ": "ﾍ",
        "ホ": "ﾎ",
        "マ": "ﾏ",
        "ミ": "ﾐ",
        "ム": "ﾑ",
        "メ": "ﾒ",
        "モ": "ﾓ",
        "ヤ": "ﾔ",
        "ユ": "ﾕ",
        "ヨ": "ﾖ",
        "ラ": "ﾗ",
        "リ": "ﾘ",
        "ル": "ﾙ",
        "レ": "ﾚ",
        "ロ": "ﾛ",
        "ワ": "ﾜ",
        "ヲ": "ｦ",
        "ン": "ﾝ",
        "ァ": "ｧ",
        "ィ": "ｨ",
        "ゥ": "ｩ",
        "ェ": "ｪ",
        "ォ": "ｫ",
        "ッ": "ｯ",
        "ャ": "ｬ",
        "ュ": "ｭ",
        "ョ": "ｮ",
        "。": "｡",
        "、": "､",
        "ー": "ｰ",
        "「": "｢",
        "」": "｣",
        "・": "･"
    }
    let reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
        .replace(reg, function(match) {
            return kanaMap[match];
        })
        .replace(/゛/g, 'ﾞ')
        .replace(/゜/g, 'ﾟ');
};

//英数字,カタカナまとめて半角変換
export const zenkakuToHankaku = (str) => {
    const hankaku_alpaNum = zenkakuToHankaku_alphaNum(str);
    const hankaku_kana = zenkakuToHankaku_kana(hankaku_alpaNum);
    return hankaku_kana
}