<template>
  <div id="overlay">
    <div class="modal">
      <div class="modal__header">
        <p class="modal__title">台割表の確定</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <div class="modal__message message_left">
          <p>台割表を確定します。</p>
        </div>
        <div class="modal__message">
          <div class="sakuhin_title">
            <p>
              {{ title }}
              <span class="edaban_title" v-if="daiwariHeader.isChild">
                {{ daiwariHeader.childDaiwariName }}
              </span>
            </p>
          </div>
        </div>
        <div class="table-wrapper" id="table-wrapper">
          <table class="frame_table">
            <thead>
              <tr>
                <th>バージョン</th>
                <th>ユーザー名</th>
                <th>台割確定日時</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rireki, index) in this.daiwariKakuteiArray"
                :key="index"
              >
                <td>{{ rireki.version }}</td>
                <td>{{ rireki.daiwariKakuteiUserMei }}</td>
                <td>{{ rireki.daiwariKakuteiDateStr }}</td>
              </tr>
              <tr class="new_version">
                <td>{{ daiwariKakuteiVersion + 1 }}</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal__message message_left">
          <p>バージョン{{ daiwariKakuteiVersion + 1 }}として確定します。</p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_orange" @click="confirmDaiwari">
            台割表を確定する
          </button>
          <button class="btn_orange" @click="hideModal">キャンセル</button>
        </div>
      </div>
      <ModalDaiwariKakuteiConfirm
        v-if="isShowConfirm"
        @daiwariConfirmStopEvent="daiwariConfirmStopEvent"
        @confirmCancel="isShowConfirm = false"
        @kakuteiConfirm="kakuteiConfirm"
        :stopEvent="stopEvent"
        :changes="changes"
        :isKakutei="isKakutei"
        :daiwariKakuteiVersion="daiwariKakuteiVersion + 1"
        :editingUserInfo="editingUserInfo"
        :title="createTitle()"
      />
    </div>
  </div>
</template>

<script>
import ModalDaiwariKakuteiConfirm from "./ModalDaiwariKakuteiConfirm.vue";
import axios from "axios";

export default {
  components: {
    ModalDaiwariKakuteiConfirm,
  },
  props: {
    daiwariId: Number,
    url: String,
    daiwariSakuhinArray: Array,
    kakuteiDaiwari: Function,
    saveDaiwari: Function,
    tantoBusyoMei: String,
    title: String,
    daiwariKakuteiVersion: Number,
    daiwariHeader: Object,
    daiwariData: Object,
    editingUserInfo: Object,
    fromOutputBtn: Boolean,
  },
  emits: ["processEnd", "hideModal", "hideModal"],
  data() {
    return {
      sakuhinList: [],
      daiwariKakuteiArray: [],
      isShowConfirm: false,
      prevSakuhinList: [],
      prevDaiwariData: {},
      changes: [],
      isKakutei: false,
    };
  },
  created() {
    this.sakuhinList = this.daiwariSakuhinArray;
    // 台割確定履歴の取得
    axios
      .get(
        `${this.url}/daiwari/get_kakutei_daiwari?daiwariId=${this.daiwariId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((res) => {
        this.daiwariKakuteiArray = [...res.data];
        let jsonArray = [];
        this.daiwariKakuteiArray.sort((a, b) => a.version - b.version);
        this.daiwariKakuteiArray.forEach((daiwari) => {
          jsonArray.push(JSON.parse(daiwari.daiwariData));
        });
        // this.createPrevSakuhinList();
        // this.compareWithPrevDaiwari();
        if (this.fromOutputBtn) {
          if (this.daiwariKakuteiArray.length >= 1) {
            this.createPrevSakuhinList();
            const hasChanges = this.compareWithPrevDaiwari();
            const prevSeisakubikou =
              this.prevDaiwariData.outputSyosiData.manualSyosiData.bikou;
            const hasKakuteiRireki = true;
            this.$emit(
              "processEnd",
              hasChanges,
              prevSeisakubikou,
              hasKakuteiRireki
            );
          } else {
            const hasChanges = false;
            const prevSakuhinBikou = "";
            const hasKakuteiRireki = false;
            this.$emit(
              "processEnd",
              hasChanges,
              prevSakuhinBikou,
              hasKakuteiRireki
            );
          }
        }
      })
      .catch((error) => {
        alert("確定履歴データが取得できませんでした\n");
        console.log(error);
      });
  },
  mounted() {
    // スクロールを常に下にする処理
    let target = document.getElementById("table-wrapper");
    setTimeout(() => {
      target.scrollTop = target.scrollHeight;
    }, 100);
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
    },
    daiwariConfirmStopEvent() {
      this.$emit("count_timeOut");
    },
    createTitle() {
      const toSendTitle = this.daiwariHeader.isChild
        ? this.title + " " + this.daiwariHeader.childDaiwariName
        : this.title;
      return toSendTitle;
    },
    confirmDaiwari() {
      // 一度でも確定していたら変更確認画面を表示
      if (this.daiwariKakuteiArray.length >= 1) {
        this.isKakutei = true;
        this.createPrevSakuhinList();
        this.compareWithPrevDaiwari();
        this.isShowConfirm = true;
      } else {
        this.isKakutei = false;
        this.isShowConfirm = true;
        // if (confirm("台割表を確定します")) {
        //   this.kakuteiDaiwari();
        // }
      }
    },
    kakuteiConfirm(toSendData) {
      const toSendTitle = this.createTitle();
      this.kakuteiDaiwari(toSendData, toSendTitle);
    },
    // 一つ前の台割確定時の作品だけの配列を作成する
    createPrevSakuhinList() {
      this.prevSakuhinList.splice(0);
      let kari_daiwariSakuhinArray = [];
      this.prevDaiwariData = JSON.parse(
        this.daiwariKakuteiArray.slice(-1)[0].daiwariData
      );
      this.prevDaiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          kari_daiwariSakuhinArray.push(sakuhin);
        });
      });
      kari_daiwariSakuhinArray.forEach((sakuhin, sakuhinIndex) => {
        if (
          (!sakuhinIndex == 0 &&
            sakuhin.sakuhin_uniqueId !== 0 &&
            sakuhin.sakuhin_uniqueId !==
              kari_daiwariSakuhinArray[sakuhinIndex - 1].sakuhin_uniqueId) ||
          (!sakuhinIndex == 0 &&
            sakuhin.sakuhin_id !== -1 &&
            sakuhin.sakuhin_id !==
              kari_daiwariSakuhinArray[sakuhinIndex - 1].sakuhin_id) ||
          sakuhinIndex == 0
        ) {
          this.prevSakuhinList.push(sakuhin);
        }
      });
    },
    // 一つ前の確定時の台割との差分を比較する
    // 作品に紐づく一意のidとしてsakuhin_bikoouIdを使用（sakuhin_uniqueIdはリロードごとに変わってしまうため）
    compareWithPrevDaiwari() {
      this.changes.splice(0);
      // 追加された作品（sakuhinListに存在するがprevSakuhinListに存在しないデータ）
      const addedSakuhin = this.sakuhinList.filter(
        (sakuhin) =>
          this.prevSakuhinList.filter(
            (prevSakuhin) =>
              prevSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId
          ).length === 0
      );
      if (addedSakuhin.length > 0) {
        addedSakuhin.forEach((addSakuhin) => {
          let startPage =
            addSakuhin.sakuhin_address_computed_array_withFlg.length > 0
              ? addSakuhin.sakuhin_address_computed_array_withFlg[0]
              : -1;
          let endPage =
            addSakuhin.sakuhin_address_computed_array_withFlg.length > 1
              ? addSakuhin.total_page +
                addSakuhin.sakuhin_address_computed_array_withFlg[0] -
                1
              : -1;
          this.changes.push({
            startPage: startPage,
            endPage: endPage,
            sakuhinTitle: this.convertSakuhinMei(addSakuhin),
            changeArray: ["追加"],
          });
        });
      }

      // 削除された作品（prevSakuhinListに存在するがsakuhinListに存在しないデータ）
      const deletedSakuhin = this.prevSakuhinList.filter(
        (prevSakuhin) =>
          this.sakuhinList.filter(
            (sakuhin) => prevSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId
          ).length === 0
      );
      if (deletedSakuhin.length > 0) {
        deletedSakuhin.forEach((deleteSakuhin) => {
          this.changes.push({
            startPage: -1,
            endPage: -1,
            sakuhinTitle: this.convertSakuhinMei(deleteSakuhin),
            changeArray: ["削除"],
          });
        });
      }

      // 内容の変更を確認
      this.sakuhinList.forEach((sakuhin) => {
        this.prevSakuhinList.forEach((prevSakuhin) => {
          if (sakuhin.sakuhin_bikouId === prevSakuhin.sakuhin_bikouId) {
            let startPage =
              sakuhin.sakuhin_address_computed_array_withFlg.length > 0
                ? sakuhin.sakuhin_address_computed_array_withFlg[0]
                : -1;
            let endPage =
              sakuhin.sakuhin_address_computed_array_withFlg.length > 1
                ? sakuhin.total_page +
                  sakuhin.sakuhin_address_computed_array_withFlg[0] -
                  1
                : -1;
            let changesData = {
              startPage: startPage,
              endPage: endPage,
              sakuhinTitle: this.convertSakuhinMei(sakuhin),
              changeArray: [],
            };

            // ページ数変更
            if (sakuhin.page !== prevSakuhin.page) {
              changesData.changeArray.push("ページ数変更");
            }

            // 作品名変更
            if (
              sakuhin.sakuhin_mei !== prevSakuhin.sakuhin_mei ||
              sakuhin.sakuhin_mei_hosoku !== prevSakuhin.sakuhin_mei_hosoku
            ) {
              changesData.changeArray.push("作品名変更");
            }

            // ペンネーム
            // 追加
            if (Array.isArray(sakuhin.penName)) {
              const addedPenName = sakuhin.penName.filter(
                (pen) =>
                  prevSakuhin.penName.filter(
                    (prevPen) => prevPen.penName === pen.penName
                  ).length === 0
              );

              //削除
              let deletedPenName = [];
              if (Array.isArray(prevSakuhin.penName)) {
                deletedPenName = prevSakuhin.penName.filter(
                  (prevPen) =>
                    sakuhin.penName.filter(
                      (pen) => prevPen.penName === pen.penName
                    ).length === 0
                );
              }

              if (addedPenName.length > 0 || deletedPenName.length > 0) {
                changesData.changeArray.push("ペンネーム変更");
              }
            }

            // ページ備考（ペンネーム等の検知に合わせ、追加作品は検知対象外）
            let targetPageBikou =
              this.daiwariData.sakuhin_page_bikou_array.find(
                (pageBikou) =>
                  pageBikou.sakuhin_bikouId === sakuhin.sakuhin_bikouId
              );
            let targetPrevPageBikou =
              this.prevDaiwariData.sakuhin_page_bikou_array.find(
                (pageBikou) =>
                  pageBikou.sakuhin_bikouId === sakuhin.sakuhin_bikouId
              );
            let hasChange = false;
            for (let i = 0; i < targetPageBikou.bikou_array.length; i++) {
              if (targetPrevPageBikou.bikou_array[i] === undefined) {
                // 現在のほうがページ数が多い場合
                if (targetPageBikou.bikou_array[i].bikou !== "")
                  hasChange = true;
              } else if (
                targetPageBikou.bikou_array[i].bikou !==
                targetPrevPageBikou.bikou_array[i].bikou
              ) {
                hasChange = true;
              }
            }
            if (hasChange) {
              changesData.changeArray.push("ページ備考情報変更");
            }

            // 変更点があったら配列に格納する
            if (changesData.changeArray.length > 0) {
              this.changes.push(changesData);
            }
          }
        });
      });
      this.changes.sort((a, b) => a.startPage - b.startPage);

      // 色数/用紙/加工
      this.daiwariData.ori_array.forEach((daiwariOri) => {
        this.prevDaiwariData.ori_array.forEach((prevDaiwariOri) => {
          if (daiwariOri.ori_id === prevDaiwariOri.ori_id) {
            let changeArray = [];
            // 色数
            if (daiwariOri.sikisuu !== prevDaiwariOri.sikisuu) {
              changeArray.push("色数変更");
            }
            // 用紙
            if (daiwariOri.yousi !== prevDaiwariOri.yousi) {
              changeArray.push("用紙変更");
            }
            // 加工
            let kakouArray = [];
            daiwariOri.kakou_array.forEach((kakou) => {
              kakouArray.push(kakou.kbn);
            });
            let prevKakouArray = [];
            prevDaiwariOri.kakou_array.forEach((prevKakou) => {
              prevKakouArray.push(prevKakou.kbn);
            });
            const addedKakou = kakouArray.filter(
              (kakou) => prevKakouArray.indexOf(kakou) == -1
            );
            const deletedKakou = prevKakouArray.filter(
              (prevKakou) => kakouArray.indexOf(prevKakou) == -1
            );
            if (addedKakou.length > 0 || deletedKakou.length > 0) {
              changeArray.push("加工区分変更");
            }

            if (changeArray.length > 0) {
              this.changes.push({
                startPage: `折区分:${daiwariOri.ori_kbn}`,
                endPage: -1,
                sakuhinTitle: "",
                changeArray: changeArray,
              });
            }
          }
        });
      });

      // 収録作品位置入れ替わり
      // 追加・削除作品を除いて比較を行う
      let deleteIndexArray = [];
      this.sakuhinList.forEach((sakuhin, sakuhinIndex) => {
        addedSakuhin.forEach((added) => {
          if (sakuhin.sakuhin_bikouId === added.sakuhin_bikouId) {
            deleteIndexArray.push(sakuhinIndex);
          }
        });
      });
      let copySakuhinList = this.sakuhinList.map((obj) =>
        Object.assign({}, obj)
      );
      copySakuhinList = copySakuhinList.filter((elm, index) => {
        return (
          deleteIndexArray.find((deleteIndex) => deleteIndex === index) ===
          undefined
        );
      });

      let deletePrevIndexArray = [];
      this.prevSakuhinList.forEach((sakuhin, sakuhinIndex) => {
        deletedSakuhin.forEach((deleted) => {
          if (sakuhin.sakuhin_bikouId === deleted.sakuhin_bikouId) {
            deletePrevIndexArray.push(sakuhinIndex);
          }
        });
      });
      let copyPrevSakuhinList = this.prevSakuhinList.map((obj) =>
        Object.assign({}, obj)
      );
      copyPrevSakuhinList = copyPrevSakuhinList.filter((elm, index) => {
        return (
          deletePrevIndexArray.find((deleteIndex) => deleteIndex === index) ===
          undefined
        );
      });
      for (let i = 0; i < copySakuhinList.length; i++) {
        if (
          copySakuhinList[i].sakuhin_bikouId !==
          copyPrevSakuhinList[i].sakuhin_bikouId
        ) {
          this.changes.push({
            startPage: -1,
            endPage: -1,
            sakuhinTitle: "",
            changeArray: ["作品順序変更"],
          });
          break;
        }
      }

      // 総ページ数
      let totalPage = 0;
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (!sakuhin.toggleHyousiFlg) {
            totalPage =
              totalPage + sakuhin.sakuhin_address_computed_array.length;
          }
        });
      });
      let prevTotalPage = 0;
      this.prevDaiwariData.ori_array.forEach((prevOri) => {
        prevOri.sakuhin_array.forEach((sakuhin) => {
          if (!sakuhin.toggleHyousiFlg) {
            prevTotalPage =
              prevTotalPage + sakuhin.sakuhin_address_computed_array.length;
          }
        });
      });
      if (totalPage !== prevTotalPage) {
        this.changes.push({
          startPage: -1,
          endPage: -1,
          sakuhinTitle: "",
          changeArray: ["総ページ数変更"],
        });
      }

      // 制作基本情報内の備考情報
      let seisakuBikou = this.daiwariData.outputSyosiData.manualSyosiData.bikou;
      let prevSeisakuBikou =
        this.prevDaiwariData.outputSyosiData.manualSyosiData.bikou;
      if (seisakuBikou !== prevSeisakuBikou) {
        this.changes.push({
          startPage: -1,
          endPage: -1,
          sakuhinTitle: "",
          changeArray: ["制作基本情報備考変更"],
        });
      }

      // App.vueから呼び出した際に使用
      if (this.changes.length > 0) return true;
      else return false;
    },
    convertSakuhinMei(sakuhin) {
      if (
        "sakuhin_mei_display_compact" in sakuhin &&
        sakuhin.sakuhin_mei_display_compact !== ""
      ) {
        let changeSakuhinTitle = sakuhin.sakuhin_mei_display_compact.replace(
          "「",
          "『"
        );
        changeSakuhinTitle = changeSakuhinTitle.replace("」", "』");
        return changeSakuhinTitle;
      } else {
        return "作品名未設定";
      }
    },

    // 使ってない？
    validateDaiwari() {
      let alert_message_list = [];
      this.sakuhinList.forEach((sakuhin) => {
        // let sakuhin_no = index + 1;
        let error_array = [];
        if (sakuhin.sakuhin_mei == "") {
          error_array.push({ message: "・作品名を入力して下さい" });
        }
        if (sakuhin.tantou.length == 0) {
          error_array.push({ message: "・担当者を選択して下さい" });
        }
        if (sakuhin.ori_sikisuu == "") {
          error_array.push({ message: "・色数を選択して下さい" });
        }
        sakuhin.genka_array.forEach((genka) => {
          if (genka.genkaKbnId == 0) {
            error_array.push({ message: "・原価区分を選択して下さい" });
          }
          if (!genka.torihikisaki) {
            error_array.push({ message: "・取引先を選択して下さい" });
          }
          genka.genkaInfo_array.forEach((kbn) => {
            if (genka.genkaKbnId == kbn.genkaKbnId && genka.genkaKbnId !== 3) {
              if (kbn.suuryo == 0 || kbn.suuryo == "") {
                error_array.push({ message: "・数量を入力して下さい" });
              }
              if (kbn.tanka == 0 || kbn.tanka == "") {
                error_array.push({ message: "・単価を入力して下さい" });
              }
              if ("overwriteKingaku" in kbn && kbn.overwriteKingaku == 0) {
                error_array.push({ message: "・金額を入力して下さい" });
              } else if (
                "overwriteKingaku" in kbn == false &&
                (kbn.kingaku == 0 || kbn.kingaku == "")
              ) {
                error_array.push({ message: "・金額を入力して下さい" });
              }
            } else if (
              genka.genkaKbnId == kbn.genkaKbnId &&
              genka.genkaKbnId == 3
            ) {
              if (kbn.suuryo == 0 || kbn.suuryo == "") {
                error_array.push({ message: "・数量を入力して下さい" });
              }
              kbn.tankaInfo_array.forEach((tanka) => {
                if (sakuhin.ori_sikisuu == tanka.sikisuu) {
                  if ("overwriteTanka" in tanka && tanka.overwriteTanka == 0) {
                    error_array.push({ message: "・単価を入力して下さい" });
                  }
                  if (
                    "overwriteKingaku" in tanka &&
                    tanka.overwriteKingaku == 0
                  ) {
                    error_array.push({ message: "・金額を入力して下さい" });
                  }
                  if (
                    "overwriteTanka" in tanka == false &&
                    (tanka.tanka == 0 || tanka.tanka == "")
                  ) {
                    error_array.push({ message: "・単価を入力して下さい" });
                  }
                  if (
                    "overwriteKingaku" in tanka &&
                    (tanka.kingaku == 0 || tanka.kingaku == "")
                  ) {
                    error_array.push({ message: "・金額を入力して下さい" });
                  }
                }
              });
            }
          });
        });
        if (error_array.length > 0) {
          if (sakuhin.sakuhin_mei === "") {
            sakuhin.sakuhin_mei = "未設定";
          }
          alert_message_list.push({
            page: sakuhin.sakuhin_address_computed_array_withFlg[0],
            sakuhin_mei: sakuhin.sakuhin_mei,
            error_list: [...error_array],
          });
        }
      });
      return alert_message_list;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  // #overlay {
  //   z-index: 10000;
  // }
  .modal {
    width: 600px;
    height: 460px;
    &__message {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  .btn_orange {
    width: 150px;
    height: 40px;
  }
  .message_left {
    margin-right: auto;
  }
  .modal__message {
    .sakuhin_title {
      font-size: 1rem;
      font-weight: bold;
      p {
        text-align: center;
        .edaban_title {
          margin-left: 10px;
        }
      }
    }
  }

  .table-wrapper {
    overflow-y: auto;
    width: 70%;
    height: 150px;
    margin: 0 auto;
    .frame_table {
      // border-collapse: collapse;
      // border-spacing: 0;
      width: 100%;
      // margin: 0;
      // tbody {
      //   overflow-y: auto;
      // }

      // thead {
      //   z-index: 1;
      // }
      // td {
      //   white-space: nowrap;
      // }
      // th {
      //   white-space: nowrap;
      //   position: sticky;
      // top: 0;
      // left: 0;
      // }
      .new_version {
        background-color: #ffbeda;
      }
    }
  }
}
</style>
