<template>
  <div id="bikou_overlay">
    <p class="bikouArea_title">
      ページ情報
      <!-- カウント対象外の場合「未設定」と表示 -->
      <span
        class="bikouArea_subtitle_page"
        v-show="referenceBikouAddress !== -1"
        >({{ referenceBikouAddress }}P)</span
      >
      <span
        class="bikouArea_subtitle_page"
        v-show="referenceBikouAddress === -1"
        >(未設定)</span
      >
    </p>
    <p class="bikouArea_subtitle" v-show="selectedSakuhin.sakuhin_mei !== ''">
      {{ selectedSakuhin.sakuhin_mei }}
    </p>
    <p class="bikouArea_subtitle" v-show="selectedSakuhin.sakuhin_mei == ''">
      未設定
    </p>

    <textarea
      class="page_bikou"
      :disabled="isLockDaiwari"
      :class="isLockDaiwari ? 'disable_button--side' : ''"
      :value="bikou"
      @input="inputBikou"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    referenceBikou: String,
    referenceBikouAddress: Number,
    selectedSakuhin: Object,
    isLockDaiwari: Boolean,
  },
  emits: ["updatePageBikou"],
  data() {
    return {
      bikou: this.referenceBikou,
    };
  },
  methods: {
    inputBikou(e) {
      const bikouVal = e.currentTarget.value;
      this.bikou = bikouVal;
      this.$emit("updatePageBikou", this.bikou);
    },
  },
  computed: {
    bikouComp() {
      return this.referenceBikou;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #bikou_overlay {
    z-index: 5;
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    text-align: center;
    justify-content: right;
  }

  .bikouArea {
    &_title {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      padding: 10px 0;
    }
    &_subtitle {
      font-size: 16px;
      color: #fff;
      font-weight: normal;
      margin: 0 auto;
      padding-bottom: 10px;
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .page_bikou {
    height: 75%;
    margin: 0 auto;
    resize: none;
    width: 250px;
    background: #fff;
  }
}
</style>
