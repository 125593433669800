<template>
  <teleport to="body">
    <div class="Modal" v-show="isVisible" @click="closeModal"></div>
    <div class="Modal_Content" v-show="isVisible">
      <div class="Modal_Header">
        <span class="Modal_Header_Title">取置在庫照会</span>
        <font-awesome-icon
          @click="closeModal"
          class="Modal_Header_Close"
          icon="xmark"
        />
      </div>
      <div class="Modal_Main">
        <table class="Modal_Table">
          <thead>
            <tr>
              <th class="Modal_Table_Kanri">取置管理No</th>
              <th class="Modal_Table_Num">取置数</th>
              <th class="Modal_Table_Date">取置期間</th>
              <th class="Modal_Table_Reason">取置理由</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="Modal_Table_SelectList"
              v-for="(torioki, index) in torioki_list"
              :key="index"
              @click="selectToriokiList(torioki)"
              :class="{ Modal_Table_isSelected: torioki.selectedFlg }"
            >
              <td class="Modal_Table_Kanri">{{ torioki.toriokiKanriNo }}</td>
              <td class="Modal_Table_Num">{{ torioki.toriokiNum }}</td>
              <td class="Modal_Table_Date">{{ torioki.toriokiKikan }}</td>
              <td class="Modal_Table_Reason">{{ torioki.toriokiRiyuu }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="Modal_ButtonArea">
        <button
          :disabled="selectedToriokiList == null"
          @click="addToriokiInfo()"
          class="Modal_Button BtnGreen"
        >
          登録
        </button>
        <button @click="closeModal" class="Modal_Button BtnGreenRev">
          取消
        </button>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "setTorioki"],
  data() {
    return {
      selectedToriokiList: null,
      torioki_list: [
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
        {
          toriokiKanriNo: "AMAZON",
          toriokiNum: 292,
          toriokiKikan: "2022/12/31",
          toriokiRiyuu: "【Amazon用】優先引当専用",
          selectedFlg: false,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    selectToriokiList(torioki) {
      this.torioki_list.forEach((torioki) => {
        torioki.selectedFlg = false;
      });
      torioki.selectedFlg = true;
      this.selectedToriokiList = torioki;
    },
    addToriokiInfo() {
      this.$emit("setTorioki", this.selectedToriokiList);
      this.closeModal();
    },
  },
};
</script>

<style scoped lang='scss'>
.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:1000;
  &_ButtonArea {
    margin: 10px 0;
    text-align: right;
  }
  &_Button {
    padding: 0 10px;
    font-size: 12px;
    height: 30px;
    border-radius: 5px;
    font-weight: 800;
    height: 35px;
    width: 120px;
    font-size: 14px;
    &.BtnGreen {
      background: #20c997;
      color: #fff;
      margin-right: 5px;
    }
    &.BtnGreenRev {
      border: 1px solid #20c997;
      background: #fff;
      color: #20c997;
    }
  }
  &_Content {
    z-index:1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 600px;
    height: auto;
    border-radius: 5px;
    @media screen and (max-width: 650px) {
      width: 90%;
    }
  }
  &_Header {
    border-radius: 5px 5px 0 0;
    height: 60px;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    border-bottom: 1px dotted #b6b6b6;
    position: relative;
    &_Title {
      line-height: 50px;
      font-size: 20px;
      font-weight: 800;
    }
    &_Close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 40px;
      color: #20c997;
    }
  }
  &_Main {
    padding: 0 20px;
    margin: 0 auto;
    height: 350px;
    overflow-y: auto;
    overflow-y: scroll;
    width: 100%;
  }
  &_Table {
    margin: 0 0 10px 0;
    border-collapse: separate;
    border-spacing: 0.8px;
    width: 500px;
    th {
      // font-size: 13px;
      height: 40px;
      padding: 0 10px;
      // border: 1px solid #fff;
      background: #2a3f54;
      color: #fff;
      position: sticky;
      top: 0;
    }
    td {
      // font-size: 12px;
      height: 40px;
      padding: 0 5px;
      border-left: 1px solid #848484;
      border-bottom: 1px solid #848484;
      // background: #fff;
      text-align: center;
      &:last-child {
        border-right: 1px solid #848484;
      }
    }
    &_isSelected {
      background: #eafdf8;
    }
    &_SelectList {
      cursor: pointer;
      &:hover {
        background: #eafdf8;
      }
    }
    &_Kanri {
      width: 100px;
    }
    &_Num {
      width: 100px;
    }
    &_Date {
      width: 100px;
    }
    &_Reason {
      width: 240px;
    }
  }
}
</style>