<template>
  <!-- <div
    class="sakuhin"
    :class="[
      oriMaximumPage == 16 ? sakuhinAreaMedium : sakuhinAreaSmall,
      {
        edit_selected: sakuhin.is_selected_sakuhin,
      },
    ]"
  > -->
  <div
    class="sakuhin"
    :class="[
      sakuhinArea,
      {
        edit_selected: sakuhin.is_selected_sakuhin,
      },
    ]"
  >
    <!-- 備考アイコン -->
    <span
      v-if="
        'sakuhin_bikou' in sakuhin &&
        sakuhin.sakuhin_bikou !== '' &&
        (!sakuhin.is_divided || (sakuhin.is_divided && sakuhin.is_parent))
      "
      @mouseover="mouseOverAction(sakuhin)"
      @mouseleave="mouseLeaveAction(sakuhin)"
      class="bikou_icon"
      :class="{ bikou_highlight: sakuhin.sakuhin_bikou_markflg }"
    >
      <font-awesome-icon :icon="['fas', 'comment-dots']" />
    </span>

    <!-- 備考テキスト -->
    <div v-if="sakuhin.sakuhin_bikou_flg" class="bikou_text">
      <p>{{ sakuhin.sakuhin_bikou }}</p>
    </div>

    <!-- 前方作品追加ボタン -->
    <span
      v-if="
        sakuhin.is_selected_sakuhin &&
        (sakuhin.is_divided == false ||
          (sakuhin.is_divided && sakuhin.is_parent)) &&
        !isLockDaiwari
      "
      :class="{ add_sakuhin__before: sakuhin.is_selected_sakuhin }"
      @click="add_sakuhin_before(sakuhin)"
      >+</span
    >

    <!-- 後方作品追加ボタン -->
    <span
      v-if="
        sakuhin.is_selected_sakuhin &&
        sakuhin.addSakuhinAfterFlg == true &&
        !isLockDaiwari
      "
      :class="{ add_sakuhin__after: sakuhin.is_selected_sakuhin }"
      @click="add_sakuhin_after(sakuhin)"
      >+</span
    >

    <div class="sakuhin_address_container" @click="onClickSakuhin($event)">
      <template
        v-for="(
          sakuhinAddress, index
        ) of sakuhin.sakuhin_address_computed_array"
      >
        <div
          v-if="!includesPages_doNotCount"
          class="sakuhin_address"
          :key="sakuhinAddress"
          @click="openPageBikou(sakuhin, index, sakuhinAddress)"
          :class="[
            sakuhin.sakuhin_address_computed_array.length != 1 &&
            index != sakuhin.sakuhin_address_computed_array.length - 1
              ? 'sakuhin_address_border_right'
              : '',
            sakuhin_page_bikou_array &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1] &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1]
              .bikou_array &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1].bikou_array[
              sakuhin.startPage + index
            ] &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1].bikou_array[
              sakuhin.startPage + index
            ].isHighlighted
              ? 'active_bikou'
              : '',
            color(sakuhin, index),
            color_preview(sakuhin, index),
          ]"
        >
          {{ sakuhinAddress }}
        </div>
        <!-- カウント対象外が含まれる場合 -->
        <div
          v-if="includesPages_doNotCount"
          class="sakuhin_address"
          :key="sakuhinAddress + '_null'"
          @click="
            openPageBikou(
              sakuhin,
              index,
              sakuhinAddres_withFlg(
                sakuhin.sakuhin_address_computed_array_withFlg,
                index
              )
            )
          "
          :class="[
            sakuhin.sakuhin_address_computed_array.length != 1 &&
            index != sakuhin.sakuhin_address_computed_array.length - 1
              ? 'sakuhin_address_border_right'
              : '',
            sakuhin.sakuhin_address_computed_array.length === 0
              ? 'sakuhin_address_border_right__blanc'
              : '',
            sakuhin_page_bikou_array &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1] &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1]
              .bikou_array &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1].bikou_array[
              sakuhin.startPage + index
            ] &&
            sakuhin_page_bikou_array[sakuhin.orderOfTheSakuhin - 1].bikou_array[
              sakuhin.startPage + index
            ].isHighlighted
              ? 'active_bikou'
              : '',
            color(sakuhin, index),
            color_preview(sakuhin, index),
          ]"
        >
          <span
            class="sakuhin_address_clickArea"
            v-show="sakuhin.sakuhin_address_computed_array_withFlg.length"
            >{{ sakuhin.sakuhin_address_computed_array_withFlg[index] }}</span
          >
          <span
            class="sakuhin_address_blanc sakuhin_address_clickArea"
            v-if="
              !sakuhin.sakuhin_address_computed_array_withFlg.length &&
              !isOpenPageRangeOverlay
            "
            >.</span
          >
          <!-- ページ範囲オーバーレイを開いているときだけ「＊ノンブル」を表示 -->
          <span
            class="sakuhin_address_clickArea"
            v-if="
              !sakuhin.sakuhin_address_computed_array_withFlg.length &&
              isOpenPageRangeOverlay
            "
            >{{
              daiwariAddressArray[sakuhin.sakuhin_index_computed_array[index]]
            }}</span
          >
        </div>
      </template>
    </div>
    <div
      class="sakuhin_infoContainer"
      @click="onClickSakuhin($event)"
      :class="{
        st_kikaku: sakuhin.sinkou_status == 'Ⅰ.企画立案',
        st_genkou: sakuhin.sinkou_status == 'Ⅱ.原稿制作',
        st_kumihan: sakuhin.sinkou_status == 'Ⅲ.組版・製版',
        st_kouryo: sakuhin.sinkou_status == 'Ⅳ.校了',
        st_insatu: sakuhin.sinkou_status == 'Ⅴ.印刷',
        st_genka: sakuhin.sinkou_status == 'Ⅵ.原価登録',
      }"
    >
      <span
        :class="[
          sakuhinDisplayName(sakuhin) !== '' ? 'sakuhin_mei' : '',
          sakuhinDisplayName(sakuhin) === '' ? 'sakuhin_mei_blanc' : '',
        ]"
      >
        <!-- 複写照会や履歴照会の参照時は、created()時のsakuhin_mei_display初期設定が出来ないため、テンプレート上で整形する -->
        {{
          sakuhinDisplayName(sakuhin) !== "" ? sakuhinDisplayName(sakuhin) : "."
        }}
      </span>

      <!-- ↓要素は存在していてほしいため空のspanを設定 -->
      <span v-if="!sakuhin.tantou.length" class="tantou_container_null"></span>
      <span v-if="sakuhin.tantou.length" class="tantou_container">
        <template v-for="(tantou, index) in sakuhin.tantou" :key="index"
          >{{ tantou.user_last_name
          }}<span
            v-show="
              sakuhin.tantou.length === 0 || index !== sakuhin.tantou.length - 1
            "
            >,</span
          ></template
        >
      </span>
      <!-- <br /> -->
      <span class="page_container">
        <template v-if="sakuhin.is_divided && sakuhin.divided_page !== 0"
          >{{ truncateDispName(sakuhin.divided_page) }}p /</template
        >
        {{ truncateDispName(sakuhin.total_page) }}p
      </span>
    </div>
  </div>
</template>

<script>
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";

export default {
  name: "Sakuhin",
  props: {
    sakuhin: Object,
    ori: Object,
    includesPages_doNotCount: Boolean,
    oriMaximumPage: Number,
    onClickAddSakuhinBefore: Function,
    onClickAddSakuhinAfter: Function,
    onClickOpenPageBikou: Function,
    isLockDaiwari: Boolean,
    sakuhin_page_bikou_array: Array,
    page_range_bikou_array: Array,
    daiwariAddressArray: Array,
    ori_array: Array,
    isOpenPageRangeOverlay: Boolean,
    previewPageRangeData: Object,
  },
  emits: ["toggleSakuhinBikouFlg", "toggleSakuhinBikouFlg", "onClickSakuhin"],
  data() {
    return {
      sakuhinAreaMedium:
        "sakuhin_area_medium_" +
        this.sakuhin.sakuhin_address_computed_array.length,
      sakuhinAreaSmall:
        "sakuhin_area_small_" +
        this.sakuhin.sakuhin_address_computed_array.length,
      sakuhinAreaLarge:
        "sakuhin_area_large_" +
        this.sakuhin.sakuhin_address_computed_array.length,
    };
  },
  methods: {
    add_sakuhin_before(sakuhin) {
      this.onClickAddSakuhinBefore(sakuhin);
    },
    add_sakuhin_after(sakuhin) {
      this.onClickAddSakuhinAfter(sakuhin);
    },
    mouseOverAction(sakuhin) {
      this.$emit("toggleSakuhinBikouFlg", sakuhin, true);
    },
    mouseLeaveAction(sakuhin) {
      this.$emit("toggleSakuhinBikouFlg", sakuhin, false);
    },
    onClickSakuhin(event) {
      this.$emit("onClickSakuhin", event, this.sakuhin.sakuhin_index);
    },
    openPageBikou(sakuhin, index, address) {
      const bikouPageIndex = index + 1;
      const bikouPageAddress = address;

      //引数のsakuhinのindex+1番目のページに備考を設定する
      this.onClickOpenPageBikou(sakuhin, bikouPageIndex, bikouPageAddress);
    },
    truncateDispName(value) {
      const ommision = "...";
      let length = 5;
      // valueが数値の場合がある為、文字列に変換
      value = "" + value;
      if (value.length <= length) {
        return value;
      }
      return value.substring(0, length) + ommision;
    },
    // 作品エリアプロパティに各値をセット
    setSakuhinArea() {
      if (this.oriMaximumPage == 16) {
        this.sakuhinAreaMedium =
          "sakuhin_area_medium_" +
          this.sakuhin.sakuhin_address_computed_array.length;
      } else if (this.oriMaximumPage == 32) {
        this.sakuhinAreaSmall =
          "sakuhin_area_small_" +
          this.sakuhin.sakuhin_address_computed_array.length;
      } else {
        this.sakuhinAreaLarge =
          "sakuhin_area_large_" +
          this.sakuhin.sakuhin_address_computed_array.length;
      }
    },
  },
  computed: {
    pageRangeBikouArray() {
      return this.page_range_bikou_array ? this.page_range_bikou_array : [];
    },
    active_plusIcon() {
      //折の上限貢数
      let maxPage = this.ori.oriMaximumPage_custom
        ? this.ori.oriMaximumPage_custom
        : this.oriMaximumPage;

      //現在の折貢数
      const sakuhinArray = this.ori.sakuhin_array;
      let oriTotalPage = sakuhinArray.reduce(function (totalPage, sakuhin) {
        return totalPage + sakuhin.sakuhin_address_computed_array.length;
      }, 0);
      if (maxPage <= oriTotalPage) {
        return false;
      } else {
        return true;
      }
    },
    sakuhinArea() {
      this.setSakuhinArea();
      if (this.oriMaximumPage === 16) {
        return this.sakuhinAreaMedium;
      } else if (this.oriMaximumPage === 32) {
        return this.sakuhinAreaSmall;
      } else {
        return this.sakuhinAreaLarge;
      }
    },
    sakuhinAddres_withFlg() {
      //カウント対象外の作品の場合、参照ページが無いため-1を返す
      return (sakuhin_address_computed_array_withFlg, index) => {
        if (sakuhin_address_computed_array_withFlg.length) {
          return sakuhin_address_computed_array_withFlg[index];
        } else {
          return -1;
        }
      };
    },
    color() {
      return (sakuhin, index) => {
        const targetIndex = sakuhin.sakuhin_index_computed_array
          ? sakuhin.sakuhin_index_computed_array[index]
          : null;
        let color = "";
        if (this.pageRangeBikouArray.length === 0) {
          return;
        }
        this.pageRangeBikouArray.forEach((bikou) => {
          if (bikou.addressIndexArray.includes(targetIndex)) {
            color = `bg_${bikou.color}`;
          }
        });
        return color;
      };
    },
    color_preview() {
      return (sakuhin, index) => {
        const targetIndex = sakuhin.sakuhin_index_computed_array
          ? sakuhin.sakuhin_index_computed_array[index]
          : null;
        if (
          targetIndex &&
          this.previewPageRangeData.previewPageRanges.includes(targetIndex)
        ) {
          return `bg_${this.previewPageRangeData.color}--preview`;
        }
      };
    },
    sakuhinDisplayName() {
      return (sakuhin) => {
        return convertSakuhinMeiFull(sakuhin);
      };
    },
  },
  watch: {
    "sakuhin.sakuhin_address_computed_array": function () {
      // 作品エリアを拡張（CSS変更）
      this.setSakuhinArea();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  // page 未設定
  .sakuhin_area_medium_ {
    background: orange;
    font-size: 11px;
    text-align: center;
    width: 100%;
    height: 100px;
    display: inline-block;
    flex-shrink: 0;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  .sakuhin_area_small_ {
    font-size: 11px;
    text-align: center;
    // width: 60px;
    width: 100%;
    height: 100px;
    display: inline-block;
    flex-shrink: 0;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  .sakuhin_area_large_ {
    font-size: 11px;
    text-align: center;
    // width: 60px;
    width: 100%;
    height: 100px;
    display: inline-block;
    flex-shrink: 0;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  // page 1~1000まで
  @for $i from 1 through 1000 {
    .sakuhin_area_medium_#{$i} {
      font-size: 10px;
      text-align: center;
      width: 100%;
      height: 90px;

      display: inline-block;
      flex-shrink: 0;
      border: solid 1px rgba(0, 0, 0, 0.2);
    }
  }

  @for $i from 1 through 1000 {
    .sakuhin_area_small_#{$i} {
      font-size: 10px;
      text-align: center;
      width: 100%;
      height: 90px;
      display: inline-block;
      flex-shrink: 0;
      border: solid 1px rgba(0, 0, 0, 0.2);
    }
  }

  @for $i from 1 through 1000 {
    .sakuhin_area_large_#{$i} {
      font-size: 10px;
      text-align: center;
      width: 100%;
      height: 90px;
      display: inline-block;
      flex-shrink: 0;
      border: solid 1px rgba(0, 0, 0, 0.2);
    }
  }

  .sakuhin_address_container {
    display: flex;
  }

  .sakuhin_address_container > .active_bikou {
    //詳細度を強くする
    border: 2px solid red;
  }

  .sakuhin:hover {
    cursor: pointer;
  }

  .sakuhin_address {
    width: 100%;
    height: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }

  .sakuhin_mei {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sakuhin_address_border_right {
    border-right: solid 1px rgba(0, 0, 0, 0.2);

    &__blanc {
      border-right: solid 0px rgba(0, 0, 0, 0.2);
      background-color: #97185e;
    }
  }

  .sakuhin_area:hover {
    cursor: pointer;
  }

  .st_kikaku {
    background-color: #ffccff;
  }

  .st_genkou {
    background-color: #ffffaa;
  }

  .st_kumihan {
    background-color: #e6ffe9;
  }

  .st_kouryo {
    background-color: #ffdbc9;
  }

  .st_insatu {
    background-color: #ffd5ec;
  }

  .st_genka {
    background-color: #e6e6fa;
  }

  .sakuhin_address_blanc {
    opacity: 0;
  }

  .sakuhin_mei_blanc {
    opacity: 0;
    display: block;
  }

  .sakuhin {
    position: relative;
  }

  .bikou_icon {
    position: absolute;
    display: inline-block;
    bottom: -12px;
    right: -12px;
    font-size: 15px;
    width: 30px;
    height: 30px;
    z-index: 900;
  }

  .bikou_text {
    word-break: break-all;
    white-space: normal;
    position: absolute;
    display: block;
    overflow: auto;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 80px;
    padding: 2px;
    text-align: left;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #2a3f54;
    z-index: 999;
  }

  .add_sakuhin__before {
    display: block;
    position: absolute;
    top: -15px;
    left: -15px;
    background: red;
    font-size: 15px;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    z-index: 1;
  }

  .add_sakuhin__after {
    display: block;
    position: absolute;
    top: -15px;
    right: -15px;
    background: red;
    font-size: 15px;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    z-index: 1;
  }

  .sakuhin_infoContainer {
    display: inline-block;
    width: 100%;
    height: calc(100% - 20px);

    span {
      height: 33.3%;
    }
  }

  .bikou_icon.bikou_highlight {
    color: #ff0000;
  }

  // 担当者名の省略表示
  .tantou_container {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    height: 15px;
    text-overflow: ellipsis;
  }

  .tantou_container_null {
    display: block;
    width: 100%;
    height: 15px;
  }

  .page_container {
    // display: inline-block;
    // width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bikou_text::-webkit-scrollbar {
    width: 5px;
  }

  .bikou_text::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* box-shadow: inset 0 0 2px rgb(0 0 0 / 30%); */
  }

  .bikou_text::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 50px;
  }

  //ページ範囲備考の背景色
  .sakuhin_address_container > .bg_pink {
    background-color: #ffd5ec;

    &--preview {
      border: 2px dashed pink;
    }
  }

  .sakuhin_address_container > .bg_yellow {
    background-color: #ffffaa;

    &--preview {
      border: 2px dashed yellow;
    }
  }

  .sakuhin_address_container > .bg_green {
    background-color: #e6ffe9;

    &--preview {
      border: 2px dashed green;
    }
  }

  .sakuhin_address_container > .bg_orange {
    background-color: #ffdbc9;

    &--preview {
      border: 2px dashed orange;
    }
  }

  .sakuhin_address_container > .bg_purple {
    background-color: #e6e6fa;

    &--preview {
      border: 2px dashed purple;
    }
  }

  .preview {
    border: 2px dashed gray;
    // background-image: repeating-linear-gradient(
    //   -45deg,
    //   #fff,
    //   #fff 6px,
    //   transparent 0,
    //   transparent 14px
    // );
  }
}
</style>
