import axios from "axios";
import { App, ref } from "vue";
import store from "../store/index";

export const axiosPlugin = {
  install: async (app: App) => {
    const axiosInstance = ref(
      axios.create({
        baseURL: "http://localhost:8080",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
    );
    app.config.globalProperties.$axios = axiosInstance.value;
    app.provide("$axios", axiosInstance.value);
    axios.interceptors.request.use(async (request) => {
      if (
        request.url?.includes("/auth/login") ||
        request.url?.includes("/account/resetPassword") ||
        request.url?.includes("tokenCheck")
      ) {
        return request;
      } else {
        try {
          await store.dispatch("login/validateToken");
          const isEffectiveToken = store.getters["login/getIsValidToken"];
          if (isEffectiveToken) {
            return request;
          } else {
            alert(
              "セッションがタイムアウトしました。再度ログインしてください。"
            );
            store.dispatch("login/logout");
            location.href = "/";
            return request;
          }
        } catch (error) {
          // alert("エラーが発生しました");
          console.log(error);
          return request;
        }
      }
    });
  },
};
