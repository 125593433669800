import { createStore, Store, useStore as baseUseStore } from "vuex";
import { InjectionKey } from "vue";
import { LoginModule } from "@/store/modules/login";
import { SearchModule } from "@/store/modules/search";
import { ConstantDataModule } from "@/store/modules/constantData";
// import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import { JuchuModule } from "@/store/modules/juchu";
import { KikakuNewModule, KikakuOldModule } from "./modules/kikaku_new";

//型定義
export type RootState = {
  version: string;
  baseUrl: string;
  newKikakuData: kikakuData;
  oldKikakuData: kikakuData;
  // stateKikakuData: kikakuData;
};

export type kikakuData = {
  title: string;
}
export default createStore({
  state: {
    version: "1.0.0",
    baseUrl: "",
    newKikakuData: {} as kikakuData,
    oldKikakuData: {} as kikakuData,
    // stateKikakuData: {} as kikakuData,
  },
  getters: {
    getBaseUrl: (state) => state.baseUrl,
  },
  mutations: {
    // 環境によってベースとなるURLを変える
    setBaseUrl(state) {
      if (location.href.includes("localhost")) {
        state.baseUrl = "http://localhost:8080";
      } else if (location.href.includes("52.193.176.11:8080")) {
        state.baseUrl = "http://52.193.176.11:8080";
      } else {
        state.baseUrl = "https://juchu-connect.net";
      }
    },
    updateKikakuData(state, payload) {
      state.newKikakuData ={...state.newKikakuData, ...payload}
    }
 
  },
  actions: {},
  modules: {
    login: LoginModule,
    search: SearchModule,
    constant: ConstantDataModule,
    juchu: JuchuModule,
    kikakunew: KikakuNewModule, 
    kikakuold: KikakuOldModule,
  },
  plugins: [
    createPersistedState({
      key: "wmsVuex",
    }),
  ],
});

export const key: InjectionKey<Store<RootState>> = Symbol();

export const useStore = () => {
  return baseUseStore(key);
};
