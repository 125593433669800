<template>
  <teleport to="body">
    <div class="Modal" @click="closeModal"></div>
    <div class="Modal_Content">
      <div class="Modal_Header">
        <span class="Modal_Header_Title">短冊データ入力</span>
        <font-awesome-icon
          @click="closeModal"
          class="Modal_Header_Close"
          icon="xmark"
        />
      </div>
      <div class="Modal_Main">
        <div class="Modal_MainArea">
          <!-- <div class="Modal_InputArea">
            <span class="Modal_InputArea_Title">短冊区分</span>
            <input class="Modal_InputArea_Input Input_M" list="tanzaku_kbn" />

            <datalist id="tanzaku_kbn">
              <option value="1:客注">1:客注</option>
              <option value="2:採用">2:採用</option>
              <option value="3:至急">3:至急</option>
              <option value="4:新予約">4:新予約</option>
              <option value="5:出次第">5:出次第</option>
            </datalist>
          </div>
          <div class="Modal_InputArea Modal_Flex">
            <div>
              <span class="Modal_InputArea_Title Modal_Flex_Item"
                >相手方担当者</span
              ><input
                class="Modal_InputArea_Input Input_M Modal_Flex_Item"
                type="text"
              />
            </div>
            <div>
              <span class="Modal_InputArea_Title">客注No</span
              ><input class="Modal_InputArea_Input Input_M" type="text" />
            </div>
          </div>
          <div class="Modal_InputArea">
            <span class="Modal_InputArea_Title">備考</span
            ><input class="Modal_InputArea_Input Input_M" type="text" />
          </div> -->
          <div class="Modal_InputArea">
            <span class="Modal_InputArea_Title">注文メモ</span
            ><input
              class="Modal_InputArea_Input Input_L"
              type="text"
              v-model="inputData.memo"
            />
            <p v-if="isValidTanzaku">
              <span class="Modal_InputArea_Error"
                >※「短冊メモ」は全角16文字以内で入力してください</span
              >
            </p>
          </div>
          <div class="Modal_ButtonArea">
            <button @click="addTanzakuInfo" class="Modal_Button BtnGreen">
              登録
            </button>
            <button @click="closeModal" class="Modal_Button BtnGreenRev">
              取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { Notyf } from "notyf";

const notyf = new Notyf();

export default {
  props: {
    selectedMeisai: {
      type: Object,
    },
  },
  emits: ["close", "addTanzakuInfo"],
  data() {
    return {
      inputData: {
        memo: "",
      },
    };
  },
  created() {
    this.inputData.memo =
      this.selectedMeisai !== undefined ? this.selectedMeisai.tanzaku : "";
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    isZenkaku(value) {
      // eslint-disable-next-line no-control-regex
      const pattern = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;
      return value.match(pattern);
    },
    addTanzakuInfo() {
      if (this.isValidTanzaku) notyf.error("入力内容を確認してください");
      else this.$emit("addTanzakuInfo", this.inputData);
    },
  },
  computed: {
    isValidTanzaku: function () {
      if (
        this.inputData.memo.length &&
        (this.inputData.memo.length > 16 ||
          !this.isZenkaku(this.inputData.memo))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  &_ButtonArea {
    margin: 10px 0;
    text-align: right;
  }
  &_Button {
    padding: 0 10px;
    font-size: 12px;
    height: 30px;
    border-radius: 5px;
    font-weight: 800;
    height: 35px;
    width: 120px;
    font-size: 14px;
    &.BtnGreen {
      @extend .common_btn_animation;
      background: #20c997;
      color: #fff;
      margin-right: 5px;
    }
    &.BtnGreenRev {
      @extend .common_btn_animation;
      border: 1px solid #20c997;
      background: #fff;
      color: #20c997;
    }
  }
  &_InputArea {
    background: #fff;
    margin: 5px 0;
    &_Title {
      display: inline-block;
      width: 100px;
      height: 40px;
      background: #2a3f54;
      color: #fff;
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      margin-right: 5px;
    }
    &_Input {
      border: 1px solid #848484;
      height: 30px;
      line-height: 15px;
      margin-right: 5px;
      &.Input_S {
        width: 40px;
      }
      &.Input_M {
        width: 120px;
      }
      &.Input_L {
        width: 350px;
        @media screen and (max-width: 650px) {
          width: 120px;
        }
      }
    }
    &_Error {
      color: red;
      font-size: 10px;
      display: inline-block;
      width: 275px;
    }
  }
  &_Content {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 520px;
    height: auto;
    border-radius: 5px;
    @media screen and (max-width: 650px) {
      width: 90%;
    }
  }
  &_Header {
    border-radius: 5px 5px 0 0;
    height: 60px;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    border-bottom: 1px dotted #b6b6b6;
    position: relative;
    &_Title {
      line-height: 50px;
      font-size: 20px;
      font-weight: 800;
    }
    &_Close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 40px;
      color: #20c997;
      :hover {
        opacity: 70%;
      }
      :active {
        opacity: 100%;
      }
    }
  }
  &_Main {
    padding: 18px;
    margin: 20px 0;
    background: #f8f8f8;
    width: 98%;
  }
  &_Flex {
    display: flex;
    @media screen and (max-width: 570px) {
      flex-wrap: wrap;
    }
    &_Item {
      margin-bottom: 5px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
s
