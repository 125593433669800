/* eslint-disable */
import { Ori } from "./Ori.js";
import { Sakuhin } from "./Sakuhin.js";
import * as PageRangeBikou from "./PageRangeBikou.js";
/**
 * 新Ver
 * 溢れた作品配列
 */
class OverflowedSakuhinInfo {
  constructor(
    overflowedSakuhinArray,
    overflowPage,
    ori_kbn,
    yousi,
    kakou_array
  ) {
    this.overflowedSakuhinArray = overflowedSakuhinArray;
    this.overflowPage = overflowPage;
    this.ori_kbn = ori_kbn;
    this.yousi = yousi;
    this.kakou_array = [...kakou_array];
  }
}

class SameSakuhinId {
  constructor(sameSakuhin, sameSakuhinId) {
    this.sameSakuhin = sameSakuhin;
    this.sameSakuhinId = sameSakuhinId;
  }
}

/**
 * 同じ作品のoriIndexとsakuhinIndexを保持する
 * 例) ori_array[X].sakuhin_array[y] ← x,yを保持する
 */
class SameSakuhinInfo {
  constructor(oriIndex, sakuhinIndex, sakuhinAddress) {
    this.oriIndex = oriIndex;
    this.sakuhinIndex = sakuhinIndex;
    this.sakuhinAddress = sakuhinAddress;
  }
}

/**
 * 台割データを再構築し、返却する
 *
 * @param {object} originDaiwariData
 * @return {object} newDaiwariData
 */

export function rebuildDaiwariData(
  originDaiwariData,
  maxAddress,
  maxSameGroupCd
) {
  // console.log("*rebuild始まり*");
  // console.trace();
  // console.log(
  //   "originDaiwariData:",
  //   JSON.parse(JSON.stringify(originDaiwariData))
  // );
  //◆変数
  let sakuhinAdArraySerial = 0;
  let sakuhinAddArraySerial_withFlg = 0;
  let newDaiwariData = { ori_array: [] };
  let overflowedSakuhinInfo = null;
  let overflowedFromOri = [];
  let isOverflowed = false;
  let isOverflowedSakuhin = false;
  let sameSakuhinId = null;
  let ori_totalPage = [];
  let overFlowPageCount = 0;
  //ページカウント対象外作品の有無
  originDaiwariData.includesPages_doNotCount = false;
  //台割内で何番目の作品か(作品ごとにカウントするので分断作品は含めない)
  let orderOfTheSakuhin = 0;
  let oriMaximumCount = originDaiwariData.oriMaximumPage;
  let prevOriId = ""; // ループ中に折が増えることによって同じ折を2回以上確認するのを防ぐのに使用
  let hasOnlyOverFlowedFromOri = false; // 折ループ内で新規折作成の際に溢れ折のみの折ができたらtrueにする（rebuild用フラグ）

  //◆関数

  /*
     【溢れの計算１】
     作品のページ数がoriMaximumPageを越えた場合に発火
     overflowedSakuhinInfoに作品と溢れたページ数を格納し、
     isOverflowedをtrueにすることで後続の折に処理を引き継ぐ
    */
  function calc_overflowedPage(sakuhin, ori_kbn, yousi, kakou_array) {
    let overflowPage =
      sakuhin.page - sakuhin.sakuhin_address_computed_array.length;
    if (!overflowPage <= 0) {
      sakuhin.is_divided = true;
      sakuhin.divided_page = sakuhin.sakuhin_address_computed_array.length;
      overflowedSakuhinInfo = new OverflowedSakuhinInfo(
        sakuhin,
        overflowPage,
        ori_kbn,
        yousi,
        kakou_array
      );
      isOverflowed = true;
    }
    //overflowPage=0でも次回ループに渡す
    sameSakuhinId = new SameSakuhinId(sakuhin, sakuhin.sakuhin_id);
  }

  /*
     【溢れの計算２】
     既にdividedしていた作品をさらに再計算する場合に発火
    */
  function calc_overflowedPageForDivided(
    sakuhin,
    preSakuhin,
    ori_kbn,
    yousi,
    kakou_array
  ) {
    //overflowpage計算用変数にこれまでのdividedPageを追加していく
    //今の作品と前の作品が異なる時はこれまでのdivided_pageをカウントしない
    if (is_matched_sakuhinId(preSakuhin, sakuhin)) {
      overFlowPageCount = overFlowPageCount + preSakuhin.divided_page;
    }
    //もし現時点で溢れたページ数がページ上限以上かつ子作品であれば、これまでの溢れページ分(overFlowPageCount)を加味する。
    let overflowPage; // あふれたページ数
    //3列以上
    if (
      !sakuhin.is_parent &&
      sakuhin.sakuhin_address_computed_array.length === oriMaximumCount
    ) {
      overflowPage =
        sakuhin.page -
        (overFlowPageCount + sakuhin.sakuhin_address_computed_array.length);
    }
    //2列
    else {
      overflowPage =
        sakuhin.page - sakuhin.sakuhin_address_computed_array.length;
    }
    sameSakuhinId = new SameSakuhinId(sakuhin, sakuhin.sakuhin_id);
    if (!overflowPage <= 0) {
      sakuhin.is_divided = true;
      sakuhin.divided_page = sakuhin.sakuhin_address_computed_array.length;
      overflowedSakuhinInfo = new OverflowedSakuhinInfo(
        sakuhin,
        overflowPage,
        ori_kbn,
        yousi,
        kakou_array
      );
      isOverflowed = true;
    }
  }

  /*
     【溢れが生じた際、後続にまだ作品が残っている場合の処理】
      後続作品をoverflowedFromOriに格納し
      isOverflowedSakuhinをtrueにすることで後続の折に処理を引き継ぐ。
      その後、元あった折から該当作品を削除する
    */
  function send_sameSakuhinArray_toThefollowingOri(ori, sakuhinIndex) {
    if (sakuhinIndex + 1 < ori.sakuhin_array.length) {
      ori.sakuhin_array.forEach((sakuhin, index) => {
        if (index > sakuhinIndex) {
          overflowedFromOri.push({
            ori_kbn: ori.ori_kbn,
            yousi: ori.yousi,
            kakou_array: ori.kakou_array,
            ...sakuhin,
          });
        }
      });
      if (overflowedFromOri.length !== 0) {
        isOverflowedSakuhin = true;
        ori.sakuhin_array.splice(sakuhinIndex + 1, overflowedFromOri.length);
      }
    }
  }

  //＿＿＿＿＿＿rebuildDaiwariDataの処理は以下から＿＿＿＿＿＿＿
  new Promise((resolve) => {
    //折配列のループ
    //上書き可能な折貢数上限計算用の変数(デフォルト16/32は上書きせず保持)
    for (const [oriIndex, ori] of originDaiwariData.ori_array.entries()) {
      // ↓forEachだと折を新規作成して増えた時に、増えた分のループに対応できないためfor文で対応
      // originDaiwariData.ori_array.forEach((ori, oriIndex) => {

      // console.log(`--${oriIndex + 1}番目の折---`);
      // console.log(
      //   "ループ中の折：",
      //   JSON.parse(JSON.stringify(ori)),
      //   ori.ori_kbn
      // );
      // console.log(
      //   "ori:",
      //   JSON.parse(JSON.stringify(originDaiwariData.ori_array))
      // );
      ori.ori_address_computed = oriIndex + 1; // 折の台割全体での通し番号を設定
      // oriMaximumPageの更新
      //折のページ上限がカスタム設定されている場合カスタム値を上限値として使用する
      oriMaximumCount = ori.oriMaximumPage_custom
        ? ori.oriMaximumPage_custom
        : originDaiwariData.oriMaximumPage;
      // console.log(
      //   `isOverflowedSakuhin:${JSON.parse(JSON.stringify(isOverflowedSakuhin))}`
      // );
      // console.log(`overflowedFromOri:${overflowedFromOri}`);
      // console.log(`isOverflowed:${JSON.parse(JSON.stringify(isOverflowed))}`);
      // console.log(`overflowedSakuhinInfo:${overflowedSakuhinInfo}`);

      if (prevOriId !== ori.ori_id) {
        // 折新規作成後、折を増やした分後ろに押された既存折が二重で処理されるのを防ぐ
        // 溢れの処理
        // [1．isOverflowedSakuhin]
        if (isOverflowedSakuhin) {
          let overflowedSakuhinFromOri = overflowedFromOri;
          // console.log(
          //   `溢れ1(isOverflowedSakuhin):${JSON.stringify(
          //     overflowedFromOri,
          //     null,
          //     2
          //   )}`
          // );
          //配列内にdivided作品がない場合
          if (!isOverflowed) {
            //元いた折の『色数or区分末尾or用紙or加工区分』と異なっていたら新たに折をつくる
            if (
              overflowedSakuhinFromOri[0].ori_sikisuu !== ori.sikisuu ||
              !checkMatchOriKbn(
                overflowedSakuhinFromOri[0].ori_kbn,
                ori.ori_kbn
              ) ||
              !checkMatchYousi(overflowedSakuhinFromOri[0].yousi, ori.yousi) ||
              !checkMatchKakouKbn(
                overflowedSakuhinFromOri[0].kakou_array,
                ori.kakou_array
              )
            ) {
              createOri_forsikikuu(
                originDaiwariData,
                overflowedSakuhinFromOri,
                maxAddress,
                oriIndex
              );

              // 2023/9/7 バグ修正のためコメントアウト。影響があるのかは現状不明。
              // このケースに該当する場合、一番最後にrebuildを走らせる処理を実装。
              //新しく作成した折にはその溢れた作品のみ格納されている場合にのみrebuild
              // if (
              //   originDaiwariData.ori_array[oriIndex].sakuhin_array.length ===
              //   overflowedSakuhinFromOri.length
              // ) {
              //   rebuildDaiwariData(
              //     originDaiwariData,
              //     maxAddress,
              //     maxSameGroupCd
              //   );
              // }
              if (
                originDaiwariData.ori_array[oriIndex].sakuhin_array.length ===
                overflowedSakuhinFromOri.length
              ) {
                hasOnlyOverFlowedFromOri = true;
              }
            } else {
              //元いた折の色数が同じであればリバースをして先頭に作品を追加
              let overflowedSakuhin = overflowedSakuhinFromOri.reverse();
              // dividedを解除する場合まず子作品を削除する
              overflowedSakuhin.forEach((sakuhin) => {
                originDaiwariData.ori_array.forEach((ori) => {
                  ori.sakuhin_array.forEach((saku, index) => {
                    if (is_matched_sakuhinId(sakuhin, saku)) {
                      ori.sakuhin_array.splice(index, 1);
                    }
                  });
                });
                sakuhin.is_divided = false;
                sakuhin.is_parent = false;
                sakuhin.divided_page = 0;
                ori.sakuhin_array.unshift(sakuhin);
              });
            }
          } else {
            //配列内にdivided作品がある場合
            overflowedSakuhinFromOri.forEach((sakuhin) => {
              // dividedを解除する場合まず子要素を削除する
              // is_dividedがfalseだったらそもそも子要素が存在しないので削除しなくてよい(？)
              if (sakuhin.is_divided) {
                originDaiwariData.ori_array.forEach((ori) => {
                  ori.sakuhin_array.forEach((saku, index) => {
                    if (is_matched_sakuhinId(sakuhin, saku)) {
                      ori.sakuhin_array.splice(index, 1);
                    }
                  });
                });
                sakuhin.is_divided = false;
                sakuhin.is_parent = false;
                sakuhin.divided_page = 0;
              }
            });
            //元いた折の『色数or区分末尾or用紙』と異なっていたら新たに折をつくる
            if (
              overflowedSakuhinFromOri[0].ori_sikisuu !== ori.sikisuu ||
              !checkMatchOriKbn(
                overflowedSakuhinFromOri[0].ori_kbn,
                ori.ori_kbn
              ) ||
              !checkMatchYousi(overflowedSakuhinFromOri[0].yousi, ori.yousi) ||
              !checkMatchKakouKbn(
                overflowedSakuhinFromOri[0].kakou_array,
                ori.kakou_array
              )
            ) {
              createOri_forsikikuu(
                originDaiwariData,
                overflowedSakuhinFromOri,
                maxAddress,
                oriIndex
              );
              //divided作品を配列の先頭に挿入
              let overFrowedSakuhin = JSON.parse(
                JSON.stringify(overflowedSakuhinInfo.overflowedSakuhinArray)
              );
              overFrowedSakuhin.sakuhin_address = ++maxAddress.sakuhin;
              overFrowedSakuhin.is_parent = false;

              overFrowedSakuhin.divided_page =
                overflowedSakuhinInfo.overflowPage;
              overFrowedSakuhin.page = overflowedSakuhinInfo.overflowPage;
              overFrowedSakuhin.sakuhin_address_computed_array.splice(
                0,
                overFrowedSakuhin.sakuhin_address_computed_array.length
              );
              for (let i = 0; i < overFrowedSakuhin.divided_page; i++) {
                overFrowedSakuhin.sakuhin_address_computed_array.push(i);
              }
              // ori_arrayのインデックスにoriIndexを指定しているため、新規作成後の折に作品を入れている
              originDaiwariData.ori_array[oriIndex].sakuhin_array.unshift(
                overFrowedSakuhin
              );
              isOverflowed = false; // ここでフラグをfalseにすること後の条件分岐に引っかからない
            } else {
              //元いた折の色数が同じであればリバースをして先頭にunshift
              let overflowedSakuhin = overflowedSakuhinFromOri.reverse();
              overflowedSakuhin.forEach((sakuhin) => {
                ori.sakuhin_array.unshift(sakuhin);
              });
            }
          }
          isOverflowedSakuhin = false;
          overflowedFromOri = [];
        }
        // [2．isOverflowed]
        if (isOverflowed) {
          let overFrowedSakuhin = JSON.parse(
            JSON.stringify(overflowedSakuhinInfo.overflowedSakuhinArray)
          );
          // console.log(
          //   `溢れ2(isOverflowed):『${overFrowedSakuhin.sakuhin_mei_display}』が途中で分断して次折に押し出されます`,
          //   overFrowedSakuhin
          // );
          overFrowedSakuhin.sakuhin_address = ++maxAddress.sakuhin;
          overFrowedSakuhin.is_parent = false;
          overFrowedSakuhin.divided_page = overflowedSakuhinInfo.overflowPage;
          overFrowedSakuhin.page = overflowedSakuhinInfo.overflowPage;
          overFrowedSakuhin.sakuhin_address_computed_array.splice(
            0,
            overFrowedSakuhin.sakuhin_address_computed_array.length
          );
          for (let i = 0; i < overFrowedSakuhin.divided_page; i++) {
            overFrowedSakuhin.sakuhin_address_computed_array.push(i);
          }
          //元いた折の『色数or区分末尾or用紙』と異なっていたら新たに折をつくる
          //【9/4メモ】ページ数を変えた時に同じ情報をもつ折同士にも関わらず、別折になってしまう原因はここ
          // 完全にあふれた折で新規折を作成したあとに、分断折の新規折を作成している。→それぞれ別のメソッドで折を作成している
          // if (originDaiwariData.ori_array[overFrowedSakuhin.parent_ori_address_computed - 1].sikisuu !== ori.sikisuu) {
          if (
            overFrowedSakuhin.ori_sikisuu !== ori.sikisuu ||
            !checkMatchOriKbn(overflowedSakuhinInfo.ori_kbn, ori.ori_kbn) ||
            !checkMatchYousi(overflowedSakuhinInfo.yousi, ori.yousi) ||
            !checkMatchKakouKbn(
              overflowedSakuhinInfo.kakou_array,
              ori.kakou_array
            )
          ) {
            createOri_forsikikuu_isOverFlowed(
              originDaiwariData,
              overFrowedSakuhin,
              oriIndex,
              maxAddress,
              maxSameGroupCd
            );
          } else {
            //溢れた作品を、今回ループの折の先頭に設定
            let overflowedSakuhinFromOri = overflowedFromOri;
            overflowedSakuhinFromOri.forEach((sakuhin) => {
              ori.sakuhin_array.unshift(sakuhin);
            });
            let sameSakuhinFlg = false;
            ori.sakuhin_array.forEach((sakuhin, index) => {
              if (is_matched_sakuhinId(overFrowedSakuhin, sakuhin)) {
                //sameSakuhinFlgの処理は無くても現状動くようになったのでコメントアウト
                sameSakuhinFlg = true;
              }
            });
            if (!sameSakuhinFlg) {
              ori.sakuhin_array.unshift(overFrowedSakuhin);
            }
          }
          // console.log(JSON.parse(JSON.stringify(ori)));
          isOverflowed = false;
        }

        // 折に格納された作品のページ数をカウントするための変数
        let oriTotalNumberOfPage = 0;
        /**
         * 元いた折の色数と異なっていたら新たに折をつくる
         */
        function createOri_forsikikuu(
          originDaiwariData,
          overflowedSakuhinFromOri,
          maxAddress,
          oriIndex
        ) {
          let newOri = new Ori();
          newOri.sikisuu = overflowedSakuhinFromOri[0].ori_sikisuu;
          newOri.yousi = originDaiwariData.ori_array[oriIndex - 1].yousi;
          newOri.ori_bikou =
            originDaiwariData.ori_array[oriIndex - 1].ori_bikou;
          newOri.ori_bikou_flg =
            originDaiwariData.ori_array[oriIndex - 1].ori_bikou_flg;
          newOri.kakou_array = [
            ...originDaiwariData.ori_array[oriIndex - 1].kakou_array,
          ];
          newOri.ori_id = getUniqueStr();
          overflowedSakuhinFromOri.forEach((sakuhin) => {
            sakuhin.sakuhin_address = ++maxAddress.sakuhin;
            // 折り返し元のカウントフラグを引き継ぎたいのでコメントアウト
            // sakuhin.pageCountFlg = true;
            sakuhin.sakuhin_address_computed_array_withFlg = [];
            //newOriの色数と、作品の色数が合致していればpush
            if (sakuhin.ori_sikisuu === newOri.sikisuu) {
              newOri.sakuhin_array.push(sakuhin);
            }
          });
          //newOriに作品がちゃんとあれば旧折と入れ替える
          if (newOri.sakuhin_array.length) {
            (newOri.ori_address = ++maxAddress.ori),
              // console.log("★1:createOri_forsikikuu"),
              // newOri.ori_kbn = (originDaiwariData.ori_array.length + 1) + 'K'
              (newOri.ori_kbn = new_ori_kbn(
                originDaiwariData.ori_array[oriIndex - 1]
              ));
            originDaiwariData.ori_array.splice(oriIndex, 0, newOri);
          }
        }

        //divided_page親要素の再計算
        function recalc_parentDividedPage(
          originDaiwariData,
          sakuhin,
          ori_totalPage,
          sakuhinIndex
        ) {
          //divided_page(親)の再計算
          sakuhin.divided_page = oriMaximumCount - ori_totalPage.length;
          // console.log("divided_page(親)の再計算", sakuhin.divided_page);
          if (sakuhin.divided_page >= sakuhin.total_page) {
            sakuhin.divided_page = 0;
            sakuhin.is_divided = false;
            for (let i = 0; i < sakuhin.page; i++) {
              //カウント対象の作品のみシリアルナンバーを振る
              if (sakuhin.pageCountFlg == true) {
                // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAddArraySerial_withFlg)
                sakuhin.sakuhin_address_computed_array_withFlg.push(
                  ++sakuhinAddArraySerial_withFlg
                );
              }
              // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAdArraySerial)
              sakuhin.sakuhin_address_computed_array.push(
                ++sakuhinAdArraySerial
              );

              ori_totalPage.push(++oriTotalNumberOfPage);
              sakuhin.total_page = sakuhin.page;
            }
            if (oriTotalNumberOfPage == oriMaximumCount) {
              //後続作品を次回ループへ
              send_sameSakuhinArray_toThefollowingOri(ori, sakuhinIndex);
              //溢れの計算
              calc_overflowedPage(
                sakuhin,
                ori.ori_kbn,
                ori.yousi,
                ori.kakou_array
              );
            }
          }
        }

        ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
          // console.log(ori);
          // console.log(`***作品ループ1-${sakuhinIndex + 1}回目***`);
          // console.log(JSON.parse(JSON.stringify(sakuhin)), sakuhin.sakuhin_mei_display);
          //前のループでis_dividedがtrueになり、sakuhin_idが渡ってきたときの処理
          if (sameSakuhinId) {
            if (is_matched_sakuhinId(sameSakuhinId.sameSakuhin, sakuhin)) {
              //前の同じidの作品が親要素、あるいは16Pだったとき(つまりループ中のsakhinが子作品に該当した場合)
              if (
                sameSakuhinId.sameSakuhin.is_parent == true ||
                sameSakuhinId.sameSakuhin.sakuhin_address_computed_array
                  .length == oriMaximumCount
              ) {
                //親要素のtotal_pageを引き継ぐ
                sakuhin.total_page = sameSakuhinId.sameSakuhin.total_page;
                sakuhin.page = sakuhin.total_page;

                if (!overflowedSakuhinInfo) {
                  sakuhin.divided_page =
                    sakuhin.total_page -
                    sameSakuhinId.sameSakuhin.sakuhin_address_computed_array
                      .length;
                  //何の削除？？16+2==18&&子作品
                  if (
                    sameSakuhinId.sameSakuhin.sakuhin_address_computed_array
                      .length +
                      sakuhin.divided_page ==
                      sakuhin.total_page &&
                    !sakuhin.is_parent
                  ) {
                    ori.sakuhin_array.splice(sakuhinIndex, 1);
                  }
                } else {
                  sakuhin.divided_page = overflowedSakuhinInfo.overflowPage;
                }
              } else if (
                sameSakuhinId.sameSakuhin.is_divided &&
                (sameSakuhinId.sameSakuhin.is_parent == false ||
                  sameSakuhinId.sameSakuhin.sakuhin_address_computed_array
                    .length <= oriMaximumCount)
              ) {
                //sameSakuhinが親じゃない＆折いっぱいに分断中でない限り、ループ中の作品が存在するはずがないので削除
                //作品IDが重複しているものがすべて削除されてしまう(意図して同じ作品を複数登録してもリビルドすると削除される可能性)
                if (!overflowedSakuhinInfo) {
                  ori.sakuhin_array.splice(sakuhinIndex, 1);
                }
              }
            }
            // sameSakuhinId = nullにしないことで引きおこるエラーは今のところない
            overflowedSakuhinInfo = null;
          }
        });

        ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
          // console.log(`***作品ループ2-${sakuhinIndex + 1}回目***`);
          // console.log(
          //   JSON.parse(JSON.stringify(sakuhin)),
          //   sakuhin.sakuhin_mei_display
          // );
          //ページカウント対象外
          if (!sakuhin.pageCountFlg) {
            originDaiwariData.includesPages_doNotCount = true;
          }

          //折の色数を保持する
          sakuhin.ori_sikisuu = ori.sikisuu;
          // ※ sakuhin_addressは不変。保存時に通し番号を割り振り、for文のkeyとして使用する。
          sakuhin.parent_ori_address_computed = ori.ori_address_computed;
          // [sakuhin] sakuhin_index（0始まりの折毎の作品配列index）
          sakuhin.sakuhin_index = sakuhinIndex;
          // [sakuhin] sakuhin_address_computed（1始まりの全作品の通し番号）
          sakuhin.sakuhin_address_computed = sakuhinIndex + 1;
          // [sakuhin] sakuhin_address_computed_arrayを設定（push前に旧配列削除）
          sakuhin.sakuhin_address_computed_array.splice(
            0,
            sakuhin.sakuhin_address_computed_array.length
          );
          //ページカウント対象外表示用
          sakuhin.sakuhin_address_computed_array_withFlg.splice(
            0,
            sakuhin.sakuhin_address_computed_array_withFlg.length
          );
          //作品番号付与
          sakuhin.orderOfTheSakuhin = orderOfTheSakuhin;

          //１：total_pageがdivided_pageを下回ったとき
          // →つまりdivideが解消されたということ
          if (
            sakuhin.divided_page > 0 &&
            sakuhin.divided_page >= sakuhin.total_page
          ) {
            // console.log("１：total_pageがdivided_pageを下回ったとき");
            //sakuhin.is_parent = false
            sakuhin.is_divided = false;
            sakuhin.page = sakuhin.total_page;
            sakuhin.divided_page = 0;
            sakuhin.startPage = 0;
            sameSakuhinId = new SameSakuhinId(sakuhin, sakuhin.sakuhin_id);
            //--以下の計算は以下の計算はアドレスの振り直しと溢れの計算---------------------------
            for (let i = 0; i < sakuhin.page; i++) {
              //カウント対象の作品のみシリアルナンバーを振る
              if (sakuhin.pageCountFlg) {
                // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAddArraySerial_withFlg)
                sakuhin.sakuhin_address_computed_array_withFlg.push(
                  ++sakuhinAddArraySerial_withFlg
                );
              }
              // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAdArraySerial)
              sakuhin.sakuhin_address_computed_array.push(
                ++sakuhinAdArraySerial
              );
              ori_totalPage.push(++oriTotalNumberOfPage);
              sakuhin.page = sakuhin.total_page;
              if (oriTotalNumberOfPage == oriMaximumCount) {
                //後続作品を次回ループへ
                send_sameSakuhinArray_toThefollowingOri(ori, sakuhinIndex);
                //溢れの計算
                calc_overflowedPage(
                  sakuhin,
                  ori.ori_kbn,
                  ori.yousi,
                  ori.kakou_array
                );
                break;
              }
            }
          }
          //２：sakuhin.divided_pageが1以上場合
          else if (sakuhin.divided_page > 0) {
            // console.log("２：sakuhin.divided_pageが1以上の場合");
            sakuhin.startPage = 0;

            //親作品の場合、新しい作品として作品番号を上書きする
            if (sakuhin.is_parent) {
              orderOfTheSakuhin++;
              sakuhin.orderOfTheSakuhin = orderOfTheSakuhin;
            }

            //1つ前の折の最後の作品が、現在の作品と異なる場合overFlowPageCountをリセットする
            let { preSakuhin, return_overFlowPageCount } =
              update_OverFlowpageCount(
                originDaiwariData.ori_array,
                sakuhin,
                overFlowPageCount
              );
            overFlowPageCount = return_overFlowPageCount;

            //親作品じゃない場合今何ページ目かをsakuhinのプロパティとして持たせる(備考ページハイライト用)
            if (!sakuhin.is_parent) {
              sakuhin.startPage = overFlowPageCount + preSakuhin.divided_page;
            }

            //divided_page(親)再計算処理
            if (
              sakuhin.is_parent === true &&
              sakuhin.total_page >= sakuhin.divided_page
            ) {
              recalc_parentDividedPage(
                originDaiwariData,
                sakuhin,
                ori_totalPage,
                sakuhinIndex
              );
            }
            if (preSakuhin) {
              if (
                sakuhinIndex == ori.sakuhin_array.length - 1 &&
                sakuhin.is_divided == true
              ) {
                decide_parentSakuhin(originDaiwariData, ori, sakuhin);
              }
            }

            //--以下の計算は以下の計算はアドレスの振り直しと溢れの計算-------------------------------
            for (let i = 0; i < sakuhin.divided_page; i++) {
              //ページカウント対象外の作品以外でシリアルナンバーを振る
              if (sakuhin.pageCountFlg) {
                //カウントしない作品があったら
                // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAddArraySerial_withFlg)
                sakuhin.sakuhin_address_computed_array_withFlg.push(
                  ++sakuhinAddArraySerial_withFlg
                );
              }
              // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAdArraySerial)
              sakuhin.sakuhin_address_computed_array.push(
                ++sakuhinAdArraySerial
              );

              ori_totalPage.push(++oriTotalNumberOfPage);
              sakuhin.page = sakuhin.total_page;
              if (oriTotalNumberOfPage == oriMaximumCount) {
                //後続作品を次回ループへ
                send_sameSakuhinArray_toThefollowingOri(ori, sakuhinIndex);
                // //溢れの計算
                if (preSakuhin) {
                  calc_overflowedPageForDivided(
                    sakuhin,
                    preSakuhin,
                    ori.ori_kbn,
                    ori.yousi,
                    ori.kakou_array
                  );
                } else {
                  calc_overflowedPage(
                    sakuhin,
                    ori.ori_kbn,
                    ori.yousi,
                    ori.kakou_array
                  );
                  break;
                }
              }
            }
            sameSakuhinId = new SameSakuhinId(sakuhin, sakuhin.sakuhin_id);
          }
          //３：sakuhin.divided_pageが0でsakuhin.pageが1以上の場合
          else if (sakuhin.page > 0 && sakuhin.divided_page == 0) {
            // console.log(
            //   "３：sakuhin.divided_pageが0でsakuhin.pageが1以上の場合"
            // );
            sakuhin.startPage = 0;

            //作品番号を上書き
            orderOfTheSakuhin++;
            sakuhin.orderOfTheSakuhin = orderOfTheSakuhin;
            //分断していないので無条件に親要素を一旦解除&念のためis_dividedをfalseに上書き
            sakuhin.is_parent = false;
            sakuhin.is_divided = false;

            //--以下の計算はアドレスの振り直しと溢れの計算--------------------------------------------------------------------------------------------
            for (let i = 0; i < sakuhin.page; i++) {
              //カウント対象の作品のみシリアルナンバーを振る
              if (sakuhin.pageCountFlg) {
                // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAddArraySerial_withFlg)
                sakuhin.sakuhin_address_computed_array_withFlg.push(
                  ++sakuhinAddArraySerial_withFlg
                );
              }
              // console.log(sakuhin.sakuhin_mei, 'のシリアルナンバー', ++sakuhinAdArraySerial)
              sakuhin.sakuhin_address_computed_array.push(
                ++sakuhinAdArraySerial
              );

              //折毎の作品のページ数のカウントを行う
              ori_totalPage.push(++oriTotalNumberOfPage);
              sakuhin.total_page = sakuhin.page;
              if (oriTotalNumberOfPage == oriMaximumCount) {
                //後続作品を次回ループへ
                send_sameSakuhinArray_toThefollowingOri(ori, sakuhinIndex);
                //溢れの計算-何ページ溢れるのか
                calc_overflowedPage(
                  sakuhin,
                  ori.ori_kbn,
                  ori.yousi,
                  ori.kakou_array
                );
                break;
              }
            }
            //分断された作品の最初の要素のis_parentをtrueにする
            if (
              sakuhinIndex == ori.sakuhin_array.length - 1 &&
              sakuhin.is_divided == true
            ) {
              sakuhin.is_parent = true;
            }
            //1つ前の折の最後の作品が、現在の作品と異なる場合overFlowPageCountをリセットする
            let { preSakuhin, return_overFlowPageCount } =
              update_OverFlowpageCount(
                originDaiwariData.ori_array,
                sakuhin,
                overFlowPageCount
              );
            overFlowPageCount = return_overFlowPageCount;

            //何ページ目かをsakuhinのプロパティとして持たせる(備考ページハイライト用)
            sakuhin.startPage = overFlowPageCount;

            //もし同じ折内の後続におなじ作品がいたら削除する
            if (sakuhin.sakuhin_address_computed !== ori.sakuhin_array.length) {
              if (
                is_matched_sakuhinId(
                  ori.sakuhin_array[sakuhin.sakuhin_address_computed],
                  sakuhin
                )
              ) {
                // console.log("重複した作品があります", sakuhin.sakuhin_mei);
                delete_duplicetedSakuhin_inSameOri(
                  originDaiwariData.ori_array,
                  ori,
                  sakuhin
                );
              }
            }
          }
        });

        // newDaiwariDataに折を1行セット
        ori_totalPage = [];
        newDaiwariData.ori_array.push(ori);
      }
      //最後の折で溢れが生じた際、折の生成と折り返し処理を行う
      if (oriIndex === originDaiwariData.ori_array.length - 1) {
        if (isOverflowed || isOverflowedSakuhin) {
          addOri_forDivided(maxAddress, originDaiwariData, maxSameGroupCd);
        }

        // 11/14 以前の改修でfor文に変更したので、折を増やすだけにする
        // if (isOverflowedSakuhin) {
        //   overflowedFromOri.forEach((sakuhin) => {
        //     if (sakuhin.is_divided == true) {
        //       //ここで一旦子要素を消さないと無駄な折が生成される
        //       originDaiwariData.ori_array.forEach((ori) => {
        //         ori.sakuhin_array.forEach((saku, index) => {
        //           if (is_matched_sakuhinId(sakuhin, saku)) {
        //             ori.sakuhin_array.splice(index, 1);
        //           }
        //         });
        //       });
        //       sakuhin.is_parent = false;
        //       sakuhin.is_divided = false;
        //       sakuhin.divided_page = 0;
        //       originDaiwariData.ori_array[oriIndex + 1].sakuhin_array.push(
        //         sakuhin
        //       );
        //     } else {
        //       originDaiwariData.ori_array[oriIndex + 1].sakuhin_array.push(
        //         sakuhin
        //       );
        //     }
        //   });
        //   rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
        // }
        // isOverflowed = false;
        // isOverflowedSakuhin = false;
        // overflowedFromOri = [];
      }
      // 前折比較用のidを更新
      prevOriId = ori.ori_id;
    }
    // });

    /**
     * @see {@link 該当コメント:2023/9/7 バグ修正のためコメントアウト}
     * ↑のコメントアウト分を以下でカバー
     */
    if (hasOnlyOverFlowedFromOri) {
      hasOnlyOverFlowedFromOri = false;
      rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
    }

    //propmise終わりを通知
    // console.log(JSON.parse(JSON.stringify(originDaiwariData)));
    resolve();
    setSakuhinIndexComputedArray(originDaiwariData.ori_array);
  }).then(() => {
    // console.log("rebuild終わりのthen");
    // console.log(JSON.parse(JSON.stringify(originDaiwariData)));
    checkOriMaxPage(maxAddress, originDaiwariData, maxSameGroupCd);
    checkPageCountNum(maxAddress, originDaiwariData, maxSameGroupCd);
    checkOriAddressComputed(maxAddress, originDaiwariData, maxSameGroupCd);
    sortOriKbn(originDaiwariData.ori_array);
    originDaiwariData.daiwariAddressArray = PageRangeBikou.sortDaiwariAddress(
      originDaiwariData.ori_array
    ); //台割の全アドレスを格納したアドレスを生成
  });
  return originDaiwariData;
}

/**
 * ページ上限増後、余白を詰める
 *
 */
export function shorten_sakuhin_toPrevOri(originDaiwariData) {
  // console.log(JSON.parse(JSON.stringify(originDaiwariData)));
  // 前折の余白ページ数とori_idを保持
  let prevOriInfo = {
    surplusPage: 0,
    ori_id: "",
  };
  // 余白を詰める処理
  for (const [oriIndex, ori] of originDaiwariData.ori_array.entries()) {
    // console.log(`${oriIndex + 1}回目の折ループ`);
    // console.log(JSON.parse(JSON.stringify(ori)));
    // console.log(prevOriInfo);
    // 折のページ上限数
    let oriMaximumPage = ori.oriMaximumPage_custom
      ? ori.oriMaximumPage_custom
      : originDaiwariData.oriMaximumPage;
    let surplusPage_forCalc = prevOriInfo.surplusPage; // 計算用の余白ページ、処理の中で減らしていく
    let targetPrevOri = originDaiwariData.ori_array.find(
      (ori) => ori.ori_id === prevOriInfo.ori_id
    );
    // console.log(
    //   targetPrevOri != undefined
    //     ? JSON.parse(JSON.stringify(targetPrevOri))
    //     : ""
    // );

    // 「最初の折ではない」かつ「前折に余白がある」かつ「折区分が前折と同一」の場合余白を詰める処理を行う
    if (
      oriIndex !== 0 &&
      prevOriInfo.surplusPage > 0 &&
      targetPrevOri.sikisuu == ori.sikisuu &&
      checkMatchOriKbn(targetPrevOri.ori_kbn, ori.ori_kbn) &&
      checkMatchYousi(targetPrevOri.yousi, ori.yousi) &&
      checkMatchKakouKbn(targetPrevOri.kakou_array, ori.kakou_array)
    ) {
      // console.log("詰める処理する", ori.sakuhin_array);
      // 削除する作品のindex（最後にまとめて削除する）
      const deleteSakuhinIndex = [];
      for (const [sakuhinIndex, sakuhin] of ori.sakuhin_array.entries()) {
        // console.log(`${sakuhinIndex + 1}回目の作品ループ`);
        // まだ前折に余白があれば詰める処理を行う
        if (surplusPage_forCalc > 0 && oriIndex !== 0) {
          // 条件① 前折の最後の作品とループ中の最初の作品が別
          if (
            !is_matched_sakuhinId(
              sakuhin,
              targetPrevOri.sakuhin_array[
                targetPrevOri.sakuhin_array.length - 1
              ]
            )
          ) {
            // console.log("作品が別");
            // 前折に詰める用の新規索引配列を作成する()
            let toShortenSakuhin = JSON.parse(JSON.stringify(sakuhin));
            // 一部値をリセットする（後ほど再設定）
            toShortenSakuhin.addSakuhinAfterFlg = false;
            toShortenSakuhin.divided_page = 0;
            toShortenSakuhin.is_divided = false;
            toShortenSakuhin.is_parent = false;
            toShortenSakuhin.startPage = 0;
            toShortenSakuhin.sakuhin_address_computed_array.splice(0);
            toShortenSakuhin.sakuhin_address_computed_array_withFlg.splice(0);
            toShortenSakuhin.sakuhin_index_computed_array.splice(0);
            // 前折の余白分のループを行い、computed_arrayの値を一つずつ移していく
            for (let i = surplusPage_forCalc; i > 0; i--) {
              surplusPage_forCalc--;
              toShortenSakuhin.sakuhin_address_computed_array.push(
                sakuhin.sakuhin_address_computed_array[0]
              );
              sakuhin.sakuhin_address_computed_array.splice(0, 1);
              toShortenSakuhin.sakuhin_address_computed_array_withFlg.push(
                sakuhin.sakuhin_address_computed_array_withFlg[0]
              );
              sakuhin.sakuhin_address_computed_array_withFlg.splice(0, 1);
              toShortenSakuhin.sakuhin_index_computed_array.push(
                sakuhin.sakuhin_index_computed_array[0]
              );
              sakuhin.sakuhin_index_computed_array.splice(0, 1);
              if (sakuhin.sakuhin_address_computed_array.length === 0) {
                break;
              }
            }
            // divided関連の計算
            // 条件① ループ中の作品が空だったら削除(divideしていない状態)
            if (sakuhin.sakuhin_address_computed_array.length === 0) {
              deleteSakuhinIndex.push(sakuhinIndex);
            }
            // 条件② ループ中の作品に余りがあったらdividedの計算(divideしている状態)
            else {
              // 前折に詰める配列のdividedの設定
              toShortenSakuhin.divided_page =
                toShortenSakuhin.sakuhin_address_computed_array.length;
              toShortenSakuhin.is_divided = true;
              toShortenSakuhin.is_parent = true;
              // toShortenSakuhin.startPage = 0;
              // ループ中作品のdividedの設定
              sakuhin.divided_page =
                sakuhin.sakuhin_address_computed_array.length;
              sakuhin.is_divided = true;
              sakuhin.is_parent = false;
              // sakuhin.startPage = toShortenSakuhin.divided_page;
            }
            // 前折に詰める
            targetPrevOri.sakuhin_array.push(toShortenSakuhin);
          }
          // 条件② 前折の最後の作品とループ中の最初の作品が同一
          else {
            // console.log("作品が同じ");
            // 前折の最後の作品に値を足していく
            const targetPrevSakuhin =
              targetPrevOri.sakuhin_array[
                targetPrevOri.sakuhin_array.length - 1
              ];
            // 前折の余白分のループを行い、computed_arrayの値を一つずつ移していく
            for (let i = surplusPage_forCalc; i > 0; i--) {
              surplusPage_forCalc--;
              targetPrevSakuhin.sakuhin_address_computed_array.push(
                sakuhin.sakuhin_address_computed_array[0]
              );
              sakuhin.sakuhin_address_computed_array.splice(0, 1);
              targetPrevSakuhin.sakuhin_address_computed_array_withFlg.push(
                sakuhin.sakuhin_address_computed_array[0]
              );
              sakuhin.sakuhin_address_computed_array_withFlg.splice(0, 1);
              targetPrevSakuhin.sakuhin_index_computed_array.push(
                sakuhin.sakuhin_index_computed_array[0]
              );
              sakuhin.sakuhin_index_computed_array.splice(0, 1);
              if (sakuhin.sakuhin_address_computed_array.length === 0) {
                break;
              }
            }
            // divided関連の計算
            // 条件① ループ中の作品が空だったら削除、dividedの計算
            if (sakuhin.sakuhin_address_computed_array.length === 0) {
              deleteSakuhinIndex.push(sakuhinIndex);
              // 前折に詰めた配列のdividedの設定
              // 前折最後の作品がdivideの親だったらdivide解消させる
              if (targetPrevSakuhin.is_parent) {
                targetPrevSakuhin.divided_page = 0;
                targetPrevSakuhin.is_divided = false;
                targetPrevSakuhin.is_parent = false;
                // targetPrevSakuhin.startPage = 0;
              }
              // 親じゃなければ更にその前に作品があるのでdivideは継続
              else {
                targetPrevSakuhin.divided_page =
                  targetPrevSakuhin.sakuhin_address_computed_array.length;
                // targetPrevSakuhin.startPage =
                //   targetPrevSakuhin.total_page - targetPrevSakuhin.divided_page;
              }
            }
            // 条件② ループ中の作品に余りがあったらdividedの計算
            // divideを解消させることはないので、divided_pageの計算のみ行う
            else {
              targetPrevSakuhin.divided_page =
                targetPrevSakuhin.sakuhin_address_computed_array.length;
              sakuhin.divided_page =
                sakuhin.sakuhin_address_computed_array.length;
            }
          }
        }
      }
      // 作品の削除
      ori.sakuhin_array = ori.sakuhin_array.filter((value, index) => {
        return deleteSakuhinIndex.indexOf(index) === -1;
      });
    }

    // 最後の処理
    let oriSakuhinTotalPage = 0;
    ori.sakuhin_array.forEach((sakuhin) => {
      if (sakuhin.is_divided) {
        oriSakuhinTotalPage += sakuhin.divided_page;
      } else {
        oriSakuhinTotalPage += sakuhin.total_page;
      }
    });
    // 折内の作品が空だったら、前折情報は余白ページ数のみ更新する(空折は削除するので参照対象から外す)
    if (oriSakuhinTotalPage == 0) {
      let prevOriTotalPage = 0;
      targetPrevOri.sakuhin_array.forEach((sakuhin) => {
        if (sakuhin.is_divided) {
          prevOriTotalPage += sakuhin.divided_page;
        } else {
          prevOriTotalPage += sakuhin.total_page;
        }
      });
      let prevOriMaximumPage = targetPrevOri.oriMaximumPage_custom
        ? targetPrevOri.oriMaximumPage_custom
        : originDaiwariData.oriMaximumPage;
      prevOriInfo.surplusPage = prevOriMaximumPage - prevOriTotalPage;
    } else {
      prevOriInfo.surplusPage = oriMaximumPage - oriSakuhinTotalPage;
      prevOriInfo.ori_id = ori.ori_id;
    }
  }

  // 各プロパティを整形する
  let overFlowPage = 0;
  for (const [oriIndex, ori] of originDaiwariData.ori_array.entries()) {
    for (const [sakuhinIndex, sakuhin] of ori.sakuhin_array.entries()) {
      if ("ori_kbn" in sakuhin) sakuhin.ori_kbn = ori.ori_kbn;
      sakuhin.ori_sikisuu = ori.sikisuu;
      sakuhin.parent_ori_address_computed = ori.ori_address_computed;
      sakuhin.sakuhin_address_computed = sakuhinIndex + 1;
      sakuhin.sakuhin_index = sakuhinIndex;
      if (sakuhin.is_parent) {
        sakuhin.startPage = 0;
        overFlowPage = sakuhin.divided_page;
      } else if (sakuhin.is_divided) {
        sakuhin.startPage = overFlowPage;
        overFlowPage += sakuhin.divided_page;
      } else {
        overFlowPage = 0;
        sakuhin.startPage = 0;
      }
    }
  }
  setSakuhinIndexComputedArray(originDaiwariData.ori_array);
  return originDaiwariData;
}

/**
 * 重複した作品があれば削除する
 *
 */
function delete_duplicetedSakuhin_inSameOri(ori_array, ori, sakuhin) {
  ori.sakuhin_array.splice(sakuhin.sakuhin_address_computed - 1, 1);
  //削除した作品が親であれば、親作品に準ずる子作品も削除する
  if (sakuhin.is_parent == true) {
    //削除した作品以降の作品配列を頭からみて、同じ作品IDが続いている間はその作品は削除、続かなくなったら処理終了
    //まず削除した作品以降の折のみを切り分ける
    let followingOriArray = ori_array.slice(
      sakuhin.parent_ori_address_computed,
      ori_array.length
    );
    followingOriArray.forEach((followingOri) => {
      //followingOriに削除した親作品とid合致する作品があれば削除対象なので削除処理を実行
      // followingOriに作品が追加されているときのみ
      if (
        followingOri.sakuhin_array.length > 0 &&
        is_matched_sakuhinId(followingOri.sakuhin_array[0], sakuhin)
      ) {
        followingOri.sakuhin_array.forEach((followingSakuhin) => {
          //削除対象は子作品であり、divideしている
          if (followingSakuhin.is_divided && !followingSakuhin.is_parent) {
            ori_array[
              followingSakuhin.parent_ori_address_computed - 1
            ].sakuhin_array.splice(
              followingSakuhin.sakuhin_address_computed - 1,
              1
            );
          }
        });
        //子作品の削除後、折が空になるようであればその折も削除する
        if (followingOri.sakuhin_array.length === 0) {
          ori_array.splice(followingOri.ori_address_computed - 1, 1);
        }
      }
    });
  }
}

/**
 * 元いた折の色数と異なっていたら新たに折をつくる(※元いた折の最後の作品が分断している場合)
 */
function createOri_forsikikuu_isOverFlowed(
  originDaiwariData,
  overFrowedSakuhin,
  oriIndex,
  maxAddress
) {
  let newOri = new Ori();
  newOri.ori_id = getUniqueStr();
  newOri.sikisuu = overFrowedSakuhin.ori_sikisuu;
  newOri.yousi = originDaiwariData.ori_array[oriIndex - 1].yousi;
  newOri.kakou_array = [
    ...originDaiwariData.ori_array[oriIndex - 1].kakou_array,
  ];
  newOri.ori_bikou = originDaiwariData.ori_array[oriIndex - 1].ori_bikou;
  newOri.ori_bikou_flg =
    originDaiwariData.ori_array[oriIndex - 1].ori_bikou_flg;
  overFrowedSakuhin.sakuhin_address = oriIndex + 1;
  overFrowedSakuhin.sakuhin_address = ++maxAddress.sakuhin;
  if (overFrowedSakuhin.ori_sikisuu === newOri.sikisuu) {
    newOri.sakuhin_array.push(overFrowedSakuhin);
    if (newOri.sakuhin_array.length) {
      newOri.ori_address = ++maxAddress.ori;
      //折り返し元の折区分末尾の値引き継ぐ
      // console.log("★2:createOri_forsikikuu_isOverFlowed");
      // const kbnAlpha = originDaiwariData.ori_array[oriIndex - 1].ori_kbn.includes('K') ? 'K' : 'R';
      // newOri.ori_kbn = (originDaiwariData.ori_array.length + 1) + kbnAlpha
      newOri.ori_kbn = new_ori_kbn(originDaiwariData.ori_array[oriIndex - 1]);
      originDaiwariData.ori_array.splice(oriIndex, 0, newOri);
    }
    // 折り返し元のカウントフラグを引き継ぎたいのでコメントアウト
    // overFrowedSakuhin.pageCountFlg = true;

    overFrowedSakuhin.sakuhin_address_computed_array_withFlg = [];
  }
}

/**
 * 1つ前の折の最後の作品が、現在の作品と異なる場合overFlowPageCountをリセットする
 */

function update_OverFlowpageCount(ori_array, sakuhin, overFlowPageCount) {
  //preSakuhinを参照して、totalPageCountの更新をする
  let preOriIndex = ori_array.findIndex((preOri, index) => {
    return index === sakuhin.parent_ori_address_computed - 2;
  });
  let preSakuhin;
  if (preOriIndex !== -1) {
    preSakuhin =
      ori_array[preOriIndex].sakuhin_array[
        ori_array[preOriIndex].sakuhin_array.length - 1
      ];
  }
  //overflow計算用の変数(2回以上折り返しが起きたときに、前回の折り返しで表示した分のページ数を加味する)
  //同じ作品同士ではない場合、overflowの計算用ページはリセット
  if (preSakuhin) {
    if (
      preSakuhin.sakuhin_uniqueId !== 0 &&
      preSakuhin.sakuhin_uniqueId !== sakuhin.sakuhin_uniqueId
    ) {
      overFlowPageCount = 0;
    } else if (
      preSakuhin.sakuhin_id !== -1 &&
      preSakuhin.sakuhin_id !== sakuhin.sakuhin_id
    ) {
      overFlowPageCount = 0;
    }
  } else {
    overFlowPageCount = 0;
  }
  return { preSakuhin, return_overFlowPageCount: overFlowPageCount };
}

// 最後の折で溢れが生じた際の折の処理
export function addOri_forDivided(
  maxAddress,
  originDaiwariData,
  maxSameGroupCd
) {
  let addOri = new Ori();
  // v-forのkeyであるori_addressが重複しないよう、既存のori_addresの最大値+1を設定
  addOri.ori_id = getUniqueStr();
  addOri.ori_address = ++maxAddress.ori;
  //折り返し元の折区分末尾の値引き継ぐ
  // console.log("★3:addOri_forDivided");
  // const kbnAlpha = originDaiwariData.ori_array[originDaiwariData.ori_array.length - 1].ori_kbn.includes('K') ? 'K' : 'R';
  // addOri.ori_kbn = addOri.ori_address + kbnAlpha
  addOri.ori_kbn = new_ori_kbn(
    originDaiwariData.ori_array[originDaiwariData.ori_array.length - 1]
  );
  addOri.sikisuu =
    originDaiwariData.ori_array[originDaiwariData.ori_array.length - 1].sikisuu;
  addOri.yousi =
    originDaiwariData.ori_array[originDaiwariData.ori_array.length - 1].yousi;
  addOri.ori_bikou =
    originDaiwariData.ori_array[
      originDaiwariData.ori_array.length - 1
    ].ori_bikou;
  addOri.ori_bikou_flg =
    originDaiwariData.ori_array[
      originDaiwariData.ori_array.length - 1
    ].ori_bikou_flg;
  addOri.kakou_array = [
    ...originDaiwariData.ori_array[originDaiwariData.ori_array.length - 1]
      .kakou_array,
  ];
  originDaiwariData.ori_array.push({ ...addOri });
  // rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
}

// 最終的にoriMaximumPageを超える折がないかのチェック、あればrebuild
export function checkOriMaxPage(maxAddress, originDaiwariData, maxSameGroupCd) {
  let maxPage = 0;
  let rebuildFlg = false;
  originDaiwariData.ori_array.forEach((ori) => {
    //oriMaximumPageの更新
    if (ori.oriMaximumPage_custom) {
      maxPage = ori.oriMaximumPage_custom;
    } else {
      maxPage = originDaiwariData.oriMaximumPage;
    }
    let oriTotalPage = 0;
    ori.sakuhin_array.forEach((sakuhin) => {
      if (sakuhin.is_divided) {
        oriTotalPage += sakuhin.divided_page;
      } else {
        oriTotalPage += sakuhin.page;
      }
    });
    if (maxPage < oriTotalPage) {
      rebuildFlg = true;
    }
  });
  if (rebuildFlg) {
    rebuildFlg = false;
    rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
  }
}

// 最終的にaddress_computed_arrayがnullの作品がないかチェック、あればrebuild
function checkPageCountNum(maxAddress, originDaiwariData, maxSameGroupCd) {
  let rebuildFlg = false;
  originDaiwariData.ori_array.forEach((ori) => {
    ori.sakuhin_array.forEach((sakuhin) => {
      if (
        sakuhin.pageCountFlg &&
        sakuhin.sakuhin_address_computed_array_withFlg.length == 0
      ) {
        rebuildFlg = true;
      }
    });
  });
  if (rebuildFlg) {
    rebuildFlg = false;
    rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
  }
}

// 最終的にori_address_computedが0の作品がないかチェック、あればrebuild
function checkOriAddressComputed(
  maxAddress,
  originDaiwariData,
  maxSameGroupCd
) {
  let rebuildFlg = false;
  originDaiwariData.ori_array.forEach((ori) => {
    if (ori.ori_address_computed === 0) {
      rebuildFlg = true;
    }
  });
  if (rebuildFlg) {
    rebuildFlg = false;
    rebuildDaiwariData(originDaiwariData, maxAddress, maxSameGroupCd);
  }
}

/*
 * divided_Page(親)の選定
 */
function decide_parentSakuhin(originDaiwariData, ori, sakuhin) {
  //1つ前の折と、1つ前の折内の最後の作品を見つける！
  let preOriIndex = originDaiwariData.ori_array.findIndex((preOri, index) => {
    return index === sakuhin.parent_ori_address_computed - 2;
  });
  let preSakuhin =
    originDaiwariData.ori_array[preOriIndex].sakuhin_array[
      originDaiwariData.ori_array[preOriIndex].sakuhin_array.length - 1
    ];

  //1つ前の折の最後の作品IDとIDが重複していなければ(divideした最初の作品なら)親要素を付与
  if (is_matched_sakuhinId(preSakuhin, sakuhin)) {
    //同じ折にdivideした同じ作品が存在したら削除用関数呼び出し(削除漏れ防止)idで絞らないとエラー発生
    let duplicatedSakuhinArray_inSameOri = ori.sakuhin_array.filter(
      (filterSakuhin) => {
        return (
          (sakuhin.sakuhin_uniqueId !== 0 &&
            sakuhin.sakuhin_uniqueId === filterSakuhin.sakuhin_uniqueId) ||
          (sakuhin.sakuhin_id !== -1 &&
            sakuhin.sakuhin_id === filterSakuhin.sakuhin_id)
        );
      }
    );
    sakuhin.is_parent = false;
    // console.log("確認", ori.sakuhin_array, duplicatedSakuhinArray_inSameOri);

    if (duplicatedSakuhinArray_inSameOri.length > 1) {
      sakuhin.is_parent = true;
      delete_duplicetedSakuhin_inSameOri(
        originDaiwariData.ori_array,
        ori,
        sakuhin
      );
    }
  } else {
    sakuhin.is_parent = true;
  }
}

//作品に、台割全体から見たページ毎のインデックスを格納した配列を追加する
export function setSakuhinIndexComputedArray(ori_array) {
  let indexCount = 0;
  ori_array.forEach((ori) => {
    ori.sakuhin_array.forEach((sakuhin) => {
      let sakuhin_index_computed_array = [];
      const addressArray = sakuhin.sakuhin_address_computed_array_withFlg.length
        ? sakuhin.sakuhin_address_computed_array_withFlg
        : sakuhin.sakuhin_address_computed_array;
      addressArray.forEach((address) => {
        sakuhin_index_computed_array.push(indexCount);
        indexCount++;
      });
      sakuhin.sakuhin_index_computed_array = sakuhin_index_computed_array;
    });
  });
  return ori_array;
}

/**
 * 折区分が一致するか判定する
 */

function checkMatchOriKbn(dividedKbn, nextKbn) {
  // 区分コード
  let dividedKbnCode = judgeKbnCode(dividedKbn);
  let nextKbnCode = judgeKbnCode(nextKbn);

  // 区分コードの判定
  function judgeKbnCode(kbn) {
    let kbnEnd = kbn.slice(-1);
    let kbnCode;
    if (kbnEnd.match(/R/)) {
      kbnCode = isNaN(kbn.slice(0, -1)) ? "aR" : "nR";
    } else if (kbnEnd.match(/K/)) {
      kbnCode = isNaN(kbn.slice(0, -1)) ? "aK" : "nK";
    } else if (kbn.indexOf("表") != -1) {
      kbnCode = "cover";
    } else {
      kbnCode = "others";
    }
    return kbnCode;
  }

  // 独立した折か否か判定
  let isIndependentOfDivided = judgeIsIndependent(dividedKbnCode);
  let isIndependentOfNext = judgeIsIndependent(nextKbnCode);

  function judgeIsIndependent(code) {
    if (
      code === "aR" ||
      code === "nR" ||
      code === "aK" ||
      code === "nK" ||
      code === "cover"
    ) {
      return true;
    } else {
      return false;
    }
  }

  // 新規折を作成するか判定
  // true:新規折を作成しない　false:新規折を作成
  // 自身も次の折も独立した折区分の場合
  if (isIndependentOfDivided && isIndependentOfNext) {
    // 折区分が一致している場合trueを返す
    if (dividedKbnCode === nextKbnCode) {
      return true;
    } else {
      return false;
    }
  }
  // 自身も次の折も独立していない折区分の場合
  else if (!isIndependentOfDivided && !isIndependentOfNext) {
    return true;
  }
  // どちらかが独立した折区分の場合
  else {
    return false;
  }
}

/**
 * 用紙が一致するか判定する
 */
function checkMatchYousi(dividedYousi, nextYousi) {
  const isMatchYousi = dividedYousi === nextYousi;
  return isMatchYousi;
}
/**
 * 加工区分が一致するか判定する
 */
function checkMatchKakouKbn(dividedKakou, nextKakou) {
  // dividedKakouには含まれるがnextKakouにはふくまれない加工区分を抽出
  const hasOnlyDividedKakou = dividedKakou.filter(
    (x) => nextKakou.filter((y) => y.kbn === x.kbn).length === 0
  );
  // nextKakouには含まれるがdividedKakouにはふくまれない加工区分を抽出
  const hasOnlyNextKakou = nextKakou.filter(
    (x) => dividedKakou.filter((y) => y.kbn === x.kbn).length === 0
  );
  // 加工区分が一致する場合は二つの配列が空
  if (hasOnlyDividedKakou.length > 0 || hasOnlyNextKakou.length > 0) {
    return false;
  } else {
    return true;
  }
}

/**
 * 同一折区分か判定する
 * (色数,区分末尾,用紙,加工区分の一致を一括で判定する　2023/10/05追加)
 *
 * @param {object} ori -比較対象折１
 * @param {object} comparisonOri -比較対象折２
 * ↑順不同
 *
 * @returns {boolean} true:折区分が同一　false:折区分が異なる
 */
export function bulkCheckOriKbn(ori, comparisonOri) {
  const isMatchSikisuu = ori.sikisuu === comparisonOri.sikisuu;
  const isMatchKbn = checkMatchOriKbn(ori.ori_kbn, comparisonOri.ori_kbn);
  const isMatchYousi = checkMatchYousi(ori.yousi, comparisonOri.yousi);
  const isMatchKakouKbn = checkMatchKakouKbn(
    ori.kakou_array,
    comparisonOri.kakou_array
  );
  if (isMatchSikisuu && isMatchKbn && isMatchYousi && isMatchKakouKbn) {
    return true;
  } else {
    return false;
  }
}

/**
 * 作品IDが合致するか
 * uniqueId!==0 and sakuhin_id==-1 →作品登録されていないもの
 */
export function is_matched_sakuhinId(comparisonSakuhin, sakuhin) {
  if (
    (sakuhin.sakuhin_uniqueId !== 0 &&
      comparisonSakuhin.sakuhin_uniqueId === sakuhin.sakuhin_uniqueId) ||
    (sakuhin.sakuhin_id !== -1 &&
      comparisonSakuhin.sakuhin_id === sakuhin.sakuhin_id)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * uniqueIdかsakuhinIdの使われている方を返す
 *
 */

export function sakuhinId_inUse(sakuhin) {
  if (sakuhin.sakuhin_uniqueId !== 0 && sakuhin.sakuhin_id === -1) {
    return sakuhin.sakuhin_uniqueId;
  } else {
    return sakuhin.sakuhin_id;
  }
}

/**
 * 保存前のフォーマット処理を行う
 * ・ori_address, sakuhin_addressを確定する
 * ・is_selectedを全てfalseにする
 *
 * @param {Object} daiwariData dataに設定の台割データ
 * @return {Object} フォーマットが完了した台割データ
 */
export function formatBeforeUpdate(daiwariData) {
  let oriLoopCnt = 1;
  let sakuhinLoopCnt = 1;

  daiwariData.ori_array.forEach((ori) => {
    // ori_addressを確定
    ori.ori_address = oriLoopCnt;
    ori.ori_address_computed = 0;
    // is_selectedをfalseに戻す
    ori.is_selected_ori = false;
    ori.sakuhin_array.forEach((sakuhin) => {
      // sakuhin_addressを確定
      sakuhin.sakuhin_address = sakuhinLoopCnt;
      sakuhin.sakuhin_address_computed = 0;
      // is_selectedをfalseに戻す
      sakuhin.is_selected_sakuhin = false;
      sakuhinLoopCnt++;
    });
    oriLoopCnt++;
  });
  return daiwariData;
}

/**
 * 数値チェック
 *
 * @param {String || number} numVal 入力値
 * @return {boolean} true(数値である) || false(数値ではない)
 */
export function isNumber(numVal) {
  // 0以上の整数のみ
  var pattern = /^([1-9]\d*|0)$/;
  // 数値チェック
  return pattern.test(numVal);
}

function sortOriKbn(ori_array) {
  let kbn_nR_array = [];
  let kbn_aR_array = [];
  let kbn_nK_array = [];
  let kbn_aK_array = [];
  let kbn_hyou_array = [];
  let kbn_ori_array = [];
  ori_array.forEach((ori, index) => {
    let kbnInfo = { oriIndex: "", oriKbn: "" };

    //折区分nR・aRのものだけをそれぞれ絞り込む
    if (ori.ori_kbn.match(/R/)) {
      let slice_kbn = ori.ori_kbn.slice(0, -1);
      if (isNaN(slice_kbn)) {
        //折の住所と区分を送る
        kbnInfo.oriIndex = index;
        kbnInfo.oriKbn = slice_kbn;
        kbn_aR_array.push(kbnInfo);
      } else {
        kbnInfo.oriIndex = index;
        kbnInfo.oriKbn = slice_kbn;
        kbn_nR_array.push(kbnInfo);
      }
    } else if (ori.ori_kbn.match(/K/)) {
      let slice_kbn = ori.ori_kbn.slice(0, -1);
      if (isNaN(slice_kbn)) {
        //折の住所と区分を送る
        kbnInfo.oriIndex = index;
        kbnInfo.oriKbn = slice_kbn;
        kbn_aK_array.push(kbnInfo);
      } else {
        kbnInfo.oriIndex = index;
        kbnInfo.oriKbn = slice_kbn;
        kbn_nK_array.push(kbnInfo);
      }
    } else if (ori.ori_kbn.match(/表/)) {
      kbnInfo.oriIndex = index;
      kbnInfo.oriKbn = ori.ori_kbn.slice(1);
      kbn_hyou_array.push(kbnInfo);
    } else if (ori.ori_kbn.match(/折/)) {
      kbnInfo.oriIndex = index;
      kbnInfo.oriKbn = ori.ori_kbn.slice(1);
      kbn_ori_array.push(kbnInfo);
    }
  });

  // 折区分「 aR」 の折をナンバリング
  let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  kbn_aR_array.forEach((kbn, index) => {
    if ("oriIndex" in kbn) {
      ori_array[kbn.oriIndex].ori_kbn = alphabet[index] + "R";
    }
  });

  //折区分「nR」の折をナンバリング
  kbn_nR_array.forEach((kbn, index) => {
    if ("oriIndex" in kbn) {
      ori_array[kbn.oriIndex].ori_kbn = index + 1 + "R";
    }
  });

  // 折区分「 aK」 の折をナンバリング
  let alphabet_K = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  kbn_aK_array.forEach((kbn, index) => {
    if ("oriIndex" in kbn) {
      ori_array[kbn.oriIndex].ori_kbn = alphabet_K[index] + "K";
    }
  });

  //折区分「nK」の折をナンバリング
  kbn_nK_array.forEach((kbn, index) => {
    if ("oriIndex" in kbn) {
      ori_array[kbn.oriIndex].ori_kbn = index + 1 + "K";
    }
  });

  // 折区分「n表」の折をナンバリング
  //ここにきて先頭が足りない
  // kbn_hyou_array.forEach((kbn, index) => {
  //     if ('oriIndex' in kbn) {
  //         ori_array[kbn.oriIndex].ori_kbn = '表' + (index + 1)
  //     }
  // })
}

export function getUrl() {
  if (location.href.includes("localhost")) {
    return "http://localhost:8080";
  } else if (location.href.includes("52.193.176.11:8080")) {
    return "http://52.193.176.11:8080";
  } else {
    return "https://juchu-connect.net";
  }
}
//sakuhin_id:-1の場合に一意のsakuhin_uniqueIDを付与
export function getUniqueStr() {
  let strong = 10000000000;
  return Math.floor(strong * Math.random()).toString(16);
}

//作品追加により折が生成された際、折り返し元の折区分を引き継いだ値を設定する
export function new_ori_kbn(pre_ori) {
  //折り返し元の折区分の値を引き継ぐ
  //折り返し元の頭文字に「数字」が含まれている場合、折区分は「nR」or「nK」
  //折り返し元の頭文字に「アルファベット」が含まれている場合、折区分は「aR」or「aK」
  const alphabetArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  //折り返し元の折区分の頭文字以外を切りとる
  const pre_kbnInitial = pre_ori.ori_kbn.slice(0, -1);
  let kbnInitial = "";

  //頭文字が数値の場合は、折り返し元の頭文字＋１した値を引き継ぐ
  if (isNumber(pre_kbnInitial)) {
    kbnInitial = Number(pre_kbnInitial) + 1;
  } else if (
    pre_kbnInitial.length === 1 &&
    alphabetArray.includes(pre_kbnInitial)
  ) {
    //数値以外かつA~Z(1文字)の場合は折り返し元のアルファベットの1つ後ろの値を引き継ぐ
    const alphaIndex = alphabetArray.findIndex(
      (alphabet) => alphabet === pre_kbnInitial
    );
    kbnInitial = alphabetArray[alphaIndex + 1];
    //折返しもとの頭文字がZの場合次のアルファベットが無いので空文字
    if (!kbnInitial) {
      kbnInitial = "";
    }
  }

  //折り返し元の語尾に「K」が含まれている場合、折区分は「nK」or「aK」
  //折り返し元の語尾に「K」が含まれていない場合、折区分は「nR」or「aR」
  let kbnLast = "";
  if (pre_ori.ori_kbn.includes("K")) {
    kbnLast = "K";
  } else if (pre_ori.ori_kbn.includes("R")) {
    kbnLast = "R";
  }
  // 折り返し元に「表」という文字が含まれている場合、折り返し元の折区分を引き継ぐ
  else if (pre_ori.ori_kbn.includes("表")) {
    kbnLast = pre_ori.ori_kbn;
  }
  return kbnInitial + kbnLast;
}
