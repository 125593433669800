export class Sakuhin {
  constructor() {
    this.divided_page = 0;
    this.genka_array = [];
    this.is_divided = false;
    this.is_parent = false;
    this.is_selected_sakuhin = false;
    this.orderOfTheSakuhin = 0;
    this.page = 1;
    this.pageCountFlg = true;
    this.page_input = 1;
    this.parent_ori_address_computed = 0;
    this.sakuhin_address = 0;
    this.sakuhin_address_computed = 0;
    this.sakuhin_address_computed_array = [];
    this.sakuhin_id = -1;
    this.sakuhin_index = 0;
    this.sakuhin_mei = "";
    this.sakuhin_bikou_flg = false;
    this.sakuhin_bikou_markflg = false;
    this.sakuhin_uniqueId = 0;
    this.same_group_cd = 0;
    this.same_sakuhin_info_array = [];
    this.sinkou_status = "";
    this.tantou = [];
    this.toggleHyousiFlg = false;
    this.total_page = 0;
    this.sakuhin_bikou = "";
    this.penName = [];
    this.sakuhin_mei_hosoku = "";
    this.genkoryo_settei = {};
    this.episode = null;

    //作品は同作品内で何ページ目か(divide中に何ページまで付与したか確認するため)
    this.startPage = 0;
    this.sakuhin_bikouId = 0;
  }
}


//作品表示名(フルバージョン)
export const convertSakuhinMeiFull = (sakuhin) => {
  // const omittion = sakuhin.sakuhin_mei && sakuhin.sakuhin_mei.length > 8 ? "..." : ""; //省略文字「...」
  const sakuhin_mei =
    sakuhin.sakuhin_mei === ""
      ? ""
      : `「${sakuhin.sakuhin_mei.substr(0, 15)}」`; //作品名(頭15文字)
  const sakuhin_mei_hosoku = sakuhin.sakuhin_mei_hosoku
    ? " " + sakuhin.sakuhin_mei_hosoku
    : ""; //タイトル補足
  const strPenname =
    sakuhin.penName && sakuhin.penName.length
      ? sakuhin.penName[0].penName.substr(0, 8)
      : ""; //ペンネーム(頭8文字)
  const penName_supplement =
    sakuhin.penName && sakuhin.penName.length > 1
      ? `,他${sakuhin.penName.length - 1}名`
      : ``; //他〇名
  const sakuhin_mei_full = `${
    strPenname + penName_supplement + sakuhin_mei + sakuhin_mei_hosoku
  }`;

  return sakuhin_mei_full;
};

//作品表示名(コンパクトバージョン)
export const convertSakuhinMeiCompact = (sakuhin) => {
  // const omittion = sakuhin.sakuhin_mei && sakuhin.sakuhin_mei.length > 8 ? "..." : ""; //省略文字「...」
  const sakuhin_mei =
    sakuhin.sakuhin_mei === ""
      ? ""
      : `「${sakuhin.sakuhin_mei.substr(0, 15)}」`; //作品名(頭15文字)
  const sakuhin_mei_hosoku = sakuhin.sakuhin_mei_hosoku
    ? " " + sakuhin.sakuhin_mei_hosoku
    : ""; //タイトル補足
  const sakuhin_mei_compact = `${sakuhin_mei + sakuhin_mei_hosoku}`;

  return sakuhin_mei_compact;
};

//作品表示名を一括で更新する
export const convertSakuhinMei = (sakuhin) => {
  sakuhin.sakuhin_mei_display = convertSakuhinMeiFull(sakuhin);
  sakuhin.sakuhin_mei_display_compact = convertSakuhinMeiCompact(sakuhin);

  return sakuhin;
};
