import { getUniqueStr } from "./util.js";

export class Ori {
  constructor() {
    this.ori_address = 0;
    this.ori_address_computed = 0;
    this.is_selected_ori = false;
    this.ori_kbn = "";
    this.sikisuu = "";
    this.yousi = "";
    this.kakou_array = [];
    this.sakuhin_array = [];
    this.ori_bikou = "";
    this.addSakuhinFlg = false;
    this.ori_bikou_active_flg = false;
    this.ori_id = getUniqueStr();
    this.is_duplicated_ori_kbn = false;
    this.ori_bikou_flg = false;
  }
}
