<template>
  <div>
    <div id="overlay" @click="hideModal">
      <div class="modal" @click="stopEvent">
        <div class="modal__header">
          <p v-if="penName_ikkatuFlg" class="modal__title">
            ペンネーム一括登録
          </p>
          <p v-else class="modal__title">ペンネーム検索</p>
          <font-awesome-icon
            :icon="['fas', 'window-close']"
            @click="hideModal"
            @keydown.enter="hideModal"
            class="modal__close"
            tabindex="0"
          />
        </div>
        <div class="modal__review">
          <!-- 取引先検索 -->
          <div class="modal__review__search-container">
            <input
              type="text"
              class="text_box"
              v-model="searchWord"
              @keydown.enter="search"
              ref="inputSearch"
            />
            <button class="btn_gray_small" @click="search">検索</button>
          </div>
          <!-- 作品に紐づくペンネーム一覧 -->
          <p
            class="modal__review__search-result"
            v-if="
              !(
                sakuhinInfoForPennameModal.sakuhinId === -1 ||
                sakuhinInfoForPennameModal.sakuhinId === null
              )
            "
          >
            <template
              v-if="
                searched_sakuhin_penName.penameList !== undefined &&
                searched_sakuhin_penName.penameList.length
              "
            >
              <span>
                「{{
                  sakuhinInfoForPennameModal.sakuhinMei
                }}」に紐づくペンネーム({{
                  searched_sakuhin_penName.penameList.length
                }}件)
              </span>
            </template>
            <template v-else>
              <span>
                「{{
                  sakuhinInfoForPennameModal.sakuhinMei
                }}」に紐づくペンネーム(0件)
              </span>
            </template>
          </p>
          <ul
            v-if="
              !(
                sakuhinInfoForPennameModal.sakuhinId === -1 ||
                sakuhinInfoForPennameModal.sakuhinId === null
              )
            "
            class="modal__review__result-container"
            style="min-height: 10%"
          >
            <template
              v-if="
                searched_sakuhin_penName.penameList !== undefined &&
                searched_sakuhin_penName.penameList.length
              "
            >
              <li
                class="modal__review__result-name result_sakuhin_penname"
                v-for="penNameData in searched_sakuhin_penName.penameList"
                :key="penNameData.pennameId"
                @click="addPenName(penNameData)"
                @keydown.enter="addPenName(penNameData)"
                tabindex="0"
              >
                {{ penNameData.penname }}
                <span
                  v-if="penNameData.pennameId < 0"
                  class="modal__review__result-torihikisakiMei"
                  >/仮ペンネーム</span
                >
                <span v-else class="modal__review__result-torihikisakiMei"
                  >/取引先名：{{ penNameData.torihikisakiMei }}</span
                >
              </li>
            </template>
            <li v-else style="list-style: none">検索結果はありません</li>
          </ul>
          <!-- ここまで -->
          <p
            class="modal__review__search-result"
            v-if="searched_penName && searched_penName.penameList"
          >
            <span v-if="!searched_penName.searchWord === ''"
              >「{{ searched_penName.searchWord }}」の</span
            >
            検索結果({{ searched_penName.totalRecords }}件)
          </p>
          <p
            class="modal__review__search-result"
            v-if="searched_penName && !searched_penName.penameList"
          >
            <span v-if="!searched_penName.searchWord === ''"
              >「{{ searched_penName.searchWord }}」の</span
            >
            検索結果({{ searched_penName.length }}件)
          </p>
          <p class="modal__review__search-result" v-if="searchResult_nullFlg">
            検索結果はありません
          </p>

          <!-- 取引先一覧表示 -->
          <ul
            class="modal__review__result-container"
            v-if="searched_penName && searched_penName.penameList"
          >
            <li
              class="modal__review__result-name"
              v-for="penNameData in searched_penName.penameList"
              :key="penNameData.pennameId"
              @click="addPenName(penNameData)"
              @keydown.enter="addPenName(penNameData)"
              tabindex="0"
            >
              {{ penNameData.penname }}
              <span
                v-if="penNameData.pennameId < 0"
                class="modal__review__result-torihikisakiMei"
                >/仮ペンネーム</span
              >
              <span v-else class="modal__review__result-torihikisakiMei"
                >/取引先名：{{ penNameData.torihikisakiMei }}</span
              >
            </li>
          </ul>
          <ul
            class="modal__review__result-container"
            v-if="searched_penName && !searched_penName.penameList"
          >
            <li
              class="modal__review__result-name"
              v-for="penNameData in searched_penName"
              :key="penNameData.pennameId"
              @click="addPenName(penNameData)"
              @keydown.enter="addPenName(penNameData)"
              tabindex="0"
            >
              {{ penNameData.penname }}
              <span
                v-if="penNameData.pennameId < 0"
                class="modal__review__result-torihikisakiMei"
                >/仮ペンネーム</span
              >
              <span v-else class="modal__review__result-torihikisakiMei"
                >/取引先名：{{ penNameData.torihikisakiMei }}</span
              >
            </li>
          </ul>
          <!-- ページング -->
          <Paginate
            class="pagenation"
            v-if="searched_penName && searched_penName.penameList"
            :page-count="searched_penName.totalPage"
            :hide-prev-next="true"
            :page-range="3"
            :margin-pages="1"
            :click-handler="getCurrentPage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-item--link'"
            :active-class="'page-item--active'"
          ></Paginate>
          <div class="modal__review__karipenname-container">
            <input v-model="useKariPenname" id="kari-check" type="checkbox" />
            <label for="kari-check">仮ペンネームを使用</label>
            <input
              v-model="inputKariPenname"
              :disabled="!useKariPenname"
              type="text"
              class="text_box kari-text"
            />
            <button
              v-if="useKariPenname"
              @click="createKariPenname(inputKariPenname)"
            >
              選択
            </button>
          </div>

          <!-- 選択済みの取引先を表示(ペンネームの場合) -->
          <ul class="modal__review__badge-container">
            <li
              class="modal__review__badge-selected"
              v-for="(penname, index) in comp_selected_penName"
              :key="index"
            >
              <span v-if="selected_penName.length">{{ penname.penName }}</span>
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="modal__review__badge-cancel"
                @click="removePenName(index)"
                @keydown.enter="removePenName(index)"
                tabindex="0"
              />
            </li>
          </ul>
          <button class="btn_blue" @click="updatePenName">決定</button>
        </div>
      </div>
    </div>
    <div
      v-if="isProcessing"
      class="processing-overlay"
      @click="clickProcessing"
    >
      <span class="processing-icon"></span>
      <span class="processing-message">処理中です</span>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";

export default {
  components: { Paginate },
  props: {
    selectedSakuhin: Object,
    torihikisakiIndex: Number,
    url: String,
    penName_ikkatuFlg: Boolean,
    kikakuId: Number,
    // 以下、原価台帳の取引先検索→ペンネーム検索変更時に追加
    modalOnModalName: String,
    sakuhinDataByDaicho: Object,
    daiwariData: Object,
    update_genkaIndex: Number,
    changeTorihikiToDaiwari: Function,
    editDaiwariGenkaTorihiki: Function,
    daiwariId: Number,
    // 作品に紐づくペンネーム関連
    sakuhinInfoForPennameModal: Object,
  },
  emits: [
    "hideModal",
    "count_timeOut",
    "updateTorihikisaki",
    "updatePenName",
    "",
  ],
  data() {
    return {
      searched_penName: null,
      selected_penName: [],
      mst_penName: [],
      searchWord: "",
      currentPage: 1,
      clickedPage: 1,
      showPages: 5,
      searchResult_nullFlg: false,
      // 以下、原価台帳の取引先検索→ペンネーム検索変更時に追加
      selected_penName_allData: {},
      isProcessing: false,
      // 仮ペンネーム関連
      useKariPenname: false,
      inputKariPenname: "",
      params: "",
      // 作品に紐づくペンネーム関連
      searched_sakuhin_penName: {},
    };
  },
  created() {
    // パラメータを取得
    this.params = new URL(location.href).searchParams;
    // 【原価台帳】作品に紐づく原価から開いた場合
    if (this.modalOnModalName === "modal_relatedGenka_torihikisaki") {
      const torihikisakiInfo = this.sakuhinDataByDaicho.torihikisaki;
      if (torihikisakiInfo !== "") {
        if ("penname" in torihikisakiInfo && "pennameId" in torihikisakiInfo) {
          this.selected_penName.push({
            penName: torihikisakiInfo.penname,
            pennameId: torihikisakiInfo.pennameId,
            torihikisakiId: torihikisakiInfo.torihikisakiId,
            isNewKariPenname: false,
          });
        }
      } else {
        this.selected_penName = [];
      }
      if (this.selected_penName.length) {
        this.searchWord = this.selected_penName[0].penName;
      }
    }

    // 【原価台帳】作品に紐づかない原価から開いた場合
    else if (this.modalOnModalName === "modal_independentGenka_torihikisaki") {
      const torihikisakiInfo =
        this.daiwariData.daiwari_genkaArray[this.update_genkaIndex]
          .torihikisaki;
      if (torihikisakiInfo !== "") {
        if ("penname" in torihikisakiInfo && "pennameId" in torihikisakiInfo) {
          this.selected_penName.push({
            penName: torihikisakiInfo.penname,
            pennameId: torihikisakiInfo.pennameId,
            torihikisakiId: torihikisakiInfo.torihikisakiId,
            isNewKariPenname: false,
          });
        }
      } else {
        this.selected_penName = [];
      }
      if (this.selected_penName.length) {
        this.searchWord = this.selected_penName[0].penName;
      }
    }

    // 通常ペンネーム検索の場合
    else {
      if (this.selectedSakuhin.penName.length) {
        this.selectedSakuhin.penName.forEach((p) => {
          this.selected_penName.push({
            penName: p.penName,
            pennameId: p.pennameId,
            torihikisakiId: p.torihikisakiId,
            isNewKariPenname: false,
            ...("isRelatedSakuhin" in p && {
              isRelatedSakuhin: p.isRelatedSakuhin,
            }),
          });
        });
      } else {
        this.selected_penName = [];
      }
      if (this.selectedSakuhin.penName.length && !this.penName_ikkatuFlg) {
        this.searchWord = this.selectedSakuhin.penName[0].penName;
      }
    }

    // ペンネーム検索
    axios
      .get(
        `${this.url}/get_penname_split?penname=${this.searchWord}&page=${
          this.currentPage
        }&isUnionKariMst=${this.params.get("hachuId") !== null ? false : true}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        this.searched_penName = response.data;
        this.searchResult_nullFlg = false;
        if (this.penName_ikkatuFlg) {
          axios
            .get(`${this.url}/get_daiwari_chosya?kikaku_id=${this.kikakuId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            })
            .then((response) => {
              this.searched_penName = response.data;
              if (this.searched_penName.length) {
                this.searchResult_nullFlg = false;
              } else {
                this.searchResult_nullFlg = true;
              }
            })
            .catch((error) => {
              alert("ペンネームデータが取得できませんでした\n" + error);
            });
        }
        this.$refs.inputSearch.focus();
      })
      .catch((error) => {
        alert("ペンネームデータが取得できませんでした\n" + error);
      });
    // 作品に紐づくペンネーム検索(作品設定がある時のみ実行)
    if (
      this.sakuhinInfoForPennameModal.sakuhinId !== -1 &&
      this.sakuhinInfoForPennameModal.sakuhinId !== null
    ) {
      axios
        .get(
          `${this.url}/get_penname_linked_sakuhin?sakuhin_id=${this.sakuhinInfoForPennameModal.sakuhinId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.searched_sakuhin_penName = response.data;
          if (this.searched_sakuhin_penName.penameList === null) {
            this.searched_sakuhin_penName.penameList = [];
          }
        })
        .catch((error) => {
          alert("ペンネームデータが取得できませんでした\n" + error);
        });
    }
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", this.modalOnModalName);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    clickProcessing(event) {
      event.preventDefault();
    },
    page_previous() {
      this.currentPage--;
      this.getPageInfo();
    },
    page_next() {
      this.currentPage++;
      this.getPageInfo();
    },
    page_number(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;
        this.getPageInfo();
      }
    },
    getPageInfo() {
      axios
        .get(
          `${this.url}/get_penname_split?penname=${this.searchWord}&page=${
            this.currentPage
          }&isUnionKariMst=${
            this.params.get("hachuId") !== null ? false : true
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.searched_penName = response.data;
          if (this.searched_penName.penameList.length === 0) {
            this.searchResult_nullFlg = true;
          } else {
            this.searchResult_nullFlg = false;
          }
        })
        .catch((error) => {
          alert("ペンネームデータが取得できませんでした\n" + error);
        });
    },
    getCurrentPage(currentPage) {
      this.clickedPage = currentPage;
      this.currentPage = currentPage;
      //新しい取引先情報呼び出し
      this.getPageInfo();
    },
    search() {
      this.currentPage = 1;
      this.getPageInfo();
    },
    addPenName(penNameData) {
      let duplicated_flg = false;
      this.selected_penName.forEach((name) => {
        if (name.penName == penNameData.penname) {
          duplicated_flg = true;
        }
      });
      if (!duplicated_flg) {
        if (
          this.modalOnModalName === "modal_relatedGenka_torihikisaki" ||
          this.modalOnModalName === "modal_independentGenka_torihikisaki"
        ) {
          this.selected_penName.splice(0);
          this.selected_penName_allData = {};
          this.selected_penName_allData = penNameData;
        }
        this.selected_penName.push({
          penName: penNameData.penname,
          pennameId: penNameData.pennameId,
          torihikisakiId: penNameData.torihikisakiId,
          isNewKariPenname: false,
        });
      }
    },
    createKariPenname(kariPenname) {
      // 原価台帳から開かれた場合、元の選択を削除する
      if (kariPenname !== "") {
        if (
          this.modalOnModalName === "modal_relatedGenka_torihikisaki" ||
          this.modalOnModalName === "modal_independentGenka_torihikisaki"
        ) {
          this.selected_penName.splice(0);
          this.selected_penName_allData = {};
        }
        this.selected_penName.push({
          penName: kariPenname,
          pennameId: null,
          torihikisakiId: null,
          isNewKariPenname: true,
        });
        this.inputKariPenname = "";
      }
    },
    removePenName(index) {
      this.selected_penName.splice(index, 1);
      this.selected_penName_allData = {};
    },

    // 【原価台帳】選択したペンネームに一致する取引先情報を取得
    async searchTorihikisaki() {
      // 仮ペンネームのAPIからのレスポンス⇒torihikisakiIdが全て0なので、ペンネームの完全一致で検索する（単価は全て0なので問題なし）
      // 新規登録仮ペンネーム⇒selected_penName_allDataが存在しない、取引先名が存在しない
      // 登録済み仮ペンネーム⇒selected_penName_allDataが存在する、取引先名が存在しない
      // ペンネーム選択済みのまま変更せず決定を押した場合⇒selected_penNameはあるが、selected_penName_allDataがない
      // （新規登録ペンネームとかぶるので二つ目の条件にする）

      // 新規登録の仮ペンネームの場合
      if (
        this.selected_penName.length > 0 &&
        this.selected_penName[0].isNewKariPenname
      ) {
        let torihikisakiInfo = {};
        try {
          const res = await axios.get(
            `${this.url}/get_torihikisaki_split?torihikisaki_mei=${this.selected_penName[0].penName}&page=1`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          res.data.torihikisakiList.forEach((torihikisaki) => {
            if (torihikisaki.penname === this.selected_penName[0].penName) {
              torihikisakiInfo = {
                tankaList: torihikisaki.tankaList,
                torihikisakiId: this.selected_penName[0].torihikisakiId,
                torihikisakiMei: "仮ペンネーム",
                pennameId: this.selected_penName[0].pennameId,
                penname: this.selected_penName[0].penName,
                torihikisakiCd: torihikisaki.torihikisakiCd,
              };
            }
          });
          return torihikisakiInfo;
        } catch (error) {
          console.log("取引先データが取得できませんでした\n" + error);
        }
      }
      // 何も変更せず決定をクリックした場合
      else if (
        this.selected_penName.length > 0 &&
        Object.keys(this.selected_penName_allData).length === 0
      ) {
        return false;
      }
      // 登録済みの仮ペンネームの場合
      else if (this.selected_penName_allData.pennameId < 0) {
        let torihikisakiInfo = {};
        try {
          const res = await axios.get(
            `${this.url}/get_torihikisaki_split?torihikisaki_mei=${this.selected_penName_allData.penname}&page=1`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          res.data.torihikisakiList.forEach((torihikisaki) => {
            if (torihikisaki.penname == this.selected_penName_allData.penname) {
              torihikisakiInfo = {
                tankaList: torihikisaki.tankaList,
                torihikisakiId: this.selected_penName_allData.torihikisakiId,
                torihikisakiMei: "仮ペンネーム",
                pennameId: this.selected_penName_allData.pennameId,
                penname: this.selected_penName_allData.penname,
                torihikisakiCd: torihikisaki.torihikisakiCd,
              };
            }
          });
          return torihikisakiInfo;
        } catch (error) {
          console.log("取引先データが取得できませんでした\n" + error);
        }
      } else {
        let torihikisakiInfo = {};
        try {
          const res = await axios.get(
            `${this.url}/get_torihikisaki_split?torihikisaki_mei=${this.selected_penName_allData.penname}&page=1`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          res.data.torihikisakiList.forEach((torihikisaki) => {
            if (
              torihikisaki.torihikisakiId ==
              this.selected_penName_allData.torihikisakiId
            ) {
              torihikisakiInfo = {
                tankaList: torihikisaki.tankaList,
                torihikisakiId: torihikisaki.torihikisakiId,
                torihikisakiMei: torihikisaki.torihikisakiMei,
                pennameId: this.selected_penName_allData.pennameId,
                penname: this.selected_penName_allData.penname,
                torihikisakiCd: torihikisaki.torihikisakiCd,
              };
            }
          });
          return torihikisakiInfo;
        } catch (error) {
          console.log("取引先データが取得できませんでした\n" + error);
        }
      }
    },

    async registerKariPenname(selected_penName_index) {
      try {
        const res = await axios.post(
          `${this.url}/save_kari_penname`,
          {
            daiwariId: this.daiwariId,
            kariPenname: this.selected_penName[selected_penName_index].penName,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.selected_penName[selected_penName_index].pennameId =
          res.data.kariPennameId;
        this.selected_penName[selected_penName_index].torihikisakiId =
          res.data.kariPennameId;
      } catch (error) {
        alert("仮ペンネームの登録に失敗しました");
        console.log(error);
      }
    },
    async postUseKariPenname(selected_penName_index) {
      try {
        await axios.post(
          `${this.url}/save_kari_penname`,
          {
            daiwariId: this.daiwariId,
            kariPennameId:
              this.selected_penName[selected_penName_index].pennameId,
            kariPenname: this.selected_penName[selected_penName_index].penName,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
      } catch (error) {
        alert("仮ペンネーム使用状況の登録に失敗しました");
        console.log(error);
      }
    },
    async updatePenName() {
      // 【原価台帳】作品に紐づく原価から開いた場合
      if (this.modalOnModalName === "modal_relatedGenka_torihikisaki") {
        // 新規仮ペンネームの場合登録を行う
        if (
          this.selected_penName.length !== 0 &&
          this.selected_penName[0].isNewKariPenname
        ) {
          await this.registerKariPenname(0);
        }
        // 既存仮ペンネームの場合使用状況をpostする
        else if (
          this.selected_penName.length !== 0 &&
          this.selected_penName[0].torihikisakiId < 0
        ) {
          await this.postUseKariPenname(0);
        }

        const torihikisakiData = await this.searchTorihikisaki();
        if (this.selected_penName.length !== 0) {
          delete this.selected_penName[0].isNewKariPenname;
        }

        // 変更がなければモーダルを閉じる
        if (torihikisakiData === false) {
          this.hideModal();
        } else {
          this.$emit("updateTorihikisaki", torihikisakiData);
          this.changeTorihikiToDaiwari();
        }
      }
      // 【原価台帳】作品に紐づかない原価から開いた場合
      else if (
        this.modalOnModalName === "modal_independentGenka_torihikisaki"
      ) {
        // 新規仮ペンネームの場合登録を行う
        if (
          this.selected_penName.length !== 0 &&
          this.selected_penName[0].isNewKariPenname
        ) {
          await this.registerKariPenname(0);
        }
        // 既存仮ペンネームの場合使用状況をpostする
        else if (
          this.selected_penName.length !== 0 &&
          this.selected_penName[0].torihikisakiId < 0
        ) {
          await this.postUseKariPenname(0);
        }

        const torihikisakiData = await this.searchTorihikisaki();
        if (this.selected_penName.length !== 0) {
          delete this.selected_penName[0].isNewKariPenname;
        }
        // 変更がなければモーダルを閉じる
        if (torihikisakiData === false) {
          this.hideModal();
        } else {
          this.editDaiwariGenkaTorihiki(
            torihikisakiData,
            this.update_genkaIndex
          );
        }
      }
      // 通常のペンネーム検索の場合
      // ペンネーム一括設定
      else if (this.penName_ikkatuFlg && this.selected_penName.length) {
        if (
          window.confirm(
            `すべての作品のペンネームを一括設定します。よろしいですか？`
          )
        ) {
          for (const [pennameIndex, penname] of Object.entries(
            this.selected_penName
          )) {
            // 新規仮ペンネームの場合登録を行う
            if (penname.isNewKariPenname) {
              await this.registerKariPenname(pennameIndex);
            }
            // 既存仮ペンネームの場合使用状況をpostする
            else if (penname.torihikisakiId < 0) {
              await this.postUseKariPenname(pennameIndex);
            }
            if (this.selected_penName.length !== 0) {
              delete penname.isNewKariPenname;
            }
          }

          this.isProcessing = true;
          try {
            this.$emit(
              "updatePenName",
              this.selected_penName,
              this.penName_ikkatuFlg
            );
          } catch (error) {
            this.isProcessing = false;
            alert("ペンネーム設定処理に失敗しました");
            console.log(error);
          }
        }
        // 処理に時間がかかるため、updatePenName内モーダル閉じる処理を行う
        // this.$emit("hideModal");
      }
      // ペンネーム単独設定
      else {
        for (const [pennameIndex, penname] of Object.entries(
          this.selected_penName
        )) {
          // 新規仮ペンネームの場合登録を行う
          if (penname.isNewKariPenname) {
            await this.registerKariPenname(pennameIndex);
          }
          // 既存仮ペンネームの場合使用状況をpostする
          else if (penname.torihikisakiId < 0) {
            await this.postUseKariPenname(pennameIndex);
          }
          if (this.selected_penName.length !== 0) {
            delete penname.isNewKariPenname;
          }
        }

        this.isProcessing = true;
        try {
          this.$emit(
            "updatePenName",
            this.selected_penName,
            this.penName_ikkatuFlg
          );
        } catch (error) {
          this.isProcessing = false;
          alert("ペンネーム設定処理に失敗しました");
          console.log(error);
        }
        // this.$emit("hideModal");
      }
    },
  },
  computed: {
    comp_selected_penName() {
      return this.selected_penName;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    z-index: 2;
    width: 60%;
    height: 95% !important;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0% !important;
  }
  .modal__header {
    display: flex;
    align-items: center;
    padding: 1px 20px;
    height: 10%;
    background-color: #2a3f54;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
  }
  .modal__title {
    display: block;
    font-size: 20px;
    color: #fff;
    text-align: left;
  }
  .modal__close {
    display: block;
    font-size: 20px;
    color: #fff;
    text-align: left;
  }
  .modal__review {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95% !important;
    gap: 10px !important;
    padding-top: 10px;
    &__result-busyo {
      padding-left: 10px;
    }
    &__result-name {
      display: block;
      background-color: #d9d9ec;
      padding: 0 2px !important;
      border: 2px solid #ccc;
      border-radius: 4px;
      width: 70%;
      font-size: 12px !important;
      &:hover {
        background-color: #c3c3d1;
        transition: 0.5s;
      }
    }
    .result_sakuhin_penname {
      background: #ffeac9;
      &:hover {
        background: #ebd7b8;
        transition: 0.5s;
      }
    }
    &__result-torihikisakiMei {
      font-size: 11px !important;
    }
    &__search-container {
      display: flex;
      gap: 5px;
    }
    &__result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;
      min-height: 20%;
      max-height: 60%;
      height: auto;
      overflow: auto;
    }
    &__page-container {
      display: flex;
      list-style: none;
      gap: 10px;
    }
    &__page-list {
      display: block;
      color: #0d6efd;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
    &__page-next {
      cursor: pointer;
    }
    &__karipenname-container {
      list-style: none;
      display: flex;
      gap: 5px;
      #kari-check {
        cursor: pointer;
      }
      label {
        font-weight: 100;
        cursor: pointer;
      }
      .kari-text {
        margin-left: 5px;
      }
    }
    .btn_orange_small {
      background-color: gray;
      color: #fff;
      border: none;
      border-radius: 6px;
      padding: 5px;
      //opacity: 80%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      &:hover {
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      }
    }

    &__badge-container {
      list-style: none;
      display: flex;
      gap: 5px;
    }
    &__badge-selected {
      background-color: rgb(235, 235, 235);
      color: rgb(0, 0, 0);
      border: none;
      border-radius: 20px;
      padding: 0 10px;
      //opacity: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2px;
    }
    &__badge-name {
      display: block;
    }
    &__badge-cancel {
      cursor: pointer;
      display: block;
    }
  }

  // ページング
  .pagination {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    list-style: none;
    font-size: 13px;
    gap: 2px;
    //border: 2px solid #ededed;
    //border-radius: 3px;
    width: 320px;
    & > li {
      border: 2px solid #ededed;
      border-radius: 2px;
      width: 40px;
      text-align: center;
    }
  }
  .page-item {
    & > a {
      display: block;
      color: #0d6efd;
      &:hover {
        color: #0d6efd;
        text-decoration: underline;
        text-decoration-color: #0d6efd;
      }
    }
  }
  .page-item--active {
    font-weight: bold;
    background: #e2f1ff;
  }

  /* common.cssから引用（後で消去？） */
  .text_box {
    height: 30px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
  }
  .btn_gray_small {
    background-color: gray;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 5px;
    //opacity: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    &:hover {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }
  }

  .btn_blue {
    background-color: #0d6efd;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 10px;
    //opacity: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    &:hover {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }
  }

  // 処理中オーバーレイ
  .processing-overlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .processing-icon {
      display: inline-block;
      border: 3px solid #ffffff;
      border-top: 3px solid #848484;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      animation: spin 1s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .processing-message {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
    }
  }

  // フォーカス用疑似クラスを追加
  button,
  li {
    &:focus-visible {
      border: 2px black solid;
    }
  }
}
</style>
