import { getUniqueStr, is_matched_sakuhinId } from "./util.js";

export class PageBikou {
  constructor(addSakuhin) {
    this.sakuhin_mei = addSakuhin.sakuhin_mei;
    this.sakuhin_id = addSakuhin.sakuhin_id;
    this.sakuhin_uniqueId = addSakuhin.sakuhin_uniqueId;
    this.sakuhin_bikouId = addSakuhin.sakuhin_bikouId;
    this.bikou_array = [{ page: 1, bikou: "", isHighlighted: false }];
  }
}

export class HukuseiPageBikou {
  constructor(hukusei_sakuhin, sakuhin_bikouId, target_bikou) {
    this.sakuhin_mei = hukusei_sakuhin.sakuhin_mei;
    this.sakuhin_id = hukusei_sakuhin.sakuhin_id;
    this.sakuhin_uniqueId = hukusei_sakuhin.sakuhin_uniqueId;
    this.sakuhin_bikouId = sakuhin_bikouId;
    this.bikou_array = target_bikou.bikou_array;
  }
}

/*
ページ備考配列の初期設定
*/
let all_sakuhin_array = [];
export const set_initialPageBikouArray = (daiwariData, daiwariSakuhinArray) => {
  //   console.log(JSON.parse(JSON.stringify(daiwariData.sakuhin_page_bikou_array)));
  if ("sakuhin_page_bikou_array" in daiwariData == false) {
    //divide含めた全作品を１つにまとめる
    all_sakuhin_array.splice(0);
    daiwariData.ori_array.forEach((ori) => {
      ori.sakuhin_array.forEach((sakuhin) => {
        all_sakuhin_array.push(sakuhin);
      });
    });
    //ページ備考配列が無ければ新規作成
    daiwariData.sakuhin_page_bikou_array = [];

    //ページ備考配列に入れる作品の絞り込み(divide作品は除外)
    daiwariData = filter_divideSakuhin({ ...daiwariData });
  } else {
    daiwariSakuhinArray.forEach((sakuhin) => {
      daiwariData.sakuhin_page_bikou_array.forEach((bikouSakuhin) => {
        // sakuhin_idのずれが発生している場合があるので揃える
        if (
          bikouSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId &&
          bikouSakuhin.sakuhin_id !== sakuhin.sakuhin_id
        ) {
          bikouSakuhin.sakuhin_id = sakuhin.sakuhin_id;
        }
        //  作品マスタに紐づかない作品の場合、sakuhin_meiにタイトル補足を入れる
        if (
          bikouSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId &&
          bikouSakuhin.sakuhin_id === -1
        ) {
          bikouSakuhin.sakuhin_mei = sakuhin.sakuhin_mei_hosoku;
        }
        // 作品マスタに紐づく作品の場合、作品名をそのまま入れる
        else if (
          bikouSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId &&
          bikouSakuhin.sakuhin_id !== -1
        ) {
          bikouSakuhin.sakuhin_mei = sakuhin.sakuhin_mei;
        }
      });
    });

    //ページのずれを防止するためページの上書きをする
    daiwariData.sakuhin_page_bikou_array.forEach((bikouSakuhin) => {
      bikouSakuhin.bikou_array.forEach((bikou, index) => {
        bikou.page = index + 1;
      });
    });
  }

  return daiwariData;
};

/*
同作品の分断作品は1作品としてまとめる
*/

const filter_divideSakuhin = (daiwariData) => {
  let prev_sakuhin;
  all_sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
    if (
      sakuhinIndex == 0 ||
      (sakuhinIndex !== 0 &&
        sakuhin.sakuhin_bikouId !== prev_sakuhin.sakuhin_bikouId)
    ) {
      let page_array = [];
      for (let i = 1; i <= sakuhin.total_page; i++) {
        page_array.push({ page: i, bikou: "", isHighlighted: false });
      }
      const sakuhinMei =
        sakuhin.sakuhin_id === -1
          ? sakuhin.sakuhin_mei_hosoku
          : sakuhin.sakuhin_mei;
      daiwariData.sakuhin_page_bikou_array.push({
        sakuhin_mei: sakuhinMei,
        sakuhin_id: sakuhin.sakuhin_id,
        sakuhin_uniqueId: sakuhin.sakuhin_uniqueId,
        sakuhin_bikouId: sakuhin.sakuhin_bikouId,
        bikou_array: [...page_array],
      });
    }
    prev_sakuhin = sakuhin;
  });
  return daiwariData;
};

/*
ページ備考配列の更新(ページ増減)
*/
let shouldChangePage = true;
let changeBikouArray = [];
export const update_pageBikouArray = (
  daiwariData,
  selectedSakuhin,
  sakuhin_page_bikou_array,
  newPage
) => {
  let targetBikou = sakuhin_page_bikou_array.find(
    (sakuhin) => sakuhin.sakuhin_bikouId === selectedSakuhin.sakuhin_bikouId
  );
  const old_bikouPageLength = targetBikou.bikou_array.length;
  const new_bikouPageLength = newPage;
  //bikou_arrayを新しい貢数に合わせて更新
  if (old_bikouPageLength > new_bikouPageLength) {
    changeBikouArray = delete_pageBikouArray(
      daiwariData,
      new_bikouPageLength,
      targetBikou,
      selectedSakuhin
    );
  } else {
    changeBikouArray = add_pageBikouArray(
      old_bikouPageLength,
      new_bikouPageLength,
      targetBikou
    );
  }

  return {
    change_bikouArray: changeBikouArray,
    should_changePage: shouldChangePage,
  };
};

//ページが減った場合:bikou_array末尾から差分を削除
const delete_pageBikouArray = (
  daiwariData,
  new_bikouPageLength,
  targetBikou,
  selectedSakuhin
) => {
  //selectedSakuhinが分断している場合親の開始ページを知りたい
  let startSakuhin = JSON.parse(JSON.stringify(selectedSakuhin));
  if (startSakuhin.is_divided && !startSakuhin.is_parent) {
    //作品のページ開始位置を把握するため親作品を取得
    daiwariData.ori_array.forEach((ori) => {
      ori.sakuhin_array.forEach((sakuhin) => {
        if (
          sakuhin.sakuhin_bikouId === startSakuhin.sakuhin_bikouId &&
          sakuhin.is_parent
        ) {
          startSakuhin = sakuhin;
        }
      });
    });
  }

  //削除アラート表示判定
  const deleteBikouArray = targetBikou.bikou_array.slice(new_bikouPageLength);
  const deleteBikouArray_existsVelue = deleteBikouArray.filter(
    (deleteBikou) => deleteBikou.bikou !== ""
  );
  const needs_confirm = deleteBikouArray_existsVelue.length;
  if (needs_confirm) {
    //入力済みの備考が何ページ目にあるか
    let deleteBikouPageArray_existsVelue = [];

    deleteBikouArray_existsVelue.forEach((bikou) => {
      let totalPage_toPreSakuhin;
      if (startSakuhin.sakuhin_address_computed_array_withFlg.length) {
        totalPage_toPreSakuhin =
          startSakuhin.sakuhin_address_computed_array_withFlg[0];
      } else {
        totalPage_toPreSakuhin = startSakuhin.sakuhin_address_computed_array[0];
      }
      deleteBikouPageArray_existsVelue.push(
        bikou.page + totalPage_toPreSakuhin
      );
    });
    const comfirmMessage = confirm(
      `『${targetBikou.sakuhin_mei}』のページ縮小範囲に備考データがあります。備考データを削除しページを縮小してもよろしいですか？`
    );

    if (!comfirmMessage) {
      shouldChangePage = false;
    } else {
      shouldChangePage = true;
      //備考配列は保持したまま、入力データのみ空にする
      deleteBikouArray_existsVelue.forEach((deleteBikou) => {
        deleteBikou.bikou = "";
        deleteBikou.isHighlighted = false;
      });
    }
  }

  //ページのずれを防止するためページの上書きをする
  targetBikou.bikou_array.forEach((bikou, index) => {
    bikou.page = index + 1;
  });
  return targetBikou;
};

//ページが増えた場合:bikou_array末尾から差分を追加
const add_pageBikouArray = (
  old_bikouPageLength,
  new_bikouPageLength,
  targetBikou
) => {
  shouldChangePage = true;

  //追加分を算出
  const addBikouLength = new_bikouPageLength - old_bikouPageLength;
  for (let i = 0; i < addBikouLength; i++) {
    targetBikou.bikou_array.push({ bikou: "", isHighlighted: false });
  }
  //ページのずれを防止するためページの上書きをする
  targetBikou.bikou_array.forEach((bikou, index) => {
    bikou.page = index + 1;
  });
  return targetBikou;
};

/*
作品が分断している際の参照中備考ページの再計算
*/
export const return_total_preBikouIndex = (
  ori_array,
  clickedSakuhin,
  bikouPage
) => {
  //選択中の作品がdivideしている場合1つ前のdivide作品を追う(親作品が見つかるまで)
  let pre_dividedSakuhin;
  let sakuhin = clickedSakuhin;
  let totalBikouPage = 0;
  for (let i = 0; i <= ori_array.length; i++) {
    const pre_sakuhin_array =
      ori_array[sakuhin.parent_ori_address_computed - 2].sakuhin_array;
    pre_dividedSakuhin = pre_sakuhin_array[pre_sakuhin_array.length - 1];

    //前divided作品の貢数をカウント
    totalBikouPage = totalBikouPage + pre_dividedSakuhin.divided_page;

    //親作品が見つかればこれ以上追う必要が無いので終了
    if (pre_dividedSakuhin.is_parent) {
      return totalBikouPage - 1 + bikouPage;
    } else {
      sakuhin = pre_dividedSakuhin;
    }
  }
};

/*
ページ備考配列の順番を作品配列の順番と揃える
*/
export const sort_sakuhin_page_bikou_array = (
  sakuhin_page_bikou_array,
  daiwariSakuhinArray,
  daiwariData
) => {
  sakuhin_page_bikou_array = sakuhin_page_bikou_array
    ? sakuhin_page_bikou_array
    : [];
  // 備考データが余分に登録されて重複する事象が起きる台割が稀にあるので、重複していない状態に戻す
  const not_duplicated_sakuhin_page_bikou_array =
    sakuhin_page_bikou_array.filter(
      (bikouSakuhin, bikouIndex, bikouArray) =>
        bikouArray.findIndex(
          (e) => e.sakuhin_bikouId === bikouSakuhin.sakuhin_bikouId
        ) === bikouIndex
    );

  const sort_array = [];
  daiwariSakuhinArray.forEach((sakuhin) => {
    let hasMatchedBikouSakuhin = false;
    not_duplicated_sakuhin_page_bikou_array.forEach((bikouSakuhin) => {
      if (
        bikouSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId &&
        ((sakuhin.sakuhin_id !== -1 &&
          bikouSakuhin.sakuhin_mei === sakuhin.sakuhin_mei) ||
          (sakuhin.sakuhin_id === -1 &&
            bikouSakuhin.sakuhin_mei === sakuhin.sakuhin_mei_hosoku))
      ) {
        sort_array.push(bikouSakuhin);
        hasMatchedBikouSakuhin = true;
      }
    });

    if (!hasMatchedBikouSakuhin) {
      //作品に紐づく備考が見つからない場合備考を新規で作成
      //(不具合で備考が作成されないor削除された場合のセーフティーネット)
      //sakuhin_bikouIdを再発行し、該当の作品と備考に付与する
      // console.log(
      //   "該当する作品がありません",
      //   sakuhin.sakuhin_mei,
      //   sakuhin.sakuhin_mei_hosoku,
      //   sakuhin
      // );
      let uniqueBikouId = getUniqueStr();
      sakuhin.sakuhin_bikouId = uniqueBikouId;
      //備考データを作成
      let page_array = [];
      for (let i = 1; i <= sakuhin.total_page; i++) {
        page_array.push({ page: i, bikou: "", isHighlighted: false });
      }
      const pushData = {
        sakuhin_mei:
          sakuhin.sakuhin_id === -1
            ? sakuhin.sakuhin_mei_hosoku
            : sakuhin.sakuhin_mei,
        sakuhin_id: sakuhin.sakuhin_id,
        sakuhin_uniqueId: sakuhin.sakuhin_uniqueId,
        sakuhin_bikouId: uniqueBikouId,
        bikou_array: [...page_array],
      };
      sort_array.push(pushData);
    }
    // divided作品のsakuhin_bikouIdがずれていることがあるので親とそろえる
    if (sakuhin.is_divided) {
      daiwariData.ori_array.forEach((daiwariOri) => {
        daiwariOri.sakuhin_array.forEach((daiwariSakuhin) => {
          if (is_matched_sakuhinId(sakuhin, daiwariSakuhin)) {
            daiwariSakuhin.sakuhin_bikouId = sakuhin.sakuhin_bikouId;
          }
        });
      });
    }
  });
  return sort_array;
};
