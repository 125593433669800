<template>
  <div class="MultiLayout">
    <ModalMultiTanzaku
      v-if="tanzakuModalVisible"
      @close="closeTanzakuModal"
      :selectedMeisai="selectedMeisai"
      @addTanzakuInfo="addTanzakuInfo"
    />
    <ModalMultiTorioki
      :isVisible="toriokiModalVisible"
      @close="closeToriokiModal"
      @setTorioki="setTorioki"
    />
    <p class="MultiLayout_Title" v-if="referenceFlg">マルチ入力照会画面</p>
    <p class="MultiLayout_Title" v-else>マルチ入力修正画面</p>
    <div class="MultiLayout_TopWrapper">
      <div class="MultiLayout_TopWrapper_Title">取引先・イベント情報入力</div>
      <ul class="MultiLayout_TopWrapper_LeadItem">
        <li>
          <p class="MultiLayout_TopWrapper_LeadItem_Title">
            <span>受注ID：</span>{{ selectedOrder.orderId }}
          </p>
        </li>
        <li>
          <p class="MultiLayout_TopWrapper_LeadItem_Title">
            <span>受注日付：</span>{{ selectedOrder.orderDate }}
          </p>
        </li>
      </ul>
      <div class="MultiLayout_TopWrapper_Content">
        <div class="MultiLayout_TopWrapper_List">
          <div class="MultiLayout_TopWrapper_ListItem">
            <p class="MultiLayout_ListTitle">取引先CD</p>
            <div class="MultiLayout_ListContent">
              <!-- 取引先CDの分岐 -->
              <select
                v-if="referenceFlg || renkeiFlg"
                disabled
                class="SelectNormal_Select"
              >
                <option>
                  {{
                    selectedOrder.toritsugiCd + ":" + selectedOrder.toritsugiMei
                  }}
                </option>
              </select>
              <SelectNormal
                v-else
                :options="toritugiList"
                :selectInfo="toritugiList[0].value"
              />
            </div>
          </div>
          <div class="MultiLayout_TopWrapper_ListItem">
            <p class="MultiLayout_ListTitle">イベント</p>
            <div class="MultiLayout_ListContent">
              <select
                v-if="referenceFlg || renkeiFlg"
                disabled
                class="SelectNormal_Select"
              >
                <option>イベント名</option>
              </select>
              <!-- イベントの分岐 -->
              <SelectNormal
                v-else
                :options="[
                  { name: '', value: '' },
                  { name: '', value: '' },
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="MultiLayout_MainArea">
      <table class="MultiLayout_Table">
        <thead>
          <tr>
            <th class="MultiLayout_Table_Meisai MultiLayout_Table_Sticky_TH">
              明細<br />No
            </th>
            <th class="MultiLayout_Table_SyotenInfo">
              書店CD<br />書店名<br />地区名
            </th>
            <th class="MultiLayout_Table_Jyoken">
              番線 / 仕分CD/依頼者<br />条件<br />納品予定日 / 請求予定日
            </th>
            <th class="MultiLayout_Table_SyosiInfo">
              書誌CD / 年月号<br />枝番 名称
            </th>
            <th class="MultiLayout_Table_JuchuInfo">受注数<br />取置No</th>
            <th class="MultiLayout_Table_Num">
              使用可能数<br />当日受注入力数
            </th>
            <th class="MultiLayout_Table_Select">保留設定</th>
            <th v-if="!referenceFlg" class="MultiLayout_Table_Tanzaku">
              短冊ﾃﾞｰﾀ<br />入力
            </th>
            <th v-if="!referenceFlg" class="MultiLayout_Table_Delete">削除</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) in selectedOrder.order_array"
            :key="index"
            :class="{ MultiLayout_Table_isSelected: order.selectedMeisaiFlg }"
          >
            <td
              class="MultiLayout_Table_Meisai MultiLayout_Table_ValignMid MultiLayout_Table_Sticky_TD"
            >
              {{ order.meisaiId }}
            </td>
            <td
              class="MultiLayout_Table_SyotenInfo MultiLayout_Table_ValignTop"
            >
              <!-- 書店情報 -->
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
                v-model="order.syotenCd"
              /><br />{{ order.syotenMei }}<br />
            </td>
            <td class="MultiLayout_Table_Jyoken MultiLayout_Table_ValignTop">
              <!-- 取次情報 -->
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
                v-model="order.toritsugiBansen"
              />
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
                v-model="order.toritsugiCd"
              />
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
              /><br />
              <div v-if="jyokenFlg">
                <select
                  @change="selectEventKbn"
                  class="MultiLayout_Input"
                  v-model="order.jyokenKbn"
                  :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                >
                  <option value="1">01:注文扱い</option>
                  <option value="2">02:延勘</option>
                  <option value="3">03:長期委託</option>
                  <option value="4">04:mm本</option>
                  <option value="5">05:常備</option>
                  <option value="6">06:委託新刊</option>
                  <option value="7">07:買切</option>
                </select>
                <input
                  type="number"
                  v-if="order.jyokenKbn == '2'"
                  @input="checkTextLength($event, order)"
                  v-model="order.jyokenKikan"
                  class="MultiLayout_InputS"
                />
                <input
                  type="number"
                  @input="checkTextLength($event, order)"
                  v-else-if="order.jyokenKbn == '3'"
                  v-model="order.jyokenKikan"
                  class="MultiLayout_InputS"
                />
                <br />
                <p v-if="order.errorFlg" class="MultiLayout_Error">
                  ※期間は1~99のみ有効です
                </p>
                <br />
                <input
                  :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                  class="MultiLayout_DateInput"
                  type="date"
                /><input
                  :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                  class="MultiLayout_DateInput"
                  type="date"
                />
              </div>
            </td>
            <td class="MultiLayout_Table_SyosiInfo MultiLayout_Table_ValignTop">
              <!-- 書誌情報 -->
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
                v-model="order.syosiCd"
              />
              <br />{{ order.syosiMei }}
              <span class="MultiLayout_HoryuBadge" v-if="order.status == 3"
                >保留</span
              >
            </td>
            <td class="MultiLayout_Table_JuchuInfo MultiLayout_Table_ValignTop">
              <!-- 受注数/取置No -->
              <input
                :disabled="referenceFlg || (renkeiFlg && order.status !== 3)"
                type="text"
                class="MultiLayout_Input"
                v-model="order.orderNum"
              /><br />
              <input
                v-if="
                  (!referenceFlg && selectedOrder.orderStatus == 1) ||
                  (!referenceFlg &&
                    selectedOrder.orderStatus == 2 &&
                    order.status == 3)
                "
                @dblclick="showToriokiModal(order)"
                type="text"
                class="MultiLayout_Inputdblclick"
                v-model="order.juchuToriokiNo"
              />
            </td>
            <td class="MultiLayout_Table_Num MultiLayout_Table_ValignMid">
              XXX<br />YYY
            </td>
            <td
              v-if="referenceFlg || (renkeiFlg && order.status !== 3)"
              class="MultiLayout_Table_Select"
            >
              <select disabled class="SelectNormal_Select">
                <option v-if="order.status !== 3">保留しない</option>
                <option v-if="order.status == 3">保留</option>
              </select>
            </td>
            <td v-else class="MultiLayout_Table_Select">
              <select v-if="order.status !== 3" class="SelectNormal_Select">
                <option selected>保留しない</option>
                <option>保留</option>
              </select>
              <select v-if="order.status == 3" class="SelectNormal_Select">
                <option>保留しない</option>
                <option selected>保留</option>
              </select>
            </td>
            <!-- 下記モーダルは起動させる？ -->
            <td
              v-if="
                (!referenceFlg && selectedOrder.orderStatus == 1) ||
                (!referenceFlg &&
                  selectedOrder.orderStatus == 2 &&
                  order.status == 3)
              "
              class="MultiLayout_Table_Tanzaku MultiLayout_Table_ValignMid"
            >
              <button
                v-if="!order.tanzakuFlg"
                @click="showTanzakuModal(order)"
                class="MultiLayout_Button Orange"
              >
                入力
              </button>
              <font-awesome-icon
                v-else
                @click="showTanzakuModal(order)"
                class="MultiLayout_Table_Icon"
                icon="pencil"
              />
            </td>
            <td v-else-if="!referenceFlg && order.status !== 3">-</td>
            <td
              v-if="
                (!referenceFlg && selectedOrder.orderStatus == 1) ||
                (!referenceFlg &&
                  selectedOrder.orderStatus == 2 &&
                  order.status == 3)
              "
              class="MultiLayout_Table_Delete MultiLayout_Table_ValignMid"
            >
              <font-awesome-icon
                @click="deleteOrder(order, index)"
                class="MultiLayout_Table_Icon"
                icon="trash-can"
              />
            </td>
            <td v-else-if="!referenceFlg && order.status !== 3">-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="MultiLayout_BtnArea">
      <button
        v-if="!referenceFlg"
        class="MultiLayout_BtnArea_UpdateBtn"
        @click="updateMultiOrder"
      >
        変更内容を登録
      </button>
    </div>
  </div>
</template>

<script>
import SelectNormal from "./atoms/select/SelectNormal.vue";
import ModalMultiTanzaku from "../components/modal/ModalMultiTanzaku.vue";
import ModalMultiTorioki from "../components/modal/ModaLMultiTorioki.vue";

export default {
  name: "CartMultiLayout",
  components: { SelectNormal, ModalMultiTanzaku, ModalMultiTorioki },
  props: {
    selectedOrder: {
      type: Object,
    },
    referenceFlg: {
      type: Boolean,
    },
    renkeiFlg: {
      type: Boolean,
    },
  },
  emits: ["setToriokiList", "deleteOrder", "addTanzakuInfo"],
  data() {
    return {
      meisaiId: null,
      jyokenFlg: true, //修正画面ではここは表示？
      toriokiFlg: false,
      tanzakuModalVisible: false,
      toriokiModalVisible: false,
      selectedOrderInfo: null,
      selectedMeisai: null,
    };
  },
  methods: {
    showTanzakuModal(order) {
      this.selectedMeisai = order;
      this.tanzakuModalVisible = true;
    },
    closeTanzakuModal() {
      this.tanzakuModalVisible = false;
    },
    showToriokiModal(order) {
      this.selectedOrderInfo = order;
      this.toriokiModalVisible = true;
    },
    addTanzakuInfo(meisai) {
      this.$emit("addTanzakuInfo", meisai);
      this.tanzakuModalVisible = false;
    },
    closeToriokiModal() {
      this.toriokiModalVisible = false;
    },
    deleteOrder(order, orderIndex) {
      if (confirm(`${order.syosiMei}を削除します。よろしいですか？`)) {
        this.$emit("deleteOrder", orderIndex);
      }
    },
    setTorioki(torioki) {
      this.$emit(
        "setToriokiList",
        torioki.toriokiKanriNo,
        this.selectedOrderInfo
      );
    },
    selectEventKbn(event) {
      console.log(event.target.value);
    },
    updateMultiOrder() {
      if (confirm("変更内容を反映します。よろしいですか？")) {
        window.close();
      }
    },
    checkTextLength(event, order) {
      if (event.target.value.length >= 3) {
        order.errorFlg = true;
        order.jyokenKikan = "";
      } else {
        order.errorFlg = false;
      }
    },
  },
  computed: {
    updateSelectedOrder() {
      return this.selectedOrder;
    },
    //取次
    toritugiList() {
      return this.$store.getters["constant/getToritugiList"];
    },
  },
};
</script>

<style scoped lang="scss">
.SelectNormal_Select {
  width: 121px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #848484;
  box-sizing: border-box;
  border-radius: 5px;
}
.MultiLayout {
  position: fixed;
  top: 15px;
  overflow: auto;
  bottom: 0;
  padding: 0 20px 20px 10px;
  width: 100%;
  left: 0;
  &_Title {
    font-size: 20px;
    margin-left: 50px;
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 30px;
      display: inline-block;
      width: 25px;
      height: 5px;
      background: #20c997;
    }
  }
  &_ListTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 35px;
    background: #e2f5ef;
  }
  &_MainArea {
    min-width: 100%;
    overflow-y: auto;
    overflow-y: scroll;
    max-height: 300px;
    margin: 20px;
  }
  &_ListContent {
    display: flex;
    align-items: center;
    height: 35px;
    background: #f7f7f7;
    padding: 5px 10px;
  }
  &_Top {
    &Wrapper {
      max-width: 580px;
      padding: 30px;
      border: 1px solid #848484;
      border-radius: 5px;
      margin: 20px;
      @media screen and (max-width: 639px) {
        width: 90%;
      }
      &_Title {
        height: 44px;
        font-weight: 700;
        font-size: 20px;
        border-bottom: 1px solid #848484;
        margin-bottom: 10px;
        @media screen and (max-width: 639px) {
          font-size: 18px;
        }
      }
      &_LeadItem {
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;
        flex-wrap: wrap;
        &_Title {
          margin-right: 20px;
          span {
            font-weight: 700;
          }
        }
      }
      &_List {
        display: flex;
        @media screen and (max-width: 639px) {
          flex-wrap: wrap;
        }
        &Item {
          display: flex;
          margin: 0 0 3px 3px;
        }
      }
    }
  }
  &_Table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0.8px;
    &_isSelected {
      outline: 3px solid #ff0000;
    }
    th {
      // font-size: 11px;
      padding: 0 10px;
      height: 40px;
      background: #2a3f54;
      color: #fff;
      position: sticky;
      top: 0;
    }
    &_Meisai {
      min-width: 45px;
      z-index: 2;
    }
    &_SyotenInfo {
      min-width: 100px;
      z-index: 1;
    }
    &_Jyoken {
      min-width: 300px;
      z-index: 1;
    }
    &_SyosiInfo {
      min-width: 230px;
      z-index: 1;
    }
    &_JuchuInfo {
      min-width: 100px;
      z-index: 1;
    }
    &_Num {
      min-width: 80px;
      z-index: 1;
    }
    &_Select {
      min-width: 100px;
      z-index: 1;
    }
    &_Tanzaku {
      min-width: 80px;
      z-index: 1;
    }
    &_Delete {
      min-width: 45px;
      z-index: 1;
    }
    td {
      // font-size: 11px;
      padding: 5px;
      border-left: 1px solid #848484;
      border-bottom: 1px solid #848484;
      background: #fff;
      text-align: center;
      &:last-child {
        border-right: 1px solid #848484;
      }
    }
    &_ValignTop {
      vertical-align: top;
    }
    &_ValignMid {
      vertical-align: middle;
    }
    &_Icon {
      cursor: pointer;
      font-size: 20px;
      color: #2a3f54;
    }
    @media screen and (max-width: 1024px) {
      &_Sticky_TH {
        position: sticky;
        border-right: 1px solid #fff;
        left: 0px;
      }
      &_Sticky_TD {
        position: sticky;
        left: 0px;
        z-index: 1;
        border-right: 1px solid #848484;
      }
    }
  }
  &_Input {
    border: 1px solid #848484;
    height: 20px;
    width: 85px;
    margin: 2px 5px;
  }
  &_InputS {
    border: 1px solid #848484;
    height: 20px;
    width: 25px;
    margin: 2px 5px;
  }
  &_Inputdblclick {
    border: 1px solid #848484;
    background: #ffffcc;
    height: 20px;
    width: 85px;
    margin: 2px 5px;
  }
  &_DateInput {
    border: 1px solid #848484;
    height: 20px;
    width: 130px;
    margin: 2px 3px;
  }
  &_HoryuBadge {
    background: #ff0000;
    padding: 1px 5px;
    color: #fff;
  }
  &_Button {
    padding: 0 10px;
    font-size: 12px;
    height: 30px;
    border-radius: 5px;
    font-weight: 800;
    &.Orange {
      background: #ff8000;
      color: #fff;
    }
  }
  &_BtnArea {
    width: 100%;
    text-align: right;
    &_UpdateBtn {
      padding: 10px 20px;
      border-radius: 5px;
      background: #20c997;
      color: #fff;
      font-weight: bold;
    }
  }
  &_Error {
    color: #ff0000;
  }
}
</style>
