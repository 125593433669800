import axios from "axios";
import { formatBeforeUpdate, getUrl } from "./util";

export class Daiwari {
  constructor(responseData) {
    this.daiwariId = responseData.daiwariId;
    this.kikakuId = responseData.kikakuId;
    this.daiwariData = JSON.parse(responseData.daiwariData);
    this.oriMaximumPage = JSON.parse(responseData.daiwariData).oriMaximumPage;
  }
  getDaiwariId() {
    return this.daiwariId;
  }

  getKikakuId() {
    return this.kikakuId;
  }

  getDaiwariData() {
    return this.daiwariData;
  }

  getMaxOriAddress() {
    return this.getDaiwariData().ori_array.length;
  }

  getMaxSakuhinAddress() {
    if (this.getMaxOriAddress() == 0) {
      return 0;
    }
    let sakuhinAddressArray = [];
    //折の中の作品が格納されている配列から作品を取り出す
    this.daiwariData.ori_array.forEach((ori) => {
      ori.sakuhin_array.forEach((sakuhin) => {
        sakuhinAddressArray.push(sakuhin.sakuhin_address);
      });
    });
    return Math.max(...sakuhinAddressArray);
  }
}

export class DaiwariHeader {
  constructor(responseData) {
    this.title = responseData.title;
    this.hankei = responseData.hankei;
    this.hannyuDate = responseData.hannyuDate;
    this.hontaiKakaku = responseData.hontaiKakaku;
    this.isGeneratedSyosi = responseData.isGeneratedSyosi;
    this.isChild = responseData.isChild;
    this.childDaiwariName = responseData.childDaiwariName;
    this.isLock = responseData.isLock;
    this.isKikakuCancel = responseData.isKikakuCancel;
    this.isDaichoKakutei = responseData.isDaichoKakutei;
    this.isKengenLock = responseData.isKengenLock;
    this.isWfLock = responseData.isWfLock;
    this.isDensi = responseData.isDensi;
    this.daiwariKakuteiLatestVersion = responseData.daiwariKakuteiLatestVersion;

    //確認用----------------------------------
    // this.isLock = false;
    // this.isKikakuCancel = false;
    // this.isKengenLock = false;
    // this.isDaiwariKakutei = false;
    // this.isDaichoKakutei = false;
  }
}

export class TokusyuKoumoku {
  constructor(responseData) {
    this.isDokusyaPresentAri = responseData.isDokusyaPresentAri;
    this.isGenteiban = responseData.isGenteiban;
    this.isMultimedia = responseData.isMultimedia;
    this.isTokusoban = responseData.isTokusoban;
    this.isZeninServiceAri = responseData.isZeninServiceAri;
  }
}

//台割表ロック(排他制御・企画倒れ・権限)の判定
export const shouldLockDaiwari = (daiwariHeader) => {
  const params = new URL(location.href).searchParams;
  if (
    daiwariHeader.isLock ||
    daiwariHeader.isKikakuCancel ||
    daiwariHeader.isKengenLock ||
    daiwariHeader.isDaichoKakutei ||
    daiwariHeader.isWfLock ||
    params.get("isYomitori") == "true"
  ) {
    return true;
  } else {
    return false;
  }
};

//重版台割の枝番初回保存用
export const autoSaveJuhanEda = async (daiwariDataArray) => {
  // 原価情報を空にしてるのでdaiwariKakuteiArrayも空
  for (const daiwari of daiwariDataArray) {
    let json_array = {
      daiwariId: daiwari.daiwariId,
      daiwariKakuteiArray: [],
    };

    // 参照渡し回避
    let daiwariDataCopy = JSON.parse(JSON.stringify(daiwari.daiwariData));
    let postData = {};

    postData = {
      daiwariId: daiwari.daiwariId,
      daiwariData: JSON.stringify(formatBeforeUpdate(daiwariDataCopy)),
      kikakuId: daiwari.kikakuId,
      daiwariKakuteiHead: json_array,
      hachuId: Number(daiwari.hachuId),
    };

    //ローカル環境,試験環境,本番環境のURL判定
    const url = getUrl();
    await axios
      .post(`${url}/daiwari`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        if (response.status == 201);
        else {
          alert("重版枝番の自動保存に失敗しました。");
          console.log("重版枝初回自動保存:fail");
          // Backlogへ起票する
          // axios.post(
          //   `${this.url}/yUBSH4pQNYANpLWNTWtp8vyEY87RunoE7Y02r3kEY9GczWtKftuR7vXRyNEkPQoSZUtjCiUCiPXB5mpe4wza0GUo9oPSJGGtLw2NGpd8pzRBcCflCZe/`,
          //   {
          //     daiwariId: this.daiwariId,
          //     kikakuId: this.kikakuId,
          //     title: this.daiwariResponseData.title,
          //     daiwariData: JSON.stringify(formatBeforeUpdate(daiwariDataCopy)),
          //   },
          //   {
          //     headers: {
          //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          //     },
          //   }
          // );
        }
      })
      .catch((error) => {
        alert("重版枝番の自動保存に失敗しました。");
        console.log("★ catchブロック ★");
        console.log(`重版枝初回自動保存error:${error}`);
      });
  }
};
