<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">台割確定履歴</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="closeModal"
          class="modal__close"
        />
      </div>
      <!-- 台割確定履歴 -->
      <div class="modal__review">
        <div
          v-show="!this.daiwariKakuteiArray.length"
          class="modal__message_null"
        >
          ※確定履歴はありません。
        </div>
        <div class="modal__message" v-show="this.daiwariKakuteiArray.length">
          <table class="frame_table">
            <thead>
              <tr>
                <th>バージョン</th>
                <th>ユーザー名</th>
                <th>台割確定日時</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rireki, index) in this.daiwariKakuteiArray"
                :key="index"
              >
                <td>{{ rireki.version }}</td>
                <td>{{ rireki.daiwariKakuteiUserMei }}</td>
                <td>{{ rireki.daiwariKakuteiDateStr }}</td>
                <td>
                  <button
                    class="btn_syokai"
                    @click="
                      openOverlay(
                        rireki.kakuteiDaiwariId,
                        rireki.daiwariKakuteiUserMei,
                        rireki.daiwariKakuteiDateStr
                      )
                    "
                  >
                    照会
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    daiwariId: Number,
    url: String,
    kakuteiRireki_flg: Boolean,
  },
  emits: ["openOverlay", "hideModal", "count_timeOut"],
  data() {
    return {
      daiwariKakuteiArray: [],
    };
  },
  created() {
    //確定履歴の取得
    axios
      .get(
        `${this.url}/daiwari/get_kakutei_daiwari?daiwariId=${this.daiwariId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((res) => {
        this.daiwariKakuteiArray = [...res.data];
        this.daiwariKakuteiArray.sort((a, b) => a.version - b.version);
      })
      .catch((error) => {
        alert("確定履歴データが取得できませんでした\n");
        console.log(error);
      });
  },
  methods: {
    openOverlay(daiwariRirekiId, toroku_user_mei, toroku_date_str) {
      //確定履歴参照をオーバーレイで表示
      const overlayId = daiwariRirekiId;
      const overlayName = "overlay_daiwariHistoryReference";
      const overlayData = {
        torokuUserMei: toroku_user_mei,
        torokuDateStr: toroku_date_str,
        kakuteiRirekiFlg: this.kakuteiRireki_flg,
      };
      this.$emit("openOverlay", overlayName, overlayId, overlayData);
    },
    closeModal() {
      this.$emit("hideModal", "modal_daiwariKakuteiHistory");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 700px;
    height: 660px;
    &__message {
      font-size: 16px;
      max-height: 80%;
      overflow: auto;
      overflow-x: hidden;
      &_null {
        font-size: 20px;
      }
      &__top {
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .btn_gray {
    width: 100px;
    height: 40px;
    padding: 10px;
  }

  .frame_table {
    height: 100%;
    width: 100%;
  }

  .btn_syokai {
    margin-right: 5px;
  }
}
</style>
