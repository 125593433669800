<script setup>
import { reactive, watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import InputText from "./atoms/Input/InputText.vue";
import BtnGreen from "./atoms/button/BtnGreen.vue";
import BadgeHold from "./atoms/Badge/BadgeHold.vue";
import ModalShopList from "@/components/modal/ModalShopList.vue";
import selectBarSingle from "../components/molecules/selectBarSingle.vue";
import axios from "axios";
import { Notyf } from "notyf";
import ModalSyosiList from "./modal/ModalSyosiList.vue";

const $route = useRoute();
const $router = useRouter();
const store = useStore();
//propsの定義
const props = defineProps({
  isActive_SidebarFlg: {
    type: Boolean,
  },
  referenceFlg: {
    type: Boolean,
  },
});
//Emitの定義
const emit = defineEmits([
  "deleteOrder_fromNormal",
  "deleteAllOrder_fromNormal",
]);
const notyf = new Notyf();

let syoten_list = reactive({
  syotenMei: "",
  syotenCd: "",
});
let headers = [
  { title: "書誌CD", titleB: "ISBN", titleC: "書誌名" },
  { title: "本体価格" },
  { title: "良品" },
  { title: "数量" },
  { title: "注文種別" },
  { title: "保留設定" },
  { title: "営業担当者" },
  { title: "出荷可能数" },
  { title: "在庫ｽﾃｰﾀｽ" },
  { title: "取置数" },
  { title: "受注合計" },
  { title: "保留合計" },
  { title: "削除" },
];
//営業担当者
const salesList = store.getters["constant/getSalesList"];
// console.log("営業担当者", salesList);
//取引条件
const torihikiList = store.getters["constant/getTorihikiList"];
// console.log("取引条件", torihikiList);
// 表示する明細一覧
const selectedOrder = ref({
  bansen: "",
  changeLogList: [
    {
      numberOfTimes: -1,
      uuid: "",
    },
  ],
  chumonMode: -1, // 0:通常受注、1:マルチ受注
  hanmotoCd: "",
  juchuId: null,
  linked: false,
  normalJuchuDetails: [
    {
      availableAmount: -1,
      bansen: null,
      chumonRemarks: "",
      companyRemarks: "",
      hold: false,
      holdSum: null,
      hontaiPrice: -1,
      inputSum: -1,
      items: -1,
      janCd: "",
      juchuDetailId: -1,
      juchuId: -1,
      juchuKbn: null,
      juchuStatus: -1,
      meisaiId: -1,
      quantity: -1,
      salesCd: -1,
      salesName: "",
      syosiCd: "",
      syotenMei: null,
      tanzaku: "",
      title: "",
      torihikiCd: null,
      torioki: -1,
      toritugiCd: null,
      toritugiSyotenCd: null,
      userId: null, // 未定なので一旦複数設定
      userName: null,
      zaikoStatus: -1,
      zaikoStatusName: "",
    },
  ],
  registerDate: "",
  salesName: null,
  syotenMei: "",
  torihikiCd: -1,
  toritugiCd: "",
  toritugiSyotenCd: "",
  userId: -1,
  username: "",
});
// ログ表示か否か
const isLogMode = ref(Number($route.query.isLogMode) === 0 ? false : true);

// let propsData = { ...props };
// console.log("propsData", propsData);
// syoten_list.syotenMei = propsData.selectedOrder.syotenMei;
// syoten_list.syotenCd = propsData.selectedOrder.toritugiSyotenCd;

// データ取得---------------------------------
const getSelectedOrder = async () => {
  try {
    let url = "";
    if (isLogMode.value) {
      url = `${store.getters["getBaseUrl"]}/chumonReference/normalDetails-log?uuid=${$route.query.uuid}`;
    } else {
      url = `${store.getters["getBaseUrl"]}/chumonReference/normalDetails?juchuId=${$route.params.id}`;
    }

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    selectedOrder.value = res.data;
  } catch (error) {
    alert("受注情報が取得出来ませんでした。");
    console.log(error);
  }
};
getSelectedOrder();

//注文種別一括選択---------------------------
let selectBar_syubetu = {
  title: "注文種別一括",
  select_list: [
    { name: "補充", selectFlg: false, juchuKbn: 0 },
    { name: "客注", selectFlg: false, juchuKbn: 1 },
    { name: "(空白)", selectFlg: false, juchuKbn: null },
  ],
};
const updateSelectSyubetu = (newVal) => {
  selectedOrder.value.normalJuchuDetails.forEach((item) => {
    item.juchuKbn = newVal.juchuKbn;
  });
  addedMeisai.value.forEach((item) => {
    item.juchuKbn = newVal.juchuKbn;
  });
  addHighLight("syubetu");
};

//一括設定時のハイライト
let highLightTargrt = ref("");
const addHighLight = (selectedValue) => {
  highLightTargrt.value = selectedValue;
  setTimeout(() => {
    highLightTargrt.value = "";
  }, 1000);
};

// 明細追加--------------------------------------------
// 追加明細データ
const addedMeisai = ref([
  {
    // 登録で使用
    janCd: "", // ISBN
    quantity: null, // 数量
    hold: false, // 保留設定
    juchuKbn: null, // 注文種別
    zaikoStatus: null, // 在庫ステータス(value)
    salesCd: null, // 営業担当者(コード)

    // 表示のみ使用
    syosiCd: "", // 書誌コード
    title: "", // 書誌名
    hontaiPrice: null, // 本体価格
    items: null, // 良品
    availableAmount: null, // 出荷可能数
    zsName: "", // 在庫ステータス(表示名)
    torioki: null, // 取置数
    inputSum: null, // 受注合計
    holdSum: null, // 保留合計
    searchSyosiCord: "", // 商品コード検索用
  },
]);
addedMeisai.value.splice(0);
const addLineNumList = [1, 5, 10, 30, 50];
const selectedLineNum = ref(1);
const addNormalOrderList = () => {
  for (let i = 0; i < selectedLineNum.value; i++) {
    addedMeisai.value.push({
      janCd: "", // ISBN
      quantity: null, // 数量
      hold: false, // 保留設定
      juchuKbn: null, // 注文種別
      zaikoStatus: null, // 在庫ステータス(value)
      salesCd: null, // 営業担当者(コード)
      syosiCd: "", // 書誌コード
      title: "", // 書誌名
      hontaiPrice: null, // 本体価格
      items: null, // 良品
      availableAmount: null, // 出荷可能数
      zsName: "", // 在庫ステータス(表示名)
      torioki: null, // 取置数
      inputSum: null, // 受注合計
      holdSum: null, // 保留合計
    });
  }
};

//明細の削除-------------------------------------------
// 通常明細の削除
const deleteOrder = async (order) => {
  if (confirm(`「${order.title}」の注文を削除してもよろしいですか？`)) {
    try {
      const res = await axios.delete(
        `${store.getters["getBaseUrl"]}/chumonReference?juchuId=${order.juchuId}&juchuDetailId=${order.juchuDetailId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      await getSelectedOrder();
      store.commit("juchu/setToEditMeisaiData", selectedOrder.value);
      localStorage.setItem("onClickToroku", "true");
      notyf.success("削除しました");
      if (selectedOrder.value.normalJuchuDetails.length === 0) {
        window.close();
      }
    } catch (error) {
      alert("注文削除に失敗しました");
      console.log(error);
    }
  }
  // if (order.status === 3 || propsData.selectedOrder.orderStatus == 1) {
  //   if (confirm(`「${order.syosiMei}」の注文を削除してもよろしいですか？`)) {
  //     emit("deleteOrder_fromNormal", orderIndex);
  //   }
  // }
};

// 追加明細の削除
const deleteAddedOrder = async (order, orderIndex) => {
  if (confirm(`「${order.title}」の注文を削除してもよろしいですか？`)) {
    addedMeisai.value.splice(orderIndex, 1);
  }
  // if (order.status === 3 || propsData.selectedOrder.orderStatus == 1) {
  //   if (confirm(`「${order.syosiMei}」の注文を削除してもよろしいですか？`)) {
  //     emit("deleteOrder_fromNormal", orderIndex);
  //   }
  // }
};
// 受注削除
const deleteJuchu = async () => {
  if (confirm("受注データを全て削除します。よろしいですか？")) {
    try {
      await axios.delete(
        `${
          store.getters["getBaseUrl"]
        }/chumonReference/headline?juchuId=${Number(
          selectedOrder.value.juchuId
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // 削除成功時の処理
      localStorage.setItem("onClickToroku", "true");
      notyf.success({
        duration: 5000,
        message: "受注削除しました。\n 5秒後に画面を閉じます。",
      });
      setTimeout(() => {
        window.close();
      }, 5000);
    } catch (error) {
      if (error.response.status == 405) {
        // 受注に連携済データが含まれている場合のエラー
        notyf.error("連携済データが含まれるため受注削除できません");
      } else {
        // それ以外のエラー
        alert("受注削除に失敗しました");
      }
      console.log(error);
    }
  }
};
// const deleteAllItems_fromNormal = () => {
//   if (confirm(`全ての注文を削除してもよろしいですか？`)) {
//     emit("deleteAllOrder_fromNormal");
//   }
// };

//保留設定-------------------------------------------
// const changeHoldFlg = (order, event) => {
//   if (event.target.value === "保留") {
//     order.status = 3;
//   } else {
//     order.status = 1;
//   }
// };
// const holdFlg = (order) => {
//   if (order.status == 3) {
//     return "保留";
//   } else {
//     return "保留しない";
//   }
// };

// 書店検索系--------------------------------
//検索条件設定用
let modalName = ref("");
let selectedSyoten = reactive({
  syotenMei: selectedOrder.value.syotenMei,
  syotenCd: "",
  toritugiCd: selectedOrder.value.toritugiCd,
  toritugiSyotenCd: selectedOrder.value.toritugiSyotenCd,
  bansen: selectedOrder.value.bansen,
});

let searchSyotenConditions = reactive({
  syotenMei: selectedOrder.value.syotenMei,
  syotenCd: "",
  toritugiCd: selectedOrder.value.toritugiCd,
  toritugiSyotenCd: selectedOrder.value.toritugiSyotenCd,
  bansen: selectedOrder.value.bansen,
});

watch(searchSyotenConditions, () => {
  store.commit("search/setSyotenDetailState", searchSyotenConditions);
});

//モーダル開閉---------------------------
const showSampleModal = (openModalName) => {
  modalName.value = openModalName;
};
const closeSampleModal = () => {
  modalName.value = "";
};

// 書店
//プロパティごとに代入するとリアクティブが効くが、丸ごと代入は利かない
const ref_selectSyoten = (selectedValue) => {
  // console.log(selectedValue);
  Object.keys(selectedSyoten).forEach((key) => {
    selectedSyoten[key] = selectedValue[key];
    searchSyotenConditions[key] = selectedValue[key];
  });
  // 表示用データにも格納する
  selectedOrder.value.toritugiCd = selectedValue.toritugiCd;
  selectedOrder.value.toritugiSyotenCd = selectedValue.toritugiSyotenCd;
  selectedOrder.value.syotenMei = selectedValue.syotenMei;
  selectedOrder.value.bansen = selectedValue.bansen;
};
const searchSyoten = async () => {
  // 入力値が空だったら各値をリセットする
  if (searchSyotenConditions.toritugiSyotenCd === "") {
    selectedSyoten.syotenMei = "";
    selectedSyoten.syotenCd = "";
    // selectedSyoten.toritugiCd = "";
    selectedSyoten.toritugiSyotenCd = "";
    selectedSyoten.bansen = "";

    searchSyotenConditions.syotenMei = "";
    searchSyotenConditions.syotenCd = "";
    // searchSyotenConditions.toritugiCd = "";
    searchSyotenConditions.toritugiSyotenCd = "";
    searchSyotenConditions.bansen = "";

    selectedOrder.value.syotenMei = "";
    selectedOrder.value.syotenCd = "";
    // selectedOrder.value.toritugiCd = "";
    selectedOrder.value.toritugiSyotenCd = "";
    selectedOrder.value.bansen = "";
  } else {
    await store.dispatch("search/searchToritugiSyotenCd");
    const res = store.getters["search/getSyotenData"];
    if (res.syotenToritugiList.length > 0) {
      ref_selectSyoten(res.syotenToritugiList[0]);
    } else {
      //該当する書店が無ければ以前の状態に戻す
      searchSyotenConditions.toritugiSyotenCd = selectedSyoten.toritugiSyotenCd;
      selectedOrder.value.toritugiSyotenCd = selectedSyoten.toritugiSyotenCd;
      notyf.error("該当する書店がありません");
      // //該当する書店が無ければリセット
      // Object.keys(selectedSyoten).forEach((key) => {
      //   if (key !== "toritugiSyotenCd") {
      //     selectedSyoten[key] = "";
      //   }
      // });
    }
  }
};

// 書誌
let changeSyosiTarget = ref({});
let holdSyosiCord = "";
// 直接コード入力されたとき↓↓
const focusSyosiCd = (orderIndex) => {
  changeSyosiTarget.value = addedMeisai.value[orderIndex];
  holdSyosiCord = addedMeisai.value[orderIndex].searchSyosiCord;
};
const searchSyosi = async (event) => {
  // 入力値が空だったら各値をリセットする
  if (event.target.value === "") {
    changeSyosiTarget.value.janCd = ""; // ISBN
    changeSyosiTarget.value.zaikoStatus = null; // 在庫ステータス(value)
    changeSyosiTarget.value.syosiCd = ""; // 書誌コード
    changeSyosiTarget.value.title = ""; // 書誌名
    changeSyosiTarget.value.hontaiPrice = null; // 本体価格
    changeSyosiTarget.value.items = null; // 良品
    changeSyosiTarget.value.availableAmount = null; // 出荷可能数
    changeSyosiTarget.value.zsName = ""; // 在庫ステータス(表示名)
    changeSyosiTarget.value.torioki = null; // 取置数
    changeSyosiTarget.value.inputSum = null; // 受注合計
    changeSyosiTarget.value.holdSum = null; // 保留合計
    return;
  } else {
    const res = await axios.get(
      `${store.getters["getBaseUrl"]}/syosi?syosiCd=${event.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res.data.syosiList.length > 0) {
      ref_selectSyosi(res.data.syosiList[0]);
    } else {
      changeSyosiTarget.value.searchSyosiCord = holdSyosiCord;
    }
  }
};
// モーダルから検索されたとき↓↓
const openSearchSyosiModal = (orderIndex) => {
  showSampleModal("syosiModal");
  changeSyosiTarget.value = addedMeisai.value[orderIndex];
};
const ref_selectSyosi = (selectedValue) => {
  changeSyosiTarget.value.janCd = selectedValue.janCd;
  changeSyosiTarget.value.zaikoStatus = Number(selectedValue.zsId);
  changeSyosiTarget.value.syosiCd = selectedValue.syosiCd;
  changeSyosiTarget.value.title = selectedValue.title;
  changeSyosiTarget.value.hontaiPrice = selectedValue.hontaiPrice;
  changeSyosiTarget.value.items = selectedValue.items;
  changeSyosiTarget.value.availableAmount = selectedValue.availableAmount;
  changeSyosiTarget.value.zsName = selectedValue.zsName;
  changeSyosiTarget.value.torioki = selectedValue.torioki;
  changeSyosiTarget.value.inputSum = selectedValue.inputSum;
  changeSyosiTarget.value.holdSum = selectedValue.holdSum;
  if (modalName.value === "syosiModal")
    changeSyosiTarget.value.searchSyosiCord = selectedValue.janCd;
};

//取次書店CD
const inputToritugiSyotenCd = (e) => {
  searchSyotenConditions.toritugiSyotenCd = e;
  selectedOrder.value.toritugiSyotenCd = e;
};
// 番線入力時
const inputBansen = (e) => {
  selectedSyoten.bansen = e;
  searchSyotenConditions.bansen = e;
  selectedOrder.value.bansen = e;
};
// 数量変更----------------------------------------------------
let holdQuantity = 0;
const focusQuantity = (e, index, isAdded) => {
  holdQuantity = Number(e.target.value);
  if (isAdded) {
    addedMeisai.value[index].quantity = "";
  } else {
    selectedOrder.value.normalJuchuDetails[index].quantity = "";
  }
};
const blurQuantity = (e, index, isAdded) => {
  if (e.target.value === "") {
    if (isAdded) {
      addedMeisai.value[index].quantity = holdQuantity;
    } else {
      selectedOrder.value.normalJuchuDetails[index].quantity = holdQuantity;
    }
  }
  holdQuantity = 0;
};
const validateQuantity = (e, index, isAdded) => {
  //符号無し整数を判定
  if (!e.target.value.match(/^[0-9]+$/)) {
    if (isAdded) {
      addedMeisai.value[index].quantity = 0;
    } else {
      selectedOrder.value.normalJuchuDetails[index].quantity = 0;
    }
  }
};

// バリデーション----------------------------------------------
const isValidTanzaku = computed(() => {
  if (
    selectedOrder.value.normalJuchuDetails[0].tanzaku.length &&
    (selectedOrder.value.normalJuchuDetails[0].tanzaku.length > 16 ||
      !isZenkaku(selectedOrder.value.normalJuchuDetails[0].tanzaku))
  ) {
    return true;
  } else {
    return false;
  }
});
const isValidChumonRemarks = computed(() => {
  if (
    selectedOrder.value.normalJuchuDetails[0].chumonRemarks.length &&
    (selectedOrder.value.normalJuchuDetails[0].chumonRemarks.length > 30 ||
      !isZenkaku(selectedOrder.value.normalJuchuDetails[0].chumonRemarks))
  ) {
    return true;
  } else {
    return false;
  }
});

const isZenkaku = (value) => {
  // eslint-disable-next-line no-control-regex
  const pattern = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;
  return value.match(pattern);
};

// 登録処理----------------------------------------------------
const onClickRegister = async () => {
  // エラーバリデーション
  // 【ヘッダー】書店情報
  if (
    selectedOrder.value.syotenMei === "" ||
    selectedOrder.value.torihikiCd === "" ||
    selectedOrder.value.toritugiSyotenCd === ""
  ) {
    notyf.error("書店コードと書店名を入力してください");
    return;
  }
  // 【ヘッダー】短冊・伝票備考
  if (isValidTanzaku.value || isValidChumonRemarks.value) {
    notyf.error("書店・取次情報入力のエラーメッセージを確認してください");
    return;
  }
  // 【既存・追加明細】数量
  let noQuantity = false;
  selectedOrder.value.normalJuchuDetails.forEach((item) => {
    if (
      item.quantity === "" ||
      item.quantity === null ||
      Number(item.quantity) === 0
    ) {
      noQuantity = true;
    }
  });
  addedMeisai.value.forEach((item) => {
    if (
      (item.quantity === "" ||
        item.quantity === null ||
        Number(item.quantity) === 0) &&
      item.janCd !== ""
    ) {
      noQuantity = true;
    }
  });
  if (noQuantity) {
    notyf.error("数量を入力してください");
    return;
  }
  // 【追加明細】書誌選択
  let noSyosi = false;
  addedMeisai.value.forEach((item) => {
    if (
      typeof item.quantity == "number" &&
      item.quantity > 0 &&
      item.janCd == ""
    ) {
      noSyosi = true;
    }
  });
  if (noSyosi) {
    notyf.error("書誌を選択してください");
    return;
  }

  // 追加明細の空データを削除する
  addedMeisai.value = addedMeisai.value.filter((item) => {
    return !(
      (item.quantity == null ||
        item.quantity == "" ||
        Number(item.quantity) == 0) &&
      item.janCd == ""
    );
  });

  // 送付データ作成
  let patchData = {
    juchuId: selectedOrder.value.juchuId,
    toritugiSyotenCd: selectedOrder.value.toritugiSyotenCd,
    syotenMei: selectedOrder.value.syotenMei,
    bansen: selectedOrder.value.bansen,
    toritugiCd: selectedOrder.value.toritugiCd,
    torihikiCd: selectedOrder.value.torihikiCd,
    salesName:
      selectedOrder.value.salesName !== null
        ? selectedOrder.value.salesName
        : 0,
    normalJuchuDetails: [],
  };
  selectedOrder.value.normalJuchuDetails.forEach((normalJuchu) => {
    patchData.normalJuchuDetails.push({
      juchuDetailId: normalJuchu.juchuDetailId,
      meisaiId: normalJuchu.meisaiId,
      janCd: normalJuchu.janCd,
      quantity: Number(normalJuchu.quantity),
      hold: normalJuchu.hold,
      juchuKbn: normalJuchu.juchuKbn,
      tanzaku: selectedOrder.value.normalJuchuDetails[0].tanzaku,
      chumonRemarks: selectedOrder.value.normalJuchuDetails[0].chumonRemarks,
      companyRemarks: selectedOrder.value.normalJuchuDetails[0].companyRemarks,
      zaikoStatus: normalJuchu.zaikoStatus,
      salesCd: normalJuchu.salesCd !== null ? normalJuchu.salesCd : 0,
    });
  });
  addedMeisai.value.forEach((addedItem) => {
    patchData.normalJuchuDetails.push({
      juchuDetailId: null,
      meisaiId: null,
      janCd: addedItem.janCd,
      quantity: Number(addedItem.quantity),
      hold: addedItem.hold,
      juchuKbn: addedItem.juchuKbn,
      tanzaku: selectedOrder.value.normalJuchuDetails[0].tanzaku,
      chumonRemarks: selectedOrder.value.normalJuchuDetails[0].chumonRemarks,
      companyRemarks: selectedOrder.value.normalJuchuDetails[0].companyRemarks,
      zaikoStatus: addedItem.zaikoStatus,
      salesCd: addedItem.salesCd !== null ? addedItem.salesCd : 0,
    });
  });

  try {
    const res = await axios.post(
      `${store.getters["getBaseUrl"]}/chumonReference/edit`,
      patchData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    localStorage.setItem("onClickToroku", "true");
    addedMeisai.value.splice(0);
    notyf.success("データを更新しました");
    getSelectedOrder();
  } catch (error) {
    notyf.error("データの更新に失敗しました");
    console.log(error);
  }
};
const inputTanzaku = (e) => {
  console.log(e);
};

// ログの表示---------------------------------------
const selectedUuid = ref({});
const showLog = () => {
  if (Object.keys(selectedUuid.value).length) {
    // 遷移処理
    let resolvedRoute = $router.resolve({
      name: "OrderConfirmEdit",
      params: { id: $route.params.id },
      query: {
        isActiveNormal: 1,
        isLogMode: 1,
        isActiveReference: 1,
        numberOfTimes: selectedUuid.value.numberOfTimes,
        uuid: selectedUuid.value.uuid,
      },
    });
    window.open(
      resolvedRoute.href,
      "_blank",
      "width=1000,height=600,scrollbars=1,location=0,menubar=0,toolbar=0,status=1,directories=0,resizable=1,left=" +
        (window.screen.width - 1000) / 2 +
        ",top=" +
        (window.screen.height - 650) / 2
    );
  }
};

// window.onbeforeunload = function (e) {
//   localStorage.setItem("onClickToroku", "true");
// };
</script>

<template>
  <div
    class="NormalLayout"
    :class="{
      SideBar_False: !props.isActive_SidebarFlg,
      SideBar_True: props.isActive_SidebarFlg,
    }"
  >
    <ModalShopList
      v-if="modalName === 'shopModal'"
      :toritugiCd="selectedOrder.toritugiCd"
      @close="closeSampleModal()"
      @ref_selectSyoten="ref_selectSyoten"
    />
    <ModalSyosiList
      v-if="modalName === 'syosiModal'"
      @close="closeSampleModal()"
      @ref_selectSyosi="ref_selectSyosi"
    />
    <!-- 通常カートレイアウト -->
    <div class="NormalLayout_Top">
      <!-- <p v-show="selectedOrder.orderStatus === 1">連携前</p>
      <p v-show="selectedOrder.orderStatus === 2">連携済</p>
      <p v-show="selectedOrder.orderStatus === 3">保留</p> -->
      <p class="NormalLayout_Title" v-if="isLogMode">
        受注照会 変更履歴画面（{{ $route.query.numberOfTimes }}回目）
      </p>
      <p class="NormalLayout_Title" v-else-if="!referenceFlg">受注修正画面</p>
      <p class="NormalLayout_Title" v-else>受注照会画面</p>

      <div class="NormalLayout_TopWrapper">
        <div class="NormalLayout_TopWrapper_Title">書店・取次情報入力</div>
        <div class="NormalLayout_TopWrapper_Lead">
          <ul class="NormalLayout_TopWrapper_LeadItem">
            <li>
              <p>受注番号：</p>
              <span>{{ selectedOrder.juchuId }}</span>
            </li>
            <li>
              <p>受注日付：</p>
              <span>{{ selectedOrder.registerDate }}</span>
            </li>
            <li>
              <p>入力担当者：</p>
              <span>{{ selectedOrder.username }}</span>
            </li>
          </ul>
        </div>
        <div class="NormalLayout_TopWrapper_Container">
          <!-- 左側 -->
          <div class="NormalLayout_TopWrapper_Content">
            <div class="NormalLayout_TopWrapper_List">
              <div class="NormalLayout_TopWrapper_ListItem">
                <p class="NormalLayout_ListTitle">書店</p>
                <div class="NormalLayout_ListContent_Large">
                  <!-- <InputText
                  class="NormalLayout_Input_Yellow"
                  :value="syoten_list.syotenMei"
                  disabled
                />
                <InputText
                  class="NormalLayout_Input_Gray"
                  :value="syoten_list.syotenCd"
                  disabled
                /> -->
                  <InputText
                    class="NormalLayout_Input_Yellow"
                    @dblclick="showSampleModal('shopModal')"
                    :value="selectedOrder.toritugiSyotenCd"
                    :type="'text'"
                    :disabled="referenceFlg"
                    @onInput="inputToritugiSyotenCd"
                    @onChange="searchSyoten"
                    onkeydown="return event.keyCode !== 69"
                  />
                  <InputText
                    class="NormalLayout_Input_Gray"
                    :value="selectedOrder.syotenMei"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="NormalLayout_TopWrapper_List">
              <div class="NormalLayout_TopWrapper_ListItem">
                <p class="NormalLayout_ListTitle">番線/作業コード</p>
                <div class="NormalLayout_ListContent">
                  <InputText
                    class="NormalLayout_Input_Normal"
                    :disabled="referenceFlg"
                    @onInput="inputBansen"
                    :value="selectedOrder.bansen"
                  />
                </div>
              </div>
            </div>
            <div class="sideBySide">
              <div class="NormalLayout_TopWrapper_List">
                <div class="NormalLayout_TopWrapper_ListItem">
                  <p class="NormalLayout_ListTitle">取引条件</p>
                  <div class="NormalLayout_ListContent">
                    <select
                      class="CartNormal_ListContent_Select"
                      v-model="selectedOrder.torihikiCd"
                      :disabled="referenceFlg"
                    >
                      <option
                        v-for="(option, index) in torihikiList"
                        :value="option.torihikiCd"
                        :key="index"
                      >
                        {{ option.torihikiMei }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedOrder.chumonMode == 0"
                class="NormalLayout_TopWrapper_List"
              >
                <div class="NormalLayout_TopWrapper_ListItem">
                  <p class="NormalLayout_ListTitle">営業担当者</p>
                  <div class="NormalLayout_ListContent">
                    <select
                      v-model="selectedOrder.salesName"
                      :disabled="referenceFlg"
                    >
                      <option
                        v-for="(option, index) in salesList"
                        :value="option.salesCd"
                        :key="index"
                      >
                        {{ option.salesName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右側 -->
          <div class="NormalLayout_TopWrapper_Content">
            <div class="NormalLayout_TopWrapper_List">
              <div class="NormalLayout_TopWrapper_ListItem">
                <p class="NormalLayout_ListTitle">短冊メモ</p>
                <div class="NormalLayout_ListContent_Large">
                  <!-- <InputText
                  :value="selectedOrder.normalJuchuDetails[0].tanzaku"
                  @onChange="inputTanzaku(e)"
                /> -->
                  <input
                    type="text"
                    v-model="selectedOrder.normalJuchuDetails[0].tanzaku"
                    class="NormalLayout_Input_Large"
                    :disabled="referenceFlg"
                  />
                </div>
              </div>
              <!-- <div class="NormalLayout_TopWrapper_ListItem">
              <p class="NormalLayout_ListTitle">短冊メモ2</p>
              <div class="NormalLayout_ListContent">
                <InputText disabled />
              </div>
            </div> -->
              <p v-if="isValidTanzaku">
                <span class="NormalLayout_ListContent_Error"
                  >※「短冊メモ」は全角16文字以内で入力してください</span
                >
              </p>
            </div>
            <div class="NormalLayout_TopWrapper_List">
              <div class="NormalLayout_TopWrapper_ListItem">
                <p class="NormalLayout_ListTitle">伝票備考</p>
                <div class="NormalLayout_ListContent_Large">
                  <!-- <InputText class="NormalLayout_Input_Large" disabled /> -->
                  <input
                    type="text"
                    v-model="selectedOrder.normalJuchuDetails[0].chumonRemarks"
                    class="NormalLayout_Input_Large"
                    :disabled="referenceFlg"
                  />
                </div>
              </div>
              <p v-if="isValidChumonRemarks">
                <span class="NormalLayout_ListContent_Error"
                  >※「伝票備考」は全角30文字以内で入力してください</span
                >
              </p>
            </div>
            <div class="NormalLayout_TopWrapper_List">
              <div class="NormalLayout_TopWrapper_ListItem">
                <p class="NormalLayout_ListTitle">社内備考</p>
                <div class="NormalLayout_ListContent_Large">
                  <!-- <InputText class="NormalLayout_Input_Large" disabled /> -->
                  <input
                    type="text"
                    v-model="selectedOrder.normalJuchuDetails[0].companyRemarks"
                    class="NormalLayout_Input_Large"
                    :disabled="referenceFlg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="NormalLayout_Bottom"
      v-if="selectedOrder.normalJuchuDetails.length"
    >
      <!-- <div class="NormalLayout_Bottom_Wrapper">
        <BtnGreenSmall
          v-show="!referenceFlg && selectedOrder.orderStatus == 1"
          @click="deleteAllItems_fromNormal"
          >すべて削除</BtnGreenSmall
        >
      </div> -->
      <div class="NormalLayout_Bottom_BtnWrapper">
        <selectBarSingle
          :selectBarList_props="selectBar_syubetu"
          @updateSelectValue="updateSelectSyubetu"
        />
        <button
          @click="deleteJuchu"
          class="btn_green"
          v-if="!isLogMode & !referenceFlg"
        >
          受注削除
        </button>
      </div>
      <div class="NormalLayout_Bottom_WrapperTable">
        <table class="NormalLayout_FrameTable">
          <thead>
            <tr>
              <template v-for="(header, index) in headers" :key="index">
                <th
                  v-if="
                    !(
                      selectedOrder.chumonMode == 0 &&
                      header.title == '営業担当者'
                    )
                  "
                  class="NormalLayout_FrameTable_Tate NormalLayout_FrameTable_minW_s"
                >
                  <p v-if="!header.titleB">{{ header.title }}</p>
                  <div v-if="header.titleB">
                    <p
                      v-if="header.titleC"
                      class="NormalLayout_FrameTable_Syosi"
                    >
                      {{ header.title }}<br />{{ header.titleB }}<br />{{
                        header.titleC
                      }}
                    </p>
                    <p v-else>{{ header.title }}<br />{{ header.titleB }}</p>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <!-- ここから受注明細 -->
          <tbody>
            <!-- 既存明細 -->
            <tr
              v-for="(order, index) in selectedOrder.normalJuchuDetails"
              :key="index"
              :class="{
                NormalLayout_Table_isSelected: order.selectedMeisaiFlg,
              }"
            >
              <td class="NormalLayout_FrameTable_Flex Text_Left">
                <div>
                  {{ order.syosiCd }}
                  <br />{{ order.janCd }}<br />
                  {{ order.title }}
                  <span v-if="order.status === 3"><BadgeHold /></span>
                </div>
              </td>
              <td>
                {{
                  order.hontaiPrice !== null
                    ? order.hontaiPrice.toLocaleString()
                    : order.hontaiPrice
                }}
              </td>
              <td>
                {{ order.items }}
              </td>
              <td>
                <input
                  class="NormalLayout_Input_Small"
                  type="number"
                  v-model="order.quantity"
                  :disabled="referenceFlg"
                  @focus="focusQuantity($event, index, false)"
                  @change="validateQuantity($event, index, false)"
                  @blur="blurQuantity($event, index, false)"
                />
              </td>
              <td :class="{ selectHighLight: highLightTargrt == 'syubetu' }">
                <!-- <SelectNormal
                  :disabled="order.juchuStatus == 2"
                  :options="[
                    { name: '補充', value: '補充' },
                    { name: '客注', value: '客注' },
                  ]"
                /> -->
                <select
                  :disabled="referenceFlg"
                  v-model="order.juchuKbn"
                  :class="{
                    selectHighLight: highLightTargrt == 'syubetu',
                  }"
                >
                  <option :value="null">(空白)</option>
                  <option :value="0">補充</option>
                  <option :value="1">客注</option>
                </select>
              </td>
              <td>
                <select :disabled="referenceFlg" v-model="order.hold">
                  <option :value="false">保留しない</option>
                  <option :value="true">保留</option>
                </select>
              </td>
              <td v-if="selectedOrder.chumonMode != 0">
                <select v-model="order.salesCd" :disabled="referenceFlg">
                  <option
                    v-for="(option, index) in salesList"
                    :value="option.salesCd"
                    :key="index"
                  >
                    {{ option.salesName }}
                  </option>
                </select>
              </td>
              <td>
                {{ order.availableAmount }}
              </td>
              <td>
                {{ order.zaikoStatusName }}
              </td>
              <td>{{ order.torioki }}</td>
              <td>{{ order.inputSum }}</td>
              <td>{{ order.holdSum }}</td>
              <td>
                <font-awesome-icon
                  v-if="!referenceFlg"
                  class="NormalLayout_FrameTable_Icon"
                  icon="trash-can"
                  @click="deleteOrder(order)"
                />
              </td>
            </tr>
            <!-- 追加明細 -->
            <tr v-for="(order, index) in addedMeisai" :key="index">
              <td class="NormalLayout_FrameTable_Flex Text_Left">
                <div>
                  <input
                    class="InputText_Input NormalLayout_Input_Yellow"
                    type="text"
                    placeholder="商品コード"
                    v-model="order.searchSyosiCord"
                    @dblclick="openSearchSyosiModal(index)"
                    @focus="focusSyosiCd(index)"
                    @change="searchSyosi"
                  />
                  <br />
                  {{ order.title }}
                  <span v-if="order.status === 3"><BadgeHold /></span>
                </div>
              </td>
              <td>
                {{
                  order.hontaiPrice !== null
                    ? order.hontaiPrice.toLocaleString()
                    : order.hontaiPrice
                }}
              </td>
              <td>
                {{ order.items }}
              </td>
              <td>
                <input
                  class="NormalLayout_Input_Small"
                  type="number"
                  v-model="order.quantity"
                  :disabled="referenceFlg"
                  @focus="focusQuantity($event, index, true)"
                  @change="validateQuantity($event, index, true)"
                  @blur="blurQuantity($event, index, true)"
                />
              </td>
              <td :class="{ selectHighLight: highLightTargrt == 'syubetu' }">
                <!-- <SelectNormal
                  :disabled="order.juchuStatus == 2"
                  :options="[
                    { name: '補充', value: '補充' },
                    { name: '客注', value: '客注' },
                  ]"
                /> -->
                <select
                  :disabled="referenceFlg"
                  v-model="order.juchuKbn"
                  :class="{
                    selectHighLight: highLightTargrt == 'syubetu',
                  }"
                >
                  <option :value="null">(空白)</option>
                  <option :value="0">補充</option>
                  <option :value="1">客注</option>
                </select>
              </td>
              <td>
                <select :disabled="referenceFlg" v-model="order.hold">
                  <option :value="false">保留しない</option>
                  <option :value="true">保留</option>
                </select>
              </td>
              <td v-if="selectedOrder.chumonMode != 0">
                <select v-model="order.salesCd" :disabled="referenceFlg">
                  <option
                    v-for="(option, index) in salesList"
                    :value="option.salesCd"
                    :key="index"
                  >
                    {{ option.salesName }}
                  </option>
                </select>
              </td>
              <td>
                {{ order.availableAmount }}
              </td>
              <td>
                {{ order.zsName }}
              </td>
              <td>{{ order.torioki }}</td>
              <td>{{ order.inputSum }}</td>
              <td>{{ order.holdSum }}</td>
              <td>
                <font-awesome-icon
                  v-if="!referenceFlg"
                  class="NormalLayout_FrameTable_Icon"
                  icon="trash-can"
                  @click="deleteAddedOrder(order, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="NormalLayout_Bottom_WrapperRegister">
        <div class="flex_left">
          <template v-if="!selectedOrder.linked && !isLogMode && !referenceFlg">
            <select class="Move_With_Enter" v-model="selectedLineNum">
              <option
                v-for="(line, index) in addLineNumList"
                :value="line"
                :key="index"
              >
                {{ line }}
              </option></select
            >行
            <button @click="addNormalOrderList" class="btn_greenRev_small">
              + 明細追加
            </button>
          </template>
        </div>
        <div class="flex_right">
          <div v-if="!isLogMode && selectedOrder.changeLogList.length > 0">
            <label for="select-log">変更履歴</label>
            <select id="select-log" v-model="selectedUuid">
              <option
                v-for="(log, logIndex) of selectedOrder.changeLogList"
                :key="logIndex"
                :value="log"
              >
                {{ log.numberOfTimes }}回目
              </option>
            </select>
            <button class="btn_orange_small" @click="showLog">表示</button>
          </div>
          <BtnGreen
            @clickBtn="onClickRegister()"
            class="NormalLayout_Btn_Register"
            v-show="!referenceFlg"
            >登録</BtnGreen
          >
        </div>
      </div>
    </div>
    <div
      class="NormalLayout_Bottom_Message"
      v-if="!selectedOrder.normalJuchuDetails.length"
    >
      書誌情報はありません
    </div>
  </div>
</template>

<style scoped lang="scss">
// 注文種別のハイライト用
@keyframes selectHighLight_animation {
  0% {
    background: none;
  }

  100% {
    background: #ffdfbf;
  }
}
.selectHighLight {
  animation: selectHighLight_animation 0.5s ease infinite alternate;
}
.NormalLayout {
  position: fixed;
  top: 10px;
  bottom: 0;
  overflow-y: auto;
  padding: 0 20px 20px 10px;
  // p,
  th,
  td {
    font-size: 12px;
  }
  &_Title {
    font-size: 20px;
    margin-left: 50px;
    padding-bottom: 20px;
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      display: inline-block;
      width: 25px;
      height: 5px;
      background: #20c997;
      @media screen and (max-width: 760px) {
        left: 20px;
      }
    }
  }
  &_Table {
    &_isSelected {
      border: 3px solid #ff0000;
    }
  }
  &_SelectContainer {
    display: flex;
    background: #f2f1f1;
    padding: 5px 25px;
    width: fit-content;
    height: 30px;
    border-radius: 50px;
    font-size: 14px;
    margin-bottom: 5px;
  }
  &_SelectArea {
    padding: 0 5px;
    &_Item {
      cursor: pointer;
      padding: 3px 10px;
      &:hover {
        background: #ff8000;
        border-radius: 50px;
        color: #ffffff;
      }
    }
  }
  &_IsSelected {
    background: #ff8000;
    border-radius: 50px;
    color: #ffffff;
  }

  &_Bottom {
    margin-top: 15px;
    //
    &_BtnWrapper {
      display: flex;
      gap: 5px;
    }
    &_Wrapper {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      &Delete {
        width: 130px;
        display: flex;
        justify-content: flex-start;
      }
      &Register {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 100%;

        .flex_right {
          display: flex;
          justify-content: flex-end;
          gap: 30px;
        }
        label {
          font-weight: bold;
        }
        .btn_orange_small {
          @extend .common_btn_animation;
          background: #ff8000;
          color: #fff;
          margin: 2px 0px;
          padding: 0 10px;
          font-size: 12px;
          height: 30px;
          border-radius: 5px;
          font-weight: 800;
          cursor: pointer;
        }
        .btn_greenRev_small {
          @extend .common_btn_animation;
          background: #fff;
          color: #20c997;
          border: 1px solid #20c997;
          margin: 2px 0px;
          padding: 0 10px;
          font-size: 12px;
          height: 30px;
          border-radius: 5px;
          font-weight: 800;
          cursor: pointer;
        }
      }
    }
    &_Message {
      font-weight: 500;
      font-size: 18px;
      display: flex;
      justify-content: center;
      width: 700px;
    }
  }
  &_Top {
    margin: 0 auto;

    &Wrapper {
      @media screen and (max-width: 400px) {
        p {
          font-size: 2px;
        }
      }
      width: 975px;
      padding: 15px 20px;
      background: #ffffff;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;

      &_Title {
        height: 44px;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #848484;
        margin-bottom: 10px;
      }
      &_Lead {
        margin-left: 15px;
        &Item li {
          display: inline-block;
          width: 30%;
          font-size: 14px;
          & p {
            display: inline;
            font-weight: 700;
          }
        }
      }
      &_Container {
        display: flex;
        gap: 10px;
      }
      &_Content {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // 横並び用
        .sideBySide {
          display: flex;
          width: 461px;
          .NormalLayout {
            &_TopWrapper_List {
              width: 50%;
            }
            &_ListTitle {
              width: 40%;
            }
            &_ListContent {
              width: 60%;
            }
          }
        }
      }
      &_List {
        display: flex;
        flex-direction: column;

        &Item {
          display: flex;
        }
      }
    }
  }
  &_ListTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 35px;
    background: #e2f5ef;
  }
  &_ListContent {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 164px;
    height: 35px;
    background: #f7f7f7;
    padding: 5px;
    &_Large {
      width: 350px;
      display: flex;
      align-items: center;
      gap: 5px;
      height: 35px;
      background: #f7f7f7;
      padding: 5px;
    }
    &_Error {
      color: red;
      font-size: 11px;
      display: inline-block;
      width: 100%;
    }
  }
  &_Input {
    &_Yellow {
      background: #ffffcc;
      height: 30px;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;
    }
    &_Gray {
      background: #e6e6e6;
      width: 191px;
      height: 30px;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;
    }
    &_Normal {
      width: 121px;
      height: 30px;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;
    }
    &_Large {
      width: 344px;
      height: 30px;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;
    }
    &_Small {
      width: 40px;
      height: 30px;
      border-radius: 1px;
      text-align: center;
      border: 1px solid #848484;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  &_Btn {
    &_Register {
      width: 100px;
      height: 40px;
      border-radius: 10px;
    }
  }
  &_FrameTable {
    height: 40px;
    margin: 15px 0px;
    max-width: 100%;
    & th {
      background: #2a3f54;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      color: #ffffff;
    }
    & td {
      width: 264px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #848484;
      box-sizing: border-box;
      text-align: center;
    }
    &_minW {
      &_xs {
        min-width: 45px;
      }
      &_s {
        min-width: 80px;
      }
      &_m {
        min-width: 100px;
      }
      &_l {
        min-width: 230px;
      }
    }
    &_Tate {
      height: 40px;
    }
    &_Syosi {
      width: 257px;
    }
    &_Icon {
      cursor: pointer;
      font-size: 20px;
      color: #2a3f54;
      &:hover {
        opacity: 70%;
      }
      &:active {
        opacity: 100%;
      }
    }
    .Text_Left {
      padding-left: 4px;
      text-align: left;
    }
  }
  @media screen and (max-width: 760px) {
    padding: 0 10px 10px 0px;
    &_Top {
      &Wrapper {
        span {
          font-size: 2px;
          display: block;
        }
        margin: 0 auto;
        width: 90%;
        padding: 10px 10px;
        &_Title {
          font-weight: 600;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #848484;
          margin-bottom: 5px;
        }
        &_Container {
          flex-direction: column;
          gap: 5px;
        }
        &_Content {
          margin: 5px 0px 0px 5px;
          .sideBySide {
            flex-direction: column;
            gap: 10px;
          }
        }
        &_List {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
    &_Bottom {
      margin-top: 50px;
      &_Wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &Select {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        &Table {
          max-width: 100%;
          overflow-y: auto;
          max-height: 300px;
        }
      }
    }
    &_List {
      &Title {
        min-width: 80px;
      }
    }

    &_Input {
      &_Yellow {
        background: #ffffcc;
        min-width: 60%;
        height: 30px;
        border: 1px solid #848484;
        box-sizing: border-box;
        border-radius: 5px;
      }
      &_Gray {
        background: #e6e6e6;
        width: 30%;
        height: 30px;
        border: 1px solid #848484;
        box-sizing: border-box;
        border-radius: 5px;
      }
      &_Large {
        width: 150px;
        height: 30px;
        border: 1px solid #848484;
        box-sizing: border-box;
        border-radius: 5px;
      }
      &_Small {
        height: 30px;
        border-radius: 1px;
        border: 1px solid #848484;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }
}

// ボタン
.btn_green {
  @extend .common_btn_animation;
  color: #fff;
  background: #20c997;
  border-radius: 10px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}
</style>
