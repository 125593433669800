<template>
  <div id="overlay" @click="closeModal">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p v-if="tantou_ikkatuFlg" class="modal__title">担当者一括登録</p>
        <p v-else class="modal__title">担当者検索</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="closeModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <div class="modal__review__search-container">
          <select
            class="search_box"
            :value="selectedBusyoMei"
            @change="selectBusyo"
          >
            <option
              v-for="busyo in mst_busyo"
              :key="busyo.busyoId"
              :value="busyo.busyoMei"
            >
              {{ busyo.busyoMei }}
            </option>
          </select>

          <input
            type="text"
            class="search_box"
            v-model="searchWord"
            @keydown.enter="searchTantou"
          />
          <button class="btn_gray" @click="searchTantou">検索</button>
        </div>
        <p class="modal__review__search-result" v-show="searched_user.length">
          検索結果({{ searched_user.length }}件)
        </p>
        <p class="modal__review__search-result" v-show="searchResult_nullFlg">
          検索結果はありません
        </p>
        <ul
          class="modal__review__result-container"
          v-show="!searched_user.length"
        >
          <li
            class="modal__review__result-name"
            v-for="user in mst_user"
            :key="user.userId"
            @click="selectUser(user)"
          >
            {{ user.userMei }}
            <span class="modal__review__result-busyo"
              >/ 部門:{{ user.busyoMei }}</span
            >
          </li>
        </ul>
        <ul
          class="modal__review__result-container"
          v-show="searched_user.length"
        >
          <li
            class="modal__review__result-name"
            v-for="user in searched_user"
            :key="user.userId"
            @click="selectUser(user)"
          >
            {{ user.userMei }}
            <span class="modal__review__result-busyo"
              >/部門:{{ user.busyoMei }}</span
            >
          </li>
        </ul>
        <ul class="modal__review__badge-container">
          <li
            class="modal__review__badge-selected"
            v-for="(user, index) in selectedUser"
            :key="user.user_id"
          >
            <span class="modal__review__badge-name"
              >{{ user.user_last_name }}{{ user.user_first_name }}</span
            >
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="modal__review__badge-cancel"
              @click="removeUser(index)"
            />
          </li>
        </ul>
        <button class="btn_blue" @click="updateTantouMei">決定</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    new Promise((resolve) => {
      //部署マスタを取得
      axios
        .get(`${this.url}/get_busyo?busyo_mei=`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          this.mst_busyo = response.data;
          this.setInitialBusyo();
          resolve();
        })
        .catch((error) => {
          alert("部署データが取得できませんでした\n" + error);
        });
    }).then(() => {
      //担当者マスタを取得
      axios
        .get(
          `${this.url}/get_user?user_mei=&busyo_id=${this.selectedBusyoId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.mst_user = response.data;
        })
        .catch((error) => {
          alert("担当者データが取得できませんでした\n" + error);
        });
      if (this.tantou_ikkatuFlg) {
        axios
          .get(`${this.url}/get_tanto_user?kikaku_id=${this.kikakuId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          })
          .then((response) => {
            this.searched_user = response.data;
            this.searched_user.forEach((user) => {
              if (user.userFirstName) {
                this.selectUser(user);
              }
            });
          })
          .catch((error) => {
            alert("担当者データが取得できませんでした\n" + error);
          });
      }
    });
  },
  mounted() {
    //一覧画面から担当者モーダルを開いた場合
    if (this.props_tantou) {
      this.props_tantou.tantou.forEach((tantou) => {
        this.selectedUser.push(tantou);
      });
      this.isPropsTantou = true;
      //サイドバーから担当者モーダルを開いた場合
    } else if (this.selectedSakuhin) {
      this.selectedSakuhin.tantou.forEach((tantou) =>
        this.selectedUser.push(tantou)
      );
    }
  },
  props: {
    selectedSakuhin: Object,
    props_tantou: Object,
    kikakuId: Number,
    daiwariData: Object,
    changeTantouToDaiwari: Function,
    tantoBusyoId: Number,
    url: String,
    tantou_ikkatuFlg: Boolean,
  },
  emits: ["hideModal", "count_timeOut", "updateTantouMei"],
  data() {
    return {
      //選択済み担当者が格納される↓
      mst_user: [],
      mst_busyo: [],
      searched_user: [],
      selectedUser: [],
      doubleCheck_selectedUser: [],
      userIsDuplicate: false,
      searchWord: "",
      searchWord_result: "",
      searchResult_nullFlg: false,
      selectedBusyoMei: null,
      selectedBusyoId: "",
      isPropsTantou: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("hideModal", "modal_tantou", this.isPropsTantou);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },

    //担当者選択
    selectUser(clickUser) {
      this.userIsDuplicate = false;
      this.selectedUser.forEach((selected) => {
        if (selected.user_id === clickUser.userId) {
          this.userIsDuplicate = true;
        }
      });
      if (this.userIsDuplicate === false) {
        // 各データの生成
        let userLastName = "";
        let userFirstName = "";
        // システムユーザー用
        if (
          clickUser.userLastName === null &&
          clickUser.userFirstName === null
        ) {
          userLastName = clickUser.userMei;
        }
        // 部署名用
        else if (clickUser.userFirstName === null) {
          userLastName = clickUser.userLastName;
        } else if (clickUser.userLastName === null) {
          userFirstName = clickUser.userFirstName;
        }
        // その他担当データ用
        else {
          userFirstName = clickUser.userFirstName;
          userLastName = clickUser.userLastName;
        }
        this.selectedUser.push({
          user_id: clickUser.userId,
          user_last_name: userLastName,
          user_first_name: userFirstName,
        });
      }
    },
    //担当者選択取り消し
    removeUser(removeIndex) {
      this.selectedUser.splice(removeIndex, 1);
    },
    //担当者情報更新
    updateTantouMei() {
      if (this.tantou_ikkatuFlg && this.selectedUser.length) {
        if (
          window.confirm(
            `すべての作品の担当者を一括設定します。よろしいですか？`
          )
        ) {
          this.$emit(
            "updateTantouMei",
            this.selectedUser,
            this.tantou_ikkatuFlg
          );
        }
      } else {
        this.$emit("updateTantouMei", this.selectedUser, this.tantou_ikkatuFlg);
      }
      if (this.props_tantou) {
        this.changeTantouToDaiwari();
      } else if (this.selectedSakuhin) {
        this.$emit("hideModal", "modal_tantou");
      }
    },
    //担当者検索
    searchTantou() {
      this.searched_user = [];
      this.searchResult_nullFlg = false;
      this.searchWord_result = "";
      this.searchWord_result = this.searchWord;

      this.$nextTick(() => {
        if (this.searchWord || this.selectedBusyoId !== null) {
          axios
            .get(
              `${this.url}/get_user?user_mei=${this.searchWord}&busyo_id=${this.selectedBusyoId}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            )
            .then((response) => {
              if (response.data.length) {
                this.searched_user = response.data;
              } else {
                this.searched_user = [];
                this.mst_user = [];
                this.searchResult_nullFlg = true;
              }
            })
            .catch((error) => {
              alert("担当者データが取得できませんでした\n" + error);
            });
        }
      });
    },
    //部署の初期設定
    setInitialBusyo() {
      this.$nextTick(() => {
        //部署名の初期設定
        if (this.tantoBusyoId === 0) {
          this.selectedBusyoMei = "";
          this.selectedBusyoId = "";
        } else {
          //初期表示は全社共通で全件表示(選択済み担当者がいない場合)
          const selectedBusyo = this.mst_busyo.find(
            (busyo) => busyo.busyoId == this.tantoBusyoId
          );
          this.selectedBusyoMei = selectedBusyo.busyoMei;
          this.selectedBusyoId = this.tantoBusyoId;
        }
      });
    },
    //部署選択
    selectBusyo(e) {
      this.selectedBusyoMei = e.target.value;
      //busyoIdの上書き
      if (this.selectedBusyoMei === "全社共通") {
        this.selectedBusyoId = "";
      } else {
        const newBusyo = this.mst_busyo.find(
          (busyo) => busyo.busyoMei == this.selectedBusyoMei
        );
        const newBusyoId = newBusyo.busyoId;
        this.selectedBusyoId = newBusyoId;
      }
      this.searchTantou();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 60%;
    height: 80%;
    &__header {
      height: 10%;
    }
    &__close {
      color: #fff;
    }
  }

  .btn_gray {
    padding: 5px;
  }
}
</style>
