<template>
  <div id="PageBikouTale" v-if="filter_sakuhin_page_bikou_array.length">
    <p>【ページ備考情報】</p>
    <table class="daiwari-table" :class="sheet">
      <tr>
        <th class="daiwari-table_th daiwari-table_th_page">ページ</th>
        <th class="daiwari-table_th daiwari-table_th_sakuhin_mei">作品名</th>
        <th class="daiwari-table_th daiwari-table_th_bikou">備考内容</th>
      </tr>
      <template
        v-for="(bikouList, bikouListIndex) in filter_sakuhin_page_bikou_array"
      >
        <template v-for="(bikou, index) in bikouList.bikou_array">
          <tr :key="bikouList.sakuhin_bikouId + index" v-if="bikou.bikou">
            <td
              :class="bikouListIndex % 2 === 0 ? '' : 'bg-gray'"
              class="daiwari-table_td daiwari-table_td_page"
            >
              {{ bikou.page }}
            </td>
            <td
              class="daiwari-table_td daiwari-table_td_sakuhin_mei"
              :class="bikouListIndex % 2 === 0 ? '' : 'bg-gray'"
              :rowspan="bikouList.bikou_array.length"
              v-if="index === 0"
            >
              {{ sakuhinDisplayName(bikouList) }}
            </td>
            <td
              :class="bikouListIndex % 2 === 0 ? '' : 'bg-gray'"
              class="daiwari-table_td daiwari-table_td_bikou"
            >
              {{ bikou.bikou }}
            </td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import { PageBikou } from "@/daiwariJS/PageBikou.js";
import { is_matched_sakuhinId } from "@/daiwariJS/util.js";
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";

export default {
  name: "PageBikouTale",
  props: {
    props_daiwariData: Object,
    printSize: String,
  },
  data() {
    return {
      sakuhin_page_bikou_array: [],
      filter_sakuhin_page_bikou_array: [],
    };
  },
  created() {
    this.sakuhin_page_bikou_array = JSON.parse(
      JSON.stringify(this.props_daiwariData.sakuhin_page_bikou_array)
    );
    //filter_sakuhin_page_bikou_array・・・『備考作品情報』備考情報のある作品のみが格納されている
    //filter_bikou_array・・・『備考内のページ情報』入力済みのページ備考のみを抽出している
    //bikouList・・・ページ備考の紐づき元となる作品の「作品名・作品ID・備考配列」を持っている
    //bikou.bikou_array・・・作品に紐づく備考のページ数と備考内容を持っている
    this.sakuhin_page_bikou_array.forEach((bikouList) => {
      let filter_bikou_array = [];

      //１：入力済みのページ備考のみを抽出して配列に格納
      bikouList.bikou_array.forEach((bikou, index) => {
        if (bikou.bikou !== "") {
          filter_bikou_array.push({
            bikou: bikou.bikou,
            page_area: bikou.page,
            page: bikou.page,
            bikouIndex: index,
          });
        }
      });

      //２：備考情報のある作品のみを「filter_sakuhin_page_bikou_array」に格納
      if (filter_bikou_array.length) {
        let pushBikou = new PageBikou(bikouList);
        pushBikou.bikou_array = filter_bikou_array;
        this.filter_sakuhin_page_bikou_array.push(pushBikou);
      }

      //３：ページ備考の作品名を更新(更新漏れ対策)し、ページ数などの付加情報を追加する
      if (filter_bikou_array.length) {
        //同作品同士の分断したaddress_arrayを1つの配列に繋げる(該当ページ参照用) ----------
        let preSakuhin;
        let sakuhinAddressComputedArray = [];
        this.props_daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (preSakuhin && is_matched_sakuhinId(preSakuhin, sakuhin)) {
              sakuhinAddressComputedArray = [
                ...sakuhinAddressComputedArray,
                ...sakuhin.sakuhin_address_computed_array_withFlg,
              ];
            } else {
              sakuhinAddressComputedArray.splice(0);
              sakuhinAddressComputedArray = [
                ...sakuhin.sakuhin_address_computed_array_withFlg,
              ];
            }
            //--------------------------------------------------------------------
            this.filter_sakuhin_page_bikou_array.forEach((bikou) => {
              if (bikou.sakuhin_bikouId === sakuhin.sakuhin_bikouId) {
                //備考情報にテーブル表示用の情報を付与する
                bikou.sakuhin_mei = sakuhin.sakuhin_mei;
                bikou.penName = sakuhin.penName;
                bikou.sakuhin_mei_hosoku = sakuhin.sakuhin_mei_hosoku
                  ? sakuhin.sakuhin_mei_hosoku
                  : "";
                bikou.sakuhin_mei_display_compact =
                  sakuhin.sakuhin_mei_display_compact
                    ? sakuhin.sakuhin_mei_display_compact
                    : "";
                bikou.bikou_array.forEach((bikou) => {
                  bikou.page = sakuhinAddressComputedArray[bikou.bikouIndex]
                    ? sakuhinAddressComputedArray[bikou.bikouIndex]
                    : "未設定";
                });
              }
            });
            preSakuhin = sakuhin;
          });
        });
      }
    });
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
    sakuhinDisplayName() {
      return (sakuhin) => {
        console.log(sakuhin);
        return convertSakuhinMeiFull(sakuhin);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #PageBikouTale {
    width: 100%;
    padding-top: 20px;
  }
  .daiwari-table {
    border-collapse: separate;
    border-right: 1px solid;
    table-layout: fixed;
    width: 100%;
    background: #fff;

    &_td {
      height: 10px;
      border: 1px solid;
      border-top: none;
      border-right: none;
      text-align: center;
      vertical-align: middle;
      &_bikou {
        width: 60%;
      }
      &_sakuhin_mei {
        width: 30%;
      }
      &_page {
        width: 10%;
      }
    }
    &_th {
      background-color: #ddd;
      padding: 0px 5px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid;
      border-right: none;
      &_bikou {
        width: 60%;
      }
      &_sakuhin_mei {
        width: 30%;
      }
      &_page {
        width: 10%;
      }
    }
  }
  .bg-gray {
    background: #efeeee;
  }
  //印刷用CSS-------------------------------
  @media print {
    //A4の場合...
    .sheet_A4 {
      // width: 792px;
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }
  }
}
</style>
