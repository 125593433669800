/**
 * パスワードのバリデーション用
 */
export const isValidPassword = (password) => {
    //8～20桁でなければエラー
    if (password.length < 8 || password.length > 20) {
        // console.log("8～20桁を入力してください")
        return false;
    } else if (!password.match(/[a-zA-Z0-9_@\\-\\/\\.]/)) {
        // console.log("半角英数字、半角記号(_@-/.)を入力してください")
        return false;
    } else {
        return true
    }
}