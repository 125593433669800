<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title"></p>
        <!-- <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />-->
      </div>
      <div class="modal__review">
        <span>
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="icon__save__filed"
          />
        </span>
        <div class="modal__message">
          <p class="modal__message__top">保存に失敗しました</p>
          <p>
            以下折のページ数が奇数の為、保存できません。
            <br />修正後、再度保存してください。
          </p>
          <p class="modal__message__bottom">
            ＜エラー対象の折※上から順に＞<br />
            <span
              class="modal__oriAddress"
              v-for="(oriAddress, index) in oddCheck_array"
              :key="oriAddress"
              >{{ oriAddress }}番目<span
                v-show="index + 1 !== oddCheck_array.length"
                >,</span
              ></span
            >
          </p>
        </div>
        <button class="btn_gray" @click="hideModal">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oddCheck_array: {
      type: Array,
    },
  },
  emits: ["hideModal", "count_timeOut"],
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_oddCheck");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay {
    z-index: 10000;
  }
  .modal {
    width: 50%;
    height: 60%;
    &__message {
      font-size: 20px;
      width: 70%;
      p {
        text-align: center;
      }
      &__top {
        font-weight: 600;
      }
      &__bottom {
        padding-top: 20px;
      }
    }
    &__header {
      height: 5%;
      background: #fff;
    }
    &__close {
      color: #000;
    }
    &__oriAddress {
      font-weight: 600;
    }
  }
  .icon__save__filed {
    color: rgb(212, 0, 0);
    width: 100px;
    height: 100px;
  }

  .btn_gray {
    padding: 10px;
  }
}
</style>
