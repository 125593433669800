<template>
  <input
    :value="value"
    @focus="onFocus"
    @input="onInput"
    @change="onChange"
    class="InputText_Input"
    :type="type"
    :name="name"
    :placeholder="placeholder"
  />
</template>
<script>
export default {
  name: "InputText",
  props: {
    value: { type: [String, Number], required: false },
    type: { type: String, required: false },
    name: { type: String, required: false },
    placeholder: { type: String, required: false },
  },
  methods: {
    onFocus(e) {
      this.$emit("onFocus", e.target.value);
    },
    onInput(e) {
      this.$emit("onInput", e.target.value);
    },
    onChange(e) {
      this.$emit("onChange", e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.InputText {
  &_Input {
    width: 121px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #848484;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
</style>
