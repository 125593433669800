export const add_pageRangeInfo = (daiwariData, pageRangeInfo) => {
    daiwariData.page_range_bikou_array.push(pageRangeInfo);
    // ページ順にソート
    daiwariData.page_range_bikou_array.sort((a, b) => {
        return a.firstPage < b.lastPage ? -1 : 1;
    });
}
export const delete_pageRangeInfo = (page_range_bikou_array, sakuhin) => {
    //削除対象の備考を探す
    let deleteBikouIndex = null;
    page_range_bikou_array.forEach((bikou, index) => {
        if (getIsDuplicate(bikou.addressIndexArray, sakuhin.sakuhin_index_computed_array)) {
            deleteBikouIndex = index;
        }
    })
    page_range_bikou_array.splice(deleteBikouIndex, 1);
};

//rebuildする度に、全作品のアドレスをつなげた配列を作成する
export const sortDaiwariAddress = (ori_array) => {
    let daiwariAddressArray = [];
    ori_array.forEach(ori => {
        ori.sakuhin_array.forEach(sakuhin => {
            daiwariAddressArray = [...daiwariAddressArray, ...addressArray(sakuhin)]
        })
    })
    asterRiskCount = 1;
    return daiwariAddressArray;
}

let asterRiskCount = 1; //カウント対象外のアドレス計算用

const addressArray = (sakuhin) => {
    //sakuhin_address_computed_array_withFlgとsakuhin_address_computed_arrayのうち有効な方を返す
    if (sakuhin.sakuhin_address_computed_array_withFlg.length) {
        return sakuhin.sakuhin_address_computed_array_withFlg;
    } else if (!sakuhin.pageCountFlg) {
        let asterRiskArray = [];
        //カウント対象外作品は別途計算してアスタリスク付与
        for (let i = 0; i < sakuhin.sakuhin_address_computed_array.length; i++) {
            asterRiskArray.push('*' + asterRiskCount);
            asterRiskCount++
        }
        return asterRiskArray
    } else {
        return sakuhin.sakuhin_address_computed_array;
    }
}

//作品移動・ページ増減・削除時のアラート処理
export const needsConfirmPageRangePosition = (page_range_bikou_array, sakuhin) => {
    let needsComfirm = false;
    page_range_bikou_array.forEach(bikou => {
        if (getIsDuplicate(bikou.addressIndexArray, sakuhin.sakuhin_index_computed_array)) {
            needsComfirm = true;
        }
    })
    return needsComfirm;
}


//2つの配列に重複値があるかを判定
export const getIsDuplicate = (arr1, arr2) => {
    return (
        [...arr1, ...arr2].filter((item) => arr1.includes(item) && arr2.includes(item))
        .length > 0
    );
}