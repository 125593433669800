<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p v-if="daiwariHeader.isLock" class="modal__title">編集中の台割</p>
        <p v-if="daiwariHeader.isDaichoKakutei" class="modal__title">
          原価台帳確定済の台割
        </p>
        <p v-if="daiwariHeader.isKikakuCancel" class="modal__title">
          企画中止の台割
        </p>
        <p v-if="daiwariHeader.isKengenLock" class="modal__title">
          編集権限がありません
        </p>
        <p v-if="daiwariHeader.isWfLock" class="modal__title">
          原価台帳確定WF申請中の台割
        </p>
      </div>
      <div class="modal__review">
        <div class="modal__message">
          <p v-if="daiwariHeader.isLock">
            『{{ title }}』は編集中のためロックされています。
            <br />
            編集者は"{{ daiwariLockUserInfo.lockingUserName }}"です。
            <br />[読み取り専用]で開いてください。
            <!-- <br />他の人が編集を終了したときに通知を受け取るには、[通知]をクリックします。 -->
          </p>
          <p v-if="daiwariHeader.isDaichoKakutei">
            『{{ title }}
            <span class="edaban_title" v-if="daiwariHeader.isChild">
              {{ daiwariHeader.childDaiwariName }}
            </span>
            』は原価台帳確定済のためロックされています。
            <br />読み取り専用で開きます。
          </p>
          <p v-if="daiwariHeader.isKikakuCancel">
            『{{ title }}』 は企画中止された台割です。
            <br />読み取り専用で開きます。
          </p>
          <p v-if="daiwariHeader.isKengenLock">
            台割の編集権限がないため読み取り専用で開きます。
          </p>
          <p v-if="daiwariHeader.isWfLock">
            『{{ title }}
            <span class="edaban_title" v-if="daiwariHeader.isChild">
              {{ daiwariHeader.childDaiwariName }}
            </span>
            』は原価台帳確定WF申請中のためロックされています。
            <br />読み取り専用で開きます。
          </p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_orange" @click="hideModal()">OK</button>
          <!-- <button
            v-if="daiwariHeader.isLock"
            class="btn_orange"
            @click="setNotification"
          >
            通知
          </button> -->
          <button class="btn_orange" @click="back_to_previousPage">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { Notyf } from "notyf";
// const notyf = new Notyf();

export default {
  props: {
    title: String,
    daiwariHeader: Object,
    daiwariLockUserInfo: Object,
    daiwariId: Number,
    url: String,
  },
  emits: [
    "hideModal",
    "fetch_daiwariLockInfo",
    "back_to_previousPage",
    "count_timeOut",
  ],
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_daiwariLock");
    },
    setNotification() {
      const postData = {
        dataId: this.daiwariId, // ← create()時に取得した台割IDを設定
        subscriberUserId: this.daiwariLockUserInfo.editingUserId, // ← create()時に取得したeditingUserIDを設定
      };
      axios
        .post(`${this.url}/save_subscriber_daiwari_lock`, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then(() => {
          this.$emit("fetch_daiwariLockInfo");
          this.hideModal();
          // notyf.success("編集終了時の通知を申請しました");
        })
        .catch((error) => {
          console.log("通知のリクエストに失敗しました", error);
        });
    },
    back_to_previousPage() {
      this.$emit("back_to_previousPage");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay {
    z-index: 10000;
  }
  .modal {
    width: 600px;
    height: 460px;
    &__message {
      font-size: 16px;
      padding: 0px 20px;
    }
    .edaban_title {
      margin-left: 10px;
    }
  }

  .btn_orange {
    width: 150px;
    height: 40px;
  }
}
</style>
