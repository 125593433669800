import { is_matched_sakuhinId } from './util.js'

export const rebuildNakatojiData = (nakatojiOriArray, nakatojiHyosiArray, includesPages_doNotCount) => {
    //1:左辺・右辺の作品をアドレス順で1つの作品配列にする
    let nakatojiSakuhinArray = format_nakatojiSakuhinArray([...nakatojiOriArray])
    let preSakuhin = { sakuhinInfo: {}, ori_id: '', position: '', address: 0 };
    let deleteSakuhinIndexArray = []
    let sakuhinCount = 0;
    let sakuhin_address_computed_array = [];
    let endPage = 0; //分断作品込みでの同作品内での最終ページ(備考ハイライトで使用)

    //カウント対象外が1つでも含まれる場合に使用：表紙のページ数を予めカウント数に含める
    const hyosiAddressCount = nakatojiHyosiArray[0].sahenArray.reduce((sum, hyosi) => { return sum + hyosi.sakuhinInfo.sakuhin_address_computed_array_withFlg.length }, 0)
    let addressCount_withFlg = hyosiAddressCount ? hyosiAddressCount : 0;

    nakatojiSakuhinArray.forEach((sakuhin, sakuhinIndex) => {

        //2:同折に所属している&&重複している作品を削除
        if (preSakuhin.ori_id === sakuhin.ori_id && preSakuhin.position === sakuhin.position) {
            if (is_matched_sakuhinId(preSakuhin.sakuhinInfo, sakuhin.sakuhinInfo)) {
                //◎(前ループの作品と)所属折・作品が同じ    
                endPage++
                deleteSakuhinIndexArray.push(sakuhinIndex - 1);
                sakuhinCount++ //全作品の続きになるため前回分＋１
                if (includesPages_doNotCount) {
                    if (sakuhin.sakuhinInfo.pageCountFlg) {
                        addressCount_withFlg++
                        sakuhin_address_computed_array.push(addressCount_withFlg);
                    } else {
                        sakuhin_address_computed_array.push(0);
                    }
                } else {
                    sakuhin_address_computed_array.push(sakuhin.address);
                }
                sakuhin.sakuhinInfo.is_divided = true,
                    sakuhin.sakuhinInfo.divided_page = sakuhinCount
                sakuhin.sakuhinInfo.nakatoji_page = sakuhinCount
            } else {
                //◎(前ループの作品と)所属折は同じだが作品が違う
                endPage = 0
                sakuhinCount = 1; //作品が切り替わっているため1から数え直し
                sakuhin.sakuhinInfo.is_divided = false,
                    sakuhin.sakuhinInfo.divided_page = 0

                sakuhin.sakuhinInfo.nakatoji_page = sakuhin.sakuhinInfo.total_page
                    // sakuhin.sakuhinInfo.nakatoji_page = sakuhinCount

                //作品が切り替わるためアドレス配列リセット
                sakuhin_address_computed_array = [];
                if (includesPages_doNotCount) {
                    if (sakuhin.sakuhinInfo.pageCountFlg) {
                        addressCount_withFlg++
                        sakuhin_address_computed_array.push(addressCount_withFlg);
                    } else {
                        sakuhin_address_computed_array.push(0);
                    }
                } else {
                    sakuhin_address_computed_array.push(sakuhin.address);
                }
            }
        } else {
            if (is_matched_sakuhinId(preSakuhin.sakuhinInfo, sakuhin.sakuhinInfo)) {
                //◎(前ループの作品と)所属折は違うが作品は同じ
                endPage++
                sakuhinCount = 1 //折が切り替わるので1から数え直し
                sakuhin.sakuhinInfo.is_divided = true,
                    sakuhin.sakuhinInfo.divided_page = sakuhinCount
                sakuhin.sakuhinInfo.nakatoji_page = sakuhinCount

                //折が切り替わるためアドレス配列リセット
                sakuhin_address_computed_array = [];
                if (includesPages_doNotCount) {
                    if (sakuhin.sakuhinInfo.pageCountFlg) {
                        addressCount_withFlg++
                        sakuhin_address_computed_array.push(addressCount_withFlg);
                    } else {
                        sakuhin_address_computed_array.push(0);
                    }
                } else {
                    sakuhin_address_computed_array.push(sakuhin.address);
                }
            } else {
                //◎(前ループの作品と)所属折も作品も違う
                endPage = 0
                sakuhinCount = 1 //折も作品も切り替わっているので1から数え直し
                sakuhin.sakuhinInfo.is_divided = false,
                    sakuhin.sakuhinInfo.divided_page = 0
                sakuhin.sakuhinInfo.nakatoji_page = sakuhin.sakuhinInfo.total_page

                //作品が切り替わるためアドレス配列リセット
                sakuhin_address_computed_array = [];
                if (includesPages_doNotCount) {
                    if (sakuhin.sakuhinInfo.pageCountFlg) {
                        addressCount_withFlg++
                        sakuhin_address_computed_array.push(addressCount_withFlg);
                    } else {
                        sakuhin_address_computed_array.push(0);
                    }
                } else {
                    sakuhin_address_computed_array.push(sakuhin.address);
                }
            }
        }
        sakuhin.sakuhinInfo.sakuhinCount = sakuhinCount;
        sakuhin.sakuhinInfo.endPage = endPage; //分断作品込みでの同作品内での最終ページ(備考ハイライトで使用)
        sakuhin.sakuhinInfo.sakuhin_address_computed_array = sakuhin_address_computed_array;
        preSakuhin = {...sakuhin };
    })

    //重複対象の作品を一気に削除(forEach内で削除するとインデックスがずれるため)
    nakatojiSakuhinArray = nakatojiSakuhinArray.filter((sakuhin, sakuhinIndex) => !deleteSakuhinIndexArray.includes(sakuhinIndex))


    //3:nakatojiSakuhinArrayを左辺配列・右辺配列で切り分ける
    reFormat_nakatojiSakuhinArray(nakatojiOriArray, nakatojiSakuhinArray, nakatojiHyosiArray, includesPages_doNotCount);
}

const format_nakatojiSakuhinArray = (nakatojiOriArray) => {
    //左辺・右辺の作品をアドレス順で1つの作品配列にする
    let nakatojiSakuhinArray = [];
    nakatojiOriArray.forEach((ori) => {
        ori.sahenArray.forEach(sakuhin => {
            //作品の所属(右辺or左辺)を判定するため、positionプロパティを追加
            nakatojiSakuhinArray.push({...sakuhin, 'ori_id': ori.ori_id, position: 'sahen', ori_kbn: ori.ori_kbn });
        })
    })
    nakatojiOriArray.slice().reverse().forEach((ori) => {
        ori.uhenArray.forEach(sakuhin => {
            //作品の所属(右辺or左辺)を判定するため、positionプロパティを追加
            nakatojiSakuhinArray.push({...sakuhin, 'ori_id': ori.ori_id, position: 'uhen', ori_kbn: ori.ori_kbn });
        })
    })

    return nakatojiSakuhinArray;
}

const reFormat_nakatojiSakuhinArray = (nakatojiOriArray, nakatojiSakuhinArray, nakatojiHyosiArray, includesPages_doNotCount) => {
    //sakuhin_address_computed_arrayの再構築(表紙)----
    let joined_hyosi_address_array_sahen = [];
    let joined_hyosi_address_array_uhen = [];
    let joined_hyosi_address_index_array_sahen = []; //ページ範囲設定のハイライト用
    let joined_hyosi_address_index_array_uhen = []; //ページ範囲設定のハイライト用
    let indexCount = 0;

    if (nakatojiHyosiArray.length) {
        nakatojiHyosiArray[0].sahenArray.forEach(sakuhin => {

            const addressNullArray = sakuhin.sakuhinInfo.pageCountFlg ? sakuhin.sakuhinInfo.sakuhin_address_computed_array_withFlg : Array(sakuhin.sakuhinInfo.sakuhin_address_computed_array.length).fill(0)
            const addressArray = includesPages_doNotCount ? addressNullArray : sakuhin.sakuhinInfo.sakuhin_address_computed_array
            joined_hyosi_address_array_sahen = [...joined_hyosi_address_array_sahen, ...addressArray]
        })
        nakatojiHyosiArray[0].uhenArray.forEach(sakuhin => {

            const addressNullArray = sakuhin.sakuhinInfo.pageCountFlg ? sakuhin.sakuhinInfo.sakuhin_address_computed_array_withFlg : Array(sakuhin.sakuhinInfo.sakuhin_address_computed_array.length).fill(0)
            const addressArray = includesPages_doNotCount ? addressNullArray : sakuhin.sakuhinInfo.sakuhin_address_computed_array
            joined_hyosi_address_array_uhen = [...joined_hyosi_address_array_uhen, ...addressArray]
        })

        //ori直下に折(左辺・右辺毎)のアドレス配列を作成
        nakatojiHyosiArray[0].sakuhin_address_computed_array_sahen = joined_hyosi_address_array_sahen;
        nakatojiHyosiArray[0].sakuhin_address_computed_array_uhen = joined_hyosi_address_array_uhen;

        joined_hyosi_address_array_sahen.forEach(() => {
            joined_hyosi_address_index_array_sahen.push(indexCount++)
        })

        nakatojiHyosiArray[0].sakuhin_index_computed_array_sahen = joined_hyosi_address_index_array_sahen;
    }

    nakatojiOriArray.forEach(ori => {
        //再構築前のsahenArrayとuhenArrayをリセット
        ori.sahenArray.splice(0)
        ori.uhenArray.splice(0)
        nakatojiSakuhinArray.forEach(sakuhin => {
            if (ori.ori_id === sakuhin.ori_id) {
                if (sakuhin.position === 'sahen') {
                    //左辺の作品の場合sahenにpush
                    ori.sahenArray.push(sakuhin)
                }
                if (sakuhin.position === 'uhen') {
                    //右辺の場合uhenにpush
                    ori.uhenArray.push(sakuhin)
                }
            }
        })
    })

    //sakuhin_address_computed_arrayの再構築(本文)---
    //本文左辺
    nakatojiOriArray.forEach(ori => {
        let joined_address_array_sahen = [];
        //本文左辺のアドレス計算
        let joined_address_index_array_sahen = []; //ページ範囲設定のハイライト用
        ori.sahenArray.forEach(sakuhin => {
                joined_address_array_sahen = [...joined_address_array_sahen, ...sakuhin.sakuhinInfo.sakuhin_address_computed_array]
            })
            //本文左辺のハイライト計算
        joined_address_array_sahen.forEach(() => {
                joined_address_index_array_sahen.push(indexCount++)
            })
            //ori直下に折(左辺・右辺毎)のアドレス配列を作成
        ori.sakuhin_address_computed_array_sahen = joined_address_array_sahen;
        ori.sakuhin_index_computed_array_sahen = joined_address_index_array_sahen; //ページ範囲設定用
    })


    // 本文右辺
    nakatojiOriArray.forEach(ori => {
        let joined_address_array_uhen = [];
        //本文右辺のアドレス計算
        ori.uhenArray.forEach(sakuhin => {
            joined_address_array_uhen = [...joined_address_array_uhen, ...sakuhin.sakuhinInfo.sakuhin_address_computed_array]
        })
        ori.sakuhin_address_computed_array_uhen = joined_address_array_uhen;
    })

    //本文右辺のハイライト計算
    const copy_nakatojiOriArray = [...nakatojiOriArray] //reverse()時に元の配列の形を破壊しないようコピーしてから使用する
    copy_nakatojiOriArray.reverse().forEach(ori => { //右辺は全左辺のアドレスを加算した後にアドレスを算出する
        let joined_address_index_array_uhen = []; //ページ範囲設定のハイライト用
        ori.sakuhin_address_computed_array_uhen.forEach(() => {
            joined_address_index_array_uhen.push(indexCount++)
        })
        ori.sakuhin_index_computed_array_uhen = joined_address_index_array_uhen; //ページ範囲設定用
    })

    //表紙右辺は最後にカウントされるので、一番最後にアドレスを設定する
    joined_hyosi_address_array_uhen.forEach(() => {
        joined_hyosi_address_index_array_uhen.push(indexCount++)
    })
    nakatojiHyosiArray[0].sakuhin_index_computed_array_uhen = joined_hyosi_address_index_array_uhen;
    return nakatojiOriArray, nakatojiHyosiArray
}