import axios from "axios";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import store from "..";
import type { RootState } from "..";
import { ZaikoData } from "@/types/zaiko";
import { OrderHoldData, ShowOrderRefData } from "@/types/order";

type SearchState = {
  searchSimpleState: string;
  searchSimpleFlg: boolean;
  pageNumber: number;
  numberOfDisplay: number | string;
  /*在庫＆受注保留解除の検索条件*/
  searchDetailState: {
    fromDate: string;
    toDate: string;
    syubetu: string;
    category: string;
    zaikoStatus: string;
    syosiCd: string;
    changeableSyukkoSuu: boolean;
    toritugiCd: string;
    toritugiSyotenCd: string;
  };
  /*在庫 */
  zaikoData: {
    size: number;
    firstCount: number;
    finalCount: number;
    syosiList: ZaikoData[];
  };
  /*受注照会 */
  orderRefData: {
    size: number;
    firstCount: number;
    finalCount: number;
    orderRefList: ShowOrderRefData[];
  };
  orderRefDetailState: {
    fromTourokuDate: string;
    toTourokuDate: string;
    toritugiCd: string;
    toritugiSyotenCd: string;
    syotenCd: string;
    bansen: string;
    torihikiCd: string;
    juchuKbn: string;
    categoryCd: string;
    userId: string;
    salesName: string;
    companyRemarks: string;
    syosiCd: string;
    syotenMei: string;
  };
  /*受注保留解除 */
  orderHoldData: {
    size: number;
    firstCount: number;
    finalCount: number;
    orderHoldList: OrderHoldData[];
  };
  /*出庫履歴 */
  syukkoDetailState: {
    fromTourokuDate: string;
    toTourokuDate: string;
    fromNouhinDate: string;
    toNouhinDate: string;
    juchuNumber?: string;
    orderNumber?: string;
    eventMei: string;
    toritugiCd: string;
    cutting: boolean;
    syosiCd: string;
    fromHatubaiDate: string;
    toHatubaiDate: string;
    syubetu: string;
    category: string;
    zaikoStatus: string;
    syukkoStatus: string;
    registrant: string;
    toritugiSyotenCd: string;
    salesCd: string | number;
  };
  // 見出し
  syukkoData: {
    size: number;
    firstCount: number;
    finalCount: number;
    syukkoList: [];
  };
  // 明細
  syukkoMeisaiPageNumber: number;
  syukkoMeisaiData: {
    size: number;
    firstCount: number;
    finalCount: number;
    syukkoDetailList: [];
  };
  /*返品伝票 ---*/
  returnSlipState: {
    fromAcceptedHenpinDate: string;
    toAcceptedHenpinDate: string;
    fromToritugiHenpinDate: string;
    toToritugiHenpinDate: string;
    eventMei: string;
    toritugiCd: string;
    syosiCd: string;
    syouhinKbnMei: string;
    jikoDenpyouNumber: string;
    toritugiDenpyouNumber: string;
  };
  returnSlipData: {
    size: number;
    firstCount: number;
    finalCount: number;
    mstHenpinDenpyou: [];
  };
  /*納品伝票 ---*/
  deliverySlipState: {
    fromHannyuDate: string;
    toHannyuDate: string;
    eventMei: string;
    toritugiCd: string;
    joukenKbn: string | number;
    fromSeikyuuDate: string;
    toSeikyuuDate: string;
    fromTourokuDate: string;
    toTourokuDate: string;
    syubetu: string;
    denpyouNumber: string;
    syosiCd: string;
  };
  deliverySlipData: {
    size: number;
    firstCount: number;
    finalCount: number;
    mstNouhinDenpyou: [];
  };
  /*調整 ---*/
  // ソート関連
  orderAdjustmentSortInfo: {
    isSorting: boolean;
    sortItem: string;
    isKoujun: boolean;
  };
  /*注文リスト ---*/
  createListState: {
    syosiCd: string;
    fromRegisterDate: string;
    toRegisterDate: string;
    fromDisplayDate: string;
    toDisplayDate: string;
    containsMukigen: boolean;
    isExpired: boolean;
  };
  /*書店 ---*/
  syotenDetailState: {
    toritugiCd: string;
    toritugiSyotenCd: string;
    syotenCd: string;
    bansen: string;
    syotenMei: string;
  };
  syotenData: {
    size: number;
    firstCount: number;
    finalCount: number;
    syotenToritugiList: [];
  };
  /*書誌 ---*/
  syosiDetailState: {
    fromDate: string;
    toDate: string;
    syubetu: string;
    category: string;
    zaikoStatus: string;
    searchWord: string;
  };
  syosiData: {
    size: number;
    firstCount: number;
    finalCount: number;
    syosiList: ZaikoData[];
  };
  cartNormalNum: number;
};

// ストアを永続化しているため、初期化のために以下の形で定義
type GetDefaultState = () => SearchState;
const getDefaultState: GetDefaultState = () => {
  return {
    searchSimpleState: "",
    searchSimpleFlg: false,
    pageNumber: 1,
    numberOfDisplay: "",
    /*在庫＆受注保留解除の検索条件 ---*/
    searchDetailState: {
      fromDate: "",
      toDate: "",
      syubetu: "",
      category: "",
      zaikoStatus: "",
      syosiCd: "",
      changeableSyukkoSuu: false,
      toritugiCd: "",
      toritugiSyotenCd: "",
    },
    /*在庫 */
    zaikoData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syosiList: [],
    },
    /*受注照会 */
    orderRefDetailState: {
      fromTourokuDate: "",
      toTourokuDate: "",
      toritugiCd: "",
      toritugiSyotenCd: "",
      syotenCd: "",
      bansen: "",
      torihikiCd: "1",
      juchuKbn: "",
      categoryCd: "",
      userId: "",
      salesName: "",
      companyRemarks: "",
      syosiCd: "",
      syotenMei: "",
    },
    orderRefData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      orderRefList: [],
    },
    /*受注保留解除 */
    orderHoldData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      orderHoldList: [],
    },
    /*出庫履歴 ---*/
    syukkoDetailState: {
      fromTourokuDate: "",
      toTourokuDate: "",
      fromNouhinDate: "",
      toNouhinDate: "",
      juchuNumber: "",
      orderNumber: "",
      eventMei: "",
      toritugiCd: "",
      cutting: false,
      syosiCd: "",
      fromHatubaiDate: "",
      toHatubaiDate: "",
      syubetu: "",
      category: "",
      zaikoStatus: "",
      syukkoStatus: "",
      registrant: "",
      toritugiSyotenCd: "",
      salesCd: "",
    },
    // 見出し
    syukkoData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syukkoList: [],
    },
    // 明細
    syukkoMeisaiPageNumber: 1,
    syukkoMeisaiData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syukkoDetailList: [],
    },
    /*返品伝票 ---*/
    returnSlipState: {
      fromAcceptedHenpinDate: "",
      toAcceptedHenpinDate: "",
      fromToritugiHenpinDate: "",
      toToritugiHenpinDate: "",
      eventMei: "",
      toritugiCd: "",
      syosiCd: "",
      syouhinKbnMei: "",
      jikoDenpyouNumber: "",
      toritugiDenpyouNumber: "",
    },
    returnSlipData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      mstHenpinDenpyou: [],
    },
    /*納品伝票 ---*/
    deliverySlipState: {
      fromHannyuDate: "",
      toHannyuDate: "",
      eventMei: "",
      toritugiCd: "",
      joukenKbn: "",
      fromSeikyuuDate: "",
      toSeikyuuDate: "",
      fromTourokuDate: "",
      toTourokuDate: "",
      syubetu: "",
      denpyouNumber: "",
      syosiCd: "",
    },
    deliverySlipData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      mstNouhinDenpyou: [],
    },
    /* 調整 */
    orderAdjustmentSortInfo: {
      // 初期値は受注数の降順
      isSorting: true,
      sortItem: "juchuSuu",
      isKoujun: true,
    },
    /*注文リスト ---*/
    createListState: {
      syosiCd: "",
      fromRegisterDate: "",
      toRegisterDate: "",
      fromDisplayDate: "",
      toDisplayDate: "",
      containsMukigen: false,
      isExpired: false,
    },
    /*書店 ---*/
    syotenDetailState: {
      toritugiCd: "",
      toritugiSyotenCd: "",
      syotenCd: "",
      bansen: "",
      syotenMei: "",
    },
    syotenData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syotenToritugiList: [],
    },
    /*書誌 ---*/
    syosiDetailState: {
      fromDate: "",
      toDate: "",
      syubetu: "",
      category: "",
      zaikoStatus: "",
      searchWord: "",
    },
    syosiData: {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syosiList: [],
    },
    cartNormalNum: 0,
  };
};

export const state: SearchState = getDefaultState();

const getters: GetterTree<SearchState, RootState> = {
  getsearchSimpleFlg: (state) => state.searchSimpleFlg,
  getSearchSimpleState: (state) => state.searchSimpleState,
  getEncodeSearchSimpleState: (state) =>
    encodeURIComponent(state.searchSimpleState),
  /*在庫＆受注保留解除の検索条件 ---*/
  getSearchState: (state) => {
    return state.searchDetailState;
  },
  /*在庫 */
  getZaikoData: (state) => state.zaikoData,
  getZaikoDataByIsbn: (state) => (isbn: string) => {
    return state.zaikoData.syosiList.find((syosi) => syosi.janCd === isbn);
  },
  /*受注照会 */
  getOrderRefData: (state) => state.orderRefData,
  getOrderRefDetailState: (state) => state.orderRefDetailState,
  /*受注保留解除 */
  getOrderHoldData: (state) => state.orderHoldData,
  /*出庫履歴 ---*/
  getSyukkoData: (state) => state.syukkoData,
  getSyukkoMeisaiData: (state) => state.syukkoMeisaiData,
  getSyukkoDetailState: (state) => state.syukkoDetailState,
  /*返品伝票 ---*/
  getReturnSlipData: (state) => state.returnSlipData,
  getReturnSlipState: (state) => state.returnSlipState,
  /*納品伝票 ---*/
  getDeliverySlipData: (state) => state.deliverySlipData,
  getDeliverySlipState: (state) => state.deliverySlipState,
  /*調整 ---*/
  getOrderAdjustmentSortInfo: (state) => state.orderAdjustmentSortInfo,
  /*注文リスト ---*/
  getCreateListState: (state) => state.createListState,
  /*書店 ---*/
  getSyotenData: (state) => state.syotenData,
  getSyotenDetailState: (state) => state.syotenDetailState,
  /*書誌 ---*/
  getSyosiData: (state) => state.syosiData,
  /*通常カートの個数 */
  getCartNormalNum: (state) => state.cartNormalNum,
  /* ページネーション */
  getPageNumber: (state) => state.pageNumber,
};

const mutations: MutationTree<SearchState> = {
  // stateを初期化するmutationを定義
  clearAuthData(state) {
    Object.assign(state, getDefaultState());
  },
  setSearchSimpleState(state, payload) {
    state.searchSimpleState = payload;
  },
  setNumberOfDisplay(state, payload) {
    state.numberOfDisplay = payload;
  },
  /*在庫＆受注保留解除の検索条件---*/
  setSearchDetailState(state, payload) {
    state.searchDetailState = { ...payload };
  },
  /*在庫 */
  setZaikoData(state, payload) {
    (state.zaikoData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syosiList: [],
    }),
      (state.zaikoData = { ...payload });
  },
  /*受注照会 */
  setOrderRefDetailState(state, payload) {
    //書店情報を更新
    state.orderRefDetailState = { ...payload };
  },
  setOrderRefData(state, payload) {
    (state.orderRefData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      orderRefList: [],
    }),
      (state.orderRefData = { ...payload });
  },
  /*受注保留解除 */
  setOrderHoldData(state, payload) {
    (state.orderHoldData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      orderHoldList: [],
    }),
      (state.orderHoldData.orderHoldList = [...payload]);
  },
  setSimpleFlg(state, payload: { simpleFlg: boolean }) {
    state.searchSimpleFlg = payload.simpleFlg;
    // 簡易・詳細検索が切り替わった時点でstateもリセットする（画面実装に準拠）
    // state.searchDetailState = {
    //   fromDate: "",
    //   toDate: "",
    //   syubetu: "",
    //   category: "",
    //   zaikoStatus: "",
    //   changeableSyukkoSuu: false,
    // };
    // state.searchSimpleState = "";
  },
  /*出庫履歴 ---*/
  setSyukkoDetailState(state, payload) {
    state.syukkoDetailState = { ...payload };
  },
  setSyukkoData(state, payload) {
    (state.syukkoData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syukkoList: [],
    }),
      (state.syukkoData = { ...payload });
  },
  setSyukkoMeisaiPageNumber(state, payload) {
    state.syukkoMeisaiPageNumber = payload;
  },
  resetSyukkoMeisaiPage(state) {
    state.syukkoMeisaiPageNumber = 1;
  },
  setSyukkoMeisaiData(state, payload) {
    state.syukkoMeisaiData = { ...payload };
  },
  /*返品伝票 ---*/
  setReturnSlipState(state, payload) {
    state.returnSlipState = { ...payload };
  },
  setReturnSlipData(state, payload) {
    (state.returnSlipData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      mstHenpinDenpyou: [],
    }),
      (state.returnSlipData = { ...payload });
  },
  /*納品伝票 ---*/
  setDeliverySlipState(state, payload) {
    state.deliverySlipState = { ...payload };
  },
  setDeliverySlipData(state, payload) {
    (state.deliverySlipData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      mstNouhinDenpyou: [],
    }),
      (state.deliverySlipData = { ...payload });
  },
  /*調整 ---*/
  setOrderAdjustmentSortInfo(state, payload) {
    state.orderAdjustmentSortInfo = { ...payload };
  },
  /*注文リスト */
  setCreateListState(state, payload) {
    //書店情報を更新
    state.createListState = { ...payload };
  },
  /*書店 ---*/
  setSyotenDetailState(state, payload) {
    type SyotenDetailState = typeof state.syotenDetailState;
    Object.keys(state.syotenDetailState).forEach((key) => {
      state.syotenDetailState[key as keyof SyotenDetailState] = payload[key];
    });
    // state.syotenDetailState = { ...payload };
  },
  setSyotenData(state, payload) {
    (state.syotenData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syotenToritugiList: [],
    }),
      (state.syotenData = { ...payload });
  },
  /*書誌 ---*/
  setSyosiDetailState(state, payload) {
    state.syosiDetailState = { ...payload };
  },
  setSyosiData(state, payload) {
    (state.syosiData = {
      size: 0,
      firstCount: 0,
      finalCount: 0,
      syosiList: [],
    }),
      (state.syosiData = { ...payload });
  },
  resetSyosiDetailState(state) {
    state.syosiDetailState.fromDate = "";
    state.syosiDetailState.toDate = "";
    state.syosiDetailState.syubetu = "";
    state.syosiDetailState.category = "";
    state.syosiDetailState.zaikoStatus = "";
    state.syosiDetailState.searchWord = "";
  },
  /*通常カートの個数 */
  setCartNormalNum(state, payload) {
    state.cartNormalNum = payload;
  },
  /*ページネーション */
  setPageNumber(state, payload: number) {
    state.pageNumber = payload;
  },
  resetPage(state) {
    state.pageNumber = 1;
  },
};

const actions: ActionTree<SearchState, RootState> = {
  /*在庫 ---*/
  async searchZaiko({ commit, state }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syosi?word=${store.getters["search/getEncodeSearchSimpleState"]}&fromDate=${state.searchDetailState.fromDate}&toDate=${state.searchDetailState.toDate}&syosiCd=${state.searchDetailState.syosiCd}&syubetu=${state.searchDetailState.syubetu}&category=${state.searchDetailState.category}&zaikoStatus=${state.searchDetailState.zaikoStatus}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setZaikoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortZaiko({ commit, state }, { sortItem, isKoujun }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syosi?word=${store.getters["search/getEncodeSearchSimpleState"]}&fromDate=${state.searchDetailState.fromDate}&toDate=${state.searchDetailState.toDate}&syosiCd=${state.searchDetailState.syosiCd}&syubetu=${state.searchDetailState.syubetu}&category=${state.searchDetailState.category}&zaikoStatus=${state.searchDetailState.zaikoStatus}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}
        &order=${sortItem}&sort=${isKoujun}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setZaikoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  // リスト一覧を表示させる用
  async searchListZaiko({ commit, state }, { listId }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syosi?syosiListId=${listId}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setZaikoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortListZaiko({ commit, state }, { sortItem, isKoujun, listId }) {
    try {
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syosi?&syosiListId=${listId}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}&order=${sortItem}&sort=${isKoujun}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setZaikoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*受注照会 */
  async searchOrderRef({ commit, state }, { headlineOrDetail, juchuStatus }) {
    try {
      const STATE = {
        ...state.orderRefDetailState,
      };
      const searchParams = `freeword=${store.getters["search/getEncodeSearchSimpleState"]}&fromRegisterDate=${STATE.fromTourokuDate}&toRegisterDate=${STATE.toTourokuDate}&toritugiCd=${STATE.toritugiCd}&syosiCd=${STATE.syosiCd}&toritugiSyotenCd=${STATE.toritugiSyotenCd}&syotenCd=${STATE.syotenCd}&bansen=${STATE.bansen}&torihikiCd=${STATE.torihikiCd}&juchuKbn=${STATE.juchuKbn}&categoryCd=${STATE.categoryCd}&userId=${STATE.userId}&salesName=${STATE.salesName}&companyRemarks=${STATE.companyRemarks}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}&headlineOrDetail=${headlineOrDetail}&juchuStatus=${juchuStatus}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/chumonReference?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log("res:", res.data);
      commit("setOrderRefData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*受注保留解除 ---*/
  async searchOrderHold({ commit, state }) {
    try {
      const searchParams = `freeword=${store.getters["search/getEncodeSearchSimpleState"]}&fromDate=${state.searchDetailState.fromDate}&toDate=${state.searchDetailState.toDate}&syosiCd=${state.searchDetailState.syosiCd}&syubetu=${state.searchDetailState.syubetu}&category=${state.searchDetailState.category}&zaikoStatus=${state.searchDetailState.zaikoStatus}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/chumonHold?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setOrderHoldData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*出庫履歴 ---*/
  async searchSyukko({ commit, state }) {
    try {
      const STATE = state.syukkoDetailState;
      const searchParams = `fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&fromNouhinDate=${STATE.fromNouhinDate}&toNouhinDate=${STATE.toNouhinDate}&toritugiCd=${STATE.toritugiCd}&toritugiSyotenCd=${STATE.toritugiSyotenCd}&juchuNumber=${STATE.juchuNumber}&orderNumber=${STATE.orderNumber}&eventMei=${STATE.eventMei}&cutting=${STATE.cutting}&syosiCd=${STATE.syosiCd}&fromHatubaiDate=${STATE.fromHatubaiDate}&toHatubaiDate=${STATE.toHatubaiDate}&syubetu=${STATE.syubetu}&categoryCd=${STATE.category}&zaikoStatus=${STATE.zaikoStatus}&syukkoStatus=${STATE.syukkoStatus}&registrant=${STATE.registrant}&salesCd=${STATE.salesCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syukko/get?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyukkoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortSyukko({ commit, state }, { sortItem, isKoujun }) {
    try {
      const STATE = state.syukkoDetailState;
      const searchParams = `fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&fromNouhinDate=${STATE.fromNouhinDate}&toNouhinDate=${STATE.toNouhinDate}&toritugiCd=${STATE.toritugiCd}&toritugiSyotenCd=${STATE.toritugiSyotenCd}&juchuNumber=${STATE.juchuNumber}&orderNumber=${STATE.orderNumber}&eventMei=${STATE.eventMei}&cutting=${STATE.cutting}&syosiCd=${STATE.syosiCd}&fromHatubaiDate=${STATE.fromHatubaiDate}&toHatubaiDate=${STATE.toHatubaiDate}&syubetu=${STATE.syubetu}&categoryCd=${STATE.category}&zaikoStatus=${STATE.zaikoStatus}&syukkoStatus=${STATE.syukkoStatus}&registrant=${STATE.registrant}&salesCd=${STATE.salesCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}
      &order=${sortItem}&sort=${isKoujun}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syukko/get?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyukkoData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async searchSyukkoMeisai({ commit, state }) {
    try {
      const STATE = state.syukkoDetailState;
      const searchParams = `fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&fromNouhinDate=${STATE.fromNouhinDate}&toNouhinDate=${STATE.toNouhinDate}&toritugiCd=${STATE.toritugiCd}&toritugiSyotenCd=${STATE.toritugiSyotenCd}&juchuNumber=${STATE.juchuNumber}&orderNumber=${STATE.orderNumber}&eventMei=${STATE.eventMei}&cutting=${STATE.cutting}&syosiCd=${STATE.syosiCd}&fromHatubaiDate=${STATE.fromHatubaiDate}&toHatubaiDate=${STATE.toHatubaiDate}&syubetu=${STATE.syubetu}&categoryCd=${STATE.category}&zaikoStatus=${STATE.zaikoStatus}&syukkoStatus=${STATE.syukkoStatus}&registrant=${STATE.registrant}&salesCd=${STATE.salesCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.syukkoMeisaiPageNumber}&numberOfDisplay=${state.numberOfDisplay}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syukko/get-detail?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyukkoMeisaiData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortSyukkoMeisai({ commit, state }, { sortItem, isKoujun }) {
    try {
      const STATE = state.syukkoDetailState;
      const searchParams = `fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&fromNouhinDate=${STATE.fromNouhinDate}&toNouhinDate=${STATE.toNouhinDate}&toritugiCd=${STATE.toritugiCd}&toritugiSyotenCd=${STATE.toritugiSyotenCd}&juchuNumber=${STATE.juchuNumber}&orderNumber=${STATE.orderNumber}&eventMei=${STATE.eventMei}&cutting=${STATE.cutting}&syosiCd=${STATE.syosiCd}&fromHatubaiDate=${STATE.fromHatubaiDate}&toHatubaiDate=${STATE.toHatubaiDate}&syubetu=${STATE.syubetu}&categoryCd=${STATE.category}&zaikoStatus=${STATE.zaikoStatus}&syukkoStatus=${STATE.syukkoStatus}&registrant=${STATE.registrant}&salesCd=${STATE.salesCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&page=${state.syukkoMeisaiPageNumber}&numberOfDisplay=${state.numberOfDisplay}
      &order=${sortItem}&sort=${isKoujun}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syukko/get-detail?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyukkoMeisaiData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*返品伝票 ---*/
  async searchReturnSlip({ commit, state }) {
    try {
      const STATE = state.returnSlipState;
      const searchParams = `fromAcceptedHenpinDate=${STATE.fromAcceptedHenpinDate}&toAcceptedHenpinDate=${STATE.toAcceptedHenpinDate}&fromToritugiHenpinDate=${STATE.fromToritugiHenpinDate}&toToritugiHenpinDate=${STATE.toToritugiHenpinDate}&eventMei=${STATE.eventMei}&toritugiCd=${STATE.toritugiCd}&syosiCd=${STATE.syosiCd}&syubetu=${STATE.syouhinKbnMei}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&hanmotoCd=${store.getters["login/getUserData"].hanmotoCd}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/denpyou/henpin/display?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setReturnSlipData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortReturnSlip({ commit, state }, { sortItem, isKoujun }) {
    try {
      const STATE = state.returnSlipState;
      const searchParams = `fromAcceptedHenpinDate=${STATE.fromAcceptedHenpinDate}&toAcceptedHenpinDate=${STATE.toAcceptedHenpinDate}&fromToritugiHenpinDate=${STATE.fromToritugiHenpinDate}&toToritugiHenpinDate=${STATE.toToritugiHenpinDate}&eventMei=${STATE.eventMei}&toritugiCd=${STATE.toritugiCd}&syosiCd=${STATE.syosiCd}&syubetu=${STATE.syouhinKbnMei}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&hanmotoCd=${store.getters["login/getUserData"].hanmotoCd}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}&order=${sortItem}&sort=${isKoujun}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/denpyou/henpin/display?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setReturnSlipData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*納品伝票 ---*/
  async searchDeliverySlip({ commit, state }) {
    try {
      const STATE = state.deliverySlipState;
      const searchParams = `fromHannyuDate=${STATE.fromHannyuDate}&toHannyuDate=${STATE.toHannyuDate}&eventMei=${STATE.eventMei}&toritugiCd=${STATE.toritugiCd}&joukenKbn=${STATE.joukenKbn}&fromSeikyuuDate=${STATE.fromSeikyuuDate}&toSeikyuuDate=${STATE.toSeikyuuDate}&fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&syubetu=${STATE.syubetu}&denpyouNumber=${STATE.denpyouNumber}&syosiCd=${STATE.syosiCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&hanmotoCd=${store.getters["login/getUserData"].hanmotoCd}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/denpyou/nouhin/display?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setDeliverySlipData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sortDeliverySlip({ commit, state }, { sortItem, isKoujun }) {
    try {
      const STATE = state.deliverySlipState;
      const searchParams = `fromHannyuDate=${STATE.fromHannyuDate}&toHannyuDate=${STATE.toHannyuDate}&eventMei=${STATE.eventMei}&toritugiCd=${STATE.toritugiCd}&joukenKbn=${STATE.joukenKbn}&fromSeikyuuDate=${STATE.fromSeikyuuDate}&toSeikyuuDate=${STATE.toSeikyuuDate}&fromTourokuDate=${STATE.fromTourokuDate}&toTourokuDate=${STATE.toTourokuDate}&syubetu=${STATE.syubetu}&denpyouNumber=${STATE.denpyouNumber}&syosiCd=${STATE.syosiCd}&freeword=${store.getters["search/getEncodeSearchSimpleState"]}&hanmotoCd=${store.getters["login/getUserData"].hanmotoCd}&page=${state.pageNumber}&numberOfDisplay=${state.numberOfDisplay}&order=${sortItem}&sort=${isKoujun}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/denpyou/nouhin/display?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setDeliverySlipData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*書店 ---*/
  async searchSyoten({ commit, state }) {
    try {
      const STATE = state.syotenDetailState;
      const searchParams = `toritugiCd=${STATE.toritugiCd}&toritugiSyotenCd=${
        STATE.toritugiSyotenCd
      }&syotenCd=${STATE.syotenCd}&bansen=${
        STATE.bansen
      }&syotenMei=${encodeURIComponent(STATE.syotenMei)}&page=${
        state.pageNumber
      }&numberOfDisplay=`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syoten/get?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(res.data);
      commit("setSyotenData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  async searchToritugiSyotenCd({ commit, state }) {
    try {
      const STATE = state.syotenDetailState;
      const searchParams = `toritugiSyotenCd=${STATE.toritugiSyotenCd}&toritugiCd=${STATE.toritugiCd}`;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syoten/get?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyotenData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
  /*書誌 ---*/
  async searchSyosi({ commit, state }) {
    try {
      const STATE = state.syosiDetailState;
      const res = await axios.get(
        `${store.getters["getBaseUrl"]}/syosi?word=${encodeURIComponent(
          STATE.searchWord
        )}&fromDate=${STATE.fromDate}&toDate=${STATE.toDate}&syubetu=${
          STATE.syubetu
        }&category=${STATE.category}&zaikoStatus=${
          STATE.zaikoStatus
        }&freeword=${encodeURIComponent(STATE.searchWord)}&page=${
          state.pageNumber
        }&numberOfDisplay=${state.numberOfDisplay}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      commit("setSyosiData", res.data);
    } catch (error) {
      console.log(error);
    }
  },
};

export const SearchModule: Module<SearchState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
