<template>
  <div>
    <GlobalHeader
      v-if="isActiveGlobalHeader"
      :toggleSidebarFlg="isActiveSidebar"
      @toggleSideBar="toggleSideBar"
    />
    <!-- 真偽値でスタイルを変える -->
    <SideBar
      ref="sidebar"
      @toggleSideBar="toggleSideBar"
      :sidebarToggle="isActiveSidebar"
      :displayNoneFlg="SidebarDisplayNoneFlg"
    />
    <div
      class="Router_View"
      :class="{
        SideBar_False: !isActiveSidebar,
        SideBar_True: isActiveSidebar,
      }"
    >
      <!-- 各viewsにサイドバーの切替を伝達 -->
      <router-view
        :isActive_SidebarFlg="isActiveSidebar"
        @toggleGlobalHeader="toggleGlobalHeader"
        @showGlobalHeader="showGlobalHeader"
      ></router-view>
    </div>
  </div>
</template>

<script>
import GlobalHeader from "./components/GlobalHeader";
import SideBar from "./components/SideBar.vue";
import vClickOutside from "click-outside-vue3";

export default {
  name: "App",
  components: { GlobalHeader, SideBar },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    const isValidToken = this.$store.getters["login/getIsValidToken"];

    const fetchAllList = async () => {
      await this.$store.dispatch("constant/fetchToritugiList");
      await this.$store.dispatch("constant/fetchSyubetuList");
      await this.$store.dispatch("constant/fetchCategoryList");
      await this.$store.dispatch("constant/fetchEventList");
      await this.$store.dispatch("constant/fetchSalesList");
      await this.$store.dispatch("constant/fetchTorihikiList");
      await this.$store.dispatch("constant/fetchInputUserList");
      await this.$store.dispatch("constant/fetchZaikoStatusList");
      await this.$store.dispatch("constant/fetchEventHenpinDenpyouList");
      await this.$store.dispatch("constant/fetchEventNouhinDenpyouList");
    };
    if (isValidToken) {
      fetchAllList();
    }
  },
  data() {
    return {
      isActiveSidebar: false,
      isActiveGlobalHeader: true,
      SidebarDisplayNoneFlg: false,
    };
  },
  emits: ["toggleGlobalHeader", "showGlobalHeader"],
  methods: {
    toggleSideBar() {
      //GlobalHeader内でサイドバーを操作
      this.isActiveSidebar = !this.isActiveSidebar;
      //真偽値で幅を切替
      this.$refs.sidebar.isActiveToggleFlg();
    },
    toggleGlobalHeader() {
      //初期表示のコンポーネントを非表示にしたいときに発火
      this.isActiveGlobalHeader = false;
      this.isActiveSidebar = false;
      this.SidebarDisplayNoneFlg = true;
    },
    showGlobalHeader() {
      //ログインを実行したときに発火
      this.isActiveGlobalHeader = true;
      this.isActiveSidebar = false; //ここがfalseじゃないとPC幅以下の場合常にサイドバーが開いてしまう
      this.SidebarDisplayNoneFlg = false;
      this.$refs.sidebar.setInitialData();
    },
  },
};
</script>

<style scoped lang="scss">
.Router_View {
  position: fixed;
}
@media print {
  .Router_View {
    position: initial;
  }
}
</style>
