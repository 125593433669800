<template>
  <div>
    <div id="overlay" @click="hideModal">
      <div class="modal" @click="stopEvent">
        <div class="modal__header">
          <p class="modal__title">加工検索</p>
          <font-awesome-icon
            :icon="['fas', 'window-close']"
            @click="hideModal"
            @keydown.enter="hideModal"
            class="modal__close"
            tabindex="0"
          />
        </div>
        <div class="modal__review">
          <!-- 加工検索 -->
          <div class="modal__review__search-container">
            <input
              type="text"
              class="text_box"
              v-model="searchWord"
              @keydown.enter="search"
              ref="inputSearch"
            />
            <button class="btn_gray_small" @click="search">検索</button>
          </div>
          <!-- 【要修正】加工API接続後にプロパティ名などを変更する -->
          <p
            class="modal__review__search-result"
            v-if="searched_kakou.length > 0"
          >
            検索結果({{ searched_kakou.length }}件)
          </p>
          <p class="modal__review__search-result" v-if="searchResult_nullFlg">
            検索結果はありません
          </p>

          <!-- 取引先一覧表示 -->
          <ul
            class="modal__review__result-container"
            v-if="searched_kakou.length > 0"
          >
            <li
              class="modal__review__result-name"
              v-for="kakouData in searched_kakou"
              :key="kakouData.kakoId"
              @click="addKakou(kakouData)"
              @keydown.enter="addKakou(kakouData)"
              tabindex="0"
            >
              {{ kakouData.kakoMei }}
            </li>
          </ul>

          <!-- 選択済みの加工区分を表示 -->
          <ul class="modal__review__badge-container">
            <li
              class="modal__review__badge-selected"
              v-for="(selectKakou, selectKakouIndex) in selected_kakou"
              :key="'select_' + selectKakouIndex"
            >
              <span v-if="selected_kakou.length">{{ selectKakou.kbn }}</span>
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="modal__review__badge-cancel"
                @click="removeKakou(selectKakouIndex)"
                @keydown.enter="removeKakou(selectKakouIndex)"
                tabindex="0"
              />
            </li>
          </ul>
          <button class="btn_blue" @click="updateKakou">決定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedOri: Object,
    url: String,
  },
  emits: ["hideModal", "count_timeOut", "updateKakou"],
  data() {
    return {
      searched_kakou: [],
      selected_kakou: [],
      searchWord: "",
      searchResult_nullFlg: false,
    };
  },
  created() {
    // 既に選択済の加工区分があれば初期値としてセットする
    if (this.selectedOri.kakou_array.length > 0) {
      this.selectedOri.kakou_array.forEach((kakou) => {
        this.selected_kakou.push(kakou);
      });
      this.searchWord = this.selectedOri.kakou_array[0].kbn;
    }
    // 初期検索
    this.getKakouInfo();
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    getKakouInfo() {
      // this.searched_kakou = this.resultTestData;
      axios
        .get(
          // ↓この分決まり次第変える
          `${this.url}/daiwari/get_kako_findby?kakoMei=${this.searchWord}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.searched_kakou = response.data;
          if (this.searched_kakou.length === 0) {
            this.searchResult_nullFlg = true;
          } else {
            this.searchResult_nullFlg = false;
          }
        })
        .catch((error) => {
          alert("加工区分が取得できませんでした\n" + error);
        });
    },
    search() {
      this.getKakouInfo();
    },
    addKakou(kakouData) {
      let duplicated_flg = false;
      this.selected_kakou.forEach((kakou) => {
        if (kakou.kbn === kakouData.kakoMei) {
          duplicated_flg = true;
        }
      });
      if (!duplicated_flg) {
        this.selected_kakou.push({ kbn: kakouData.kakoMei });
      }
    },
    removeKakou(index) {
      this.selected_kakou.splice(index, 1);
    },
    updateKakou() {
      this.$emit("updateKakou", this.selected_kakou);
      this.hideModal();
    },
  },
};
</script>
