<template>
  <span class="BadgeHold">保留</span>
</template>

<style scoped lang="scss">
.BadgeHold {
  background: red;
  color: #fff;
  width: 34px;
  height: 18px;
}
</style>