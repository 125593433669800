<template>
  <div>
    <div class="side_selected_title">作品・原価情報</div>

    <div class="side_page_area">
      <div class="side_input_group">
        <label for="page_in">ページ数：</label>
        <!-- 一旦is_dividedの分岐はエラーを吐くので保留にする  -->
        <template v-if="vm_selectedSakuhin.is_divided">
          <input
            type="text"
            id="page_in"
            class="page_input"
            :value="vm_selectedSakuhin.total_page"
            @change="input_totalPage"
            :disabled="isLockDaiwari"
          />
        </template>
        <template v-else>
          <input
            type="text"
            id="page_in"
            class="page_input"
            ref="input_page"
            :value="vm_selectedSakuhin.page"
            @change="input_page"
            :disabled="isLockDaiwari"
          />
        </template>
        <br />
        <p v-if="inputErrorFlg_zenkaku" class="error-message">
          ※半角数字で入力してください
        </p>
        <p v-if="inputErrorFlg_overMaxPage" class="error-message">
          ※500以下の数値を設定してください
        </p>
        <p v-if="inputErrorFlg_zeroPage" class="error-message">
          ※1以上の数値を設定してください
        </p>
      </div>
      <div class="side_input_group flex">
        <!-- <label for="sakuhin_in">ページ設定：</label><br /> -->
        カウント対象外
        <VueToggles
          @click="togglePageCountFlg"
          :value="!vm_selectedSakuhin.pageCountFlg"
          :width="50"
          :disabled="isLockDaiwari"
        />
      </div>
      <div class="side_input_group flex">
        <!-- <label for="sakuhin_in">表紙設定：</label><br /> -->
        表紙に設定する
        <VueToggles
          @click="change_hyousiFlg"
          :value="vm_selectedSakuhin.toggleHyousiFlg"
          :width="50"
          :disabled="isLockDaiwari"
        />
      </div>
    </div>

    <div class="side_sakuhin_area">
      <div class="side_input_group">
        <label for="sakuhin_in">作品設定：</label>
        <!-- <button
          @click="showModal('modal_sakuhinMei')"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
        >
          検索
        </button> -->
        <button class="disable_button--side">検索</button>
        <ul class="modal__review__badge-container">
          <li
            @mouseover="isShowSakuhinTooltip = true"
            @mouseleave="isShowSakuhinTooltip = false"
            class="modal__review__badge-name modal__review__badge-selected"
          >
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="modal__review__badge-cancel"
              @click="updateSakuhinMei_fromSide('')"
              v-if="!isLockDaiwari && vm_selectedSakuhin.sakuhin_mei !== ''"
            />
            {{ vm_selectedSakuhin.sakuhin_mei }}
          </li>
        </ul>
        <!-- ツールチップ -->
        <transition>
          <div
            v-if="selectedSakuhin.sakuhin_mei !== '' && isShowSakuhinTooltip"
            class="sakuhin_tooltip"
          >
            <div class="balloon-002">
              {{ selectedSakuhin.sakuhin_mei }}
            </div>
          </div>
        </transition>
      </div>
      <div class="side_input_group">
        <label for="episode_in"
          >話数：
          <input
            type="number"
            id="episode_in"
            :value="vm_selectedSakuhin.episode"
            @input="input_episode"
            :disabled="isLockDaiwari"
          />
        </label>
      </div>
      <div class="side_input_group">
        <label for="tantou_in">ﾍﾟﾝﾈｰﾑ：</label>
        <!-- <button
          @click="showModal('modal_penName')"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
        >
          検索
        </button>
        <button
          @click="selectIkkatu('modal_penName')"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
        >
          一括設定
        </button> -->
        <button class="disable_button--side">検索</button>
        <button class="disable_button--side">一括設定</button>
        <span
          class="modal__review__badge-name modal__review__badge-selected"
          v-for="(penName, index) in vm_selectedSakuhin.penName"
          :key="'penname_' + index"
        >
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="modal__review__badge-cancel"
            @click="removePenName(penName, index)"
            v-if="!isLockDaiwari && vm_selectedSakuhin.penName.length"
          />
          {{ penName.penName }}
        </span>
      </div>
      <div class="side_input_group">
        <label for="sakuhin_in"
          >タイトル補足：
          <input
            :value="vm_selectedSakuhin.sakuhin_mei_hosoku"
            @input="input_sakuhin_mei_hosoku"
            :disabled="isLockDaiwari"
          />
          <!-- <SuggestSakuhinMei
            :sakuhinMei="this.selectedSakuhin.sakuhin_mei"
            :input_sakuhinMei="input_sakuhinMei"
            :sakuhinMeiList="sakuhinMeiList"
            @updateSakuhinMeiFromSide="updateSakuhinMei_fromSide"
            @updateSakuhinMeiSuggestion="updateSakuhinMei_suggestion"
            :isLockDaiwari="isLockDaiwari"
          /> -->
        </label>
      </div>
      <div class="side_input_group">
        <label for="sakuhin_in">台割表示名(自動生成)：</label><br />
        {{ vm_selectedSakuhin.sakuhin_mei_display }}
      </div>
      <!-- <div class="side_input_group">
        <label for="sakuhin_in">作品設定：</label>
        <span
          v-if="selectedSakuhin.sakuhin_mei_suggest"
          class="modal__review__badge"
        >
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="modal__review__badge-cancel"
            @click="updateSakuhinMei_suggestion('')"
            v-if="!isLockDaiwari"
          />{{ selectedSakuhin.sakuhin_mei_suggest }}
        </span>
        <span v-else>未設定</span>
      </div>
      <div class="side_input_group">
        <label for="sakuhin_in">作品表示名：</label>
        <br />
        <SuggestSakuhinMei
          :sakuhinMei="this.selectedSakuhin.sakuhin_mei"
          :input_sakuhinMei="input_sakuhinMei"
          :sakuhinMeiList="sakuhinMeiList"
          @updateSakuhinMeiFromSide="updateSakuhinMei_fromSide"
          @updateSakuhinMeiSuggestion="updateSakuhinMei_suggestion"
          :isLockDaiwari="isLockDaiwari"
        />
      </div> -->
      <div class="side_input_group">
        <label for="tantou_in">担当：</label>
        <!-- <button
          @click="showModal('modal_tantou')"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
        >
          検索
        </button>
        <button
          @click="selectIkkatu('modal_tantou')"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
        >
          一括設定
        </button> -->
        <button class="disable_button--side">検索</button>
        <button class="disable_button--side">一括設定</button>
        <ul class="modal__review__badge-container">
          <li
            class="modal__review__badge-selected"
            v-for="(tantou, index) in vm_selectedSakuhin.tantou"
            :key="index"
          >
            <span class="modal__review__badge-name">
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="modal__review__badge-cancel"
                @click="removeTantou(index)"
                v-if="!isLockDaiwari"
              />
              {{ tantou.user_last_name }}{{ tantou.user_first_name }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="side_extra_area">
      <div class="side_extra_title_area">
        <label for="side_extra_title">進行状態：</label>
        <br />
        <select
          id="sinkou_st_in"
          class="side_input"
          :value="vm_selectedSakuhin.sinkou_status"
          @input="input_sinkouStatus"
          :disabled="isLockDaiwari"
        >
          <option value>未設定</option>
          <option value="Ⅰ.企画立案">Ⅰ.企画立案</option>
          <option value="Ⅱ.原稿制作">Ⅱ.原稿制作</option>
          <option value="Ⅲ.組版・製版">Ⅲ.組版・製版</option>
          <option value="Ⅳ.校了">Ⅳ.校了</option>
          <option value="Ⅴ.印刷">Ⅴ.印刷</option>
          <option value="Ⅵ.原価登録">Ⅵ.原価登録</option>
        </select>
      </div>
      <!-- 原稿料設定 -->
      <div v-if="!isDensi" class="side_extra_title_area">
        <span class="side_extra_title">原稿料設定：</span>
        <br />
        <select
          name="genkoryo-settei"
          id="genkoryo-settei"
          :disabled="isLockDaiwari"
          class="side_input"
          @change="changeGenkoryoSettei"
        >
          <option
            v-for="(genkoryoSelect, selectIndex) in genkoryoSetteiSelect"
            :key="selectIndex"
            :value="JSON.stringify(genkoryoSelect)"
            :selected="
              vm_selectedSakuhin.genkoryo_settei.setteiKbn ===
              genkoryoSelect.setteiKbn
            "
          >
            {{ genkoryoSelect.meisyo }}
          </option>
        </select>
      </div>
      <div class="side_extra_title_area">
        <span class="side_extra_title">備考・進行メモ：</span>
        <textarea
          cols="25"
          rows="3"
          class="side_extra_textarea"
          @input="set_sakuhinBikou"
          :value="vm_selectedSakuhin.sakuhin_bikou"
          :disabled="isLockDaiwari"
        />
        <div
          v-if="vm_selectedSakuhin.sakuhin_bikou !== ''"
          class="bikou_toggleArea"
        >
          <span>マークする</span>
          <VueToggles
            @click="toggleSakuhinBikouMarkFlg"
            :value="vm_selectedSakuhin.sakuhin_bikou_markflg"
            :width="50"
            :disabled="isLockDaiwari"
          />
        </div>
      </div>
      <!-- <div v-if="!isDensi" class="side_extra_title_area">
        <span class="side_extra_title">原価情報入力&emsp;</span>
        <button
          @click="addGenka"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : ''"
        >
          <i class="fas fa-plus-circle"></i> 原価追加
        </button>
      </div>
      <template v-if="selectedSakuhin.genka_array.length > 0">
        <template v-for="(genka, index) in selectedSakuhin.genka_array">
          <div class="side_extra_group" :key="index">
            <div class="badge_genka">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="side_extra_group_inner">
              <label>
                区分
                <span class="space"></span>：
                <select
                  class="side_input_extra"
                  :value="genka.genkaKbnId"
                  @change="input_genkaKbn(index, $event)"
                  :disabled="isLockDaiwari"
                >
                  <option
                    v-for="(kbn, index) in genkaKbnList"
                    :key="index"
                    :value="kbn.genkaKbnId"
                  >
                    {{ kbn.meisyo }}
                  </option>
                </select>
              </label>
              <br />
              <label>
                取引先：
                <span v-if="genka.torihikisaki === ''">未設定</span>
                <span v-else class="modal__review__badge-torihikisaki">
                  <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="modal__review__badge-cancel"
                    @click="removeTorihikisaki(genka, index)"
                    v-if="!isLockDaiwari"
                  />
                  {{ genka.torihikisaki.torihikisakiMei }}
                </span>
              </label>
              <div class="side_btn_container">
                <button
                  @click="showModal('modal_torihikisaki', index)"
                  :disabled="isLockDaiwari"
                  :class="isLockDaiwari ? 'disable_button--side' : ''"
                >
                  取引先検索
                </button>
                <button
                  @click="deleteGenka(index)"
                  :disabled="isLockDaiwari"
                  :class="
                    isLockDaiwari ? 'disable_button--side' : 'delete_button'
                  "
                >
                  <i class="fas fa-minus-circle"></i> 原価削除
                </button>
              </div>
            </div>
          </div>
        </template>
      </template> -->
    </div>
    <div class="delete_btn">
      <div class="side_input_group">
        <button
          @click="deleteSakuhin"
          :disabled="isLockDaiwari"
          :class="isLockDaiwari ? 'disable_button--side' : 'delete_button'"
        >
          <i class="fas fa-minus-circle"></i> 作品削除
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import SuggestSakuhinMei from "../SuggestSakuhinMei.vue";
import * as pageBikou from "@/daiwariJS/PageBikou.js";
import { useVModel } from "vue-composable";

export default {
  props: {
    selectedSakuhin: {
      type: Object,
    },
    daiwariData: {
      type: Object,
    },
    genkaKbnList: {
      type: Array,
    },
    sakuhinMeiList: {
      type: Array,
    },
    inputGenkaKbn: {
      type: Function,
    },
    toggleHyousiFlg: {
      type: Function,
    },
    isLockDaiwari: Boolean,
    isDensi: Boolean,
  },
  emits: [
    "inputTotalPage",
    "inputPage",
    "togglePageCountFlg",
    "updateSakuhinMeiFromSide",
    "input_sakuhin_mei_hosoku",
    "input_episode",
    "inputTantouMei",
    "inputSinkouStatus",
    "addGenka",
    "deleteGenka",
    "deleteSakuhin",
    "showModal",
    "removeTorihikisaki",
    "removeTantou",
    "set_sakuhinBikou",
    "toggleSakuhinBikouMarkFlg",
    "removePenName",
    "changeGenkoryoSettei",
  ],
  // components: {
  //   SuggestSakuhinMei,
  // },
  setup(props) {
    const vm_selectedSakuhin = useVModel(props, "selectedSakuhin");
    return {
      vm_selectedSakuhin,
    };
  },
  data() {
    return {
      inputErrorFlg_zenkaku: false,
      inputErrorFlg_overMaxPage: false,
      inputErrorFlg_zeroPage: false,
      pageCountFlg: false,
      genkoryoSetteiSelect: [
        {
          setteiKbn: 1,
          meisyo: "原稿料あり",
        },
        {
          setteiKbn: 2,
          meisyo: "原稿料なし",
        },
        {
          setteiKbn: 3,
          meisyo: "原稿流用",
        },
        {
          setteiKbn: 4,
          meisyo: "前渡原稿料",
        },
      ],
      genkoryoSettei: {
        setteiKbn: null,
        kbnMeisyo: "",
      },
      isShowSakuhinTooltip: false,
    };
  },
  methods: {
    change_pageCount() {
      this.pageCountFlg = !this.pageCountFlg;
    },
    focusInputPage() {
      this.$refs.input_page.focus();
    },
    increment() {
      this.sakuhinPage++;
    },
    //ページの入力1
    input_totalPage(event) {
      const oldVal = this.selectedSakuhin.total_page;
      this.vm_selectedSakuhin.before_totalPage =
        this.selectedSakuhin.total_page;
      let newVal = event.target.value;
      Number(newVal);
      //newValが全角の場合
      if (isNaN(newVal)) {
        this.inputErrorFlg_zenkaku = true;
        this.inputErrorFlg_overMaxPage = false;
        this.inputErrorFlg_zeroPage = false;
      }
      //newValが500以上の場合(仮)
      else if (Number(newVal) > 500) {
        this.inputErrorFlg_zenkaku = false;
        this.inputErrorFlg_overMaxPage = true;
        this.inputErrorFlg_zeroPage = false;
      }
      //newValが0の場合
      else if (Number(newVal) == 0) {
        this.inputErrorFlg_zenkaku = false;
        this.inputErrorFlg_overMaxPage = false;
        this.inputErrorFlg_zeroPage = true;
      } else {
        if (Number(newVal) > 0) {
          this.inputErrorFlg_zenkaku = false;
          this.inputErrorFlg_overMaxPage = false;
          this.inputErrorFlg_zeroPage = false;

          //備考データの有無で削除キャンセルした場合
          if (
            !pageBikou.update_pageBikouArray(
              this.daiwariData,
              this.selectedSakuhin,
              this.daiwariData.sakuhin_page_bikou_array,
              newVal
            ).should_changePage
          ) {
            this.vm_selectedSakuhin.page = oldVal;
            event.target.value = oldVal;
            return;
          } else {
            this.$emit("inputTotalPage", Number(newVal));
          }
        }
      }
    },
    //ページの入力2
    input_page(event) {
      const oldVal = this.selectedSakuhin.total_page;
      let newVal = event.target.value;
      Number(newVal);
      if (isNaN(newVal)) {
        this.inputErrorFlg_zenkaku = true;
        this.inputErrorFlg_overMaxPage = false;
        this.inputErrorFlg_zeroPage = false;
      }
      //newValが500以上の場合(仮)
      else if (Number(newVal) > 500) {
        this.inputErrorFlg_zenkaku = false;
        this.inputErrorFlg_overMaxPage = true;
        this.inputErrorFlg_zeroPage = false;
      }
      //newValが0の場合
      else if (Number(newVal) == 0) {
        this.inputErrorFlg_zenkaku = false;
        this.inputErrorFlg_overMaxPage = false;
        this.inputErrorFlg_zeroPage = true;
      } else {
        if (Number(newVal) > 0) {
          this.inputErrorFlg_zenkaku = false;
          this.inputErrorFlg_overMaxPage = false;
          this.inputErrorFlg_zeroPage = false;

          //備考データの有無で削除キャンセルした場合
          if (
            !pageBikou.update_pageBikouArray(
              this.daiwariData,
              this.selectedSakuhin,
              this.daiwariData.sakuhin_page_bikou_array,
              newVal
            ).should_changePage
          ) {
            this.vm_selectedSakuhin.page = oldVal;
            event.target.value = oldVal;
            return;
          } else {
            this.$emit("inputPage", Number(newVal));
          }
        }
      }
    },
    //ページカウント対象外
    togglePageCountFlg() {
      this.$emit("togglePageCountFlg");
    },
    change_hyousiFlg() {
      this.toggleHyousiFlg();
    },
    // //作品名の入力
    // input_sakuhinMei(newVal) {
    //   this.$emit("inputSakuhinMei", newVal);
    // },
    updateSakuhinMei_fromSide(newVal_sakuhinmei) {
      this.$emit("updateSakuhinMeiFromSide", newVal_sakuhinmei, null);
    },
    //タイトル補足の入力
    input_sakuhin_mei_hosoku(e) {
      this.$emit("input_sakuhin_mei_hosoku", e.target.value);
    },
    //話数の入力
    input_episode(e) {
      this.$emit("input_episode", parseInt(e.target.value));
    },
    //手入力で書き換えできない表示用の作品名
    // updateSakuhinMei_suggestion(newVal_sakuhinmei) {
    //   this.$emit("updateSakuhinMeiSuggestion", newVal_sakuhinmei);
    // },
    //担当者の入力
    input_tantouMei(event) {
      this.$emit("inputTantouMei", event.target.value);
    },
    //進行状況の入力
    input_sinkouStatus(event) {
      this.$emit("inputSinkouStatus", event.target.value);
    },
    //原価の追加
    addGenka() {
      const pushData = {
        meisyo: "未設定",
        genkaKbnId: 0,
        torihikisaki: "",
        statusComment: "",
        isAutoAppend: false,
        sakuhin_index: this.selectedSakuhin.sakuhin_address_computed - 1,
        genkaInfo_array: [
          {
            genkaKbnId: 1,
            meisyo: "外注加工費",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 2,
            meisyo: "企画編集費",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 3,
            meisyo: "原稿料",
            suuryo: 0,
            tankaInfo_array: [
              {
                sikisuu: "1C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "4C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "その他",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
            ],
          },
          {
            genkaKbnId: 4,
            meisyo: "印税・版権料",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 5,
            meisyo: "デザイン料",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 999999999,
            meisyo: "科目不明",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
        ],
      };
      this.$emit("addGenka", pushData);
    },
    //原価区分の変更
    input_genkaKbn(index, event) {
      this.inputGenkaKbn(index, event.target.value);
    },
    //原価の削除
    deleteGenka(index) {
      this.$emit("deleteGenka", index);
    },
    //作品の削除
    deleteSakuhin() {
      this.$emit("deleteSakuhin");
    },
    //モーダル表示
    showModal(modalName, index) {
      if (modalName === "modal_torihikisaki") {
        this.$emit("showModal", modalName, index);
      } else {
        this.$emit("showModal", modalName);
      }
    },
    selectIkkatu(modalName) {
      //一括であることをモーダルに伝える
      this.$emit("showModal", modalName, true);
    },
    removeTorihikisaki(genka, index) {
      this.$emit("removeTorihikisaki", genka, index);
    },
    removeTantou(removeIndex) {
      this.$emit("removeTantou", removeIndex);
    },

    //備考・進行メモの設定
    set_sakuhinBikou(e) {
      const sakuhin_bikou = e.target.value;
      this.$emit("set_sakuhinBikou", sakuhin_bikou);
    },
    toggleSakuhinBikouMarkFlg() {
      this.$emit("toggleSakuhinBikouMarkFlg");
    },
    //ペンネームの取り消し
    removePenName(penName, index) {
      this.$emit("removePenName", penName, index);
    },

    // 原稿料設定関連
    changeGenkoryoSettei(e) {
      this.$emit("changeGenkoryoSettei", JSON.parse(e.target.value));
    },
  },
  watch: {
    "selectedSakuhin.sakuhin_mei"() {
      if (this.selectedSakuhin.sakuhin_mei === "") {
        this.isShowSakuhinTooltip = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#daiwari_edit {
  .modal__review {
    &__badge {
      display: inline-block;
      background-color: rgb(235, 235, 235);
      color: rgb(0, 0, 0);
      border: none;
      border-radius: 20px;
      padding: 0px 15px;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__badge-container {
      list-style: none;
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    &__badge-selected {
      background-color: rgb(235, 235, 235);
      color: rgb(0, 0, 0);
      border: none;
      border-radius: 20px;
      padding: 0 15px;
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      width: fit-content;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }

    &__badge-name {
      display: inline-block;
    }

    &__badge-cancel {
      cursor: pointer;
      display: inline-block;
      padding-right: 3px;
    }

    &__badge-torihikisaki {
      display: inline-block;
      font-size: 10px;
      background: rgb(235, 235, 235);
      padding: 0 15px;
      border-radius: 8px;
      width: 60%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  .error-message {
    color: rgb(255, 0, 0);
    font-size: 11px;
    letter-spacing: -0.5px;
  }

  .bikou_toggleArea {
    text-align: right;
    margin-right: 15px;
    display: flex;
    gap: 5px;
  }

  // 上書きCSS
  .page_input {
    width: 100px;
  }

  button {
    min-width: 50px;
  }
}

// ツールチップ
.sakuhin_tooltip {
  position: absolute;
  .balloon-002 {
    position: relative;
    text-align: center;
    padding: 2px;
    width: 200px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #ebebeb;
    color: #000;
    font-weight: 600;
    font-size: 11px;
  }
  .balloon-002::before {
    position: absolute;
    top: -9px;
    width: 20px;
    height: 10px;
    left: 5px;
    background-color: #ebebeb;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    content: "";
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
