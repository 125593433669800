<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">台割枝番</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal()"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <div class="modal__message" v-if="edaban_list.length">
          <table class="frame_table">
            <thead>
              <tr>
                <th>台割枝番名</th>
                <th>登録日時</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(edaban, index) in edaban_list" :key="index">
                <td>
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    @click="toggleInputArea(index)"
                  />{{ edaban.childDaiwariName }}
                  <!-- 枝番名変更用の入力欄 -->
                  <div
                    class="input_container"
                    v-show="inputAreaNumber === index"
                  >
                    <input v-model="new_edaban_name" type="text" /><button
                      @click="renameEdaban(index)"
                    >
                      更新
                    </button>
                    <span
                      ><font-awesome-icon
                        class="icon_close"
                        :icon="['fas', 'times']"
                        @click="toggleInputArea(index)"
                    /></span>
                  </div>
                </td>
                <td>{{ edaban.torokuDateStr }}</td>
                <td>
                  <span>
                    <button
                      class="btn_small"
                      @click="move_to_daiwariEditChild(edaban)"
                    >
                      編集
                    </button>
                    <button
                      class="btn_small"
                      @click="deleteEdaban(edaban, index)"
                      style="background-color: red"
                    >
                      削除
                    </button>
                    <div class="change_toji_container" v-if="!edaban.isDensi">
                      <select class="select_box" v-model="edaban.isNakatoji">
                        <option :value="false">無線綴じ</option>
                        <option :value="true">中綴じ</option>
                      </select>
                      <button
                        @click="changeToji(edaban)"
                        v-if="edaban.isNakatoji !== edaban.isNakatojiInit"
                      >
                        変更
                      </button>
                    </div>
                  </span>
                </td>
              </tr>
              <!-- <tr v-for="(item,index) in koumoku_array" :key="index">
                <td>
                  <input type="checkbox" v-model="item.isSelected" />
                </td>
                <td>{{item.koumokuMei}}</td>
              </tr>-->
            </tbody>
          </table>
        </div>
        <div class="modal__message" v-if="!edaban_list.length">
          <div class="modal__message__none">
            <p>台割枝番はありません</p>
          </div>
        </div>
        <div class="modal__bottom">
          <div class="modal__bottom__input">
            <div class="modal__bottom__input_list">
              <div class="modal__bottom__input_item">
                <span class="modal__bottom__input_item_title">電子台割</span>
                <VueToggles
                  @click="toggleDensiFlg"
                  :value="isDensi"
                  :width="50"
                />
                <!-- <input type="text" class="text_box" v-model="edaban_name" /> -->
              </div>
              <div class="modal__bottom__input_item">
                <span class="modal__bottom__input_item_title">綴じ選択</span>
                <select
                  class="select_box"
                  v-model="isNakatoji"
                  :disabled="isDensi"
                >
                  <option :value="false">無線綴じ</option>
                  <option :value="true">中綴じ</option>
                </select>
              </div>
              <div class="modal__bottom__input_item">
                <span class="modal__bottom__input_item_title">台割枝番名</span>
                <input type="text" class="text_box" v-model="edaban_name" />
              </div>
            </div>
            <button
              v-if="isDensi && !isOyaDaiwariKakutei"
              class="btn_disabled"
              disabled
            >
              作成
            </button>
            <button v-else class="btn" @click="createEdaban">作成</button>
          </div>
          <div class="modal__bottom__error" v-if="edaban_nullMessage">
            {{ edaban_nullMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Notyf } from "notyf";
const notyf = new Notyf();

export default {
  props: {
    daiwariId: Number,
    kikakuId: Number,
    url: String,
    is_daiwari_saved: Boolean,
    isOyaDaiwariKakutei: Boolean,
  },
  emits: [
    "startProcessing",
    "endProcessing",
    "unLockDaiwari",
    "hide_acordion",
    "hideModal",
    "count_timeOut",
  ],
  data() {
    return {
      edaban_list: [],
      edaban_name: null,
      new_edaban_name: "",
      edaban_nullMessage: null,
      isNakatoji: false,
      inputAreaNumber: -1,
      isDensi: false,
    };
  },
  created() {
    this.getEdaban();
  },
  methods: {
    getEdaban() {
      axios
        .get(
          `${this.url}/daiwari/get_child_daiwari?parentDaiwariId=${this.daiwariId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.edaban_list = response.data;
          // 綴じ方の初期値保持用の変数をセットする
          this.edaban_list.forEach((list) => {
            list.isNakatojiInit = list.isNakatoji;
          });
        })
        .catch((error) => {
          alert("台割枝番データが取得できませんでした\n" + error);
        });
    },
    toggleDensiFlg() {
      this.edaban_nullMessage = "";
      //電子版は親台割を引き継ぐので必ず無線綴じになる
      this.isDensi = !this.isDensi;
      if (this.isDensi) {
        this.isNakatoji = false;
      }

      // 親台割未確定で電子フラグオンにした場合エラーメッセージを出す
      if (this.isDensi && !this.isOyaDaiwariKakutei) {
        this.edaban_nullMessage = "電子台割は台割確定後に作成可能です";
      }
    },
    createEdaban() {
      this.edaban_nullMessage = null;
      setTimeout(() => {
        if (this.edaban_name) {
          let confirm = window.confirm(
            `「${this.edaban_name}」の台割枝番を作成してもよろしいですか?`
          );
          if (confirm) {
            let postData = {
              kikakuId: this.kikakuId, // 企画Id
              daiwariId: this.daiwariId, // 親の台割ID
              isDensi: this.isDensi, // 電子台割か否か
              tojiId: this.isNakatoji ? 2 : 1,
              daiwariEdabanName: this.edaban_name, // 枝番台割名
            };
            if (this.isDensi) {
              this.$emit("startProcessing");
            }
            axios
              .post(`${this.url}/daiwari/save_child_daiwari`, postData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              })
              .then(() => {
                this.$emit("endProcessing");
                notyf.success("枝番を作成しました");
                this.edaban_name = null;
                //枝番再取得
                this.getEdaban();
                //作成した枝番の編集画面に遷移
                setTimeout(() => {
                  const targrt_edaban =
                    this.edaban_list[this.edaban_list.length - 1];
                  this.move_to_daiwariEditChild(targrt_edaban);
                }, 500);
              })
              .catch((error) => {
                this.$emit("endProcessing");
                notyf.error("枝番を作成できませんでした");
                console.log(error);
              });
          }
        } else {
          this.edaban_nullMessage = "※台割枝番名を入力してください";
        }
      }, 100);
    },
    deleteEdaban(edaban, edabanIndex) {
      const confirm = window.confirm(
        `『${edaban.childDaiwariName}』を削除してもよろしいですか?`
      );
      if (confirm) {
        axios
          .post(
            `${this.url}/daiwari/delete_daiwari_child?daiwariId=${edaban.daiwariId}`,
            {
              daiwariId: edaban.daiwariId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((res) => {
            // const deleteStatus = res.data; // 1:削除成功,0:削除失敗,null:削除失敗（セッションタイムアウト）
            // if (deleteStatus === 1) {
            //   notyf.success(`『${edaban.childDaiwariName}』を削除しました`);
            //   //ローカルも削除
            //   this.edaban_list.splice(edabanIndex, 1);
            // } else if (deleteStatus === 0) {
            //   notyf.error(
            //     `『${edaban.childDaiwariName}』を削除できませんでした`
            //   );
            // }
            if (res.status == 200) {
              notyf.success(`『${edaban.childDaiwariName}』を削除しました`);
              //ローカルも削除
              this.edaban_list.splice(edabanIndex, 1);
              // 関連台割リストから削除
              this.$emit("deleteEdaban", edaban.daiwariId);
            } else {
              alert(
                `セッションがタイムアウトしました。再度ログインしてから実行してください。`
              );
            }
          })
          .catch((error) => {
            notyf.error("枝番台割の削除に失敗しました");
            console.log(error);
          });
      }
    },
    move_to_daiwariEditChild(edaban) {
      // 枝番台割の企画IDの補填
      if (edaban.kikakuId == null) {
        edaban.kikakuId = this.kikakuId;
      }

      //未保存ならページ遷移して良いかの確認
      let isAllowedToMoveChildDaiwari = true;
      if (!this.is_daiwari_saved) {
        isAllowedToMoveChildDaiwari = window.confirm(
          "編集中の台割が未保存ですが移動してもよろしいですか？"
        );
      }
      if (!isAllowedToMoveChildDaiwari) {
        return;
      }
      if (edaban.daiwariId && edaban.kikakuId) {
        //urlのparamsを枝番用に更新
        const url = new URL(window.location);
        url.searchParams.set("daiwariId", edaban.daiwariId);
        url.searchParams.set("kikakuId", edaban.kikakuId);
        url.searchParams.set("isEdaban", true);

        //枝番画面に遷移
        window.history.pushState({ name: "daiwari" }, "", url);
        window.location.reload();

        //親台割から離れ子台割に移ることになるので親台割のロックを解除する
        this.$emit("unLockDaiwari");
        this.$emit("hide_acordion");

        this.hideModal();
      } else {
        alert("台割枝番の情報が不足しているため編集が出来ません");
      }
    },

    //枝番名変更用の入力欄の開閉
    toggleInputArea(edabanIndex) {
      //入力欄をリセット
      this.new_edaban_name = "";
      if (this.inputAreaNumber === edabanIndex) {
        this.inputAreaNumber = -1;
      } else {
        this.inputAreaNumber = edabanIndex;
      }
    },
    renameEdaban(edabanIndex) {
      const targetEdaban = this.edaban_list[edabanIndex];
      //枝番名の更新
      if (this.new_edaban_name !== "") {
        const confirm = window.confirm(
          `『${targetEdaban.childDaiwariName}』の枝番名を『${this.new_edaban_name}』に変更してもよろしいですか？`
        );
        if (confirm) {
          //DBの枝番データを更新
          const postData = {
            daiwariId: targetEdaban.daiwariId,
            daiwariEdabanName: this.new_edaban_name,
          };
          axios
            .post(`${this.url}/daiwari/update_daiwari_child_name`, postData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            })
            .then(() => {
              notyf.success("枝番名を変更しました");
              //枝番再取得
              this.getEdaban();
            })
            .catch((error) => {
              notyf.error("枝番名を変更できませんでした");
              console.log(error);
            });
          //入力欄閉じる
          this.toggleInputArea(-1);
        }
      } else {
        alert("枝番名を入力してください");
      }
    },
    // 製本方法の変更
    async changeToji(edabanData) {
      try {
        const postData = {
          daiwariId: edabanData.daiwariId,
          tojiId: edabanData.isNakatoji ? 2 : 1,
        };
        await axios.post(`${this.url}/daiwari/change_toji_type`, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        notyf.success(`${edabanData.childDaiwariName}の綴じを変更しました`);
        // App.vueのdaiwariResponseData.edabanDaiwariListは更新できないが、isNakatojiを特に使わないので未対応
        this.getEdaban();
      } catch (error) {
        console.log(error);
        notyf.error("綴じの変更に失敗しました");
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 600px;
    height: 560px;
    gap: 2%;
  }
  .modal__message {
    font-size: 16px;
    height: 70%;
    width: 100%;
    overflow-y: auto;
    &__none {
      background: rgb(235, 235, 235);
      width: 70%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal__bottom {
    width: 70%;
    height: 20%;
    padding-top: 20px;
    border-top: 1px solid #2a3f54;
    border-style: dashed;
    &__input {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;
      &_list {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      &_item {
        display: flex;
        &_title {
          width: 80px;
        }
      }
    }
    &__error {
      color: red;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
  }

  .btn {
    width: 80px;
    height: 40px;
    &_small {
      width: 80px;
    }
    &_disabled {
      @extend .btn;
      background: #b9b9b9;
      pointer-events: none;
    }
  }
  .select_box {
    height: 30px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
  }
  .frame_table {
    height: 100%;
    width: 500px;
    margin: 0 auto;
  }

  .frame_table th,
  .frame_table td {
    vertical-align: middle;
    max-width: 220px;
  }
  .frame_table td {
    padding: 5px 15px;
  }
  .frame_table td > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  //綴じ方変更用のセレクト
  .change_toji_container {
    display: flex;
    gap: 5px;
    align-items: center;
    button {
      min-width: 50px;
      height: 25px;
      padding: 2px;
      font-size: 11px;
    }
  }
  //枝番名変更用の入力欄
  .input_container {
    position: absolute;
    z-index: 999;
    width: 155px;
    height: 40px;
    background: #dee2e6;
    border: 2px solid #ced4da;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    // top:0px;
    input {
      width: 60%;
      height: 20px;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }
    button {
      min-width: 50px;
      height: 20px;
      padding: 2px;
      font-size: 11px;
    }
    span {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0px 2px;
    }
  }
  //吹き出しの先
  .input_container:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 12%;
    margin-left: -15px;
    border: 6px solid transparent;
    border-bottom: 6px solid #dee2e6ff;
    z-index: 2;
  }
  //吹き出しの先
  .input_container:after {
    content: "";
    position: absolute;
    top: -17px;
    left: 12%;
    margin-left: -17px;
    border: 8px solid transparent;
    border-bottom: 8px solid #ced4da;
    z-index: 1;
  }
}
</style>
