<template>
  <div>
    <div class="side_selected_title">折情報</div>
    <div class="side_ori_area">
      <div class="side_input_group">
        <label for="ori_kbn_in">折区分：</label>
        <br />
        <input
          list="ori_kbn_select"
          :value="selectedOri.ori_kbn"
          @change="input_oriKbn"
          id="ori_kbn_in"
          name="ori_kbn_in"
          :disabled="isLockDaiwari"
        />
        <datalist id="ori_kbn_select">
          <option value="表1"></option>
          <option value="表2"></option>
          <option value="表3"></option>
          <option value="表4"></option>
          <option value="口絵"></option>
          <option value="ハガキ"></option>
        </datalist>
      </div>
      <div class="side_input_group">
        <label class="siki_suu_label" for="siki_suu_in">色数：</label>
        <br />
        <div class="siki_suu_group--text" v-if="!this.isAllowedToChangeSikisuu">
          <span class="siki_suu_text">{{ selectedOri.sikisuu }}</span>
          <span class="siki_suu_text" v-if="!selectedOri.sikisuu">未設定</span>
        </div>
        <div v-else>
          <p v-if="sikisuuuErrorFlg" class="error-message">
            ※0以上の半角数値を入力してください
          </p>
          <input
            type="number"
            min="0"
            class="sikisuu_input"
            @change="input_sikisuu"
            :value="Number(selectedOri.sikisuu.replace('C', ''))"
            :disabled="isLockDaiwari"
          />
          <span>C</span>
        </div>
      </div>
      <div class="side_input_group">
        <label for="yousi_in">用紙：</label>
        <!-- 用紙(変更可) -->
        <span v-if="this.isAllowedToChangeSikisuu">
          <button
            @click="showModal('modal_yosi')"
            :disabled="isLockDaiwari"
            :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
          >
            用紙検索
          </button>
          <span class="modal__review__badge-name modal__review__badge-selected">
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="delete_youshi"
              @click="removeYoushi"
              v-if="!isLockDaiwari && selectedOri.yousi !== ''"
            />
            {{ selectedOri.yousi }}
          </span>
        </span>
        <!-- 用紙(変更不可) -->
        <span v-if="!this.isAllowedToChangeSikisuu">
          <span class="modal__review__badge-name modal__review__badge-selected">
            {{ selectedOri.yousi }}
          </span>
        </span>
      </div>
      <div class="side_input_group">
        <label for="yousi_in">加工区分：</label>
        <!-- 加工(変更可) -->
        <span v-if="this.isAllowedToChangeSikisuu">
          <button
            @click="showModal('modal_kakou')"
            :disabled="isLockDaiwari"
            :class="isLockDaiwari ? 'disable_button--side' : 'action_button'"
          >
            加工検索
          </button>
          <span
            class="modal__review__badge-name modal__review__badge-selected"
            v-for="(kakou, kakouIndex) in selectedOri.kakou_array"
            :key="'kakou_' + kakouIndex"
          >
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="delete_youshi"
              @click="removeKakou(kakouIndex)"
              v-if="!isLockDaiwari && selectedOri.kakou_array.length > 0"
            />
            {{ kakou.kbn }}
          </span>
        </span>
        <!-- 加工(変更不可) -->
        <span v-if="!this.isAllowedToChangeSikisuu">
          <span
            class="modal__review__badge-name modal__review__badge-selected"
            v-for="(kakou, kakouIndex) in selectedOri.kakou_array"
            :key="'kakou_' + kakouIndex"
          >
            {{ kakou.kbn }}
          </span>
        </span>
        <!-- <label for="kakou_in">加工区分：</label>
        <br />
        <select
          class="side_input"
          :value="selectedOri.kakou_array[0].kbn"
          @input="input_kakou"
          :disabled="isLockDaiwari||!this.isAllowedToChangeSikisuu"
        >
          <option value="未設定">未設定</option>
          <option value="グロスPP">グロスPP</option>
          <option value="マットPP">マットPP</option>
          <option value="プレスコート">プレスコート</option>
          <option value=" グロスニス">グロスニス</option>
          <option value=" マットニス">マットニス</option>
        </select> -->
      </div>
      <div class="side_input_group">
        <label for="yousi_in">ページ数上限：</label>
        <br />
        <p v-if="inputErrorFlg_maxpage" class="error-message">
          ※{{ this.daiwariData.oriMaximumPage }}以下の偶数値を入力してください
        </p>

        <input
          :disabled="isLockDaiwari"
          type="number"
          class="no-spin"
          @blur="reset_oriMaximumPage"
          :value="set_oriMaximumPage_custom()"
        />
      </div>

      <div class="side_input_group">
        <div class="side_btn_container">
          <button
            @click="addSakuhin"
            :disabled="isLockDaiwari"
            :class="
              isLockDaiwari || !active_sakuhinAddButton
                ? 'disable_button--side'
                : 'add_button'
            "
          >
            <i class="fas fa-minus-circle"></i> 作品追加
          </button>
          <button
            @click="deleteOri"
            :disabled="isLockDaiwari"
            :class="isLockDaiwari ? 'disable_button--side' : 'delete_button'"
          >
            <i class="fas fa-minus-circle"></i> 折削除
          </button>
        </div>
      </div>
    </div>
    <div class="side_extra_area">
      <div class="side_extra_title_area">
        <span class="side_extra_title">備考・進行メモ</span>
        <textarea
          class="side_extra_textarea"
          cols="25"
          rows="3"
          @input="set_oriBikou"
          :value="selectedOri.ori_bikou"
          :disabled="isLockDaiwari"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Notyf } from "notyf";
import { isNumber } from "@/daiwariJS/util";
const notyf = new Notyf();
export default {
  props: {
    selectedOri: Object,
    daiwariData: Object,
    isLockDaiwari: Boolean,
  },
  emits: [
    "inputOriKbn",
    "enableHyosiFlg",
    "inputSikisuu",
    "showModal",
    "removeYoushi",
    "removeKakou",
    "addSakuhin",
    "deleteOri",
    "child-emit",
    "reset_oriMaximumPage",
    "set_oriBikou",
    "allowsToChangeOriInfo",
  ],
  data() {
    return {
      isAllowedToChangeSikisuu: false,
      dividedSakuhinArray_from_selectedOri: [],
      dividedOriArray_from_selectedOri: [], //選択中の分断作品が紐づいている折(未分断作品の場合は空)
      inputErrorFlg_maxpage: false,
      sikisuuuErrorFlg: false,
      oriMaximumPage_custom: null,
    };
  },
  methods: {
    //折区分の入力
    input_oriKbn(event) {
      const inputValue = event.target.value;
      this.$emit("inputOriKbn", inputValue);
      //雑誌以外の作品の折区分が「表紙」「表N」の場合、表紙フラグをオンにする
      let isHyousi = false;
      if (!this.daiwariData.outputSyosiData.isZassi && inputValue === "表紙") {
        //1:折区分「表紙」
        isHyousi = true;
      } else if (
        !this.daiwariData.outputSyosiData.isZassi &&
        inputValue.includes("表")
      ) {
        //2:折区分「表N」(Nの部分が数値であれば表紙と判断)
        //この場合「表1・表2」という区分名であっても表紙と判定される
        const slicedValue = inputValue.slice(1, 2);
        if (isNumber(slicedValue)) {
          isHyousi = true;
        }
      }
      if (isHyousi) {
        this.$emit("enableHyosiFlg", this.selectedOri.ori_address_computed - 1);
      }
    },
    //色数の入力
    input_sikisuu(event) {
      if (isNumber(event.target.value)) {
        this.$emit(
          "inputSikisuu",
          event.target.value,
          this.dividedOriArray_from_selectedOri
        );
      } else {
        event.target.value = Number(this.selectedOri.sikisuu.replace("C", ""));
      }
    },
    // input_kakou(event) {
    //   const newVal = event.target.value;
    //   const oldVal = { ...this.selectedOri }.kakou_array[0].kbn;
    //   this.$emit("inputKakou", newVal, oldVal);
    // },
    //用紙検索モーダル表示
    showModal(modalName) {
      this.$emit("showModal", modalName);
    },
    //ペンネームの取り消し
    removeYoushi() {
      this.$emit("removeYoushi");
    },
    // 加工区分の取り消し
    removeKakou(removeIndex) {
      this.$emit("removeKakou", removeIndex);
    },
    //作品の追加
    addSakuhin() {
      if (this.active_sakuhinAddButton) {
        this.$emit("addSakuhin");
      } else {
        notyf.error("折のページ数が上限に達したため作品を追加できません");
      }
    },
    //折の削除
    deleteOri() {
      this.$emit("deleteOri");
    },
    //折選択時にallowsToChangeSikisuuを発火させる用
    emitEvent() {
      this.$emit("child-emit");
    },
    set_oriMaximumPage_custom() {
      if ("oriMaximumPage_custom" in this.selectedOri) {
        return this.selectedOri.oriMaximumPage_custom;
      } else {
        return this.daiwariData.oriMaximumPage;
      }
    },
    //折の貢数上限設定
    reset_oriMaximumPage(e) {
      this.inputErrorFlg_maxpage = false;
      this.oriMaximumPage_custom = e.target.value;
      const oriMaximumPage_custom = Number(this.oriMaximumPage_custom);
      //16Por32Pを入力した場合プロパティ削除
      if (oriMaximumPage_custom === this.daiwariData.oriMaximumPage) {
        delete this.selectedOri.oriMaximumPage_custom;
        return;
      }
      //バリデーション:16p・32p以下か
      if (
        oriMaximumPage_custom > 0 &&
        oriMaximumPage_custom <= this.daiwariData.oriMaximumPage
      ) {
        //バリデーション:偶数であるか
        if (oriMaximumPage_custom % 2 === 0) {
          this.$emit("reset_oriMaximumPage", oriMaximumPage_custom);
        } else {
          this.inputErrorFlg_maxpage = true;
        }
      } else {
        this.inputErrorFlg_maxpage = true;
      }
    },
    //備考・進行メモの設定
    set_oriBikou(e) {
      const ori_bikou = e.target.value;
      this.$emit("set_oriBikou", ori_bikou);
    },
    //色数の変更を制御
    allowsToChangeSikisuu() {
      this.dividedSakuhinArray_from_selectedOri = [];
      this.dividedOriArray_from_selectedOri = [];

      //selectedOriに分断作品があるか否か
      // trueの場合未分断-falseの場合分断なので注意
      let hasDividedSakuhin_inSelectedOri =
        this.selectedOri.sakuhin_array.every((sakuhin) => {
          return sakuhin.is_divided == false;
        });

      //作品が一つでも入っていたら
      if (this.selectedOri.sakuhin_array.length) {
        this.isAllowedToChangeSikisuu = true;
        //[1]sakuhin_arrayのsakuhinが全て未分断:色数変更OK------------------------------
        if (hasDividedSakuhin_inSelectedOri) {
          this.isAllowedToChangeSikisuu = true;
        }
        //[2]sakuhin_arrayのsakuhinが分断している かつ かたまりのうち最初の折-----------------------------
        // selectedOriより後を見ていき、lastの折が見つかったら処理を終える
        else if (this.judgeOriPosition(this.selectedOri) === "first") {
          // console.log(this.judgeOriPosition(this.selectedOri));
          const startIndex = this.selectedOri.ori_address_computed - 1;
          let loopFlg = true;
          for (let i = startIndex; loopFlg; i++) {
            this.dividedOriArray_from_selectedOri.push(
              this.daiwariData.ori_array[i]
            );
            if (
              this.judgeOriPosition(this.daiwariData.ori_array[i]) === "last"
            ) {
              loopFlg = false;
            }
          }
        }
        //[3]sakuhin_arrayのsakuhinが分断している かつ かたまりのうち最後の折-----------------------------
        // selectedOriより前を見ていき、firstの折が見つかったら処理を終える
        else if (this.judgeOriPosition(this.selectedOri) === "last") {
          // console.log(this.judgeOriPosition(this.selectedOri));
          const startIndex = this.selectedOri.ori_address_computed - 1;
          let loopFlg = true;
          for (let i = startIndex; loopFlg; i--) {
            this.dividedOriArray_from_selectedOri.unshift(
              this.daiwariData.ori_array[i]
            );
            if (
              this.judgeOriPosition(this.daiwariData.ori_array[i]) === "first"
            ) {
              loopFlg = false;
            }
          }
        }
        //[4]sakuhin_arrayのsakuhinが分断している かつ かたまりのうち途中の折-----------------------------
        // selectedOriの前後を見ていき、それぞれfirstとlastの折が見つかったら処理を終える
        else if (this.judgeOriPosition(this.selectedOri) === "middle") {
          // console.log(this.judgeOriPosition(this.selectedOri));
          const startIndex = this.selectedOri.ori_address_computed - 1;
          // selectedOriより前を見ていく
          let firstLoopFlg = true;
          for (let i = startIndex; firstLoopFlg; i--) {
            this.dividedOriArray_from_selectedOri.unshift(
              this.daiwariData.ori_array[i]
            );
            if (
              this.judgeOriPosition(this.daiwariData.ori_array[i]) === "first"
            ) {
              firstLoopFlg = false;
            }
          }
          // selectedOriより後を見ていく
          let lastLoopFlg = true;
          for (let i = startIndex + 1; lastLoopFlg; i++) {
            this.dividedOriArray_from_selectedOri.push(
              this.daiwariData.ori_array[i]
            );
            if (
              this.judgeOriPosition(this.daiwariData.ori_array[i]) === "last"
            ) {
              lastLoopFlg = false;
            }
          }
        }
      } else {
        this.isAllowedToChangeSikisuu = false;
      }

      this.$nextTick(() => {
        // console.log(this.dividedOriArray_from_selectedOri);
        this.$emit(
          "allowsToChangeOriInfo",
          this.dividedOriArray_from_selectedOri
        );
      });
    },
    // 選択された折が分断している場合、ひとかたまりの最初・途中・最後のいずれかを判定する
    judgeOriPosition(ori) {
      let oriPosition = "";

      const oriFirstSakuhin = ori.sakuhin_array[0];
      const oriLastSakuhin = ori.sakuhin_array[ori.sakuhin_array.length - 1];

      if (oriFirstSakuhin.startPage === 0) {
        oriPosition = "first";
      } else if (
        !oriLastSakuhin.is_divided ||
        (oriLastSakuhin.is_divided &&
          oriLastSakuhin.total_page ===
            oriLastSakuhin.divided_page + oriLastSakuhin.startPage)
      ) {
        oriPosition = "last";
      } else if (
        (ori.sakuhin_array.length === 1 &&
          oriFirstSakuhin.is_divided &&
          !oriFirstSakuhin.is_parent) ||
        (oriFirstSakuhin.is_divided &&
          !oriFirstSakuhin.is_parent &&
          oriLastSakuhin.is_divided &&
          oriLastSakuhin.is_parent)
      ) {
        oriPosition = "middle";
      }
      return oriPosition;
    },
  },
  computed: {
    //「作品追加」ボタンの表示フラグ判定
    active_sakuhinAddButton() {
      //折の上限貢数
      let maxPage = this.selectedOri.oriMaximumPage_custom
        ? this.selectedOri.oriMaximumPage_custom
        : this.daiwariData.oriMaximumPage;

      //現在の折貢数
      const sakuhinArray = this.selectedOri.sakuhin_array;
      let oriTotalPage = sakuhinArray.reduce(function (totalPage, sakuhin) {
        return totalPage + sakuhin.sakuhin_address_computed_array.length;
      }, 0);
      if (maxPage <= oriTotalPage) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
#daiwari_edit {
  .error-message {
    color: rgb(255, 0, 0);
    font-size: 11px;
    letter-spacing: -0.5px;
  }
  .disable_button--side {
    margin-right: 2px;
    background-color: rgb(235, 235, 235);
    color: #a0a0a0;
    box-shadow: none;
  }

  .delete_youshi {
    cursor: pointer;
    display: inline-block;
    padding-right: 3px;
  }

  .modal__review__badge-selected {
    margin-top: 5px;
    display: inline-block;
  }

  .modal__review__badge-cancel .no-spin::-webkit-inner-spin-button,
  .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }

  .sikisuu_input {
    width: 30%;
  }
}
</style>
