<script setup lang="ts">
</script>
<template>
  <div class="Errors">
    <div class="Errors_Contents">
      <div class="Errors_Wrapper">
        <font-awesome-icon icon="triangle-exclamation" class="Errors_Icon" />
        <h1 class="Errors_Title">お探しのページを表示できません。</h1>
      </div>
      <p>
        システムエラーが発生しております。<br />
        お手数ですが、サイト管理者にお問い合わせください。
      </p>
      <router-link :to="{ name: 'DashboardPage' }" class="Errors_Link"
        >TOPページへ戻る</router-link
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.Errors {
  position: fixed;
  display: flex;
  justify-content: space-around;
  top: 120px;
  bottom: 0;
  overflow-y: auto;
  padding: 0 20px 20px 30px;
  &_Wrapper {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    height: 40px;
  }
  &_Title {
    font-size: 20px;
    font-weight: bold;
  }
  &_Icon {
    color: #d40000;
    font-size: 40px;
  }
  p {
    padding: 10px 0px;
  }
  &_Link {
    color: blue;
    text-decoration: underline;
  }
}
</style>
