<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">台割複写</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="closeModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <!-- 取引先検索 -->
        <div class="modal__review__search-container">
          <input
            type="text"
            class="text_box"
            v-model="keyword"
            @keydown.enter="search"
          />
          <button class="btn_gray_small" @click="search">検索</button>
        </div>
        <div v-if="isSearched">
          <p class="modal__review__search-result" v-if="!this.searchNullFlg">
            <span v-if="!searchedWord == ''">「{{ searchedWord }}」の</span>
            検索結果({{ totalRecords }}件)
          </p>
          <p class="modal__review__search-result" v-if="this.searchNullFlg">
            <span v-if="!searchedWord == ''">「{{ searchedWord }}」の</span
            >検索結果はありません
          </p>
        </div>
        <div class="modal__message">
          <table class="frame_table">
            <thead>
              <tr>
                <th>タイトル</th>
                <th>登録日時</th>
                <th>操作</th>
              </tr>
            </thead>
            <!-- ロード時または検索ヒットしなかった場合(初期表示) -->
            <tbody>
              <template v-for="(daiwari, index) in daiwari_list" :key="index">
                <tr :class="`bg-red-${index}`">
                  <td>
                    <div
                      :class="{
                        'parent-title': hasShowEdabanList(daiwari),
                      }"
                    >
                      <button
                        v-if="hasShowEdabanList(daiwari)"
                        @click="daiwari.isShowEdaList = !daiwari.isShowEdaList"
                        class="showEda-button"
                      >
                        <span v-if="!daiwari.isShowEdaList">枝表示</span>
                        <span v-else>枝非表示</span>
                      </button>
                      <span v-show="daiwari.hinmokuId">
                        {{ truncateDispName(daiwari.syomei) }}
                      </span>
                      <span v-show="!daiwari.hinmokuId">
                        {{ truncateDispName(daiwari.kikakuMei) }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <p v-show="daiwari.torokuDateStr">
                      {{ daiwari.torokuDateStr }}
                    </p>
                    <p v-show="!daiwari.torokuDateStr">-</p>
                  </td>
                  <td>
                    <button
                      class="btn_syokai"
                      @click="
                        openOverlay(
                          daiwari.daiwariId,
                          daiwari.kikakuId,
                          daiwari.kikakuMei,
                          daiwari.torokuDateStr,
                          false,
                          false,
                          daiwari
                        )
                      "
                    >
                      照会
                    </button>
                  </td>
                </tr>
                <!-- ここから -->
                <template
                  v-for="(childDaiwari, childIndex) in daiwari.childDaiwariList"
                >
                  <tr
                    v-if="!childDaiwari.isDensi && daiwari.isShowEdaList"
                    :key="`${index}-${childIndex}`"
                    class="eda-daiwari"
                    :class="`bg-red-${index}`"
                  >
                    <td>
                      <p>
                        <font-awesome-icon
                          :icon="['fas', 'arrow-circle-right']"
                        />
                        {{ truncateDispName(childDaiwari.childDaiwariName) }}
                      </p>
                    </td>
                    <td>
                      <p v-if="childDaiwari.torokuDateStr !== null">
                        {{ childDaiwari.torokuDateStr }}
                      </p>
                      <p v-else-if="childDaiwari.torokuDate">
                        {{ getEdaRegisterDate(childDaiwari.torokuDate) }}
                      </p>
                      <p v-else>-</p>
                    </td>
                    <td>
                      <button
                        class="btn_syokai"
                        @click="
                          openOverlay(
                            childDaiwari.daiwariId,
                            childDaiwari.kikakuId,
                            childDaiwari.childDaiwariName,
                            childDaiwari.torokuDateStr,
                            true,
                            childDaiwari.isNakatoji,
                            childDaiwari
                          )
                        "
                      >
                        照会
                      </button>
                    </td>
                  </tr>
                </template>
                <!-- ここまで -->
              </template>
            </tbody>
          </table>
        </div>
        <Paginate
          class="pagenation"
          :page-count="this.totalPage"
          :hide-prev-next="true"
          :page-range="5"
          :margin-pages="1"
          :click-handler="getCurrentPage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-item--link'"
          :active-class="'page-item--active'"
        ></Paginate>

        <div class="modal__btn__block">
          <button class="btn_gray" @click="closeModal">戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";

export default {
  props: {
    url: String,
  },
  emits: ["setKariData", "openOverlay", "hideModal", "count_timeOut"],
  components: { Paginate },
  data() {
    return {
      currentPage: 1,
      keyword: "",
      daiwari_list: [], // ← 台割データのリスト
      daiwari_list_searched: [], // ← 検索結果のリスト
      displayLimit: 10, // ← 1画面での表示アイテム件数
      totalPage: 0, // ← 総ページ数
      totalRecords: 0, // ← 総アイテム件数
      isSearched: false,
      searchedWord: "",
      searchNullFlg: false,
    };
  },
  async created() {
    this.getDaiwariOnLoading();
  },
  methods: {
    openOverlay(
      daiwari_id,
      kikaku_id,
      title,
      toroku_date,
      isEdaban,
      isNakatoji,
      copyDaiwariData
    ) {
      //履歴参照をオーバーレイで表示
      const overlayId = daiwari_id;

      const overlayData = {
        copyKikakuId: kikaku_id,
        referenceTitle: title,
        torokuDate: toroku_date,
        isCopyEda: isEdaban,
        isNakatoji: isNakatoji,
      };
      const overlayName = "overlay_daiwariCopyReference";
      // 仮作品・ﾍﾟﾝﾈｰﾑデータの生成
      let kariSakuhinArray = [];
      if (copyDaiwariData.mstKariSakuhinSiyoDaichoList != null) {
        copyDaiwariData.mstKariSakuhinSiyoDaichoList.forEach((sakuhin) => {
          kariSakuhinArray.push(sakuhin.kariSakuhinId);
        });
      }

      let kariPennameArray = [];
      if (copyDaiwariData.mstKariPennameSiyoDaichoList != null) {
        copyDaiwariData.mstKariPennameSiyoDaichoList.forEach((penname) => {
          kariPennameArray.push(penname.kariPennameId);
        });
      }

      this.$emit("setKariData", kariSakuhinArray, kariPennameArray);

      this.$emit("openOverlay", overlayName, overlayId, overlayData);
    },
    closeModal() {
      this.$emit("hideModal", "modal_daiwariCopy");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    // 枝番項目の表示非表示切り替えのためのフラグを付与する
    setShowEdaFlg() {
      this.daiwari_list.forEach((list) => {
        list.isShowEdaList = false;
      });
    },
    hasShowEdabanList(daiwari) {
      let showEdabanCount = 0;
      if (
        daiwari.childDaiwariList !== null &&
        Array.isArray(daiwari.childDaiwariList) &&
        daiwari.childDaiwariList.length !== 0
      ) {
        daiwari.childDaiwariList.forEach((childDaiwari) => {
          if (!childDaiwari.isDensi) {
            showEdabanCount++;
          }
        });
      }
      return showEdabanCount > 0 ? true : false;
    },
    getDaiwariOnLoading() {
      this.currentPage = 1;
      axios
        .get(
          `${this.url}/daiwari/get_daiwari_split?freeword=&page=1&numberOfDisplay=${this.displayLimit}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.daiwari_list = response.data.display;
          this.totalRecords = response.data.size;
          this.totalPage = Math.ceil(response.data.size / this.displayLimit);
          this.setShowEdaFlg();
        })
        .catch((error) => {
          alert("複写データが取得できませんでした\n" + error);
        });
    },
    search() {
      this.currentPage = 1;
      this.searchedWord = this.keyword;
      if (this.keyword === "") {
        this.isSearched = false;
        this.searchNullFlg = false;
        this.daiwari_list_searched = [];
        this.getDaiwariOnLoading();
      } else {
        this.isSearched = true;
        axios
          .get(
            `${this.url}/daiwari/get_daiwari_split?freeword=${this.keyword}&page=1&numberOfDisplay=${this.displayLimit}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            //検索結果がある場合
            if (response.data.display.length) {
              this.searchNullFlg = false;
              this.daiwari_list = response.data.display;
              this.totalRecords = response.data.size;
              this.totalPage = Math.ceil(
                response.data.size / this.displayLimit
              );
              // this.totalPage = this.totalRecords / this.displayLimit;
              this.setShowEdaFlg();
            } else {
              //検索結果無い場合前回の検索結果をリセット
              this.searchNullFlg = true;
              this.getDaiwariOnLoading();
            }
          })
          .catch(() => {
            this.daiwari_list_searched = [];
          });
      }
    },
    getPageInfo() {
      if (this.searchNullFlg) {
        this.keyword = "";
      }
      axios
        .get(
          `${this.url}/daiwari/get_daiwari_split?freeword=${this.keyword}&page=${this.currentPage}&numberOfDisplay=${this.displayLimit}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.daiwari_list = response.data.display;
          this.totalRecords = response.data.size;
          this.totalPage = Math.ceil(response.data.size / this.displayLimit);
          // this.totalPage = this.totalRecords / this.displayLimit;
          this.setShowEdaFlg();
        })
        .catch((error) => {
          alert("複写データが取得できませんでした\n" + error);
        });
    },
    getCurrentPage(currentPage) {
      this.currentPage = currentPage;
      // 新しい複写情報呼び出し;
      this.getPageInfo();
    },
    getEdaRegisterDate(date) {
      const targetDate = new Date(date);
      const day =
        targetDate.getUTCFullYear() +
        "-" +
        targetDate.getMonth() +
        "-" +
        targetDate.getDate();
      const time =
        targetDate.getHours() +
        ":" +
        targetDate.getMinutes() +
        ":" +
        targetDate.getSeconds();
      return day + " " + time;
    },
    truncateDispName(value) {
      const ommision = "...";
      let length = 38;
      // valueが数値の場合がある為、文字列に変換
      value = "" + value;
      if (value.length <= length) {
        return value;
      }
      return value.substring(0, length) + ommision;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 700px;
    height: 660px;
    &__message {
      font-size: 16px;
      min-height: 330px;
      max-height: 360px;
      overflow: auto;
    }
  }

  .btn_gray {
    width: 100px;
    height: 40px;
    padding: 10px;
  }

  .frame_table {
    max-width: 90%;
    margin: 0 auto;
  }

  .frame_table th,
  .frame_table td {
    vertical-align: middle;
  }

  .frame_table thead th {
    min-width: 165px;
  }

  .btn_syokai {
    margin-right: 5px;
  }
  .parent-title {
    display: flex;
    align-items: center;
  }
  .showEda-button {
    min-width: 60px;
    margin-right: 5px;
  }
  .eda-daiwari > td:nth-child(1) {
    text-align: left;
    padding-left: 20px;
  }
  @for$i from 0 through 10 {
    @if $i % 2 == 0 {
      .bg-red-#{$i} {
        background: #fff !important;
      }
    } @else {
      .bg-red-#{$i} {
        background: #f7f7f7 !important;
      }
    }
  }
}
</style>
