<template>
  <div class="content_container" @click="hide_acordion">
    <table class="frame_table">
      <tr>
        <th>
          <!-- v-ifグループ① -->
          <span
            class="badge badge_red"
            v-if="daiwariHeader.daiwariKakuteiLatestVersion !== null"
            >台割確定済み</span
          >
          <!-- v-ifグループ② -->
          <span class="badge badge_green" v-if="daiwariHeader.isDaichoKakutei"
            >台帳確定済み</span
          >
          <span
            class="badge badge_dark_red"
            v-else-if="daiwariHeader.isKikakuCancel"
            >企画中止</span
          >
          <span class="badge badge_purple" v-else-if="daiwariHeader.isWfLock"
            >WF申請中</span
          >
          <span class="badge badge_blue" v-else-if="isLockDaiwari"
            >読み取り専用</span
          >
          <!-- v-showグループ -->
          <span class="badge badge_gray" v-show="daiwariHeader.isGeneratedSyosi"
            >書誌を参照</span
          >
          <span
            class="badge badge_gray"
            v-show="!daiwariHeader.isGeneratedSyosi"
            >企画を参照</span
          >
          <span class="badge badge_yellow" v-show="daiwariHeader.isDensi"
            >電子版</span
          >
          タイトル
        </th>
        <th>搬入日</th>
        <!-- 価格 -->
        <!-- <th v-if="calcInzeiData.jissaiKakaku">本体価格</th>
        <th v-else-if="calcInzeiData.yoteiKakaku">予価</th>
        <th v-else>価格</th> -->
        <!-- 部数 -->
        <!-- <th v-if="calcInzeiData.jissaiBusu">製造部数</th>
        <th v-else-if="calcInzeiData.yoteiBusu">想定部数</th>
        <th v-else>部数</th> -->
        <th>判型</th>
      </tr>
      <tr>
        <td>
          {{ daiwariHeader.title }}
          <span v-show="daiwariHeader.isChild" class="badge badge_red">{{
            daiwariHeader.childDaiwariName
          }}</span>
        </td>
        <td>{{ daiwariHeader.hannyuDate }}</td>
        <!-- 価格 -->
        <!-- <td v-if="calcInzeiData.jissaiKakaku">
          {{ calcInzeiData.jissaiKakaku.toLocaleString() }}
        </td>
        <td v-else-if="calcInzeiData.yoteiKakaku">
          {{ calcInzeiData.yoteiKakaku.toLocaleString() }}
        </td>
        <td v-else></td> -->
        <!-- 部数 -->
        <!-- <td v-if="calcInzeiData.jissaiBusu">
          {{ calcInzeiData.jissaiBusu.toLocaleString() }}
        </td>
        <td v-else-if="calcInzeiData.yoteiBusu">
          {{ calcInzeiData.yoteiBusu.toLocaleString() }}
        </td>
        <td v-else></td> -->
        <td>{{ daiwariHeader.hankei }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    daiwariHeader: Object,
    isLockDaiwari: Boolean,
    calcInzeiData: Object,
  },
  emits: ["hide_acordion"],
  //本体価格を3桁カンマ区切りに直す
  computed: {
    hontaiKakaku() {
      let hontaiKakaku = this.daiwariHeader.hontaiKakaku;
      if (hontaiKakaku) {
        hontaiKakaku = Number(hontaiKakaku).toLocaleString();
      }
      return hontaiKakaku;
    },
  },
  methods: {
    hide_acordion() {
      this.$emit("hide_acordion");
    },
  },
  watch: {
    calcInzeiData: function () {
      console.log(this.calcInzeiData);
    },
  },
};
</script>

<style scoped lang="scss">
#daiwari_edit {
  .frame_table {
    border-collapse: collapse;
    width: 100%;
    height: 70px;
  }

  .frame_table th,
  td {
    vertical-align: middle;
    border: solid 1px #dddddd;
    border-left: 0;
    text-align: center;
  }

  /* バッジ */
  .badge {
    cursor: default;
    padding: 2px 6px 2px 6px;
    border: none;
    border-radius: 20px;
  }
  .badge_gray {
    background-color: rgb(235, 235, 235);
    color: rgb(0, 0, 0);
    font-weight: normal;

    //台割ヘッダ用
    margin-right: 10px;
  }
  .badge_green {
    background-color: rgb(19, 213, 113);
    color: rgb(0, 0, 0);
    font-weight: normal;

    //台割ヘッダ用
    margin-right: 5px;
  }
  .badge_blue {
    cursor: default;
    background-color: rgb(89, 205, 251);
    color: rgb(0, 0, 0);
    font-weight: normal;

    //台割ヘッダ用
    margin-right: 5px;
  }

  .badge_red {
    background-color: rgb(255, 228, 228);
    color: rgb(0, 0, 0);

    //台割ヘッダ用
    font-weight: normal;
    margin-right: 5px;
  }

  .badge_dark_red {
    background-color: rgb(195, 0, 0);
    color: #fff;

    //台割ヘッダ用
    font-weight: normal;
  }

  .badge_purple {
    background-color: rgb(137, 0, 192);
    color: #fff;

    //台割ヘッダ用
    font-weight: normal;
  }

  .badge_yellow {
    background-color: #ffffaa;

    //台割ヘッダ用
    font-weight: normal;
  }
}
</style>
