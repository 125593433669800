<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title"></p>
      </div>
      <div class="modal__review">
        <span>
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="icon__save__filed"
          />
        </span>
        <div class="modal__message">
          <p class="modal__message__top">【タイムアウトエラー】</p>
          <p>一定期間操作が無かったためタイムアウトになりました。</p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_gray" @click="hideModal()">台割一覧に戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["hideModal"],
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_timeout");
    },
    stopEvent(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay {
    z-index: 10000;
  }
  .modal {
    width: 50%;
    height: 50%;
    &__message {
      font-size: 18px;
      &__top {
        font-weight: 600;
        text-align: center;
      }
    }
    &__header {
      height: 5%;
      background-color: #fff;
    }
    &__close {
      color: #000;
    }
  }

  .icon__save__filed {
    color: rgb(212, 0, 0);
    width: 100px;
    height: 100px;
  }

  .btn_gray {
    width: 170px;
    height: 40px;
    padding: 10px;
  }
}
</style>
