<template>
  <div :id="sheet" class="DaiwariFormattingLeft">
    <NakatojiHyosi
      v-if="this.nakatojiHyosiArray.length"
      v-model:nakatojiHyosiArray="nakatojiHyosiArray"
      :oriMaximumPage="oriMaximumPage"
      :props_daiwariData="props_daiwariData"
      :page_range_bikou_array="page_range_bikou_array"
      :isCompact="isCompact"
      :props_selectBarList="props_selectBarList"
      :isOnlyDaiwari="isOnlyDaiwari"
      :isCustom="isCustom"
      :customOutputList="customOutputList"
      @format_detailOriArray="format_detailOriArray"
      @format_detailSakuhinArray="format_detailSakuhinArray"
    />
    <div
      v-for="(ori, oriIndex) in nakatojiOriArray"
      :key="'ori_' + oriIndex"
      class="nakatoji_line"
    >
      <div class="nakatoji_sakuhin_left">
        <!-- 左辺 -->
        <div class="flex_nakatoji_sahen">
          <template
            v-for="(
              sahenAddress, sahenIndex
            ) in ori.sakuhin_address_computed_array_sahen"
          >
            <div
              class="nakatoji_address_sahen"
              v-if="!props_daiwariData.includesPages_doNotCount"
              :key="'sahen_address' + sahenIndex"
              :style="{ '--half': oriMaximumPage / 2 }"
              :class="[
                props_selectBarList[1].selected &&
                needsHighLight(ori.bikou_array_sahen, sahenIndex)
                  ? 'active_bikou'
                  : '',
                props_selectBarList[2].selected
                  ? color(ori.sakuhin_index_computed_array_sahen[sahenIndex])
                  : '',
              ]"
            >
              {{ sahenAddress }}
            </div>
            <div
              class="nakatoji_address_sahen"
              v-if="props_daiwariData.includesPages_doNotCount"
              :key="'sahen_address' + sahenIndex"
              :style="{ '--half': oriMaximumPage / 2 }"
              :class="[
                props_selectBarList[1].selected &&
                needsHighLight(ori.bikou_array_sahen, sahenIndex)
                  ? 'active_bikou'
                  : '',
                props_selectBarList[2].selected
                  ? color(ori.sakuhin_index_computed_array_sahen[sahenIndex])
                  : '',
              ]"
            >
              <span v-show="sahenAddress === 0" class="sakuhin_address_blanc"
                >.</span
              >
              <span v-show="sahenAddress !== 0">{{ sahenAddress }}</span>
            </div>
          </template>
        </div>
        <div class="flex_nakatoji_sahen">
          <div
            v-for="(sahen, sahenIndex) in ori.sahenArray"
            :key="'sahen_' + sahenIndex"
            class="sahen_sakuhin nakatoji_sakuhin"
            :class="[
              props_selectBarList[0].selected
                ? sakuhinSinkouStatus(sahen.sakuhinInfo.sinkou_status)
                : '',
              { nakatoji_sakuhin_onlydaiwari: isCompact || isOnlyDaiwari },
            ]"
            :style="{
              '--half': oriMaximumPage / 2,
              '--width': sahen.sakuhinInfo.sakuhinCount,
              height: settingOriHeight,
            }"
          >
            <span class="sakuhin_mei" ref="sahen_sakuhin_mei">
              {{ sakuhinDisplayName(sahen.sakuhinInfo) }}
            </span>
            <span
              v-if="
                !isCompact &&
                !isOnlyDaiwari &&
                !(isCustom && !customOutputList.tantou.isSelected)
              "
              class="tantou_mei"
              ref="sahen_tantou"
            >
              <span
                v-for="(tantou, index) in sahen.sakuhinInfo.tantou"
                :key="index + '_sahen'"
              >
                {{ tantou.user_last_name }}{{ tantou.user_first_name }}
                <span
                  v-show="
                    sahen.sakuhinInfo.tantou.length === 0 ||
                    index !== sahen.sakuhinInfo.tantou.length - 1
                  "
                  >,</span
                >
              </span>
            </span>
            <span
              v-if="!(isCustom && !customOutputList.sakuhinBiko.isSelected)"
              class="bikou_sakuhin"
              ref="sahen_bikou_sakuhin"
              >{{ sahen.sakuhinInfo.sakuhin_bikou }}</span
            >
            <!-- 左辺ページ数 -->
            <span class="bikou_sakuhin"
              ><span
                v-if="
                  sahen.sakuhinInfo.sakuhinCount !== 0 &&
                  sahen.sakuhinInfo.sakuhinCount !==
                    sahen.sakuhinInfo.total_page
                "
                >{{ sahen.sakuhinInfo.sakuhinCount }}/</span
              >{{ sahen.sakuhinInfo.total_page }}p</span
            >
          </div>
        </div>
      </div>

      <!-- 折 -->
      <div class="nakatoji_ori_container">
        <p>
          {{ ori.ori_kbn }}
          <span
            v-if="
              !isOnlyDaiwari &&
              !(isCustom && !customOutputList.irokazu.isSelected)
            "
            >/{{ ori.sikisuu }}</span
          >
          <span v-if="!isOnlyDaiwari"
            >/{{
              ori.sakuhin_address_computed_array_uhen.length +
              ori.sakuhin_address_computed_array_sahen.length
            }}P</span
          >
        </p>
        <p
          v-if="
            !isCompact &&
            !isOnlyDaiwari &&
            !(isCustom && !customOutputList.yousi.isSelected)
          "
        >
          {{ hankaku(ori.yousi) }}
        </p>
        <p
          v-if="
            ori.kakou_array.length &&
            !isCompact &&
            !isOnlyDaiwari &&
            !(isCustom && !customOutputList.kakou.isSelected)
          "
        >
          <span v-for="(kakou, index) in ori.kakou_array" :key="index">
            {{ hankaku(kakou.kbn) }}
            <span
              v-show="
                ori.kakou_array.length === 0 ||
                index !== ori.kakou_array.length - 1
              "
              >,</span
            >
          </span>
        </p>
        <p :class="'ref_' + ori.ori_kbn" class="bikou_ori" ref="ref_ori_bikou">
          {{ ori.ori_bikou }}
        </p>
      </div>

      <!-- 右辺 -->
      <div class="nakatoji_sakuhin_right">
        <div class="flex_nakatoji_uhen">
          <template
            v-for="(
              uhenAddress, uhenIndex
            ) in ori.sakuhin_address_computed_array_uhen"
          >
            <div
              class="nakatoji_address_uhen"
              v-if="!props_daiwariData.includesPages_doNotCount"
              :key="'uhen_address' + uhenIndex"
              :style="{ '--half': oriMaximumPage / 2 }"
              :class="[
                props_selectBarList[1].selected &&
                needsHighLight(ori.bikou_array_uhen, uhenIndex)
                  ? 'active_bikou'
                  : '',
                props_selectBarList[2].selected
                  ? color(ori.sakuhin_index_computed_array_uhen[uhenIndex])
                  : '',
              ]"
            >
              {{ uhenAddress }}
            </div>
            <div
              class="nakatoji_address_uhen"
              v-if="props_daiwariData.includesPages_doNotCount"
              :key="'uhen_address' + uhenIndex"
              :style="{ '--half': oriMaximumPage / 2 }"
              :class="[
                props_selectBarList[1].selected &&
                needsHighLight(ori.bikou_array_uhen, uhenIndex)
                  ? 'active_bikou'
                  : '',
                props_selectBarList[2].selected
                  ? color(ori.sakuhin_index_computed_array_uhen[uhenIndex])
                  : '',
              ]"
            >
              <span v-show="uhenAddress === 0" class="sakuhin_address_blanc"
                >.</span
              >
              <span v-show="uhenAddress !== 0">{{ uhenAddress }}</span>
            </div>
          </template>
        </div>
        <div class="flex_nakatoji_uhen">
          <div
            v-for="(uhen, uhenIndex) in ori.uhenArray"
            :key="'uhen_' + uhenIndex"
            class="uhen_sakuhin nakatoji_sakuhin"
            :class="[
              props_selectBarList[0].selected
                ? sakuhinSinkouStatus(uhen.sakuhinInfo.sinkou_status)
                : '',
              { nakatoji_sakuhin_onlydaiwari: isCompact || isOnlyDaiwari },
            ]"
            :style="{
              '--half': oriMaximumPage / 2,
              '--width': uhen.sakuhinInfo.sakuhinCount,
              height: settingOriHeight,
            }"
          >
            <span class="sakuhin_mei" ref="uhen_sakuhin_mei">{{
              sakuhinDisplayName(uhen.sakuhinInfo)
            }}</span>
            <span
              v-if="
                !isCompact &&
                !isOnlyDaiwari &&
                !(isCustom && !customOutputList.tantou.isSelected)
              "
              class="tantou_mei"
              ref="uhen_tantou"
            >
              <span
                v-for="(tantou, index) in uhen.sakuhinInfo.tantou"
                :key="index + '_uhen'"
              >
                {{ tantou.user_last_name }}{{ tantou.user_first_name }}
                <span
                  v-show="
                    uhen.sakuhinInfo.tantou.length === 0 ||
                    index !== uhen.sakuhinInfo.tantou.length - 1
                  "
                  >,</span
                >
              </span>
            </span>
            <span
              v-if="!(isCustom && !customOutputList.sakuhinBiko.isSelected)"
              class="bikou_sakuhin"
              ref="uhen_bikou_sakuhin"
              >{{ uhen.sakuhinInfo.sakuhin_bikou }}</span
            >

            <!-- 右辺ページ数 -->
            <span class="bikou_sakuhin"
              ><span
                v-if="
                  uhen.sakuhinInfo.sakuhinCount !== 0 &&
                  uhen.sakuhinInfo.sakuhinCount !== uhen.sakuhinInfo.total_page
                "
                >{{ uhen.sakuhinInfo.sakuhinCount }}/</span
              >{{ uhen.sakuhinInfo.total_page }}p</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rebuildNakatojiData } from "@/daiwariJS/Nakatoji.js";
import { is_matched_sakuhinId } from "@/daiwariJS/util";
import { zenkakuToHankaku } from "@/daiwariJS/StringConvertion.js";
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";
import NakatojiHyosi from "./NakatojiHyosi.vue";

export default {
  name: "DaiwariFormattingNakatoji",
  components: {
    NakatojiHyosi,
  },
  props: {
    props_daiwariData: Object,
    oriMaximumPage: Number,
    printSize: String,
    page_range_bikou_array: Array,
    isCompact: Boolean,
    props_selectBarList: Array,
    isOnlyDaiwari: Boolean,
    isCustom: Boolean,
    customOutputList: Object,
  },
  emits: ["updateDetailOriArray", "updateDetailSakuhinArray"],
  setup() {
    return {
      nakatojiHyosiArray: [], //表紙用の折配列
    };
  },
  data() {
    return {
      tantou_list: [{ user_last_name: "担当" }, { user_last_name: "名前" }],
      detailOriArray: [],
      detailSakuhinArray: [],
      isNakatojiFlg: true, //強制的に中綴じレイアウト
      nakatojiOriArray: [], //中綴じ用の折配列
      nakatojiOriArray_honbun: [],
      nakatojiOriArray_hyousi: [],
      joinedOriArray: [],
      joined_address_index_array: [], //ページ範囲設定のハイライト用
    };
  },
  created() {
    this.format_daiwariData_nakatoji();
    this.format_detailOriArray();
    this.format_detailSakuhinArray();
  },
  methods: {
    format_daiwariData_nakatoji() {
      //以下中綴じレイアウト
      this.nakatojiHyosiArray = [];
      this.nakatojiOriArray = [];
      let totalSakuhinPage = 0;
      let halfSakuhinPage = 0;

      //表紙と表紙以外の配列を分ける
      this.props_daiwariData.ori_array.forEach((ori) => {
        if (ori.ori_kbn !== "表紙") {
          this.nakatojiOriArray.push({ ...ori });
        } else if (ori.ori_kbn == "表紙") {
          this.nakatojiHyosiArray.push({ ...ori });
        }
      });

      //表紙以外：中綴じ用にフォーマットした、空の折配列(&作品配列)を作成する
      this.nakatojiOriArray.forEach((ori, oriIndex) => {
        ori.uhenArray = [];
        ori.sahenArray = [];
        totalSakuhinPage = 0; //折に含まれる作品の総ページ数
        ori.sakuhin_array.forEach((sakuhin) => {
          if (sakuhin.is_divided) {
            totalSakuhinPage += sakuhin.divided_page;
          } else {
            totalSakuhinPage += sakuhin.total_page;
          }
        });
        halfSakuhinPage = Number(totalSakuhinPage) / 2; //左右に振り分ける
        if (Number.isInteger(halfSakuhinPage)) {
          //折ページをきっかり半分に出来た場合
          for (let i = 0; i < halfSakuhinPage; i++) {
            this.nakatojiOriArray[oriIndex].sahenArray.push({
              containFlg: true,
            });
            this.nakatojiOriArray[oriIndex].uhenArray.push({
              containFlg: true,
            });
          }
        } else {
          //折ページをきっかり半分に出来なかった場合、左辺の貢数を繰り上げる
          for (let i = 0; i < Math.floor(halfSakuhinPage) + 1; i++) {
            this.nakatojiOriArray[oriIndex].sahenArray.push({
              containFlg: true,
            });
          }
          for (let i = 0; i < Math.floor(halfSakuhinPage); i++) {
            this.nakatojiOriArray[oriIndex].uhenArray.push({
              containFlg: true,
            });
          }
        }

        this.nakatojiOriArray[oriIndex].sahenArray = [
          ...this.nakatojiOriArray[oriIndex].sahenArray.reverse(),
        ];
      });

      //表紙：中綴じ用にフォーマットした、折配列(&作品配列)を作成する
      // if (this.nakatojiOriArray.length) {
      this.nakatojiHyosiArray.forEach((ori) => {
        ori.uhenArray = [];
        ori.sahenArray = [];
        ori.sakuhin_array.forEach((sakuhin) => {
          if (ori.ori_address_computed == 1) {
            this.nakatojiHyosiArray[0].sahenArray.push({
              sakuhinInfo: sakuhin,
              ori_id: ori.ori_id,
              position: "sahen",
              ori_kbn: ori.ori_kbn,
            });
          } else if (
            ori.ori_address_computed == this.props_daiwariData.ori_array.length
          ) {
            this.nakatojiHyosiArray[0].uhenArray.push({
              sakuhinInfo: sakuhin,
              ori_id: ori.ori_id,
              position: "uhen",
              ori_kbn: ori.ori_kbn,
            });
          }
        });
        this.nakatojiHyosiArray[0].sahenArray = [
          ...this.nakatojiHyosiArray[0].sahenArray.reverse(),
        ];
      });
      this.nakatojiHyosiArray.splice(1, 1); //表紙折は先頭折のみ残す(先頭折以外使用しないため)
      // }
      this.setSakuhinInfo();
    },
    setSakuhinInfo() {
      //空の作品オブジェクトに作品データを反映
      let allSakuhinArray = [];
      this.props_daiwariData.ori_array.forEach((ori) => {
        if (ori.ori_kbn !== "表紙") {
          allSakuhinArray = [...allSakuhinArray, ...ori.sakuhin_array];
        }
      });
      let address = 0; //参照中のアドレス
      //表紙の貢数分addressを更新
      if (this.nakatojiHyosiArray.length) {
        this.nakatojiHyosiArray[0].sahenArray.forEach((sakuhin) => {
          address += sakuhin.sakuhinInfo.total_page;
        });
      }
      //左辺の作品データ設定---------------------------------
      this.nakatojiOriArray.forEach((ori) => {
        ori.sahenArray.forEach((sakuhinContainer) => {
          //作品を格納
          address++;
          sakuhinContainer.address = address;

          //アドレスに一致する作品をpush
          allSakuhinArray.forEach((sakuhin) => {
            let isTargetSakuhin = false;
            sakuhin.sakuhin_address_computed_array.forEach((sakuhinAddress) => {
              if (sakuhinAddress === address) {
                isTargetSakuhin = true;
              }
            });
            if (isTargetSakuhin) {
              sakuhinContainer.sakuhinInfo = { ...sakuhin };
            }
          });
        });
      });
      //右辺の作品データ設定(※下から格納するため上下反転させる)--------
      this.nakatojiOriArray
        .slice()
        .reverse()
        .forEach((ori) => {
          ori.uhenArray.forEach((sakuhinContainer) => {
            //作品を格納
            address++;
            sakuhinContainer.address = address;

            //アドレスに一致する作品をpush
            allSakuhinArray.forEach((sakuhin) => {
              let isTargetSakuhin = false;
              sakuhin.sakuhin_address_computed_array.forEach(
                (sakuhinAddress) => {
                  if (sakuhinAddress === address) {
                    isTargetSakuhin = true;
                  }
                }
              );
              if (isTargetSakuhin) {
                sakuhinContainer.sakuhinInfo = { ...sakuhin };
              }
            });
          });
        });
      rebuildNakatojiData(
        this.nakatojiOriArray,
        this.nakatojiHyosiArray,
        this.props_daiwariData.includesPages_doNotCount
      );
      this.set_PageBikouInfo();
    },
    set_PageBikouInfo() {
      const sakuhin_page_bikou_array = JSON.parse(
        JSON.stringify(this.props_daiwariData.sakuhin_page_bikou_array)
      );
      this.nakatojiOriArray.forEach((ori) => {
        let bikou_array_sahen = [];
        let bikou_array_uhen = [];
        //左辺
        ori.sahenArray.forEach((sahen) => {
          //備考情報を割り当てる
          const targetBikou = sakuhin_page_bikou_array.find(
            (bikouSakuhin) =>
              bikouSakuhin.sakuhin_bikouId === sahen.sakuhinInfo.sakuhin_bikouId
          );

          //中綴じフォーマット後のページ数に合わせて備考情報を切りとる
          const startPage =
            sahen.sakuhinInfo.endPage +
            1 -
            sahen.sakuhinInfo.sakuhin_address_computed_array.length;
          const numberOfCutPage = sahen.sakuhinInfo.endPage + 1;
          const slicedTargetBikou = targetBikou.bikou_array.slice(
            startPage,
            numberOfCutPage
          );
          sahen.sakuhinInfo.bikou_array = slicedTargetBikou;
          bikou_array_sahen = [...bikou_array_sahen, ...slicedTargetBikou];
        });

        //右辺
        ori.uhenArray.forEach((uhen) => {
          //備考情報を割り当てる
          const targetBikou = sakuhin_page_bikou_array.find(
            (bikouSakuhin) =>
              bikouSakuhin.sakuhin_bikouId === uhen.sakuhinInfo.sakuhin_bikouId
          );

          //中綴じフォーマット後のページ数に合わせて備考情報を切りとる
          const startPage =
            uhen.sakuhinInfo.endPage +
            1 -
            uhen.sakuhinInfo.sakuhin_address_computed_array.length;
          const numberOfCutPage = uhen.sakuhinInfo.endPage + 1;
          const slicedTargetBikou = targetBikou.bikou_array.slice(
            startPage,
            numberOfCutPage
          );

          uhen.sakuhinInfo.bikou_array = slicedTargetBikou;
          bikou_array_uhen = [...bikou_array_uhen, ...slicedTargetBikou];
        });

        ori.bikou_array_sahen = bikou_array_sahen;
        ori.bikou_array_uhen = bikou_array_uhen;
      });
    },
    format_detailOriArray(hyosi_flg = false) {
      this.detailOriArray = [];
      //折省略テーブル用の配列を生成
      this.$nextTick(() => {
        //lines配列にある全ての折を取り出し1つの配列に格納する
        this.joinedOriArray = [];
        this.props_daiwariData.ori_array.forEach((ori) => {
          this.joinedOriArray.push(ori);
        });
        if (hyosi_flg) {
          //表示折で省略が発生したらtrue
          this.detailOriArray.push(this.joinedOriArray[0]);
        }
        this.$refs.ref_ori_bikou.forEach((refBikou, refBikouIndex) => {
          if (refBikou.scrollHeight > refBikou.offsetHeight) {
            this.detailOriArray.push(this.joinedOriArray[refBikouIndex + 1]);
          }
        });
        this.$emit("updateDetailOriArray", this.detailOriArray);
      });
    },
    merged_array(parent_array, child_array) {
      parent_array = [...parent_array, ...child_array];
      return parent_array;
    },
    format_detailSakuhinArray(hyosiRefArray = null) {
      this.$nextTick(() => {
        //作品を取り出し1つの配列に格納する
        let nakatojiSakuhinArray = [];
        let ref = this.$refs;
        //表紙の左辺
        this.nakatojiHyosiArray[0].sahenArray.forEach((sakuhin) => {
          nakatojiSakuhinArray.push(sakuhin.sakuhinInfo);
        });
        //作品の左辺
        this.nakatojiOriArray.forEach((ori) => {
          ori.sahenArray.forEach((sakuhin) => {
            nakatojiSakuhinArray.push(sakuhin.sakuhinInfo);
          });
        });
        //作品の右辺
        this.nakatojiOriArray
          .slice()
          .reverse()
          .forEach((ori) => {
            ori.uhenArray.forEach((sakuhin) => {
              nakatojiSakuhinArray.push(sakuhin.sakuhinInfo);
            });
          });
        //表紙の右辺
        this.nakatojiHyosiArray[0].uhenArray.forEach((sakuhin) => {
          nakatojiSakuhinArray.push(sakuhin.sakuhinInfo);
        });

        if (hyosiRefArray !== null) {
          //表紙の左辺のあとに作品の左辺を格納
          hyosiRefArray.sakuhin_mei = [
            ...hyosiRefArray.sakuhin_mei,
            ...ref.sahen_sakuhin_mei,
          ];
          hyosiRefArray.tantou = [...hyosiRefArray.tantou, ...ref.sahen_tantou];
          hyosiRefArray.bikou_sakuhin = [
            ...hyosiRefArray.bikou_sakuhin,
            ...ref.sahen_bikou_sakuhin,
          ];

          //作品の右辺のあとに表紙の右辺を格納
          ref.uhen_sakuhin_mei = [
            ...ref.uhen_sakuhin_mei.reverse(),
            ...hyosiRefArray.uhen_sakuhin_mei,
          ];

          ref.uhen_tantou = [
            ...ref.uhen_tantou.reverse(),
            ...hyosiRefArray.uhen_tantou,
          ];
          ref.uhen_bikou_sakuhin = [
            ...ref.uhen_bikou_sakuhin.reverse(),
            ...hyosiRefArray.uhen_bikou_sakuhin,
          ];

          //左辺右辺を結合
          ref.sakuhin_mei = [
            ...hyosiRefArray.sakuhin_mei,
            ...ref.uhen_sakuhin_mei,
          ];
          ref.tantou = [...hyosiRefArray.tantou, ...ref.uhen_tantou];
          ref.bikou_sakuhin = [
            ...hyosiRefArray.bikou_sakuhin,
            ...ref.uhen_bikou_sakuhin,
          ];
        }
        this.pick_detailSakuhin(nakatojiSakuhinArray, ref.sakuhin_mei);
        this.pick_detailSakuhin(nakatojiSakuhinArray, ref.bikou_sakuhin);
        this.$emit("updateDetailSakuhinArray", this.detailSakuhinArray);
      });
    },
    pick_detailSakuhin(joinedSakuhinArray, refsArray) {
      //省略対象の作品(作品,担当者,ペンネーム,作品備考)を作品省略テーブルに切り分ける
      if (refsArray) {
        refsArray.forEach((ref, refIndex) => {
          if (ref.scrollHeight > ref.offsetHeight) {
            const hasDuplicatedSakuhin = this.detailSakuhinArray.some(
              (sakuhin) =>
                is_matched_sakuhinId(sakuhin, joinedSakuhinArray[refIndex])
            );
            if (!hasDuplicatedSakuhin) {
              //出力用に整形前の台割作品を全て取得する(対象ページを参照するため)
              let sakuhinArray = [];
              this.props_daiwariData.ori_array.forEach((ori) => {
                sakuhinArray = [...sakuhinArray, ...ori.sakuhin_array];
              });
              const target_sameSakuhinArray = sakuhinArray.filter((sakuhin) =>
                is_matched_sakuhinId(sakuhin, joinedSakuhinArray[refIndex])
              );
              const lastSakuhin =
                target_sameSakuhinArray[target_sameSakuhinArray.length - 1];
              //作品のページ範囲(開始ページ,終了ページ)の算出
              const startPage = joinedSakuhinArray[refIndex]
                .sakuhin_address_computed_array_withFlg.length
                ? joinedSakuhinArray[refIndex]
                    .sakuhin_address_computed_array_withFlg[0]
                : "未設定";
              const endPage = lastSakuhin.sakuhin_address_computed_array_withFlg
                .length
                ? lastSakuhin.sakuhin_address_computed_array_withFlg[
                    lastSakuhin.sakuhin_address_computed_array_withFlg.length -
                      1
                  ]
                : "未設定";
              this.detailSakuhinArray.push({
                ...joinedSakuhinArray[refIndex],
                startPage: startPage,
                endPage: endPage,
              });
            }
          }
        });
      }
    },
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
    hankaku() {
      return (character) => {
        const character_hankaku = zenkakuToHankaku(character);
        return character_hankaku;
      };
    },
    sakuhinSinkouStatus() {
      //進行ステータスに合わせた色付け
      return (status) => {
        if (status == "Ⅰ.企画立案") {
          return "st_kikaku";
        } else if (status == "Ⅱ.原稿制作") {
          return "st_genkou";
        } else if (status == "Ⅲ.組版・製版") {
          return "st_kumihan";
        } else if (status == "Ⅳ.校了") {
          return "st_kouryo";
        } else if (status == "Ⅴ.印刷") {
          return "st_insatu";
        } else if (status == "Ⅵ.原価登録") {
          return "st_genka";
        }
      };
    },
    needsHighLight() {
      return (bikou_array, addressIndex) => {
        if (bikou_array && bikou_array[addressIndex]) {
          if (bikou_array[addressIndex].isHighlighted) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    pageRangeBikouArray() {
      return this.page_range_bikou_array ? this.page_range_bikou_array : [];
    },
    color() {
      return (addressIndex) => {
        let color = "";
        const targetIndex = addressIndex;
        if (this.pageRangeBikouArray.length === 0) {
          return;
        }
        this.pageRangeBikouArray.forEach((bikou) => {
          if (bikou.addressIndexArray.includes(targetIndex)) {
            color = `bg_${bikou.color}`;
          }
        });
        return color;
      };
    },
    sakuhinDisplayName() {
      return (sakuhin) => {
        return convertSakuhinMeiFull(sakuhin);
      };
    },
    // カスタム時の折の高さ設定
    settingOriHeight() {
      if (this.isCustom) {
        let height = 75;
        if (!this.customOutputList.tantou.isSelected) {
          height -= 15;
        }
        if (!this.customOutputList.sakuhinBiko.isSelected) {
          height -= 15;
        }
        return height + "px";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  :root {
    --page: 0;
    --maxpage: 0;
    --width: 0;
    --half: 0;
  }
  .DaiwariFormattingLeft {
    margin-top: 20px;
  }
  //A4サイズ
  #sheet_A4 {
    width: 1066px;
  }
  //A3サイズ
  #sheet_A3 {
    width: 1200px;
  }
  //B4サイズ
  #sheet_B4 {
    width: 1190px;
  }

  .sakuhin_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 13px;
  }
  .tantou_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .author_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &_null {
      display: block;
      height: 10px;
    }
  }
  .bikou {
    &_ori {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: auto;
    }
    &_sakuhin {
      display: -webkit-box;
      font-size: 8px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 12px;
    }
  }
  .active_bikou {
    border: 2px solid red !important;
    margin: -2px 0;
  }
  //進行ステータス
  .st_kikaku {
    background-color: #ffccff;
  }

  .st_genkou {
    background-color: #ffffaa;
  }

  .st_kumihan {
    background-color: #e6ffe9;
  }

  .st_kouryo {
    background-color: #ffdbc9;
  }

  .st_insatu {
    background-color: #ffd5ec;
  }

  .st_genka {
    background-color: #e6e6fa;
  }

  //ページカウント対象外の場合何も表示させない
  .sakuhin_address_blanc {
    opacity: 0;
  }

  //ページ範囲備考の背景色
  .bg_pink {
    background-color: #ffd5ec;
    &--preview {
      border: 2px dashed pink;
    }
  }
  .bg_yellow {
    background-color: #ffffaa;
    &--preview {
      border: 2px dashed yellow;
    }
  }
  .bg_green {
    background-color: #e6ffe9;
    &--preview {
      border: 2px dashed green;
    }
  }
  .bg_orange {
    background-color: #ffdbc9;
    &--preview {
      border: 2px dashed orange;
    }
  }
  .bg_purple {
    background-color: #e6e6fa;
    &--preview {
      border: 2px dashed purple;
    }
  }

  // 出力設定用-----------------------------
  // 台割のみ
  .nakatoji_sakuhin.nakatoji_sakuhin_onlydaiwari {
    height: 55px;
  }

  //印刷用CSS-------------------------------
  @media print {
    html,
    body {
      height: auto;
    }
    //A4サイズ
    .sheet_A4 {
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }
    //B4サイズ
    .sheet_B4 {
      width: 1190px;
    }

    .nakatoji_line {
      break-inside: avoid;
    }

    //A4は9行,A3・B4は10行ごとにlineを改行
    .page_next {
      page-break-after: always;
    }
    .page_next + div {
      margin-top: 60px;
      border-top: 1px solid rgb(0, 0, 0);
    }
    /*最後のページは改ページを入れない*/
    .DaiwariFormattingLeft > div:last-child {
      // border: 3px solid blue;
      page-break-after: auto;
    }
  }

  // 中綴じ台割用CSS__________________________

  .nakatoji_line {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
  }

  .nakatoji_sakuhin {
    &_left {
      width: 45%;
      justify-content: right;
    }
    &_right {
      width: 45%;
      justify-content: left;
    }
  }
  .nakatoji_sakuhin {
    width: calc(100% / var(--half) * var(--width));
    font-size: 10px;
    text-align: center;
    height: 75px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .sahen_sakuhin {
    border-left: 1px solid #000;
  }
  .uhen_sakuhin {
    border-right: 1px solid #000;
  }
  .flex_nakatoji_uhen {
    display: flex;
    justify-content: left;
  }
  .flex_nakatoji_sahen {
    display: flex;
    justify-content: right;
  }
  .nakatoji_address_uhen {
    text-align: center;
    font-size: 10px;
    width: calc(100% / var(--half));
    display: inline-block;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
  }
  .nakatoji_address_sahen {
    text-align: center;
    font-size: 10px;
    width: calc(100% / var(--half));
    display: inline-block;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }
  .nakatoji_ori_container {
    width: 85px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
    padding-top: 2px;
    background: #ddd;
    p {
      font-size: 10px;
      line-height: 11px;
    }
  }
}
</style>
