import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fb043b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "InputCheckBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: "InputCheckBox_Check",
      class: _normalizeClass(["InputCheckBox_Check", { isActive: _ctx.selectFlg }])
    }, "全選択", 2),
    _createElementVNode("input", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelectBox && _ctx.toggleSelectBox(...args))),
      type: "checkbox",
      name: "InputCheckBox_Check",
      id: "InputCheckBox_Check"
    })
  ]))
}