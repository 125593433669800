<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title"></p>
      </div>
      <div class="modal__review">
        <div class="modal__message">
          <p>{{ this.oriAddress + 1 }}折の基本情報を設定してください</p>
        </div>
        <div class="select_container">
          <p class="text_c">色数：</p>
          <div class="modal__btn__block">
            <button
              :class="
                this.initialSikisuu == '1C'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectSikisuu('1C')"
            >
              1C
            </button>
            <button
              :class="
                this.initialSikisuu == '4C'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectSikisuu('4C')"
            >
              4C
            </button>
            <button
              :class="
                this.initialSikisuu == '自由入力'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectSikisuu('自由入力')"
            >
              自由入力
            </button>
          </div>
        </div>
        <div class="select_container input_block">
          <div class="input_wrapper">
            <input
              id="ori_sikisuu_in"
              name="ori_sikisuu_in"
              class="input_sikisuu text_box no-spin"
              type="number"
              :disabled="initialSikisuu !== '自由入力'"
              :value="freeInputSikisuu_comp"
              @change="input_sikisuu"
            />C
          </div>
          <p class="error-message" v-show="hasError_sikisuu">
            マイナスの値は設定できません
          </p>
        </div>
        <div class="select_container">
          <p class="text_c">折区分：</p>
          <div class="modal__btn__block">
            <button
              :class="
                this.initialKbn == kbnNum + 'R'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectKbn(kbnNum + 'R')"
            >
              {{ kbnNum }}R
            </button>
            <button
              :class="
                this.initialKbn == kbnAlpha + 'K'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectKbn(kbnAlpha + 'K')"
            >
              {{ kbnAlpha }}K
            </button>
            <button
              :class="
                this.initialKbn == '表紙'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectKbn('表紙')"
            >
              表紙
            </button>
            <button
              :class="
                this.initialKbn == '自由入力'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectKbn('自由入力')"
            >
              自由入力
            </button>
          </div>
        </div>
        <div class="select_container input_block">
          <div class="input_wrapper">
            <input
              list="ori_kbn_select"
              @change="input_oriKbn"
              id="ori_kbn_in"
              class="text_box"
              name="ori_kbn_in"
              :disabled="initialKbn !== '自由入力'"
            />
            <datalist id="ori_kbn_select">
              <option value="表1"></option>
              <option value="表2"></option>
              <option value="表3"></option>
              <option value="表4"></option>
              <option value="口絵"></option>
              <option value="ハガキ"></option>
            </datalist>
          </div>
        </div>
        <div class="select_container">
          <p class="text_c">追加位置：</p>
          <div class="modal__btn__block">
            <button
              :class="
                this.initialPosition == '最後尾'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectPosition('最後尾')"
            >
              最後尾
            </button>
            <button
              :class="
                this.initialPosition == '選択中の1つ後ろ'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              class="btn_wide"
              @click="selectPosition('選択中の1つ後ろ')"
            >
              選択中の1つ後ろ
            </button>
            <button
              :class="
                this.initialPosition == '自由入力'
                  ? 'btn_sikisuu_selected'
                  : 'btn_sikisuu'
              "
              @click="selectPosition('自由入力')"
            >
              自由入力
            </button>
          </div>
        </div>
        <div class="select_container input_block">
          <div class="input_wrapper">
            <input
              id="ori_sikisuu_in"
              name="ori_sikisuu_in"
              class="input_sikisuu text_box no-spin"
              type="number"
              :disabled="initialPosition !== '自由入力'"
              :value="freeInputPosition_num"
              @change="input_position"
            />折目
          </div>
          <p class="error-message" v-show="hasError_position">
            0以下の値は設定できません
          </p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_gray" @click="hideModal('modal_sikisuu', true)">
            決定
          </button>
          <button class="btn_gray" @click="hideModal('modal_sikisuu', false)">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notyf } from "notyf";
const notyf = new Notyf({ duration: 5000 });

export default {
  props: {
    oriAddress: {
      type: Number,
    },
    ori_array: {
      type: Array,
    },
  },
  emits: ["onClickAddOri", "hideModal", "count_timeOut"],
  data() {
    return {
      initialSikisuu: "1C",
      initialKbn: "",
      initialPosition: "最後尾",
      freeInputSikisuu_num: 0,
      freeInputKbn: "",
      kbnNum: "",
      kbnAlpha: "",
      kbnHyou: "",
      freeInputPosition_num: 1,
      hasError_sikisuu: false,
      hasError_position: false,
      insertOriIndex: null,
    };
  },
  created() {
    //nK=数値,aK=アルファベット
    let kbn_nR_array = [];
    let kbn_aK_array = [];
    this.ori_array.forEach((ori) => {
      //折区分nK・aKのものだけをそれぞれ絞り込む
      if (ori.ori_kbn.match(/K/)) {
        let slice_kbn = ori.ori_kbn.slice(0, -1);
        if (isNaN(slice_kbn)) {
          kbn_aK_array.push(slice_kbn);
        }
      }
      if (ori.ori_kbn.match(/R/)) {
        let slice_kbn = ori.ori_kbn.slice(0, -1);
        if (!isNaN(slice_kbn)) {
          kbn_nR_array.push(slice_kbn);
        }
      }
    });

    //追加する場合のnK・aK・表nの値を求める
    let alphabetArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    if (kbn_nR_array.length) {
      this.kbnNum = Number(kbn_nR_array[kbn_nR_array.length - 1]) + 1;
    } else {
      this.kbnNum = 1;
    }
    if (kbn_aK_array.length) {
      this.kbnAlpha = alphabetArray[kbn_aK_array.length] + "";
    } else {
      this.kbnAlpha = "A";
    }
    this.initialKbn = this.kbnNum + "R";
  },
  methods: {
    hideModal(modalName, shouldAddOri) {
      //色数・区分の設定
      new Promise((resolve) => {
        if (shouldAddOri) {
          let shouldTakeOverOriInfo = false;
          if (this.initialSikisuu === "自由入力") {
            this.initialSikisuu = this.freeInputSikisuu_num + "C";
          }
          if (this.initialKbn === "表紙") {
            //表紙ボタンから区分を設定した場合、表紙情報を引き継がせる
            shouldTakeOverOriInfo = true;
          } else if (this.initialKbn === "自由入力") {
            this.initialKbn = this.freeInputKbn;
          }
          if (this.initialPosition === "自由入力") {
            this.insertOriIndex = this.freeInputPosition_num - 1;
          } else if (this.initialPosition === "最後尾") {
            this.insertOriIndex = this.ori_array.length;
          }
          if (!this.shoulAddOri(this.initialPosition)) {
            return;
          }
          this.$emit(
            "onClickAddOri",
            this.initialSikisuu,
            this.initialKbn,
            this.insertOriIndex,
            shouldTakeOverOriInfo
          );
        }
        resolve();
      }).then(() => {
        const modalData = {
          shouldAddOri: shouldAddOri,
          insertOriIndex: this.insertOriIndex,
        };
        this.$emit("hideModal", modalName, modalData);
      });
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    //色数の選択
    selectSikisuu(newSikisuu) {
      this.initialSikisuu = newSikisuu;
    },
    input_sikisuu(e) {
      let newVal = Number(e.target.value);
      if (0 > newVal) {
        this.hasError_sikisuu = true;
      } else {
        this.hasError_sikisuu = false;
      }
      this.freeInputSikisuu_num = newVal;
    },
    //区分の選択
    input_oriKbn(event) {
      this.freeInputKbn = event.target.value;
    },
    selectKbn(newKbn) {
      this.initialKbn = newKbn;

      if (newKbn === "表紙") {
        this.autoUpdateSikisuu();
      }
    },
    autoUpdateSikisuu() {
      //表紙の場合、最初に出てくる表紙折の色数情報を自動で選択する
      const firstHyousiOri = this.ori_array.find(
        (ori) => ori.ori_kbn === "表紙"
      );
      if (firstHyousiOri) {
        //1C・4cの場合はそのまま設定する
        if (
          firstHyousiOri.sikisuu === "1C" ||
          firstHyousiOri.sikisuu === "4C"
        ) {
          this.initialSikisuu = firstHyousiOri.sikisuu;
        } else {
          //1C・4C以外の場合自由入力扱い
          this.initialSikisuu = "自由入力";
          const freeInputSikisuu_num = firstHyousiOri.sikisuu.slice(0, -1);
          this.freeInputSikisuu_num = freeInputSikisuu_num;
        }
      }
    },
    //追加位置の選択
    selectPosition(initialPosition) {
      this.initialPosition = initialPosition;
      if (initialPosition === "選択中の1つ後ろ") {
        this.ori_array.forEach((ori) => {
          if (ori.is_selected_ori) {
            this.insertOriIndex = ori.ori_address_computed;
          }
          ori.sakuhin_array.forEach((sakuhin) => {
            if (sakuhin.is_selected_sakuhin) {
              this.insertOriIndex = sakuhin.parent_ori_address_computed;
            }
          });
        });
      }
    },
    input_position(e) {
      let newVal = Number(e.target.value);
      if (1 > newVal) {
        this.hasError_position = true;
      } else {
        this.hasError_position = false;
      }
      this.freeInputPosition_num = newVal;
    },
    isDivided() {
      //追加先の折が分断中か否かを判定
      //対象折の最後尾の作品が分断中かつ、その次の折が分断中で子作品である
      const isDivided_oriLastSakuhin = this.ori_array[this.insertOriIndex - 1]
        ? this.ori_array[this.insertOriIndex - 1].sakuhin_array.at(-1)
            .is_divided
        : false;
      const isDivided_nextOriFirstSakuhin = this.ori_array[this.insertOriIndex]
        ? !this.ori_array[this.insertOriIndex].sakuhin_array[0].is_parent &&
          this.ori_array[this.insertOriIndex].sakuhin_array[0].is_divided
        : false;

      if (isDivided_oriLastSakuhin && isDivided_nextOriFirstSakuhin) {
        return true; //分断中なので折追加NG
      } else {
        return false; //分断していないので折追加OK
      }
    },
  },
  computed: {
    freeInputSikisuu_comp() {
      if (0 > this.freeInputSikisuu_num) {
        return 0;
      } else {
        return this.freeInputSikisuu_num;
      }
    },
    shoulAddOri() {
      return (initialPosition) => {
        if (
          initialPosition === "自由入力" ||
          initialPosition === "選択中の1つ後ろ"
        ) {
          //「選択中の1つ後ろ」の場合はinsertOriIndex,「自由入力」の場合はfreeInputPosition_numを採用する
          if (
            initialPosition === "選択中の1つ後ろ" &&
            (!this.insertOriIndex || this.insertOriIndex < 0)
          ) {
            notyf.error(
              "選択中の作品または折が存在しないため、折を追加できません。"
            );
            return false;
          }
          if (initialPosition === "自由入力" && this.insertOriIndex < 0) {
            notyf.error("0以下の値は設定不可のため、折を追加できません。");
            return false;
          }
          if (this.ori_array.length >= this.insertOriIndex) {
            if (this.isDivided()) {
              //指定折が分断中の時追加不可
              notyf.error(
                "指定した追加位置で作品が分断しているため、折を追加出来ません"
              );
              return false;
            }
            return true;
          } else {
            //存在しない折インデックスを指定した場合追加不可
            notyf.error("指定した追加位置は存在しないため、折を追加出来ません");
            return false;
          }
        } else {
          return true;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 600px;
    height: 590px;
    &__message {
      font-size: 18px;
      &__top {
        font-weight: 600;
        text-align: center;
      }
    }
    &__header {
      height: 5%;
      background-color: #fff;
    }
    &__close {
      color: #000;
    }
  }

  .btn_sikisuu {
    padding: 10px;
    border: 2px solid rgb(200, 200, 200);
    border-radius: 5px;
    color: rgb(40, 40, 40);
    font-size: 13px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    width: 80px;
    background: #fff;
  }
  .btn_sikisuu_selected {
    padding: 10px;
    border: 2px solid #ec7211;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    width: 80px;
    background: #ec7211;
  }
  .btn_wide {
    width: 125px;
  }
  .btn_gray {
    width: 140px;
    height: 40px;
    padding: 10px;
  }
  .input_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .input {
    &_sikisuu {
      width: 60px;
    }
  }
  .text_c {
    text-align: center;
  }
  .no-spin::-webkit-inner-spin-button,
  .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
  .error-message {
    color: rgb(255, 0, 0);
    font-size: 11px;
    letter-spacing: -0.5px;
  }
}
</style>
