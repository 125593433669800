import { getUniqueStr } from "./util.js";

// 制作基本情報----------------------------------
export class FormatOri {
  constructor(ori, formatSakuhinArray) {
    this.ori_kbn = ori.ori_kbn;
    this.sikisuu = ori.sikisuu;
    this.yousi = ori.yousi;
    this.kakou_array = ori.kakou_array;
    this.sakuhin_array = formatSakuhinArray;
    this.ori_bikou = ori.ori_bikou;
    this.oriTotalPage = get_oriTotalPage(formatSakuhinArray);
    this.ori_id = "ori_id" in ori ? ori.ori_id : getUniqueStr();
  }
}

export class FormatSakuhin {
  constructor(
    sakuhin,
    formatSakuhinAddressArray,
    sakuhin_address_computed_array_withFlg
  ) {
    this.sakuhin_address_computed_array = formatSakuhinAddressArray;
    this.sakuhin_address_computed_array_withFlg =
      sakuhin_address_computed_array_withFlg;
    this.sakuhin_index_computed_array = sakuhin.sakuhin_index_computed_array
      ? sakuhin.sakuhin_index_computed_array
      : [];
    this.sakuhin_id = sakuhin.sakuhin_id;
    this.sakuhin_index = sakuhin.sakuhin_index;
    this.sakuhin_mei = sakuhin.sakuhin_mei;
    this.sakuhin_mei_hosoku = sakuhin.sakuhin_mei_hosoku;
    this.sakuhin_mei_display = sakuhin.sakuhin_mei_display
      ? sakuhin.sakuhin_mei_display
      : "";
    this.sakuhin_uniqueId = sakuhin.sakuhin_uniqueId;
    this.sinkou_status = sakuhin.sinkou_status;
    this.tantou = sakuhin.tantou;
    this.sakuhin_bikou = sakuhin.sakuhin_bikou;
    this.sakuhin_bikouId = sakuhin.sakuhin_bikouId;
    this.sakuhin_bikou = sakuhin.sakuhin_bikou;
    this.penName = sakuhin.penName;
    this.is_parent = sakuhin.is_parent;
    this.is_divided = sakuhin.is_divided;
    this.total_page = sakuhin.total_page;
    this.lastPage = sakuhin.lastPage;
    this.sakuhinPageBikouList = sakuhin.sakuhinPageBikouList;
    this.pageCountFlg = sakuhin.pageCountFlg;
  }
}

export class ShosiData {
  constructor(responseData) {
    this.labelMei = responseData.labelMei;
    this.title = responseData.title;
    this.kansu = responseData.kansu;
    this.chosya1 = responseData.chosya1;
    this.chosya2 = responseData.chosya2;
    this.chosya3 = responseData.chosya3;
    this.chosya4 = responseData.chosya4;
    this.chosya5 = responseData.chosya5;
    this.chosya6 = responseData.chosya6;
    this.hankei = responseData.hankei;
    this.hensyuTanto = responseData.hensyuTanto;
    this.siagariSunpo = responseData.siagariSunpo;
    this.tuka = responseData.tuka;
    this.juryo = responseData.juryo;
    this.mihonDate = responseData.mihonDate;
    this.hannyuDate = responseData.hannyuDate;
    this.hatubaiDate = responseData.hatubaiDate;
    this.hontaiKakaku = responseData.hontaiKakaku;
    this.hontaikakakuZeikomi = responseData.hontaikakakuZeikomi;
    this.isbn = responseData.isbn;
    this.cbunruiCd = responseData.cbunruiCd;
    this.zassiCd = responseData.zassiCd;
    this.janCdSita = responseData.janCdSita;
    this.hakkoDate = responseData.hakkoDate;
    this.tantoBumon = responseData.tantoBumon;
    this.syosiTorokuDate = responseData.syosiTorokuDate;
    this.seihan = responseData.seihan;
    this.insatu = responseData.insatu;
    this.seihon = responseData.seihon;
    this.kosinDate = responseData.kosinDate;
    this.childDaiwariName = responseData.childDaiwariName;
    this.isZassi = responseData.isZassi;
    this.kosinDateStr = responseData.kosinDateStr;
    this.kosinUserMei = responseData.kosinUserMei;
    this.daiwariKakuteiDateStr = responseData.daiwariKakuteiDateStr;
    this.seisakuBusu = responseData.seisakuBusu;
    this.isAnthology = responseData.isAnthology;

    //書誌データ(手入力項目)がDBにあれば引き継ぐ,無ければ新規作成
    const daiwariData = JSON.parse(responseData.daiwariData);
    this.manualSyosiData =
      "outputSyosiData" in daiwariData
        ? new ManualShosiData(daiwariData.outputSyosiData)
        : new ManualShosiData({});
  }
}

class ManualShosiData {
  constructor(outputSyosiData) {
    let manualSyosiData =
      "manualSyosiData" in outputSyosiData
        ? outputSyosiData.manualSyosiData
        : {};
    this.versionInfo =
      "versionInfo" in manualSyosiData ? manualSyosiData.versionInfo : null;
    this.nagekomi =
      "nagekomi" in manualSyosiData ? manualSyosiData.nagekomi : "";
    this.tsukimonoNyukoDate =
      "tsukimonoNyukoDate" in manualSyosiData
        ? manualSyosiData.tsukimonoNyukoDate
        : "";
    this.tsukimonoKoryoDate =
      "tsukimonoKoryoDate" in manualSyosiData
        ? manualSyosiData.tsukimonoKoryoDate
        : "";
    this.honbunNyukoDate =
      "honbunNyukoDate" in manualSyosiData
        ? manualSyosiData.honbunNyukoDate
        : "";
    this.honbunKoryoDate =
      "honbunKoryoDate" in manualSyosiData
        ? manualSyosiData.honbunKoryoDate
        : "";
    this.syagaikosei =
      "syagaikosei" in manualSyosiData ? manualSyosiData.syagaikosei : "";
    this.hensyuNin =
      "hensyuNin" in manualSyosiData ? manualSyosiData.hensyuNin : "";
    this.hakkoNin =
      "hakkoNin" in manualSyosiData ? manualSyosiData.hakkoNin : "";
    this.hakkojo = "hakkojo" in manualSyosiData ? manualSyosiData.hakkojo : "";
    this.bikou = "bikou" in manualSyosiData ? manualSyosiData.bikou : "";
    this.oriInfoArray =
      "oriInfoArray" in manualSyosiData ? manualSyosiData.oriInfoArray : [];
    this.torihikisaki_hensyu =
      "torihikisaki_hensyu" in manualSyosiData
        ? manualSyosiData.torihikisaki_hensyu
        : "";
    this.torihikisaki_design =
      "torihikisaki_design" in manualSyosiData
        ? manualSyosiData.torihikisaki_design
        : "";
    this.hiraki =
      "hiraki" in manualSyosiData ? manualSyosiData.hiraki : "right";
  }
}

const get_oriTotalPage = (formatSakuhinArray) => {
  let totalPage = 0;
  formatSakuhinArray.forEach((sakuhin) => {
    totalPage = totalPage + sakuhin.sakuhin_address_computed_array.length;
  });

  return totalPage;
};
