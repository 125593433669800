<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">台割保存履歴</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="closeModal"
          class="modal__close"
        />
      </div>
      <!-- 保存履歴(手動・自動) -->
      <div class="modal__review">
        <div class="modal__review__head">
          <span
            @click="selectTab(false)"
            :class="showAutoSaveTab ? 'non-active' : 'active'"
            >ユーザー保存</span
          >
          <span
            @click="selectTab(true)"
            :class="showAutoSaveTab ? 'active' : 'non-active'"
            >システム自動保存</span
          >
        </div>
        <div class="modal__review__content">
          <div
            v-show="!this.daiwariRirekiArray.length"
            class="modal__message_null"
          >
            ※保存履歴はありません。
          </div>
          <div class="modal__message" v-show="this.daiwariRirekiArray.length">
            <table class="frame_table">
              <thead>
                <tr>
                  <th>ユーザー名</th>
                  <th>修正日時</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody v-if="this.daiwariRireki_paging">
                <tr
                  v-for="(rireki, index) in this.daiwariRireki_paging.display"
                  :key="index"
                >
                  <td>
                    <span v-show="!showAutoSaveTab">{{
                      rireki.torokuUserMei
                    }}</span>
                    <span v-show="showAutoSaveTab">自動</span>
                  </td>
                  <td>{{ rireki.torokuDateStr }}</td>
                  <td>
                    <button
                      class="btn_syokai"
                      @click="
                        openOverlay(
                          rireki.rirekiId,
                          rireki.torokuUserMei,
                          rireki.torokuDateStr,
                          kakuteiRireki_flg
                        )
                      "
                    >
                      照会
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="!this.daiwariRireki_paging">
                <tr
                  v-for="(rireki, index) in this.daiwariRirekiArray"
                  :key="index"
                >
                  <td>
                    <span v-if="!showAutoSaveTab">{{
                      rireki.torokuUserMei
                    }}</span>
                    <span v-if="showAutoSaveTab">自動</span>
                  </td>
                  <td>{{ rireki.torokuDateStr }}</td>
                  <td>
                    <button
                      class="btn_syokai"
                      @click="
                        openOverlay(
                          rireki.rirekiId,
                          rireki.torokuUserMei,
                          rireki.torokuDateStr
                        )
                      "
                    >
                      照会
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Paginate
            v-show="this.daiwariRirekiArray.length"
            class="pagenation"
            :page-count="daiwariRirekiTotalPage"
            :hide-prev-next="true"
            :page-range="3"
            :margin-pages="1"
            :click-handler="getCurrentPage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-item--link'"
            :active-class="'page-item--active'"
          ></Paginate>

          <!-- <div class="modal__btn__block" v-show="daiwariRireki_paging">
          <button class="btn_gray" @click="closeModal">戻る</button>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";

export default {
  props: {
    daiwariId: Number,
    url: String,
    kakuteiRireki_flg: Boolean,
  },
  emits: ["openOverlay", "hideModal", "count_timeOut"],
  components: { Paginate },
  data() {
    return {
      currentPage: 1,
      clickedPage: 1,
      daiwariRireki_paging: null,
      daiwariKakuteiArray: [],
      daiwariRirekiTotalPage: 1,
      daiwariRirekiArray: [],
      showAutoSaveTab: false,
      rirekiListUrl: "daiwari/daiwari_rireki_list",
    };
  },
  created() {
    //手動保存履歴の取得
    this.fetchRirekiData();
  },
  methods: {
    selectTab(showAutoSaveTab) {
      if (this.showAutoSaveTab !== showAutoSaveTab) {
        this.showAutoSaveTab = showAutoSaveTab;
        this.rirekiListUrl = this.showAutoSaveTab
          ? "daiwari/daiwari_rireki_auto_list"
          : "daiwari/daiwari_rireki_list";

        this.getCurrentPage(1);
      }
    },
    fetchRirekiData() {
      axios
        .get(
          `${this.url}/${this.rirekiListUrl}?daiwariId=${this.daiwariId}&page=1&displayLimit=10`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.daiwariRirekiTotalPage = Math.ceil(response.data.size / 10);
          this.daiwariRirekiArray = response.data.display;
        })
        .catch((error) => {
          alert("保存履歴データが取得できませんでした\n");
          console.log(error);
        });
    },
    openOverlay(daiwariRirekiId, toroku_user_mei, toroku_date_str) {
      const rirekiUrl =
        this.rirekiListUrl === "daiwari/daiwari_rireki_list"
          ? "daiwari/daiwari_rireki"
          : "daiwari/daiwari_rireki_auto";
      //履歴参照をオーバーレイで表示
      const overlayId = daiwariRirekiId;
      const overlayName = "overlay_daiwariHistoryReference";
      const overlayData = {
        torokuUserMei: toroku_user_mei,
        torokuDateStr: toroku_date_str,
        kakuteiRirekiFlg: this.kakuteiRireki_flg,
        rirekiUrl: rirekiUrl,
      };
      this.$emit("openOverlay", overlayName, overlayId, overlayData);
    },
    closeModal() {
      this.$emit("hideModal", "modal_workingHistory");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    getPageInfo() {
      axios
        .get(
          `${this.url}/${this.rirekiListUrl}?daiwariId=${this.daiwariId}&page=${this.currentPage}&displayLimit=10`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          this.daiwariRirekiTotalPage = Math.ceil(response.data.size / 10);
          this.daiwariRireki_paging = response.data;
        })
        .catch((error) => {
          alert("履歴データが取得できませんでした\n" + error);
        });
    },
    getCurrentPage(currentPage) {
      this.clickedPage = currentPage;
      this.currentPage = currentPage;
      //新しい履歴情報呼び出し
      this.getPageInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 700px;
    height: 660px;
    &__message {
      font-size: 16px;
      max-height: 80%;
      overflow: auto;
      overflow-x: hidden;
      &_null {
        font-size: 20px;
      }
      &__top {
        font-weight: 600;
        text-align: center;
      }
    }
    &__review {
      gap: 0px;
      &__content {
        border: 2px solid rgb(38, 124, 164);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 40px;
        width: 560px;
        height: 470px;
        gap: 20px;
      }
      &__head {
        border: 2px solid rgb(38, 124, 164);
        border-bottom: none;
        position: relative;
        top: 0px;
        left: -130px;
        span {
          display: inline-block;
          padding: 2px 10px;
        }
        .active {
          background: rgb(38, 124, 164);
          color: white;
        }
        .non-active {
          background: white;
          color: rgb(38, 124, 164);
        }
      }
    }
  }

  .btn_gray {
    width: 100px;
    height: 40px;
    padding: 10px;
  }

  .frame_table {
    height: 100%;
    width: 100%;
  }

  .btn_syokai {
    margin-right: 5px;
  }
  .btn__left {
    position: relative;
  }
  .btn__right {
    position: relative;
  }
}
</style>
