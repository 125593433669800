import { nextTick } from "vue";
import { useStore } from "vuex";

export default function () {
  // エンターキーでのフォーカス移動処理
  const creatMoveWithEnterElms = async () => {
    const targetElms = document.getElementsByClassName(
      "Move_With_Enter"
      // eslint-disable-next-line no-undef
    ) as HTMLCollectionOf<HTMLElement>;

    await nextTick();
    const splitArray = [...targetElms];
    return splitArray.sort((x, y) => {
      if (x.tabIndex > y.tabIndex) return 1;
      if (x.tabIndex < y.tabIndex) return -1;
      return 0;
    });
  };
  const moveNext = (event: any, elementsArray: HTMLElement[]) => {
    event.preventDefault();
    // console.log("moveNext");
    const index = [].findIndex.call(elementsArray, (e) => e === event.target);
    // 要素の最後にフォーカスが当たっていたら次のフォーカス処理を行わない
    if (elementsArray.length - 1 >= index + 1) {
      elementsArray[index + 1].focus();
    }
  };
  const movePrev = (event: any, elementsArray: HTMLElement[]) => {
    event.preventDefault();
    // console.log("movePrev");
    const index = [].findIndex.call(elementsArray, (e) => e === event.target);
    // 要素の最初にフォーカスが当たっていたら次のフォーカス処理を行わない
    if (index > 0) {
      elementsArray[index - 1].focus();
    }
  };

  // 検索コンポーネント用リスト整形
  const store = useStore();
  const createToritugiList = () => {
    const toritugiListData = [...store.getters["constant/getToritugiList"]];
    const toritugiList = [
      {
        label: "全件",
        value: "",
      },
    ];
    toritugiListData.forEach((toritugi) => {
      toritugiList.push({
        label: toritugi.toritugiMei,
        value: toritugi.toritugiCd,
      });
    });
    return toritugiList;
  };

  return { creatMoveWithEnterElms, moveNext, movePrev, createToritugiList };
}
