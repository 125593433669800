<template>
  <div id="overlay_daiwariOutput">
    <div class="overlay_header" :class="sheet">
      <ModalEditOriInfo
        v-if="modalName === 'modalEditOriInfo'"
        :editOriMeisyo="editOriMeisyo"
        @deleteOriInfo="deleteOriInfo"
        @hideModal="hideModal"
      />
      <ModalTorihikisaki
        v-if="modalName === 'modal_design' || modalName === 'modal_hensyu'"
        :modalName="modalName"
        :selectedDesign="manualSyosiData.torihikisaki_design"
        :selectedHensyu="manualSyosiData.torihikisaki_hensyu"
        :url="this.url"
        @hideModal="hideModal"
        @updateTorihikisaki="updateTorihikisaki"
      />
      <ModalSearchYoushi
        v-if="modalName === 'modal_output_youshi'"
        :selectedOri="target_oriInfoArray"
        @removeYoushi="removeYoushi"
        @updateYoushi="updateYoushi"
        @hideModal="hideModal"
        :url="this.url"
        :mst_yoto="mst_yoto"
        :mst_yosi="mst_yosi"
      />
      <div class="print_titleArea">
        <span>書誌台割表</span>
        <span>『{{ hankaku(syosiData.title) }}』</span>
        <span v-if="syosiData.kansu">{{ syosiData.kansu }}</span>
        <span v-if="manualSyosiData.versionInfo">{{
          manualSyosiData.versionInfo
        }}</span>
      </div>
      <p class="overlay_headerTitle print_display_none">書誌台割表</p>
      <div class="select_container print_display_none">
        <span>用紙サイズ:</span>
        <select v-model="printSize" class="select_box select_box_printSize">
          <option value="A4">A4</option>
          <option value="A3">A3</option>
          <option value="B4">B4</option>
        </select>
      </div>
      <div class="btn_container">
        <!-- 印刷のアコーディオンボタン -->
        <div class="acordion_group">
          <button
            v-if="cancopy"
            class="daiwari_title action_button"
            @click="printDaiwari"
          >
            印刷
          </button>
          <button
            v-else
            class="daiwari_title disable_button"
            @mouseover="isShowTooltip = true"
            @mouseleave="isShowTooltip = false"
          >
            印刷
          </button>
          <!-- ツールチップ -->
          <transition>
            <div
              v-if="!cancopy && isShowTooltip"
              class="disable_button_tooltip"
            >
              <div class="balloon-002">
                台割内容に変更があります。<br />再度確定処理を行ってください。
              </div>
            </div>
          </transition>
          <!-- <div v-show="isOpen" class="acordion_area print_display_none">
            <ul class="acordion_list">
              <li class="acordion_item" @click="printDaiwariFull">
                "フルバージョン"で印刷
              </li>
              <li class="acordion_item" @click="printDaiwariCompact">
                "コンパクトバージョン"で印刷
              </li>
            </ul>
          </div> -->
        </div>
        <button
          class="daiwari_title action_button"
          @click="saveDaiwari"
          v-if="!isLockDaiwari"
        >
          保存(ctrl+shift+s)
        </button>
        <button class="daiwari_title action_button" @click="hideOverlay">
          閉じる
        </button>
      </div>
    </div>

    <!-- 出力設定 -->
    <div class="output_container" v-click-outside="closeShowcustomOutputList">
      <MultiSelectBar
        :selectList="selectOutputList"
        :title="'出力設定'"
        @selectEvent="switchOutputSetting"
      />
      <div class="output_custom" v-if="isCustom && isShowcustomOutputList">
        <div class="output_custom_checkarea">
          <p class="description">出力したい項目を選択してください</p>
          <ul
            class="ks-cboxtags"
            v-for="(listVal, listKey) in customOutputList"
            :key="listKey"
          >
            <p>＜{{ listVal.label }}＞</p>
            <div class="list_flex">
              <template v-for="(contentVal, contentKey) in listVal">
                <li v-if="contentKey !== 'label'" :key="contentKey">
                  <input
                    type="checkbox"
                    :id="`checkbox_${listKey}_${contentKey}`"
                    v-model="contentVal.isSelected"
                  /><label :for="`checkbox_${listKey}_${contentKey}`">{{
                    contentVal.label
                  }}</label>
                </li>
              </template>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="!isOnlyDaiwari" class="contents_wrapper_head" :class="sheet">
      <div class="list_daiwari-table_head" :class="table_head">
        <table
          class="daiwari-table daiwari-table_head"
          style="border-bottom: 1px solid black"
        >
          <tr>
            <th>企画ID</th>
            <td>{{ kikakuId }}</td>
          </tr>
          <tr>
            <th>台割ID</th>
            <td>{{ daiwariId }}</td>
          </tr>
        </table>
        <table
          class="daiwari-table daiwari-table_head"
          style="border-bottom: 1px solid black"
        >
          <tr>
            <th>{{ hankaku("バージョン情報") }}</th>
            <td>
              <span>{{ manualSyosiData.versionInfo }}</span>
            </td>
          </tr>
          <tr>
            <th>更新日時</th>
            <td>{{ syosiData.kosinDateStr }}</td>
          </tr>
        </table>
        <table
          class="daiwari-table daiwari-table_head"
          style="border-bottom: 1px solid black"
        >
          <tr>
            <th>最終更新</th>
            <td>
              <span class="table_span">
                <p>{{ syosiData.kosinDateStr }}</p>
                <p>{{ syosiData.kosinUserMei }}</p>
              </span>
            </td>
          </tr>
          <tr>
            <th>台割確定</th>
            <td>{{ syosiData.daiwariKakuteiDateStr }}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- 「1. 製作基本情報」テーブル -->
    <div class="kotei_top" :class="[sheet, kotei_top]">
      <p :class="isOnlyDaiwari ? 'print_display_none' : ''">1.制作基本情報</p>
      <table class="daiwari-table daiwari-table_top">
        <tr>
          <th>{{ hankaku("レーベル") }}</th>
          <td>{{ syosiData.labelMei }}</td>
          <th>作品名</th>
          <td
            colspan="7"
            v-if="syosiData.childDaiwariName === null"
            class="td_sakuhin_mei"
          >
            {{ hankaku(syosiData.title) }}
          </td>
          <td colspan="3" v-if="syosiData.childDaiwariName !== null">
            {{ syosiData.title }}
          </td>
          <th v-if="syosiData.childDaiwariName !== null">枝番名</th>
          <td colspan="3" v-if="syosiData.childDaiwariName !== null">
            {{ hankaku(syosiData.childDaiwariName) }}
          </td>
          <th>巻数</th>
          <td>{{ syosiData.kansu }}</td>
        </tr>
      </table>
      <table
        class="daiwari-table daiwari-table_top"
        style="border-bottom: 1px solid black"
      >
        <tr>
          <th>著者情報1</th>
          <td>{{ syosiData.chosya1 }}</td>
          <th>著者情報2</th>
          <td>{{ syosiData.chosya2 }}</td>
          <th>著者情報3</th>
          <td>{{ syosiData.chosya3 }}</td>
          <th>著者情報4</th>
          <td>{{ syosiData.chosya4 }}</td>
          <th>著者情報5</th>
          <td>{{ syosiData.chosya5 }}</td>
          <th>著者情報6</th>
          <td>{{ syosiData.chosya6 }}</td>
        </tr>
      </table>
    </div>
    <div class="overlay_content">
      <div v-if="!isOnlyDaiwari" class="contents_wrapper_main" :class="sheet">
        <!-- 折りたたまないテーブル -->
        <div class="table_wrapper">
          <table
            class="daiwari-table daiwari-table_top table_fixed bordet-bottom"
          >
            <tr style="border-top: none">
              <th>判型</th>
              <td>{{ syosiData.hankei }}</td>
              <th>右開/左開</th>
              <td>
                <select
                  class="select_box"
                  v-show="showInput"
                  v-model="manualSyosiData.hiraki"
                  :disabled="isLockDaiwari"
                >
                  <option value="right">右開</option>
                  <option value="left">左開</option>
                </select>
                <span v-show="!showInput && manualSyosiData.hiraki === 'right'"
                  >右開</span
                >
                <span v-show="!showInput && manualSyosiData.hiraki === 'left'"
                  >左開</span
                >
              </td>
              <th>編集担当</th>
              <td>{{ syosiData.hensyuTanto }}</td>
              <th>{{ hankaku("デザイン") }}</th>
              <!-- <td
                class="bg_yellow"
                v-show="!isLockDaiwari"
                @dblclick="showModal('modal_design')"
                :value="manualSyosiData.torihikisaki_design"
              >
                {{ hankaku(manualSyosiData.torihikisaki_design) }}
              </td>
              <td
                v-show="isLockDaiwari"
                :value="manualSyosiData.torihikisaki_design"
              >
                {{ hankaku(manualSyosiData.torihikisaki_design) }}
              </td> -->
              <td>{{ hankaku(manualSyosiData.torihikisaki_design) }}</td>
              <th>{{ hankaku("外編・編プロ") }}</th>
              <!-- <td
                class="bg_yellow"
                @dblclick="showModal('modal_hensyu')"
                v-show="!isLockDaiwari"
                :value="manualSyosiData.torihikisaki_hensyu"
              >
                {{ hankaku(manualSyosiData.torihikisaki_hensyu) }}
              </td>
              <td
                v-show="isLockDaiwari"
                :value="manualSyosiData.torihikisaki_hensyu"
              >
                {{ hankaku(manualSyosiData.torihikisaki_design) }}
              </td> -->
              <td>{{ hankaku(manualSyosiData.torihikisaki_hensyu) }}</td>
              <th>投込み</th>
              <td>
                <input
                  v-model="manualSyosiData.nagekomi"
                  v-show="showInput"
                  type="text"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{ manualSyosiData.nagekomi }}</span>
              </td>
            </tr>
          </table>
          <table
            class="daiwari-table daiwari-table_middle table_fixed"
            v-if="
              !(isCustom && !customOutputList.seisakuKihon.syousai.isSelected)
            "
          >
            <tr>
              <th
                colspan="12"
                class="pointer_item th_detail"
                style="border-top: 1px solid black"
                @click="toggle_showTableFlg"
              >
                詳細情報
                <font-awesome-icon
                  :icon="['fas', 'chevron-down']"
                  v-if="showTableFlg"
                />
                <font-awesome-icon
                  :icon="['fas', 'chevron-up']"
                  v-if="!showTableFlg"
                />
              </th>
            </tr>
          </table>
          <table
            class="daiwari-table table_fixed table_detail"
            v-if="
              showTableFlg &&
              !(isCustom && !customOutputList.seisakuKihon.syousai.isSelected)
            "
          >
            <!-- 折りたたんで基本は非表示にするテーブル -->
            <tr>
              <th>仕上がり寸法</th>
              <td>{{ syosiData.siagariSunpo }}</td>
              <th>{{ hankaku("総ページ数") }}</th>
              <td>{{ daiwariTotalPage }}</td>
              <th>{{ hankaku("カラー頁(3C以上)") }}</th>
              <td>{{ daiwariTotalPage_4C }}</td>
              <th>{{ hankaku("モノクロ頁(2C含)") }}</th>
              <td>{{ daiwariTotalPage_1C }}</td>
              <th>束</th>
              <td>
                {{ syosiData.tuka }}
                <span v-show="syosiData.tuka">mm</span>
              </td>
              <th>重量</th>
              <td>
                {{ syosiData.juryo }}
                <span v-show="syosiData.juryo">g</span>
              </td>
            </tr>
            <tr>
              <th>制作部数</th>
              <td>{{ syosiData.seisakuBusu }}</td>
              <th>見本日</th>
              <td>{{ syosiData.mihonDate }}</td>
              <th>搬入年月日</th>
              <td>{{ syosiData.hannyuDate }}</td>
              <th>発売年月日</th>
              <td>{{ syosiData.hatubaiDate }}</td>
              <th>本体税抜</th>
              <td>
                {{ syosiData.hontaiKakaku }}
                <span v-show="syosiData.hontaiKakaku">円</span>
              </td>
              <th>本体税込</th>
              <td>
                {{ syosiData.hontaikakakuZeikomi }}
                <span v-show="syosiData.hontaikakakuZeikomi">円</span>
              </td>
            </tr>
            <tr>
              <th>ISBN</th>
              <td class="text_small_print">{{ syosiData.isbn }}</td>
              <th>{{ hankaku("Cコード") }}</th>
              <td>{{ syosiData.cbunruiCd }}</td>
              <th>{{ hankaku("雑誌コード") }}</th>
              <td>{{ syosiData.zassiCd }}</td>
              <th>JAN下</th>
              <td class="text_small_print">{{ syosiData.janCdSita }}</td>
              <th>発売年月日</th>
              <td>{{ syosiData.hakkoDate }}</td>
              <th>担当部門</th>
              <td>{{ syosiData.tantoBumon }}</td>
            </tr>
            <tr
              v-if="!isCompact"
              :class="isCompact ? 'print_display_none' : ''"
            >
              <th>付物入稿日</th>
              <td>
                <input
                  v-model="manualSyosiData.tsukimonoNyukoDate"
                  v-show="showInput"
                  type="date"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{
                  manualSyosiData.tsukimonoNyukoDate
                }}</span>
              </td>
              <th>付物校了日</th>
              <td>
                <input
                  v-model="manualSyosiData.tsukimonoKoryoDate"
                  v-show="showInput"
                  type="date"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{
                  manualSyosiData.tsukimonoKoryoDate
                }}</span>
              </td>
              <th>本文入稿日</th>
              <td>
                <input
                  v-model="manualSyosiData.honbunNyukoDate"
                  v-show="showInput"
                  type="date"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{
                  manualSyosiData.honbunNyukoDate
                }}</span>
              </td>
              <th>本文校了日</th>
              <td>
                <input
                  v-model="manualSyosiData.honbunKoryoDate"
                  v-show="showInput"
                  type="date"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{
                  manualSyosiData.honbunKoryoDate
                }}</span>
              </td>
              <th>社外校正</th>
              <td>
                <input
                  v-model="manualSyosiData.syagaikosei"
                  v-show="showInput"
                  type="text"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{
                  manualSyosiData.syagaikosei
                }}</span>
              </td>
              <th>書籍確定</th>
              <td>{{ syosiData.syosiTorokuDate }}</td>
            </tr>
            <tr
              v-if="!isCompact"
              :class="isCompact ? 'print_display_none' : ''"
            >
              <th>
                <span class="span_print"
                  >{{ hankaku("データ制作") }}
                  <p>(製版所)</p></span
                >
                <span class="span_default"
                  >{{ hankaku("データ制作") }}(製版所)</span
                >
              </th>
              <td>{{ syosiData.seihan }}</td>
              <th>印刷</th>
              <td>{{ syosiData.insatu }}</td>
              <th>製本</th>
              <td>{{ syosiData.seihon }}</td>
              <th>編集人</th>
              <td>
                <input
                  v-model="manualSyosiData.hensyuNin"
                  v-show="showInput"
                  type="text"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{ manualSyosiData.hensyuNin }}</span>
              </td>
              <th>発行人</th>
              <td>
                <input
                  v-model="manualSyosiData.hakkoNin"
                  v-show="showInput"
                  type="text"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{ manualSyosiData.hakkoNin }}</span>
              </td>
              <th>発行所</th>
              <td>
                <input
                  v-model="manualSyosiData.hakkojo"
                  v-show="showInput"
                  type="text"
                  class="text_box"
                  :disabled="isLockDaiwari"
                />
                <span v-show="!showInput">{{ manualSyosiData.hakkojo }}</span>
              </td>
            </tr>
          </table>
          <!-- <div class="table_wrapper_flex page_next"> -->
          <!-- 改ページ無 -->
          <div
            class="table_wrapper_flex"
            v-if="
              !(isCustom && !customOutputList.seisakuKihon.irokazu.isSelected)
            "
          >
            <table
              class="daiwari-table daiwari-table_bottom daiwari-table_bottom_left table_fixed"
              style="border-bottom: 1px solid black"
              :style="{ '--koumokuNum': oriInfoArray.length + 1 }"
            >
              <!-- 折情報テーブル -->
              <tr>
                <th v-show="!isLockDaiwari" class="print_display_none">操作</th>
                <th colspan="4">色数</th>
                <th colspan="4">用紙</th>
                <th colspan="4">加工</th>
              </tr>
              <tr v-for="(ori, index) in oriInfoArray" :key="index">
                <th v-show="!isLockDaiwari" class="print_display_none">
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    v-if="ori.canEdit && !isLockDaiwari"
                    @click="editOriInfo(index)"
                    class="pointer_item"
                  />
                </th>
                <th colspan="2">{{ hankaku(ori.meisyo) }}色数</th>
                <td colspan="2">
                  <input
                    type="text"
                    v-show="showInput"
                    class="text_box"
                    :value="ori.sikisuu"
                    @input="updateSikisuu($event, index)"
                    :disabled="isLockDaiwari"
                  />
                  <span v-show="!showInput">{{ ori.sikisuu }}</span>
                </td>
                <th colspan="2">{{ hankaku(ori.meisyo) }}用紙</th>
                <td
                  class="bg_yellow"
                  v-show="!isLockDaiwari"
                  colspan="2"
                  @dblclick="showModal('modal_output_youshi', index)"
                >
                  {{ ori.yousi !== "" ? hankaku(ori.yousi) : "" }}
                </td>
                <td colspan="2" v-show="isLockDaiwari">
                  {{ ori.yousi !== "" ? hankaku(ori.yousi) : "" }}
                </td>
                <th colspan="2">{{ hankaku(ori.meisyo) }}加工</th>
                <td colspan="2">
                  <input
                    type="text"
                    class="text_box"
                    v-show="showInput"
                    :value="ori.kakou"
                    @input="updateKakou($event, index)"
                    :disabled="isLockDaiwari"
                  />
                  <span v-show="!showInput">{{ hankaku(ori.kakou) }}</span>
                </td>
              </tr>
            </table>
            <!-- 備考テーブル -->
            <table
              class="daiwari-table daiwari-table_bottom_right"
              style="border-bottom: 1px solid black; border-left: none"
              :style="{ '--koumokuNum': oriInfoArray.length + 1 }"
            >
              <tr>
                <th>備考</th>
                <td v-show="showInput">
                  <textarea
                    class="text_box_bikou"
                    v-model="manualSyosiData.bikou"
                    :disabled="isLockDaiwari"
                  ></textarea>
                </td>
                <td v-show="!showInput" style="vertical-align: top">
                  <span class="text_box_bikou_print">{{
                    manualSyosiData.bikou
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
          <button
            class="add_info_button"
            @click="add_oriInfo"
            v-show="!isLockDaiwari"
          >
            <font-awesome-icon
              :icon="['fas', 'plus']"
              class="add_info_icon"
            />項目追加
          </button>
        </div>
      </div>
      <!-- 改ページ無 -->
      <p
        class="sakuhinInfo_title"
        :class="isOnlyDaiwari ? 'print_display_none' : ''"
      >
        2.作品内容情報
      </p>
      <!-- カラー設定 -->
      <MultiSelectBar
        :selectList="selectBarList"
        :title="'カラー設定'"
        @selectEvent="switchColorSetting"
      />
      <!-- 無線綴じ -->
      <DaiwariFormattingLeft
        v-if="props_daiwariData.ori_array.length && !isNakatojiFlg"
        :props_daiwariData="props_daiwariData"
        :oriMaximumPage="oriMaximumPage"
        :printSize="printSize"
        :class="sheet"
        :isCompact="isCompact"
        :props_selectBarList="selectBarList"
        v-model:page_range_bikou_array="vm_page_range_bikou_array"
        :isOnlyDaiwari="isOnlyDaiwari"
        :isCustom="isCustom"
        :customOutputList="customOutputList.sakuhinNaiyou"
        @updateDetailOriArray="updateDetailOriArray"
        @updateDetailSakuhinArray="updateDetailSakuhinArray"
      />
      <!-- 中綴じ -->
      <DaiwariFormattingNakatoji
        v-if="props_daiwariData.ori_array.length && isNakatojiFlg"
        :props_daiwariData="props_daiwariData"
        :oriMaximumPage="oriMaximumPage"
        :printSize="printSize"
        :class="sheet"
        :isCompact="isCompact"
        :props_selectBarList="selectBarList"
        :page_range_bikou_array="page_range_bikou_array"
        :isOnlyDaiwari="isOnlyDaiwari"
        :isCustom="isCustom"
        :customOutputList="customOutputList.sakuhinNaiyou"
        @updateDetailOriArray="updateDetailOriArray"
        @updateDetailSakuhinArray="updateDetailSakuhinArray"
      />
      <PageBikouTable
        v-if="
          !isOnlyDaiwari &&
          !(isCustom && !customOutputList.syoryaku.pageBiko.isSelected)
        "
        :printSize="printSize"
        :props_daiwariData="props_daiwariData"
      />
      <PageRangeBikouTable
        v-if="
          !isCompact &&
          !isOnlyDaiwari &&
          !(isCustom && !customOutputList.syoryaku.pageRange.isSelected)
        "
        :printSize="printSize"
        :props_daiwariData="props_daiwariData"
        :page_range_bikou_array="page_range_bikou_array"
        :isCompact="isCompact"
      />
      <DetailOriTable
        v-if="
          detailOriArray.length &&
          props_daiwariData.ori_array.length &&
          !isOnlyDaiwari
        "
        :printSize="printSize"
        :props_daiwariData="props_daiwariData"
        :detailOriArray="detailOriArray"
      />
      <DetailSakuhinTable
        v-if="
          detailSakuhinArray.length &&
          props_daiwariData.ori_array.length &&
          !isCompact &&
          !isOnlyDaiwari &&
          !(isCustom && !customOutputList.syoryaku.sakuhin.isSelected)
        "
        :printSize="printSize"
        :props_daiwariData="props_daiwariData"
        :props_detailSakuhinArray="detailSakuhinArray"
        :isCompact="isCompact"
      />
    </div>
  </div>
</template>

<script>
import DaiwariFormattingLeft from "../DaiwariOutput/DaiwariFormattingLeft.vue";
import DaiwariFormattingNakatoji from "../DaiwariOutput/DaiwariFormattingNakatoji.vue";
import PageBikouTable from "../DaiwariOutput/PageBikouTable.vue";
import PageRangeBikouTable from "../DaiwariOutput/PageRangeBikouTable.vue";
import ModalEditOriInfo from "../Modal/ModalEditOriInfo.vue";
import ModalTorihikisaki from "../Modal/ModalTorihikisaki.vue";
import ModalSearchYoushi from "../Modal/ModalSearchYoushi.vue";
import DetailOriTable from "../DaiwariOutput/DetailOriTable.vue";
import DetailSakuhinTable from "../DaiwariOutput/DetailSakuhinTable.vue";
import { zenkakuToHankaku } from "@/daiwariJS/StringConvertion.js";
import MultiSelectBar from "../DaiwariOutput/MultiSelectBar";
import vClickOutside from "click-outside-vue3";
import { useVModel } from "vue-composable";

export default {
  name: "DaiwariOutput",
  components: {
    DaiwariFormattingLeft,
    PageBikouTable,
    PageRangeBikouTable,
    ModalEditOriInfo,
    ModalTorihikisaki,
    ModalSearchYoushi,
    DetailOriTable,
    DetailSakuhinTable,
    DaiwariFormattingNakatoji,
    MultiSelectBar,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    props_daiwariData: Object,
    daiwariId: Number,
    kikakuId: Number,
    oriMaximumPage: Number,
    isLockDaiwari: Boolean,
    props_syosiData: Object,
    url: String,
    mst_yoto: Array,
    mst_yosi: Array,
    isNakatojiFlg: Boolean,
    setDaiwariVersionInfo: Function,
    page_range_bikou_array: Array,
    currentRowDaiwariData: String,
    latestKakuteiRowDaiwariData: String,
    updateCurrentRowDaiwariData: Function,
    hasDaiwariKakuteiChanges: { type: Boolean, default: false },
    prevSeisakuBikou: String,
    hasKakuteiRireki: { type: Boolean, default: false },
  },
  emits: ["hideOverlay", "saveDaiwari"],
  setup(props) {
    const vm_page_range_bikou_array = useVModel(
      props,
      "page_range_bikou_array"
    );
    return {
      vm_page_range_bikou_array,
    };
  },
  data() {
    return {
      // shouldHide:true,//中綴じ確認用
      showTableFlg: false,
      formatOriArray: [],
      oriInfoArray: [
        { meisyo: "カバー", sikisuu: "", yousi: "", kakou: "", canEdit: false },
        { meisyo: "オビ", sikisuu: "", yousi: "", kakou: "", canEdit: false },
        { meisyo: "表紙", sikisuu: "", yousi: "", kakou: "", canEdit: false },
        { meisyo: "口絵", sikisuu: "", yousi: "", kakou: "", canEdit: false },
        { meisyo: "本文", sikisuu: "", yousi: "", kakou: "", canEdit: false },
      ],
      syosiData: this.props_syosiData,
      manualSyosiData: this.props_syosiData.manualSyosiData,
      bikou: "",
      daiwariTotalPage: 0,
      daiwariTotalPage_4C: 0,
      daiwariTotalPage_1C: 0,
      modalName: "",
      editOriIndex: "",
      editOriMeisyo: "",
      kosinDate: "",
      torihikisaki_design: "",
      torihikisaki_hensyu: "",
      //単行本はA4,雑誌はA3・B4で設定(予定)
      printSize: "A4",
      showInput: true,
      detailOriArray: [],
      detailSakuhinArray: [],
      target_oriInfoArray: null,
      isOpen: false,
      selectBarList: [
        //カラー設定
        { label: "進行ステータス", selected: true },
        { label: "ページ備考", selected: true },
        { label: "ページ範囲設定", selected: true },
      ],
      // 出力設定関連--------------------------
      selectOutputList: [
        { label: "フル", selected: true },
        { label: "コンパクト", selected: false },
        { label: "台割のみ", selected: false },
        { label: "カスタム", selected: false },
      ],
      isCompact: false, // コンパクト出力
      isOnlyDaiwari: false, // 台割のみ出力
      isCustom: false, // カスタム出力
      customOutputList: {
        seisakuKihon: {
          label: "制作基本情報",
          syousai: {
            label: "詳細情報",
            isSelected: true,
          },
          irokazu: {
            label: "色数/用紙/加工",
            isSelected: true,
          },
        },
        sakuhinNaiyou: {
          label: "作品内容情報",
          irokazu: {
            label: "色数",
            isSelected: true,
          },
          yousi: {
            label: "用紙",
            isSelected: true,
          },
          kakou: {
            label: "加工区分",
            isSelected: true,
          },
          tantou: {
            label: "担当者",
            isSelected: true,
          },
          sakuhinBiko: {
            label: "作品備考情報",
            isSelected: true,
          },
        },
        syoryaku: {
          label: "省略情報",
          pageBiko: {
            label: "ページ備考情報",
            isSelected: false,
          },
          pageRange: {
            label: "ページ範囲備考情報",
            isSelected: false,
          },
          sakuhin: {
            label: "作品省略情報",
            isSelected: false,
          },
        },
      },
      isShowcustomOutputList: true, // カスタムが選択されたときにfalseになり、初期表示されないようになる
      // 印刷ボタン非活性関連------------
      canCopy: true,
      isShowTooltip: false,
      // -------------------------------
    };
  },
  created() {
    window.addEventListener("afterprint", this.afterprint);
    this.set_printSize();
    this.set_oriInfo();
    this.set_shosiData();
    this.set_totalPage_withoutHyousi();
    this.set_totalPage_sikisuu_withoutHyousi();
    this.setDaiwariVersionInfo();
    // this.set_canCopy();
  },
  methods: {
    //モーダル・オーバーレイの表示非表示-----------------
    showModal(modalName, oriIndex = null) {
      this.modalName = modalName;
      if (oriIndex !== null) {
        this.target_oriInfoArray = this.oriInfoArray[oriIndex];
      }
    },
    hideModal(shouldEditOri, newKbnMei) {
      if (this.modalName === "modalEditOriInfo" && shouldEditOri) {
        this.oriInfoArray[this.editOriIndex].meisyo = newKbnMei;
      }
      this.modalName = "";
    },
    hideOverlay() {
      //手入力項目の上書き
      this.manualSyosiData.oriInfoArray = this.oriInfoArray;
      this.syosiData.manualSyosiData = this.manualSyosiData;
      this.$emit("hideOverlay", "overlay_daiwariOutput", this.syosiData);
    },
    //保存-------------------------------------
    saveDaiwari() {
      this.$emit("saveDaiwari");
    },
    //アコーディオン----------------------------
    // openAcordion() {
    //   this.isOpen = !this.isOpen;
    // },
    hide_acordion() {
      this.isOpen = false;
    },
    //制作基本情報の各項目---------------------------

    toggle_showTableFlg() {
      //制作基本情報詳細デーブルの表示切替
      this.showTableFlg = !this.showTableFlg;
    },
    set_shosiData() {
      //発行人と発行所の自動セット(一度入力後に再度空にした場合のみ適用)
      if (this.manualSyosiData.hakkoNin === "野内雅宏") {
        this.manualSyosiData.hakkoNin = "";
      }
      if (this.manualSyosiData.hakkojo === "株式会社一迅社") {
        this.manualSyosiData.hakkojo = "";
      }
    },
    set_oriInfo() {
      //保存済みの折情報があれば上書きする
      if (this.manualSyosiData.oriInfoArray.length) {
        this.oriInfoArray = this.manualSyosiData.oriInfoArray;
      }
      //表紙・口絵・本文の折情報を割り当てる
      this.props_daiwariData.ori_array.forEach((ori) => {
        if (ori.ori_kbn === "表紙") {
          this.oriInfoArray[2].sikisuu = ori.sikisuu;
          this.oriInfoArray[2].yousi = ori.yousi;
          if (ori.kakou_array.length) {
            this.oriInfoArray[2].kakou = "";
            ori.kakou_array.forEach((kakou, index) => {
              if (index === ori.kakou_array.length - 1) {
                this.oriInfoArray[2].kakou += kakou.kbn;
              } else {
                this.oriInfoArray[2].kakou += kakou.kbn + "、";
              }
            });
          }
        } else if (ori.ori_kbn === "口絵") {
          this.oriInfoArray[3].sikisuu = ori.sikisuu;
          this.oriInfoArray[3].yousi = ori.yousi;
          if (ori.kakou_array.length) {
            this.oriInfoArray[3].kakou = "";
            ori.kakou_array.forEach((kakou, index) => {
              if (index === ori.kakou_array.length - 1) {
                this.oriInfoArray[3].kakou += kakou.kbn;
              } else {
                this.oriInfoArray[3].kakou += kakou.kbn + "、";
              }
            });
          }
        } else if (ori.ori_kbn === "本文") {
          this.oriInfoArray[4].sikisuu = ori.sikisuu;
          this.oriInfoArray[4].yousi = ori.yousi;
          if (ori.kakou_array.length) {
            this.oriInfoArray[4].kakou = "";
            ori.kakou_array.forEach((kakou, index) => {
              if (index === ori.kakou_array.length - 1) {
                this.oriInfoArray[4].kakou += kakou.kbn;
              } else {
                this.oriInfoArray[4].kakou += kakou.kbn + "、";
              }
            });
          }
        }
      });
    },
    set_totalPage_withoutHyousi() {
      //総ページ数の算出(表紙以外)
      let totalPage = 0;
      this.props_daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (!sakuhin.toggleHyousiFlg) {
            totalPage =
              totalPage + sakuhin.sakuhin_address_computed_array.length;
          }
        });
      });
      this.daiwariTotalPage = totalPage;
    },
    set_totalPage_sikisuu_withoutHyousi() {
      //4C・1Cの各総ページ数の算出(表紙以外)
      let totalPage_1C = 0;
      let totalPage_4C = 0;
      this.props_daiwariData.ori_array.forEach((ori) => {
        if (ori.sikisuu === "1C" || ori.sikisuu === "2C") {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (!sakuhin.toggleHyousiFlg) {
              totalPage_1C =
                totalPage_1C + sakuhin.sakuhin_address_computed_array.length;
            }
          });
        } else if (
          ori.sikisuu !== "0C" &&
          ori.sikisuu !== "1C" &&
          ori.sikisuu !== "2C"
        ) {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (!sakuhin.toggleHyousiFlg) {
              totalPage_4C =
                totalPage_4C + sakuhin.sakuhin_address_computed_array.length;
            }
          });
        }
      });
      this.daiwariTotalPage_1C = totalPage_1C;
      this.daiwariTotalPage_4C = totalPage_4C;
    },
    set_canCopy() {
      if (this.latestKakuteiRowDaiwariData !== "") {
        // 最新の確定台割と開いている台割を比較し、変更があったら印刷ボタンをdisabledにする
        let currentDaiwariData = JSON.parse(this.currentRowDaiwariData);
        let kakuteiDaiwariData = JSON.parse(this.latestKakuteiRowDaiwariData);

        // 台割比較時に比較不要なプロパティを削除する
        currentDaiwariData.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
            // sakuhin_uniqueIdは保存の度に変わってしまうため比較対象から外す
            delete sakuhin.sakuhin_uniqueId;
          });
        });
        // ↓（仮）制作基本情報関連→比較対象にするか否か先方確認中
        // 対象なら出力画面で編集した内容と比べる必要あり
        delete currentDaiwariData.outputSyosiData.kosinDate;
        delete currentDaiwariData.outputSyosiData.kosinDateStr;
        delete currentDaiwariData.outputSyosiData.kosinUserMei;
        // 台割を開いた際に最新のバージョン情報をセットするので、台割の内容は変わってなくてもDBとは異なっている
        delete currentDaiwariData.outputSyosiData.manualSyosiData.versionInfo;

        kakuteiDaiwariData.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
            delete sakuhin.sakuhin_uniqueId;
          });
        });
        delete kakuteiDaiwariData.outputSyosiData.kosinDate;
        delete kakuteiDaiwariData.outputSyosiData.kosinDateStr;
        delete kakuteiDaiwariData.outputSyosiData.kosinUserMei;
        delete kakuteiDaiwariData.outputSyosiData.manualSyosiData.versionInfo;

        // デバッグ用
        // console.log(
        //   JSON.stringify(currentDaiwariData.ori_array[6].sakuhin_array[0]) ===
        //     JSON.stringify(kakuteiDaiwariData.ori_array[6].sakuhin_array[0])
        // );
        // console.log(currentDaiwariData.ori_array[6]);
        // console.log(kakuteiDaiwariData.ori_array[6]);

        const JSON_currentDaiwari = JSON.stringify(currentDaiwariData);
        const JSON_kakuteiDaiwari = JSON.stringify(kakuteiDaiwariData);
        this.canCopy = JSON_currentDaiwari === JSON_kakuteiDaiwari;
      }
    },
    add_oriInfo() {
      let newOriInfo = {
        meisyo: "",
        sikisuu: "",
        yousi: "",
        kakou: "",
        canEdit: true,
      };
      this.oriInfoArray.push(newOriInfo);
    },
    deleteOriInfo() {
      const confirm = window.confirm(`項目を削除してもよろしいですか?`);
      if (confirm === true) {
        this.oriInfoArray.splice(this.editOriIndex, 1);
        this.modalName = "";
      }
    },
    editOriInfo(index) {
      this.modalName = "modalEditOriInfo";
      this.editOriIndex = index;
      this.editOriMeisyo = this.oriInfoArray[this.editOriIndex].meisyo;
    },
    updateTorihikisaki(selected_torihikisaki) {
      if (selected_torihikisaki !== "") {
        selected_torihikisaki = selected_torihikisaki.torihikisakiMei;
      }
      if (this.modalName === "modal_design") {
        //取引先(デザイン)の更新
        this.manualSyosiData.torihikisaki_design = selected_torihikisaki;
      } else if (this.modalName === "modal_hensyu") {
        //取引先(外編・編プロ)の更新
        this.manualSyosiData.torihikisaki_hensyu = selected_torihikisaki;
      }
    },
    updateYoushi(newVal) {
      this.target_oriInfoArray.yousi = newVal;
    },
    removeYoushi() {
      this.target_oriInfoArray.yousi = "";
    },
    updateSikisuu(event, index) {
      this.oriInfoArray[index].sikisuu = event.target.value;
    },
    updateKakou(event, index) {
      this.oriInfoArray[index].kakou = event.target.value;
    },

    //印刷系---------------------------------------
    switchColorSetting(selected, colorIndex) {
      //カラー設定
      this.selectBarList[colorIndex].selected = selected;
    },
    // 出力設定の変更
    switchOutputSetting(selected, outputIndex) {
      // 一旦全てリセット
      this.selectOutputList.forEach((select) => (select.selected = false));
      this.isCompact = false;
      this.isOnlyDaiwari = false;
      this.isCustom = false;
      this.selectOutputList[3].label = "カスタム";
      // 項目セット
      const target = this.selectOutputList[outputIndex];
      target.selected = true;
      if (target.label === "コンパクト") {
        this.isCompact = true;
        // カスタムチェックリストの表示を初期値に戻す
        this.isShowcustomOutputList = true;
      } else if (target.label === "台割のみ") {
        this.isOnlyDaiwari = true;
        this.isShowcustomOutputList = true;
      } else if (target.label === "カスタム" || target.label === "カスタム▼") {
        target.label = "カスタム▼";
        this.isCustom = true;
        this.isShowcustomOutputList = !this.isShowcustomOutputList;
      } else {
        this.isShowcustomOutputList = true;
      }
    },
    closeShowcustomOutputList() {
      this.isShowcustomOutputList = false;
    },
    printDaiwari() {
      this.showTableFlg = true;
      this.showInput = false;

      let fileName =
        this.syosiData.childDaiwariName !== null
          ? `【${this.syosiData.childDaiwariName}】${this.syosiData.title}`
          : this.syosiData.title;
      const fileNameLimit = 32;
      if (fileName.length > fileNameLimit) {
        const fileNameLimitHead = 25;
        const fileNameLimitLast = 7;
        fileName = `${fileName.substr(0, fileNameLimitHead)}~${fileName.substr(
          -fileNameLimitLast,
          fileNameLimitLast
        )}`;
      }
      const version =
        this.manualSyosiData.versionInfo !== null
          ? `ver.${this.manualSyosiData.versionInfo}`
          : "";
      document.title = `台割表『${fileName}』${version}`;

      //印刷プレビュー時全てのinput→textに変換(改行用)
      this.$nextTick(() => {
        window.print();
      });
    },

    // printDaiwariFull() {
    //   //フルバージョンで印刷
    //   this.isCompact = false;
    //   this.printDaiwari();
    // },

    // printDaiwariCompact() {
    //   //コンパクトバージョンで印刷
    //   this.isCompact = true;
    //   this.printDaiwari();
    // },
    afterprint() {
      //印刷プレビュー閉じたら全てのtext→inputに戻す
      this.showInput = true;
      document.title = "台割作成｜出版こねくと";
    },
    set_printSize() {
      //印刷サイズの設定
      if (this.syosiData.isZassi !== null) {
        if (this.syosiData.isZassi) {
          //雑誌ならA3.B4(ひとまずA4で設定)
          this.printSize = "A3";
        } else {
          //雑誌以外ならA4
          this.printSize = "A4";
        }
      }
    },

    //テーブル
    //折省略テーブル用の配列
    updateDetailOriArray(detailOriArray) {
      this.detailOriArray = detailOriArray;
    },
    //作品省略テーブル用の配列
    updateDetailSakuhinArray(detailSakuhinArray) {
      this.detailSakuhinArray = detailSakuhinArray;
    },
  },
  watch: {
    oriInfoArray: {
      handler: function () {
        this.manualSyosiData.oriInfoArray = this.oriInfoArray;
        this.syosiData.manualSyosiData = this.manualSyosiData;
        this.updateCurrentRowDaiwariData(this.syosiData);
      },
      deep: true,
    },
    manualSyosiData: {
      handler: function () {
        this.manualSyosiData.oriInfoArray = this.oriInfoArray;
        this.syosiData.manualSyosiData = this.manualSyosiData;
        this.updateCurrentRowDaiwariData(this.syosiData);
      },
      deep: true,
    },
    currentRowDaiwariData: {
      handler: function () {
        this.set_canCopy();
      },
      deep: true,
    },
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
    kotei_top() {
      if (this.printSize === "B4") {
        return "kotei_top_" + this.printSize;
      } else {
        return "kotei_top";
      }
    },
    table_head() {
      return "table_head_" + this.printSize;
    },
    hankaku() {
      return (character) => {
        const character_hankaku = zenkakuToHankaku(character);
        return character_hankaku;
      };
    },
    cancopy() {
      if (!this.hasKakuteiRireki) {
        return true;
      } else if (
        this.hasDaiwariKakuteiChanges ||
        this.manualSyosiData.bikou != this.prevSeisakuBikou
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  :root {
    --koumokuNum: 0;
  }
  /* オーバーレイのレイアウト */
  #overlay_daiwariOutput {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
  }
  .overlay_content {
    width: 100%;
    height: calc(100% - 270px);
    overflow-y: scroll;
    padding: 0 0px 10px 10px;
    &_message {
      font-size: 18px;
      padding: 10px;
    }
  }
  .overlay_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background: #f7f7f7;
    &Title {
      font-weight: 700;
      font-size: 18px;
      color: rgb(37, 37, 37);
    }
    &Text {
      display: inline-block;
      padding: 0 15px;
      font-weight: normal;
      font-size: 16px;
      color: rgb(37, 37, 37);
    }
  }
  .overlay_header > p {
    margin-right: auto;
  }
  .overlay__close {
    font-size: 25px;
    color: black;
  }

  /*印刷のアコーディオン*/
  .acordion {
    &_group {
      position: relative;
      > button {
        margin-left: 0px;
      }
      .disable_button_tooltip {
        position: absolute;
        .balloon-002 {
          position: relative;
          text-align: center;
          padding: 2px;
          width: 200px;
          margin-top: 10px;
          border-radius: 5px;
          background-color: #f88181;
          color: #fff;
          font-weight: 600;
          font-size: 11px;
        }

        .balloon-002::before {
          position: absolute;
          top: -9px;
          width: 20px;
          height: 10px;
          left: 5px;
          background-color: #f88181;
          clip-path: polygon(50% 0, 0 100%, 100% 100%);
          content: "";
        }
      }
      .v-enter-active,
      .v-leave-active {
        transition: opacity 0.5s ease;
      }
      .v-enter-from,
      .v-leave-to {
        opacity: 0;
      }
    }
    &_area {
      min-width: 80px;
      background: #fff;
      color: #ec7211;
      position: absolute;
      left: 0px;
    }
    &_list {
      list-style: none;
      cursor: pointer;
      li {
        text-align: left;
        font-size: 11px;
        border: 1px solid #ec7211;
        padding: 0px 4px;
        width: 180px;
        &:nth-child(2) {
          border-top: none;
        }
      }
    }
    &_item {
      background: #fff;
      &:hover {
        background: #ec72118f;
        color: #fff;
      }
    }
  }
  /*ボタン */
  .btn_container {
    display: flex;
  }
  /* テーブル*/
  .contents {
    &_wrapper {
      &_head {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .table_wrapper {
    width: 100%;
    &_flex {
      display: flex;
      align-items: stretch;
      width: 100%;
      height: 100%;
    }
  }
  .list_daiwari-table_head {
    width: 1000px;
    display: flex;
    gap: 20px;
    margin: 10px 20px;
    align-items: stretch;
  }

  // テーブル
  .daiwari-table {
    table-layout: fixed;
    width: 100%;
    border: 1px solid black;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
    font-size: 10px;
    tr {
      border-top: 1px solid black;
    }
    th {
      border-right: 1px solid black;
      background-color: #ddd;
      padding: 0px 5px;
      text-align: center;
      vertical-align: middle;
      height: 22px;
      font-size: 10px;
    }
    td {
      border-right: 1px solid black;
      // min-width: 100px;
      height: 10px;
      background-color: #fff;
      text-align: center;
      vertical-align: middle;
      font-size: 10px;
    }
    td.bg_yellow {
      background: #ffffcc;
    }
    //詳細情報より上の開閉しないテーブル(スクロール時固定)
    &_top {
      position: sticky;
      z-index: 999;
      top: 0;
      table-layout: fixed;
      width: 100%;
      tr {
        width: 100%;
      }
      th {
        min-width: 145px;
      }
    }
    &_head {
      table-layout: fixed;
    }

    // テーブル内のinput調整
    input,
    select {
      min-height: 20px;
    }
  }

  .daiwari-table_bottom {
    height: calc(22px * var(--koumokuNum));
    th,
    td {
      height: 22px;
    }
    &_left {
      width: 75%;
    }
    &_right {
      width: 25%;
      th {
        width: 20%;
      }
      td {
        width: 80%;
      }
    }
  }
  .bordet-bottom,
  .daiwari-table_middle,
  .table_detail {
    border-bottom: 1px solid black;
    margin-bottom: -1px;
  }

  //テキストエリア
  .text_box {
    width: 90%;
    height: 85%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;

    &_bikou {
      width: 90%;
      height: calc(20px * var(--koumokuNum));
      resize: none;
      &_print {
        white-space: pre-wrap;
        display: block;
        text-align: left;
      }
    }
  }
  .table_span {
    display: block;
    line-height: 110%;
    padding: 2px 0px;
  }
  //セレクトボックス
  .select_container {
    display: inline;
    margin-right: 10px;
  }

  .select_box {
    width: 80%;
    height: 80%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
  }
  .select_box_printSize {
    width: 80px;
    padding: 5px;
  }
  //ボタン
  .add_info_button {
    border: 1px solid #ec7211;
    background: #fff;
    color: #ec7211;
    width: 120px;
    font-size: 16px;
    margin-top: 5px;
  }

  .add_info_icon {
    display: inline-block;
    padding-right: 5px;
  }
  .table_fixed {
    table-layout: fixed;
    width: 100%;
  }
  .pointer_item {
    cursor: pointer;
  }
  //フォント
  .text_small {
    font-size: 10px;
  }

  .kotei_top {
    padding: 0 10px 0 10px;
  }

  .overlay_content::-webkit-scrollbar {
    width: 10px;
  }
  .overlay_content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .overlay_content::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }
  .span_print {
    display: none;
    line-height: initial;
  }
  .span_default {
    display: block;
  }

  .print_titleArea {
    display: none;
  }
  .sakuhinInfo_title {
    display: inline-block;
    margin-top: 20px;
  }
  .disable_button {
    box-shadow: none;
    background: rgb(150, 150, 150);
    cursor: default;
  }

  // カスタム出力チェックボックス用-------------------------
  .output_container {
    padding-top: 10px;
    .output_custom {
      position: relative;

      &_checkarea {
        position: absolute;
        top: 0;
        left: 335px;
        z-index: 1000;

        width: 300px;
        height: auto;
        padding: 10px;
        border: 2px solid #f48c36;
        border-radius: 3%;
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
        .description {
          padding: 5px;
          font-size: 13px;
          font-weight: 600;
          border-bottom: 1px solid rgb(167, 167, 167);
        }
        // チェックボックス
        .ks-cboxtags {
          list-style: none;
          padding: 5px;
          p {
            font-size: 13px;
            color: rgb(74, 74, 74);
          }
          .list_flex {
            display: flex;
            flex-wrap: wrap;
            gap: 2px;
            li {
              display: inline;
              input[type="checkbox"] {
                display: absolute;
                position: absolute;
                opacity: 0;
              }
              input[type="checkbox"]:checked + label {
                border: 2px solid #ff9d33;
                background-color: #ed881c;
                color: #fff;
                transition: all 0.2s;
              }
              label {
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.9);
                border: 2px solid rgba(139, 139, 139, 0.3);
                color: #adadad;
                border-radius: 25px;
                white-space: nowrap;
                // margin: 3px 0px;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: transparent;
                transition: all 0.2s;
                font-size: 12px;

                padding: 1px 6px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  //印刷用css----------------------------------------------
  @media print {
    #overlay_daiwariOutput {
      //staticにしないと2ページ移行の印刷プレビューが表示されないため
      position: static;
      -webkit-print-color-adjust: exact;
    }
    .overlay_header {
      background: none;
      padding: 0px;
    }
    .sheet_A4 {
      display: block;
      width: 1066px;
    }
    .sheet_A3 {
      display: block;
      width: 1200px;
    }
    .overlay_content::-webkit-scrollbar {
      display: none;
      overflow: visible;
    }
    button,
    #MultiSelectBar,
    .output_custom {
      display: none;
    }
    .overlay_content {
      padding: 0 10px;
    }
    .print_titleArea {
      display: block;
      width: 100%;
      margin: 20px;
      text-align: center;
      > span {
        margin: 0 5px;
      }
    }
    /*テーブル */
    .daiwari-table {
      tr {
        width: 100%;
      }
      th {
        padding: 0px;
      }
      td.bg_yellow {
        background: #fff;
      }
      //詳細情報より上の開閉しないテーブル
      &_top {
        table-layout: fixed;
        tr {
          width: 100%;
        }
        th {
          width: 6.66%;
        }
        td {
          width: 10%;
        }
        td.td_sakuhin_mei {
          width: 59.98%;
        }
      }
      &_middle {
        th {
          width: 6.66%;
        }
        th.th_detail {
          width: auto;
        }
      }
    }
    .daiwari-table_bottom {
      &_right {
        height: calc(22px + 25px * var(--koumokuNum-1));
        tr {
          height: 100%;
        }
        th {
          height: 100%;
        }
        td {
          height: 100%;
          text-align: center;
          line-height: 15px;
        }
      }
    }
    .table_detail {
      tr {
        width: 100%;
      }
      th {
        width: 6.66%;
      }
      td {
        width: 10%;
      }
    }
    .list_daiwari-table_head {
      margin: 10px 0px 10px 10px;
    }
    .table_head_A4 {
      width: 1065px;
    }
    .table_head_A3 {
      width: 1190px;
    }
    .table_head_B4 {
      width: 100%;
      padding-right: 10px;
    }
    .kotei_top {
      padding: 0px;
      margin: 0px 0px 0px 10px;
    }
    .kotei_top_B4 {
      margin: 0px 10px 0px 10px;
    }
    //操作項目を非表示にする
    .print_display_none {
      display: none;
    }
    .span_print {
      display: block;
      line-height: 110%;
    }
    .span_default {
      display: none;
    }
    .text_small_print {
      font-size: 10px;
    }
  }
}
</style>
