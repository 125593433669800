<template>
  <div
    class="SideBar"
    :class="{
      SideBar_60px: !sidebarToggle,
      SideBar_200px: sidebarToggle,
      SideBar_None: displayNoneFlg,
    }"
  >
    <font-awesome-icon
      @click="toggleSideBar"
      class="SideBar_Toggle_Icon"
      icon="bars"
    />
    <!-- width:180px(SideBar_200px) -->
    <ul v-if="sidebarToggle" class="SideBar_200px_List">
      <!-- 親要素1 -->
      <li
        @click="selectDashboard"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveDashboard }"
      >
        <span class="SideBar_200px_Menu_Icon">
          <font-awesome-icon
            class="SideBar_200px_Menu_Icon_Style"
            icon="home"
          />
        </span>
        ホーム
      </li>
      <!-- 親要素2 -->
      <li
        v-if="!isKodansha"
        @click="selectOrder"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveOrder }"
      >
        <div @click="toggleOrderContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="pencil-alt"
            />
          </span>

          受注入力
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="this.orderContentsFlg">
          <template v-for="(contents, index) in order_list" :key="index">
            <!-- 子要素1 -->
            <li
              v-if="contents.name == '通常受注'"
              @click="selectOrderList(contents)"
              class="SideBar_200px_SubMenu"
            >
              <div @click="toggleNormalCart">
                <span class="SideBar_200px_SubMenu_Title">{{
                  contents.name
                }}</span>
                <font-awesome-icon
                  class="SideBar_200px_DropDown"
                  icon="angle-down"
                />
              </div>
              <!-- 孫要素1 -->
              <ul v-if="this.normalCartFlg" class="SideBar_200px_SubMenu_List">
                <li
                  v-for="(list, index) in orderNomal_list"
                  :key="index"
                  class="SideBar_200px_SubMenu_Content"
                  :class="{
                    SideBar_200px_SubMenu_Content_isSubSelected: list.selectFlg,
                  }"
                  @click="decideRouter(list)"
                >
                  {{ list.name }}
                </li>
              </ul>
            </li>
            <!-- 子要素2 -->
            <li
              v-else-if="contents.name == 'マルチ受注'"
              class="SideBar_200px_SubMenu"
              @click="selectOrderList(contents)"
            >
              <div @click="toggleMultiCart">
                <span class="SideBar_200px_SubMenu_Title">{{
                  contents.name
                }}</span>
                <font-awesome-icon
                  class="SideBar_200px_DropDown"
                  icon="angle-down"
                />
              </div>
              <!-- 孫要素2 -->
              <ul v-if="this.multiCartFlg" class="SideBar_200px_SubMenu_List">
                <li
                  v-for="(list, index) in orderMulti_list"
                  :key="index"
                  class="SideBar_200px_SubMenu_Content"
                  :class="{
                    SideBar_200px_SubMenu_Content_isSubSelected: list.selectFlg,
                  }"
                  @click="decideRouter(list)"
                >
                  {{ list.name }}
                </li>
              </ul>
            </li>
            <!-- 子要素3~ -->
            <li
              v-else
              @click="selectOrderList(contents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected: contents.selectFlg,
                }"
                >{{ contents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素3 -->
      <li
        v-if="!isKodansha"
        @click="selectProductReference"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveProductReference }"
      >
        <span class="SideBar_200px_Menu_Icon">
          <font-awesome-icon
            class="SideBar_200px_Menu_Icon_Style"
            icon="search"
          />
        </span>
        在庫照会
      </li>
      <!-- 親要素4 -->
      <li
        v-if="!isKodansha"
        @click="selectSalesHistory"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveSalesHistory }"
      >
        <div @click="toggleHistoryContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="truck"
            />
          </span>
          <span class="SideBar_200px_Menu_Title">販売履歴</span>
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="historyContentsFlg">
          <template
            v-for="(historyContents, historyIndex) in history_list"
            :key="historyIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectHistoryList(historyContents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected:
                    historyContents.selectFlg,
                }"
                >{{ historyContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素5 -->
      <li
        v-if="!isKodansha"
        @click="selectSlipList"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveSlipReference }"
      >
        <div @click="toggleSlipContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="file-invoice"
            />
          </span>
          伝票照会
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="this.slipReferenceContentsFlg">
          <template
            v-for="(slipContents, slipIndex) in slip_list"
            :key="slipIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectSlipList(slipContents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected:
                    slipContents.selectFlg,
                }"
                >{{ slipContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素6 -->
      <li
        v-if="!isKodansha"
        @click="selectMaster"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveMasterAdmin }"
      >
        <div @click="toggleMasterContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="cog"
            />
          </span>
          製品マスタ
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="this.masterContentsFlg">
          <template
            v-for="(masterContents, masterIndex) in master_list"
            :key="masterIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectMasterList(masterContents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected:
                    masterContents.selectFlg,
                }"
                >{{ masterContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素7 -->
      <!-- <li
        @click="selectProductManageList"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveProductManage }"
      >
        <div @click="toggleProductManageContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="boxes"
            />
          </span>
          在庫管理
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="this.productManageContentsFlg">
          <template
            v-for="(contents, index) in product_manage_list"
            :key="index"
          > -->
      <!-- 子要素1~ -->
      <!-- <li
              @click="selectProductManageList(contents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected: contents.selectFlg,
                }"
                >{{ contents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li> -->
      <!-- 親要素8 -->
      <li
        v-if="isKodansha"
        @click="selectEditList"
        class="SideBar_200px_Menu"
        :class="{ SideBar_200px_Menu_isSelected: isActiveEdit }"
      >
        <div @click="toggleEditContents">
          <span class="SideBar_200px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_200px_Menu_Icon_Style"
              icon="book"
            />
          </span>
          製作
          <font-awesome-icon class="SideBar_200px_DropDown" icon="angle-down" />
        </div>
        <ul v-if="this.editContentsFlg">
          <template
            v-for="(editContents, editIndex) in edit_list"
            :key="editIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectEditList(editContents)"
              class="SideBar_200px_SubMenu"
            >
              <span
                class="SideBar_200px_SubMenu_Title"
                :class="{
                  SideBar_200px_SubMenu_Title_isSelected:
                    editContents.selectFlg,
                }"
                >{{ editContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
    </ul>

    <!-- width:60px -->
    <ul v-else class="SideBar_60px_List" v-click-outside="contentsOutsideClick">
      <li
        @click="selectDashboard"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveDashboard }"
      >
        <span class="SideBar_60px_Menu_Icon">
          <font-awesome-icon class="SideBar_60px_Menu_Icon_Style" icon="home" />
        </span>
        <span class="SideBar_60px_Menu_Title">ホーム</span>
      </li>
      <li
        v-if="!isKodansha"
        @click="selectOrder"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveOrder }"
      >
        <div @click="toggleOrderContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="pencil-alt"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">受注入力</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="this.orderContentsFlg">
          <template v-for="(contents, index) in order_list" :key="index">
            <li
              v-if="contents.name == '通常受注'"
              @click="selectOrderList(contents)"
              class="SideBar_60px_SubMenu"
            >
              <div class="SideBar_60px_DropDown" @click="toggleNormalCart">
                <span class="SideBar_60px_SubMenu_Title">{{
                  contents.name
                }}</span>
                <font-awesome-icon
                  class="SideBar_60px_DropDown_Icon"
                  icon="angle-down"
                />
              </div>
              <ul v-if="this.normalCartFlg" class="SideBar_60px_SubMenu_List">
                <li
                  v-for="(list, index) in orderNomal_list"
                  :key="index"
                  class="SideBar_60px_SubMenu_Content"
                  :class="{
                    SideBar_60px_SubMenu_Content_isSubSelected: list.selectFlg,
                  }"
                  @click="decideRouter(list)"
                >
                  {{ list.name }}
                </li>
              </ul>
            </li>
            <li
              v-else-if="contents.name == 'マルチ受注'"
              class="SideBar_60px_SubMenu"
              @click="selectOrderList(contents)"
            >
              <div class="SideBar_60px_DropDown" @click="toggleMultiCart">
                <span class="SideBar_60px_SubMenu_Title">{{
                  contents.name
                }}</span>
                <font-awesome-icon
                  class="SideBar_60px_DropDown_Icon"
                  icon="angle-down"
                />
              </div>
              <ul v-if="this.multiCartFlg" class="SideBar_60px_SubMenu_List">
                <li
                  v-for="(list, index) in orderMulti_list"
                  :key="index"
                  class="SideBar_60px_SubMenu_Content"
                  :class="{
                    SideBar_60px_SubMenu_Content_isSubSelected: list.selectFlg,
                  }"
                  @click="decideRouter(list)"
                >
                  {{ list.name }}
                </li>
              </ul>
            </li>
            <li
              v-else
              @click="selectOrderList(contents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected: contents.selectFlg,
                }"
                >{{ contents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素3 -->
      <li
        v-if="!isKodansha"
        @click="selectProductReference"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveProductReference }"
      >
        <span class="SideBar_60px_Menu_Icon">
          <font-awesome-icon
            class="SideBar_60px_Menu_Icon_Style"
            icon="search"
          />
        </span>
        <span class="SideBar_60px_Menu_Title">在庫照会</span>
      </li>
      <!-- 親要素4 -->
      <li
        v-if="!isKodansha"
        @click="selectSalesHistory"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveSalesHistory }"
      >
        <div @click="toggleHistoryContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="truck"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">販売履歴</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="historyContentsFlg">
          <template
            v-for="(historyContents, historyIndex) in history_list"
            :key="historyIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectHistoryList(historyContents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected:
                    historyContents.selectFlg,
                }"
                >{{ historyContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素5 -->
      <li
        v-if="!isKodansha"
        @click="selectMaster"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveSlipReference }"
      >
        <div @click="toggleSlipContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="file-invoice"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">伝票照会</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="this.slipReferenceContentsFlg">
          <template
            v-for="(slipContents, slipIndex) in slip_list"
            :key="slipIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectSlipList(slipContents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected: slipContents.selectFlg,
                }"
                >{{ slipContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素6 -->
      <li
        v-if="!isKodansha"
        @click="selectMaster"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveMasterAdmin }"
      >
        <div @click="toggleMasterContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="cog"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">製品マスタ</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="this.masterContentsFlg">
          <template
            v-for="(masterContents, masterIndex) in master_list"
            :key="masterIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectMasterList(masterContents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected:
                    masterContents.selectFlg,
                }"
                >{{ masterContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
      <!-- 親要素7 -->
      <!-- <li
        @click="selectProductManageList"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveProductManage }"
      >
        <div @click="toggleProductManageContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="boxes"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">在庫管理</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="this.productManageContentsFlg">
          <template
            v-for="(contents, index) in product_manage_list"
            :key="index"
          > -->
      <!-- 子要素1~ -->
      <!-- <li
              @click="selectProductManageList(contents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected: contents.selectFlg,
                }"
                >{{ contents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li> -->
      <!-- 親要素8 -->
      <li
        v-if="isKodansha"
        @click="selectEditList"
        class="SideBar_60px_Menu"
        :class="{ SideBar_60px_Menu_isSelected: isActiveEdit }"
      >
        <div @click="toggleEditContents">
          <span class="SideBar_60px_Menu_Icon">
            <font-awesome-icon
              class="SideBar_60px_Menu_Icon_Style"
              icon="book"
            />
          </span>
          <span class="SideBar_60px_Menu_Title">製作</span>
        </div>
        <ul class="SideBar_60px_OrderList" v-if="this.editContentsFlg">
          <template
            v-for="(editContents, editIndex) in edit_list"
            :key="editIndex"
          >
            <!-- 子要素1~ -->
            <li
              @click="selectEditList(editContents)"
              class="SideBar_60px_SubMenu"
            >
              <span
                class="SideBar_60px_SubMenu_Title"
                :class="{
                  SideBar_60px_SubMenu_Title_isSelected: editContents.selectFlg,
                }"
                >{{ editContents.name }}</span
              >
            </li>
          </template>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  props: {
    sidebarToggle: {
      type: Boolean,
    },
    displayNoneFlg: {
      type: Boolean,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      // 子要素表示制御フラグ
      orderContentsFlg: false, // 受注入力第１子要素の表示制御フラグ
      normalCartFlg: false, // 受注入力第２子要素（受注入力＞通常受注）の表示制御フラグ
      multiCartFlg: false, // 受注入力第２子要素（受注入力＞マルチ受注）の表示制御フラグ
      historyContentsFlg: false, // 販売履歴子要素の表示制御フラグ
      masterContentsFlg: false, // 製品マスタ子要素の表示制御フラグ
      slipReferenceContentsFlg: false, // 伝票照会子要素の表示制御フラグ
      productManageContentsFlg: false, // 在庫管理子要素の表示制御フラグ
      editContentsFlg: false, // 編集子要素の表示制御フラグ

      // 選択中制御フラグ
      isActiveOrder: false, //受注入力アイコンの選択中フラグ、サイドナビのメニューが増えたらobj型で管理する
      isActiveDashboard: false, //ホームアイコンの選択中フラグ、サイドナビのメニューが増えたらobj型で管理する
      isActiveProductReference: false, // 在庫照会アイコンの選択中フラグ
      isActiveSalesHistory: false, // 販売履歴アイコンの選択中フラグ
      isActiveMasterAdmin: false, // 製品マスタアイコンの選択中フラグ
      isActiveSlipReference: false, // 伝票照会アイコンの選択中フラグ
      isActiveProductManage: false, // 在庫管理アイコンの選択中フラグ
      isActiveEdit: false, // 編集アイコンの選択中フラグ

      // 各サイドバーコンテンツ内容
      orderNomal_list: [
        { name: "通常入力", url: "/orderSearch", selectFlg: false },
        { name: "カート照会", url: "/cartNormal", selectFlg: false },
      ],
      orderMulti_list: [
        { name: "マルチ入力", url: "/orderFormMulti", selectFlg: false },
        { name: "マルチカート照会", url: "/cartMulti", selectFlg: false },
      ],
      order_list: [
        { name: "通常受注" },
        { name: "マルチ受注" },
        { name: "受注照会", url: "/orderConfirm", selectFlg: false },
        { name: "受注保留解除", url: "/orderHold", selectFlg: false },
        { name: "調整", url: "/orderAdjustment", selectFlg: false },
        { name: "注文リスト作成", url: "/createList", selectFlg: false },
        { name: "事前注文リスト作成", url: "/preOrderList", selectFlg: false },
      ],
      history_list: [
        { name: "出庫履歴", url: "/shippingHistory", selectFlg: false },
        { name: "品切保留", url: "/orderOutOfStock", selectFlg: false },
      ],
      master_list: [
        { name: "商品ステータス", url: "/editProductStatus", selectFlg: false },
      ],
      slip_list: [
        { name: "納品伝票", url: "/deliverySlip", selectFlg: false },
        { name: "返品伝票", url: "/returnSlip", selectFlg: false },
      ],
      product_manage_list: [
        {
          name: "在庫シミュレーション",
          url: "/productSimulation",
          selectFlg: false,
        },
        { name: "改装指示", url: "/", selectFlg: false },
      ],
      edit_list: [
        { name: "企画", url: "/kikaku", selectFlg: false },
        { name: "台割", url: "/daiwari", selectFlg: false },
      ],
    };
  },
  methods: {
    // ログイン時に選択情報をリセットし、ホームを初期値とする
    setInitialData() {
      Object.assign(this.$data, this.$options.data());
      this.isActiveDashboard = true;
    },
    //width:60pxの際にはclick-outsideイベントを発火
    contentsOutsideClick() {
      this.orderContentsFlg = false;
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
    },
    //GlobalHeaderのイベントと連動
    isActiveToggleFlg() {
      //タイミングがひとつずれるのでtrueのときに発火
      if (this.sidebarToggle) {
        this.orderContentsFlg = false;
      }
    },
    toggleSideBar() {
      this.$emit("toggleSideBar");
    },

    // ダッシュボード関連-----------------------------------------------------------
    //ダッシュボードの選択
    selectDashboard() {
      this.isActiveDashboard = true;
      if (this.isActiveDashboard) {
        //それ以外をリセット
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();
        this.$router.push("/dashboard");
      }
    },
    // 受注入力関連----------------------------------------------------------------
    //受注入力の選択
    selectOrder() {
      // this.isActiveOrder = true;
      // if (this.isActiveOrder) {
      //   //それ以外をリセット
      //   this.isActiveDashboard = false;
      //   this.isActiveProductReference = false;
      //   this.isActiveSalesHistory = false;
      //   this.isActiveMasterAdmin = false;
      //   this.resetMasterList();
      // }
    },
    //受注入力要素の開閉
    toggleOrderContents() {
      this.orderContentsFlg = !this.orderContentsFlg;
      // その他の要素を閉じる
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.productManageContentsFlg = false;
      this.editContentsFlg = false;
    },
    //通常受注要素の開閉
    toggleNormalCart() {
      this.normalCartFlg = !this.normalCartFlg;
      // if (!this.normalCartFlg) {
      //   this.resetOrderNormalList();
      // } else {
      //   this.resetOrderMultiList();
      // }
      this.multiCartFlg = false;
    },
    //マルチ受注要素の開閉
    toggleMultiCart() {
      this.multiCartFlg = !this.multiCartFlg;
      // if (!this.multiCartFlg) {
      //   this.resetOrderMultiList();
      // } else {
      //   this.resetOrderNormalList();
      // }
      this.normalCartFlg = false;
    },
    //通常・マルチ受注以外の要素の選択
    selectOrderList(contents) {
      if (contents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();
      }
      if (contents.url) {
        //urlがあれば遷移
        // 一旦各項目をリセット
        this.isActiveDashboard = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        // 選択部分アクティブ化
        this.isActiveOrder = true;
        contents.selectFlg = true;

        this.$router.push(contents.url);
      }
    },
    //通常・マルチ受注内の孫要素の選択
    decideRouter(list) {
      this.isActiveDashboard = false;
      this.isActiveProductReference = false;
      this.isActiveSalesHistory = false;
      this.isActiveMasterAdmin = false;
      this.isActiveSlipReference = false;
      this.isActiveProductManage = false;
      this.isActiveEdit = false;
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.productManageContentsFlg = false;
      this.editContentsFlg = false;
      this.resetOrderNormalList();
      this.resetOrderMultiList();
      this.resetOrderList();
      this.resetHistoryList();
      this.resetMasterList();
      this.resetSlipList();
      this.resetProductManageList();
      this.resetEditList();

      this.isActiveOrder = true;
      list.selectFlg = true;
      if (list.name === "通常入力" || list.name === "カート照会") {
        this.normalCartFlg = true;
      } else if (
        list.name === "マルチ入力" ||
        list.name === "マルチカート照会"
      ) {
        this.multiCartFlg = true;
      }
      this.$router.push(list.url);
      this.contentsOutsideClick();
    },
    //通常受注リセット用
    resetOrderNormalList() {
      this.orderNomal_list.forEach((list) => {
        list.selectFlg = false;
      });
    },
    //マルチ受注カートリセット用
    resetOrderMultiList() {
      this.orderMulti_list.forEach((list) => {
        list.selectFlg = false;
      });
    },
    //受注入力内リセット用
    resetOrderList() {
      this.order_list.forEach((order) => {
        if (order.selectFlg) {
          order.selectFlg = false;
        }
      });
    },

    // 在庫照会関連--------------------------------------------------
    // 在庫照会の選択
    selectProductReference() {
      this.isActiveProductReference = true;
      if (this.isActiveProductReference) {
        //それ以外をリセット
        this.isActiveOrder = false;
        this.isActiveDashboard = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.contentsOutsideClick();
        this.resetEditList();
        this.$router.push("/productReference");
      }
    },

    //販売履歴関連---------------------------------------------------
    //販売履歴検索(親)を選択
    selectSalesHistory() {
      // this.isActiveSalesHistory = true;
      // if (this.isActiveSalesHistory) {
      //   //それ以外をリセット
      //   this.isActiveOrder = false;
      //   this.isActiveDashboard = false;
      //   this.orderContentsFlg = false;
      //   this.isActiveProductReference = false;
      //   this.resetOrderMultiList();
      //   this.resetOrderNormalList();
      //   this.resetOrderList();
      //   this.masterContentsFlg = false;
      //   this.isActiveMasterAdmin = false;
      //   this.resetMasterList();
      //   this.normalCartFlg = false;
      //   this.multiCartFlg = false;
      //   // this.contentsOutsideClick();
      // }
    },
    //子要素の表示
    toggleHistoryContents() {
      this.historyContentsFlg = !this.historyContentsFlg;
      // その他の要素を閉じる
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.masterContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.productManageContentsFlg = false;
      this.editContentsFlg = false;
    },
    // 要素の選択
    selectHistoryList(contents) {
      if (contents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();

        this.isActiveDashboard = false;
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        this.isActiveSalesHistory = true;
        contents.selectFlg = true;
        //urlがあれば遷移
        this.$router.push(contents.url);
      }
    },
    // 要素のセレクトフラグリセット
    resetHistoryList() {
      this.history_list.forEach((list) => {
        list.selectFlg = false;
      });
    },
    // 製品管理マスタ関連---------------------------------------------
    //選択
    selectMaster() {
      // this.isActiveMasterAdmin = true;
      // if (this.isActiveMasterAdmin) {
      //   //それ以外をリセット
      //   this.isActiveOrder = false;
      //   this.isActiveDashboard = false;
      //   this.isActiveProductReference = false;
      //   this.isActiveSalesHistory = false;
      //   this.historyContentsFlg = false;
      //   this.resetOrderMultiList();
      //   this.resetOrderNormalList();
      //   this.resetOrderList();
      //   // this.contentsOutsideClick();
      // }
    },
    //要素の開閉
    toggleMasterContents() {
      this.masterContentsFlg = !this.masterContentsFlg;
      // その他の要素を閉じる
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.historyContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.productManageContentsFlg = false;
      this.editContentsFlg = false;
    },
    // 要素の選択
    selectMasterList(masterContents) {
      if (masterContents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();

        this.isActiveDashboard = false;
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        this.isActiveMasterAdmin = true;
        masterContents.selectFlg = true;
        //urlがあれば遷移
        this.$router.push(masterContents.url);
      }
    },
    // 要素のセレクトフラグリセット
    resetMasterList() {
      this.master_list.forEach((list) => {
        list.selectFlg = false;
      });
    },

    // 伝票照会関連--------------------------------------------------
    //要素の開閉
    toggleSlipContents() {
      this.slipReferenceContentsFlg = !this.slipReferenceContentsFlg;
      // その他の要素を閉じる
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
      this.productManageContentsFlg = false;
      this.editContentsFlg = false;
    },
    // 要素の選択
    selectSlipList(slipContents) {
      if (slipContents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();

        this.isActiveDashboard = false;
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        this.isActiveSlipReference = true;
        slipContents.selectFlg = true;
        //urlがあれば遷移
        this.$router.push(slipContents.url);
      }
    },
    // 要素のセレクトフラグリセット
    resetSlipList() {
      this.slip_list.forEach((list) => {
        list.selectFlg = false;
      });
    },

    // 在庫管理関連--------------------------------------------------
    //要素の開閉
    toggleProductManageContents() {
      this.productManageContentsFlg = !this.productManageContentsFlg;
      // その他の要素を閉じる
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.editContentsFlg = false;
    },
    // 要素の選択
    selectProductManageList(contents) {
      if (contents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();

        this.isActiveDashboard = false;
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        this.isActiveProductManage = true;
        contents.selectFlg = true;
        //urlがあれば遷移
        this.$router.push(contents.url);
      }
    },
    // 要素のセレクトフラグリセット
    resetProductManageList() {
      this.product_manage_list.forEach((list) => {
        list.selectFlg = false;
      });
    },
    // 編集関連--------------------------------------------------
    //要素の開閉
    toggleEditContents() {
      this.editContentsFlg = !this.editContentsFlg;
      // その他の要素を閉じる
      this.orderContentsFlg = false;
      this.normalCartFlg = false;
      this.multiCartFlg = false;
      this.historyContentsFlg = false;
      this.masterContentsFlg = false;
      this.slipReferenceContentsFlg = false;
      this.productManageContentsFlg = false;
    },
    // 要素の選択
    selectEditList(editContents) {
      if (editContents.url) {
        //選択するまで現在いる場所を保持
        this.contentsOutsideClick();

        this.isActiveDashboard = false;
        this.isActiveOrder = false;
        this.isActiveProductReference = false;
        this.isActiveSalesHistory = false;
        this.isActiveMasterAdmin = false;
        this.isActiveSlipReference = false;
        this.isActiveProductManage = false;
        this.isActiveEdit = false;
        this.orderContentsFlg = false;
        this.normalCartFlg = false;
        this.multiCartFlg = false;
        this.historyContentsFlg = false;
        this.masterContentsFlg = false;
        this.slipReferenceContentsFlg = false;
        this.productManageContentsFlg = false;
        this.editContentsFlg = false;
        this.resetOrderNormalList();
        this.resetOrderMultiList();
        this.resetOrderList();
        this.resetHistoryList();
        this.resetMasterList();
        this.resetSlipList();
        this.resetProductManageList();
        this.resetEditList();

        this.isActiveEdit = true;
        editContents.selectFlg = true;
        //urlがあれば遷移
        this.$router.push(editContents.url);
      }
    },
    // 要素のセレクトフラグリセット
    resetEditList() {
      this.edit_list.forEach((list) => {
        list.selectFlg = false;
      });
    },
  },
  computed: {
    // 講談社用表示制御
    // 1. computed で、Storeから版元コードを取得する
    getUserData() {
      return this.$store.getters["login/getUserData"];
    },
    // 2. Storeから取得した版元コードで台割の表示判定を行う
    isKodansha() {
      const res = this.getUserData;
      return res.hanmotoCd == "2253";
    },
  },
};
</script>

<style lang="scss" scoped>
//メニュー ホバー時の使いまわし用
.item_hover {
  background: #ffffff2d;
  color: #fff;
  text-decoration: none;
}
.SideBar {
  position: relative;

  &_Toggle_Icon {
    @media screen and (min-width: 1025px) {
      cursor: pointer;
      position: absolute;
      color: #fff;
      font-size: 35px;
      top: 10px;
      right: 15px;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &_200px {
    font-size: 13px;
    position: fixed;
    top: 50px;
    left: 0;
    width: 180px;
    height: 100vh;
    background-color: #2a3f54;
    @media screen and (max-width: 1024px) {
      z-index: 900;
    }

    &_Menu {
      cursor: pointer;
      position: relative;
      line-height: 40px;

      &_isSelected {
        background: #20c997;
        box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
          inset rgb(255 255 255 / 16%) 0 1px 0;
        & .SideBar_200px_Menu_Icon_Style {
          background-color: rgb(0 0 0 / 10%);
        }
        &:hover {
          @extend .SideBar_200px_Menu_isSelected;
        }
      }

      &:hover {
        @extend .item_hover;
      }

      &_Icon {
        display: inline-block;
        vertical-align: middle;
        height: 35px;
        line-height: 35px;
        margin-left: 10px;
        margin-right: 3px;

        &_Style {
          background-color: #dddddd3d;
          padding: 7px;
          border-radius: 5px;
          height: 15px;
          line-height: 15px;
        }
      }
    }

    &_SubMenu {
      cursor: pointer;
      position: relative;
      background-color: #37495b;

      &_Title {
        display: inline-block;
        padding-left: 45px;
        width: 100%;
        height: 100%;
        &_isSelected {
          background: #20c997;
          box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
            inset rgb(255 255 255 / 16%) 0 1px 0;
          &:hover {
            @extend .SideBar_200px_Menu_isSelected;
          }
        }
        &:hover {
          @extend .item_hover;
        }
      }

      &_List {
        background-color: #505f6f;
        border-left: 5px solid #20c997;
      }

      &_Content {
        padding-left: 45px;

        &_isSubSelected {
          background: #3b8d80;
          box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
            inset rgb(255 255 255 / 16%) 0 1px 0;
        }

        &:hover {
          background: #ffffff2d;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    &_DropDown {
      display: inline-block;
      position: absolute;
      top: 13px;
      right: 25px;
    }
    &_List {
      margin-top: 50px;
      color: #fff;
      // margin-left: 5px;
    }
  }
}

.SideBar {
  &_60px {
    position: fixed;
    top: 50px;
    left: 0;
    width: 60px;
    height: 100vh;
    background-color: #2a3f54;
    z-index: 999; //ここを設定しないと子要素がメイン領域のうしろにいってしまう。
    @media screen and (min-width: 1025px) {
      left: 0;
      width: 60px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }

    &_Menu {
      cursor: pointer;
      position: relative;
      padding: 5px 0;
      text-align: center;
      &_isSelected {
        background: #20c997;
        box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
          inset rgb(255 255 255 / 16%) 0 1px 0;
        & .SideBar_60px_Menu_Icon_Style {
          background-color: rgb(0 0 0 / 10%);
        }
        &:hover {
          @extend .SideBar_200px_Menu_isSelected;
        }
      }
      &:hover {
        @extend .item_hover;
      }

      &_Title {
        display: inline-block;
        font-size: 10px;
        padding-top: 5px;
      }

      &_Icon {
        display: block;
        height: 40px;
        line-height: 40px;

        &_Style {
          background-color: #dddddd3d;
          padding: 8px;
          border-radius: 5px;
          height: 25px;
          line-height: 25px;
        }
      }
    }

    &_OrderList {
      position: absolute;
      width: 150px;
      top: 0;
      right: -150px;
    }

    &_SubMenu {
      cursor: pointer;
      text-align: left;
      background-color: #37495b;

      &_Title {
        display: inline-block;
        padding: 4px 0;
        font-size: 13px;
        padding-left: 15px;
        width: 100%;
        height: 100%;

        &_isSelected {
          background: #20c997;
          box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
            inset rgb(255 255 255 / 16%) 0 1px 0;
          &:hover {
            @extend .SideBar_200px_Menu_isSelected;
          }
        }
        &:hover {
          @extend .item_hover;
        }
      }

      &_List {
        background-color: #505f6f;
        border-left: 5px solid #20c997;
      }

      &_Content {
        padding: 3px 0;
        font-size: 12px;
        padding-left: 20px;

        &_isSubSelected {
          background: #3b8d80;
          box-shadow: rgb(0 0 0 / 25%) 0 1px 0,
            inset rgb(255 255 255 / 16%) 0 1px 0;
        }

        &:hover {
          background: #ffffff2d;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    &_DropDown {
      display: flex;
      position: relative;

      &_Icon {
        display: inline-block;
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 13px;
      }
    }

    &_List {
      margin-top: 50px;
      color: #fff;
    }
  }
}

.SideBar {
  &_None {
    display: none;
  }
}
</style>
