<template>
  <!-- 用途:yoto/用紙:yosi -->
  <div id="overlay" @click="closeModal">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">用紙検索</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="closeModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <div class="modal__review__search-container">
          <select
            class="search_box"
            @change="selectYoto"
            :value="selectedYotoMei"
          >
            <option v-for="yoto in mst_yoto" :key="yoto.cd" :value="yoto.cdMei">
              {{ yoto.cdMei }}
            </option>
          </select>
          <input
            type="text"
            class="search_box"
            v-model="searchWord"
            @keydown.enter="searchYosi"
          />
          <button class="btn_gray" @click="searchYosi">検索</button>
        </div>
        <p class="modal__review__search-result">
          検索結果({{ searched_yosi.length }}件)
        </p>
        <p class="modal__review__search-result" v-show="searchResult_nullFlg">
          検索結果はありません
        </p>
        <ul
          class="modal__review__result-container"
          v-show="!searched_yosi.length && !searchResult_nullFlg"
        >
          <li
            class="modal__review__result-name"
            v-for="yosi in mst_yosi"
            :key="yosi.id"
            @click="selectYosi(yosi)"
          >
            {{ yosi.yosiMei }}
            <span class="modal__review__result-yoto"
              >/ 主な用途:{{ yosi.yotoMei }}</span
            >
          </li>
        </ul>
        <ul
          class="modal__review__result-container"
          v-show="searched_yosi.length || searchResult_nullFlg"
        >
          <li
            class="modal__review__result-name"
            v-for="yosi in searched_yosi"
            :key="yosi.id"
            @click="selectYosi(yosi)"
          >
            {{ yosi.yosiMei }}
            <span class="modal__review__result-yoto"
              >/ 主な用途:{{ yosi.yotoMei }}</span
            >
          </li>
        </ul>

        <ul class="modal__review__free-input">
          <li>
            <input
              class="search_box"
              type="text"
              v-model="freeInput.yosiMei"
            /><button class="btn_gray" @click="selectYosi(freeInput)">
              自由入力
            </button>
          </li>
        </ul>

        <ul class="modal__review__badge-container">
          <li
            class="modal__review__badge-selected"
            v-for="(yosi, index) in selectedYosi"
            :key="yosi.id"
          >
            <span class="modal__review__badge-name">{{ yosi.yosiMei }}</span>
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="modal__review__badge-cancel"
              @click="cancelYosi(index)"
            />
          </li>
        </ul>

        <button class="btn_blue" @click="updateYosi">決定</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    console.log(this.selectedOri.yousi);
    //用紙設定済みの折であればbadgeを初期表示する
    let hasMstYosi = false;
    this.mst_yosi.forEach((yosi) => {
      if (yosi.yosiMei == this.selectedOri.yousi) {
        this.selectedYosi.push(yosi);
        hasMstYosi = true;
      }
    });
    if (hasMstYosi == false && this.selectedOri.yousi != "") {
      this.selectedYosi.push({
        id: -1,
        yosiMei: this.selectedOri.yousi,
        yotoCd: null,
        yotoMei: null,
      });
    }
    this.searchYosi();
  },
  props: {
    selectedOri: Object,
    url: String,
    mst_yoto: Array,
    mst_yosi: Array,
  },
  emits: ["hideModal", "count_timeOut", "updateYoushi", "removeYoushi"],
  data() {
    return {
      selectedYosi: [],
      mst_full_search: [],
      searched_yosi: [],
      searchWord: "",
      searchResult_nullFlg: false, //検索結果が0の場合でも全件表示にしない
      selectedYotoMei: "全て",
      selectedYotoCd: null,
      freeInput: {
        id: -1,
        yosiMei: "",
        yotoCd: null,
        yotoMei: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("hideModal", "modal_yosi");
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    //用紙選択
    selectYosi(clickYosi) {
      if (clickYosi.yosiMei === "") {
        return;
      } else {
        console.log(clickYosi);
        this.selectedYosi = [];
        this.selectedYosi.push({
          id: clickYosi.id,
          yosiMei: clickYosi.yosiMei,
        });
      }
    },
    //用紙選択取り消し
    cancelYosi(removeIndex) {
      this.selectedYosi.splice(removeIndex, 1);
    },
    //用紙情報更新
    updateYosi() {
      if (this.selectedYosi.length) {
        this.$emit("updateYoushi", this.selectedYosi[0].yosiMei);
        this.$emit("hideModal", "modal_yosi");
      } else {
        this.$emit("removeYoushi");
        this.$emit("hideModal", "modal_yosi");
      }
    },
    //用途選択
    selectYoto(e) {
      const newYoto = this.mst_yoto.find(
        (yoto) => yoto.cdMei == e.target.value
      );
      this.selectedYotoCd = newYoto.cd;
      this.selectedYotoMei = e.target.value;
      this.searchYosi();
    },
    //用途検索
    searchYosi() {
      this.searched_yosi = [];
      this.searchResult_nullFlg = false;
      this.$nextTick(() => {
        if (this.selectedYotoCd !== null && this.selectedYotoCd !== 999) {
          axios
            .get(
              `${this.url}/daiwari/get_yosi_findby?yotoCd=${this.selectedYotoCd}&yosi_mei=${this.searchWord}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            )
            .then((response) => {
              if (response.data.length) {
                this.searched_yosi = response.data;
              } else {
                this.searchResult_nullFlg = true;
              }
            })
            .catch((error) => {
              alert("用紙データが取得できませんでした\n" + error);
            });
        } else {
          if (this.searchWord !== "") {
            this.searched_yosi = JSON.parse(JSON.stringify(this.mst_yosi));
            this.mst_full_search = this.searched_yosi.filter((yosi) =>
              yosi.yosiMei.match(this.searchWord)
            );
            this.searched_yosi = this.mst_full_search;
            if (!this.searched_yosi.length) {
              this.searchResult_nullFlg = true;
            }
          } else {
            this.searched_yosi = JSON.parse(JSON.stringify(this.mst_yosi));
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 60%;
    height: 80%;
    &__header {
      height: 10%;
    }
    &__close {
      color: #fff;
    }
    &__review {
      &__free-input {
        li {
          list-style: none;
        }
        input {
          margin-right: 5px;
          width: 300px;
        }
      }
    }
  }

  .btn_gray {
    padding: 5px;
  }
}
</style>
