<template>
  <div id="overlay_daiwariFistory">
    <div class="overlay_header">
      <p class="overlay_headerTitle">
        台割複写照会
        <span class="overlay_headerText">
          <span class="daiwari_title" v-show="this.referenceTitle"
            >『{{ this.referenceTitle }}』</span
          >
          <span v-show="this.daiwariTorokuDate"
            >/登録日時:{{ this.daiwariTorokuDate }}</span
          >
        </span>
      </p>
      <div class="overlay_headerBtn" v-click-outside="closeShowCustom">
        <div class="btn_container">
          <button
            v-if="canCopy()"
            @click="switchShowCustom"
            class="btn_hukugen"
          >
            複写設定 ▼
          </button>
          <button v-else class="btn_hukugen_disabled">複写</button>
          <button @click="hideOverlay" class="btn_back">複写一覧に戻る</button>
        </div>
        <p v-if="!canCopy()" class="hukugen_error">
          複写先とレイアウトが異なるため複写できません
        </p>
        <!-- 複写設定 -->
        <div class="overlay_headerCustom" v-if="isShowCustom">
          <p class="description">複写したい項目を選択してください</p>
          <ul
            class="ks-cboxtags"
            v-for="(listVal, listKey) in customCopyList"
            :key="listKey"
          >
            <p>＜{{ listVal.label }}＞</p>
            <div class="list_flex">
              <template v-for="(contentVal, contentKey) in listVal">
                <li v-if="contentKey !== 'label'" :key="contentKey">
                  <input
                    type="checkbox"
                    :id="`checkbox_${listKey}_${contentKey}`"
                    v-model="contentVal.isSelected"
                  /><label :for="`checkbox_${listKey}_${contentKey}`">{{
                    contentVal.label
                  }}</label>
                </li>
              </template>
            </div>
          </ul>
          <div class="copy_btn">
            <button @click="restoreDaiwari">複写</button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isEmptyDaiwari" class="overlay_content">
      <div v-for="(ori, index) in ori_array" :key="index" class="row">
        <!-- 折コンポーネントに置き換え -->
        <OriComponents
          :ori="ori"
          :isReferencePage="true"
          @toggleOriBikouFlg="toggleOriBikouFlg"
        />
        <!-- 作品コンポーネントに置き換え -->
        <div class="sakuhin_area">
          <template
            v-for="sakuhin in ori.sakuhin_array"
            :key="sakuhin.sakuhin_address"
          >
            <SakuhinComponents
              :sakuhin="sakuhin"
              :includesPages_doNotCount="includesPages_doNotCount"
              :oriMaximumPage="oriMaximumPage"
              :daiwariAddressArray="daiwariAddressArray"
              :sakuhin_page_bikou_array="sakuhin_page_bikou_array"
              :page_range_bikou_array="page_range_bikou_array"
              :previewPageRangeData="previewPageRangeData"
              @toggleSakuhinBikouFlg="toggleSakuhinBikouFlg"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="overlay_content" v-show="isEmptyDaiwari">
      <p class="overlay_content_message">台割情報がありません</p>
    </div>
  </div>
</template>

<script>
import OriComponents from "../Ori.vue";
import SakuhinComponents from "../Sakuhin.vue";
import vClickOutside from "click-outside-vue3";
import axios from "axios";

export default {
  name: "DaiwariHistory",
  components: {
    OriComponents,
    SakuhinComponents,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    overlayId: Number,
    kikakuId: Number,
    referenceTitle: String,
    daiwariTorokuDate: String,
    previewPageRangeData: Object,
    copyFromData: Object,
    copyToData: Object,
    url: String,
    isDensi: Boolean,
  },
  emits: [
    "hideOverlay",
    "hideModal",
    "setUseKariFlg",
    "copyDaiwari",
    "toggleOriBikouFlg",
    "toggleSakuhinBikouFlg",
    "",
  ],
  data() {
    return {
      ori_array: [],
      daiwariId: this.overlayId,
      includesPages_doNotCount: false,
      oriMaximumPage: null,
      target_daiwari: null,
      isEmptyDaiwari: false,
      daiwariAddressArray: null,
      sakuhin_page_bikou_array: null,
      page_range_bikou_array: null,
      isShowCustom: false,
      customCopyList: {
        seisakuKihon: {
          label: "制作基本情報",
          syousai: {
            label: "詳細情報",
            isSelected: true,
          },
          irokazu: {
            label: "色数/用紙/加工",
            isSelected: true,
          },
        },
        daiwari: {
          label: "台割",
          sakuhinMei: {
            label: "作品名",
            isSelected: true,
          },
          penname: {
            label: "ペンネーム",
            isSelected: true,
          },
          tantou: {
            label: "担当者",
            isSelected: true,
          },
          pageBikou: {
            label: "ページ備考",
            isSelected: true,
          },
          pageRangeBikou: {
            label: "ページ範囲備考",
            isSelected: true,
          },
          sakuhinBikou: {
            label: "作品備考",
            isSelected: true,
          },
        },
      },
    };
  },
  created() {
    // //台割複写情報の取得
    // 環境ごとのAPI呼び出しドメインの決定
    // const baseUrl = location.origin.replace("8081", "8080");
    // 台割データを取得------------------------------------------------------------
    if (this.overlayId && this.kikakuId) {
      axios
        .get(
          `${this.url}/daiwari/?daiwariId=${this.overlayId}&kikakuId=${this.kikakuId}&isEdaban=${this.copyFromData.isChild}&isFukusya=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          // daiwariDataがnullだったら初期値をセット
          if (response.data.daiwariData == null) {
            response.data.daiwariData = `{"ori_array":[]}`;
          }
          this.isEmptyDaiwari = false;
          let target_daiwari = JSON.parse(response.data.daiwariData);
          this.includesPages_doNotCount =
            target_daiwari.includesPages_doNotCount;
          this.oriMaximumPage = target_daiwari.oriMaximumPage;
          this.daiwariAddressArray = target_daiwari.daiwariAddressArray
            ? target_daiwari.daiwariAddressArray
            : [];
          this.sakuhin_page_bikou_array =
            target_daiwari.sakuhin_page_bikou_array
              ? target_daiwari.sakuhin_page_bikou_array
              : [];
          this.page_range_bikou_array = target_daiwari.page_range_bikou_array
            ? target_daiwari.page_range_bikou_array
            : [];
          this.ori_array = [...target_daiwari.ori_array];
          this.target_daiwari = target_daiwari;
          if (!this.ori_array.length) {
            this.isEmptyDaiwari = true;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("複写参照データが取得できませんでした\n" + error);
        });
    } else {
      //kikakuIdまたはdaiwariIdが無い場合、参照画面で通知
      this.isEmptyDaiwari = true;
    }
  },
  methods: {
    hideOverlay() {
      this.$emit("hideOverlay");
    },
    restoreDaiwari() {
      let shouldRestore;
      if (!this.isEmptyDaiwari) {
        shouldRestore = window.confirm(`台割を複写してもよろしいですか?`);
      } else if (
        this.isEmptyDaiwari &&
        this.overlayId !== null &&
        this.kikakuId !== null
      ) {
        //企画ID台割IDがあり、台割の複写自体は可能だが台割情報が無い場合
        shouldRestore = window.confirm(
          `折情報未登録ですが、本当に複写してもよろしいですか?`
        );
      } else if (
        this.isEmptyDaiwari &&
        (this.overlayId === null || this.kikakuId === null)
      ) {
        //企画IDまたは台割IDが無く、台割の複写自体が不可能な場合
        alert("台割情報が不足しているため複写できません");
        shouldRestore = false;
      }
      if (shouldRestore == true) {
        this.$emit("hideOverlay");
        //台割一覧モーダルも閉じる
        this.$emit("hideModal", "modal_daiwariCopy");
        this.$emit("setUseKariFlg");
        this.$emit(
          "copyDaiwari",
          this.target_daiwari,
          this.isDensi,
          null,
          this.customCopyList
        );
      }
    },
    toggleOriBikouFlg(ori, isOpenBikou) {
      this.$emit("toggleOriBikouFlg", ori, isOpenBikou);
    },
    toggleSakuhinBikouFlg(sakuhin, isOpenBikou) {
      this.$emit("toggleSakuhinBikouFlg", sakuhin, isOpenBikou);
    },
    // 複写先と複写元の綴じ方が異なる場合複写不可にする
    canCopy() {
      // 複写元が親：複写先が枝かつ中綴じ
      if (
        !this.copyFromData.isChild &&
        this.copyToData.isChild &&
        this.copyToData.isNakatoji
      ) {
        return false;
      }
      // 複写元が枝かつ無線綴じ：複写先が枝かつ中綴じ
      else if (
        this.copyFromData.isChild &&
        !this.copyFromData.isNakatoji &&
        this.copyToData.isChild &&
        this.copyToData.isNakatoji
      ) {
        return false;
      }
      // 複写元が枝かつ中綴じ：複写先が枝かつ無線綴じ 又は親
      else if (
        this.copyFromData.isChild &&
        this.copyFromData.isNakatoji &&
        ((this.copyToData.isChild && !this.copyToData.isNakatoji) ||
          !this.copyToData.isChild)
      ) {
        return false;
      } else {
        return true;
      }
    },
    switchShowCustom() {
      this.isShowCustom = !this.isShowCustom;
    },
    closeShowCustom() {
      this.isShowCustom = false;
    },
  },
  filters: {
    truncateDispName(value) {
      const ommision = "...";
      let length = 5;
      value = "" + value;
      if (value.length <= length) {
        return value;
      }
      return value.substring(0, length) + ommision;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay_daiwariFistory {
    z-index: 9999;
    position: fixed;
    top: 500;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
  }
  .overlay_content {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    padding: 0 20px 80px 20px;
    &_message {
      font-size: 18px;
      padding: 10px;
    }
  }
  .overlay_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background: #f7f7f7;
    &Title {
      font-weight: 700;
      font-size: 18px;
      color: rgb(37, 37, 37);
    }
    &Text {
      display: inline-block;
      padding: 0 15px;
      font-weight: normal;
      font-size: 16px;
      color: rgb(37, 37, 37);
    }
    &Btn {
      position: relative;
      .btn_container {
        display: flex;
        gap: 5px;
      }
    }
    &Custom {
      position: absolute;
      top: 45px;
      right: 0;
      z-index: 1000;

      width: 300px;
      height: auto;
      padding: 10px;
      border: 2px solid #f48c36;
      border-radius: 3%;
      background: #fff;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
      .description {
        padding: 5px;
        font-size: 13px;
        font-weight: 600;
        border-bottom: 1px solid rgb(167, 167, 167);
      }
      // チェックボックス
      .ks-cboxtags {
        list-style: none;
        padding: 5px;
        p {
          font-size: 13px;
          color: rgb(74, 74, 74);
        }
        .list_flex {
          display: flex;
          flex-wrap: wrap;
          gap: 2px;
          li {
            display: inline;
            input[type="checkbox"] {
              display: absolute;
              position: absolute;
              opacity: 0;
            }
            input[type="checkbox"]:checked + label {
              border: 2px solid #ff9d33;
              background-color: #ed881c;
              color: #fff;
              transition: all 0.2s;
            }
            label {
              display: inline-block;
              background-color: rgba(255, 255, 255, 0.9);
              border: 2px solid rgba(139, 139, 139, 0.3);
              color: #adadad;
              border-radius: 25px;
              white-space: nowrap;
              // margin: 3px 0px;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -webkit-tap-highlight-color: transparent;
              transition: all 0.2s;
              font-size: 12px;

              padding: 1px 6px;
              cursor: pointer;
            }
          }
        }
      }
      .copy_btn {
        text-align: right;
        padding: 5px;
        margin-top: 5px;
        border-top: 1px solid #a7a7a7;
      }
    }
  }
  .overlay__close {
    font-size: 25px;
    color: black;
  }

  .btn_back {
    width: 130px;
    padding: 10px 0;
  }
  .row {
    display: flex;
    margin-top: 20px;
    white-space: nowrap;
  }

  .rireki_ori_area {
    float: left;
    font-size: 10px;
    text-align: center;
    width: 100px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin-right: 50px;
    flex-shrink: 0;
  }

  .sakuhin_address_container {
    display: flex;
  }
  .sakuhin {
    position: relative;
    text-align: center;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  .sakuhin_address {
    width: 80px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  .sakuhin_mei {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sakuhin_mei_blanc {
    opacity: 0;
    display: block;
  }

  .sakuhin_address_border_right {
    border-right: solid 1px rgba(0, 0, 0, 0.2);
    &__blanc {
      border-right: solid 0px rgba(0, 0, 0, 0.2);
      background-color: #97185e;
    }
  }
  .btn_hukugen {
    border: 1px solid #ec7211;
    background: #fff;
    color: #ec7211;
    width: 130px;
    //padding: 10px 0;
    font-size: 16px;

    &_disabled {
      border: 1px solid gray;
      background: gray;
      color: #fff;
      width: 130px;
      //padding: 10px 0;
      font-size: 16px;
    }
  }
  .hukugen_error {
    color: red;
    font-size: 12px;
  }
  .daiwari_title {
    display: inline-block;
    padding-left: 5px;
  }
}
</style>
