<template>
  <div id="overlay" @click="hideModal">
    <div class="modal" @click="stopEvent">
      <!-- モーダル名 -->
      <div class="modal__header">
        <p class="modal__title">{{ modalTitle }}</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <!-- 作品名検索結果 -->
        <div class="modal__review__search-container">
          <input
            type="text"
            class="search_box"
            v-model="searchWord"
            @keydown.enter="search"
          />
          <button class="btn_gray" @click="search">検索</button>
        </div>
        <p
          class="modal__review__search-result"
          v-if="searched_sakuhinMeiList.length !== 0"
        >
          検索結果({{ totalRecords }}件)
        </p>
        <p class="modal__review__search-result" v-if="searchResult_nullFlg">
          検索結果はありません
        </p>

        <!-- 取引先一覧表示 -->
        <ul
          class="modal__review__result-container"
          v-if="searched_sakuhinMeiList"
        >
          <li
            class="modal__review__result-name"
            v-for="(item, index) in searched_sakuhinMeiList"
            :key="index"
            @click="add_sakuhinMei(item)"
          >
            {{ item.sakuhinMei }}
            <span
              v-if="item.sakuhinId < -1"
              class="modal__review__result-karisakuhin"
              >/仮作品</span
            >
          </li>
        </ul>
        <!-- ページング -->
        <Paginate
          class="pagenation"
          v-if="searched_sakuhinMeiList"
          :page-count="totalPage"
          :hide-prev-next="true"
          :page-range="3"
          :margin-pages="1"
          :click-handler="getCurrentPage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-item--link'"
          :active-class="'page-item--active'"
        ></Paginate>
        <!-- 仮作品 -->
        <div class="modal__review__karisakuhin-container">
          <input v-model="useKariSakuhin" id="kari-check" type="checkbox" />
          <label for="kari-check">仮作品を使用</label>
          <input
            v-model="inputKariSakuhin"
            :disabled="!useKariSakuhin"
            type="text"
            class="text_box kari-text"
          />
          <button
            v-if="useKariSakuhin"
            @click="add_newKariSakuhinMei(inputKariSakuhin)"
          >
            選択
          </button>
        </div>

        <!-- 選択済みの取引先を表示 -->
        <ul class="modal__review__badge-container">
          <li
            class="modal__review__badge-selected"
            v-if="selected_sakuhinMei !== ''"
          >
            <span>{{ selected_sakuhinMei }}</span>
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="modal__review__badge-cancel"
              @click="remove_sakuhinMei"
            />
          </li>
        </ul>
        <button class="btn_blue" @click="update_sakuhinMei">決定</button>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";
// 台帳の取引先検索がペンネーム検索に変更
// 仕様確定したら不要なコードは削除する
export default {
  components: { Paginate },
  props: {
    modalName: String,
    selectedSakuhin: Object,
    props_sakuhin: Object,
    changeSakuhinToDaiwari: Function,
    url: String,
    daiwariId: Number,
  },
  emits: [
    "hideModal",
    "count_timeOut",
    "updateSakuhinList",
    "updateSakuhinMeiFromModal",
    "update_sakuhinMei",
  ],
  data() {
    return {
      modalTitle: "作品検索",
      searched_sakuhinMeiList: [],
      selected_sakuhinMei: "",
      selected_sakuhinId: null,
      searchWord: "",
      totalPage: 0,
      currentPage: 1,
      clickedPage: 1,
      showPages: 5,
      searchResult_nullFlg: false,
      isPropsSakuhin: false,
      totalRecords: 0,
      // 仮作品関連
      useKariSakuhin: false,
      inputKariSakuhin: "",
      isNewKariPenname: false,
      params: "",
    };
  },
  created() {
    // パラメータを取得
    this.params = new URL(location.href).searchParams;

    this.selected_sakuhinMei = this.selectedSakuhin.sakuhin_mei
      ? this.selectedSakuhin.sakuhin_mei
      : "";
    this.selected_sakuhinId = this.selectedSakuhin.sakuhin_id
      ? this.selectedSakuhin.sakuhin_id
      : null;
    this.searchWord = this.selectedSakuhin.sakuhin_mei
      ? this.selectedSakuhin.sakuhin_mei
      : "";
    this.getPageInfo();
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_sakuhinMei", this.isPropsSakuhin);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    page_previous() {
      this.currentPage--;
      this.getPageInfo();
    },
    page_next() {
      this.currentPage++;
      this.getPageInfo();
    },
    getPageInfo() {
      axios
        .get(
          `${this.url}/get_sakuhin_split?sakuhin_mei=${this.searchWord}&page=${
            this.currentPage
          }&isUnionKariMst=${
            this.params.get("hachuId") !== null ? false : true
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          //ページングを更新
          this.totalPage = response.data.totalPage;
          this.totalRecords = response.data.totalRecords;
          if (response.data.sakuhinList.length) {
            this.searched_sakuhinMeiList.splice(
              0,
              this.searched_sakuhinMeiList.length,
              ...response.data.sakuhinList
            );
            this.searchResult_nullFlg = false;
          } else {
            this.searched_sakuhinMeiList.splice(0);
            this.searchResult_nullFlg = true;
          }
        })
        .catch((error) => {
          alert("作品データが取得できませんでした\n" + error);
        });
    },
    getCurrentPage(currentPage) {
      this.clickedPage = currentPage;
      this.currentPage = currentPage;
      this.getPageInfo();
    },
    search() {
      this.currentPage = 1;
      this.getPageInfo();
    },
    add_sakuhinMei(sakuhin) {
      this.isNewKariPenname = false;
      this.selected_sakuhinMei = sakuhin.sakuhinMei;
      this.selected_sakuhinId = sakuhin.sakuhinId;
    },
    add_newKariSakuhinMei(kariSakuhinMei) {
      this.isNewKariPenname = true;
      this.inputKariSakuhin = "";
      this.selected_sakuhinMei = kariSakuhinMei;
      this.selected_sakuhinId = null;
    },
    remove_sakuhinMei() {
      this.isNewKariPenname = false;
      this.selected_sakuhinMei = "";
      this.selected_sakuhinId = null;
    },
    async update_sakuhinMei() {
      // 新しい仮作品の場合、登録を行う
      if (this.isNewKariPenname) {
        await axios.post(
          `${this.url}/save_kari_sakuhin`,
          {
            daiwariId: this.daiwariId,
            kariSakuhinMei: this.selected_sakuhinMei,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$emit(
          "updateSakuhinList",
          () => this.emitUpdateSakuhinMei(),
          // 新規登録仮作品のidを親コンポーネントで取得してselected_sakuhinIdにセットする
          (karisakuhin_id) => this.setKarisakuhinId(karisakuhin_id)
        );
      }
      // 既存仮作品の場合、APIに使用状況をpost
      else if (this.selected_sakuhinId < 0) {
        await axios.post(
          `${this.url}/save_kari_sakuhin`,
          {
            daiwariId: this.daiwariId,
            kariSakuhinId: this.selected_sakuhinId,
            kariSakuhinMei: this.selected_sakuhinMei,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.emitUpdateSakuhinMei();
      } else {
        this.emitUpdateSakuhinMei();
      }
    },
    setKarisakuhinId(karisakuhinId) {
      this.selected_sakuhinId = karisakuhinId;
    },
    emitUpdateSakuhinMei() {
      //原価台帳モーダル経由（使用してない？）
      if (this.props_sakuhin) {
        this.$emit(
          "updateSakuhinMeiFromModal",
          this.props_sakuhin,
          this.selected_sakuhinMei
        );
        this.changeSakuhinToDaiwari();
      } else {
        //作品サイドバー経由
        this.$emit(
          "update_sakuhinMei",
          this.selected_sakuhinMei,
          this.selected_sakuhinId
        );
        this.$emit("hideModal", "modal_sakuhinMei", this.isPropsSakuhin);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 60%;
    height: 95% !important;
    gap: 0% !important;
    &__header {
      height: 10%;
    }
    &__close {
      color: #fff;
    }
    &__review {
      height: 95% !important;
      padding-top: 10px;
      gap: 10px !important;
      &__result-container {
        min-height: 20%;
        max-height: 60%;
        height: auto;
      }
      &__result-name {
        padding: 0 2px !important;
        font-size: 12px !important;
      }
      &__result-karisakuhin {
        font-size: 11px !important;
      }
      &__karisakuhin-container {
        list-style: none;
        display: flex;
        gap: 5px;
        #kari-check {
          cursor: pointer;
        }
        label {
          font-weight: 100;
          cursor: pointer;
        }
        .kari-text {
          margin-left: 5px;
        }
      }
    }
  }

  .btn_gray {
    padding: 5px;
  }
}
</style>
