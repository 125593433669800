import { GetterTree, Module, useStore } from "vuex";
import { RootState } from "..";
import axios from "axios";

// 型定義
export interface KikakuData {
  kikakuId: number | null;
  kikakuMei: string;
  kikakuMeiKana: string;
  syohinKbnId: number | null;
  syohinBunruiId: number | null;
  hannyuKiboYearAndMonth: string;
  version: number | null;
  isCancel: boolean,
  cancelRiyu: string
}

export interface KariSiyoData {
  kikakuId: number | null;
  seihonSiyoId: number | null;
  hangataId: number | null;
  singleColorQty: number | null;
  fullColorQty: number | null;
  weight: number | null;
}

export interface KikakuNewState {
  newKikakuData: {
    kikaku: KikakuData;
    kariSiyo: KariSiyoData;
  };
  responseData:{
    kikaku: KikakuData;
    kariSiyo: KariSiyoData;
  }
}
export interface KikakuOldState {
  oldKikakuData: {
    kikaku: KikakuData;
    kariSiyo: KariSiyoData;
  };
}

export interface stateKikakuData {
  oldKikakuData: {
    kikaku: KikakuData;
    kariSiyo: KariSiyoData;
  };
}


//仮仕様情報
const kikakuStateClear = (): KikakuNewState => ({
  newKikakuData: {
    kikaku: {
      kikakuId: null,
      kikakuMei: "",
      kikakuMeiKana: "",
      syohinKbnId: null,
      syohinBunruiId: null,
      hannyuKiboYearAndMonth: "",
      version: null,
      isCancel:false,
      cancelRiyu:""
    },
    kariSiyo: {
      kikakuId: null,
      seihonSiyoId: null,
      hangataId: null,
      singleColorQty: null,
      fullColorQty: null,
      weight: null,
    },
  },
  responseData: {
    kikaku: {
      kikakuId: null,
      kikakuMei: "",
      kikakuMeiKana: "",
      syohinKbnId: null,
      syohinBunruiId: null,
      hannyuKiboYearAndMonth: "",
      version: null,
      isCancel:false,
      cancelRiyu:""
    },
    kariSiyo: {
      kikakuId: null,
      seihonSiyoId: null,
      hangataId: null,
      singleColorQty: null,
      fullColorQty: null,
      weight: null,
    },
  }
});


export const KikakuOldModule: Module<KikakuOldState, RootState> = {
  mutations:{
 
    oldKikakuDataSubmit(state, payload) {
      state.oldKikakuData = { ...state.oldKikakuData, ...payload};
    },
    oldKikakuClear(state) {
      state.oldKikakuData = { ...kikakuStateClear().newKikakuData };
    },
  }
}

export const getters: GetterTree<KikakuNewState, RootState> = {
  getkikakuResponse:(state) => state.responseData

}
const requestData = "";

export const KikakuNewModule: Module<KikakuNewState, RootState> = {
  state: kikakuStateClear,

  mutations: {
    
    createKikaku(state, payload) {

   if (payload.kikaku) {
    state.newKikakuData.kikaku = { ...state.newKikakuData.kikaku, ...payload.kikaku };
  }
  if (payload.kariSiyo) {
    state.newKikakuData.kariSiyo = { ...state.newKikakuData.kariSiyo, ...payload.kariSiyo };
  }
      const requestData = {
        kikaku: {
          kikakuId: state.newKikakuData.kikaku.kikakuId,
          kikakuMei: state.newKikakuData.kikaku.kikakuMei,
          kikakuMeiKana: state.newKikakuData.kikaku.kikakuMeiKana,
          syohinKbnId: state.newKikakuData.kikaku.syohinKbnId,
          syohinBunruiId: state.newKikakuData.kikaku.syohinBunruiId,
          hannyuKiboYearAndMonth: state.newKikakuData.kikaku.hannyuKiboYearAndMonth,
          version: state.newKikakuData.kikaku.version,
          isCancel: state.newKikakuData.kikaku.isCancel,
          cancelRiyu: state.newKikakuData.kikaku.cancelRiyu
        },
        kariSiyo: {
          kikakuId: state.newKikakuData.kariSiyo.kikakuId,
          seihonSiyoId: state.newKikakuData.kariSiyo.seihonSiyoId,
          hangataId: state.newKikakuData.kariSiyo.hangataId,
          singleColorQty: state.newKikakuData.kariSiyo.singleColorQty,
          fullColorQty: state.newKikakuData.kariSiyo.fullColorQty,
          weight: state.newKikakuData.kariSiyo.weight,
        },
      };
      state.responseData = requestData;


    },

    
    createKikakuClear(state) {
      state.newKikakuData = { ...kikakuStateClear().newKikakuData };
    },
    
  },
  actions: {
    async submitKikakuData({ state, commit, getters }) {
      const postData = state.responseData;
      const storePath = getters.getBaseUrl;
      const fullPath = location.href;
      let postURL = "";
      if (fullPath.includes("new")) {
        postURL = `${storePath}/kikaku/create`;
      } else if (fullPath.includes("edit")) {
        postURL = `${storePath}/kikaku/edit`;
      }
      try {
        const response = await axios.post(postURL, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });


        location.reload();
      } catch (err) {
        console.error(err);
      }
    },

  },
};
