<template>
  <div id="overlay">
    <div class="modal" @click="stopPropagation">
      <div class="kakutei-header">
        <p v-if="isKakutei === true" class="kakutei-header_title">
          台割確定変更内容
        </p>
        <p v-else class="kakutei-header_title">台割確定確認</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="$emit('confirmCancel')"
          class="modal__close"
        />
      </div>
      <div class="modal-container">
        <div
          :class="
            isKakutei ? 'kakutei-contents' : 'kakutei-contents_first-time'
          "
        >
          <div v-if="!isKakutei" class="kakutei-contents_nochange">
            台割表を確定します
          </div>
          <div
            v-else-if="changes.length !== 0"
            class="kakutei-contents_changes"
          >
            <table>
              <tr
                class="oya_tr"
                v-for="(change, changesIndex) in changes"
                :key="changesIndex"
              >
                <td class="change_id">{{ changesIndex + 1 }}.</td>
                <td class="change_pages">
                  <span v-if="change.startPage !== -1">{{
                    change.startPage
                  }}</span
                  ><span v-if="change.startPage > 0">P</span>
                  <span v-if="change.endPage !== -1"
                    >~{{ change.endPage }}P</span
                  >
                </td>
                <td>
                  <span v-if="change.sakuhinTitle !== ''"
                    >「{{ change.sakuhinTitle }}」</span
                  >
                </td>
                <td class="change_contents">
                  <table>
                    <tr
                      class="child_tr"
                      v-for="(
                        changeContent, changeArrayIndex
                      ) in change.changeArray"
                      :key="`${changesIndex}-${changeArrayIndex}`"
                    >
                      <td>{{ changeContent }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div class="change_date">変更日時：{{ dateOfChange }}</div>
          </div>
          <div v-else class="kakutei-contents_nochange">
            変更内容がありません
          </div>
          <div class="kakutei-contents_addtext">
            <p>〇追記テキスト</p>
            <textarea v-model="addText" />
          </div>
        </div>
        <div class="kakutei-footer">
          <p v-if="isKakutei">台割確定内容を変更しますか？</p>
          <div class="kakutei-footer_btnarea">
            <button @click="confirmChange">
              <span v-if="isKakutei">変更</span><span v-else>OK</span>
            </button>
            <button class="cancel_btn" @click="$emit('confirmCancel')">
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stopEvent: Function,
    changes: Array,
    isKakutei: Boolean,
    daiwariKakuteiVersion: Number,
    editingUserInfo: Object,
    title: String,
  },
  emits: ["confirmCancel", "daiwariConfirmStopEvent", "kakuteiConfirm"],
  data() {
    return {
      dateOfChange: "",
      addText: "",
    };
  },
  created() {
    // 変更日時の取得
    const now = new Date();
    const Year = now.getFullYear();
    const Month = now.getMonth() + 1;
    const Day = now.getDate();
    const Hour = now.getHours().toString().padStart(2, "0");
    const Min = now.getMinutes().toString().padStart(2, "0");
    const Sec = now.getSeconds().toString().padStart(2, "0");
    this.dateOfChange =
      Year + "年" + Month + "月" + Day + "日" + Hour + ":" + Min + ":" + Sec;
  },
  methods: {
    stopPropagation(event) {
      this.$emit("daiwariConfirmStopEvent");
      event.stopPropagation();
    },
    confirmChange() {
      // メール通知用文字列生成

      let toSendData = "";
      // 追記事項
      let toSendText = this.addText !== "" ? this.addText : "なし";
      // 共通事項
      const daiwariTitle = this.title;
      const tanto = this.editingUserInfo.editingUserName;
      const url = location.href + "&isYomitori=true";
      const commonSendData = `\r\n\r\n＜作品タイトル＞\r\n\u0020\u0020${daiwariTitle}\r\n＜確定処理担当者名＞\r\n\u0020\u0020${tanto}\r\n＜台割URL＞\r\n\u0020\u0020${url}`;

      // 初回確定時
      if (this.daiwariKakuteiVersion === 1) {
        toSendData = `台割確定を行いました（バージョン${this.daiwariKakuteiVersion}）${commonSendData}\r\n\r\n＜追記事項＞\r\n${toSendText}\r\n`;
      }
      // 2回目以降確定時 ①確定内容に変更なし
      else if (this.changes.length === 0) {
        toSendData = `台割確定を行いました（バージョン${this.daiwariKakuteiVersion}）${commonSendData}\r\n\r\n変更内容はありません。\r\n\r\n＜追記事項＞\r\n${toSendText}\r\n`;
      }
      // 2回目以降確定時 ②確定内容に変更あり
      else {
        toSendData += `台割確定を行いました（バージョン${this.daiwariKakuteiVersion}）${commonSendData}\r\n\r\n＜変更内容＞\r\n\u0020\u0020`;
        this.changes.forEach((change, changeIndex) => {
          const startPage =
            change.startPage === -1
              ? ""
              : change.startPage > 0
              ? change.startPage + "P"
              : change.startPage;
          const endPage =
            change.endPage === -1 ? "" : "～" + change.endPage + "P";
          const sakuhinTitle =
            change.sakuhinTitle === "" ? "" : `「${change.sakuhinTitle}」`;
          toSendData += `${
            changeIndex + 1
          }．${startPage}${endPage}${sakuhinTitle}`;
          change.changeArray.forEach((changeInfo, changeInfoIndex) => {
            toSendData +=
              change.changeArray.length === changeInfoIndex + 1
                ? changeInfo + "\r\n\u0020\u0020"
                : changeInfo + "、";
          });
        });
        toSendData += `\r\n＜追記事項＞\r\n${toSendText}\r\n`;
      }
      // console.log(toSendData);
      this.$emit("kakuteiConfirm", toSendData);
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 600px;
    height: 400px;
    overflow-y: auto;
    &__message {
      font-size: 16px;
      padding: 0px 20px;
    }
    .kakutei-header {
      display: flex;
      align-items: center;
      padding: 1px 20px;
      height: 10%;
      background-color: #2a3f54;
      border-radius: 5px 5px 0 0;
      justify-content: space-between;
      &_title {
        display: block;
        font-size: 20px;
        color: #fff;
        text-align: left;
      }
    }
    .modal-container {
      width: 95%;
      padding: 10px 0;
      margin: 0 auto;

      .kakutei-contents {
        padding: 15px;
        border: 1px solid #a5a5a5;
        background: #fffaf0;
        &_changes {
          > table {
            margin: 0 auto;
          }
          table {
            td {
              padding: 0 5px;
              word-break: break-word;
            }
            tr.oya_tr {
              border-bottom: 1px solid #c4c4c4;
              &:last-child {
                border: none;
              }
            }
            .change_id {
              min-width: 25px;
            }
            .change_pages {
              min-width: 100px;
            }
            .change_contents {
              min-width: 130px;
            }
          }
          .change_date {
            font-size: 12px;
            text-align: center;
          }
        }
        &_nochange {
          padding: 15px 0;
          color: red;
          font-weight: bold;
        }
        &_addtext {
          padding-top: 10px;
          textarea {
            resize: none;
            width: 90%;
            height: 50px;
            display: block;
            margin: 0 auto;
          }
        }
      }

      .kakutei-contents_first-time {
        @extend .kakutei-contents;
        border: none;
        background: none;
      }

      .kakutei-footer {
        padding: 10px 0;
        p,
        div {
          display: flex;
          justify-content: center;
        }
        button {
          margin: 0 5px;
          width: 150px;
          height: 40px;
        }
        .cancel_btn {
          border: 1px solid #ec7211;
          color: #ec7211;
          background: none;
        }
      }
    }
  }
}
</style>
