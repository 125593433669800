<template>
  <div id="daiwari_edit">
    <GlobalEvents @keyup.ctrl.shift.s="saveDaiwari()" @click="count_timeOut" />
    <ModalTorihikisaki
      v-if="
        modalName === 'modal_torihikisaki' ||
        modalOnModalName === 'modal_torihikisaki'
      "
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @updateTorihikisaki="updateTorihikisaki"
      :selectedSakuhin="this.selectedSakuhin"
      :genkaKbnList="this.genkaKbnList"
      :torihikisakiIndex="this.torihikisakiIndex"
      :changeTorihikiToDaiwari="changeTorihikiToDaiwari"
      :props_torihiki="this.props_torihiki"
      :modalName="modalName"
      :modalOnModalName="modalOnModalName"
      :update_genkaIndex="this.update_genkaIndex"
      :url="this.url"
    />
    <!-- ↓使ってる？ -->
    <ModalTorihikisaki
      v-if="
        modalName === 'modal_genka_torihikisaki' ||
        modalOnModalName === 'modal_genka_torihikisaki'
      "
      @hideModal="hideModal"
      :daiwariData="this.daiwariData"
      :modalName="modalName"
      :modalOnModalName="modalOnModalName"
      :update_genkaIndex="this.update_genkaIndex"
      :editDaiwariGenkaTorihiki="editDaiwariGenka_torihiki"
      :url="this.url"
    />
    <ModalPenName
      v-if="
        modalName === 'modal_penName' ||
        modalOnModalName === 'modal_relatedGenka_torihikisaki' ||
        modalOnModalName === 'modal_independentGenka_torihikisaki'
      "
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @updatePenName="updatePenName"
      @updateTorihikisaki="updateTorihikisaki"
      :selectedSakuhin="this.selectedSakuhin"
      :torihikisakiIndex="this.torihikisakiIndex"
      :url="this.url"
      :penName_ikkatuFlg="penName_ikkatuFlg"
      :kikakuId="kikakuId"
      :modalOnModalName="modalOnModalName"
      :sakuhinDataByDaicho="this.props_torihiki"
      :daiwariData="this.daiwariData"
      :update_genkaIndex="this.update_genkaIndex"
      :changeTorihikiToDaiwari="changeTorihikiToDaiwari"
      :editDaiwariGenkaTorihiki="editDaiwariGenka_torihiki"
      :daiwariId="this.daiwariId"
      :sakuhinInfoForPennameModal="this.sakuhinInfoForPennameModal"
    />
    <ModalSakuhinMei
      v-if="
        modalName === 'modal_sakuhinMei' ||
        modalOnModalName === 'modal_sakuhinMei'
      "
      :modalName="modalName"
      :selectedSakuhin="selectedSakuhin"
      :props_sakuhin="this.props_sakuhin"
      :changeSakuhinToDaiwari="changeSakuhinToDaiwari"
      :url="this.url"
      :daiwariId="this.daiwariId"
      @update_sakuhinMei="updateSakuhinMei_fromSide"
      @updateSakuhinMeiFromModal="updateSakuhinMei_fromModal"
      @hideModal="hideModal"
      @updateSakuhinList="updateSakuhinList"
    />
    <ModalTantou
      v-if="modalName === 'modal_tantou' || modalOnModalName === 'modal_tantou'"
      @hideModal="hideModal"
      @updateTantouMei="updateTantouMei"
      @count_timeOut="count_timeOut"
      :changeTantouToDaiwari="changeTantouToDaiwari"
      :selectedSakuhin="this.selectedSakuhin"
      :daiwariData="this.daiwariData"
      :kikakuId="kikakuId"
      :props_tantou="this.props_tantou"
      :tantoBusyoId="tantoBusyoId"
      :url="this.url"
      :tantou_ikkatuFlg="tantou_ikkatuFlg"
    />
    <ModalOddCheck
      v-if="modalName === 'modal_oddCheck'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      :oddCheck_array="this.oddCheck_array"
    />
    <ModalSaveConfimation
      :isLockDaiwari="isLockDaiwari"
      v-if="modalName === 'modal_saveConfimation'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
    />
    <ModalWorkingHistory
      v-if="modalName === 'modal_workingHistory'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @openOverlay="showOverlay"
      :daiwariRireki="this.daiwariRireki"
      :daiwariId="this.daiwariId"
      :url="this.url"
      :kakuteiRireki_flg="kakuteiRireki_flg"
    />
    <ModalDaiwariKakuteiHistory
      v-if="modalName === 'modal_daiwariKakuteiHistory'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @openOverlay="showOverlay"
      :daiwariId="this.daiwariId"
      :url="this.url"
      :kakuteiRireki_flg="kakuteiRireki_flg"
    />
    <ModalDaiwariCopy
      v-if="modalName === 'modal_daiwariCopy'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @setKariData="setKariData"
      @openOverlay="showOverlay"
      :url="this.url"
    />
    <ModalSikisuu
      v-if="modalName === 'modal_sikisuu'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @onClickAddOri="onClickAddOri"
      :oriAddress="this.daiwariData.ori_array.length"
      :ori_array="this.daiwariData.ori_array"
      :insertOriIndex="insertOriIndex"
    />
    <ModalEdaban
      v-if="modalName === 'modal_edaban'"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @unLockDaiwari="unLockDaiwari"
      @hide_acordion="hide_acordion"
      @startProcessing="isProcessing = true"
      @endProcessing="isProcessing = false"
      @deleteEdaban="deleteEdaban"
      :daiwariId="this.daiwariId"
      :kikakuId="this.kikakuId"
      :url="this.url"
      :is_daiwari_saved="this.is_daiwari_saved"
      :isOyaDaiwariKakutei="isOyaDaiwariKakutei"
    />
    <ModalDaiwariLock
      v-if="modalName === 'modal_daiwariLock'"
      :title="this.daiwariHeader.title"
      :daiwariHeader="this.daiwariHeader"
      :daiwariLockUserInfo="this.daiwariLockUserInfo"
      :daiwariId="this.daiwariId"
      :url="this.url"
      @fetch_daiwariLockInfo="this.fetch_daiwariLockInfo"
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      @back_to_previousPage="back_to_previousPage"
    />
    <ModalDaiwariUnlock
      @hideModal="hideModal"
      @count_timeOut="count_timeOut"
      v-if="modalName === 'modal_daiwariUnlock'"
      :title="this.daiwariHeader.title"
    />
    <ModalTimeout
      @hideModal="hideModal"
      v-if="modalName === 'modal_timeout'"
      class="print"
    />
    <ModalCopySakuhin
      v-if="modalName === 'modal_copy'"
      :selectedSakuhin="selectedSakuhin"
      :copySakuhin="copySakuhin"
      @hideModal="hideModal"
    />
    <ModalPasteSakuhin
      v-if="modalName === 'modal_paste'"
      :selectedSakuhin="selectedSakuhin"
      :copiedSakuhin="copiedSakuhin"
      :copySakuhin="copySakuhin"
      :replaceSakuhin="replaceSakuhin"
      :pasteSakuhin="pasteSakuhin"
      @hideModal="hideModal"
    />
    <ModalSearchYoushi
      v-if="modalName === 'modal_yosi'"
      :selectedOri="selectedOri"
      @removeYoushi="removeYoushi"
      @updateYoushi="updateYoushi"
      @hideModal="hideModal"
      :url="this.url"
      :mst_yoto="mst_yoto"
      :mst_yosi="mst_yosi"
    />
    <ModalDaiwariKakutei
      v-if="modalName === 'modal_daiwariKakutei'"
      @hideModal="hideModal"
      @showModal="showModal"
      @count_timeOut="count_timeOut"
      @processEnd="openOutputOverlay"
      :daiwariId="this.daiwariId"
      :url="this.url"
      :daiwariSakuhinArray="this.daiwariSakuhinArray"
      :kakuteiDaiwari="kakuteiDaiwari"
      :saveDaiwari="saveDaiwari"
      :tantoBusyoMei="tantoBusyoMei"
      :title="daiwariHeader.title"
      :daiwariKakuteiVersion="daiwariKakuteiVersion"
      :daiwariHeader="daiwariHeader"
      :daiwariData="this.daiwariData"
      :editingUserInfo="editingUserInfo"
      :fromOutputBtn="fromOutputBtn"
    />
    <ModalKakou
      v-if="modalName === 'modal_kakou'"
      :selectedOri="selectedOri"
      :url="url"
      @hideModal="hideModal"
      @updateKakou="inputKakou"
    />
    <DaiwariHistoryReference
      v-if="overlayName === 'overlay_daiwariHistoryReference'"
      :daiwariId="this.daiwariId"
      :overlayId="this.overlayId"
      :rirekiTorokuUserMei="this.rirekiTorokuUserMei"
      :rirekiTorokuDate="this.rirekiTorokuDate"
      :url="this.url"
      :rirekiUrl="rirekiUrl"
      :kakuteiRireki_flg="this.kakuteiRireki_flg"
      :isLockDaiwari="isLockDaiwari"
      :previewPageRangeData="previewPageRangeData"
      @hideOverlay="hideOverlay"
      @hideModal="hideModal('modal_workingHistory')"
      @restoreDaiwari="restore_daiwari"
      @toggleSakuhinBikouFlg="toggleSakuhinBikouFlg"
      @toggleOriBikouFlg="toggleOriBikouFlg"
      ref="rirekiId"
    />

    <DaiwariCopyReference
      v-if="overlayName === 'overlay_daiwariCopyReference'"
      :overlayId="this.overlayId"
      :kikakuId="this.copyKikakuId"
      :referenceTitle="this.referenceTitle"
      :daiwariTorokuDate="this.daiwariTorokuDate"
      :previewPageRangeData="previewPageRangeData"
      :copyFromData="this.copyFromData"
      :copyToData="this.copyToData"
      :url="this.url"
      :isDensi="this.isDensi"
      @hideOverlay="hideOverlay"
      @hideModal="hideModal"
      @copyDaiwari="copy_daiwari"
      @toggleSakuhinBikouFlg="toggleSakuhinBikouFlg"
      @toggleOriBikouFlg="toggleOriBikouFlg"
      @setUseKariFlg="setUseKariFlg"
    />
    <DaiwariOutput
      :props_daiwariData="JSON.parse(JSON.stringify(daiwariData))"
      v-if="overlayName === 'overlay_daiwariOutput'"
      @hideOverlay="hideOverlay"
      @showModal="showModal"
      @saveDaiwari="saveDaiwari"
      :oriMaximumPage="oriMaximumPage"
      :isLockDaiwari="isLockDaiwari"
      :props_syosiData="daiwariData.outputSyosiData"
      :daiwariId="daiwariId"
      :kikakuId="kikakuId"
      :url="this.url"
      :mst_yoto="mst_yoto"
      :mst_yosi="mst_yosi"
      :isNakatojiFlg="isNakatojiFlg"
      :setDaiwariVersionInfo="setDaiwariVersionInfo"
      :page_range_bikou_array="daiwariData.page_range_bikou_array"
      :currentRowDaiwariData="currentRowDaiwariData"
      :latestKakuteiRowDaiwariData="latestKakuteiRowDaiwariData"
      :updateCurrentRowDaiwariData="updateCurrentRowDaiwariData"
      :hasDaiwariKakuteiChanges="hasDaiwariKakuteiChanges"
      :prevSeisakuBikou="prevSeisakuBikou"
      :hasKakuteiRireki="hasKakuteiRireki"
    />
    <PageBikou
      v-if="overlayName === 'overlay_pageBikou'"
      v-click-outside="onClickClosePageBikou"
      @updatePageBikou="updatePageBikou"
      :referenceBikou="referenceBikou"
      :referenceBikouAddress="referenceBikouAddress"
      :selectedSakuhin="selectedSakuhin"
      :isLockDaiwari="isLockDaiwari"
    />
    <PageRangeBikou
      v-if="overlayName === 'overlay_pageRangeBikou'"
      v-click-outside="onClickClosePageRageBikou"
      :page_range_bikou_array="daiwariData.page_range_bikou_array"
      :daiwariAddressArray="daiwariData.daiwariAddressArray"
      @setPreviewPageRangeAddress="setPreviewPageRangeAddress"
      @hideOverlay="hideOverlay"
      @registerPageRangeBikou="registerPageRangeBikou"
      @updatePageRangeBikou="updatePageRangeBikou"
      @deletePageRangeBikou="deletePageRangeBikou"
    />
    <ModalDaiwariError
      v-if="modalName === 'modal_daiwariError'"
      :errorMessage="daiwariErrorMessage"
      @hideModal="hideModal"
    />
    <!-- 処理中オーバーレイ -->
    <div
      v-if="isProcessing"
      class="processing-overlay"
      @click="clickProcessing"
    >
      <span class="processing-icon"></span>
      <span class="processing-message">処理中です</span>
    </div>

    <div class="main_area print">
      <!-- [sidebar] -->
      <div class="sidebar" @click="hide_acordion()">
        <SidebarNull
          v-if="selectedSideBar == 0"
          :selectedSakuhin="this.selectedSakuhin"
        />
        <SidebarOri
          v-if="selectedSideBar == 1"
          :selectedOri="this.selectedOri"
          :daiwariData="this.daiwariData"
          :isLockDaiwari="isLockDaiwari"
          @showModal="showModal"
          @inputOriKbn="inputOriKbn"
          @inputSikisuu="inputSikisuu"
          @inputKakou="inputKakou"
          @addSakuhin="onClickAddSakuhin"
          @deleteOri="onClickDeleteOri"
          @removeYoushi="removeYoushi"
          @reset_oriMaximumPage="reset_oriMaximumPage"
          @set_oriBikou="set_oriBikou"
          @enableHyosiFlg="enableHyosiFlg"
          @allowsToChangeOriInfo="allowsToChangeOriInfo"
          @removeKakou="removeKakou"
          ref="child"
        />
        <SidebarSakuhin
          v-if="selectedSideBar == 2"
          :selectedSakuhin="this.selectedSakuhin"
          :daiwariData="this.daiwariData"
          :genkaKbnList="this.genkaKbnList"
          :sakuhinMeiList="this.sakuhinMeiList"
          :inputGenkaKbn="inputGenkaKbn"
          :isKikakuCancel="this.isKikakuCancel"
          :isLockDaiwari="isLockDaiwari"
          :isDensi="isDensi"
          @addGenka="onClickAddGenka"
          @deleteGenka="onClickDeleteGenka"
          @deleteSakuhin="onClickDeleteSakuhin"
          @inputTotalPage="inputTotalPage"
          @inputPage="inputPage"
          @inputSinkouStatus="inputSinkouStatus"
          @showModal="showModal"
          @removeTorihikisaki="removeTorihikisaki"
          @removeTantou="removeTantou"
          @removePenName="removePenName"
          @updateSakuhinMeiFromSide="updateSakuhinMei_fromSide"
          @togglePageCountFlg="togglePageCountFlg"
          @set_sakuhinBikou="set_sakuhinBikou"
          :toggleHyousiFlg="toggleHyousiFlg"
          @toggleSakuhinBikouMarkFlg="toggleSakuhinBikouMarkFlg"
          @input_sakuhin_mei_hosoku="input_sakuhin_mei_hosoku"
          @input_episode="input_episode"
          @changeGenkoryoSettei="changeGenkoryoSettei"
          ref="childSakuhin"
        />
      </div>

      <div
        class="content"
        :class="{
          SideBar_False_Daiwari: !isActive_SidebarFlg,
          SideBar_True_Daiwari: isActive_SidebarFlg,
        }"
      >
        <!-- [daiwari_info_table] -->
        <FrameTable
          :daiwariHeader="daiwariHeader"
          :isLockDaiwari="isLockDaiwari"
          :calcInzeiData="calcInzeiData"
          @hide_acordion="hide_acordion()"
          ref="frame-table"
        />
        <!-- [action_button] -->
        <div class="action_area">
          <button
            class="action_button button_narrow"
            @click="
              undo_process();
              hide_acordion();
            "
            :class="
              this.backup_index <= 0 ||
              !this.backup_daiwariArray.length ||
              this.isKikakuCancel
                ? 'disable_button--header'
                : ''
            "
            :disabled="
              this.backup_index < 0 ||
              !this.backup_daiwariArray.length ||
              isLockDaiwari ||
              isJuhanDaiwari
            "
          >
            <font-awesome-icon :icon="['fas', 'reply']" />
          </button>
          <button
            class="action_button button_narrow"
            @click="
              redo_process();
              hide_acordion();
            "
            :class="
              this.backup_index >= this.backup_daiwariArray.length - 1 ||
              !this.backup_daiwariArray.length ||
              this.isKikakuCancel
                ? 'disable_button--header'
                : ''
            "
            :disabled="
              this.backup_index >= this.backup_daiwariArray.length - 1 ||
              !this.backup_daiwariArray.length ||
              isLockDaiwari ||
              isJuhanDaiwari
            "
          >
            <font-awesome-icon :icon="['fas', 'share']" />
          </button>
          <button
            @click="
              showModal('modal_sikisuu');
              hide_acordion();
            "
            :disabled="isLockDaiwari || isJuhanDaiwari"
            :class="
              isLockDaiwari || isJuhanDaiwari
                ? 'disable_button--header'
                : 'action_button'
            "
            style="min-width: 55px"
          >
            <i class="fas fa-plus-circle"></i> 折追加
          </button>
          <button
            class="action_button button_wide"
            @click="
              saveDaiwari();
              hide_acordion();
            "
            :disabled="isLockDaiwari || isJuhanDaiwari"
            :class="
              isLockDaiwari || isJuhanDaiwari ? 'disable_button--header' : ''
            "
          >
            保存(ctrl+shift+s)
          </button>
          <!-- <button
            @click="
              showModal('modal_listDisplay');
              hide_acordion();
            "
            class="action_button"
            :class="
              this.daiwariHeader.isDensi || isJuhanDaiwari
                ? 'disable_button--header'
                : ''
            "
            :disabled="this.daiwariHeader.isDensi || isJuhanDaiwari"
          >
            台帳登録
          </button> -->
          <button
            class="action_button"
            :class="isJuhanDaiwari ? 'disable_button--header' : ''"
            :disabled="isJuhanDaiwari"
            @click="
              showOverlay('overlay_daiwariOutput');
              hide_acordion();
            "
          >
            台割出力
          </button>
          <div class="acordion_group">
            <button
              class="action_button button_wide"
              :class="isJuhanDaiwari ? 'disable_button--header' : ''"
              :disabled="isJuhanDaiwari"
              @click="openAcordion(1)"
            >
              履歴参照と複写
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <div v-show="acordionPosition === 1" class="acordion_area">
              <ul class="acordion_list">
                <li
                  @click="showModal('modal_workingHistory')"
                  class="acordion_item"
                >
                  保存履歴の参照
                </li>
                <li
                  @click="showModal('modal_daiwariKakuteiHistory')"
                  class="acordion_item"
                >
                  台割確定履歴の参照
                </li>
                <li
                  @click="showModal('modal_daiwariCopy')"
                  v-if="!isLockDaiwari"
                  class="acordion_item"
                >
                  他の台割から複写
                </li>
              </ul>
            </div>
          </div>
          <button
            v-if="isLockDaiwari || daiwariHeader.isChild"
            class="disable_button--header"
          >
            台割枝番の登録
          </button>
          <button
            @click="showModal('modal_edaban')"
            v-if="!isLockDaiwari && !daiwariHeader.isChild"
            class="action_button button_wide"
          >
            台割枝番の登録
          </button>
          <!-- <div class="acordion_group">
            <button
              class="action_button button_wide"
              :class="isJuhanDaiwari ? 'disable_button--header' : ''"
              :disabled="isJuhanDaiwari"
              @click="openAcordion(2)"
            >
              特殊仕様と枝番
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <div v-show="acordionPosition === 2" class="acordion_area">
              <ul class="acordion_list">
                <li
                  class="acordion_item--disable"
                  v-if="isLockDaiwari || !isActiveTokusyuSiyoBtn"
                >
                  特殊仕様の登録
                </li>
                <li
                  v-else-if="!isLockDaiwari"
                  @click="showModal('modal_tokusyu')"
                  class="acordion_item"
                >
                  特殊仕様の登録
                </li>
                <li
                  v-if="isLockDaiwari || daiwariHeader.isChild"
                  class="acordion_item--disable"
                >
                  台割枝番の登録
                </li>
                <li
                  @click="showModal('modal_edaban')"
                  v-if="!isLockDaiwari && !daiwariHeader.isChild"
                  class="acordion_item"
                >
                  台割枝番の登録
                </li>
              </ul>
            </div>
          </div> -->
          <button
            v-if="isLockDaiwari || isJuhanDaiwari"
            class="disable_button--header"
          >
            台割確定
          </button>
          <button
            @click="showModal('modal_daiwariKakutei')"
            v-else
            class="action_button"
          >
            台割確定
          </button>
          <!-- <div class="acordion_group">
            <button class="action_button" @click="openAcordion(3)">
              確定 <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <div v-show="acordionPosition === 3" class="acordion_area">
              <ul class="acordion_list">
                <li
                  class="acordion_item--disable"
                  v-if="isLockDaiwari || isJuhanDaiwari"
                >
                  台割表の確定
                </li>
                <li
                  v-else
                  @click="showModal('modal_daiwariKakutei')"
                  class="acordion_item"
                >
                  台割表の確定
                </li>
                <li
                  v-if="
                    isLockDaiwari ||
                    this.daiwariHeader.isChild ||
                    this.daiwariHeader.isDensi
                  "
                  class="acordion_item--disable"
                >
                  原価台帳の確定
                </li>
                <li
                  @click="
                    showModal('modal_listDisplay', (shouldKakutei = true))
                  "
                  v-else-if="!isLockDaiwari"
                  class="acordion_item"
                >
                  原価台帳の確定
                </li>
              </ul>
            </div>
          </div> -->
          <button
            class="action_button page_range_button"
            :class="{
              'disable_button--header': isLockDaiwari || isJuhanDaiwari,
            }"
            :disabled="isLockDaiwari || isJuhanDaiwari"
            @click="
              showOverlay('overlay_pageRangeBikou');
              hide_acordion();
            "
          >
            ページ範囲設定
          </button>
          <!-- <div class="switch-button" v-if="daiwariData"> -->
          <button
            class="switch-button-case switch-button-case--left action_button button_narrow"
            :disabled="isJuhanDaiwari"
            :class="this.oriMaximumPage === 8 ? 'active' : ''"
            @click="
              switchOriMaximumPage(8);
              hide_acordion();
            "
          >
            8P
          </button>
          <button
            class="switch-button-case switch-button-case--center button_narrow"
            :disabled="isJuhanDaiwari"
            :class="this.oriMaximumPage === 16 ? 'active' : ''"
            @click="
              switchOriMaximumPage(16);
              hide_acordion();
            "
          >
            16P
          </button>
          <button
            class="switch-button-case switch-button-case--right button_narrow"
            :disabled="isJuhanDaiwari"
            :class="this.oriMaximumPage === 32 ? 'active' : ''"
            @click="
              switchOriMaximumPage(32);
              hide_acordion();
            "
          >
            32P
          </button>
          <div class="acordion_group">
            <button
              class="action_button button_wide"
              @click="openAcordion(4)"
              style="min-width: 70px"
            >
              関連台割 <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <div v-show="acordionPosition === 4" class="acordion_area">
              <ul class="acordion_list">
                <template
                  v-for="(
                    relatedDaiwari, relatedDaiwatiIndex
                  ) in relatedDaiwariList"
                  :key="relatedDaiwatiIndex"
                >
                  <li
                    @click="onClickRelatedDaiwariLink(relatedDaiwari)"
                    :class="[
                      relatedDaiwari.isSelected
                        ? 'acordion_item_selected'
                        : 'acordion_item',
                    ]"
                  >
                    {{ relatedDaiwari.daiwariMei }}
                    <!-- {{
                      relatedDaiwari.isEdaban
                        ? relatedDaiwari.daiwariMei
                        : "親台割"
                    }} -->
                    <!-- <span v-if="relatedDaiwari.isSelected">(選択中)</span> -->
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <button
            class="action_button page_range_button"
            @click="
              back_to_previousPage();
              hide_acordion();
            "
          >
            台割一覧へ戻る
          </button>
          <button
            class="action_button page_range_button"
            @click="go_to_kikaku_edit()"
          >
            企画画面
          </button>
          <!-- </div> -->
          <!-- <button
            class="action_button return_button"
            @click="
              back_to_previousPage();
              hide_acordion();
            "
          >
            台割一覧へ戻る
          </button> -->
        </div>
        <!-- [edit] -->
        <template v-if="daiwariData">
          <div class="content_container" @click="hide_acordion()">
            <div id="edit_area">
              <div class="edit_container">
                <draggable
                  :list="daiwariData.ori_array"
                  item-key="ori_id"
                  :move="onMove"
                  :disabled="isLockDaiwari || isJuhanDaiwari"
                  group="ori"
                  animation="200"
                  ghost-class="ghost"
                  @end="onEndOri"
                  @start="onStartOri"
                >
                  <template #item="{ element: ori, index: oriIndex }">
                    <div class="row">
                      <div @click="onChooseOri(oriIndex)">
                        <OriComponents
                          :ori="ori"
                          @toggleOriBikouFlg="toggleOriBikouFlg"
                          :ref="`oriComp${oriIndex}`"
                        />
                      </div>
                      <draggable
                        class="sakuhin_area"
                        style="display: flex"
                        :list="ori.sakuhin_array"
                        item-key="sakuhin_bikouId"
                        :disabled="isLockDaiwari || isJuhanDaiwari"
                        group="sakuhin"
                        animation="200"
                        ghost-class="ghost"
                        :data-column-id="oriIndex"
                        @choose="onChooseSakuhin"
                        @start="onStartSkuhin"
                        @end="onEndSakuhin"
                      >
                        <template #item="{ element: sakuhin }">
                          <div
                            @click.right.prevent="sakuhinCopyPaste(sakuhin)"
                            :class="[sakuhinWidth(sakuhin)]"
                          >
                            <SakuhinComponents
                              :onClickAddSakuhinBefore="onClickAddSakuhinBefore"
                              :onClickAddSakuhinAfter="onClickAddSakuhinAfter"
                              :onClickOpenPageBikou="onClickOpenPageBikou"
                              :sakuhin="sakuhin"
                              :ori="ori"
                              :includesPages_doNotCount="
                                daiwariData.includesPages_doNotCount
                              "
                              :oriMaximumPage="oriMaximumPage"
                              :isLockDaiwari="isLockDaiwari"
                              :sakuhin_page_bikou_array="
                                daiwariData.sakuhin_page_bikou_array
                              "
                              :page_range_bikou_array="
                                daiwariData.page_range_bikou_array
                              "
                              :daiwariAddressArray="
                                daiwariData.daiwariAddressArray
                              "
                              :ori_array="daiwariData.ori_array"
                              :isOpenPageRangeOverlay="isOpenPageRangeOverlay"
                              :previewPageRangeData="previewPageRangeData"
                              @toggleSakuhinBikouFlg="toggleSakuhinBikouFlg"
                              @addSakuhin="onClickAddSakuhin"
                              @onClickSakuhin="onClickSakuhin"
                            />
                          </div>
                        </template>
                      </draggable>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Daiwari,
  DaiwariHeader,
  TokusyuKoumoku,
  shouldLockDaiwari,
  autoSaveJuhanEda,
} from "../daiwariJS/Daiwari.js";
import { Ori } from "../daiwariJS/Ori.js";
import { Sakuhin } from "../daiwariJS/Sakuhin.js";
import { ShosiData } from "../daiwariJS/DaiwariOutput.js";
import * as util from "../daiwariJS/util.js";
import * as pageBikou from "../daiwariJS/PageBikou.js";
import * as pageRangeBikou from "../daiwariJS/PageRangeBikou.js";
import {
  convertSakuhinMeiFull,
  convertSakuhinMeiCompact,
  convertSakuhinMei,
} from "../daiwariJS/Sakuhin";
import draggable from "vuedraggable";
import { Notyf } from "notyf";
import vClickOutside from "click-outside-vue3";
import OriComponents from "../components/daiwari/Ori.vue";
import SakuhinComponents from "../components/daiwari/Sakuhin";
import SidebarNull from "../components/daiwari/Sidebar/SidebarNull.vue";
import SidebarSakuhin from "../components/daiwari/Sidebar/SidebarSakuhin.vue";
import SidebarOri from "../components/daiwari/Sidebar/SidebarOri.vue";
import FrameTable from "../components/daiwari/FrameTable.vue";
import ModalTantou from "../components/daiwari/Modal/ModalTantou.vue";
import ModalTorihikisaki from "../components/daiwari/Modal/ModalTorihikisaki.vue";
import ModalPenName from "../components/daiwari/Modal/ModalPenName.vue";
import ModalSakuhinMei from "../components/daiwari/Modal/ModalSakuhinMei.vue";
import ModalOddCheck from "../components/daiwari/Modal/ModalOddCheck.vue";
import ModalSaveConfimation from "../components/daiwari/Modal/ModalSaveConfirmation.vue";
import ModalWorkingHistory from "../components/daiwari/Modal/ModalWorkingHistory.vue";
import ModalDaiwariKakuteiHistory from "../components/daiwari/Modal/ModalDaiwariKakuteiHistory.vue";
import ModalDaiwariCopy from "../components/daiwari/Modal/ModalDaiwariCopy.vue";
import ModalSikisuu from "../components/daiwari/Modal/ModaSikisuu.vue";
import ModalDaiwariLock from "../components/daiwari/Modal/ModalDaiwariLock.vue";
import DaiwariHistoryReference from "../components/daiwari/Overlay/DaiwariHistoryReference.vue";
import DaiwariCopyReference from "../components/daiwari/Overlay/DaiwariCopyReference.vue";
import DaiwariOutput from "../components/daiwari/Overlay/DaiwariOutput.vue";
import ModalEdaban from "../components/daiwari/Modal/ModalEdaban.vue";
import ModalTimeout from "../components/daiwari/Modal/ModalTimeout.vue";
import ModalDaiwariUnlock from "../components/daiwari/Modal/ModalDaiwariUnlock.vue";
import ModalCopySakuhin from "../components/daiwari/Modal/ModalCopySakuhin.vue";
import ModalPasteSakuhin from "../components/daiwari/Modal/ModalPasteSakuhin.vue";
import ModalSearchYoushi from "../components/daiwari/Modal/ModalSearchYoushi.vue";
import ModalDaiwariKakutei from "../components/daiwari/Modal/ModalDaiwariKakutei.vue";
import PageBikou from "../components/daiwari/Overlay/PageBikou.vue";
import PageRangeBikou from "../components/daiwari/Overlay/PageRangeBikou.vue";
import ModalKakou from "../components/daiwari/Modal/ModalKakou.vue";
import ModalDaiwariError from "../components/daiwari/Modal/ModalDaiwariError.vue";
import axios from "axios";
let kikakuId = null;
let maxAddress = {
  ori: 0,
  sakuhin: 0,
};
let maxSameGroupCd = {
  sameGroupCd: 0,
};
const notyf = new Notyf({
  types: [
    {
      type: "info",
      background: "blue",
      icon: false,
      dismissible: true,
      duration: 10000,
      ripple: true,
    },
  ],
});
const notyf2 = new Notyf({
  position: { x: "center", y: "top" },
  types: [
    {
      type: "info",
      background: "blue",
      icon: false,
      dismissible: true,
      duration: 10000,
      ripple: true,
    },
  ],
});
history.pushState(null, null, location.href);
export default {
  name: "App",
  components: {
    draggable,
    OriComponents,
    SakuhinComponents,
    SidebarNull,
    SidebarSakuhin,
    SidebarOri,
    FrameTable,
    ModalTantou,
    ModalTorihikisaki,
    ModalPenName,
    ModalSakuhinMei,
    ModalOddCheck,
    ModalSaveConfimation,
    ModalWorkingHistory,
    ModalDaiwariKakuteiHistory,
    ModalDaiwariCopy,
    ModalSikisuu,
    DaiwariHistoryReference,
    DaiwariCopyReference,
    ModalEdaban,
    ModalDaiwariLock,
    ModalTimeout,
    ModalDaiwariUnlock,
    DaiwariOutput,
    PageBikou,
    PageRangeBikou,
    ModalCopySakuhin,
    ModalPasteSakuhin,
    ModalSearchYoushi,
    ModalDaiwariKakutei,
    ModalKakou,
    ModalDaiwariError,
  },
  props: {
    isActive_SidebarFlg: Boolean,
  },
  emits: ["toggleGlobalHeader", "showGlobalHeader"],
  data() {
    return {
      url: "",
      daiwariResponseData: null,
      backup_daiwariData: null,
      backup_daiwariArray: [],
      backup_selectedSakuhin: null,
      backup_selectedSakuhinArray: [],
      backup_selectedOri: null,
      backup_selectedOriArray: [],
      backup_index: 0,
      totalKingaku: 0,
      before_sakuhinId: null,
      after_sakuhinId: null,
      sakuhinMeiList: [],
      sakuhinList: [],
      props_torihiki: null,
      oddCheck_array: [],
      torihikisakiIndex: null,
      genkaKbnList: [],
      daiwariGenkaKbnList: [],
      daiwariSakuhinArray: [],
      daiwariData: null,
      daiwariData_sameAsDB: null,
      selectedSideBar: 0,
      selectedOri: null,
      selectedSakuhin: null,
      same_sakuhin_array: [],
      props_sakuhin: null,
      props_tantou: null,
      tantoBusyoId: null,
      tantoBusyoMei: null,
      selectedAction: 0,
      sakuhinWord: "",
      oriMaximumPage: 16,
      daiwariRireki: [],
      daiwariRirekiId: null,
      rirekiTorokuDate: "",
      rirekiTorokuUserMei: "",
      daiwariHeader: {},
      daiwariId: null,
      kikakuId: null,
      modalName: "",
      modalOnModalName: "",
      overlayName: "",
      overlayId: "",
      referenceTitle: "",
      daiwariTorokuDate: "",
      daiwariErrorList: [],
      tokusyuKoumoku: {},
      isOpenTokusyuSiyoModal: false,
      daiwariLockUserInfo: {},
      copyKikakuId: null,
      pageTransitionTo: null,
      setTimeout_timeoutId: null,
      alertTimeOut_flg: false,
      isLock: false,
      isKikakuCancel: false,
      isLockDaiwari: false,
      isActivePageRangeBikou: true,
      pageBikouDataArray: [],
      referenceBikou: "",
      referenceBikouIndex: "",
      referenceBikouAddress: "",
      insertOriIndex: null,
      outputSyosiData: {},
      copiedSakuhin: null,
      update_genkaIndex: null,
      isNakatojiFlg: false,
      mst_yoto: [
        {
          cd: 999,
          cdMei: "全て",
          cdSyubetu: "",
          cdSyubetuMei: "用紙の主な用途",
          kaisyaId: null,
          sortKey: null,
        },
      ],
      mst_yosi: [],
      dividedOriArray_from_selectedOri: [], //用紙の引継ぎ判定用
      tantou_ikkatuFlg: false,
      penName_ikkatuFlg: false,
      kakuteiRireki_flg: false,
      acordionPosition: 0,
      shouldGenkaKakutei: false,
      clickedKakuteiBtn: false,
      isDaichoKakutei: false,
      daiwari_errorList: [],
      daiwari_error_paints: [],
      daiwari_genkaerror_paints: [],
      daiwariKakuteiVersion: null,
      calcInzeiData: {},
      is_daiwari_saved: true,
      isDaichoKakuteiError: false,
      frameTablesHtml: {},
      parentDaiwariId: null,
      parentDaiwariData: null,
      isDensi: false,
      rirekiUrl: "",
      kihyo_completed: false,
      isOyaDaiwariKakutei: false,
      previewPageRangeData: {
        color: "",
        previewPageRanges: [],
      },
      isProcessing: false,
      editingUserInfo: {
        editingUserId: null,
        editingUserName: "",
      },
      copyFromData: {
        isChild: false,
        isNakatoji: false,
      },
      copyToData: {
        isChild: false,
        isNakatoji: false,
      },
      relatedDaiwariList: [],
      isJuhanDaiwari: false, // 重版台割ボタンから開かれたらtrue
      // 【旧：台割出力印刷ボタン非活性用】念のため残しておく
      latestKakuteiRowDaiwariData: "", // 最新の確定台割のJSONデータ
      currentRowDaiwariData: "", // 編集中台割のJSONデータ
      // 【現：台割出力印刷ボタン非活性用】----------------
      fromOutputBtn: false,
      hasDaiwariKakuteiChanges: false,
      prevSeisakuBikou: "",
      hasKakuteiRireki: false,
      // ------------------------------------------------
      isActiveTokusyuSiyoBtn: false,
      sakuhinInfoForPennameModal: {
        sakuhinId: null,
        sakuhinMei: null,
      },
      // 台割複写時の仮作品・ﾍﾟﾝﾈｰﾑの使用状況保存用--------
      copyDaiwariKariData: {
        useKariData: false,
        kariSakuhinIdArray: [],
        kariPennameIdArray: [],
      },
      // ------------------------------------------------
      isKanriBusyoFlg: false, // ログイン中ユーザーが管理部門の場合true
      daiwariErrorMessage: "", // 存在しない台割IDで遷移されたときのメッセージ
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    this.fetchDaiwariData();
  },
  mounted() {
    this.frameTablesHtml = this.$refs["frame-table"].$el;
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.confirmSave);
    window.removeEventListener("popstate", this.handlePopstate);
    document.removeEventListener("keydown", this.onKeyDown_backSpace);
  },
  beforeRouteLeave(to, from, next) {
    // 保存確認モーダル経由の場合はそのまま遷移
    if (this.modalName == "modal_saveConfimation") {
      this.unLockDaiwari();
      next();
    } else {
      new Promise((resolve) => {
        //未保存か否かを判定(判定前にダイアログ処理が走るためpromise)
        this.isDaiwariSaved();
        resolve();
      }).then(() => {
        //台割が未保存である場合、確認用ダイアログ表示する
        if (this.is_daiwari_saved || this.isLockDaiwari) {
          this.unLockDaiwari();
          next();
        } else {
          this.pageTransitionTo = to.href;
          this.modalName = "modal_saveConfimation";
          next(false);
        }
      });
    }
  },
  methods: {
    async fetchDaiwariData() {
      //ローカル環境,試験環境,本番環境のURL判定
      const url = util.getUrl();
      this.url = url;

      // パラメータを取得
      const params = new URL(location.href).searchParams;
      // 管理部門か否かを判定
      this.isKanriBusyoFlg =
        params.get("isKanriBusyoFlg") == "true" ? true : false;
      // 台割データを取得------------------------------------------------------------
      let getDaiwariUrl = "";
      // 通常台割の場合
      if (
        params.get("hachuId") === null &&
        (params.get("isYomitori") === null ||
          params.get("isYomitori") === "false")
      ) {
        getDaiwariUrl = `${url}/daiwari/?daiwariId=${params.get(
          "daiwariId"
        )}&kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
          "isEdaban"
        )}&isFukusya=false`;
      }
      // 読取専用台割の場合
      else if (params.get("isYomitori") === "true") {
        getDaiwariUrl = `${url}/daiwari/?daiwariId=${params.get(
          "daiwariId"
        )}&kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
          "isEdaban"
        )}&isFukusya=false&isYomitori=true`;
      }
      // 重版台割の場合
      else {
        getDaiwariUrl = `${url}/daiwari/?daiwariId=${params.get(
          "daiwariId"
        )}&kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
          "isEdaban"
        )}&isFukusya=false&hachuId=${params.get("hachuId")}`;
      }
      try {
        const response = await axios.get(getDaiwariUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        // 不正な台割IDで遷移されたときの処理
        if (response.data.daiwariRequestError !== null) {
          this.daiwariErrorMessage =
            response.data.daiwariRequestError.errorMessage;
          this.modalName = "modal_daiwariError";
          return;
        }
        // daiwariDataがnullだったら初期値をセット
        if (response.data.daiwariData == null) {
          response.data.daiwariData = `{"ori_array":[]}`;
        }
        this.daiwariResponseData = response.data;
        console.log("res", response.data);
        this.daiwariKakuteiVersion = response.data.daiwariKakuteiLatestVersion;
        this.isNakatojiFlg = response.data.isNakatoji;
        this.daiwariHeader = new DaiwariHeader(response.data);
        this.tokusyuKoumoku = new TokusyuKoumoku(response.data);
        this.daiwariLockUserInfo.lockingUserName =
          response.data.lockingUserName;
        this.daiwariLockUserInfo.editingUserId = response.data.editingUserId;
        this.editingUserInfo.editingUserId = response.data.editingUserId;
        this.editingUserInfo.editingUserName = response.data.editingUserName;
        this.isActiveTokusyuSiyoBtn = response.data.isActiveTokusyuSiyoBtn;
        //台割ロック判定(排他制御,企画倒れ,権限)
        this.daiwariHeader.isDaichoKakutei ||
        this.daiwariHeader.isKikakuCancel ||
        this.daiwariHeader.isKengenLock ||
        this.daiwariHeader.isWfLock
          ? (this.modalName = "modal_daiwariLock")
          : "";
        this.daiwariHeader.isLock
          ? (this.modalName = "modal_daiwariLock")
          : !this.daiwariHeader.isWfLock && !this.daiwariHeader.isChild
          ? (this.isOpenTokusyuSiyoModal = response.data.isOpenTokusyuSiyoModal)
          : "";
        this.isLockDaiwari = shouldLockDaiwari(this.daiwariHeader);
        this.tantoBusyoId = response.data.tantoBusyoId;
        this.calcInzeiData = response.data.daichoInzeiCalcInfo;
        this.isDensi = response.data.isDensi;
        this.parentDaiwariId = response.data.parentDaiwariId;
        // 関連台割リストの初期設定を行う
        this.relatedDaiwariList.splice(0);
        response.data.relatedDaiwariList.forEach((relatedDaiwari) => {
          if (relatedDaiwari.daiwariId === response.data.daiwariId) {
            relatedDaiwari.isSelected = true;
          } else {
            relatedDaiwari.isSelected = false;
          }
          this.relatedDaiwariList.push(relatedDaiwari);
        });

        //部署名取得
        try {
          const response = await axios.get(`${this.url}/get_busyo?busyo_mei=`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });
          let busyo_list = response.data;
          busyo_list.forEach((busyo) => {
            if (busyo.busyoId == this.tantoBusyoId) {
              this.tantoBusyoMei = busyo.busyoMei;
            }
          });
        } catch (error) {
          console.log(error);
        }
        let daiwari = new Daiwari(response.data);

        //電子台割の新規作成後は親台割の情報を持たせる
        let daiwariDataPreAdReset = daiwari.getDaiwariData();
        this.daiwariId = daiwari.getDaiwariId();
        this.kikakuId = daiwari.getKikakuId();
        kikakuId = daiwari.getKikakuId();
        maxAddress.ori = daiwari.getMaxOriAddress();
        maxAddress.sakuhin = daiwari.getMaxSakuhinAddress();

        //daiwariData内部のプロパティの初期設定
        await this.set_initialDaiwariData(daiwariDataPreAdReset);
        // this.set_initialDaiwariData(daiwariDataPreAdReset);
        console.log(this.daiwariData);
        this.changePenNameType(); //penNameもデータの持ち方を検証

        //「台割一覧に戻る」際の保存チェック用としてresponseデータを別で持っておく
        this.daiwariData_sameAsDB = JSON.parse(
          JSON.stringify(this.daiwariData)
        );

        //読み取り専用時以外はストレージ(1分毎)と自動保存履歴(5分毎)へ保存する
        if (!this.isLockDaiwari) {
          setInterval(this.saveDaiwari_onStorage, 60000);
          setInterval(this.saveDaiwariAuto, 300000); //5分：300000ミリ秒
        }
        // 企画キャンセル、権限ロック、台帳確定時にAPIのisLockをfalseにする
        if (
          this.daiwariHeader.isKikakuCancel ||
          this.daiwariHeader.isKengenLock ||
          this.daiwariHeader.isDaichoKakutei
        ) {
          this.unLockDaiwari();
        }

        //タイムアウト設定-------------------------------------------------------------
        this.count_timeOut();
        //離脱防止アラートを介してリロードした場合、ストレージは削除
        const accessType =
          window.performance.getEntriesByType("navigation")[0].type;
        if (accessType == "reload") {
          localStorage.removeItem(`daiwari_${this.daiwariId}`);
        }

        //ストレージに台割データがあれば復元するか確認する(台割データ取得完了後)------
        let localStorageDATA = localStorage.getItem(
          `daiwari_${this.daiwariId}`
        );
        if (localStorageDATA && !this.isLockDaiwari) {
          const should_restoreDaiwari = window.confirm(
            "自動バックアップされた作業データがあります。復元しますか？"
          );
          if (should_restoreDaiwari) {
            this.daiwariData = JSON.parse(localStorageDATA).daiwariData;
            util.rebuildDaiwariData(
              JSON.parse(localStorageDATA).daiwariData,
              maxAddress,
              maxSameGroupCd
            );
          }
          this.update_daiwariSakuhinArray();
        }
        if (this.isLockDaiwari) {
          localStorage.removeItem(`daiwari_${this.daiwariId}`);
        }
        // 折区分の重複チェック初期化
        this.setInitialDuplicatedOriKbn();
        console.log("daiwariData", this.daiwariData);
        // （親台割の場合）台割確定を判定する------------------------------------------
        if (
          this.parentDaiwariId === null &&
          this.daiwariResponseData.daiwariKakuteiLatestVersion !== null
        ) {
          this.isOyaDaiwariKakutei = true;
        }
        // 重版台割の場合、原価台帳確定画面を初期表示-----------------------------------
        if (params.get("hachuId") !== null) {
          let shouldReload = false;
          let edaDaiwariDataArray = [];
          // 一度も保存されていない場合は原価データを削除して保存
          if (this.daiwariData.outputSyosiData.kosinDate === null) {
            this.daiwariData.daiwari_genkaArray.splice(0);
            this.daiwariData.ori_array.forEach((ori) => {
              ori.sakuhin_array.forEach((sakuhin) => {
                sakuhin.genka_array.splice(0);
              });
            });
            await this.saveDaiwari();
            shouldReload = true;
          }
          // 自身が親で枝番が一度も保存されていない場合原価データを削除して保存
          if (
            !this.daiwariResponseData.isChild &&
            this.daiwariResponseData.edabanDaiwariList.length > 0
          ) {
            this.daiwariResponseData.edabanDaiwariList.forEach((edaDaiwari) => {
              if (!edaDaiwari.isDensi && edaDaiwari.kosinDate === null) {
                let shapedEdaDaiwari = new Daiwari(edaDaiwari);
                let edaDaiwariData = shapedEdaDaiwari.getDaiwariData();

                edaDaiwariData.daiwari_genkaArray.splice(0);
                edaDaiwariData.ori_array.forEach((ori) => {
                  ori.sakuhin_array.forEach((sakuhin) => {
                    sakuhin.genka_array.splice(0);
                  });
                });

                edaDaiwariDataArray.push({
                  daiwariId: shapedEdaDaiwari.getDaiwariId(),
                  kikakuId: shapedEdaDaiwari.getKikakuId(),
                  hachuId: params.get("hachuId"),
                  daiwariData: edaDaiwariData,
                });
                shouldReload = true;
              }
            });
          }
          if (edaDaiwariDataArray.length > 0)
            await autoSaveJuhanEda(edaDaiwariDataArray);
          this.update_daiwariSakuhinArray();
          this.isJuhanDaiwari = true;
          if (shouldReload) {
            window.location.reload();
          }
          setTimeout(() => {
            this.showModal("modal_listDisplay", (this.shouldKakutei = true));
          }, 100);
        }
        // 【台割出力印刷ボタン非活性用】最新の確定台割データを取得------------------------------------------------
        try {
          const res = await axios.get(
            `${this.url}/daiwari/get_kakutei_daiwari?daiwariId=${this.daiwariId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          // 最新の確定台割のJSONデータを取得
          this.latestKakuteiRowDaiwariData = "";
          if (res.data.length > 0) {
            res.data.sort((a, b) => a.version - b.version); // versionの小さい順で並び替える
            this.latestKakuteiRowDaiwariData =
              res.data[res.data.length - 1].daiwariData;
          }
          // 編集中台割のJSONデータを取得
          this.updateCurrentRowDaiwariData();
        } catch (error) {
          alert("確定履歴データが取得できませんでした\n");
          console.log(error);
        }

        // 0312 原価情報の入力を行わないため一旦コメントアウト
        // //原価情報の取得---------------------------------------------------------------
        // try {
        //   const response = await axios.get(`${url}/get_daiwari_genka_kbn`, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        //     },
        //   });
        //   this.genkaKbnList = [...response.data];
        // } catch (error) {
        //   alert("原価区分データが取得できませんでした\n" + error);
        // }

        // //台割原価情報の取得-----------------------------------------------------------
        // try {
        //   const response = await axios.get(`${url}/get_daiwari_genka_kbn`, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        //     },
        //   });
        //   this.daiwariGenkaKbnList = [...response.data];
        // } catch (error) {
        //   alert("原価区分データが取得できませんでした\n" + error);
        // }

        //用紙検索用途の取得-----------------------------------------------------------
        try {
          const response = await axios.get(`${url}/daiwari/get_yoto`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });
          response.data.forEach((res) => {
            this.mst_yoto.push(res);
          });
        } catch (error) {
          alert("用途データが取得できませんでした\n" + error);
        }

        //用紙検索用紙の取得-----------------------------------------------------------
        try {
          const response = await axios.get(`${url}/daiwari/get_yosi`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });
          this.mst_yosi = response.data;
        } catch (error) {
          alert("用紙データが取得できませんでした\n" + error);
        }
        //---------------------------------------------------------------
        // 0312 作品名設定がないため一旦コメントアウト
        // this.sakuhinMeiList = [];
        // this.sakuhinList = [];
        // try {
        //   const response = await axios.get(
        //     `${url}/get_sakuhin_mei?sakuhin_mei`,
        //     {
        //       headers: {
        //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        //       },
        //     }
        //   );
        //   response.data.forEach((sakuhin) => {
        //     this.sakuhinMeiList.push(sakuhin.sakuhinMei);
        //     this.sakuhinList.push({
        //       sakuhin_mei: sakuhin.sakuhinMei,
        //       sakuhin_id: sakuhin.sakuhinId,
        //     });
        //   });
        // } catch (error) {
        //   console.log(error);
        // }
        window.addEventListener("beforeunload", this.confirmSave);
        window.addEventListener("popstate", this.handlePopstate);
        document.addEventListener("keydown", this.onKeyDown_backSpace);
      } catch (error) {
        console.log(error);
        alert("台割データが取得できませんでした\n" + error);
        this.isProcessing = false;
      }

      // new Promise((resolve) => {
      //   let getDaiwariUrl = "";
      //   // 通常台割の場合
      //   if (
      //     params.get("hachuId") === null &&
      //     params.get("isYomitori") === null
      //   ) {
      //     getDaiwariUrl = `${url}/daiwari/${params.get(
      //       "daiwariId"
      //     )}?kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
      //       "isEdaban"
      //     )}&isFukusya=false`;
      //   }
      //   // 読取専用台割の場合
      //   else if (params.get("isYomitori") === "true") {
      //     getDaiwariUrl = `${url}/daiwari/${params.get(
      //       "daiwariId"
      //     )}?kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
      //       "isEdaban"
      //     )}&isFukusya=false&isYomitori=true`;
      //   }
      //   // 重版台割の場合
      //   else {
      //     getDaiwariUrl = `${url}/daiwari/${params.get(
      //       "daiwariId"
      //     )}?kikakuId=${params.get("kikakuId")}&isEdaban=${params.get(
      //       "isEdaban"
      //     )}&isFukusya=false&hachuId=${params.get("hachuId")}`;
      //   }

      //   // const response = await axios.get(getDaiwariUrl)
      //   axios
      //     .get(getDaiwariUrl)
      //     .then((response) => {
      //       // 不正な台割IDで遷移されたときの処理
      //       if (response.data.daiwariRequestError !== null) {
      //         this.daiwariErrorMessage =
      //           response.data.daiwariRequestError.errorMessage;
      //         this.modalName = "modal_daiwariError";
      //         return;
      //       }
      //       this.daiwariResponseData = response.data;
      //       console.log("res", response.data);
      //       this.daiwariKakuteiVersion =
      //         response.data.daiwariKakuteiLatestVersion;
      //       this.isNakatojiFlg = response.data.isNakatoji;
      //       this.daiwariHeader = new DaiwariHeader(response.data);
      //       this.tokusyuKoumoku = new TokusyuKoumoku(response.data);
      //       this.daiwariLockUserInfo.lockingUserName =
      //         response.data.lockingUserName;
      //       this.daiwariLockUserInfo.editingUserId =
      //         response.data.editingUserId;
      //       this.editingUserInfo.editingUserId = response.data.editingUserId;
      //       this.editingUserInfo.editingUserName =
      //         response.data.editingUserName;
      //       this.isActiveTokusyuSiyoBtn = response.data.isActiveTokusyuSiyoBtn;
      //       //台割ロック判定(排他制御,企画倒れ,権限)
      //       this.daiwariHeader.isDaichoKakutei ||
      //       this.daiwariHeader.isKikakuCancel ||
      //       this.daiwariHeader.isKengenLock ||
      //       this.daiwariHeader.isWfLock
      //         ? (this.modalName = "modal_daiwariLock")
      //         : "";
      //       this.daiwariHeader.isLock
      //         ? (this.modalName = "modal_daiwariLock")
      //         : !this.daiwariHeader.isWfLock && !this.daiwariHeader.isChild
      //         ? (this.isOpenTokusyuSiyoModal =
      //             response.data.isOpenTokusyuSiyoModal)
      //         : "";
      //       this.isLockDaiwari = shouldLockDaiwari(this.daiwariHeader);
      //       this.tantoBusyoId = response.data.tantoBusyoId;
      //       this.calcInzeiData = response.data.daichoInzeiCalcInfo;
      //       this.isDensi = response.data.isDensi;
      //       this.parentDaiwariId = response.data.parentDaiwariId;
      //       // 関連台割リストの初期設定を行う
      //       this.relatedDaiwariList.splice(0);
      //       response.data.relatedDaiwariList.forEach((relatedDaiwari) => {
      //         if (relatedDaiwari.daiwariId === response.data.daiwariId) {
      //           relatedDaiwari.isSelected = true;
      //         } else {
      //           relatedDaiwari.isSelected = false;
      //         }
      //         this.relatedDaiwariList.push(relatedDaiwari);
      //       });

      //       //部署名取得
      //       axios
      //         .get(`${this.url}/get_busyo?busyo_mei=`)
      //         .then((response) => {
      //           let busyo_list = response.data;
      //           busyo_list.forEach((busyo) => {
      //             if (busyo.busyoId == this.tantoBusyoId) {
      //               this.tantoBusyoMei = busyo.busyoMei;
      //             }
      //           });
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //         });

      //       let daiwari = new Daiwari(response.data);

      //       //電子台割の新規作成後は親台割の情報を持たせる
      //       let daiwariDataPreAdReset = daiwari.getDaiwariData();
      //       this.daiwariId = daiwari.getDaiwariId();
      //       this.kikakuId = daiwari.getKikakuId();
      //       kikakuId = daiwari.getKikakuId();
      //       maxAddress.ori = daiwari.getMaxOriAddress();
      //       maxAddress.sakuhin = daiwari.getMaxSakuhinAddress();

      //       //daiwariData内部のプロパティの初期設定
      //       (async () => {
      //         await this.set_initialDaiwariData(daiwariDataPreAdReset);
      //       })();
      //       // this.set_initialDaiwariData(daiwariDataPreAdReset);
      //       console.log(this.daiwariData);
      //       this.changePenNameType(); //penNameもデータの持ち方を検証

      //       //「台割一覧に戻る」際の保存チェック用としてresponseデータを別で持っておく
      //       this.daiwariData_sameAsDB = JSON.parse(
      //         JSON.stringify(this.daiwariData)
      //       );

      //       //読み取り専用時以外はストレージ(1分毎)と自動保存履歴(5分毎)へ保存する
      //       if (!this.isLockDaiwari) {
      //         setInterval(this.saveDaiwari_onStorage, 60000);
      //         setInterval(this.saveDaiwariAuto, 300000); //5分：300000ミリ秒
      //       }
      //       // 企画キャンセル、権限ロック、台帳確定時にAPIのisLockをfalseにする
      //       if (
      //         this.daiwariHeader.isKikakuCancel ||
      //         this.daiwariHeader.isKengenLock ||
      //         this.daiwariHeader.isDaichoKakutei
      //       ) {
      //         this.unLockDaiwari();
      //       }
      //       resolve();
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       alert("台割データが取得できませんでした\n" + error);
      //       this.isProcessing = false;
      //     });
      // }).then(async () => {
      //   //タイムアウト設定-------------------------------------------------------------
      //   this.count_timeOut();
      //   //離脱防止アラートを介してリロードした場合、ストレージは削除
      //   const accessType =
      //     window.performance.getEntriesByType("navigation")[0].type;
      //   if (accessType == "reload") {
      //     localStorage.removeItem(`daiwari_${this.daiwariId}`);
      //   }

      //   //ストレージに台割データがあれば復元するか確認する(台割データ取得完了後)------
      //   let localStorageDATA = localStorage.getItem(
      //     `daiwari_${this.daiwariId}`
      //   );
      //   if (localStorageDATA && !this.isLockDaiwari) {
      //     const should_restoreDaiwari = window.confirm(
      //       "自動バックアップされた作業データがあります。復元しますか？"
      //     );
      //     if (should_restoreDaiwari) {
      //       this.daiwariData = JSON.parse(localStorageDATA).daiwariData;
      //       util.rebuildDaiwariData(
      //         JSON.parse(localStorageDATA).daiwariData,
      //         maxAddress,
      //         maxSameGroupCd
      //       );
      //     }
      //     this.update_daiwariSakuhinArray();
      //   }
      //   if (this.isLockDaiwari) {
      //     localStorage.removeItem(`daiwari_${this.daiwariId}`);
      //   }
      //   // 折区分の重複チェック初期化
      //   this.setInitialDuplicatedOriKbn();
      //   console.log("daiwariData", this.daiwariData);
      //   // （親台割の場合）台割確定を判定する------------------------------------------
      //   if (
      //     this.parentDaiwariId === null &&
      //     this.daiwariResponseData.daiwariKakuteiLatestVersion !== null
      //   ) {
      //     this.isOyaDaiwariKakutei = true;
      //   }
      //   // 重版台割の場合、原価台帳確定画面を初期表示-----------------------------------
      //   if (params.get("hachuId") !== null) {
      //     let shouldReload = false;
      //     let edaDaiwariDataArray = [];
      //     // 一度も保存されていない場合は原価データを削除して保存
      //     if (this.daiwariData.outputSyosiData.kosinDate === null) {
      //       this.daiwariData.daiwari_genkaArray.splice(0);
      //       this.daiwariData.ori_array.forEach((ori) => {
      //         ori.sakuhin_array.forEach((sakuhin) => {
      //           sakuhin.genka_array.splice(0);
      //         });
      //       });
      //       await this.saveDaiwari();
      //       shouldReload = true;
      //     }
      //     // 自身が親で枝番が一度も保存されていない場合原価データを削除して保存
      //     if (
      //       !this.daiwariResponseData.isChild &&
      //       this.daiwariResponseData.edabanDaiwariList.length > 0
      //     ) {
      //       this.daiwariResponseData.edabanDaiwariList.forEach((edaDaiwari) => {
      //         if (!edaDaiwari.isDensi && edaDaiwari.kosinDate === null) {
      //           let shapedEdaDaiwari = new Daiwari(edaDaiwari);
      //           let edaDaiwariData = shapedEdaDaiwari.getDaiwariData();

      //           edaDaiwariData.daiwari_genkaArray.splice(0);
      //           edaDaiwariData.ori_array.forEach((ori) => {
      //             ori.sakuhin_array.forEach((sakuhin) => {
      //               sakuhin.genka_array.splice(0);
      //             });
      //           });

      //           edaDaiwariDataArray.push({
      //             daiwariId: shapedEdaDaiwari.getDaiwariId(),
      //             kikakuId: shapedEdaDaiwari.getKikakuId(),
      //             hachuId: params.get("hachuId"),
      //             daiwariData: edaDaiwariData,
      //           });
      //           shouldReload = true;
      //         }
      //       });
      //     }
      //     if (edaDaiwariDataArray.length > 0)
      //       await autoSaveJuhanEda(edaDaiwariDataArray);
      //     this.update_daiwariSakuhinArray();
      //     this.isJuhanDaiwari = true;
      //     if (shouldReload) {
      //       window.location.reload();
      //     }
      //     setTimeout(() => {
      //       this.showModal("modal_listDisplay", (this.shouldKakutei = true));
      //     }, 100);
      //   }
      //   // 【台割出力印刷ボタン非活性用】最新の確定台割データを取得------------------------------------------------
      //   axios
      //     .get(`${this.url}/get_kakutei_daiwari?daiwari_id=${this.daiwariId}`)
      //     .then((res) => {
      //       // 最新の確定台割のJSONデータを取得
      //       this.latestKakuteiRowDaiwariData = "";
      //       if (res.data.length > 0) {
      //         res.data.sort((a, b) => a.version - b.version); // versionの小さい順で並び替える
      //         this.latestKakuteiRowDaiwariData =
      //           res.data[res.data.length - 1].daiwariData;
      //       }
      //       // 編集中台割のJSONデータを取得
      //       this.updateCurrentRowDaiwariData();
      //     })
      //     .catch((error) => {
      //       alert("確定履歴データが取得できませんでした\n");
      //       console.log(error);
      //     });
      // });
      // //原価情報の取得---------------------------------------------------------------
      // axios
      //   .get(`${url}/get_daiwari_genka_kbn`)
      //   .then((response) => {
      //     this.genkaKbnList = [...response.data];
      //   })
      //   .catch((error) => {
      //     alert("原価区分データが取得できませんでした\n" + error);
      //   });
      // //台割原価情報の取得-----------------------------------------------------------
      // axios
      //   // .get(`${url}/get_daiwari_genka_kbn_outside`)
      //   .get(`${url}/get_daiwari_genka_kbn`)
      //   .then((response) => {
      //     this.daiwariGenkaKbnList = [...response.data];
      //   })
      //   .catch((error) => {
      //     alert("原価区分データが取得できませんでした\n" + error);
      //   });
      // //用紙検索用途の取得-----------------------------------------------------------
      // axios
      //   .get(`${url}/get_yoto`)
      //   .then((response) => {
      //     response.data.forEach((res) => {
      //       this.mst_yoto.push(res);
      //     });
      //   })
      //   .catch((error) => {
      //     alert("用途データが取得できませんでした\n" + error);
      //   });
      // //用紙検索用紙の取得-----------------------------------------------------------
      // axios
      //   .get(`${url}/get_yosi`)
      //   .then((response) => {
      //     this.mst_yosi = response.data;
      //   })
      //   .catch((error) => {
      //     alert("用紙データが取得できませんでした\n" + error);
      //   });
      // //---------------------------------------------------------------
      // this.sakuhinMeiList = [];
      // this.sakuhinList = [];
      // axios.get(`${url}/get_sakuhin_mei?sakuhin_mei`).then((response) => {
      //   response.data.forEach((sakuhin) => {
      //     this.sakuhinMeiList.push(sakuhin.sakuhinMei);
      //     this.sakuhinList.push({
      //       sakuhin_mei: sakuhin.sakuhinMei,
      //       sakuhin_id: sakuhin.sakuhinId,
      //     });
      //   });
      // });
      // window.addEventListener("beforeunload", this.confirmSave);
      // window.addEventListener("popstate", this.handlePopstate);
      // document.addEventListener("keydown", this.onKeyDown_backSpace);
    },
    handlePopstate() {
      //枝番台割を開いている場合のみ、ブラウザバックしたら意図的にリロードさせる
      //※枝番台割からブラウザバックした際に画面が変わらないため
      if (this.daiwariHeader.isChild) {
        window.location.reload();
      }
    },
    //【台割出力印刷ボタン非活性用】編集中台割のJSONデータを生成
    updateCurrentRowDaiwariData(syosiData) {
      // syosiDataがある時は出力画面で制作基本情報の変更があったためdaiwariDataに上書きする
      if (syosiData !== undefined) this.daiwariData.outputSyosiData = syosiData;
      let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
      // 最新の確定台割とデータの整合性をとるため保存時の形式にしてJSONを作成
      this.currentRowDaiwariData = JSON.stringify(
        util.formatBeforeUpdate(daiwariDataCopy)
      );
    },
    //daiwaridata内部のプロパティの初期設定
    async set_initialDaiwariData(daiwariData) {
      this.isProcessing = true;
      let daiwari = new Daiwari(this.daiwariResponseData); //createdで取得直後の台割データ

      maxAddress.ori = daiwari.getMaxOriAddress();
      maxAddress.sakuhin = daiwari.getMaxSakuhinAddress();

      //原価用プロパティ
      if ("daiwari_genkaArray" in daiwariData === false) {
        daiwariData.daiwari_genkaArray = [];
      }
      //出力用プロパティ：複写しても複写元の出力情報を引き継がない
      daiwariData.outputSyosiData = new ShosiData(this.daiwariResponseData);

      //最大ページ数：複写時は複写元の最大ページ数を引き継ぐ
      this.oriMaximumPage = daiwariData.oriMaximumPage
        ? daiwariData.oriMaximumPage
        : 16;

      let uniqueId;
      for (let ori of daiwariData.ori_array) {
        //ori_idプロパティ
        if ("ori_id" in ori === false) {
          ori.ori_id = util.getUniqueStr();
        }
        //雑誌以外の作品にて折区分「表紙」の折は表紙フラグをオンにする
        //(自動設定機能実装前のデータに対して表紙の自動設定を反映させるためここにも処理を記載)
        if (!daiwariData.outputSyosiData.isZassi && ori.ori_kbn === "表紙") {
          const updated_sakuhinArray = ori.sakuhin_array.map((sakuhin) => {
            return { ...sakuhin, toggleHyousiFlg: true };
          });
          ori.sakuhin_array = updated_sakuhinArray;
        } else if (
          !daiwariData.outputSyosiData.isZassi &&
          ori.ori_kbn.includes("表")
        ) {
          //折区分「表n」の折は表紙フラグをオンにする
          const slicedValue = ori.ori_kbn.slice(1, 2);
          if (util.isNumber(slicedValue)) {
            const updated_sakuhinArray = ori.sakuhin_array.map((sakuhin) => {
              return { ...sakuhin, toggleHyousiFlg: true };
            });
            ori.sakuhin_array = updated_sakuhinArray;
          }
        }
        for (const [sakuhinIndex, sakuhin] of ori.sakuhin_array.entries()) {
          sakuhin.sakuhin_address_computed_array_withFlg = [];

          //作品表示名(フルバージョン)
          if ("sakuhin_mei_display" in sakuhin === false) {
            sakuhin.sakuhin_mei_display = "";
          }
          //作品表示名(ペンネーム無しのコンパクトバージョン)
          if ("sakuhin_mei_display_compact" in sakuhin === false) {
            sakuhin.sakuhin_mei_display_compact = "";
          }
          //タイトル補足プロパティ
          if ("sakuhin_mei_hosoku" in sakuhin === false) {
            sakuhin.sakuhin_mei_hosoku = "";
          }
          //マスタ紐づき作品が設定されている場合は作品名にセットする
          if ("sakuhin_mei_suggest" in sakuhin) {
            //作品名がマスタ紐つき作品名設定後に加工されておらず、
            //完全一致の場合はsakuhin_mei_suggestをsakuhin_meiに上書き
            if (sakuhin.sakuhin_mei === sakuhin.sakuhin_mei_suggest) {
              sakuhin.sakuhin_mei = sakuhin.sakuhin_mei_suggest;
              delete sakuhin.sakuhin_mei_suggest; //旧仕様でのプロパティのため削除
            } else {
              //作品名が独自に加工されている場合はタイトル補足に逃がす
              sakuhin.sakuhin_mei_hosoku = sakuhin.sakuhin_mei;
              sakuhin.sakuhin_mei = "";
              delete sakuhin.sakuhin_mei_suggest; //旧仕様でのプロパティのため削除
            }
          }

          // penNameプロパティ
          // penNameプロパティが存在しない場合
          if ("penName" in sakuhin === false) {
            sakuhin.penName = [];
          }

          // //episodeプロパティ
          // //episodeプロパティが存在しない場合
          if ("episode" in sakuhin === false) {
            sakuhin.episode = null;
          }

          //sakuhin_uniqueIdプロパティ
          //is_divided==trueのときは親のidを引き継ぐ
          if (sakuhin.sakuhin_id === -1) {
            //マスタに紐づかない作品はタイトル補足として扱う
            if (sakuhin.sakuhin_mei_hosoku == "") {
              sakuhin.sakuhin_mei_hosoku = sakuhin.sakuhin_mei;
            }
            sakuhin.sakuhin_mei = "";
            if (sakuhin.is_divided === false) {
              sakuhin.sakuhin_uniqueId = util.getUniqueStr();
            }
          } else {
            sakuhin.sakuhin_uniqueId = 0;
          }

          //作品表示名プロパティ
          sakuhin.sakuhin_mei_display_compact =
            convertSakuhinMeiCompact(sakuhin);
          sakuhin.sakuhin_mei_display = convertSakuhinMeiFull(sakuhin);

          //bikouIdプロパティ
          if ("sakuhin_bikouId" in sakuhin === false) {
            if (
              !sakuhin.is_divided ||
              (sakuhin.is_divided && sakuhin.is_parent) ||
              (!sakuhin.is_divided && sakuhinIndex === 0)
            ) {
              //分断していない又は分断した親作品の場合,新規の備考IDを付与
              uniqueId = util.getUniqueStr();
              sakuhin.sakuhin_bikouId = uniqueId;
            } else {
              //分断した子作品の場合、親と同じ備考IDを付与
              sakuhin.sakuhin_bikouId = uniqueId;
            }
          } else if (sakuhin.sakuhin_bikouId === 0) {
            if (
              !sakuhin.is_divided ||
              (sakuhin.is_divided && sakuhin.is_parent)
            ) {
              sakuhin.sakuhin_bikouId = util.getUniqueStr();
              if (
                !sakuhin.is_divided ||
                (sakuhin.is_divided && sakuhin.is_parent)
              ) {
                sakuhin.sakuhin_bikouId = util.getUniqueStr();
              }
            }
          }
          // 【旧台割】penNameプロパティがstringの場合(ペンネームが１つまたは未入力)
          if (typeof sakuhin.penName === "string") {
            // ペンネームが入っていない場合
            if (sakuhin.penName === "") {
              delete sakuhin.penName;
              sakuhin.penName = [];
            }
            // ペンネームが一つ入っている場合
            else {
              const res = await this.getTorihikisakiData(sakuhin.penName);
              // console.log(res);
              // 検索結果が0件の場合
              if (res.torihikisakiList.length === 0) {
                delete sakuhin.penName;
                sakuhin.penName = [];
              }
              // 検索結果が存在する場合
              else {
                res.torihikisakiList[0].pennameList.forEach((penname) => {
                  if (penname.penname === sakuhin.penName) {
                    delete sakuhin.penName;
                    sakuhin.penName = [
                      {
                        penName: penname.penname,
                        pennameId: penname.pennameId,
                        torihikisakiId:
                          res.data.torihikisakiList[0].torihikisakiId,
                      },
                    ];
                  }
                });
              }
            }
          }
          // 【旧台割】penNameプロパティが配列の場合(ペンネームが複数または配列で未入力)
          else if (Array.isArray(sakuhin.penName) === true) {
            // ペンネームが入っていない場合
            if (sakuhin.penName.length === 0) {
              delete sakuhin.penName;
              sakuhin.penName = [];
            } // ペンネームが一つ以上入っている場合
            else {
              let newPennameArray = [];
              for (let penname of sakuhin.penName) {
                // 旧台割(pennameIdが存在しない場合)
                if ("pennameId" in penname === false) {
                  const res = await this.getTorihikisakiData(penname.penName);
                  // 検索結果が0件の場合
                  if (res.torihikisakiList.length === 0) {
                    return;
                  }
                  // 検索結果が存在する場合
                  else {
                    res.torihikisakiList[0].pennameList.forEach((penList) => {
                      if (penList.penname === penname.penName) {
                        newPennameArray.push({
                          penName: penList.penname,
                          pennameId: penList.pennameId,
                          torihikisakiId:
                            res.torihikisakiList[0].torihikisakiId,
                        });
                      }
                    });
                  }
                }
                // 新台割
                else {
                  newPennameArray.push(penname);
                }
              }
              delete sakuhin.penName;
              sakuhin.penName = newPennameArray;
            }
          }
          // 原稿料設定の初期設定
          if ("genkoryo_settei" in sakuhin === false) {
            let genkoryoSettei = {
              setteiKbn: -1,
              meisyo: "",
            };
            // 雑誌・アンソロの場合
            if (
              daiwariData.outputSyosiData.isZassi ||
              daiwariData.outputSyosiData.isAnthology
            ) {
              genkoryoSettei.setteiKbn = 1;
              genkoryoSettei.meisyo = "原稿料あり";
            } else {
              genkoryoSettei.setteiKbn = 2;
              genkoryoSettei.meisyo = "原稿料なし";
            }
            sakuhin.genkoryo_settei = genkoryoSettei;
          }
        }
      }
      //ori_bikou,sakuhin_bikouプロパティ
      daiwariData.ori_array.forEach((ori) => {
        // 選択されていない加工区分を削除する
        if (ori.kakou_array.length > 0) {
          ori.kakou_array = ori.kakou_array.filter((kakou) => {
            return kakou.kbn !== "" && kakou.kbn !== "未設定";
          });
        }

        // if (ori.kakou_array.length === 0) {
        //   ori.kakou_array.push({ kbn: "未設定" });
        // }
        // // 選択されていない加工区分を「未設定」にする
        // else if (ori.kakou_array[0].kbn === "") {
        //   ori.kakou_array[0].kbn = "未設定";
        // }
        ori.ori_bikou_flg = false;
        if (!ori.ori_bikou) {
          ori.ori_bikou = "";
        }
        if ("ori_bikou_active_flg" in ori == false) {
          ori.ori_bikou_active_flg = false;
        }
        ori.sakuhin_array.forEach((sakuhin) => {
          if ("sakuhin_bikou_markflg" in sakuhin == false) {
            sakuhin.sakuhin_bikou_markflg = false;
          }
          if ("sakuhin_bikou_flg" in sakuhin == false) {
            sakuhin.sakuhin_bikou_flg = false;
            sakuhin.sakuhin_bikou_flg = false;
          }
          if (!sakuhin.sakuhin_bikou) {
            sakuhin.sakuhin_bikou = "";
          }
        });
      });

      // 作品idの重複を解消する
      //作品idのみが入った配列を作成し重複分を抽出する
      let sakuhinIdArray = [];
      daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (
            (!sakuhin.is_divided ||
              (sakuhin.is_divided && sakuhin.is_parent)) &&
            sakuhin.sakuhin_id != -1
          )
            sakuhinIdArray.push(sakuhin.sakuhin_id);
        });
      });
      // console.log(sakuhinIdArray);
      let duplicatedSakuhinIdArray = sakuhinIdArray.filter(function (
        x,
        i,
        self
      ) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });
      // console.log("重複した作品id", duplicatedSakuhinIdArray);

      duplicatedSakuhinIdArray.forEach((sakuhinId) => {
        let settingSakuhinId = 0;
        let settingAsterisk = "";
        daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (sakuhin.sakuhin_id == sakuhinId) {
              if (sakuhin.is_divided && sakuhin.is_parent) {
                // divideしている場合、まず親作品にidを付与
                settingSakuhinId = sakuhinId;
                sakuhin.sakuhin_id =
                  settingAsterisk == ""
                    ? settingSakuhinId
                    : settingSakuhinId + settingAsterisk;
                // 子作品に親作品のidを付与
                daiwariData.ori_array.forEach((secondOri) => {
                  secondOri.sakuhin_array.forEach((secondSakuhin) => {
                    if (
                      secondSakuhin.orderOfTheSakuhin ==
                      sakuhin.orderOfTheSakuhin
                    ) {
                      secondSakuhin.sakuhin_id = sakuhin.sakuhin_id;
                    }
                  });
                });
                settingAsterisk += "*";
              } else if (!sakuhin.is_divided) {
                // divideしていない場合
                settingSakuhinId = sakuhinId;
                sakuhin.sakuhin_id =
                  settingAsterisk == ""
                    ? settingSakuhinId
                    : settingSakuhinId + settingAsterisk;
                settingAsterisk += "*";
              }
            }
          });
        });
      });
      // -----------------------------------------------

      util.rebuildDaiwariData(daiwariData, maxAddress, maxSameGroupCd);
      this.daiwariData = daiwariData;
      this.update_daiwariSakuhinArray();
      //ページ備考周りの設定リセット
      pageBikou.set_initialPageBikouArray(
        daiwariData,
        this.daiwariSakuhinArray
      );

      util.rebuildDaiwariData(daiwariData, maxAddress, maxSameGroupCd);
      this.daiwariData = daiwariData;
      this.update_daiwariSakuhinArray();
      this.daiwariData.sakuhin_page_bikou_array =
        pageBikou.sort_sakuhin_page_bikou_array(
          this.daiwariData.sakuhin_page_bikou_array,
          this.daiwariSakuhinArray,
          this.daiwariData
        );
      this.daiwariData.oriMaximumPage = this.oriMaximumPage;
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin, index) => {
          let sakuhin_index = index;
          sakuhin.genka_array.forEach((genka, index) => {
            genka.genka_index = index;
            genka.sakuhin_index = sakuhin_index;
          });
        });
      });

      //ページ範囲周りの設定リセット
      if ("page_range_bikou_array" in this.daiwariData === false) {
        this.daiwariData.page_range_bikou_array = [];
      }

      // 外注加工費、科目不明追加前のgenka_arrayに対する処理
      for (const ori of this.daiwariData.ori_array) {
        for (const sakuhin of ori.sakuhin_array) {
          for (const genka of sakuhin.genka_array) {
            let hasGaichuKakouhi = false;
            let hasKamokuHumei = false;
            for (const genkaInfo of genka.genkaInfo_array) {
              if (genkaInfo.meisyo === "外注加工費") {
                hasGaichuKakouhi = true;
              }
              if (genkaInfo.meisyo === "科目不明") {
                hasKamokuHumei = true;
              }
            }
            if (hasGaichuKakouhi === false) {
              genka.genkaInfo_array.push({
                genkaKbnId: 1,
                meisyo: "外注加工費",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              });
            }
            if (hasKamokuHumei === false) {
              genka.genkaInfo_array.push({
                genkaKbnId: 999999999,
                meisyo: "科目不明",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              });
            }
          }
        }
      }
      // genkaInfo_arrayの新旧で順番が異なるのを並び替える
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          sakuhin.genka_array.forEach((genka) => {
            genka.genkaInfo_array.sort((x, y) => {
              return x.genkaKbnId - y.genkaKbnId;
            });
            // 自動追加フラグがない場合追加する
            if ("isAutoAppend" in genka === false) {
              if (sakuhin.penName.length > 0) {
                sakuhin.penName.forEach((penname) => {
                  // 自動追加
                  if (
                    genka.torihikisaki.torihikisakiId ===
                      penname.torihikisakiId &&
                    genka.genkaKbnId === 3 &&
                    sakuhin.genkoryo_settei.setteiKbn !== 2
                  ) {
                    genka.isAutoAppend = true;
                  }
                  // 手入力
                  else {
                    genka.isAutoAppend = false;
                  }
                });
              } else {
                genka.isAutoAppend = false;
              }
            }

            // sakuhin_indexがない場合追加する
            if ("sakuhin_index" in genka === false) {
              genka.sakuhin_index = sakuhin.sakuhin_address_computed - 1;
            }

            // 原稿料のtankaInfo_arrayに「色数：その他」がない場合追加する
            genka.genkaInfo_array.forEach((genkaInfo) => {
              let hasOther = false;
              if (genkaInfo.genkaKbnId === 3) {
                genkaInfo.tankaInfo_array.forEach((tanka) => {
                  if (tanka.sikisuu === "その他") {
                    hasOther = true;
                  }
                });
                if (hasOther === false) {
                  genkaInfo.tankaInfo_array.push({
                    sikisuu: "その他",
                    tanka: 0,
                    kingaku: 0,
                    overwriteTankaFlg: false,
                    defaultTanka: 0,
                  });
                }
              }
            });
          });
        });
      });

      // 原価データのtorihikisakiにpennameとpennameIdが存在しない場合追加する
      // 作品に紐づく原価
      for (let ori of this.daiwariData.ori_array) {
        for (let sakuhin of ori.sakuhin_array) {
          for (let genka of sakuhin.genka_array) {
            if (
              genka.torihikisaki !== "" &&
              ("penname" in genka.torihikisaki === false ||
                "pennameId" in genka.torihikisaki === false)
            ) {
              const res = await this.getTorihikisakiData(
                genka.torihikisaki.torihikisakiMei
              );
              // 検索結果が0件の場合
              if (res.length === 0) {
                return;
              }
              // 検索結果が存在する場合
              else {
                res.torihikisakiList.forEach((torihikisaki) => {
                  if (
                    genka.torihikisaki.torihikisakiId ===
                      torihikisaki.torihikisakiId &&
                    torihikisaki.pennameList.length === 1
                  ) {
                    delete genka.torihikisaki.penname;
                    genka.torihikisaki.penname =
                      torihikisaki.pennameList[0].penname;
                    delete genka.torihikisaki.pennameId;
                    genka.torihikisaki.pennameId =
                      torihikisaki.pennameList[0].pennameId;
                  }
                });
              }
            }
          }
        }
      }

      // 作品に紐づかない原価
      for (let daiwariGenka of this.daiwariData.daiwari_genkaArray) {
        if (
          daiwariGenka.torihikisaki !== "" &&
          ("penname" in daiwariGenka.torihikisaki === false ||
            "pennameId" in daiwariGenka.torihikisaki === false)
        ) {
          const res = await this.getTorihikisakiData(
            daiwariGenka.torihikisaki.torihikisakiMei
          );
          // 検索結果が0件の場合
          if (res.length === 0) {
            return;
          }
          // 検索結果が存在する場合
          else {
            res.torihikisakiList.forEach((torihikisaki) => {
              if (
                daiwariGenka.torihikisaki.torihikisakiId ===
                  torihikisaki.torihikisakiId &&
                torihikisaki.pennameList.length === 1
              ) {
                delete daiwariGenka.torihikisaki.penname;
                daiwariGenka.torihikisaki.penname =
                  torihikisaki.pennameList[0].penname;
                delete daiwariGenka.torihikisaki.pennameId;
                daiwariGenka.torihikisaki.pennameId =
                  torihikisaki.pennameList[0].pennameId;
              }
            });
          }
        }
      }

      //電子台割(新規)の場合親台割を引き継ぐ
      if (this.isDensi && this.daiwariData.ori_array.length === 0) {
        if (!this.isLockDaiwari) {
          this.fetchParentDaiwariData();
        }
      }
      // 電子台割(既存)のとき、原価情報は引き継がないため削除する
      else if (this.isDensi && !this.isLockDaiwari) {
        this.daiwariData.daiwari_genkaArray.splice(0);
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            sakuhin.genka_array.splice(0);
          });
        });
      }
      this.isProcessing = false;
    },
    // 【set_initialDaiwariDataで使用】
    // ペンネームに基づいて取引先検索APIから、ペンネームに紐づく取引先情報を取得
    async getTorihikisakiData(penname) {
      try {
        const res = await axios.get(
          `${this.url}/get_torihikisaki_split?torihikisaki_mei=${penname}&page=1`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        return res.data;
      } catch (error) {
        console.log("取引先データが取得できませんでした\n" + error);
      }
    },
    // aK,nK,aR,nRは重複状態で保存後再度開くと、自動的に連番に修正されているため、折区分の重複チェックを行う
    setInitialDuplicatedOriKbn() {
      //区分が重複した折をハイライトさせるためのプロパティ
      this.daiwariData.ori_array.forEach((ori) => {
        ori.is_duplicated_ori_kbn = false;
      });

      //折区分のみが入った配列を作成し重複した折区分を抽出する
      let oriKbnArray = [];
      this.daiwariData.ori_array.forEach((ori) => {
        oriKbnArray.push(ori.ori_kbn);
      });
      let duplicatedOriKbnArray = oriKbnArray.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });
      //折区分「表紙」は重複チェックの対象に入らないため「表紙」以外の折に絞り込む
      duplicatedOriKbnArray = duplicatedOriKbnArray.filter(
        (kbn) => kbn !== "表紙"
      );
      if (duplicatedOriKbnArray.length > 0) {
        // notyf.error("折区分に重複個所があります");
        this.daiwariData.ori_array.forEach((ori) => {
          duplicatedOriKbnArray.forEach((dup) => {
            if (ori.ori_kbn == dup && dup !== "表紙") {
              ori.is_duplicated_ori_kbn = true;
            }
          });
        });
      }
      // 折コンポーネントのメソッドを発火させ、重複の色付けを行う
      this.$nextTick(() => {
        this.daiwariData.ori_array.forEach((ori, index) => {
          this.$refs["oriComp" + index].reflectDuplicatedOriKbn();
        });
      });
    },

    //ヘッダーボタン群のアコーディオン開閉処理
    hide_acordion() {
      if (this.acordionPosition !== 0) {
        this.acordionPosition = 0;
      }
    },
    //ヘッダーボタン群のアコーディオン制御
    openAcordion(acordion_position) {
      if (this.isJuhanDaiwari) return;
      if (this.acordionPosition !== acordion_position) {
        this.acordionPosition = acordion_position;
      } else {
        this.acordionPosition = 0;
      }
    },
    //作品コピー--------------------------------------------------------------------------------
    pasteSakuhin(hukusei_flg, isActiveFront) {
      //貼り付け先の折・作品のindex取得
      if (hukusei_flg) {
        //作品の複製作業
        let hukusei_sakuhin = JSON.parse(JSON.stringify(this.copiedSakuhin));
        //コピー元を残す
        let to_ori_index = this.selectedSakuhin.parent_ori_address_computed - 1;
        let to_sakuhin_index =
          this.selectedSakuhin.sakuhin_address_computed - 1;
        // 初期値として*なしの作品idをセット(重複処理の*の計算を作品idの初期値から開始したいため)
        hukusei_sakuhin.sakuhin_id = Number(
          String(hukusei_sakuhin.sakuhin_id).replace(/\*/g, "")
        );
        hukusei_sakuhin.sakuhin_uniqueId = util.getUniqueStr();
        const sakuhin_bikouId = util.getUniqueStr();
        hukusei_sakuhin.sakuhin_bikouId = sakuhin_bikouId;

        // 作品idの重複を解消する
        let duplicatedCount = 0;
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (sakuhin.sakuhin_id !== -1) {
              //sakuhinIdで重複チェック
              //チェック先がdividedしている場合
              if (
                sakuhin.is_divided &&
                sakuhin.is_parent &&
                sakuhin.sakuhin_id === hukusei_sakuhin.sakuhin_id
              ) {
                duplicatedCount++;
              }
              //チェック先がdividedしていない場合
              else if (
                sakuhin.is_divided == false &&
                sakuhin.sakuhin_id === hukusei_sakuhin.sakuhin_id
              ) {
                duplicatedCount++;
              }
            }
          });
        });
        if (duplicatedCount >= 1) {
          //末尾に追加する「*」の数を計算する
          let isMatching = false;
          do {
            isMatching = false;
            for (let ori of this.daiwariData.ori_array) {
              for (let sakuhin of ori.sakuhin_array) {
                if (util.is_matched_sakuhinId(hukusei_sakuhin, sakuhin)) {
                  hukusei_sakuhin.sakuhin_id = sakuhin.sakuhin_id + "*";
                  isMatching = true;
                  break;
                }
              }
              if (isMatching == true) {
                break;
              }
            }
          } while (isMatching == true);
          notyf.error("作品名が重複しています");
        }
        // sakuhin_uniqueIdのセット
        if (Number(hukusei_sakuhin.sakuhin_id) !== -1) {
          hukusei_sakuhin.sakuhin_uniqueId = 0;
        }

        if (isActiveFront) {
          //前方へ複製
          this.daiwariData.ori_array[to_ori_index].sakuhin_array.splice(
            to_sakuhin_index,
            0,
            hukusei_sakuhin
          );
        } else {
          //後方へ複製
          let target_ori;
          let target_index;
          this.daiwariData.ori_array.forEach((ori, oriIndex) => {
            ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
              if (util.is_matched_sakuhinId(sakuhin, this.selectedSakuhin)) {
                target_ori = oriIndex;
                target_index = sakuhinIndex;
              }
            });
          });
          this.daiwariData.ori_array[target_ori].sakuhin_array.splice(
            target_index + 1,
            0,
            hukusei_sakuhin
          );
        }
        //コピー元のページ備考情報(id以外)を引き継いだ備考オブジェクトを追加
        const target_bikou = this.daiwariData.sakuhin_page_bikou_array.find(
          (bikou) =>
            bikou.sakuhin_bikouId ===
            JSON.parse(JSON.stringify(this.copiedSakuhin)).sakuhin_bikouId
        );
        const pushData = new pageBikou.HukuseiPageBikou(
          hukusei_sakuhin,
          sakuhin_bikouId,
          JSON.parse(JSON.stringify(target_bikou))
        );
        this.daiwariData.sakuhin_page_bikou_array.push(pushData);
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        this.update_daiwariSakuhinArray();
        //ページ備考のハイライト用に台割本体と備考配列の作品の順番を揃える
        this.daiwariData.sakuhin_page_bikou_array =
          pageBikou.sort_sakuhin_page_bikou_array(
            this.daiwariData.sakuhin_page_bikou_array,
            this.daiwariSakuhinArray,
            this.daiwariData
          );
      } else {
        //コピー元を残さない
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
            if (util.is_matched_sakuhinId(sakuhin, this.copiedSakuhin)) {
              ori.sakuhin_array.splice(sakuhinIndex, 1);
            }
          });
        });
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        let to_ori_index = this.selectedSakuhin.parent_ori_address_computed - 1;
        let to_sakuhin_index =
          this.selectedSakuhin.sakuhin_address_computed - 1;
        if (isActiveFront) {
          //前方
          this.daiwariData.ori_array[to_ori_index].sakuhin_array.splice(
            to_sakuhin_index,
            0,
            this.copiedSakuhin
          );
        } else {
          //後方
          let target_ori;
          let target_index;
          this.daiwariData.ori_array.forEach((ori, oriIndex) => {
            ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
              if (util.is_matched_sakuhinId(sakuhin, this.selectedSakuhin)) {
                target_ori = oriIndex;
                target_index = sakuhinIndex;
              }
            });
          });
          this.daiwariData.ori_array[target_ori].sakuhin_array.splice(
            target_index + 1,
            0,
            this.copiedSakuhin
          );
        }
        notyf.success("作品を貼り付けました");
        this.copiedSakuhin = null;
      }
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.modalName = "";
    },
    replaceSakuhin() {
      //コピー元
      let from_ori_index = this.copiedSakuhin.parent_ori_address_computed - 1;
      let from_sakuhin_index = this.copiedSakuhin.sakuhin_address_computed - 1;
      //貼り付け先
      let to_ori_index = this.selectedSakuhin.parent_ori_address_computed - 1;
      let to_sakuhin_index = this.selectedSakuhin.sakuhin_address_computed - 1;

      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
          if (util.is_matched_sakuhinId(sakuhin, this.copiedSakuhin)) {
            ori.sakuhin_array.splice(sakuhinIndex, 1);
          }
        });
      });
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
          if (util.is_matched_sakuhinId(sakuhin, this.selectedSakuhin)) {
            ori.sakuhin_array.splice(sakuhinIndex, 1);
          }
        });
      });
      if (this.copiedSakuhin.is_divided) {
        this.copiedSakuhin.is_divided = false;
        this.copiedSakuhin.divided_page = 0;
      }
      if (this.selectedSakuhin.is_divided) {
        this.selectedSakuhin.is_divided = false;
        this.selectedSakuhin.divided_page = 0;
      }
      if (
        (to_ori_index == from_ori_index &&
          to_sakuhin_index > from_sakuhin_index) ||
        to_ori_index !== from_ori_index
      ) {
        this.daiwariData.ori_array[from_ori_index].sakuhin_array.splice(
          from_sakuhin_index,
          0,
          this.selectedSakuhin
        );
        this.daiwariData.ori_array[to_ori_index].sakuhin_array.splice(
          to_sakuhin_index,
          0,
          this.copiedSakuhin
        );
      } else {
        this.daiwariData.ori_array[to_ori_index].sakuhin_array.splice(
          to_sakuhin_index,
          0,
          this.copiedSakuhin
        );
        this.daiwariData.ori_array[from_ori_index].sakuhin_array.splice(
          from_sakuhin_index,
          0,
          this.selectedSakuhin
        );
      }

      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
      this.daiwariData.sakuhin_page_bikou_array =
        pageBikou.sort_sakuhin_page_bikou_array(
          this.daiwariData.sakuhin_page_bikou_array,
          this.daiwariSakuhinArray,
          this.daiwariData
        );
      this.addSakuhinAfterFlg();
      this.modalName = "";
      notyf.success("作品を入れ替えました");
      this.copiedSakuhin = null;
    },
    copySakuhin() {
      //参照渡し
      this.copiedSakuhin = {
        ...this.selectedSakuhin,
        is_selected_sakuhin: false,
      };
      this.modalName = "";
      notyf.success("作品をコピーしました");
    },
    sakuhinCopyPaste(rightClickSakuhin) {
      // sidebarを変更
      this.selectedSideBar = 2;
      //旧ハイライトがあれば削除(作品)
      if (this.selectedSakuhin != null) {
        this.selectedSakuhin.is_selected_sakuhin = false;
      }
      // 旧ハイライトを削除(折)
      if (this.selectedOri != null) {
        this.selectedOri.is_selected_ori = false;
      }

      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          sakuhin.is_selected_sakuhin = false;
        });
      });

      if (rightClickSakuhin) {
        let target_sakuhin = rightClickSakuhin;
        if (target_sakuhin.is_divided && !target_sakuhin.is_parent) {
          this.daiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              if (
                util.is_matched_sakuhinId(sakuhin, target_sakuhin) &&
                sakuhin.is_parent
              ) {
                this.selectedSakuhin = sakuhin;
              }
            });
          });
        } else {
          this.selectedSakuhin = target_sakuhin;
        }
      }

      // 新ハイライトを設定
      this.selectedSakuhin.is_selected_sakuhin = true;
      this.addSakuhinAfterFlg();
      //コピーモーダルorペーストモーダルの判定
      if (!this.isLockDaiwari) {
        if (this.copiedSakuhin == null) {
          this.modalName = "modal_copy";
        } else {
          this.modalName = "modal_paste";
        }
      }
    },
    //-----------------------------------------------------------------------------------------

    //ストレージへの台割仮保存(1分毎)
    saveDaiwari_onStorage() {
      let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
      daiwariDataCopy.ori_array.forEach((ori) => {
        ori.is_selected_ori = false;
        ori.sakuhin_array.forEach((sakuhin) => {
          sakuhin.is_selected_sakuhin = false;
        });
      });

      const strageData = {
        daiwariId: this.daiwariId,
        daiwariData: daiwariDataCopy,
        kikakuId: kikakuId,
      };
      //localStorageに保存
      localStorage.setItem(
        `daiwari_${this.daiwariId}`,
        JSON.stringify(strageData)
      );
    },
    showModal(showModalName, modalData) {
      if (showModalName === "modal_daiwariKakutei") {
        this.update_daiwariSakuhinArray();
      }
      this.modalName = showModalName;
      //一覧表示モーダルの場合---
      if (this.modalName === "modal_listDisplay") {
        this.update_daiwariSakuhinArray();
        let shouldKakutei = modalData;
        this.shouldGenkaKakutei = shouldKakutei ? true : false;
        this.calc_totalKingaku();
        this.isDaiwariSaved();
      } else if (this.modalName === "modal_tantou") {
        //担当者モーダルの場合---
        this.props_tantou = null;
        if (modalData) {
          this.tantou_ikkatuFlg = modalData;
        } else {
          this.tantou_ikkatuFlg = false;
        }
      } else if (this.modalName === "modal_torihikisaki") {
        //取引先モーダルの場合--- // 使ってる？
        let index = modalData;
        this.torihikisakiIndex = index;
        this.props_torihiki = null;
      } else if (this.modalName === "modal_errorMessage") {
        //台割確定エラーモーダルの場合---
        let alert_message = modalData;
        this.daiwariErrorList = [];
        this.daiwariErrorList = [...alert_message];
      } else if (this.modalName === "modal_sikisuu") {
        //色数モーダル(折追加時)の場合---
        if (modalData == "overflow") {
          this.insertOriIndex =
            this.selectedSakuhin.parent_ori_address_computed - 1;
        } else {
          this.insertOriIndex = null;
        }
      } else if (this.modalName === "modal_penName") {
        // ペンネームモーダルでの作品に紐づくペンネーム表示用データ
        this.sakuhinInfoForPennameModal = {
          sakuhinId: Number(
            String(this.selectedSakuhin.sakuhin_id).replace(/[*]/g, "")
          ),
          sakuhinMei: this.selectedSakuhin.sakuhin_mei,
        };
        //ペンネームモーダルの場合---
        if (modalData) {
          this.penName_ikkatuFlg = modalData;
        } else {
          this.penName_ikkatuFlg = false;
        }
      } else if (this.modalName === "modal_workingHistory") {
        this.kakuteiRireki_flg = false;
      } else if (this.modalName === "modal_daiwariKakuteiHistory") {
        this.kakuteiRireki_flg = true;
      } else if (this.modalName === "modal_edaban") {
        this.isDaiwariSaved();
      }
    },

    //モーダル閉じるhideModalメソッド
    hideModal(hideModalName, modalData) {
      if (this.modalName === "") {
        this.isOpenTokusyuSiyoModal = false;
      }
      //色数・区分設定モーダルの場合---
      else if (this.modalName === "modal_sikisuu") {
        const shouldAddOri = modalData.shouldAddOri;
        const insertOriIndex = modalData.insertOriIndex;
        if (shouldAddOri) {
          this.$nextTick(() => {
            let rowList = document.querySelectorAll(".row");
            const targetLowIndex =
              0 <= insertOriIndex ? insertOriIndex : rowList.length - 1;

            let targetRow = rowList[targetLowIndex];
            targetRow.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "end",
            });
            targetRow.classList.add("added_ori");
            setTimeout(() => {
              targetRow.classList.remove("added_ori");
            }, 4000);
          });
        }
        this.modalName = "";
      }
      // 保存確認モーダル、台割エラーモーダルの場合---
      else if (
        this.modalName === "modal_saveConfimation" ||
        this.modalName === "modal_daiwariError"
      ) {
        let shouldSaveSaiwari = modalData;
        if (shouldSaveSaiwari === "true") {
          this.saveDaiwari();
          // 保存して戻る
          if (this.modalName !== "modal_oddCheck") {
            localStorage.removeItem(`daiwari_${this.daiwariId}`);
            let query = {};
            if (
              this.pageTransitionTo === null ||
              this.pageTransitionTo === "/daiwari"
            ) {
              this.pageTransitionTo = "/daiwari";
              query = this.isJuhanDaiwari
                ? { isJuhan: true }
                : this.isKanriBusyoFlg
                ? { status: 0, busyo: 0 }
                : {};
            } else if (this.pageTransitionTo === "/kikaku_edit") {
              query = { kikakuId: this.kikakuId };
            }
            this.$router.push({ path: this.pageTransitionTo, query: query });
          }
        } else if (shouldSaveSaiwari === "false") {
          localStorage.removeItem(`daiwari_${this.daiwariId}`);
          // 保存せず戻る
          let query = {};
          if (
            this.pageTransitionTo === null ||
            this.pageTransitionTo === "/daiwari"
          ) {
            this.pageTransitionTo = "/daiwari";
            query = this.isJuhanDaiwari
              ? { isJuhan: true }
              : this.isKanriBusyoFlg
              ? { status: 0, busyo: 0 }
              : {};
          } else if (this.pageTransitionTo === "/kikaku_edit") {
            query = { kikakuId: this.kikakuId };
          }
          this.$router.push({ path: this.pageTransitionTo, query: query });
        } else if (shouldSaveSaiwari === "cancel") {
          // キャンセル
          this.modalName = "";
        }
      } else if (this.modalName === "modal_timeout") {
        //タイムアウトモーダルの場合---
        this.modalName = "";
        this.pageTransitionTo = this.isJuhanDaiwari
          ? "/daiwari?isJuhan=true"
          : this.isKanriBusyoFlg
          ? "/daiwari?status=0&busyo=0"
          : "/daiwari";
        location.href = this.url + this.pageTransitionTo;
      } else if (this.modalName === "modal_daiwariUnlock") {
        //台割ロック(排他制御)モーダルの場合---
        let isLock = modalData;
        if (!isLock) {
          //編集可能にする
          this.daiwariHeader.isLock = false;
          window.location.reload();
        }
        this.modalName = "";
      } else if (
        hideModalName === "modal_tantou" ||
        hideModalName === "modal_torihikisaki" ||
        hideModalName === "modal_sakuhinMei"
      ) {
        // 担当者, 取引先,作品名モーダルの場合（二重モーダル）
        if (modalData === true) {
          // 2枚目のモーダルの場合の処理
          this.modalOnModalName = "";
        } else {
          this.modalName = "";
        }
      }
      // 原価台帳で使用するペンネーム検索の場合（二重モーダル）
      else if (
        hideModalName === "modal_relatedGenka_torihikisaki" ||
        hideModalName === "modal_independentGenka_torihikisaki"
      ) {
        this.modalOnModalName = "";
        this.props_torihiki = null;
        this.$refs.daiwariList.setFocus();
      } else {
        this.modalName = "";
      }
    },

    // 関連台割リンククリック
    onClickRelatedDaiwariLink(relatedDaiwari) {
      new Promise((resolve) => {
        //未保存か否かを判定(判定前にダイアログ処理が走るためpromise)
        this.isDaiwariSaved();
        resolve();
      }).then(() => {
        if (!relatedDaiwari.isSelected) {
          //未保存ならページ遷移して良いかの確認
          let isAllowedToMoveChildDaiwari = true;
          if (!this.is_daiwari_saved) {
            isAllowedToMoveChildDaiwari = window.confirm(
              "編集中の台割が未保存ですが移動してもよろしいですか？"
            );
          }
          if (!isAllowedToMoveChildDaiwari) {
            return;
          }
          if (relatedDaiwari.daiwariId && relatedDaiwari.kikakuId) {
            //urlのparamsを遷移先の台割情報に更新
            const url = new URL(window.location);
            url.searchParams.delete("isEdaban");
            url.searchParams.set("daiwariId", relatedDaiwari.daiwariId);
            url.searchParams.set("kikakuId", relatedDaiwari.kikakuId);
            if (relatedDaiwari.isEdaban) {
              url.searchParams.set("isEdaban", true);
            }
            new Promise((resolve) => {
              //台割のロックを解除する
              this.unLockDaiwari();
              this.hide_acordion();
              this.hideModal();
              resolve();
            }).then(() => {
              //枝番画面に遷移
              window.history.pushState({ name: "daiwari" }, "", url);
              window.location.reload();
            });
          } else {
            alert("台割枝番の情報が不足しているため編集が出来ません");
          }
        }
      });
    },

    unLockDaiwari() {
      const params = new URL(location.href).searchParams;
      if (
        params.get("hachuId") === null &&
        (params.get("isYomitori") === null ||
          params.get("isYomitori") === "false")
      ) {
        const postData = {
          daiwariId: this.daiwariId,
        };
        //isLock=falseの場合ロック解除
        if (!this.daiwariHeader.isLock) {
          axios
            .post(
              `${this.url}/daiwari/unlock_daiwari?daiwariId=${this.daiwariId}`,
              postData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            )
            .then(() => {
              console.log("ロックを解除しました");
            })
            .catch((error) => {
              console.log("台割ロックの解除に失敗しました");
              console.log(error);
            });
        }
      }
    },
    fetch_daiwariLockInfo() {
      //台割がまだロック中かを判定
      const setInterval_isLock = setInterval(() => {
        axios
          .get(`${this.url}/is_lock_daiwari?daiwari_id=${this.daiwariId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          })
          .then((response) => {
            if (!response.data.isLock) {
              //インターバルの削除
              clearInterval(setInterval_isLock);
              this.showModal("modal_daiwariUnlock");
            }
          });
      }, 10000);
    },
    showOverlay(overlayName, overlayId, overlayData) {
      //複写参照オーバーレイの場合
      if (overlayName === "overlay_daiwariCopyReference") {
        this.referenceTitle = overlayData.referenceTitle;
        this.overlayName = overlayName;
        this.overlayId = overlayId;
        this.daiwariTorokuDate = overlayData.torokuDate;

        this.copyFromData.isChild = overlayData.isCopyEda;
        this.copyFromData.isNakatoji = overlayData.isNakatoji;
        this.copyToData.isChild = this.daiwariHeader.isChild;
        this.copyToData.isNakatoji = this.isNakatojiFlg;

        if (overlayData.copyKikakuId) {
          this.copyKikakuId = overlayData.copyKikakuId;
        }
      }
      //履歴照会オーバーレイの場合
      if (overlayName === "overlay_daiwariHistoryReference") {
        this.overlayName = overlayName;
        this.overlayId = overlayId;
        this.rirekiTorokuUserMei = overlayData.torokuUserMei;
        this.rirekiTorokuDate = overlayData.torokuDateStr;
        this.kakuteiRireki_flg = overlayData.kakuteiRirekiFlg;
        this.rirekiUrl = overlayData.rirekiUrl;
      }
      //台割出力オーバーレイの場合
      if (overlayName === "overlay_daiwariOutput") {
        let hyosiOriCount = 0; //中綴じかつ表紙の折が未設定の場合は出力画面を開かない
        let hyosiError_flg = false; //表示の位置が最前・最後折にセットされているか
        this.daiwariData.ori_array.forEach((ori) => {
          if (
            this.isNakatojiFlg &&
            ori.ori_kbn == "表紙" &&
            ori.ori_address_computed !== 1 &&
            ori.ori_address_computed !== this.daiwariData.ori_array.length
          ) {
            hyosiError_flg = true;
          }
          if (this.isNakatojiFlg && ori.ori_kbn == "表紙") {
            hyosiOriCount++;
          }
        });

        if (this.isNakatojiFlg && hyosiOriCount < 2) {
          notyf.error("[折区分:表紙]の折を設定してください");
        } else if (hyosiError_flg) {
          notyf.error("[折区分:表紙]の位置を修正してください");
        } else {
          this.isProcessing = true;
          //【台割出力印刷ボタン非活性用】編集中台割のJSONデータを生成
          this.updateCurrentRowDaiwariData();

          this.overlayName = "overlay_daiwariOutput";

          this.fromOutputBtn = true;
          this.update_daiwariSakuhinArray();
          this.modalName = "modal_daiwariKakutei";
        }
      }
      //ページ範囲オーバーレイの場合
      if (overlayName === "overlay_pageRangeBikou") {
        this.overlayName = overlayName;
      }

      // オーバーレイが表示されたらサイドバーを非表示にする
      if (this.overlayName !== "" && overlayName !== "overlay_pageRangeBikou") {
        this.$emit("toggleGlobalHeader");
      }
    },
    hideOverlay(overlayName, overlayData) {
      if (overlayName === "overlay_daiwariOutput") {
        this.daiwariData.outputSyosiData = overlayData;
        this.fromOutputBtn = false;
      }
      this.overlayName = "";

      // オーバーレイが非表示になったらサイドバーを再表示する
      if (this.overlayName === "") {
        this.$emit("showGlobalHeader");
      }
    },
    // 台割出力画面の印刷ボタン非活性判定結果を格納
    openOutputOverlay(hasChanges, prevSeisakuBikou, hasKakuteiRireki) {
      this.hasDaiwariKakuteiChanges = hasChanges;
      this.prevSeisakuBikou = prevSeisakuBikou;
      this.hasKakuteiRireki = hasKakuteiRireki;
      this.modalName = "";
      this.isProcessing = false;
    },

    //バックアップを保存する--------------------------------------------------------
    save_backupDaiwari(daiwariData, selectedSakuhin, selectedOri) {
      //更新時一番古いバックアップを参照しているとき現在のバックアップを削除
      if (this.backup_index === 0 && this.backup_daiwariArray) {
        this.backup_daiwariArray = [];
        this.backup_selectedOriArray = [];
        this.backup_selectedSakuhinArray = [];
      }
      //バックアップが上限(5つ)に達したら、1番古いバックアップを削除
      if (this.backup_daiwariArray.length >= 5) {
        this.backup_daiwariArray.shift();
        this.backup_selectedOriArray.shift();
        this.backup_selectedSakuhinArray.shift();
      }

      //バックアップに保存
      this.backup_daiwariArray.push(JSON.parse(JSON.stringify(daiwariData)));
      this.backup_selectedOriArray.push(
        JSON.parse(JSON.stringify(selectedOri))
      );
      this.backup_selectedSakuhinArray.push(
        JSON.parse(JSON.stringify(selectedSakuhin))
      );
      //バックアップ更新後は最新の参照インデックスを指定
      this.backup_index = this.backup_daiwariArray.length;

      //確認用
      // console.log("-保存後-", this.backup_daiwariArray_status);
    },

    // 前の作業に戻る(UNDO)
    undo_process() {
      if (this.backup_index > 0) {
        this.backup_index--;
        this.restore_process();
      }
    },
    // 後の作業へ進める(REDO)
    redo_process() {
      if (this.backup_index < this.backup_daiwariArray.length) {
        this.backup_index++;
        if (this.backup_index === 1 && this.backup_daiwariArray.length === 1) {
          this.backup_index = 0;
        }
        this.restore_process();
      }
    },
    //台割・サイドバー・ハイライトの復元
    restore_process() {
      //値渡しする
      let target_daiwari = JSON.parse(
        JSON.stringify(this.backup_daiwariArray[this.backup_index])
      );
      this.daiwariData = target_daiwari;
      this.oriMaximumPage = target_daiwari.oriMaximumPage;
      util.rebuildDaiwariData(target_daiwari, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();

      //selectedOriの復元
      this.selectedOri = null;
      let target_ori = this.backup_selectedOriArray[this.backup_index];

      if (target_ori) {
        this.selectedOri =
          this.daiwariData.ori_array[target_ori.ori_address_computed - 1];
      }
      //selectedSakuhinの復元
      this.selectedSakuhin = null;
      let target_sakuhin = this.backup_selectedSakuhinArray[this.backup_index];
      if (target_sakuhin) {
        this.selectedSakuhin =
          this.daiwariData.ori_array[
            target_sakuhin.parent_ori_address_computed - 1
          ].sakuhin_array[target_sakuhin.sakuhin_address_computed - 1];
      }

      //サイドバーの判定用
      const is_selected_ori = target_ori ? target_ori.is_selected_ori : false;
      const is_selected_sakuhin = target_sakuhin
        ? target_sakuhin.is_selected_sakuhin
        : false;

      //サイドバーの復元
      if (!is_selected_ori && !is_selected_sakuhin) {
        this.selectedSideBar = 0;
      } else if (is_selected_ori && !is_selected_sakuhin) {
        this.selectedSideBar = 1;
      } else if (!is_selected_ori && is_selected_sakuhin) {
        this.selectedSideBar = 2;
      }
    },
    setTokusyuSiyou(postData_tokusyuKoumoku) {
      this.tokusyuKoumoku = postData_tokusyuKoumoku;
    },

    //台割一覧用---------------------------------------------------------------
    //表示用の作品配列の生成
    update_daiwariSakuhinArray() {
      this.daiwariSakuhinArray.splice(0);
      let kari_daiwariSakuhinArray = [];
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          kari_daiwariSakuhinArray.push(sakuhin);
        });
      });
      kari_daiwariSakuhinArray.forEach((sakuhin, sakuhinIndex) => {
        if (
          (!sakuhinIndex == 0 &&
            sakuhin.sakuhin_uniqueId !== 0 &&
            sakuhin.sakuhin_uniqueId !==
              kari_daiwariSakuhinArray[sakuhinIndex - 1].sakuhin_uniqueId) ||
          (!sakuhinIndex == 0 &&
            sakuhin.sakuhin_id !== -1 &&
            sakuhin.sakuhin_id !==
              kari_daiwariSakuhinArray[sakuhinIndex - 1].sakuhin_id) ||
          sakuhinIndex == 0
        ) {
          this.daiwariSakuhinArray.push(sakuhin);
        }
      });
    },
    //台割一覧内の原価区分の変更を反映
    editGenka_kbn(sakuhin, genkaIndex, newVal) {
      let update_genkaMeisyo = ""; //新しい原価区分の値
      let selectSakuhinInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1]; //変更対象の作品
      let selectGenkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array[
          genkaIndex
        ]; //変更対象の原価情報
      let target_sikisuu =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sikisuu;
      let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
      selectGenkaInfo.genkaKbnId = Number(newVal); //原価区分IDの更新
      this.genkaKbnList.forEach((genka) => {
        if (genka.genkaKbnId == newVal) {
          update_genkaMeisyo = genka.meisyo; //原価区分名取得
          selectGenkaInfo.meisyo = genka.meisyo; //原価区分名の更新
          if (selectGenkaInfo.meisyo === "原稿料") {
            selectGenkaInfo.genkaInfo_array.forEach((kbn) => {
              if (kbn.meisyo === selectGenkaInfo.meisyo) {
                kbn.suuryo = selectSakuhinInfo.total_page; //数量反映
                if (
                  selectGenkaInfo.torihikisaki &&
                  selectGenkaInfo.torihikisaki.tankaList.length > 0
                ) {
                  if (target_sikisuu_num == 1) {
                    selectGenkaInfo.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 1) {
                          // デフォルト単価に取引先の単価を適用する
                          kbn.tankaInfo_array[0].defaultTanka =
                            defaultTanka.tankaKingaku;
                          // if (kbn.tankaInfo_array[0].tanka == 0) {
                          //   kbn.tankaInfo_array[0].tanka =
                          //     defaultTanka.tankaKingaku;
                          // }
                          // if (kbn.tankaInfo_array[0].kingaku == 0) {
                          //   kbn.tankaInfo_array[0].kingaku =
                          //     kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                          // }
                          if (
                            // 単価を変更後の取引先デフォルト単価に切り替える条件
                            // 「単価が0または上書き単価フラグがfalse」かつ「『原稿料設定が2~4で自動設定されているもの』でない」場合
                            (kbn.tankaInfo_array[0].tanka == 0 ||
                              !kbn.tankaInfo_array[0].overwriteTankaFlg) &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              selectGenkaInfo.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[0].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[0].kingaku =
                              kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                          }
                        }
                      }
                    );
                  } else if (target_sikisuu_num > 3) {
                    selectGenkaInfo.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 2) {
                          kbn.tankaInfo_array[1].defaultTanka =
                            defaultTanka.tankaKingaku;
                          // if (kbn.tankaInfo_array[1].tanka == 0) {
                          //   kbn.tankaInfo_array[1].tanka =
                          //     defaultTanka.tankaKingaku;
                          // }
                          // if (kbn.tankaInfo_array[1].kingaku == 0) {
                          //   kbn.tankaInfo_array[1].kingaku =
                          //     kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                          // }
                          if (
                            (kbn.tankaInfo_array[1].tanka == 0 ||
                              !kbn.tankaInfo_array[1].overwriteTankaFlg) &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              selectGenkaInfo.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[1].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[1].kingaku =
                              kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                          }
                        }
                      }
                    );
                  }
                }
              }
            });
          }
          this.calc_totalKingaku();
        }
      });
      if (sakuhin.is_divided) {
        //変更を子要素に反映
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((saku) => {
            if (util.is_matched_sakuhinId(selectSakuhinInfo, saku)) {
              saku.genka_array[genkaIndex].meisyo = update_genkaMeisyo;
              saku.genka_array[genkaIndex].genkaKbnId = Number(newVal);
            }
          });
        });
        // 原価の変更を子要素に反映
        this.updateGenkaInfo(sakuhin, genkaIndex);
      }
    },
    //台割一覧内の金額上書きを反映
    overwrite_kingaku(event, sakuhin, genka, genkaIndex) {
      event.target.value = event.target.value.replace(/,/g, "");
      let update_genkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array[
          genkaIndex
        ].genkaInfo_array; //変更対象のgenkaInfo_array

      let searchSakuhin =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1]; //変更対象の作品

      update_genkaInfo.forEach((kbn) => {
        if (kbn.genkaKbnId == genka.genkaKbnId) {
          let target_sikisuu =
            this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
              .sikisuu;
          let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
          if (kbn.meisyo === "原稿料") {
            if (target_sikisuu_num == 1) {
              kbn.tankaInfo_array[0].overwriteKingaku = Number(
                event.target.value
              );
              this.calc_totalKingaku();
            } else if (target_sikisuu_num > 3) {
              kbn.tankaInfo_array[1].overwriteKingaku = Number(
                event.target.value
              );
              this.calc_totalKingaku();
            } else if (target_sikisuu_num == 2 || target_sikisuu_num == 3) {
              if ("overwriteKingaku" in kbn.tankaInfo_array[2]) {
                kbn.tankaInfo_array[2].overwriteKingaku = Number(
                  event.target.value
                );
              } else {
                kbn.tankaInfo_array[2].overwriteKingaku = Number(
                  event.target.value
                );
              }
            }
          } else {
            kbn.overwriteKingaku = Number(event.target.value);
            this.calc_totalKingaku();
          }
        }
      });
      if (searchSakuhin.is_divided) {
        this.updateGenkaInfo(searchSakuhin, genkaIndex);
      }
    },
    //台割一覧画面内で数量を変更
    editGenka_suuryo(event, sakuhin, genka, genkaIndex) {
      let update_genkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array[
          genkaIndex
        ].genkaInfo_array; //変更対象のgenkaInfo_array
      let searchSakuhin =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1]; //変更対象の作品

      update_genkaInfo.forEach((kbn) => {
        if (kbn.genkaKbnId == genka.genkaKbnId) {
          let target_sikisuu =
            this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
              .sikisuu;
          let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
          if (kbn.meisyo === "原稿料") {
            kbn.suuryo = Number(event.target.value);
            if (target_sikisuu_num == 1) {
              kbn.tankaInfo_array[0].kingaku =
                Number(event.target.value) *
                Number(kbn.tankaInfo_array[0].tanka);
              this.calc_totalKingaku();
            } else if (target_sikisuu_num > 3) {
              kbn.tankaInfo_array[1].kingaku =
                Number(event.target.value) *
                Number(kbn.tankaInfo_array[1].tanka);
              this.calc_totalKingaku();
            } else if (target_sikisuu_num == 2 || target_sikisuu_num == 3) {
              kbn.tankaInfo_array[2].kingaku =
                Number(event.target.value) *
                Number(kbn.tankaInfo_array[2].tanka);
              this.calc_totalKingaku();
            }
          } else {
            kbn.suuryo = Number(event.target.value);
            kbn.kingaku = Number(event.target.value) * Number(kbn.tanka);
            this.calc_totalKingaku();
          }
        }
      });
      if (searchSakuhin.is_divided) {
        this.updateGenkaInfo(searchSakuhin, genkaIndex);
      }
    },
    editGenka_tanka(event, sakuhin, genka, genkaIndex) {
      event.target.value = event.target.value.replace(/,/g, "");
      let update_genkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array[
          genkaIndex
        ].genkaInfo_array; //変更対象のgenkaInfo_array
      let searchSakuhin =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1]; //変更対象の作品

      update_genkaInfo.forEach((kbn) => {
        if (kbn.genkaKbnId == genka.genkaKbnId) {
          let target_sikisuu =
            this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
              .sikisuu;
          let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
          if (kbn.meisyo === "原稿料") {
            if (target_sikisuu_num == 1) {
              kbn.tankaInfo_array[0].tanka = Number(event.target.value);
              kbn.tankaInfo_array[0].kingaku =
                Number(event.target.value) * Number(kbn.suuryo);
              if (event.target.value == kbn.tankaInfo_array[0].defaultTanka) {
                kbn.tankaInfo_array[0].overwriteTankaFlg = false;
              } else {
                kbn.tankaInfo_array[0].overwriteTankaFlg = true;
              }
              this.calc_totalKingaku();
            } else if (target_sikisuu_num > 3) {
              kbn.tankaInfo_array[1].tanka = Number(event.target.value);
              kbn.tankaInfo_array[1].kingaku =
                Number(event.target.value) * Number(kbn.suuryo);
              if (event.target.value == kbn.tankaInfo_array[1].defaultTanka) {
                kbn.tankaInfo_array[1].overwriteTankaFlg = false;
              } else {
                kbn.tankaInfo_array[1].overwriteTankaFlg = true;
              }
              this.calc_totalKingaku();
            } else if (target_sikisuu_num == 2 || target_sikisuu_num == 3) {
              kbn.tankaInfo_array[2].tanka = Number(event.target.value);
              kbn.tankaInfo_array[2].kingaku =
                Number(event.target.value) * Number(kbn.suuryo);
              kbn.tankaInfo_array[2].overwriteTankaFlg = true;

              this.calc_totalKingaku();
            }
          } else {
            kbn.tanka = Number(event.target.value);
            kbn.kingaku = Number(event.target.value) * Number(kbn.suuryo);
            this.calc_totalKingaku();
          }
        }
      });
      if (searchSakuhin.is_divided) {
        this.updateGenkaInfo(searchSakuhin, genkaIndex);
      }
    },
    // 子要素に原価情報の変更を反映する
    updateGenkaInfo(searchSakuhin, genkaIndex) {
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(searchSakuhin, sakuhin)) {
            sakuhin.genka_array[genkaIndex] =
              searchSakuhin.genka_array[genkaIndex];
          }
        });
      });
    },
    editGenka_comment(event, sakuhin, genkaIndex) {
      //作業内容の更新
      this.daiwariData.ori_array[
        sakuhin.parent_ori_address_computed - 1
      ].sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array[
        genkaIndex
      ].statusComment = event.target.value;

      if (sakuhin.is_divided) {
        this.updateGenkaInfo(sakuhin, genkaIndex);
      }
    },
    //台割一覧内で原価情報を追加する処理
    addGenkaArray(sakuhin) {
      let selectedGenkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array;
      selectedGenkaInfo.push({
        meisyo: "未設定",
        genkaKbnId: 0,
        torihikisaki: "",
        statusComment: "",
        isAutoAppend: false,
        genkaInfo_array: [
          {
            genkaKbnId: 1,
            meisyo: "外注加工費",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 2,
            meisyo: "企画編集費",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 3,
            meisyo: "原稿料",
            suuryo: 0,
            tankaInfo_array: [
              {
                sikisuu: "1C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "4C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "その他",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
            ],
          },
          {
            genkaKbnId: 4,
            meisyo: "印税・版権料",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 5,
            meisyo: "デザイン料",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
          {
            genkaKbnId: 999999999,
            meisyo: "科目不明",
            suuryo: 0,
            tanka: 0,
            kingaku: 0,
          },
        ],
        sakuhin_index: sakuhin.sakuhin_address_computed - 1,
      });
      let genka_length = selectedGenkaInfo.length;
      //genka情報にindexを持たせる(取引先検索用)
      selectedGenkaInfo[genka_length - 1].genka_index = genka_length - 1;
      if (sakuhin.is_divided) {
        this.takeOverGenka_toDividedSakuhin(sakuhin);
      }
      this.update_daiwariSakuhinArray();
    },

    // 台割一覧内収録作品に紐づかない原価情報関連
    addDaiwariGenkaArray() {
      this.daiwariData.daiwari_genkaArray.push({
        torihikisakiId: null,
        torihikisaki: "",
        suuryo: 0,
        tanka: 0,
        kingaku: 0,
        overwriteKingaku: 0,
        genkaKbnId: null,
        sagyoNaiyo: "",
      });
    },
    editDaiwariGenka_kbn(index, event) {
      this.daiwariData.daiwari_genkaArray[index].genkaKbnId = Number(
        event.target.value
      );
    },
    editDaiwariGenka_suuryo(index, event) {
      this.daiwariData.daiwari_genkaArray[index].suuryo = Number(
        event.target.value
      );
      this.daiwariData.daiwari_genkaArray[index].kingaku =
        Number(this.daiwariData.daiwari_genkaArray[index].suuryo) *
        Number(this.daiwariData.daiwari_genkaArray[index].tanka);
      this.calc_totalKingaku();
    },
    editDaiwariGenka_tanka(index, event) {
      event.target.value = event.target.value.replace(/,/g, "");
      this.daiwariData.daiwari_genkaArray[index].tanka = Number(
        event.target.value
      );
      this.daiwariData.daiwari_genkaArray[index].kingaku =
        Number(this.daiwariData.daiwari_genkaArray[index].suuryo) *
        Number(this.daiwariData.daiwari_genkaArray[index].tanka);
      this.calc_totalKingaku();
    },
    editDaiwariGenka_kingaku(index, event) {
      event.target.value = event.target.value.replace(/,/g, "");
      this.daiwariData.daiwari_genkaArray[index].overwriteKingaku = Number(
        event.target.value
      );
      this.calc_totalKingaku();
    },
    editDaiwariGenka_sagyo(index, event) {
      this.daiwariData.daiwari_genkaArray[index].sagyoNaiyo =
        event.target.value;
    },
    editDaiwariGenka_torihiki(torihiki, index) {
      // this.modalName = "modal_listDisplay";
      this.modalOnModalName = "";
      // this.$nextTick(() => {
      //   this.$refs.daiwariList.scrollTableEnd();
      // });
      this.daiwariData.daiwari_genkaArray[index].torihikisaki = torihiki;
      this.daiwariData.daiwari_genkaArray[index].torihikisakiId =
        torihiki.torihikisakiId;
      this.update_genkaIndex = null;
    },
    deleteDaiwariGenka(index) {
      this.daiwariData.daiwari_genkaArray.splice(index, 1);
      this.calc_totalKingaku();
    },
    openDaiwariGenkaTorihiki(index) {
      // ペンネームモーダルでの作品に紐づくペンネーム表示用データ
      this.sakuhinInfoForPennameModal = {
        sakuhinId: null,
        sakuhinMei: null,
      };
      // this.modalName = "modal_genka_torihikisaki";
      this.modalOnModalName = "modal_independentGenka_torihikisaki";
      this.penName_ikkatuFlg = false;
      this.update_genkaIndex = index;
    },
    //台割一覧の合計金額算出
    calc_totalKingaku() {
      this.totalKingaku = 0;
      this.daiwariSakuhinArray.forEach((sakuhin) => {
        sakuhin.genka_array.forEach((genka) => {
          genka.genkaInfo_array.forEach((kbn) => {
            //原価区分「原稿料」以外
            if (genka.meisyo === kbn.meisyo && genka.meisyo !== "原稿料") {
              //overwriteKingakuがgenkaInfo内にあれば優先
              if ("overwriteKingaku" in kbn) {
                this.totalKingaku += kbn.overwriteKingaku;
              } else {
                this.totalKingaku += kbn.kingaku;
              }
            } else if (
              //原価区分「原稿料」
              genka.meisyo === kbn.meisyo &&
              genka.meisyo === "原稿料"
            ) {
              let target_sikisuu =
                this.daiwariData.ori_array[
                  sakuhin.parent_ori_address_computed - 1
                ].sikisuu;
              let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
              //tankaInfo_array内にoverwriteKingakuがあれば優先
              kbn.tankaInfo_array.forEach((tanka) => {
                if (target_sikisuu_num == 1) {
                  if (tanka.sikisuu == "1C") {
                    //色数照合
                    if ("overwriteKingaku" in tanka) {
                      this.totalKingaku += tanka.overwriteKingaku;
                    } else if (tanka.tanka) {
                      this.totalKingaku += tanka.kingaku;
                    }
                  }
                } else if (target_sikisuu_num > 3) {
                  if (tanka.sikisuu == "4C") {
                    //色数照合
                    if ("overwriteKingaku" in tanka) {
                      this.totalKingaku += tanka.overwriteKingaku;
                    } else if (tanka.tanka) {
                      this.totalKingaku += tanka.kingaku;
                    }
                  }
                } else if (target_sikisuu_num == 2 || target_sikisuu_num == 3) {
                  if (tanka.sikisuu == "その他") {
                    //色数照合
                    if ("overwriteKingaku" in tanka) {
                      this.totalKingaku += tanka.overwriteKingaku;
                    } else if (tanka.tanka) {
                      this.totalKingaku += tanka.kingaku;
                    }
                  }
                }
              });
            }
          });
        });
      });
      this.daiwariData.daiwari_genkaArray.forEach((genka) => {
        if (genka.overwriteKingaku > 0) {
          this.totalKingaku += genka.overwriteKingaku;
        } else {
          this.totalKingaku += genka.kingaku;
        }
      });
      this.totalKingaku = Number(this.totalKingaku).toLocaleString();
    },
    //台割一覧内で原価情報を削除する処理
    deleteGenkaInfo(sakuhin, index) {
      let selectedGenkaInfo =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array[sakuhin.sakuhin_address_computed - 1].genka_array;
      selectedGenkaInfo.splice(index, 1);
      selectedGenkaInfo.forEach((genka, index) => {
        genka.genka_index = index;
      });
      if (sakuhin.is_divided) {
        this.takeOverGenka_toDividedSakuhin(sakuhin);
      }
      this.calc_totalKingaku();
    },

    //モーダル関連---------------------------------------------------------------
    changeGenkaToTorihiki(sakuhin, genka, sakuhiId, sakuhinMei) {
      // ペンネームモーダルでの作品に紐づくペンネーム表示用データ
      this.sakuhinInfoForPennameModal = {
        sakuhinId: Number(String(sakuhiId).replace(/[*]/g, "")),
        sakuhinMei: sakuhinMei,
      };
      // this.modalName = "modal_torihikisaki";
      this.modalOnModalName = "modal_relatedGenka_torihikisaki";
      this.penName_ikkatuFlg = false;
      this.props_torihiki = {
        ...genka,
        sakuhin,
        sakuhin_id: sakuhiId,
        sakuhin_mei: sakuhinMei,
      };
    },
    changeTorihikiToDaiwari() {
      // this.modalName = "modal_listDisplay";
      this.modalOnModalName = "";
    },
    changeDaiwariToTantou(props_tantou) {
      // this.modalName = "modal_tantou";
      this.modalOnModalName = "modal_tantou";
      this.tantou_ikkatuFlg = false;
      this.props_tantou = props_tantou;
    },
    changeTantouToDaiwari() {
      // this.modalName = "modal_listDisplay";
      this.modalOnModalName = "";
    },
    //修正履歴から台割を復元
    restore_daiwari(restore_daiwariData) {
      const isDensi = false;
      const notyfMessage = "台割を復元しました";
      this.copy_daiwari(restore_daiwariData, isDensi, notyfMessage);
      this.update_daiwariSakuhinArray();
    },
    // 台割複写モーダルから複写オーバーレイが開かれたとき、仮作品・仮ﾍﾟﾝﾈｰﾑ使用状況データを格納（フラグは後ほど判定）
    setKariData(kariSakuhinData, kariPennameData) {
      this.copyDaiwariKariData.kariSakuhinIdArray = kariSakuhinData;
      this.copyDaiwariKariData.kariPennameIdArray = kariPennameData;
    },
    // 複写オーバーレイから複写されたとき、仮情報有無フラグの判定を行う
    setUseKariFlg() {
      if (
        this.copyDaiwariKariData.kariSakuhinIdArray.length > 0 ||
        this.copyDaiwariKariData.kariPennameIdArray.length > 0
      ) {
        this.copyDaiwariKariData.useKariData = true;
      }
    },
    //台割複写から別の台割を複写
    copy_daiwari(copy_daiwariData, isDensi, notyfMessage, customCopyList) {
      //複写前の台割データを保持
      const ORIGIN_DAIWARIDATA = JSON.parse(JSON.stringify(this.daiwariData));

      //複写前の台割をバックアップ保存
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      // 仮作品・ﾍﾟﾝﾈｰﾑの使用状況をAPIにポスト(非同期でOK)
      if (this.copyDaiwariKariData.useKariData) {
        for (const sakuhinId of this.copyDaiwariKariData.kariSakuhinIdArray) {
          axios.post(
            `${this.url}/save_kari_sakuhin`,
            {
              daiwariId: this.daiwariId,
              kariSakuhinId: sakuhinId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
        }
        for (const pennameId of this.copyDaiwariKariData.kariPennameIdArray) {
          axios.post(
            `${this.url}/save_kari_penname`,
            {
              daiwariId: this.daiwariId,
              kariPennameId: pennameId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
        }
        this.copyDaiwariKariData.useKariData = false;
      }

      new Promise((resolve) => {
        this.selectedSideBar = 0;
        this.daiwariData = JSON.parse(JSON.stringify(copy_daiwariData));
        this.update_daiwariSakuhinArray();
        // console.log(JSON.parse(JSON.stringify(this.daiwariSakuhinArray)));
        // console.log(JSON.parse(JSON.stringify(copy_daiwariData)));
        resolve();
      })
        .then(() => {
          return new Promise((resolve) => {
            (async () => {
              await this.set_initialDaiwariData(
                JSON.parse(JSON.stringify(copy_daiwariData))
              );
              resolve();
            })();
          });
        })
        .then(() => {
          return new Promise((resolve) => {
            //制作基本情報は複写前の状態を引き継ぐ
            delete this.daiwariData.outputSyosiData;
            this.daiwariData.outputSyosiData =
              ORIGIN_DAIWARIDATA.outputSyosiData;

            this.daiwariData.outputSyosiData;
            this.changePenNameType();
            this.includesPages_doNotCount =
              this.daiwariData.includesPages_doNotCount;

            // 複写画面から複写した場合、カスタム設定を適用させる（台割）
            if (customCopyList !== undefined) {
              let holdSakuhinUniqueId = "";
              this.daiwariData.ori_array.forEach((ori) => {
                ori.sakuhin_array.forEach((sakuhin) => {
                  // 作品名の削除
                  if (!customCopyList.daiwari.sakuhinMei.isSelected) {
                    sakuhin.sakuhin_mei = "";
                    sakuhin.sakuhin_mei_hosoku = "";
                    convertSakuhinMei(sakuhin);
                    sakuhin.sakuhin_id = -1;
                    // sakuhin_uniqueIdの更新（divideを考慮）
                    if (sakuhin.is_divided && sakuhin.is_parent) {
                      // divideかつ親
                      sakuhin.sakuhin_uniqueId = util.getUniqueStr();
                      holdSakuhinUniqueId = sakuhin.sakuhin_uniqueId;
                    } else if (sakuhin.is_divided && !sakuhin.is_parent) {
                      // divideかつ子
                      sakuhin.sakuhin_uniqueId = holdSakuhinUniqueId;
                    } else {
                      // divideしていない
                      sakuhin.sakuhin_uniqueId = util.getUniqueStr();
                    }

                    //ページ備考のsakuhin_idを更新
                    if (!(sakuhin.is_divided && !sakuhin.is_parent)) {
                      this.daiwariData.sakuhin_page_bikou_array.forEach(
                        (bikou) => {
                          bikou.sakuhin_mei = "";
                          if (
                            bikou.sakuhin_bikouId === sakuhin.sakuhin_bikouId
                          ) {
                            bikou.sakuhin_id = -1;
                            bikou.sakuhin_uniqueId = sakuhin.sakuhin_uniqueId;
                          }
                        }
                      );
                    }
                  }
                  // ペンネームの削除
                  if (!customCopyList.daiwari.penname.isSelected) {
                    sakuhin.penName.splice(0);
                    sakuhin.genka_array = sakuhin.genka_array.filter(
                      (genka) => !genka.isAutoAppend
                    );
                    convertSakuhinMei(sakuhin);
                  }
                  // 担当者の削除
                  if (!customCopyList.daiwari.tantou.isSelected) {
                    sakuhin.tantou.splice(0);
                  }
                  // ページ備考の削除
                  if (!customCopyList.daiwari.pageBikou.isSelected) {
                    this.daiwariData.sakuhin_page_bikou_array.forEach(
                      (page_bikou) => {
                        page_bikou.bikou_array.forEach((bikouInfo) => {
                          bikouInfo.bikou = "";
                          bikouInfo.isHighlighted = false;
                        });
                      }
                    );
                  }
                  // ページ範囲備考の削除
                  if (!customCopyList.daiwari.pageRangeBikou.isSelected) {
                    this.daiwariData.page_range_bikou_array.splice(0);
                  }
                  // 作品備考の削除
                  if (!customCopyList.daiwari.sakuhinBikou.isSelected) {
                    sakuhin.sakuhin_bikou = "";
                    sakuhin.sakuhin_bikou_markflg = false;
                  }
                  // 作品名を引き継がずペンネームは引き継がれている場合、ペンネームの作品連携用プロパティを削除する
                  if (
                    !customCopyList.daiwari.sakuhinMei.isSelected &&
                    customCopyList.daiwari.penname.isSelected
                  ) {
                    sakuhin.penName.forEach((pen) => {
                      if ("isRelatedSakuhin" in pen) {
                        delete pen.isRelatedSakuhin;
                      }
                    });
                  }
                });
              });
              this.calc_totalKingaku();
            }
            resolve();
          });
        })
        .then(() => {
          return new Promise((resolve) => {
            (async () => {
              await resetGenkoryoSettei();
              resolve();
            })();
          });
        })
        .then(() => {
          return new Promise((resolve) => {
            //複写前の台割をバックアップ保存
            this.save_backupDaiwari(
              this.daiwariData,
              this.selectedSakuhin,
              this.selectedOri
            );
            //ページ上限数を複写データから流用
            // const copy_maximumPage = copy_daiwariData.oriMaximumPage
            //   ? copy_daiwariData.oriMaximumPage
            //   : this.oriMaximumPage;

            // 電子枝番のとき、原価情報は引き継がないため削除する
            if (this.isDensi || isDensi) {
              this.daiwariData.daiwari_genkaArray.splice(0);
              this.daiwariData.ori_array.forEach((ori) => {
                ori.sakuhin_array.forEach((sakuhin) => {
                  sakuhin.genka_array.splice(0);
                });
              });
            }
            this.set_initialDaiwariData(
              JSON.parse(JSON.stringify(this.daiwariData))
            );

            // 以下、set_initialdDaiwariDataの影響を受けさせたくないため最下部に記載

            // 下記の場合、制作基本情報の「色数・用紙・加工」の項目追加欄(oriInfoArray)のみ複写データを流用
            // ①台割複写のカスタム項目で「色数・用紙・加工」が選択されている場合
            // ②台割複写以外で複写がおこなわれた場合
            if (
              "outputSyosiData" in copy_daiwariData &&
              (customCopyList === undefined ||
                customCopyList.seisakuKihon.irokazu.isSelected)
            ) {
              this.daiwariData.outputSyosiData.manualSyosiData.oriInfoArray.splice(
                0,
                this.daiwariData.outputSyosiData.manualSyosiData.oriInfoArray
                  .length,
                ...copy_daiwariData.outputSyosiData.manualSyosiData.oriInfoArray
              );
            }
            // 複写画面から複写した場合、カスタム設定を適用させる（制作基本情報関連）
            // 詳細情報の手入力部分を複写する
            if (
              customCopyList !== undefined &&
              customCopyList.seisakuKihon.syousai.isSelected
            ) {
              this.daiwariData.outputSyosiData.manualSyosiData.tsukimonoNyukoDate =
                copy_daiwariData.outputSyosiData.manualSyosiData.tsukimonoNyukoDate;
              this.daiwariData.outputSyosiData.manualSyosiData.tsukimonoKoryoDate =
                copy_daiwariData.outputSyosiData.manualSyosiData.tsukimonoKoryoDate;
              this.daiwariData.outputSyosiData.manualSyosiData.honbunNyukoDate =
                copy_daiwariData.outputSyosiData.manualSyosiData.honbunNyukoDate;
              this.daiwariData.outputSyosiData.manualSyosiData.honbunKoryoDate =
                copy_daiwariData.outputSyosiData.manualSyosiData.honbunKoryoDate;
              this.daiwariData.outputSyosiData.manualSyosiData.syagaikosei =
                copy_daiwariData.outputSyosiData.manualSyosiData.syagaikosei;
              this.daiwariData.outputSyosiData.manualSyosiData.hensyuNin =
                copy_daiwariData.outputSyosiData.manualSyosiData.hensyuNin;
              this.daiwariData.outputSyosiData.manualSyosiData.hakkoNin =
                copy_daiwariData.outputSyosiData.manualSyosiData.hakkoNin;
              this.daiwariData.outputSyosiData.manualSyosiData.hakkojo =
                copy_daiwariData.outputSyosiData.manualSyosiData.hakkojo;
            }
            resolve();
          });
        })
        .then(() => {
          this.update_daiwariSakuhinArray();
          // this.switchOriMaximumPage(copy_maximumPage);
          //電子台割の自動引継ぎの場合は即自動保存&通知なし
          if (!isDensi && !notyfMessage) {
            notyf.success("台割を複写しました");
          } else if (!isDensi && notyfMessage) {
            notyf.success(notyfMessage);
          } else {
            util.rebuildDaiwariData(
              this.daiwariData,
              maxAddress,
              maxSameGroupCd
            );
            this.saveDaiwari();
          }
          this.backup_daiwariData = null;
        });
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);

      // 原稿料設定を初期値にリセットする-------------------------------------
      // changeGenkoryoSetteiで変更をかけたい
      // selectedSakuhinに一つずつ格納していく
      // divided作品は最初の一つだけ渡す
      // 最後にselectedSakuhinを戻す
      const resetGenkoryoSettei = async () => {
        try {
          this.isProcessing = true;
          // 原稿料設定の初期値
          let targetGenkoryoSettei = {
            setteiKbn: -1,
            meisyo: "",
          };
          // 雑誌・アンソロの場合
          if (
            this.daiwariData.outputSyosiData.isZassi ||
            this.daiwariData.outputSyosiData.isAnthology
          ) {
            targetGenkoryoSettei.setteiKbn = 1;
            targetGenkoryoSettei.meisyo = "原稿料あり";
          } else {
            targetGenkoryoSettei.setteiKbn = 2;
            targetGenkoryoSettei.meisyo = "原稿料なし";
          }

          // selectedSakuhinを元に戻す用
          let initialSelectedSakuhinData = null;
          if (this.selectedSakuhin !== null) {
            initialSelectedSakuhinData = {
              parent_ori_address_computed: JSON.stringify(
                this.selectedSakuhin.parent_ori_address_computed
              ),
              sakuhin_index: JSON.stringify(this.selectedSakuhin.sakuhin_index),
            };
          }
          // for内での前作品との比較用
          let prevSakuhin = {};
          for (const ori of this.daiwariData.ori_array) {
            for (const sakuhin of ori.sakuhin_array) {
              this.selectedSakuhin = sakuhin;
              if (
                Object.keys(prevSakuhin).length === 0 ||
                !util.is_matched_sakuhinId(sakuhin, prevSakuhin)
              ) {
                await this.changeGenkoryoSettei(targetGenkoryoSettei);
              }
              prevSakuhin = sakuhin;
            }
          }
          this.selectedSakuhin =
            initialSelectedSakuhinData === null
              ? initialSelectedSakuhinData
              : this.daiwariData.ori_array[
                  Number(
                    initialSelectedSakuhinData.parent_ori_address_computed
                  ) - 1
                ].sakuhin_array[
                  Number(initialSelectedSakuhinData.sakuhin_index)
                ];
          this.isProcessing = false;
        } catch (error) {
          this.isProcessing = false;
          alert("原稿料設定の初期化に失敗しました");
          console.log(error);
        }
      };
      // -----------------------------------------------------------------
    },
    //使用していない？
    editSakuhinMei(sakuhin, newVal) {
      if (newVal == "") {
        console.log(sakuhin, newVal);
      }
    },
    //担当者を更新（sakuhin_arrayとselectedSakuhin両方）
    updateTantouMei(selectedUser, ikkatuFlg = false) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      if (this.props_tantou) {
        this.daiwariData.ori_array[
          this.props_tantou.parent_ori_address_computed - 1
        ].sakuhin_array[this.props_tantou.sakuhin_index].tantou = selectedUser;
        //一覧画面から更新した際の引継ぎ
        if (this.props_tantou.is_divided) {
          this.takeOverTantou_toDividedSakuhin(this.props_tantou, selectedUser);
        }
      } else if (this.selectedSakuhin) {
        if (ikkatuFlg) {
          this.daiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              sakuhin.tantou = JSON.parse(JSON.stringify(selectedUser));
            });
          });
        } else {
          this.selectedSakuhin.tantou = selectedUser;
          //サイドバーから更新した際の引継ぎ
          if (this.selectedSakuhin.is_divided) {
            this.takeOverTantou_toDividedSakuhin(
              this.selectedSakuhin,
              selectedUser
            );
          }
        }
      }
    },
    removeTantou(removeIndex) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      this.selectedSakuhin.tantou.forEach((tantou, tantouIndex) => {
        if (tantouIndex === removeIndex) {
          this.selectedSakuhin.tantou.splice(removeIndex, 1);
        }
      });
      if (this.selectedSakuhin.is_divided) {
        this.takeOverTantou_toDividedSakuhin(
          this.selectedSakuhin,
          this.selectedSakuhin.tantou
        );
      }
    },
    takeOverTantou_toDividedSakuhin(selectedSakuhin, selectedUser) {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.tantou = selectedUser;
          }
        })
      );
    },
    // 原価台帳：作品に紐づく原価の取引先コード検索
    changeTorihikisakiCd(
      sakuhin,
      genka,
      sakuhiId,
      sakuhinMei,
      torihikisakiInfo
    ) {
      // updateTorihikisaki内で参照されるデータ
      this.props_torihiki = {
        ...genka,
        sakuhin,
        sakuhin_id: sakuhiId,
        sakuhin_mei: sakuhinMei,
      };
      this.updateTorihikisaki(torihikisakiInfo, true);
    },
    // 原価台帳：作品に紐づかない原価の取引先コード検索
    changeGenkaTorihikisakiCd(index, torihikisakiInfo) {
      this.daiwariData.daiwari_genkaArray[index].torihikisaki =
        torihikisakiInfo;
      this.daiwariData.daiwari_genkaArray[index].torihikisakiId =
        torihikisakiInfo.torihikisakiId;
    },
    //取引先更新
    updateTorihikisaki(selected_torihikisaki, forcedIgnition) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      if (
        this.modalName === "modal_torihikisaki" ||
        this.modalOnModalName === "modal_torihikisaki" ||
        this.modalOnModalName === "modal_relatedGenka_torihikisaki" ||
        forcedIgnition === true // モーダル以外から発火させたい場合はtrueにして渡す
      ) {
        // 原価台帳からペンネーム更新を行った場合
        // props_torihikiには取引先更新を行った原価に紐づく作品情報が格納されている
        if (this.props_torihiki) {
          //該当する作品のgenka情報に取引先情報を紐づける
          this.daiwariData.ori_array[
            this.props_torihiki.sakuhin.parent_ori_address_computed - 1
          ].sakuhin_array[
            this.props_torihiki.sakuhin.sakuhin_address_computed - 1
          ].genka_array[this.props_torihiki.genka_index].torihikisaki =
            selected_torihikisaki;

          //修正を加える原価情報を特定する
          let genka_array =
            this.daiwariData.ori_array[
              this.props_torihiki.sakuhin.parent_ori_address_computed - 1
            ].sakuhin_array[
              this.props_torihiki.sakuhin.sakuhin_address_computed - 1
            ].genka_array[this.props_torihiki.genka_index];

          //dividedSakuhinがあれば変更を伝える
          this.takeOverTorihikisaki_toDividedSakuhin(
            this.props_torihiki,
            selected_torihikisaki,
            this.props_torihiki.genka_index
          );

          //原価情報が属する作品を特定する(toggleHyousiFlg用)
          let sakuhin =
            this.daiwariData.ori_array[
              this.props_torihiki.sakuhin.parent_ori_address_computed - 1
            ].sakuhin_array[
              this.props_torihiki.sakuhin.sakuhin_address_computed - 1
            ];
          if (
            //表紙フラグがfalseで色数設定済みの場合
            sakuhin.toggleHyousiFlg === false &&
            this.daiwariData.ori_array[
              this.props_torihiki.sakuhin.parent_ori_address_computed - 1
            ].sikisuu !== ""
          ) {
            //取引先が単価情報を持っている場合
            if (
              genka_array.torihikisaki.tankaList &&
              genka_array.torihikisaki.tankaList.length !== 0
            ) {
              //色数の単価参照先を探す
              let target_sikisuu =
                this.daiwariData.ori_array[
                  this.props_torihiki.sakuhin.parent_ori_address_computed - 1
                ].sikisuu;
              let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
              genka_array.genkaInfo_array.forEach((kbn) => {
                if (kbn.meisyo === "原稿料") {
                  // デフォルト単価と単価をリセットする
                  kbn.tankaInfo_array.forEach((tanka) => {
                    // 取引先が変わるのでデフォルト単価は一旦すべてリセット
                    tanka.defaultTanka = 0;
                    // 上書き単価でなければ単価もリセット(上書き単価があればそれを保持)
                    if (!tanka.overwriteTankaFlg) {
                      tanka.tanka = 0;
                    }
                    // 金額の再計算
                    tanka.kingaku = tanka.tanka * kbn.suuryo;
                  });
                  //原稿料に単価リストを参照
                  if (target_sikisuu_num == 1) {
                    genka_array.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 1) {
                          kbn.tankaInfo_array[0].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            // 単価を変更後の取引先デフォルト単価に切り替える条件
                            // 「単価が0または上書き単価フラグがfalse」かつ「『原稿料設定が2~4で自動設定されているもの』でない」場合
                            (kbn.tankaInfo_array[0].tanka == 0 ||
                              !kbn.tankaInfo_array[0].overwriteTankaFlg) &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              genka_array.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[0].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[0].kingaku =
                              kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                          }
                        }
                        this.calc_totalKingaku();
                      }
                    );
                  } else if (target_sikisuu_num > 3) {
                    genka_array.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 2) {
                          kbn.tankaInfo_array[1].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            (kbn.tankaInfo_array[1].tanka == 0 ||
                              !kbn.tankaInfo_array[1].overwriteTankaFlg) &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              genka_array.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[1].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[1].kingaku =
                              kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                          }
                        }
                        this.calc_totalKingaku();
                      }
                    );
                  }
                }
              });
            }
          }
          if (sakuhin.isDivided) {
            // 原価の変更を子要素に反映
            this.updateGenkaInfo(sakuhin, this.props_torihiki.genka_index);
          }
        }
        // サイドバーから作品のペンネーム更新を行った場合
        else if (this.selectedSakuhin) {
          this.selectedSakuhin.genka_array[
            this.torihikisakiIndex
          ].torihikisaki = selected_torihikisaki;
          if (this.selectedSakuhin.is_divided) {
            this.takeOverTorihikisaki_toDividedSakuhin(
              this.selectedSakuhin,
              selected_torihikisaki,
              this.torihikisakiIndex
            );
          }
          //修正を加える原価情報を特定する
          let genka_array =
            this.selectedSakuhin.genka_array[this.torihikisakiIndex];
          if (
            //表紙フラグがfalseで色数設定済みの場合
            this.selectedSakuhin.toggleHyousiFlg === false &&
            this.selectedSakuhin.ori_sikisuu !== ""
          ) {
            //取引先が単価情報を持っている場合
            if (
              genka_array.torihikisaki.tankaList &&
              genka_array.torihikisaki.tankaList.length !== 0
            ) {
              let target_sikisuu = this.selectedSakuhin.ori_sikisuu;
              let target_sikisuu_num = Number(target_sikisuu.replace("C", ""));
              genka_array.genkaInfo_array.forEach((kbn) => {
                if (kbn.meisyo === "原稿料") {
                  // デフォルト単価をリセットする
                  kbn.tankaInfo_array.forEach((tanka) => {
                    // 取引先が変わるのでデフォルト単価は一旦すべてリセット
                    tanka.defaultTanka = 0;
                    // 上書き単価でなければ単価もリセット(上書き単価があればそれを保持)
                    if (!tanka.overwriteTankaFlg) {
                      tanka.tanka = 0;
                    }
                    // 金額の再計算
                    tanka.kingaku = tanka.tanka * kbn.suuryo;
                  });
                  if (target_sikisuu_num == 1) {
                    genka_array.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 1) {
                          kbn.tankaInfo_array[0].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            (kbn.tankaInfo_array[0].tanka == 0 ||
                              !kbn.tankaInfo_array[0].overwriteTankaFlg) &&
                            !(
                              (this.selectedSakuhin.genkoryo_settei
                                .setteiKbn === 2 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 3 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 4) &&
                              genka_array.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[0].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[0].kingaku =
                              kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                          }
                        }
                      }
                    );
                  } else if (target_sikisuu_num > 3) {
                    genka_array.torihikisaki.tankaList.forEach(
                      (defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 2) {
                          kbn.tankaInfo_array[1].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            (kbn.tankaInfo_array[1].tanka == 0 ||
                              !kbn.tankaInfo_array[1].overwriteTankaFlg) &&
                            !(
                              (this.selectedSakuhin.genkoryo_settei
                                .setteiKbn === 2 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 3 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 4) &&
                              genka_array.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[1].tanka =
                              defaultTanka.tankaKingaku;
                            kbn.tankaInfo_array[1].kingaku =
                              kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                            kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                          }
                        }
                      }
                    );
                  }
                }
              });
            }
          }
          if (this.selectedSakuhin.isDivided) {
            // 原価の変更を子要素に反映
            this.updateGenkaInfo(this.selectedSakuhin, this.torihikisakiIndex);
          }
        }
      }
      this.props_torihiki = null;
    },
    removeTorihikisaki(genka, index) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedSakuhin.genka_array[index].torihikisaki = "";
      if (this.selectedSakuhin.is_divided) {
        this.takeOverTorihikisaki_toDividedSakuhin(
          this.selectedSakuhin,
          this.selectedSakuhin.genka_array[index].torihikisaki,
          index
        );
      }
    },
    takeOverTorihikisaki_toDividedSakuhin(
      selectedSakuhin,
      selectedTorihiki,
      genkaIndex
    ) {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.genka_array[genkaIndex].torihikisaki = selectedTorihiki;
          }
        })
      );
    },
    // 更新前のペンネームと比較して、削除された又は追加されたペンネームを抽出する
    compareWithPrevPenname(prevPenname, newPenname, compareKinds) {
      // 追加されたペンネーム（newPennameに存在するがprevPennameに存在しないデータ）
      if (compareKinds === "added") {
        const addedPenname = newPenname.filter(
          (newPen) =>
            prevPenname.filter(
              (prevPen) => prevPen.pennameId === newPen.pennameId
            ).length === 0
        );
        return addedPenname;
      }
      // 削除されたペンネーム（prevPennameに存在するがnewPennameに存在しないデータ）
      else if (compareKinds === "deleted") {
        const deletedPenname = prevPenname.filter(
          (prevPen) =>
            newPenname.filter(
              (newPen) => prevPen.pennameId === newPen.pennameId
            ).length === 0
        );
        return deletedPenname;
      }
    },
    // 引数で渡されたペンネームに一致する取引先情報を取得
    async searchTorihikisaki(pennameData) {
      let torihikisakiInfo = {};
      try {
        const res = await axios.get(
          `${this.url}/get_torihikisaki_split?torihikisaki_mei=${pennameData.penName}&page=1`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const torihikisakiList = res.data.torihikisakiList;
        torihikisakiList.forEach((torihikisaki) => {
          // 仮ペンネームの場合
          if (
            pennameData.pennameId < 0 &&
            pennameData.penName === torihikisaki.penname
          ) {
            torihikisakiInfo = {
              tankaList: torihikisaki.tankaList,
              torihikisakiId: pennameData.torihikisakiId,
              torihikisakiMei: "仮ペンネーム",
              pennameId: pennameData.pennameId,
              penname: pennameData.penName,
              torihikisakiCd: torihikisaki.torihikisakiCd,
            };
          }
          // 通常ペンネームの場合
          else if (torihikisaki.torihikisakiId == pennameData.torihikisakiId) {
            torihikisakiInfo = {
              tankaList: torihikisaki.tankaList,
              torihikisakiId: torihikisaki.torihikisakiId,
              torihikisakiMei: torihikisaki.torihikisakiMei,
              pennameId: pennameData.pennameId,
              penname: pennameData.penName,
              torihikisakiCd: torihikisaki.torihikisakiCd,
            };
          }
        });
        return torihikisakiInfo;
      } catch (error) {
        console.log("取引先データが取得できませんでした\n" + error);
      }
    },
    async updatePenName(selected_penName, ikkatuFlg) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      // let pennameString = ""; //作品名の前に自動セットするため、全てのペンネームを文字列連結
      // selected_penName.forEach((p) => {
      //   pennameString = pennameString + " " + p.penName;
      // });
      if (!ikkatuFlg) {
        const addedPenname = this.compareWithPrevPenname(
          this.selectedSakuhin.penName,
          selected_penName,
          "added"
        );
        const deletedPenname = this.compareWithPrevPenname(
          this.selectedSakuhin.penName,
          selected_penName,
          "deleted"
        );
        this.selectedSakuhin.penName = selected_penName;
        // 作品名の自動追加
        // if (this.selectedSakuhin.sakuhin_mei !== "") {
        //   this.selectedSakuhin.sakuhin_mei =
        //     pennameString + "「" + this.selectedSakuhin.sakuhin_mei + "」";
        // }

        //ページ備考の作品名を更新(不要？一旦コメントアウト)
        // this.daiwariData.sakuhin_page_bikou_array.forEach((bikou) => {
        //   if (bikou.sakuhin_bikouId === this.selectedSakuhin.sakuhin_bikouId) {
        //     bikou.sakuhin_mei = this.selectedSakuhin.sakuhin_mei;
        //   }
        // });

        // 原稿料設定に基づいて原稿料の削除と追加を行う
        // 作品の原稿料設定が「原稿料あり(=1)」「原稿流用(=3)」「前渡原稿料(=4)」の場合
        // 「原稿料なし(=2)」かつ「雑誌・アンソロのいずれか」の場合
        if (
          this.selectedSakuhin.genkoryo_settei.setteiKbn === 1 ||
          (this.selectedSakuhin.genkoryo_settei.setteiKbn === 2 &&
            (this.daiwariData.outputSyosiData.isZassi ||
              this.daiwariData.outputSyosiData.isAnthology)) ||
          this.selectedSakuhin.genkoryo_settei.setteiKbn === 3 ||
          this.selectedSakuhin.genkoryo_settei.setteiKbn === 4
        ) {
          // 原稿料の追加
          for (const addPen of addedPenname) {
            const torihikisakiData = await this.searchTorihikisaki(addPen);
            await this.onClickAddGenka({
              meisyo: "未設定",
              genkaKbnId: 0,
              torihikisaki: "",
              statusComment: "",
              isAutoAppend: true,
              sakuhin_index: this.selectedSakuhin.sakuhin_address_computed - 1,
              genkaInfo_array: [
                {
                  genkaKbnId: 1,
                  meisyo: "外注加工費",
                  suuryo: 0,
                  tanka: 0,
                  kingaku: 0,
                },
                {
                  genkaKbnId: 2,
                  meisyo: "企画編集費",
                  suuryo: 0,
                  tanka: 0,
                  kingaku: 0,
                },
                {
                  genkaKbnId: 3,
                  meisyo: "原稿料",
                  suuryo: 0,
                  tankaInfo_array: [
                    {
                      sikisuu: "1C",
                      tanka: 0,
                      kingaku: 0,
                      overwriteTankaFlg: false,
                      defaultTanka: 0,
                    },
                    {
                      sikisuu: "4C",
                      tanka: 0,
                      kingaku: 0,
                      overwriteTankaFlg: false,
                      defaultTanka: 0,
                    },
                    {
                      sikisuu: "その他",
                      tanka: 0,
                      kingaku: 0,
                      overwriteTankaFlg: false,
                      defaultTanka: 0,
                    },
                  ],
                },
                {
                  genkaKbnId: 4,
                  meisyo: "印税・版権料",
                  suuryo: 0,
                  tanka: 0,
                  kingaku: 0,
                },
                {
                  genkaKbnId: 5,
                  meisyo: "デザイン料",
                  suuryo: 0,
                  tanka: 0,
                  kingaku: 0,
                },
                {
                  genkaKbnId: 999999999,
                  meisyo: "科目不明",
                  suuryo: 0,
                  tanka: 0,
                  kingaku: 0,
                },
              ],
            });
            this.torihikisakiIndex =
              this.selectedSakuhin.genka_array.length - 1;
            await this.inputGenkaKbn(
              this.selectedSakuhin.genka_array.length - 1,
              3
            );
            await this.updateTorihikisaki(torihikisakiData, true);
          }

          // 原稿料の削除
          for (const deletePen of deletedPenname) {
            this.save_backupDaiwari(
              this.daiwariData,
              this.selectedSakuhin,
              this.selectedOri
            );
            this.selectedSakuhin.genka_array.forEach((genka, index) => {
              if (
                genka.torihikisaki.torihikisakiId ===
                  deletePen.torihikisakiId &&
                genka.genkaKbnId === 3 &&
                genka.isAutoAppend === true
              ) {
                this.selectedSakuhin.genka_array.splice(index, 1);
                this.calc_totalKingaku();
                this.takeOverGenka_toDividedSakuhin(this.selectedSakuhin);
              }
            });
            this.selectedSakuhin.genka_array.forEach((genka, index) => {
              genka.genka_index = index;
            });
          }
        }
        this.takeOverPenName_toDividedSakuhin();
        this.applyGenkoryoSettei(addedPenname, this.selectedSakuhin);
      } else {
        const initialSelectedSakuhinData = {
          parent_ori_address_computed: JSON.stringify(
            this.selectedSakuhin.parent_ori_address_computed
          ),
          sakuhin_index: JSON.stringify(this.selectedSakuhin.sakuhin_index),
        };
        // async/awaitがforEach内だと効かないのでfor文でまわす
        let prevSakuhin = {};
        for (const ori of this.daiwariData.ori_array) {
          for (const sakuhin of ori.sakuhin_array) {
            // console.log(prevSakuhin, sakuhin);
            this.selectedSakuhin = sakuhin;
            // 作品ごとに追加・削除されたペンネームを抽出
            const addedPenname = this.compareWithPrevPenname(
              sakuhin.penName,
              selected_penName,
              "added"
            );
            const deletedPenname = this.compareWithPrevPenname(
              sakuhin.penName,
              selected_penName,
              "deleted"
            );
            // ペンネームの削除
            sakuhin.penName = [];
            selected_penName.forEach((p) => {
              sakuhin.penName.push({
                penName: p.penName,
                pennameId: p.pennameId,
                torihikisakiId: p.torihikisakiId,
              });
              // if (sakuhin.sakuhin_mei !== "") {
              //   sakuhin.sakuhin_mei = pennameString + "「" + sakuhin.sakuhin_mei + "」";
              // }
            });
            convertSakuhinMei(sakuhin);

            // divideしていないまたはdivide1つ目の作品
            if (
              Object.keys(prevSakuhin).length === 0 ||
              !util.is_matched_sakuhinId(sakuhin, prevSakuhin)
            ) {
              // 原稿料設定に基づいて原稿料の削除と追加を行う
              // 作品の原稿料設定が「原稿料あり(=1)」「原稿流用(=3)」「前渡原稿料(=4)」の場合
              // 「原稿料なし(=2)」かつ「雑誌・アンソロのいずれか」の場合
              if (
                this.selectedSakuhin.genkoryo_settei.setteiKbn === 1 ||
                (this.selectedSakuhin.genkoryo_settei.setteiKbn === 2 &&
                  (this.daiwariData.outputSyosiData.isZassi ||
                    this.daiwariData.outputSyosiData.isAnthology)) ||
                this.selectedSakuhin.genkoryo_settei.setteiKbn === 3 ||
                this.selectedSakuhin.genkoryo_settei.setteiKbn === 4
              ) {
                // 原稿料の削除
                for (const deletePen of deletedPenname) {
                  this.save_backupDaiwari(
                    this.daiwariData,
                    this.selectedSakuhin,
                    this.selectedOri
                  );
                  sakuhin.genka_array.forEach((genka, index) => {
                    if (
                      genka.torihikisaki.torihikisakiId ===
                        deletePen.torihikisakiId &&
                      genka.genkaKbnId === 3 &&
                      genka.isAutoAppend === true
                    ) {
                      sakuhin.genka_array.splice(index, 1);
                      this.calc_totalKingaku();
                      // this.takeOverGenka_toDividedSakuhin(this.selectedSakuhin);
                    }
                  });
                  sakuhin.genka_array.forEach((genka, index) => {
                    genka.genka_index = index;
                  });
                }
                // 原稿料の追加
                for (const addPen of addedPenname) {
                  const torihikisakiData = await this.searchTorihikisaki(
                    addPen
                  );
                  await this.onClickAddGenka({
                    meisyo: "未設定",
                    genkaKbnId: 0,
                    torihikisaki: "",
                    statusComment: "",
                    isAutoAppend: true,
                    sakuhin_index: sakuhin.sakuhin_address_computed - 1,
                    genkaInfo_array: [
                      {
                        genkaKbnId: 1,
                        meisyo: "外注加工費",
                        suuryo: 0,
                        tanka: 0,
                        kingaku: 0,
                      },
                      {
                        genkaKbnId: 2,
                        meisyo: "企画編集費",
                        suuryo: 0,
                        tanka: 0,
                        kingaku: 0,
                      },
                      {
                        genkaKbnId: 3,
                        meisyo: "原稿料",
                        suuryo: 0,
                        tankaInfo_array: [
                          {
                            sikisuu: "1C",
                            tanka: 0,
                            kingaku: 0,
                            overwriteTankaFlg: false,
                            defaultTanka: 0,
                          },
                          {
                            sikisuu: "4C",
                            tanka: 0,
                            kingaku: 0,
                            overwriteTankaFlg: false,
                            defaultTanka: 0,
                          },
                          {
                            sikisuu: "その他",
                            tanka: 0,
                            kingaku: 0,
                            overwriteTankaFlg: false,
                            defaultTanka: 0,
                          },
                        ],
                      },
                      {
                        genkaKbnId: 4,
                        meisyo: "印税・版権料",
                        suuryo: 0,
                        tanka: 0,
                        kingaku: 0,
                      },
                      {
                        genkaKbnId: 5,
                        meisyo: "デザイン料",
                        suuryo: 0,
                        tanka: 0,
                        kingaku: 0,
                      },
                      {
                        genkaKbnId: 999999999,
                        meisyo: "科目不明",
                        suuryo: 0,
                        tanka: 0,
                        kingaku: 0,
                      },
                    ],
                  });
                  this.torihikisakiIndex = sakuhin.genka_array.length - 1;
                  await this.inputGenkaKbn(this.torihikisakiIndex, 3);
                  await this.updateTorihikisaki(torihikisakiData, true);
                }
              }
              this.applyGenkoryoSettei(addedPenname, this.selectedSakuhin);
            }
            // divide2つ目以降の作品
            // else if (util.is_matched_sakuhinId(sakuhin, prevSakuhin)) {
            //   console.log("divideしてる");
            //   sakuhin.genka_array.splice(0);
            //   prevSakuhin.genka_array.forEach((prevGenka) => {
            //     sakuhin.genka_array.push(prevGenka);
            //   });
            // }
            prevSakuhin = sakuhin;
          }
        }
        // selectesSakuhinに元の選択されていた作品を戻す
        this.selectedSakuhin =
          this.daiwariData.ori_array[
            Number(initialSelectedSakuhinData.parent_ori_address_computed) - 1
          ].sakuhin_array[Number(initialSelectedSakuhinData.sakuhin_index)];
      }
      this.hideModal();
    },
    removePenName(penName, index) {
      // ペンネームの削除
      this.selectedSakuhin.penName.splice(index, 1);
      this.takeOverPenName_toDividedSakuhin();
      this.selectedSakuhin.genka_array.forEach((genka, index) => {
        if (
          genka.torihikisaki.torihikisakiId === penName.torihikisakiId &&
          genka.genkaKbnId === 3 &&
          genka.isAutoAppend
        ) {
          this.selectedSakuhin.genka_array.splice(index, 1);
          convertSakuhinMei(this.selectedSakuhin);
          this.calc_totalKingaku();
          this.takeOverGenka_toDividedSakuhin(this.selectedSakuhin);
        }
      });
    },
    changePenNameType() {
      //ペンネームが1名登録→複数名登録になったため、Stringから配列に置き換える
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (typeof sakuhin.penName == "string" && sakuhin.penName) {
            let penName = JSON.parse(JSON.stringify(sakuhin.penName));
            sakuhin.penName = [];
            sakuhin.penName.push({ penName: penName });
          }
        });
      });
    },
    takeOverPenName_toDividedSakuhin() {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
            sakuhin.penName = [...this.selectedSakuhin.penName];
            sakuhin.sakuhin_mei = this.selectedSakuhin.sakuhin_mei;
            convertSakuhinMei(sakuhin);
          }
        })
      );
    },
    // ペンネーム自動追加後に追加した単価に原稿料設定を適用させる
    applyGenkoryoSettei(targetPennameList, targetSakuhin) {
      targetPennameList.forEach((penname) => {
        targetSakuhin.genka_array.forEach((genka) => {
          // 作品のペンネームと取引先idが一致かつ自動追加の原稿料
          if (
            genka.genkaKbnId === 3 &&
            genka.torihikisaki.torihikisakiId === penname.torihikisakiId &&
            genka.isAutoAppend === true
          ) {
            // 「『2.原稿料なし』かつ『雑誌またはアンソロジー』、「3.原稿流用」、「4.前渡原稿料」の場合単価0にする
            if (
              (targetSakuhin.genkoryo_settei.setteiKbn === 2 &&
                (this.daiwariData.outputSyosiData.isZassi ||
                  this.daiwariData.outputSyosiData.isAnthology)) ||
              targetSakuhin.genkoryo_settei.setteiKbn === 3 ||
              targetSakuhin.genkoryo_settei.setteiKbn === 4
            ) {
              genka.genkaInfo_array[2].tankaInfo_array.forEach((tankaInfo) => {
                tankaInfo.tanka = 0;
                tankaInfo.kingaku =
                  tankaInfo.tanka * genka.genkaInfo_array[2].suuryo;
                tankaInfo.overwriteTankaFlg = true;
                this.calc_totalKingaku();
              });
            }
          }
        });
      });
    },

    onMove({ relatedContext, draggedContext }) {
      //D&Dが可能かどうかflgで分岐
      const draggedElement = draggedContext.element; //選択中の折
      const relatedElement = relatedContext.element; //ドラッグ＆ドロップにより影響を受けた折
      let related_divided_flg = false;
      let dragged_divided_flg = false;
      new Promise((resolve) => {
        if (
          //分断作品を含む折の移動
          draggedElement.sakuhin_array[0].is_divided ||
          draggedElement.sakuhin_array[draggedElement.sakuhin_array.length - 1]
            .is_divided
        ) {
          this.daiwariData.ori_array.forEach((ori, oriIndex) => {
            if (draggedContext.index !== oriIndex) {
              if (
                //ドラッグ対象折内の始終にdivided作品がある場合
                draggedElement.sakuhin_array[0].is_divided &&
                draggedElement.sakuhin_array[
                  draggedElement.sakuhin_array.length - 1
                ].is_divided
              ) {
                ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
                  // ドラッグ対象折の最初の作品と同じ作品が他の折にあった場合、その作品を折から削除
                  if (
                    util.is_matched_sakuhinId(
                      draggedElement.sakuhin_array[0],
                      sakuhin
                    )
                  ) {
                    ori.sakuhin_array.splice(sakuhinIndex, 1);
                  }
                });
                ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
                  // ドラッグ対象折の最後の作品と同じ作品が他の折にあった場合、その作品を折から削除
                  if (
                    util.is_matched_sakuhinId(
                      draggedElement.sakuhin_array[
                        draggedElement.sakuhin_array.length - 1
                      ],
                      sakuhin
                    )
                  ) {
                    ori.sakuhin_array.splice(sakuhinIndex, 1);
                  }
                });
              } else {
                ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
                  if (draggedElement.sakuhin_array[0].is_divided) {
                    if (
                      util.is_matched_sakuhinId(
                        draggedElement.sakuhin_array[0],
                        sakuhin
                      )
                    ) {
                      ori.sakuhin_array.splice(sakuhinIndex, 1);
                    }
                  } else if (
                    draggedElement.sakuhin_array[
                      draggedElement.sakuhin_array.length - 1
                    ].is_divided
                  ) {
                    if (
                      util.is_matched_sakuhinId(
                        draggedElement.sakuhin_array[
                          draggedElement.sakuhin_array.length - 1
                        ],
                        sakuhin
                      )
                    ) {
                      ori.sakuhin_array.splice(sakuhinIndex, 1);
                    }
                  }
                });
              }
            }
          });
          // dragged_divided_flg = true;
        }
        resolve();
      }).then(() => {
        // ドラッグ対象折内の始終のdivide設定を解除
        draggedElement.sakuhin_array[0].is_divided = false;
        draggedElement.sakuhin_array[0].divided_page = 0;
        draggedElement.sakuhin_array[
          draggedElement.sakuhin_array.length - 1
        ].is_divided = false;
        draggedElement.sakuhin_array[
          draggedElement.sakuhin_array.length - 1
        ].divided_page = 0;
      });

      if (
        //relatedElementの先頭が分断作品の場合原則D&D非活性(例外あり)
        relatedElement.sakuhin_array.length > 0 &&
        relatedElement.sakuhin_array[0].is_divided
      ) {
        related_divided_flg = true;
      }
      if (
        //先頭が分断作品だが分断全体の最後尾ある場合D&D活性
        relatedElement.sakuhin_array.length > 0 &&
        relatedElement.sakuhin_array[0].is_divided &&
        relatedContext.index == draggedContext.futureIndex &&
        relatedContext.index == relatedContext.list.length - 1 // 一番最後の折
      ) {
        related_divided_flg = false; //最後尾の差し込みを許可
      }
      // relatedElementの先頭又は最後が分断作品で、
      // 影響受ける折がドラッグする折よりも後ろにあった場合（上から下の移動）
      if (
        relatedElement.sakuhin_array.length > 0 &&
        (relatedElement.sakuhin_array[0].is_divided ||
          relatedElement.sakuhin_array[relatedElement.sakuhin_array.length - 1]
            .is_divided) &&
        relatedContext.index > draggedContext.index
      ) {
        //移動後の折の前後に位置する折と作品をそれぞれ取得
        const previousOri = this.daiwariData.ori_array[
          draggedContext.futureIndex
        ]
          ? this.daiwariData.ori_array[draggedContext.futureIndex]
          : null;
        const nextOri = this.daiwariData.ori_array[
          draggedContext.futureIndex + 1
        ]
          ? this.daiwariData.ori_array[draggedContext.futureIndex + 1]
          : null;
        const previousSakuhin = previousOri
          ? previousOri.sakuhin_array[previousOri.sakuhin_array.length - 1]
          : null;
        const nextSakuhin = nextOri ? nextOri.sakuhin_array[0] : null;

        if (
          previousSakuhin &&
          nextSakuhin &&
          util.is_matched_sakuhinId(previousSakuhin, nextSakuhin)
        ) {
          related_divided_flg = true; //移動後の折の前後で分断が続いているため移動中断
        } else if (
          previousSakuhin &&
          nextSakuhin &&
          !util.is_matched_sakuhinId(previousSakuhin, nextSakuhin)
        ) {
          related_divided_flg = false; //移動後の折の前後で分断が終了しているため移動可能
        }
      }

      // relatedElementの先頭又は最後が分断作品で、
      // 影響受ける折がドラッグする折よりも前にあった場合（下から上の移動）
      if (
        relatedElement.sakuhin_array.length > 0 &&
        (relatedElement.sakuhin_array[0].is_divided ||
          relatedElement.sakuhin_array[relatedElement.sakuhin_array.length - 1]
            .is_divided) &&
        relatedContext.index < draggedContext.index
      ) {
        //移動後の折の前後に位置する折と作品をそれぞれ取得
        const previousOri = this.daiwariData.ori_array[
          draggedContext.futureIndex - 1
        ]
          ? this.daiwariData.ori_array[draggedContext.futureIndex - 1]
          : null;
        const nextOri = this.daiwariData.ori_array[draggedContext.futureIndex]
          ? this.daiwariData.ori_array[draggedContext.futureIndex]
          : null;
        const previousSakuhin = previousOri
          ? previousOri.sakuhin_array[previousOri.sakuhin_array.length - 1]
          : null;
        const nextSakuhin = nextOri ? nextOri.sakuhin_array[0] : null;

        if (
          previousSakuhin &&
          nextSakuhin &&
          util.is_matched_sakuhinId(previousSakuhin, nextSakuhin)
        ) {
          related_divided_flg = true; //移動後の折の前後で分断が続いているため移動中断
        } else if (
          previousSakuhin &&
          nextSakuhin &&
          !util.is_matched_sakuhinId(previousSakuhin, nextSakuhin)
        ) {
          related_divided_flg = false; //移動後の折の前後で分断が終了しているため移動可能
        }
      }

      if (
        relatedElement.sakuhin_array.length > 0 &&
        relatedElement.sakuhin_array[relatedElement.sakuhin_array.length - 1]
          .is_divided &&
        relatedContext.index < draggedContext.futureIndex
      ) {
        // relatedElementの最後が分断作品で、
        // 下から上にドラッグしたものを再度上から下に戻した時
        // related_divided_flg = true;
      } else {
        if (draggedContext.futureIndex == 0) {
          related_divided_flg = false; //先頭の差し込みを許可
        }
      }

      if (related_divided_flg || dragged_divided_flg) {
        return false;
      } else {
        return true;
      }
    },
    onChooseOri(oriIndex) {
      if (this.isJuhanDaiwari) return;
      // sidebarを変更
      this.selectedSideBar = 1;
      //旧ハイライト(作品)があれば削除
      if (this.selectedSakuhin != null) {
        this.selectedSakuhin.is_selected_sakuhin = false;
      }

      // 旧ハイライトを削除
      if (this.selectedOri != null) {
        this.selectedOri.is_selected_ori = false;
      }
      // 作品は複数ある可能性があるため全走査して削除
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          sakuhin.is_selected_sakuhin = false;
        });
      });

      // seletedeOriをdataに設定
      this.selectedOri = this.daiwariData.ori_array[oriIndex];
      // 新ハイライトを設定
      this.selectedOri.is_selected_ori = true;

      // 子コンポーネントのメソッド呼び出し
      this.$nextTick(() => {
        this.$refs.child.allowsToChangeSikisuu();
      });
      console.log(this.selectedOri);
    },
    onStartOri() {
      //折移動前の台割バックアップを取っておく
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
    },
    onEndOri() {
      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(() => {
        new Promise((resolve) => {
          this.deleteEmptyOri();
          resolve();
        }).then(() => {
          this.setInitialDuplicatedOriKbn();
          this.update_daiwariSakuhinArray();
          this.daiwariData.sakuhin_page_bikou_array =
            pageBikou.sort_sakuhin_page_bikou_array(
              this.daiwariData.sakuhin_page_bikou_array,
              this.daiwariSakuhinArray,
              this.daiwariData
            );
          // 折が選択されている状態だったら折かたまりの配列を作成し直す
          if (this.$refs.child !== undefined) {
            this.$refs.child.allowsToChangeSikisuu();
          }
        });
      });
    },
    onChooseSakuhin(event) {
      // sidebarを変更
      this.selectedSideBar = 2;
      //旧ハイライトがあれば削除
      if (this.selectedSakuhin != null) {
        this.selectedSakuhin.is_selected_sakuhin = false;
      }
      // 旧ハイライトを削除(折)
      if (this.selectedOri != null) {
        this.selectedOri.is_selected_ori = false;
      }
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          sakuhin.is_selected_sakuhin = false;
        });
      });
      if (event) {
        let selectedOriIndex =
          Number(
            event.item.parentNode.parentNode
              .querySelector(".ori_address")
              .innerHTML.replace("[", "")
              .replace("]", "")
          ) - 1;

        this.selectedSakuhin =
          this.daiwariData.ori_array[selectedOriIndex].sakuhin_array[
            event.oldIndex
          ];
      }
      // 新ハイライトを設定
      this.selectedSakuhin.is_selected_sakuhin = true;
      this.addSakuhinAfterFlg();
      console.log(this.selectedSakuhin);
    },
    onClickSakuhin(event, sakuhinIndex) {
      if (this.isLockDaiwari) {
        // sidebarを変更
        this.selectedSideBar = 2;
        //旧ハイライトがあれば削除
        if (this.selectedSakuhin != null) {
          this.selectedSakuhin.is_selected_sakuhin = false;
        }
        // 旧ハイライトを削除(折)
        if (this.selectedOri != null) {
          this.selectedOri.is_selected_ori = false;
        }
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            sakuhin.is_selected_sakuhin = false;
          });
        });
        if (event) {
          let selectedOriIndex =
            Number(
              event
                .composedPath()[5]
                .querySelector(".ori_address")
                .innerHTML.replace("[", "")
                .replace("]", "")
            ) - 1;
          this.selectedSakuhin =
            this.daiwariData.ori_array[selectedOriIndex].sakuhin_array[
              sakuhinIndex
            ];
        }

        // 新ハイライトを設定
        this.selectedSakuhin.is_selected_sakuhin = true;
        this.addSakuhinAfterFlg();
        console.log(this.selectedSakuhin);
      }
    },

    onStartSkuhin(event) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      let old_oriIndex = Number(event.from.dataset.columnId);
      let selectedSakuhin =
        this.daiwariData.ori_array[old_oriIndex].sakuhin_array[event.oldIndex];
      if (
        selectedSakuhin.is_parent == false &&
        selectedSakuhin.is_divided == true
      ) {
        selectedSakuhin.divided_page = 0;
        this.daiwariData.ori_array.forEach((ori, oriIndex) => {
          if (oriIndex !== old_oriIndex) {
            ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
              if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
                ori.sakuhin_array.splice(sakuhinIndex, 1);
              }
            });
          }
        });
      } else if (
        selectedSakuhin.is_parent == true &&
        selectedSakuhin.is_divided == true
      ) {
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin, sakuhinIndex) => {
            if (sakuhin.is_parent == false && sakuhin.is_divided == true) {
              if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
                ori.sakuhin_array.splice(sakuhinIndex, 1);
              }
            }
          });
        });
      }
    },
    onEndSakuhin() {
      //ページ範囲内の場合アラート表示
      this.confirmPageRangePosition();
      //selectedのデータ有り
      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        this.$nextTick(() => {
          //selectedSakuhinの前後の作品を取得
          let preSelectedSakuhin;
          let nextSelectedSakuhin;
          if (this.selectedSakuhin.sakuhin_address_computed_array[0] !== 1) {
            if (
              this.selectedSakuhin.is_divided === false &&
              this.selectedSakuhin.sakuhin_index === 0
            ) {
              //didivdeしていて折内で１番目の作品なら、そのpreSakuhinは1つ前の折に所属する
              preSelectedSakuhin =
                this.daiwariData.ori_array[
                  this.selectedSakuhin.parent_ori_address_computed - 2
                ].sakuhin_array[0];
            } else if (
              (this.selectedSakuhin.is_divided === false &&
                this.selectedSakuhin.sakuhin_index !== 0) ||
              this.selectedSakuhin.is_divided
            ) {
              //divideしていて折内で２番目以降の作品 または divideしているなら、そのpreSakuihinは同じ折に所属する
              this.daiwariData.ori_array[
                this.selectedSakuhin.parent_ori_address_computed - 1
              ].sakuhin_array.forEach((sakuhin) => {
                if (
                  sakuhin.sakuhin_address_computed ===
                  this.selectedSakuhin.sakuhin_address_computed - 1
                ) {
                  preSelectedSakuhin = sakuhin;
                }
              });
            }
          } else {
            //1折り目の作品の時nextSelectedSakuhinを探す
            this.daiwariData.ori_array.forEach((ori) => {
              ori.sakuhin_array.forEach((sakuhin) => {
                if (
                  sakuhin.orderOfTheSakuhin ===
                  this.selectedSakuhin.orderOfTheSakuhin + 1
                ) {
                  nextSelectedSakuhin = sakuhin;
                }
              });
            });
          }
          this.daiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              if (
                this.selectedSakuhin.sakuhin_address_computed_array[0] <
                sakuhin.sakuhin_address_computed_array[0]
              ) {
                if (preSelectedSakuhin) {
                  if (util.is_matched_sakuhinId(preSelectedSakuhin, sakuhin)) {
                    this.daiwariData.ori_array[
                      sakuhin.parent_ori_address_computed - 1
                    ].sakuhin_array.splice(
                      sakuhin.sakuhin_address_computed - 1,
                      1
                    );
                    util.rebuildDaiwariData(
                      this.daiwariData,
                      maxAddress,
                      maxSameGroupCd
                    );
                  }
                }
                if (nextSelectedSakuhin) {
                  //nextSelectedSakuhin以降にnextSelectedSakuhinと同じ作品があれば削除する(削除漏れ防止)
                  if (
                    util.is_matched_sakuhinId(nextSelectedSakuhin, sakuhin) &&
                    nextSelectedSakuhin.sakuhin_address_computed_array[0] <
                      sakuhin.sakuhin_address_computed_array[0]
                  ) {
                    this.daiwariData.ori_array[
                      sakuhin.parent_ori_address_computed - 1
                    ].sakuhin_array.splice(
                      sakuhin.sakuhin_address_computed - 1,
                      1
                    );
                    util.rebuildDaiwariData(
                      this.daiwariData,
                      maxAddress,
                      maxSameGroupCd
                    );
                  }
                }
              }
            });
          });
        });
        resolve();
      })
        .then(() => {
          util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        })
        .then(() => {
          // 原稿料の色数変更処理
          this.selectedSakuhin.genka_array.forEach((genka, genkaIndex) => {
            if (
              //原価区分「原稿料」/取引先情報あり
              this.selectedSakuhin.toggleHyousiFlg === false &&
              genka.meisyo === "原稿料" &&
              genka.torihikisaki
            ) {
              genka.genkaInfo_array.forEach((kbn) => {
                if (kbn.meisyo === "原稿料") {
                  // kbn.tankaInfo_array.forEach((tanka) => {
                  //   tanka.overwriteTankaFlg = false;
                  // });
                  if (genka.torihikisaki.tankaList.length > 0) {
                    let target_sikisuu_num = Number(
                      this.selectedSakuhin.ori_sikisuu.replace("C", "")
                    );
                    if (target_sikisuu_num == 1) {
                      genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 1) {
                          kbn.tankaInfo_array[0].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            kbn.tankaInfo_array[0].tanka == 0 &&
                            !(
                              (this.selectedSakuhin.genkoryo_settei
                                .setteiKbn === 2 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 3 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 4) &&
                              genka.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[0].tanka =
                              defaultTanka.tankaKingaku;
                          }
                          if (kbn.tankaInfo_array[0].kingaku == 0) {
                            kbn.tankaInfo_array[0].kingaku =
                              kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                          }
                          if (
                            kbn.tankaInfo_array[0].tanka ==
                            kbn.tankaInfo_array[0].defaultTanka
                          ) {
                            kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                          } else {
                            kbn.tankaInfo_array[0].overwriteTankaFlg = true;
                          }
                        }
                      });
                    } else if (target_sikisuu_num > 3) {
                      genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 2) {
                          kbn.tankaInfo_array[1].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            kbn.tankaInfo_array[1].tanka == 0 &&
                            !(
                              (this.selectedSakuhin.genkoryo_settei
                                .setteiKbn === 2 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 3 ||
                                this.selectedSakuhin.genkoryo_settei
                                  .setteiKbn === 4) &&
                              genka.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[1].tanka =
                              defaultTanka.tankaKingaku;
                          }
                          if (kbn.tankaInfo_array[1].kingaku == 0) {
                            kbn.tankaInfo_array[1].kingaku =
                              kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                          }
                          if (
                            kbn.tankaInfo_array[1].tanka ==
                            kbn.tankaInfo_array[1].defaultTanka
                          ) {
                            kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                          } else {
                            kbn.tankaInfo_array[1].overwriteTankaFlg = true;
                          }
                        }
                      });
                    }
                    this.calc_totalKingaku();
                    this.updateGenkaInfo(this.selectedSakuhin, genkaIndex);
                  }
                }
              });
            }
          });
          this.update_daiwariSakuhinArray();
          this.addSakuhinAfterFlg();
          this.daiwariData.sakuhin_page_bikou_array =
            pageBikou.sort_sakuhin_page_bikou_array(
              this.daiwariData.sakuhin_page_bikou_array,
              this.daiwariSakuhinArray,
              this.daiwariData
            );
        })
        .then(() => {
          this.deleteEmptyOri();
        })
        .then(() => {
          this.setInitialDuplicatedOriKbn();
        });
    },
    async deleteEmptyOri() {
      await new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      });
      const newDaiwariData = this.daiwariData.ori_array.filter(
        (el) => !(el.sakuhin_array.length == 0)
      );
      this.daiwariData.ori_array = [...newDaiwariData];
      new Promise((resolve_1) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve_1();
      }).then(() => {
        this.update_daiwariSakuhinArray();
      });
    },

    filter_pre_orderOfSelectedSakuhin(selectedSakuhin, originDaiwariData) {
      originDaiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.find((sakuhin) => {
          return (
            sakuhin.orderOfTheSakuhin === selectedSakuhin.orderOfTheSakuhin - 1
          );
        });
      });
    },
    filter_next_orderOfSelectedSakuhin(selectedSakuhin, originDaiwariData) {
      originDaiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.find((sakuhin) => {
          sakuhin.orderOfTheSakuhin === selectedSakuhin.orderOfTheSakuhin + 1;
        });
      });
    },
    inputOriKbn(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      this.selectedOri.ori_kbn = newVal;

      //区分が重複した折をハイライトさせるためのプロパティ
      this.daiwariData.ori_array.forEach((ori) => {
        ori.is_duplicated_ori_kbn = false;
      });

      //折区分のみが入った配列を作成し重複した折区分を抽出する
      let oriKbnArray = [];
      this.daiwariData.ori_array.forEach((ori) => {
        oriKbnArray.push(ori.ori_kbn);
      });
      let duplicatedOriKbnArray = oriKbnArray.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });
      //折区分「表紙」は重複チェックの対象に入らないため「表紙」以外の折に絞り込む
      duplicatedOriKbnArray = duplicatedOriKbnArray.filter(
        (kbn) => kbn !== "表紙"
      );
      if (duplicatedOriKbnArray.length > 0) {
        notyf.error("折区分に重複個所があります");
        this.daiwariData.ori_array.forEach((ori) => {
          duplicatedOriKbnArray.forEach((dup) => {
            if (ori.ori_kbn == dup && dup !== "表紙") {
              ori.is_duplicated_ori_kbn = true;
            }
          });
        });
      }
      // 折コンポーネントのメソッドを発火させ、重複の色付けを行う
      this.daiwariData.ori_array.forEach((ori, index) => {
        this.$refs["oriComp" + index].reflectDuplicatedOriKbn();
      });
    },
    //色数・用紙・加工を引き継ぐ際に使用する
    allowsToChangeOriInfo(dividedOriArray_from_selectedOri) {
      this.dividedOriArray_from_selectedOri.splice(
        0,
        dividedOriArray_from_selectedOri.length
      );
      this.dividedOriArray_from_selectedOri = dividedOriArray_from_selectedOri;
    },
    //色数の変更
    inputSikisuu(newVal, dividedOriArray_from_selectedOri) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedOri.sikisuu = newVal + "C";

      this.selectedOri.sakuhin_array.forEach((sakuhin) => {
        sakuhin.genka_array.forEach((genka, genkaIndex) => {
          if (
            //原価区分「原稿料」/取引先情報あり
            sakuhin.toggleHyousiFlg === false &&
            genka.meisyo === "原稿料" &&
            genka.torihikisaki
          ) {
            genka.genkaInfo_array.forEach((kbn) => {
              if (kbn.meisyo === "原稿料") {
                // kbn.tankaInfo_array.forEach((tanka) => {
                //   tanka.overwriteTankaFlg = false;
                // });
                if (genka.torihikisaki.tankaList.length > 0) {
                  let target_sikisuu_num = Number(newVal.replace("C", ""));
                  if (target_sikisuu_num == 1) {
                    genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                      if (defaultTanka.tankaKbnCd == 1) {
                        kbn.tankaInfo_array[0].defaultTanka =
                          defaultTanka.tankaKingaku;
                        if (
                          kbn.tankaInfo_array[0].tanka == 0 &&
                          !(
                            (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                              sakuhin.genkoryo_settei.setteiKbn === 3 ||
                              sakuhin.genkoryo_settei.setteiKbn === 4) &&
                            genka.isAutoAppend
                          )
                        ) {
                          kbn.tankaInfo_array[0].tanka =
                            defaultTanka.tankaKingaku;
                        }
                        if (kbn.tankaInfo_array[0].kingaku == 0) {
                          kbn.tankaInfo_array[0].kingaku =
                            kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                        }
                        if (
                          kbn.tankaInfo_array[0].tanka ==
                          kbn.tankaInfo_array[0].defaultTanka
                        ) {
                          kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                        } else {
                          kbn.tankaInfo_array[0].overwriteTankaFlg = true;
                        }
                      }
                    });
                  } else if (target_sikisuu_num > 3) {
                    genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                      if (defaultTanka.tankaKbnCd == 2) {
                        kbn.tankaInfo_array[1].defaultTanka =
                          defaultTanka.tankaKingaku;
                        if (
                          kbn.tankaInfo_array[1].tanka == 0 &&
                          !(
                            (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                              sakuhin.genkoryo_settei.setteiKbn === 3 ||
                              sakuhin.genkoryo_settei.setteiKbn === 4) &&
                            genka.isAutoAppend
                          )
                        ) {
                          kbn.tankaInfo_array[1].tanka =
                            defaultTanka.tankaKingaku;
                        }
                        if (kbn.tankaInfo_array[1].kingaku == 0) {
                          kbn.tankaInfo_array[1].kingaku =
                            kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                        }
                        if (
                          kbn.tankaInfo_array[1].tanka ==
                          kbn.tankaInfo_array[1].defaultTanka
                        ) {
                          kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                        } else {
                          kbn.tankaInfo_array[1].overwriteTankaFlg = true;
                        }
                      }
                    });
                  }
                  this.calc_totalKingaku();
                  this.updateGenkaInfo(sakuhin, genkaIndex);
                }
              }
            });
          }
        });
        //色数を変更したら作品のori_sikisuuを変更する
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            sakuhin.ori_sikisuu = ori.sikisuu;
          });
        });
      });
      if (dividedOriArray_from_selectedOri.length) {
        this.takeOverSikisuu(dividedOriArray_from_selectedOri);
      }
    },
    //色数の変更を分断作品に伝える
    takeOverSikisuu(dividedOriArray_from_selectedOri) {
      // 色数を折と作品に設定する
      dividedOriArray_from_selectedOri.forEach((dividedOri) => {
        this.daiwariData.ori_array[
          dividedOri.ori_address_computed - 1
        ].sikisuu = this.selectedOri.sikisuu;
        this.daiwariData.ori_array[
          dividedOri.ori_address_computed - 1
        ].sakuhin_array.forEach((sakuhin) => {
          sakuhin.ori_sikisuu = this.selectedOri.sikisuu;
          // 原稿料設定を変更した色数に準拠させる
          sakuhin.genka_array.forEach((genka, genkaIndex) => {
            if (
              //原価区分「原稿料」/取引先情報あり
              sakuhin.toggleHyousiFlg === false &&
              genka.meisyo === "原稿料" &&
              genka.torihikisaki
            ) {
              genka.genkaInfo_array.forEach((kbn) => {
                if (kbn.meisyo === "原稿料") {
                  // kbn.tankaInfo_array.forEach((tanka) => {
                  //   tanka.overwriteTankaFlg = false;
                  // });
                  if (genka.torihikisaki.tankaList.length > 0) {
                    let target_sikisuu_num = Number(
                      this.selectedOri.sikisuu.replace("C", "")
                    );
                    if (target_sikisuu_num == 1) {
                      genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 1) {
                          kbn.tankaInfo_array[0].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            kbn.tankaInfo_array[0].tanka == 0 &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              genka.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[0].tanka =
                              defaultTanka.tankaKingaku;
                          }
                          if (kbn.tankaInfo_array[0].kingaku == 0) {
                            kbn.tankaInfo_array[0].kingaku =
                              kbn.tankaInfo_array[0].tanka * kbn.suuryo;
                          }
                          if (
                            kbn.tankaInfo_array[0].tanka ==
                            kbn.tankaInfo_array[0].defaultTanka
                          ) {
                            kbn.tankaInfo_array[0].overwriteTankaFlg = false;
                          } else {
                            kbn.tankaInfo_array[0].overwriteTankaFlg = true;
                          }
                        }
                      });
                    } else if (target_sikisuu_num > 3) {
                      genka.torihikisaki.tankaList.forEach((defaultTanka) => {
                        if (defaultTanka.tankaKbnCd == 2) {
                          kbn.tankaInfo_array[1].defaultTanka =
                            defaultTanka.tankaKingaku;
                          if (
                            kbn.tankaInfo_array[1].tanka == 0 &&
                            !(
                              (sakuhin.genkoryo_settei.setteiKbn === 2 ||
                                sakuhin.genkoryo_settei.setteiKbn === 3 ||
                                sakuhin.genkoryo_settei.setteiKbn === 4) &&
                              genka.isAutoAppend
                            )
                          ) {
                            kbn.tankaInfo_array[1].tanka =
                              defaultTanka.tankaKingaku;
                          }
                          if (kbn.tankaInfo_array[1].kingaku == 0) {
                            kbn.tankaInfo_array[1].kingaku =
                              kbn.tankaInfo_array[1].tanka * kbn.suuryo;
                          }
                          if (
                            kbn.tankaInfo_array[1].tanka ==
                            kbn.tankaInfo_array[1].defaultTanka
                          ) {
                            kbn.tankaInfo_array[1].overwriteTankaFlg = false;
                          } else {
                            kbn.tankaInfo_array[1].overwriteTankaFlg = true;
                          }
                        }
                      });
                    }
                    this.calc_totalKingaku();
                    this.updateGenkaInfo(sakuhin, genkaIndex);
                  }
                }
              });
            }
          });
        });
      });
    },
    //加工区分の変更
    inputKakou(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      //折区分「表紙」の加工情報を連動させる
      if (this.selectedOri.ori_kbn === "表紙") {
        const confirm =
          "折区分「表紙」の加工情報が一括で更新されます。よろしいですか？";
        if (window.confirm(confirm)) {
          this.selectedOri.kakou_array.splice(0);
          newVal.forEach((newKakou) => {
            this.selectedOri.kakou_array.push({
              kbn: newKakou.kbn,
            });
          });
          this.daiwariData.ori_array.forEach((ori) => {
            if (ori.ori_kbn === "表紙") {
              ori.kakou_array.splice(0);
              newVal.forEach((newKakou) => {
                ori.kakou_array.push({
                  kbn: newKakou.kbn,
                });
              });
            }
          });
        } else {
          //変更をキャンセルした場合
          //変更を検知できずセレクトボックスを選択前の状態に戻せないため
          //意図的にプロパティを追加・削除してリアクティブにさせる
          this.selectedOri.property = "";
          delete this.selectedOri.property;
        }
      } else {
        //表紙以外の加工情報はそのままセットする
        this.selectedOri.kakou_array.splice(0);
        newVal.forEach((newKakou) => {
          this.selectedOri.kakou_array.push({
            kbn: newKakou.kbn,
          });
        });
        this.takeOverKakou();
      }
    },
    removeKakou(removeIndex) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      //折区分「表紙」の加工情報を連動させる
      if (this.selectedOri.ori_kbn === "表紙") {
        const confirm =
          "折区分「表紙」の加工情報が一括で更新されます。よろしいですか？";
        if (window.confirm(confirm)) {
          this.daiwariData.ori_array.forEach((ori) => {
            if (ori.ori_kbn === "表紙") {
              ori.kakou_array.splice(removeIndex, 1);
            }
          });
        } else {
          //変更をキャンセルした場合
          //変更を検知できずセレクトボックスを選択前の状態に戻せないため
          //意図的にプロパティを追加・削除してリアクティブにさせる
          this.selectedOri.property = "";
          delete this.selectedOri.property;
        }
      } else {
        this.selectedOri.kakou_array.splice(removeIndex, 1);
        this.takeOverKakou();
      }
    },
    takeOverKakou() {
      if (this.dividedOriArray_from_selectedOri.length) {
        this.dividedOriArray_from_selectedOri.forEach((dividedOri) => {
          this.daiwariData.ori_array[
            dividedOri.ori_address_computed - 1
          ].kakou_array = [...this.selectedOri.kakou_array];
        });
      }
    },
    updateYoushi(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      this.selectedOri.yousi = newVal;
      this.takeOverYousi();
    },
    takeOverYousi() {
      if (this.dividedOriArray_from_selectedOri.length) {
        this.dividedOriArray_from_selectedOri.forEach((dividedOri) => {
          this.daiwariData.ori_array[
            dividedOri.ori_address_computed - 1
          ].yousi = this.selectedOri.yousi;
        });
      }
    },
    removeYoushi() {
      this.selectedOri.yousi = "";
      this.takeOverYousi();
    },
    //台割が保存済みか否かを返す
    isDaiwariSaved() {
      let daiwariData = JSON.parse(JSON.stringify(this.daiwariData));
      let daiwariDataDB = JSON.parse(JSON.stringify(this.daiwariData_sameAsDB));
      new Promise((resolve) => {
        // 台割比較時に比較不要なプロパティを削除する
        daiwariData.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
          });
        });
        daiwariDataDB.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
          });
        });
        resolve();
      }).then(() => {
        const JSON_daiwariData = JSON.stringify(daiwariData);
        const JSON_daiwariData_sameAsDB = JSON.stringify(daiwariDataDB);
        this.is_daiwari_saved = JSON_daiwariData === JSON_daiwariData_sameAsDB;
        return JSON_daiwariData === JSON_daiwariData_sameAsDB;
      });
    },
    back_to_previousPage() {
      new Promise((resolve) => {
        //未保存か否かを判定(判定前にダイアログ処理が走るためpromise)
        this.isDaiwariSaved();
        resolve();
      }).then(() => {
        //台割が未保存である場合、確認用ダイアログ表示する
        if (this.is_daiwari_saved || this.isLockDaiwari) {
          const query = this.isJuhanDaiwari
            ? { isJuhan: true }
            : this.isKanriBusyoFlg
            ? { status: 0, busyo: 0 }`${this.url}/daiwari?status=0&busyo=0`
            : {};
          this.$router.push({ path: "/daiwari", query: query });
        } else {
          this.pageTransitionTo = "/daiwari";
          this.modalName = "modal_saveConfimation";
        }
      });
    },
    go_to_kikaku_edit() {
      new Promise((resolve) => {
        //未保存か否かを判定(判定前にダイアログ処理が走るためpromise)
        this.isDaiwariSaved();
        resolve();
      }).then(() => {
        //台割が未保存である場合、確認用ダイアログ表示する
        if (this.is_daiwari_saved || this.isLockDaiwari) {
          //保存されている場合、企画編集画面へ
          this.$router.push({
            path: "/kikaku_edit",
            query: { kikakuId: this.kikakuId },
          });
        } else {
          //未保存の場合、保存後の遷移先（this.pageTransitionTo）を企画編集画面へ設定
          this.pageTransitionTo = `/kikaku_edit`;
          this.modalName = "modal_saveConfimation";
        }
      });
    },
    resetDaiwariErrorList() {
      this.daiwari_errorList = [];
    },
    updateDaiwariErrorList(list) {
      this.daiwari_errorList = [...list];
    },
    resetDaiwariErrorPaints() {
      this.daiwari_error_paints = [];
      this.daiwari_genkaerror_paints = [];
    },
    updateDaiwariErrorPaints(errorList, genkaErrorList) {
      this.daiwari_error_paints = [...errorList];
      this.daiwari_genkaerror_paints = [...genkaErrorList];
    },
    pageTransition(pageUrl) {
      this.pageTransitionTo = pageUrl;

      new Promise((resolve) => {
        //未保存か否かを判定(判定前にダイアログ処理が走るためpromise)
        this.isDaiwariSaved();
        resolve();
      }).then(() => {
        //台割が未保存である場合、確認用ダイアログ表示する
        if (this.is_daiwari_saved) {
          location.href = this.url + pageUrl;
        } else {
          this.modalName = "modal_saveConfimation";
        }
      });
    },
    // 新規作品追加時の原稿料設定を判定して返す
    judgeInitialGenkoryoSettei() {
      let genkoryoSettei = {
        setteiKbn: -1,
        meisyo: "",
      };
      // 雑誌・アンソロの場合
      if (
        this.daiwariData.outputSyosiData.isZassi ||
        this.daiwariData.outputSyosiData.isAnthology
      ) {
        genkoryoSettei.setteiKbn = 1;
        genkoryoSettei.meisyo = "原稿料あり";
      } else {
        genkoryoSettei.setteiKbn = 2;
        genkoryoSettei.meisyo = "原稿料なし";
      }
      return genkoryoSettei;
    },
    onClickAddOri(
      initialSikisuu,
      initialKbn,
      insertOriIndex,
      shouldTakeOverOriInfo
    ) {
      new Promise((resolve) => {
        this.save_backupDaiwari(
          this.daiwariData,
          this.selectedSakuhin,
          this.selectedOri
        );
        resolve();
      }).then(() => {
        //選択中折・作品の切り替え
        if (this.selectedOri) {
          this.selectedOri.is_selected_ori = false;
          this.selectedOri = null;
        }
        if (this.selectedSakuhin) {
          this.selectedSakuhin.is_selected_sakuhin = false;
          this.selectedSakuhin = null;
          this.daiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              sakuhin.is_selected_sakuhin = false;
            });
          });
        }

        //折・作品の追加(決定ボタン押下後)
        let ori = new Ori();
        // v-forのkeyであるori_addressが重複しないよう、既存のori_addresの最大値+1を設定
        ori.ori_address = ++maxAddress.ori;
        ori.ori_kbn = this.daiwariData.ori_array.length + 1 + "K";

        for (let i = 0; i < 1; i++) {
          let sakuhin = new Sakuhin();
          sakuhin.sakuhin_address = ++maxAddress.sakuhin;
          sakuhin.sakuhin_uniqueId = util.getUniqueStr();
          sakuhin.sakuhin_bikouId = util.getUniqueStr();
          ori.sakuhin_array.push(sakuhin);
          this.addBikou_toPageBikouArray(sakuhin);
          sakuhin.pageCountFlg = true;
          sakuhin.sakuhin_address_computed_array_withFlg = [];
          const judgedGenkoryoSettei = this.judgeInitialGenkoryoSettei();
          sakuhin.genkoryo_settei.setteiKbn = judgedGenkoryoSettei.setteiKbn;
          sakuhin.genkoryo_settei.meisyo = judgedGenkoryoSettei.meisyo;
          // sakuhin.genkoryo_settei = this.judgeInitialGenkoryoSettei();
        }
        // ori.ori_kbn = "折" + ori.ori_address;
        this.selectedSakuhin = ori.sakuhin_array[0];
        this.selectedSakuhin.is_selected_sakuhin = true;
        this.selectedSakuhin.sakuhin_bikou_flg = false;
        this.selectedSideBar = 2;
        if (insertOriIndex === null) {
          this.daiwariData.ori_array.push(ori);
        } else {
          this.daiwariData.ori_array.splice(insertOriIndex, 0, ori);
        }
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        this.update_daiwariSakuhinArray();

        this.daiwariData.sakuhin_page_bikou_array =
          pageBikou.sort_sakuhin_page_bikou_array(
            this.daiwariData.sakuhin_page_bikou_array,
            this.daiwariSakuhinArray,
            this.daiwariData
          );
        this.addSakuhinAfterFlg();

        //色数・区分の初期設定
        let newOri =
          this.daiwariData.ori_array[
            this.selectedSakuhin.parent_ori_address_computed - 1
          ];

        //表紙の場合、最初に出てくる表紙折の折情報(色数・用紙・加工)を自動で引継ぐ
        const firstHyousiOri = this.daiwariData.ori_array.find(
          (ori) => ori.ori_kbn === "表紙"
        );
        const hasFirstHyosiOri = shouldTakeOverOriInfo && firstHyousiOri;
        newOri.sikisuu = hasFirstHyosiOri
          ? firstHyousiOri.sikisuu
          : initialSikisuu;
        newOri.yousi = hasFirstHyosiOri ? firstHyousiOri.yousi : "";
        newOri.kakou_array = hasFirstHyosiOri
          ? [...firstHyousiOri.kakou_array]
          : [];
        newOri.ori_kbn = initialKbn;

        //折区分「表紙」または「表N」の場合、表紙フラグをオンにする
        if (
          !this.daiwariData.outputSyosiData.isZassi &&
          initialKbn === "表紙"
        ) {
          newOri.sakuhin_array.forEach((sakuhin) => {
            sakuhin.toggleHyousiFlg = true;
          });
        } else if (
          !this.daiwariData.outputSyosiData.isZassi &&
          initialKbn.includes("表")
        ) {
          const slicedValue = initialKbn.slice(1, 2);
          if (util.isNumber(slicedValue)) {
            newOri.sakuhin_array.forEach((sakuhin) => {
              sakuhin.toggleHyousiFlg = true;
            });
          }
        }

        this.$nextTick(() => {
          this.$refs.childSakuhin.focusInputPage();
          this.setInitialDuplicatedOriKbn();
        });
      });
    },

    onClickAddSakuhin() {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      let sakuhin = new Sakuhin();
      sakuhin.sakuhin_address = ++maxAddress.sakuhin;
      sakuhin.pageCountFlg = true;
      sakuhin.sakuhin_address_computed_array_withFlg = [];
      sakuhin.sakuhin_id = -1;
      sakuhin.sakuhin_uniqueId = util.getUniqueStr();
      sakuhin.sakuhin_bikouId = util.getUniqueStr();
      const judgedGenkoryoSettei = this.judgeInitialGenkoryoSettei();
      sakuhin.genkoryo_settei.setteiKbn = judgedGenkoryoSettei.setteiKbn;
      sakuhin.genkoryo_settei.meisyo = judgedGenkoryoSettei.meisyo;

      this.daiwariData.ori_array[
        this.selectedOri.ori_address_computed - 1
      ].sakuhin_array.push(sakuhin);

      //備考の追加
      this.addBikou_toPageBikouArray(sakuhin);
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
      this.setInitialDuplicatedOriKbn();

      this.daiwariData.sakuhin_page_bikou_array =
        pageBikou.sort_sakuhin_page_bikou_array(
          this.daiwariData.sakuhin_page_bikou_array,
          this.daiwariSakuhinArray,
          this.daiwariData
        );
    },
    onClickAddSakuhinBefore(sakuhin) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      let newSakuhin = new Sakuhin();
      newSakuhin.sakuhin_address = ++maxAddress.sakuhin;
      newSakuhin.pageCountFlg = true;
      newSakuhin.sakuhin_address_computed_array_withFlg = [];
      newSakuhin.sakuhin_id = -1;
      newSakuhin.sakuhin_uniqueId = util.getUniqueStr();
      newSakuhin.sakuhin_bikouId = util.getUniqueStr();
      const judgedGenkoryoSettei = this.judgeInitialGenkoryoSettei();
      newSakuhin.genkoryo_settei.setteiKbn = judgedGenkoryoSettei.setteiKbn;
      newSakuhin.genkoryo_settei.meisyo = judgedGenkoryoSettei.meisyo;
      let target_ori =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1];
      let sakuhinTotalPage = 0;
      let newOri = new Ori();
      newOri.ori_kbn = this.daiwariData.ori_array.length + 1 + "K";
      newOri.sikisuu = target_ori.sikisuu;
      newOri.yousi = target_ori.yousi;
      newOri.ori_bikou = target_ori.ori_bikou;
      newOri.ori_bikou_flg = target_ori.ori_bikou_flg;
      newOri.kakou_array = [...target_ori.kakou_array];
      //折区分「表紙」「表N」の場合、表紙フラグをオンにする
      if (this.isHyosiOri(target_ori)) {
        newSakuhin.toggleHyousiFlg = true;
      }
      target_ori.sakuhin_array.splice(
        sakuhin.sakuhin_address_computed - 1,
        0,
        newSakuhin
      );

      target_ori.sakuhin_array.forEach((sakuhin) => {
        sakuhinTotalPage += sakuhin.total_page;
      });

      if (target_ori.oriMaximumPage_custom == sakuhinTotalPage) {
        this.daiwariData.ori_array.splice(
          sakuhin.parent_ori_address_computed,
          0,
          newOri
        );
      }

      this.addBikou_toPageBikouArray(newSakuhin);
      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(() => {
        this.update_daiwariSakuhinArray();
        this.daiwariData.sakuhin_page_bikou_array =
          pageBikou.sort_sakuhin_page_bikou_array(
            this.daiwariData.sakuhin_page_bikou_array,
            this.daiwariSakuhinArray,
            this.daiwariData
          );
        this.setInitialDuplicatedOriKbn();
      });
    },
    onClickAddSakuhinAfter(sakuhin) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      let countMax = 0;
      //選択中の折の作品配列
      let sakuhinArray = [
        ...this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array,
      ];
      //選択中の折の作品数
      let sakuhinArrayLength =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          .sakuhin_array.length;

      let target_ori =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1];

      //クリックした作品がその作品の配列の一番うしろにあるとき(countMaxはこの条件以外のときはカウントされず0になる)
      if (sakuhinArrayLength == sakuhin.sakuhin_address_computed) {
        if (!sakuhin.is_divided) {
          sakuhinArray.forEach((sakuhin) => {
            if (sakuhin.divided_page > 0) {
              countMax += sakuhin.divided_page;
            } else {
              countMax += sakuhin.total_page;
            }
          });
        } else {
          //分断した作品の場合dividedPageをカウント数に追加
          // 「is_divided」かつ「作品追加ボタン押下可能」かつ「作品配列の一番後ろ」
          // ↑の条件を満たす作品は、折内にその作品しかない状態
          countMax += sakuhin.divided_page;
        }
      }

      let sakuhinTotalPage = 0;
      let newOri = new Ori();
      newOri.ori_kbn = util.new_ori_kbn(target_ori);
      newOri.sikisuu = target_ori.sikisuu;
      newOri.yousi = target_ori.yousi;
      newOri.ori_bikou = target_ori.ori_bikou;
      newOri.ori_bikou_flg = target_ori.ori_bikou_flg;
      newOri.kakou_array = [...target_ori.kakou_array];
      target_ori.sakuhin_array.forEach((sakuhin) => {
        if (sakuhin.divided_page > 0) {
          sakuhinTotalPage += sakuhin.divided_page;
        } else {
          sakuhinTotalPage += sakuhin.total_page;
        }
      });

      let newSakuhin = new Sakuhin();
      newSakuhin.sakuhin_address = ++maxAddress.sakuhin;
      newSakuhin.pageCountFlg = true;
      newSakuhin.sakuhin_address_computed_array_withFlg = [];
      newSakuhin.sakuhin_id = -1;
      newSakuhin.sakuhin_uniqueId = util.getUniqueStr();
      newSakuhin.sakuhin_bikouId = util.getUniqueStr();
      const judgedGenkoryoSettei = this.judgeInitialGenkoryoSettei();
      newSakuhin.genkoryo_settei.setteiKbn = judgedGenkoryoSettei.setteiKbn;
      newSakuhin.genkoryo_settei.meisyo = judgedGenkoryoSettei.meisyo;
      //折の上限貢数(カスタム)参照
      const parentOriMaxPage =
        this.daiwariData.ori_array[
          this.selectedSakuhin.parent_ori_address_computed - 1
        ].oriMaximumPage_custom;
      const oriMaxPage = parentOriMaxPage
        ? parentOriMaxPage
        : this.daiwariData.oriMaximumPage;
      // 「折り返し元のページ数がページ上限に達した」かつ「次の折区分が異なる」場合新たに折を生成
      let next_ori =
        this.daiwariData.ori_array[sakuhin.parent_ori_address_computed]; // 次折
      if (
        next_ori != undefined &&
        oriMaxPage == sakuhinTotalPage &&
        !util.bulkCheckOriKbn(target_ori, next_ori)
      ) {
        this.daiwariData.ori_array.splice(
          sakuhin.parent_ori_address_computed,
          0,
          newOri
        );
      }

      if (countMax < oriMaxPage) {
        //折区分「表紙」または「表N」の時に表紙フラグを設定する
        if (
          this.isHyosiOri(
            this.daiwariData.ori_array[sakuhin.parent_ori_address_computed - 1]
          )
        ) {
          newSakuhin.toggleHyousiFlg = true;
        }
        //16以下なら同折内に作品追加
        this.daiwariData.ori_array[
          sakuhin.parent_ori_address_computed - 1
        ].sakuhin_array.splice(sakuhin.sakuhin_address_computed, 0, newSakuhin);
      } else {
        //16以上なら1つ後ろの折に作品追加
        //後続に折がある場合
        if (
          this.daiwariData.ori_array.length >
          sakuhin.parent_ori_address_computed
        ) {
          this.daiwariData.ori_array[
            sakuhin.parent_ori_address_computed
          ].sakuhin_array.splice(0, 0, newSakuhin);
        } else {
          // 後続に折がないので新たに生成
          let newOri = new Ori();
          newOri.ori_address = ++maxAddress.ori;
          const pre_ori =
            this.daiwariData.ori_array[
              this.selectedSakuhin.parent_ori_address_computed - 1
            ];
          newOri.ori_kbn = util.new_ori_kbn(pre_ori);
          newOri.sikisuu = target_ori.sikisuu;
          newOri.yousi = target_ori.yousi;
          newOri.ori_bikou = target_ori.ori_bikou;
          newOri.ori_bikou_flg = target_ori.ori_bikou_flg;
          newOri.kakou_array = [...target_ori.kakou_array];
          newOri.sakuhin_array.push(newSakuhin);
          this.daiwariData.ori_array.push(newOri);
        }
      }
      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(() => {
        this.update_daiwariSakuhinArray();

        //備考の追加
        this.addBikou_toPageBikouArray(newSakuhin);
        this.addSakuhinAfterFlg();
        this.daiwariData.sakuhin_page_bikou_array =
          pageBikou.sort_sakuhin_page_bikou_array(
            this.daiwariData.sakuhin_page_bikou_array,
            this.daiwariSakuhinArray,
            this.daiwariData
          );
        this.setInitialDuplicatedOriKbn();
      });
    },
    addSakuhinAfterFlg() {
      // dividedしている場合最後の要素にだけボタンを付与
      this.same_sakuhin_array = [];
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
            this.same_sakuhin_array.push(sakuhin);
            sakuhin.is_selected_sakuhin = true;
          }
        });
      });
      this.same_sakuhin_array.forEach((sakuhin, index) => {
        if (index == this.same_sakuhin_array.length - 1) {
          sakuhin.addSakuhinAfterFlg = true;
        } else {
          sakuhin.addSakuhinAfterFlg = false;
        }
      });
    },
    onClickDeleteOri() {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      // 対象の折を削除
      let oriIndex = Number(this.selectedOri.ori_address_computed) - 1;
      if (this.daiwariData.ori_array[oriIndex].sakuhin_array.length === 0) {
        this.daiwariData.ori_array.splice(oriIndex, 1);
        this.selectedSideBar = 0;
      } else {
        notyf.error(
          `[${this.selectedOri.ori_address_computed}]折は作品登録済みのため削除できません`
        );
      }
      // アドレス再計算
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
      this.setInitialDuplicatedOriKbn();
      // サイドバーを未選択に戻す
    },
    onClickDeleteSakuhin() {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      //ページ範囲内の場合アラート表示
      this.confirmPageRangePosition();
      if (this.selectedSakuhin.is_divided) {
        //dividedしている同作品は全て削除
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
              this.daiwariData.ori_array[
                sakuhin.parent_ori_address_computed - 1
              ].sakuhin_array.splice(sakuhin.sakuhin_index, 1);
            }
          });
        });
      } else {
        // 単一の作品の場合、対象の作品を削除
        this.daiwariData.ori_array[
          this.selectedSakuhin.parent_ori_address_computed - 1
        ].sakuhin_array.splice(this.selectedSakuhin.sakuhin_index, 1);
      }
      this.deleteBikou_fromPageBikouArray();
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
      this.selectedSideBar = 0;
      this.setInitialDuplicatedOriKbn();
    },
    //タイムアウト判定
    shouldTimeOut(JSON_oldDaiwari, JSON_newDaiwari) {
      if (JSON_oldDaiwari === JSON_newDaiwari) {
        return true;
      } else {
        return false;
      }
    },
    //タイムアウト実行
    showTimeoutModal(JSON_oldDaiwari, JSON_newDaiwari) {
      if (this.shouldTimeOut(JSON_oldDaiwari, JSON_newDaiwari)) {
        //未操作のためタイムアウト
        this.unLockDaiwari();
        this.modalName = "modal_timeout";
      } else {
        this.count_timeOut();
      }
    },
    //タイムアウト用に30分カウントする(読み取り時以外)
    count_timeOut() {
      if (!this.isLockDaiwari) {
        //タイムアウト延長報告
        if (this.alertTimeOut_flg && !this.daiwariHeader.isLock) {
          notyf.open({
            type: "info",
            message: "タイムアウトを延長しました",
          });
          this.alertTimeOut_flg = false;
        }
        //古いタイマーを削除
        if (this.setTimeout_timeoutId) {
          clearTimeout(this.setTimeout_timeoutId);
        }
        const oldDaiwari = JSON.stringify(this.daiwariData);
        this.setTimeout_timeoutId = setTimeout(() => {
          this.showTimeoutModal(oldDaiwari, JSON.stringify(this.daiwariData));
        }, 1800000); //30分:1800000,30秒:30000
      }
    },
    //台割保存
    async saveDaiwari(newHtml) {
      let json_array = this.updateDaicho();
      if (!this.isLockDaiwari) {
        // 講談社台割:保存時の奇数チェックをコメントアウト
        // this.oddCheck_array = [];
        // //折内のページ数が奇数の場合エラーを表示する※電子版は対象外
        // if (!this.daiwariHeader.isDensi) {
        //   this.daiwariData.ori_array.forEach((ori) => {
        //     let oddCheck_oriTotalPage = 0;
        //     ori.sakuhin_array.forEach((sakuhin) => {
        //       oddCheck_oriTotalPage +=
        //         sakuhin.sakuhin_address_computed_array.length;
        //     });
        //     if (oddCheck_oriTotalPage % 2 == 1) {
        //       this.oddCheck_array.push(ori.ori_address_computed);
        //     }
        //   });
        // }

        // if (this.oddCheck_array.length) {
        //   //エラー用モーダル(ModalOddCheck)を表示
        //   this.modalName = "modal_oddCheck";
        // } else {
        //一覧表示モーダル, 離脱アラートモーダルで保存した場合はモーダル閉じない
        if (
          this.modalName !== "modal_listDisplay" &&
          this.modalName !== "modal_saveConfimation"
        ) {
          this.modalName = "";
        }
        // 参照渡し回避
        let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
        let postData = {};
        const params = new URL(location.href).searchParams;
        // 要修正
        // 親、電子の保存データ
        if (newHtml === undefined) {
          postData = {
            daiwariId: this.daiwariId,
            daiwariData: JSON.stringify(
              util.formatBeforeUpdate(daiwariDataCopy)
            ),
            kikakuId: kikakuId,
            daiwariKakuteiHead: json_array,
            hachuId:
              params.get("hachuId") !== null
                ? Number(params.get("hachuId"))
                : null,
          };
        }
        // 電子以外の枝番の保存データ
        else {
          postData = {
            daiwariId: this.daiwariId,
            daiwariData: JSON.stringify(
              util.formatBeforeUpdate(daiwariDataCopy)
            ),
            kikakuId: kikakuId,
            daiwariKakuteiHead: json_array,
            daichoHtml: newHtml,
            hachuId:
              params.get("hachuId") !== null
                ? Number(params.get("hachuId"))
                : null,
          };
        }
        await axios
          .post(`${this.url}/daiwari`, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          })
          .then((response) => {
            console.log("★ thenブロック ★");
            console.log(response);

            if (response.status == 201) {
              notyf.success("保存しました");
              //sameAsDB(未保存チェック用)も更新する
              let daiwariDataCopy = JSON.parse(
                JSON.stringify(this.daiwariData)
              );
              this.daiwariData_sameAsDB = JSON.parse(
                JSON.stringify(daiwariDataCopy)
              );
              // 保存後ストレージの台割データは削除
              localStorage.removeItem(`daiwari_${this.daiwariId}`);
            } else {
              notyf.error("保存できませんでした");
              // Backlogへ起票する
              // axios.post(
              //   `${this.url}/yUBSH4pQNYANpLWNTWtp8vyEY87RunoE7Y02r3kEY9GczWtKftuR7vXRyNEkPQoSZUtjCiUCiPXB5mpe4wza0GUo9oPSJGGtLw2NGpd8pzRBcCflCZe/`,
              //   {
              //     daiwariId: this.daiwariId,
              //     kikakuId: this.kikakuId,
              //     title: this.daiwariResponseData.title,
              //     daiwariData: JSON.stringify(
              //       util.formatBeforeUpdate(daiwariDataCopy)
              //     ),
              //   },
              //   {
              //     headers: {
              //       Authorization: `Bearer ${localStorage.getItem(
              //         "accessToken"
              //       )}`,
              //     },
              //   }
              // );
            }
          })
          .catch((error) => {
            console.log("★ catchブロック ★");
            notyf.error("保存できませんでした");
            console.log(error);
          });
        // }
      }
    },
    saveDaiwariAuto() {
      //台割自動保存
      if (this.modalName !== "modal_timeout") {
        let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
        axios
          .post(
            `${this.url}/daiwari/save_daiwari_rireki_auto`,
            {
              daiwariId: this.daiwariId,
              daiwariData: JSON.stringify(
                util.formatBeforeUpdate(daiwariDataCopy)
              ),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.status != 201) {
              console.log("セッション切れのため自動保存できませんでした");
              alert("セッション切れのため自動保存できませんでした");
              // if (!this.kihyo_completed) {
              //   // Backlogへ起票する
              //   axios
              //     .post(
              //       `${this.url}/yUBSH4pQNYANpLWNTWtp8vyEY87RunoE7Y02r3kEY9GczWtKftuR7vXRyNEkPQoSZUtjCiUCiPXB5mpe4wza0GUo9oPSJGGtLw2NGpd8pzRBcCflCZe/`,
              //       {
              //         daiwariId: this.daiwariId,
              //         kikakuId: this.kikakuId,
              //         title: this.daiwariResponseData.title,
              //         daiwariData: JSON.stringify(
              //           util.formatBeforeUpdate(daiwariDataCopy)
              //         ),
              //       },
              //       {
              //         headers: {
              //           Authorization: `Bearer ${localStorage.getItem(
              //             "accessToken"
              //           )}`,
              //         },
              //       }
              //     )
              //     .then((response) => {
              //       if (response.data === "kihyo_completed") {
              //         this.kihyo_completed = true;
              //       }
              //     });
              // }
            }
          })
          .catch((error) => {
            console.log("自動保存できませんでした");
            console.log(error);
          });
      }
    },
    //台割確定後バージョン情報更新
    setDaiwariVersionInfo() {
      this.daiwariData.outputSyosiData.manualSyosiData.versionInfo =
        this.daiwariKakuteiVersion;
    },
    //台割確定
    kakuteiDaiwari(toSendData, toSendTitle) {
      // 作品の未登録チェック
      let hasDaiwariError = false;
      if (this.daiwariData.ori_array.length === 0) {
        hasDaiwariError = true;
      } else {
        let hasSakuhin = false;
        this.daiwariData.ori_array.forEach((ori) => {
          if (ori.sakuhin_array.length > 0) {
            hasSakuhin = true;
          }
        });
        if (hasSakuhin === false) {
          hasDaiwariError = true;
        }
      }

      if (hasDaiwariError === true) {
        notyf.error("作品が登録されていません");
      } else {
        this.isProcessing = true;
        let json_array = this.updateDaicho();
        // 追記内容が消えるのを防ぐためコメントアウト
        // this.modalName = "";
        let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
        axios
          .post(
            `${this.url}/daiwari/save_kakutei_daiwari`,
            {
              daiwariId: this.daiwariId,
              daiwariData: JSON.stringify(
                util.formatBeforeUpdate(daiwariDataCopy)
              ),
              kikakuId: kikakuId,
              daiwariKakuteiHead: json_array,
              title: toSendTitle,
              daiwariKakuteiMessage: toSendData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then(() => {
            this.isProcessing = false;
            notyf.success("台割確定を実行しました");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            this.isProcessing = false;
            notyf.error("台割確定を実行できませんでした");
            console.log(error);
          });
      }
    },
    //台帳確定
    kakuteiDaicho(newHtml) {
      let json_array = this.updateDaicho();
      let daiwariDataCopy = JSON.parse(JSON.stringify(this.daiwariData));
      this.isDaichoKakuteiError = false;

      const params = new URL(location.href).searchParams;
      axios
        .post(
          `${this.url}/save_kakutei_daicho/`,
          {
            daiwariId: this.daiwariId,
            daiwariData: JSON.stringify(
              util.formatBeforeUpdate(daiwariDataCopy)
            ),
            kikakuId: kikakuId,
            daiwariKakuteiHead: json_array,
            naiyo: newHtml,
            hachuId:
              params.get("hachuId") !== null
                ? Number(params.get("hachuId"))
                : null,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((res) => {
          if (res.data == "NG（製品化フラグOFF）") {
            notyf2.error("ISBN登録依頼をONにしてください");
            this.isDaichoKakuteiError = true;
          } else {
            notyf2.success("台帳確定ワークフロー申請画面に遷移します。");
            this.unLockDaiwari();
            const targetUrl = `${this.url}/wf_sinsei?sinsei_id=${res.data}`;
            setTimeout(function () {
              this.modalName = "";
              location.href = targetUrl;
            }, 3000);
          }
        })
        .catch((error) => {
          notyf2.error("台帳確定を実行できませんでした");
          this.isDaichoKakuteiError = true;
          console.log(error);
        });
    },
    //台帳整形
    updateDaicho() {
      let json_array = { daiwariId: this.daiwariId, daiwariKakuteiArray: [] };
      this.update_daiwariSakuhinArray();
      this.daiwariSakuhinArray.forEach((sakuhin) => {
        let sakuhinId =
          typeof sakuhin.sakuhin_id === "string"
            ? Number(sakuhin.sakuhin_id.split("*").join(""))
            : sakuhin.sakuhin_id;
        // ページ数の設定
        let start_page = null;
        let end_page = null;
        if (!(sakuhin.sakuhin_address_computed_array_withFlg.length === 0)) {
          start_page = sakuhin.sakuhin_address_computed_array_withFlg[0];
          if (sakuhin.total_page > 1) {
            end_page =
              sakuhin.total_page +
              sakuhin.sakuhin_address_computed_array_withFlg[0] -
              1;
          }
        }
        // 担当者のuserIdを配列に格納
        let tantouIdList = [];
        sakuhin.tantou.forEach((tantouInfo) => {
          tantouIdList.push(tantouInfo.user_id);
        });

        // 作品に紐づくペンネームidをカンマ区切りの文字列にする
        let pennameIdArray = [];
        sakuhin.penName.forEach((pen) => {
          pennameIdArray.push(pen.pennameId);
        });
        let pennameArrayConfiguredSakuhin = pennameIdArray.join(",");

        sakuhin.genka_array.forEach((genka) => {
          // 原価区分未設定分をここで拾う
          if (genka.genkaKbnId == 0) {
            json_array.daiwariKakuteiArray.push({
              torihikisakiId:
                genka.torihikisaki.torihikisakiId < 0
                  ? null
                  : genka.torihikisaki.torihikisakiId,
              suuryo: 0,
              tanka: 0,
              kingaku: 0,
              genkaKbnId: genka.genkaKbnId,
              sagyoNaiyo: genka.statusComment,
              startPage: start_page,
              endPage: end_page,
              irokazu: sakuhin.ori_sikisuu,
              sakuhinMei: sakuhin.sakuhin_mei,
              sakuhinTantoUserId: null,
              sakuhinId: sakuhinId,
              pennameId: genka.torihikisaki.pennameId,
              tantoUserIdList: tantouIdList,
              daiwariHyojiMei: sakuhin.sakuhin_mei_display,
              titleHosoku: sakuhin.sakuhin_mei_hosoku,
              genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
              isTankaZero: true,
              pennameArrayConfiguredSakuhin: pennameArrayConfiguredSakuhin,
            });
          }
          genka.genkaInfo_array.forEach((kbn) => {
            let new_kingaku; //overwriteKingaku有無による分岐
            // 原稿料以外
            if (genka.genkaKbnId == kbn.genkaKbnId && genka.genkaKbnId !== 3) {
              if (kbn.overwriteKingaku) {
                new_kingaku = kbn.overwriteKingaku;
              } else {
                new_kingaku = kbn.kingaku;
              }
              json_array.daiwariKakuteiArray.push({
                torihikisakiId:
                  genka.torihikisaki.torihikisakiId < 0
                    ? null
                    : genka.torihikisaki.torihikisakiId,
                suuryo: kbn.suuryo,
                tanka: kbn.tanka,
                kingaku: new_kingaku,
                genkaKbnId: genka.genkaKbnId,
                sagyoNaiyo: genka.statusComment,
                startPage: start_page,
                endPage: end_page,
                irokazu: sakuhin.ori_sikisuu,
                sakuhinMei: sakuhin.sakuhin_mei,
                sakuhinTantoUserId: null,
                sakuhinId: sakuhinId,
                pennameId: genka.torihikisaki.pennameId,
                tantoUserIdList: tantouIdList,
                daiwariHyojiMei: sakuhin.sakuhin_mei_display,
                titleHosoku: sakuhin.sakuhin_mei_hosoku,
                genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
                isTankaZero: false,
                pennameArrayConfiguredSakuhin: pennameArrayConfiguredSakuhin,
              });
            }
            // 原稿料
            else if (
              genka.genkaKbnId == kbn.genkaKbnId &&
              genka.genkaKbnId == 3
            ) {
              let isProcessed0C = false;
              kbn.tankaInfo_array.forEach((tanka) => {
                let target_sikusuu_num = Number(
                  sakuhin.ori_sikisuu.replace("C", "")
                );

                // 0Cの場合
                if (target_sikusuu_num === 0 && !isProcessed0C) {
                  json_array.daiwariKakuteiArray.push({
                    torihikisakiId:
                      genka.torihikisaki.torihikisakiId < 0
                        ? null
                        : genka.torihikisaki.torihikisakiId,
                    suuryo: 0,
                    tanka: 0,
                    kingaku: 0,
                    genkaKbnId: genka.genkaKbnId,
                    sagyoNaiyo: genka.statusComment,
                    startPage: start_page,
                    endPage: end_page,
                    irokazu: sakuhin.ori_sikisuu,
                    sakuhinMei: sakuhin.sakuhin_mei,
                    sakuhinTantoUserId: null,
                    sakuhinId: sakuhinId,
                    pennameId: genka.torihikisaki.pennameId,
                    tantoUserIdList: tantouIdList,
                    daiwariHyojiMei: sakuhin.sakuhin_mei_display,
                    titleHosoku: sakuhin.sakuhin_mei_hosoku,
                    genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
                    isTankaZero: true,
                    pennameArrayConfiguredSakuhin:
                      pennameArrayConfiguredSakuhin,
                  });
                  isProcessed0C = true;
                }
                // 1Cの場合
                else if (target_sikusuu_num === 1 && tanka.sikisuu === "1C") {
                  //overwriteKingaku有無による分岐
                  let new_tanka;
                  let new_kingaku;
                  if (tanka.overwriteTanka && tanka.overwriteTanka > 0) {
                    new_tanka = tanka.overwriteTanka;
                  } else {
                    new_tanka = tanka.tanka;
                  }
                  if (tanka.overwriteKingaku && tanka.overwriteKingaku > 0) {
                    new_kingaku = tanka.overwriteKingaku;
                  } else {
                    new_kingaku = tanka.kingaku;
                  }
                  json_array.daiwariKakuteiArray.push({
                    torihikisakiId:
                      genka.torihikisaki.torihikisakiId < 0
                        ? null
                        : genka.torihikisaki.torihikisakiId,
                    suuryo: kbn.suuryo,
                    tanka: new_tanka,
                    kingaku: new_kingaku,
                    genkaKbnId: genka.genkaKbnId,
                    sagyoNaiyo: genka.statusComment,
                    startPage: start_page,
                    endPage: end_page,
                    irokazu: sakuhin.ori_sikisuu,
                    sakuhinMei: sakuhin.sakuhin_mei,
                    sakuhinTantoUserId: null,
                    sakuhinId: sakuhinId,
                    pennameId: genka.torihikisaki.pennameId,
                    tantoUserIdList: tantouIdList,
                    daiwariHyojiMei: sakuhin.sakuhin_mei_display,
                    titleHosoku: sakuhin.sakuhin_mei_hosoku,
                    genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
                    isTankaZero: tanka.tanka === 0 ? true : false,
                    pennameArrayConfiguredSakuhin:
                      pennameArrayConfiguredSakuhin,
                  });
                }
                // 3C以上の場合
                else if (target_sikusuu_num > 3 && tanka.sikisuu === "4C") {
                  //overwriteKingaku有無による分岐
                  let new_tanka;
                  let new_kingaku;
                  if (tanka.overwriteTanka && tanka.overwriteTanka > 0) {
                    new_tanka = tanka.overwriteTanka;
                  } else {
                    new_tanka = tanka.tanka;
                  }
                  if (tanka.overwriteKingaku && tanka.overwriteKingaku > 0) {
                    new_kingaku = tanka.overwriteKingaku;
                  } else {
                    new_kingaku = tanka.kingaku;
                  }
                  json_array.daiwariKakuteiArray.push({
                    torihikisakiId:
                      genka.torihikisaki.torihikisakiId < 0
                        ? null
                        : genka.torihikisaki.torihikisakiId,
                    suuryo: kbn.suuryo,
                    tanka: new_tanka,
                    kingaku: new_kingaku,
                    genkaKbnId: genka.genkaKbnId,
                    sagyoNaiyo: genka.statusComment,
                    startPage: start_page,
                    endPage: end_page,
                    irokazu: sakuhin.ori_sikisuu,
                    sakuhinMei: sakuhin.sakuhin_mei,
                    sakuhinTantoUserId: null,
                    sakuhinId: sakuhinId,
                    pennameId: genka.torihikisaki.pennameId,
                    tantoUserIdList: tantouIdList,
                    daiwariHyojiMei: sakuhin.sakuhin_mei_display,
                    titleHosoku: sakuhin.sakuhin_mei_hosoku,
                    genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
                    isTankaZero: tanka.tanka === 0 ? true : false,
                    pennameArrayConfiguredSakuhin:
                      pennameArrayConfiguredSakuhin,
                  });
                }
                // 2,3Cの場合
                else if (
                  (target_sikusuu_num === 2 || target_sikusuu_num === 3) &&
                  tanka.sikisuu === "その他"
                ) {
                  //overwriteKingaku有無による分岐
                  let new_tanka;
                  let new_kingaku;
                  if (tanka.overwriteTanka && tanka.overwriteTanka > 0) {
                    new_tanka = tanka.overwriteTanka;
                  } else {
                    new_tanka = tanka.tanka;
                  }
                  if (tanka.overwriteKingaku && tanka.overwriteKingaku > 0) {
                    new_kingaku = tanka.overwriteKingaku;
                  } else {
                    new_kingaku = tanka.kingaku;
                  }
                  json_array.daiwariKakuteiArray.push({
                    torihikisakiId:
                      genka.torihikisaki.torihikisakiId < 0
                        ? null
                        : genka.torihikisaki.torihikisakiId,
                    suuryo: kbn.suuryo,
                    tanka: new_tanka,
                    kingaku: new_kingaku,
                    genkaKbnId: genka.genkaKbnId,
                    sagyoNaiyo: genka.statusComment,
                    startPage: start_page,
                    endPage: end_page,
                    irokazu: sakuhin.ori_sikisuu,
                    sakuhinMei: sakuhin.sakuhin_mei,
                    sakuhinTantoUserId: null,
                    sakuhinId: sakuhinId,
                    pennameId: genka.torihikisaki.pennameId,
                    tantoUserIdList: tantouIdList,
                    daiwariHyojiMei: sakuhin.sakuhin_mei_display,
                    titleHosoku: sakuhin.sakuhin_mei_hosoku,
                    genkoryoSettei: sakuhin.genkoryo_settei.setteiKbn,
                    isTankaZero: tanka.tanka === 0 ? true : false,
                    pennameArrayConfiguredSakuhin:
                      pennameArrayConfiguredSakuhin,
                  });
                }
              });
            }
          });
        });
      });
      this.daiwariData.daiwari_genkaArray = this.daiwariData.daiwari_genkaArray
        ? this.daiwariData.daiwari_genkaArray
        : [];
      this.daiwariData.daiwari_genkaArray.forEach((genka) => {
        let new_kingaku; //overwriteKingaku有無による分岐
        if (genka.overwriteKingaku) {
          new_kingaku = genka.overwriteKingaku;
        } else {
          new_kingaku = genka.kingaku;
        }
        json_array.daiwariKakuteiArray.push({
          torihikisakiId:
            genka.torihikisakiId < 0 ? null : genka.torihikisakiId,
          suuryo: genka.suuryo,
          tanka: genka.tanka,
          kingaku: new_kingaku,
          genkaKbnId: genka.genkaKbnId,
          sagyoNaiyo: genka.sagyoNaiyo,
          pennameId: genka.torihikisaki.pennameId,
        });
      });
      // 電子台割のときは原価データを削除する
      if (this.isDensi) json_array.daiwariKakuteiArray.splice(0);

      return json_array;
    },

    //1折のページ上限数を切り替え(8か16か32か)
    switchOriMaximumPage(page) {
      // 折上限増の場合フラグオン
      let isIncrePage = false;
      if (this.oriMaximumPage < page) isIncrePage = true;
      // 上限数クリアの確認
      let confirmFlg = true;
      for (let ori of this.daiwariData.ori_array) {
        if (ori.oriMaximumPage_custom && ori.oriMaximumPage_custom > page) {
          if (!confirm("設定した上限数がクリアされますがよろしいですか？")) {
            confirmFlg = false;
          }
          break;
        }
      }
      // ユーザーがカスタム上限数クリアを承諾したらページ上限数切り替えの処理を行う
      if (confirmFlg) {
        if (this.oriMaximumPage !== page) {
          this.oriMaximumPage = page;
          this.daiwariData.oriMaximumPage = page;
          // カスタムデータの上限が切り替え後の上限数を超えていたらデフォルトの上限数にする
          this.daiwariData.ori_array.forEach((ori) => {
            if (ori.oriMaximumPage_custom && ori.oriMaximumPage_custom > page) {
              if (this.selectedSideBar === 1) {
                delete this.selectedOri.oriMaximumPage_custom;
              }
              delete ori.oriMaximumPage_custom;
            }
          });

          //折サイドバーを選択しているときにページ上限をリアクティブに反映させる
          if (this.selectedSideBar === 1) {
            this.$refs.child.set_oriMaximumPage_custom();
          }

          new Promise((resolve) => {
            util.rebuildDaiwariData(
              this.daiwariData,
              maxAddress,
              maxSameGroupCd
            );
            resolve();
          }).then(() => {
            this.setInitialDuplicatedOriKbn();
            //もし空の折があったら削除する
            let isEmptyOri = this.daiwariData.ori_array.some((ori) => {
              return ori.sakuhin_array.length == 0;
            });
            if (isEmptyOri) {
              this.deleteEmptyOri().then(() => {
                if (isIncrePage) this.shorten_sakuhin_toPrevOri();
              });
            }
            // util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);

            // ページ上限増の場合余白を詰める
            else if (isIncrePage) this.shorten_sakuhin_toPrevOri();
          });
        }
      }
    },
    shorten_sakuhin_toPrevOri() {
      // console.log("shorten_sakuhin_toPrevOri始まり");
      new Promise((resolve) => {
        util.shorten_sakuhin_toPrevOri(
          this.daiwariData,
          maxAddress,
          maxSameGroupCd
        );
        resolve();
      }).then(() => {
        //もし空の折があったら削除する
        let isEmptyOri = this.daiwariData.ori_array.some((ori) => {
          return ori.sakuhin_array.length == 0;
        });
        if (isEmptyOri) {
          const newDaiwariData = this.daiwariData.ori_array.filter(
            (el) => !(el.sakuhin_array.length == 0)
          );
          this.daiwariData.ori_array = [...newDaiwariData];
          new Promise((resolve) => {
            util.rebuildDaiwariData(
              this.daiwariData,
              maxAddress,
              maxSameGroupCd
            );
            resolve();
          }).then(() => {
            this.setInitialDuplicatedOriKbn();
            // console.log("shorten_sakuhin_toPrevOri終わり");
          });
        } else {
          this.setInitialDuplicatedOriKbn();
          // console.log("shorten_sakuhin_toPrevOri終わり");
        }
      });
    },
    reset_oriMaximumPage(page) {
      new Promise((resolve) => {
        this.save_backupDaiwari(
          this.daiwariData,
          this.selectedSakuhin,
          this.selectedOri
        );
        this.selectedOri.oriMaximumPage_custom = page;
        notyf.success(`折のページ数上限を「${page}」に更新しました`);
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(() => {
        // 子コンポーネントのメソッド呼び出し
        this.$refs.child.allowsToChangeSikisuu();
      });
    },

    //備考・進行メモ(折)の設定
    set_oriBikou(oriBikou) {
      this.selectedOri.ori_bikou = oriBikou;
    },
    //備考・進行メモ(作品)の設定
    set_sakuhinBikou(sakuhinBikou) {
      this.selectedSakuhin.sakuhin_bikou = sakuhinBikou;
      this.takeOverSakuhinBikou_toDividedSakuhin(
        this.selectedSakuhin,
        sakuhinBikou
      );
    },
    //備考・進行メモ(作品)の伝達
    takeOverSakuhinBikou_toDividedSakuhin(selectedSakuhin, newVal) {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.sakuhin_bikou = newVal;
          }
        })
      );
    },

    // ---以下、SidebarSakuhin内の処理---

    onClickAddGenka(pushData) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      this.selectedSakuhin.genka_array.push(pushData);
      let genka_length = this.selectedSakuhin.genka_array.length;
      this.selectedSakuhin.genka_array[genka_length - 1].genka_index =
        genka_length - 1;
      //追加後、dividedSakuhinにも追加した情報を伝える。
      if (this.selectedSakuhin.is_divided) {
        this.takeOverGenka_toDividedSakuhin(this.selectedSakuhin);
      }
      this.update_daiwariSakuhinArray();
    },
    onClickDeleteGenka(index) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedSakuhin.genka_array.splice(index, 1);
      this.selectedSakuhin.genka_array.forEach((genka, index) => {
        genka.genka_index = index;
      });
      this.calc_totalKingaku();
      this.takeOverGenka_toDividedSakuhin(this.selectedSakuhin);
    },
    takeOverGenka_toDividedSakuhin(selectedSakuhin) {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.genka_array = selectedSakuhin.genka_array;
          }
        })
      );
    },
    inputTotalPage(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      const isDecrePage =
        this.selectedSakuhin.total_page > newVal ? true : false;
      this.confirmPageRangePosition();
      this.selectedSakuhin.total_page = newVal;
      if (this.selectedSakuhin.genka_array.length > 0) {
        this.selectedSakuhin.genka_array.forEach((genka) => {
          genka.genkaInfo_array[2].suuryo = newVal;
          genka.genkaInfo_array[2].tankaInfo_array.forEach((tanka) => {
            tanka.kingaku = genka.genkaInfo_array[2].suuryo * tanka.tanka;
          });
        });
      }
      //子作品が変更された場合
      if (this.selectedSakuhin.is_parent === false) {
        //親作品に子作品のtotalPage変更を伝える必要がある
        //「sakuhinIDが同じ」かつ「親作品である」作品を更新
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (sakuhin.sakuhin_uniqueId) {
              if (
                sakuhin.sakuhin_uniqueId ===
                  this.selectedSakuhin.sakuhin_uniqueId &&
                sakuhin.is_parent
              ) {
                sakuhin.total_page = newVal;
                // 原稿料の更新
                if (sakuhin.genka_array.length > 0) {
                  sakuhin.genka_array.forEach((genka) => {
                    genka.genkaInfo_array[2].suuryo = newVal;
                    genka.genkaInfo_array[2].tankaInfo_array.forEach(
                      (tanka) => {
                        tanka.kingaku =
                          genka.genkaInfo_array[2].suuryo * tanka.tanka;
                      }
                    );
                  });
                }
                //更新後dividedが解除された場合、親作品をselectedSakuhinとして更新する
                this.$nextTick(() => {
                  if (sakuhin.is_divided === false) {
                    this.selectedSakuhin = sakuhin;
                  }
                });
              }
            } else {
              if (
                sakuhin.sakuhin_id === this.selectedSakuhin.sakuhin_id &&
                sakuhin.is_parent
              ) {
                sakuhin.total_page = newVal;
                // 原稿料の更新
                if (sakuhin.genka_array.length > 0) {
                  sakuhin.genka_array.forEach((genka) => {
                    genka.genkaInfo_array[2].suuryo = newVal;
                    genka.genkaInfo_array[2].tankaInfo_array.forEach(
                      (tanka) => {
                        tanka.kingaku =
                          genka.genkaInfo_array[2].suuryo * tanka.tanka;
                      }
                    );
                  });
                }
                //更新後dividedが解除された場合、親作品をselectedSakuhinとして更新する
                this.$nextTick(() => {
                  if (sakuhin.is_divided === false) {
                    this.selectedSakuhin = sakuhin;
                  }
                });
              }
            }
          });
        });
      }
      this.update_daiwariSakuhinArray();

      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(async () => {
        await this.deleteEmptyOri();
        this.addSakuhinAfterFlg();
        this.setInitialDuplicatedOriKbn();
        if (isDecrePage) this.shorten_sakuhin_toPrevOri();
      });
    },
    inputPage(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      const isDecrePage = this.selectedSakuhin.page > newVal ? true : false;
      this.confirmPageRangePosition();
      this.selectedSakuhin.page = newVal;
      if (this.selectedSakuhin.genka_array.length > 0) {
        this.selectedSakuhin.genka_array.forEach((genka) => {
          genka.genkaInfo_array[2].suuryo = newVal;
          genka.genkaInfo_array[2].tankaInfo_array.forEach((tanka) => {
            tanka.kingaku = genka.genkaInfo_array[2].suuryo * tanka.tanka;
          });
        });
      }

      //this.selectedSakuhin.genka_array[1].suuryo=newVal
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      const newDaiwariData = this.daiwariData.ori_array.filter(
        (el) => !(el.sakuhin_array.length == 0)
      );
      this.daiwariData.ori_array = [...newDaiwariData];
      // divided=falseからdivided=trueの100ページとかにすると以下のrebuildでエラーが発生しaddSakuhinAfterFlg()が機能しない

      new Promise((resolve) => {
        util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
        resolve();
      }).then(async () => {
        await this.deleteEmptyOri();
        this.update_daiwariSakuhinArray();
        this.addSakuhinAfterFlg();
        this.setInitialDuplicatedOriKbn();
        if (isDecrePage) this.shorten_sakuhin_toPrevOri();
      });
    },
    //ページカウント対象外
    togglePageCountFlg() {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedSakuhin.pageCountFlg = !this.selectedSakuhin.pageCountFlg;
      //dividedしている場合変更を同作品に伝える
      if (this.selectedSakuhin.is_divided) {
        this.daiwariData.ori_array.forEach((ori) =>
          ori.sakuhin_array.forEach((sakuhin) => {
            if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
              sakuhin.pageCountFlg = this.selectedSakuhin.pageCountFlg;
            }
          })
        );
      }
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
    },
    toggleHyousiFlg() {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedSakuhin.toggleHyousiFlg =
        !this.selectedSakuhin.toggleHyousiFlg;
      //dividedしている場合変更を同作品に伝える
      if (this.selectedSakuhin.is_divided) {
        this.daiwariData.ori_array.forEach((ori) =>
          ori.sakuhin_array.forEach((sakuhin) => {
            if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
              sakuhin.toggleHyousiFlg = this.selectedSakuhin.toggleHyousiFlg;
            }
          })
        );
      }
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
    },
    isHyosiOri(ori) {
      //雑誌以外の作品にて折区分「表紙」の折は表紙フラグをオンにする
      //(自動設定機能実装前のデータに対して表紙の自動設定を反映させるためここにも処理を記載)
      if (!this.daiwariData.outputSyosiData.isZassi && ori.ori_kbn === "表紙") {
        return true;
      } else if (
        !this.daiwariData.outputSyosiData.isZassi &&
        ori.ori_kbn.includes("表")
      ) {
        //折区分「表n」の折は表紙フラグをオンにする
        const slicedValue = ori.ori_kbn.slice(1, 2);
        if (util.isNumber(slicedValue)) {
          return true;
        }
      } else {
        return false;
      }
    },
    enableHyosiFlg(oriIndex) {
      this.daiwariData.ori_array[oriIndex].sakuhin_array.forEach((sakuhin) => {
        sakuhin.toggleHyousiFlg = true;
      });
    },
    /*作品名 */
    changeDaiwariToSakuhin(props_sakuhin) {
      // this.modalName = "modal_sakuhin";
      this.modalOnModalName = "modal_sakuhinMei";
      this.props_sakuhin = props_sakuhin;
    },
    changeSakuhinToDaiwari() {
      this.modalOnModalName = "";
    },
    // 仮作品がモーダルから追加されたら作品リストを更新する
    async updateSakuhinList(emitUpdateSakuhinMei, setKarisakuhinId) {
      this.sakuhinMeiList.splice(0);
      this.sakuhinList.splice(0);
      const res = await axios.get(`${this.url}/get_sakuhin_mei?sakuhin_mei`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      res.data.forEach((sakuhin) => {
        this.sakuhinMeiList.push(sakuhin.sakuhinMei);
        this.sakuhinList.push({
          sakuhin_mei: sakuhin.sakuhinMei,
          sakuhin_id: sakuhin.sakuhinId,
        });
      });
      setKarisakuhinId(res.data[0].sakuhinId);
      emitUpdateSakuhinMei();
    },
    async updateSakuhinMei_fromSide(newVal_sakuhinmei, newVal_sakuhinId) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      // 作品に紐づくペンネームの自動セット処理
      if (newVal_sakuhinId !== this.selectedSakuhin.sakuhin_id) {
        // 作品に紐づくペンネームを全て削除
        const deletePenname = [];
        this.selectedSakuhin.penName.forEach((pen, penIndex) => {
          if ("isRelatedSakuhin" in pen) {
            deletePenname.push({
              pennameInfo: pen,
              index: penIndex,
            });
          }
        });
        deletePenname.reverse().forEach((deletePen) => {
          this.removePenName(deletePen.pennameInfo, deletePen.index);
        });
        // 作品に紐づくペンネームを取得し、自動セットする
        if (newVal_sakuhinId !== -1 && newVal_sakuhinId !== null) {
          this.isProcessing = true;
          const res = await axios.get(
            `${this.url}/get_penname_linked_sakuhin?sakuhin_id=${newVal_sakuhinId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          let initialPenname = this.selectedSakuhin.penName.map((obj) =>
            Object.assign({}, obj)
          );
          let searchedPenname = [];
          if (res.data.penameList !== null) {
            res.data.penameList.forEach((penname) => {
              // 作品に紐づくペンネームがすでに設定されている場合削除する
              initialPenname = initialPenname.filter((initialPen) => {
                return initialPen.pennameId != penname.pennameId;
              });
              searchedPenname.push({
                penName: penname.penname,
                pennameId: penname.pennameId,
                torihikisakiId: penname.torihikisakiId,
                isRelatedSakuhin: true,
              });
            });
          }
          // 作品に紐づくペンネームを先に表示したいため手動で設定されていたペンネームは後にまわす
          initialPenname.forEach((initialPen) => {
            searchedPenname.push(initialPen);
          });
          await this.updatePenName(searchedPenname, false);
          this.isProcessing = false;
        }
      }

      //作品名・作品表示名の変更処理
      this.selectedSakuhin.sakuhin_mei = newVal_sakuhinmei;
      convertSakuhinMei(this.selectedSakuhin);

      //折り返しが生じた場合の処理
      //same_sakuhin_info_arrayのlengthがなぜか0で分断されてしまう
      if (!this.selectedSakuhin.same_sakuhin_info_array.length == 0) {
        this.selectedSakuhin.same_sakuhin_info_array.forEach(
          (sameSakuhinInfo) => {
            this.daiwariData.ori_array[sameSakuhinInfo.oriIndex].sakuhin_array[
              sameSakuhinInfo.sakuhinIndex
            ].sakuhin_mei = newVal_sakuhinmei;
          }
        );
      }
      if (this.selectedSakuhin.is_divided) {
        this.updateSakuhinId(
          this.selectedSakuhin,
          newVal_sakuhinmei,
          newVal_sakuhinId
        );
        this.takeOverSakuhinMei_toDividedSakuhin(
          this.selectedSakuhin,
          newVal_sakuhinmei
        );
      } else {
        this.updateSakuhinId(
          this.selectedSakuhin,
          newVal_sakuhinmei,
          newVal_sakuhinId
        );
      }
      //ページ備考の作品名を更新
      this.daiwariData.sakuhin_page_bikou_array.forEach((bikou) => {
        if (bikou.sakuhin_bikouId === this.selectedSakuhin.sakuhin_bikouId) {
          if (newVal_sakuhinmei === "") {
            bikou.sakuhin_mei = this.selectedSakuhin.sakuhin_mei_hosoku;
          } else {
            bikou.sakuhin_mei = this.selectedSakuhin.sakuhin_mei;
          }
        }
      });
      this.checkDuplicatedSakuhin();
    },
    //原価台帳一覧から変更された場合（原価台帳から作品名の更新は不可になったので不要？コード内容未更新）
    updateSakuhinMei_fromModal(updateSakuhin, newVal_sakuhinmei) {
      //変更前のidを一旦変数に代入して保持しておく(検索用に)
      let before_sakuhinId_fromModal = null;
      let uniqueId = util.getUniqueStr();
      if (updateSakuhin.sakuhin_uniqueId !== 0) {
        before_sakuhinId_fromModal = updateSakuhin.sakuhin_uniqueId;
      } else {
        before_sakuhinId_fromModal = updateSakuhin.sakuhin_id;
      }
      //まずdaiwariData内のどの作品を更新すればいいのか検索する
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (
            sakuhin.sakuhin_uniqueId == before_sakuhinId_fromModal ||
            sakuhin.sakuhin_id == before_sakuhinId_fromModal
          ) {
            sakuhin.sakuhin_mei = newVal_sakuhinmei;
            convertSakuhinMei(sakuhin);
            //変更後の作品名を作品リストから検索し、idを取得する
            this.sakuhinList.forEach((item) => {
              if (item.sakuhin_mei == newVal_sakuhinmei) {
                sakuhin.sakuhin_id = item.sakuhin_id;
                sakuhin.sakuhin_uniqueId = 0;
              }
            });
            if (this.sakuhinMeiList.includes(newVal_sakuhinmei) == false) {
              sakuhin.sakuhin_id = -1;
              sakuhin.sakuhin_uniqueId = uniqueId;
            }
          }
        });
      });
      //ページ備考の作品名を更新
      this.daiwariData.sakuhin_page_bikou_array.forEach((bikou) => {
        if (bikou.sakuhin_bikouId === updateSakuhin.sakuhin_bikouId) {
          bikou.sakuhin_mei = updateSakuhin.sakuhin_mei;
        }
      });
    },
    //ページ補足入力時発火
    input_sakuhin_mei_hosoku(newVal) {
      // console.log("selectedSakuhin" + selectedSakuhin);
      this.selectedSakuhin.sakuhin_mei_hosoku = newVal;
      //ページ備考の作品名を更新（作品マスタに紐づかない場合のみ）
      //-1 = 作品名設定なし
      this.daiwariData.sakuhin_page_bikou_array.forEach((bikou) => {
        if (
          this.selectedSakuhin.sakuhin_id === -1 &&
          //総ページ数IDと選択したページ数ID
          bikou.sakuhin_bikouId === this.selectedSakuhin.sakuhin_bikouId
        ) {
          //各ページ備考モーダルの作品名表示を選択した作品名に初期化
          bikou.sakuhin_mei = this.selectedSakuhin.sakuhin_mei_hosoku;
        }
      });
      //タイトル補足の引継ぎメソッド
      this.takeOverSakuhinMeiHosoku_toDividedSakuhin(this.selectedSakuhin);
    },

    //エピソード更新メソッド
    //子ｺﾝﾎﾟｰﾈﾝﾄのSidebarSakuhin.vueで this.$emit("input_episode", e.target.value);から
    //呼び出されたinput_episodeイベントが発火
    //第２引数のe.target.value（inputの入力値）が引数(newVal)にセットされる
    input_episode(newVal) {
      //この親ｺﾝﾎﾟｰﾈﾝﾄ内のselectedSakuhin.episodeに
      //第２引数を受け取ったnewValに置き換える
      this.selectedSakuhin.episode = newVal;
      //話数の引継ぎメソッド
      this.takeOverEpisode_toDividedSakuhin(this.selectedSakuhin);
    },

    checkDuplicatedSakuhin() {
      let duplicatedCount = 0;
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (sakuhin.sakuhin_id !== -1) {
            //sakuhinIdで重複チェック
            //チェック先がdividedしている場合
            if (
              sakuhin.is_divided &&
              sakuhin.sakuhin_id === this.selectedSakuhin.sakuhin_id &&
              sakuhin.is_parent
            ) {
              duplicatedCount++;
            }
            //チェック先がdividedしていない場合
            else if (sakuhin.is_divided == false) {
              if (sakuhin.sakuhin_id === this.selectedSakuhin.sakuhin_id) {
                duplicatedCount++;
              }
            }
          }
        });
      });
      if (duplicatedCount > 1) {
        //末尾に追加する「*」の数を計算する
        //作品マスタにある作品が台割内で重複した場合.差別化するためにsakuhinIdを更新
        // this.daiwariData.ori_array.forEach((ori) => {
        //   ori.sakuhin_array.forEach((sakuhin) => {
        //     console.log(sakuhin.is_selected_sakuhin);
        //     if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
        //       //まだ「*」付きidがなければ、新たに末尾に「*」を加える
        //       if (String(sakuhin.sakuhin_id).indexOf("*") != 1) {
        //         this.selectedSakuhin.sakuhin_id =
        //           this.selectedSakuhin.sakuhin_id + "*";
        //       }
        //     }
        //   });
        // });

        // 改修後コード
        let isMatching = false;
        do {
          isMatching = false;
          for (let ori of this.daiwariData.ori_array) {
            for (let sakuhin of ori.sakuhin_array) {
              if (sakuhin.is_selected_sakuhin == false) {
                if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
                  this.selectedSakuhin.sakuhin_id = sakuhin.sakuhin_id + "*";
                  isMatching = true;
                  break;
                }
              }
            }
            if (isMatching == true) {
              break;
            }
          }
        } while (isMatching == true);

        //divideしている場合子作品にもidを受け渡したい
        if (this.selectedSakuhin.is_divided) {
          this.daiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              if (
                sakuhin.orderOfTheSakuhin ==
                this.selectedSakuhin.orderOfTheSakuhin
              ) {
                sakuhin.sakuhin_id = this.selectedSakuhin.sakuhin_id;
              }
            });
          });
        }
        notyf.error("作品名が重複しています");
      }
    },
    //タイトル補足の引継ぎ
    takeOverSakuhinMeiHosoku_toDividedSakuhin(selectedSakuhin) {
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          //選択した作品の通し番号と台割画面に表示されている作品の通し番号
          if (selectedSakuhin.orderOfTheSakuhin == sakuhin.orderOfTheSakuhin) {
            //sakuhin_array内のタイトル補足を選択し更新されたタイトル補足に初期化
            sakuhin.sakuhin_mei_hosoku = selectedSakuhin.sakuhin_mei_hosoku;
            //台割表示名を整形し更新
            convertSakuhinMei(sakuhin);
          }
        });
      });
    },
    takeOverSakuhinMei_toDividedSakuhin(selectedSakuhin) {
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (selectedSakuhin.orderOfTheSakuhin == sakuhin.orderOfTheSakuhin) {
            sakuhin.sakuhin_mei = selectedSakuhin.sakuhin_mei;
            sakuhin.sakuhin_mei_display = selectedSakuhin.sakuhin_mei_display;
            if (selectedSakuhin.sakuhin_uniqueId !== 0) {
              sakuhin.sakuhin_uniqueId = selectedSakuhin.sakuhin_uniqueId;
              sakuhin.sakuhin_id = -1;
            } else if (selectedSakuhin.sakuhin_id !== -1) {
              sakuhin.sakuhin_id = selectedSakuhin.sakuhin_id;
              sakuhin.sakuhin_uniqueId = 0;
            }
          }
        });
      });
    },
    //溢れの話数を更新する
    takeOverEpisode_toDividedSakuhin(selectedSakuhin) {
      //ori_arrayの中身oriにループ
      this.daiwariData.ori_array.forEach((ori) => {
        //oriの中のsakuhin_arrayをsakuhinにループ
        ori.sakuhin_array.forEach((sakuhin) => {
          //選択した作品の通し番号（ordesrOfTheSakuhin）とoriにある作品の通し番号が同じなら
          if (selectedSakuhin.orderOfTheSakuhin == sakuhin.orderOfTheSakuhin) {
            //sakuhin内episodeをselectedSakuhin.episodeに置き換える
            sakuhin.episode = selectedSakuhin.episode;
          }
        });
      });
    },
    updateSakuhinId(selectSakuhin, newVal, newVal_sakuhinId) {
      // //sakuhinListに登録のない作品であればuniqueIdを付与
      // if (this.sakuhinMeiList.includes(newVal) == false) {
      //   selectSakuhin.sakuhin_id = -1;
      //   //作品マスタ無しで、uniqueId未設定の時のみuniqueIdを更新する
      //   if (selectSakuhin.sakuhin_uniqueId === 0) {
      //     selectSakuhin.sakuhin_uniqueId = util.getUniqueStr();
      //   }
      // }
      // //sakuhinListに登録済みの作品であればそのIdを付与
      // this.sakuhinList.forEach((sakuhin) => {
      //   if (
      //     "sakuhin_mei_suggest" in selectSakuhin &&
      //     selectSakuhin.sakuhin_mei_suggest === sakuhin.sakuhin_mei
      //   ) {
      //     selectSakuhin.sakuhin_id = sakuhin.sakuhin_id;
      //     selectSakuhin.sakuhin_uniqueId = 0;
      //   } else if (sakuhin.sakuhin_mei == newVal) {
      //     selectSakuhin.sakuhin_id = sakuhin.sakuhin_id;
      //     selectSakuhin.sakuhin_uniqueId = 0;
      //   }
      // });

      // 2023/9/26 作品IDのセットはマスタに準拠するよう変更
      if (newVal_sakuhinId === null) {
        selectSakuhin.sakuhin_id = -1;
        if (selectSakuhin.sakuhin_uniqueId === 0) {
          selectSakuhin.sakuhin_uniqueId = util.getUniqueStr();
        }
      } else {
        selectSakuhin.sakuhin_id = newVal_sakuhinId;
        selectSakuhin.sakuhin_uniqueId = 0;
      }

      //ページ備考のsakuhin_idを更新
      this.daiwariData.sakuhin_page_bikou_array.forEach((bikou) => {
        if (bikou.sakuhin_bikouId === this.selectedSakuhin.sakuhin_bikouId) {
          bikou.sakuhin_id = selectSakuhin.sakuhin_id;
        }
      });
    },
    inputGenkaKbn(genkaIndex, newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      let update_genkaMeisyo = "";
      let selectSakuhinInfo = this.selectedSakuhin;
      let selectGenkaInfo = this.selectedSakuhin.genka_array[genkaIndex];
      selectGenkaInfo.genkaKbnId = Number(newVal);

      this.genkaKbnList.forEach((genka) => {
        if (genka.genkaKbnId == newVal) {
          update_genkaMeisyo = genka.meisyo;
          selectGenkaInfo.meisyo = update_genkaMeisyo;
          if (selectGenkaInfo.meisyo === "原稿料") {
            selectGenkaInfo.genkaInfo_array.forEach((kbn) => {
              if (kbn.meisyo === selectGenkaInfo.meisyo) {
                kbn.suuryo = selectSakuhinInfo.total_page;
              }
            });
          }
          this.calc_totalKingaku();
        }
      });
      if (this.selectedSakuhin.is_divided) {
        this.daiwariData.ori_array.forEach((ori) =>
          ori.sakuhin_array.forEach((sakuhin) => {
            if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
              sakuhin.genka_array[genkaIndex].genkaKbnId = Number(newVal);
              sakuhin.genka_array[genkaIndex].meisyo = update_genkaMeisyo;
            }
          })
        );
      }
      util.rebuildDaiwariData(this.daiwariData, maxAddress, maxSameGroupCd);
      this.update_daiwariSakuhinArray();
    },
    inputSinkouStatus(newVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );

      this.selectedSakuhin.sinkou_status = newVal;
      //折り返しが生じた場合の処理
      if (!this.selectedSakuhin.same_sakuhin_info_array.length == 0) {
        this.selectedSakuhin.same_sakuhin_info_array.forEach(
          (sameSakuhinInfo) => {
            this.daiwariData.ori_array[sameSakuhinInfo.oriIndex].sakuhin_array[
              sameSakuhinInfo.sakuhinIndex
            ].sinkou_status = newVal;
          }
        );
      }
      if (this.selectedSakuhin.is_divided) {
        this.takeOverSinkouStatus_toDividedSakuhin(
          this.selectedSakuhin,
          newVal
        );
      }
    },
    takeOverSinkouStatus_toDividedSakuhin(selectedSakuhin, newVal) {
      this.daiwariData.ori_array.forEach((ori) =>
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.sinkou_status = newVal;
          }
        })
      );
    },
    // 原稿料設定の変更
    async changeGenkoryoSettei(genkoryoSettei) {
      this.isProcessing = true;
      this.selectedSakuhin.genkoryo_settei.setteiKbn = genkoryoSettei.setteiKbn;
      this.selectedSakuhin.genkoryo_settei.meisyo = genkoryoSettei.meisyo;
      this.takeOverGankoryoSettei_toDividedSakuhin(
        this.selectedSakuhin,
        genkoryoSettei
      );
      // 作品に設定されたペンネーム配列
      const penNameArray = this.selectedSakuhin.penName;
      // 作品の原価配列
      const genkaArray = this.selectedSakuhin.genka_array;
      // 作品に設定された色数の数字
      const target_sikisuu_num = Number(
        this.selectedSakuhin.ori_sikisuu.replace("C", "")
      );
      //   「2.原稿料なし(雑誌・アンソロ以外)」で削除する原価のインデックスを配列に格納し、最後にまとめて削除
      //  （途中で削除するとインデックスが狂うため）
      let deleteGenkaIndexArray = [];

      for (const penname of penNameArray) {
        let hasGenkoryoData = false;
        for (const [genkaIndex, genka] of genkaArray.entries()) {
          if (
            penname.torihikisakiId === genka.torihikisaki.torihikisakiId &&
            penname.pennameId === genka.torihikisaki.pennameId &&
            genka.genkaKbnId === 3 &&
            genka.isAutoAppend === true
          ) {
            hasGenkoryoData = true;
            // 一旦原稿料のtankaInfoを初期状態に戻す
            let targetGenkaInfo = genka.genkaInfo_array.find(
              (genkaInfo) => genkaInfo.genkaKbnId === 3
            );
            targetGenkaInfo.tankaInfo_array.splice(0);
            targetGenkaInfo.tankaInfo_array.push(
              {
                sikisuu: "1C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "4C",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              },
              {
                sikisuu: "その他",
                tanka: 0,
                kingaku: 0,
                overwriteTankaFlg: false,
                defaultTanka: 0,
              }
            );
            // 数量
            const targetSuuryo = genka.genkaInfo_array.find(
              (genkaInfo) => genkaInfo.genkaKbnId === 3
            ).suuryo;

            // 折の色数が1Cの場合
            if (target_sikisuu_num === 1) {
              // 対象デフォルト原価
              const targetDefaultTanka = genka.torihikisaki.tankaList.find(
                (torihikiTanka) => torihikiTanka.tankaKbnCd === "1"
              );
              // 編集対象のtankaInfo（原稿料）
              let targetChangeTankaInfo = genka.genkaInfo_array
                .find((genkaInfo) => genkaInfo.genkaKbnId === 3)
                .tankaInfo_array.find(
                  (tankaInfo) => tankaInfo.sikisuu === "1C"
                );
              // 「1.原稿料あり」
              // 作品に設定されたペンネームに紐づく原稿料があれば色数に基づく単価を設定する
              // 紐づく原稿料がなければ新たに原稿料データを作成する
              if (genkoryoSettei.setteiKbn === 1) {
                // 単価の代入
                targetChangeTankaInfo.defaultTanka =
                  targetDefaultTanka.tankaKingaku;
                targetChangeTankaInfo.tanka =
                  targetChangeTankaInfo.defaultTanka;
                // 金額の計算
                targetChangeTankaInfo.kingaku =
                  targetChangeTankaInfo.tanka * targetSuuryo;
                // 合計金額の計算
                this.calc_totalKingaku();
              }
              // 「2.原稿料なし(雑誌・アンソロジー)」「3.原稿流用」「4.前渡原稿料」
              // デフォルト単価のみ設定し、単価は0、overwriteTankaはfalseにする
              else if (
                (genkoryoSettei.setteiKbn === 2 &&
                  (this.daiwariData.outputSyosiData.isZassi ||
                    this.daiwariData.outputSyosiData.isAnthology)) ||
                genkoryoSettei.setteiKbn === 3 ||
                genkoryoSettei.setteiKbn === 4
              ) {
                // 単価の代入
                targetChangeTankaInfo.defaultTanka =
                  targetDefaultTanka.tankaKingaku;
                // 合計金額の計算
                this.calc_totalKingaku();
              }
              // 「2.原稿料なし(雑誌・アンソロジー以外)」は原稿料を削除する
              else if (
                genkoryoSettei.setteiKbn === 2 &&
                !(
                  this.daiwariData.outputSyosiData.isZassi ||
                  this.daiwariData.outputSyosiData.isAnthology
                )
              ) {
                deleteGenkaIndexArray.push(genkaIndex);
              }
            }
            // 折の色数が4C以上の場合
            if (target_sikisuu_num > 3) {
              // 対象デフォルト原価
              const targetDefaultTanka = genka.torihikisaki.tankaList.find(
                (torihikiTanka) => torihikiTanka.tankaKbnCd === "2"
              );
              // 編集対象のtankaInfo（原稿料）
              let targetChangeTankaInfo = genka.genkaInfo_array
                .find((genkaInfo) => genkaInfo.genkaKbnId === 3)
                .tankaInfo_array.find(
                  (tankaInfo) => tankaInfo.sikisuu === "4C"
                );
              // 「1.原稿料あり」
              // 作品に設定されたペンネームに紐づく原稿料があれば色数に基づく単価を設定する
              // 紐づく原稿料がなければ新たに原稿料データを作成する
              if (genkoryoSettei.setteiKbn === 1) {
                // 単価の代入
                targetChangeTankaInfo.defaultTanka =
                  targetDefaultTanka.tankaKingaku;
                targetChangeTankaInfo.tanka =
                  targetChangeTankaInfo.defaultTanka;
                // 金額の計算
                targetChangeTankaInfo.kingaku =
                  targetChangeTankaInfo.tanka * targetSuuryo;
                // 合計金額の計算
                this.calc_totalKingaku();
              }
              // 「2.原稿料なし(雑誌・アンソロジー)」「3.原稿流用」「4.前渡原稿料」
              // デフォルト単価のみ設定し、単価は0、overwriteTankaはfalseにする
              else if (
                (genkoryoSettei.setteiKbn === 2 &&
                  (this.daiwariData.outputSyosiData.isZassi ||
                    this.daiwariData.outputSyosiData.isAnthology)) ||
                genkoryoSettei.setteiKbn === 3 ||
                genkoryoSettei.setteiKbn === 4
              ) {
                // 単価の代入
                targetChangeTankaInfo.defaultTanka =
                  targetDefaultTanka.tankaKingaku;
                // 合計金額の計算
                this.calc_totalKingaku();
              }
              // 「2.原稿料なし(雑誌・アンソロジー以外)」は原稿料を削除する
              else if (
                genkoryoSettei.setteiKbn === 2 &&
                !(
                  this.daiwariData.outputSyosiData.isZassi ||
                  this.daiwariData.outputSyosiData.isAnthology
                )
              ) {
                deleteGenkaIndexArray.push(genkaIndex);
              }
            }
            // 折の色数が0,2,3Cの場合
            else {
              // 単価関連の設定は不要
              // 「2.原稿料なし(雑誌・アンソロジー以外)」は原稿料を削除する
              if (
                genkoryoSettei.setteiKbn === 2 &&
                !(
                  this.daiwariData.outputSyosiData.isZassi ||
                  this.daiwariData.outputSyosiData.isAnthology
                )
              ) {
                deleteGenkaIndexArray.push(genkaIndex);
              }
            }
            // divide作品にも適用
            this.updateGenkaInfo(this.selectedSakuhin, genkaIndex);
          }
        }
        // ペンネームに一致する原稿料が存在しなかった場合のフォロー
        // 原稿料設定が1,3,4で原稿料がなかったときここで原稿料データを作成する
        if (
          !hasGenkoryoData &&
          !(
            genkoryoSettei.setteiKbn === 2 &&
            !(
              this.daiwariData.outputSyosiData.isZassi ||
              this.daiwariData.outputSyosiData.isAnthology
            )
          )
        ) {
          const torihikisakiData = await this.searchTorihikisaki(penname);
          await this.onClickAddGenka({
            meisyo: "未設定",
            genkaKbnId: 0,
            torihikisaki: "",
            statusComment: "",
            isAutoAppend: true,
            sakuhin_index: this.selectedSakuhin.sakuhin_address_computed - 1,
            genkaInfo_array: [
              {
                genkaKbnId: 1,
                meisyo: "外注加工費",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              },
              {
                genkaKbnId: 2,
                meisyo: "企画編集費",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              },
              {
                genkaKbnId: 3,
                meisyo: "原稿料",
                suuryo: 0,
                tankaInfo_array: [
                  {
                    sikisuu: "1C",
                    tanka: 0,
                    kingaku: 0,
                    overwriteTankaFlg: false,
                    defaultTanka: 0,
                  },
                  {
                    sikisuu: "4C",
                    tanka: 0,
                    kingaku: 0,
                    overwriteTankaFlg: false,
                    defaultTanka: 0,
                  },
                  {
                    sikisuu: "その他",
                    tanka: 0,
                    kingaku: 0,
                    overwriteTankaFlg: false,
                    defaultTanka: 0,
                  },
                ],
              },
              {
                genkaKbnId: 4,
                meisyo: "印税・版権料",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              },
              {
                genkaKbnId: 5,
                meisyo: "デザイン料",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              },
              {
                genkaKbnId: 999999999,
                meisyo: "科目不明",
                suuryo: 0,
                tanka: 0,
                kingaku: 0,
              },
            ],
          });
          this.torihikisakiIndex = this.selectedSakuhin.genka_array.length - 1;
          await this.inputGenkaKbn(
            this.selectedSakuhin.genka_array.length - 1,
            3
          );
          await this.updateTorihikisaki(torihikisakiData, true);
          this.updateGenkaInfo(
            this.selectedSakuhin,
            this.selectedSakuhin.genka_array.length - 1
          );
        }
      }
      // 削除対象原価をまとめて削除する
      if (deleteGenkaIndexArray.length > 0) {
        this.daiwariData.ori_array.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            if (util.is_matched_sakuhinId(this.selectedSakuhin, sakuhin)) {
              sakuhin.genka_array = sakuhin.genka_array.filter(
                (genka, genkaIndex) =>
                  !deleteGenkaIndexArray.includes(genkaIndex)
              );
            }
          });
        });
        // 合計金額の計算
        this.calc_totalKingaku();
      }
      this.isProcessing = false;
    },
    // 原稿料設定変更をdivideにも適用
    takeOverGankoryoSettei_toDividedSakuhin(selectedSakuhin, genkoryoSettei) {
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((sakuhin) => {
          if (util.is_matched_sakuhinId(selectedSakuhin, sakuhin)) {
            sakuhin.genkoryo_settei.setteiKbn = genkoryoSettei.setteiKbn;
            sakuhin.genkoryo_settei.meisyo = genkoryoSettei.meisyo;
          }
        });
      });
    },

    //台割未保存でリロード・ブラウザバック実行時に保存確認アラートを表示する
    confirmSave() {
      if (this.isLockDaiwari) {
        return false;
      }
      // ブラウザを閉じたあとにも非同期通信を適用したいのでkeep-aliveで台割ロック解除を行う
      const params = new URL(location.href).searchParams;
      if (
        params.get("hachuId") === null &&
        (params.get("isYomitori") === null ||
          params.get("isYomitori") === "false")
      ) {
        const postData = {
          daiwariId: this.daiwariId,
        };
        //isLock=falseの場合ロック解除
        if (!this.daiwariHeader.isLock) {
          try {
            fetch(
              `${this.url}/daiwari/unlock_daiwari?daiwariId=${this.daiwariId}`,
              {
                keepalive: true,
                method: "POST",
                headers: {
                  // 'content-type': 'application/json',
                  // any header you can pass here
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
                body: JSON.stringify(postData),
              }
            ).then((res) => {
              console.log(res);
              console.log("台割ロックを解除しました");
            });
          } catch (error) {
            console.log("台割ロックの解除に失敗しました");
            console.log(error);
          }
        }
      }

      let daiwariData = JSON.parse(JSON.stringify(this.daiwariData));
      let daiwariDataDB = JSON.parse(JSON.stringify(this.daiwariData_sameAsDB));

      new Promise((resolve) => {
        // 台割比較時に比較不要なプロパティを削除する
        daiwariData.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
          });
        });
        daiwariDataDB.ori_array.forEach((ori) => {
          delete ori.is_selected_ori;
          ori.sakuhin_array.forEach((sakuhin) => {
            delete sakuhin.is_selected_sakuhin;
            delete sakuhin.addSakuhinAfterFlg;
          });
        });
        resolve();
      }).then(() => {
        const JSON_daiwariData = JSON.stringify(daiwariData);
        const JSON_daiwariData_sameAsDB = JSON.stringify(daiwariDataDB);
        if (JSON_daiwariData !== JSON_daiwariData_sameAsDB) {
          event.returnValue =
            "編集中のものは保存されませんが、よろしいですか？";
        } else {
          localStorage.removeItem(`daiwari_${this.daiwariId}`);
        }
      });
    },
    //backspaceキーによるブラウザバックの無効化
    onKeyDown_backSpace(event) {
      let obj = event.target;
      let key = event.key;
      if (key === "Backspace") {
        if (
          (obj.tagName == "INPUT" && obj.type == "text") ||
          (obj.tagName == "INPUT" && obj.type == "number") ||
          obj.tagName == "TEXTAREA"
        ) {
          //入力項目内では使用できるようにする
          if (!obj.readOnly && !obj.disabled) {
            return true;
          }
        } else {
          event.preventDefault();
        }
      }
    },
    //折備考の表示非表示
    toggleOriBikouFlg(ori, flg) {
      ori.ori_bikou_active_flg = flg;
    },
    //作品備考の表示非表示
    toggleSakuhinBikouFlg(sakuhin, showBikou) {
      sakuhin.sakuhin_bikou_flg = showBikou;
    },
    toggleSakuhinBikouMarkFlg() {
      this.selectedSakuhin.sakuhin_bikou_markflg =
        !this.selectedSakuhin.sakuhin_bikou_markflg;
      this.daiwariData.ori_array.forEach((ori) => {
        ori.sakuhin_array.forEach((saku) => {
          if (util.is_matched_sakuhinId(this.selectedSakuhin, saku)) {
            saku.sakuhin_bikou_markflg =
              this.selectedSakuhin.sakuhin_bikou_markflg;
          }
        });
      });
    },
    //ページ備考エリアの表示非表示
    onClickOpenPageBikou(clickedSakuhin, bikouIndex, bikouAddress) {
      //bikouAddress:対象のページ備考のアドレスを表示のために使用
      //bikouindex:対象のページ備考情報を特定するために使用
      // this.isActivePageBikou = true;
      if (this.overlayName == "overlay_pageBikou") {
        this.overlayName = "";
      }
      this.referenceBikouAddress = bikouAddress;
      this.referenceBikouIndex = bikouIndex;
      let pageBikouIndex = bikouIndex - 1;

      //divide中の子作品の場合は前の頁数も考慮して備考ページを参照する
      if (clickedSakuhin.is_divided && !clickedSakuhin.is_parent) {
        pageBikouIndex = pageBikou.return_total_preBikouIndex(
          this.daiwariData.ori_array,
          clickedSakuhin,
          bikouIndex
        );
        this.referenceBikouIndex = pageBikouIndex + 1;
      }

      //参照対象の備考を検索しreferenceBikouに登録(Sakuhin.vueとPagebikou.vueに送る用)
      this.daiwariData.sakuhin_page_bikou_array.forEach((sakuhin) => {
        if (clickedSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId) {
          this.referenceBikou = sakuhin.bikou_array[pageBikouIndex].bikou;
        }
      });
      setTimeout(() => {
        this.overlayName = "overlay_pageBikou";
      }, 100);
    },
    //作品追加時のページ備考の追加
    addBikou_toPageBikouArray(addSakuhin) {
      const pushData = new pageBikou.PageBikou(addSakuhin);
      this.daiwariData.sakuhin_page_bikou_array.push(pushData);
    },
    //作品削除時のページ備考の削除
    deleteBikou_fromPageBikouArray() {
      const deleteIndex = this.daiwariData.sakuhin_page_bikou_array.findIndex(
        (sakuhin) =>
          sakuhin.sakuhin_bikouId === this.selectedSakuhin.sakuhin_bikouId
      );
      this.daiwariData.sakuhin_page_bikou_array.splice(deleteIndex, 1);
    },
    onClickClosePageBikou(event) {
      if (
        !event.target.className.includes("sakuhin_address") &&
        !event.target.className.includes("sakuhin_address_clickArea")
      ) {
        // this.isActivePageBikou = false;
        this.overlayName = "";
      }
    },
    //ページ備考データの更新
    updatePageBikou(newPageBikouVal) {
      this.save_backupDaiwari(
        this.daiwariData,
        this.selectedSakuhin,
        this.selectedOri
      );
      let new_sakuhin_page_bikou_array = [
        ...this.daiwariData.sakuhin_page_bikou_array,
      ];
      new_sakuhin_page_bikou_array.forEach((sakuhin) => {
        if (this.selectedSakuhin.sakuhin_bikouId === sakuhin.sakuhin_bikouId) {
          //新しい備考内容をsakuhin_page_bikou_arrayに反映
          // sakuhin.bikou_array[this.referenceBikouIndex - 1].bikou = newPageBikouVal;
          sakuhin.bikou_array[this.referenceBikouIndex - 1].bikou =
            newPageBikouVal;
          //入力内容があればハイライト
          if (newPageBikouVal === "") {
            sakuhin.bikou_array[
              this.referenceBikouIndex - 1
            ].isHighlighted = false;
          } else {
            sakuhin.bikou_array[
              this.referenceBikouIndex - 1
            ].isHighlighted = true;
            sakuhin.bikou_array[this.referenceBikouIndex - 1].bikou =
              newPageBikouVal;
            //入力内容があればハイライト
            if (newPageBikouVal === "") {
              sakuhin.bikou_array[
                this.referenceBikouIndex - 1
              ].isHighlighted = false;
            } else {
              sakuhin.bikou_array[
                this.referenceBikouIndex - 1
              ].isHighlighted = true;
            }
          }
        }
      });
      this.daiwariData.sakuhin_page_bikou_array = new_sakuhin_page_bikou_array;
    },
    fetchParentDaiwariData() {
      const baseUrl = util.getUrl();
      const params = new URL(location.href).searchParams;
      axios
        .get(
          `${baseUrl}/daiwari/?daiwariId=${
            this.parentDaiwariId
          }&kikakuId=${params.get("kikakuId")}&isEdaban=false&isFukusya=true`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          // daiwariDataがnullだったら初期値をセット
          if (response.data.daiwariData == null) {
            response.data.daiwariData = `{"ori_array":[]}`;
          }
          const DaiwariClass = new Daiwari(response.data);
          this.parentDaiwariData = DaiwariClass.daiwariData;
          const isDensi = true;
          // 親台割が空だったら折を一つ追加する
          if (this.parentDaiwariData.ori_array.length === 0) {
            // 折の追加
            let ori = new Ori();
            ori.ori_address = ++maxAddress.ori;
            ori.ori_kbn = this.daiwariData.ori_array.length + 1 + "K";
            ori.sikisuu = "1C";
            // 作品の追加
            let sakuhin = new Sakuhin();
            sakuhin.sakuhin_address = ++maxAddress.sakuhin;
            sakuhin.sakuhin_uniqueId = util.getUniqueStr();
            sakuhin.sakuhin_bikouId = util.getUniqueStr();
            sakuhin.addSakuhinAfterFlg = true;
            ori.sakuhin_array.push(sakuhin);
            this.addBikou_toPageBikouArray(sakuhin);
            sakuhin.pageCountFlg = true;
            sakuhin.sakuhin_address_computed_array_withFlg = [];
            const judgedGenkoryoSettei = this.judgeInitialGenkoryoSettei();
            sakuhin.genkoryo_settei.setteiKbn = judgedGenkoryoSettei.setteiKbn;
            sakuhin.genkoryo_settei.meisyo = judgedGenkoryoSettei.meisyo;
            this.parentDaiwariData.ori_array.push(ori);
            util.rebuildDaiwariData(
              this.daiwariData,
              maxAddress,
              maxSameGroupCd
            );
            this.update_daiwariSakuhinArray();

            this.daiwariData.sakuhin_page_bikou_array =
              pageBikou.sort_sakuhin_page_bikou_array(
                this.daiwariData.sakuhin_page_bikou_array,
                this.daiwariSakuhinArray,
                this.daiwariData
              );
          }

          // 仮作品、仮ペンネームの使用状況をポストする
          let kariSakuhinIdArray = [];
          let kariPennameIdArray = [];
          this.parentDaiwariData.ori_array.forEach((ori) => {
            ori.sakuhin_array.forEach((sakuhin) => {
              if (sakuhin.sakuhin_id < -1) {
                kariSakuhinIdArray.push(sakuhin.sakuhin_id);
              }
              sakuhin.penName.forEach((pen) => {
                if (pen.pennameId < -1) {
                  kariPennameIdArray.push(pen.pennameId);
                }
              });
            });
          });
          this.copyDaiwariKariData.useKariData = true;
          this.copyDaiwariKariData.kariSakuhinIdArray = Array.from(
            new Set(kariSakuhinIdArray)
          );
          this.copyDaiwariKariData.kariPennameIdArray = Array.from(
            new Set(kariPennameIdArray)
          );
          this.copy_daiwari(this.parentDaiwariData, isDensi);
        });
    },
    //ページ範囲備考の設定まわり
    onClickClosePageRageBikou() {
      if (!event.target.className.includes("page_range_button")) {
        this.overlayName = "";
        this.previewPageRangeData.previewPageRanges.splice(0); //ページ範囲のプレビュー非表示
      }
    },
    registerPageRangeBikou(pageRangeInfo) {
      pageRangeBikou.add_pageRangeInfo(this.daiwariData, pageRangeInfo);
    },
    updatePageRangeBikou(targetBikouObj, insertIndex) {
      this.daiwariData.page_range_bikou_array.splice(insertIndex, 1, {
        ...targetBikouObj,
      });
      //ページ順にソート
      this.daiwariData.page_range_bikou_array.sort((a, b) => {
        return a.firstPage < b.lastPage ? -1 : 1;
      });
    },
    deletePageRangeBikou(deleteIndex) {
      this.daiwariData.page_range_bikou_array.splice(deleteIndex, 1);
    },
    confirmPageRangePosition() {
      if ("page_range_bikou_array" in this.daiwariData === false) {
        return;
      }
      //ページ範囲内の場合アラート表示
      if (
        pageRangeBikou.needsConfirmPageRangePosition(
          this.daiwariData.page_range_bikou_array,
          this.selectedSakuhin
        )
      ) {
        if (window.confirm("ページ範囲情報が削除されますがよろしいですか？")) {
          //備考の削除
          pageRangeBikou.delete_pageRangeInfo(
            this.daiwariData.page_range_bikou_array,
            this.selectedSakuhin
          );
        } else {
          //移動前のデータに戻す
          this.undo_process();
          return;
        }
      }
    },
    setPreviewPageRangeAddress(newAddressIndexArray, color) {
      this.previewPageRangeData.previewPageRanges = newAddressIndexArray;
      this.previewPageRangeData.color = color;
    },
    deleteEdaban(edabanDaiwariId) {
      this.relatedDaiwariList = this.relatedDaiwariList.filter(
        (elm) => elm.daiwariId !== edabanDaiwariId
      );
    },
    // 処理中オーバーレイ表示時クリックイベントを発火させないようにする
    clickProcessing(event) {
      event.preventDefault();
    },
  },
  watch: {
    selectedAction(newSelected) {
      // 1. 保存
      if (newSelected == 1) {
        this.selectedAction = 0;
        this.saveDaiwari();
      }
      // 2. 一覧表示
      if (newSelected == 2) {
        this.selectedAction = 0;
      }
    },
  },
  computed: {
    //32Pを選択したとき空の折があれば画面上に表示しない(データは保持している)
    isEmptyOri_32() {
      if (this.oriMaximumPage === 32) {
        let isEmptyOri = this.daiwariData.ori_array.some(
          (ori) => ori.sakuhin_array === 0
        );
        if (isEmptyOri) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isOpenPageRangeOverlay() {
      return this.overlayName === "overlay_pageRangeBikou";
    },
    // 作品エリアのwidth切替用
    sakuhinWidth() {
      return (sakuhin) => {
        if (this.oriMaximumPage == 16) {
          return (
            "sakuhin_medium_" + sakuhin.sakuhin_address_computed_array.length
          );
        } else if (this.oriMaximumPage == 32) {
          return (
            "sakuhin_small_" + sakuhin.sakuhin_address_computed_array.length
          );
        } else {
          return (
            "sakuhin_large_" + sakuhin.sakuhin_address_computed_array.length
          );
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  @media print {
    .print {
      //台割出力画面の印刷時に台割編集画面を表示させないため
      display: none;
    }
  }

  @page {
    margin-top: 0.8cm;
    margin-bottom: 0.8cm;
  }

  .switch-button {
    &-case {
      display: inline-block;
      background-color: #969696;
      height: 30px;
      color: white;
      border: none;

      &--left {
        border-radius: 5px 0px 0px 5px;
      }

      &--center {
        border-radius: 0px;
      }

      &--right {
        border-radius: 0px 5px 5px 0px;
      }

      &:hover {
        color: grey;
        cursor: pointer;
      }

      // &:focus {
      //   outline: none;
      // }
    }
  }

  .active {
    height: 30px;
    color: white;
    background-color: #ec7211;
    // 競合によりボタン表示されないため一旦コメントアウト
    // opacity: 80%;

    &-case {
      color: #151515;
    }
  }

  .disable_button--header {
    background: rgb(150, 150, 150);
    box-shadow: none;
  }

  .button_narrow {
    min-width: 30px;
  }

  .acordion_group {
    position: relative;
  }

  .acordion_button {
    background: #ec7211;
    border: 1px solid #ec7211;
    min-width: 120px;
    color: #fff;
  }

  .acordion {
    &_area {
      min-width: 80px;
      background: #fff;
      color: #ec7211;
      position: absolute;
      right: 0px;
    }

    &_list {
      list-style: none;
      cursor: pointer;

      li {
        text-align: center;
        font-size: 11px;
        border: 1px solid #ec7211;
        padding: 0px 4px;

        &:nth-child(2) {
          border-top: none;
        }
      }
    }

    &_item {
      background: #fff;
      white-space: nowrap;

      &:hover {
        background: #ec72118f;
        color: #fff;
      }

      &_selected {
        background: #ec72118f;
        color: #fff;
      }

      &_return {
        background: #969696;
        color: #fff;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &_item--disable {
      border: 1px solid #ec7211;
      background: #969696;
      color: white;
    }
  }

  // 作品エリア
  // page 1~1000まで
  @for $i from 1 through 1000 {
    .sakuhin_medium_#{$i} {
      width: 6.25% * $i;
    }
  }
  @for $i from 1 through 1000 {
    .sakuhin_small_#{$i} {
      width: 3.125% * $i;
    }
  }
  @for $i from 1 through 1000 {
    .sakuhin_large_#{$i} {
      width: 12.5% * $i;
    }
  }

  .badge_gray {
    cursor: default;
    background-color: rgb(235, 235, 235);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 20px;
    padding: 0px 5px 0px 5px;
    // opacity: 80%;
  }

  // 処理中オーバーレイ
  .processing-overlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .processing-icon {
      display: inline-block;
      border: 3px solid #ffffff;
      border-top: 3px solid #848484;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .processing-message {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
    }
  }

  // サイドバーに合わせてcontentのleftとwidthを変更する
  .SideBar_False_Daiwari {
    left: 300px;
    width: calc(100vw - 300px);
  }
  .SideBar_True_Daiwari {
    left: 420px;
    width: calc(100vw - 420px);
  }
  @media screen and (max-width: 1024px) {
    .SideBar_False_Daiwari {
      left: 240px;
      width: 100vw;
    }
    .SideBar_True_Daiwari {
      left: 240px;
      width: 100vw;
    }
  }
}
</style>
