import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import OrderSearch from "../views/OrderSearch.vue";
import OrderHold from "../views/OrderHold.vue";
import OrderFormMulti from "../views/OrderFormMulti.vue";
import OrderConfirm from "../views/OrderConfirm.vue";
import Login from "../views/Login.vue";
import PasswordForget from "../views/PasswordForget.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PasswordMailSubmitted from "../views/PasswordMailSubmitted.vue";
import UserInfo from "../views/UserInfo.vue";
import Admin from "../views/Admin.vue";
import CartNormal from "../views/CartNormal.vue";
import CartMulti from "../views/CartMulti.vue";
import OrderAdjustment from "../views/OrderAdjustment.vue";
import DashboardPage from "../views/DashboardPage.vue";
import OrderOutOfStock from "../views/OrderOutOfStock.vue";
import OrderAdjustmentDetail from "../views/OrderAdjustmentDetail.vue";
import OrderConfirmEdit from "../views/OrderConfirmEdit.vue";
import OrderConfirmReference from "../views/OrderConfirmReference.vue";
import OrderItemInfo from "../views/OrderItemInfo.vue";
import ProductReference from "../views/ProductReference.vue";
import ProductReferenceDetail from "../views/ProductReferenceDetail.vue";
import OrderSearchDetail from "../views/OrderSearchDetail.vue";
import ShippingHistory from "../views/ShippingHistory.vue";
import ShippingHistoryDetail from "../views/ShippingHistoryDetail.vue";
import ToriokiDetail from "../views/ToriokiDetail.vue";
import EditProductStatus from "../views/EditProductStatus.vue";
import ReturnSlip from "../views/ReturnSlip.vue";
import DeliverySlip from "../views/DeliverySlip.vue";
import DeliverySlipDetail from "../views/DeliverySlipDetail.vue";
import ReturnSlipDetail from "../views/ReturnSlipDetail.vue";
import CreateList from "../views/CreateList.vue";
import CreateNewList from "../views/CreateNewList.vue";
import OrderOutOfStockLog from "../views/OrderOutOfStockLog.vue";
import PreOrderList from "../views/PreOrderList.vue";
import NewPreOrderList from "../views/NewPreOrderList.vue";
import ProductSimulation from "../views/ProductSimulation.vue";
import ItemRemarksLog from "../views/ItemRemarksLog.vue";
import UpdateStatusLog from "../views/UpdateStatusLog.vue";
import ProductSimulationLog from "../views/ProductSimulationLog.vue";
import EditProductStatusLog from "../views/EditProductStatusLog.vue";
import InformationDetail from "../views/InformationDetail.vue";
import DaiwariEdit from "../views/DaiwariEdit.vue";
import Daiwari from "../views/Daiwari.vue";
import Errors from "../views/Errors.vue";
import Kikaku from "../views/Kikaku.vue";
import KikakuEdit from "../views/Kikaku_edit.vue";
import KikakuSubmit from "../views/KikakuSubmit.vue";

const routes = [
  {
    path: "/orderSearch",
    name: "OrderSearch",
    component: OrderSearch,
    meta: { title: "通常入力" },
  },
  {
    path: "/orderHold",
    name: "OrderHold",
    component: OrderHold,
    meta: { title: "受注保留解除" },
  },
  {
    path: "/orderFormMulti",
    name: "OrderFormMulti",
    component: OrderFormMulti,
    meta: { title: "マルチ入力" },
  },
  {
    path: "/orderConfirm",
    name: "OrderConfirm",
    component: OrderConfirm,
    meta: { title: "受注照会" },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "ログイン" },
  },
  {
    path: "/passwordForget",
    name: "PasswordForget",
    component: PasswordForget,
    meta: { title: "パスワード再設定" },
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { title: "パスワード再設定" },
  },
  {
    path: "/passwordMailSubmitted",
    name: "PasswordMailSubmitted",
    component: PasswordMailSubmitted,
    meta: { title: "再設定メール送信完了" },
  },
  {
    path: "/userInfo",
    name: "UserInfo",
    component: UserInfo,
    meta: { title: "ユーザー情報" },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { title: "管理者" },
  },
  {
    path: "/cartNormal",
    name: "CartNormal",
    component: CartNormal,
    meta: { title: "通常カート照会" },
  },
  {
    path: "/cartMulti",
    name: "CartMulti",
    component: CartMulti,
    meta: { title: "マルチカート照会" },
  },
  {
    path: "/orderAdjustment",
    name: "OrderAdjustment",
    component: OrderAdjustment,
    meta: { title: "調整" },
    beforeEnter: (to: any, from: any, next: any) => {
      // 調整詳細画面から遷移した場合は元の検索データを保持する
      if (from.name !== "OrderAdjustmentDetail") {
        store.commit("search/clearAuthData");
      }
      next();
    },
  },
  {
    //下記画面遷移は後/:idを付与する
    path: "/orderAdjustmentDetail",
    name: "OrderAdjustmentDetail",
    component: OrderAdjustmentDetail,
    meta: { title: "調整詳細" },
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: DashboardPage,
    meta: { title: "ダッシュボード" },
  },
  {
    path: "/orderOutOfStock",
    name: "OrderOutOfStock",
    component: OrderOutOfStock,
    meta: { title: "品切保留" },
  },
  {
    path: "/orderConfirmEdit/:id",
    name: "OrderConfirmEdit",
    component: OrderConfirmEdit,
    meta: { title: "受注修正" },
  },
  {
    path: "/orderConfirmReference/:id",
    name: "OrderConfirmReference",
    component: OrderConfirmReference,
    meta: { title: "通常入力照会" },
  },
  {
    //データ連携後IDを付与
    path: "/orderItemInfo/:id",
    name: "OrderItemInfo",
    component: OrderItemInfo,
    meta: { title: "製品詳細" },
  },
  {
    path: "/productReference",
    name: "ProductReference",
    component: ProductReference,
    meta: { title: "在庫照会" },
  },
  {
    path: "/productReferenceDetail/:id",
    // path: '/ProductReferenceDetail',
    name: "ProductReferenceDetail",
    component: ProductReferenceDetail,
    meta: { title: "在庫詳細" },
  },
  {
    path: "/orderSearchDetail/:id",
    // path: '/ProductReferenceDetail',
    name: "OrderSearchDetail",
    component: OrderSearchDetail,
    meta: { title: "通常入力詳細" },
  },
  {
    path: "/shippingHistory",
    name: "ShippingHistory",
    component: ShippingHistory,
    meta: { title: "出庫履歴検索" },
  },
  {
    path: "/shippingHistoryDetail/:id",
    name: "ShippingHistoryDetail",
    component: ShippingHistoryDetail,
    meta: { title: "出庫履歴照会" },
  },
  {
    path: "/toriokiDetail/:janCd",
    name: "ToriokiDetail",
    component: ToriokiDetail,
    meta: { title: "取置詳細" },
  },
  {
    path: "/editProductStatus",
    name: "EditProductStatus",
    component: EditProductStatus,
    meta: { title: "商品ステータス" },
  },
  {
    path: "/returnSlip",
    name: "returnSlip",
    component: ReturnSlip,
    meta: { title: "返品伝票" },
  },
  {
    path: "/returnSlipDetail/:id",
    name: "ReturnSlipDetail",
    component: ReturnSlipDetail,
    meta: { title: "返品伝票詳細" },
  },
  {
    path: "/deliverySlip",
    name: "deliverySlip",
    component: DeliverySlip,
    meta: { title: "納品伝票" },
  },
  {
    path: "/deliverySlipDetail/:id",
    name: "DeliverySlipDetail",
    component: DeliverySlipDetail,
    meta: { title: "納品伝票詳細" },
  },
  {
    path: "/createList",
    name: "CreateList",
    component: CreateList,
    meta: { title: "注文リスト作成" },
  },
  {
    path: "/createNewList/:id",
    name: "CreateNewList",
    component: CreateNewList,
    meta: { title: "新規注文リスト作成" },
  },
  {
    path: "/orderOutOfStockLog",
    name: "OrderOutOfStockLog",
    component: OrderOutOfStockLog,
    meta: { title: "品切保留履歴" },
  },
  {
    path: "/preOrderList",
    name: "PreOrderList",
    component: PreOrderList,
    meta: { title: "事前注文リスト作成" },
  },
  {
    path: "/newPreOrderList/:id",
    name: "NewPreOrderList",
    component: NewPreOrderList,
    meta: { title: "新規事前注文リスト作成" },
  },
  {
    path: "/productSimulation",
    name: "ProductSimulation",
    component: ProductSimulation,
    meta: { title: "在庫シミュレーション" },
  },
  {
    path: "/itemRemarksLog",
    name: "ItemRemarksLog",
    component: ItemRemarksLog,
    meta: { title: "商品備考ログ" },
  },
  {
    path: "/updateStatusLog",
    name: "UpdateStatusLog",
    component: UpdateStatusLog,
    meta: { title: "VAN強制ステータスログ" },
  },
  {
    path: "/productSimulationLog",
    name: "ProductSimulationLog",
    component: ProductSimulationLog,
    meta: { title: "在庫シミュレーションログ" },
  },
  {
    path: "/editProductStatusLog",
    name: "EditProductStatusLog",
    component: EditProductStatusLog,
    meta: { title: "商品ステータスログ" },
  },
  {
    path: "/informationDetail",
    name: "InformationDetail",
    component: InformationDetail,
    meta: { title: "お知らせ一覧" },
  },
  {
    path: "/daiwari_edit",
    name: "DaiwariEdit",
    component: DaiwariEdit,
    meta: { title: "台割作成" },
  },
  {
    path: "/daiwari",
    name: "Daiwari",
    component: Daiwari,
    meta: { title: "台割一覧" },
    beforeEnter: (to: any, from: any, next: any) => {
      // 台割作成画面から遷移した場合は元の検索データを保持する
      if (from.name !== "DaiwariEdit") {
        store.commit("search/clearAuthData");
      }
      next();
    },
  },
  {
    path: "/errors",
    name: "Errors",
    component: Errors,
    meta: { title: "ページを表示できません" },
  },
  {
    path: "/kikaku",
    name: "Kikaku",
    component: Kikaku,
    meta: { title: "企画登録" },
  },
  {
    path: "/kikaku_new",
    name: "KikakuNew",
    component: KikakuEdit,
    meta: { title: "新規企画作成" },
  },
  {
    path: "/kikaku_edit",
    name: "KikakuEdit",
    component: KikakuEdit,
    meta: { title: "企画編集" },
  },
  {
    path: "/kikaku_edit_submit",
    name: "KikakuSubmit",
    component: KikakuSubmit,
    meta: { title: "企画編集送信" },
  },
  {
    path: "/kikaku_new_submit",
    name: "KikakuNewSubmit",
    component: KikakuSubmit,
    meta: { title: "企画登録送信" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//トークン有効性チェック
const validateToken = async () => {
  await store.dispatch("login/validateToken");
};

// const DEFAULT_TITLE = "受注こねくと";

router.afterEach((to) => {
  const title = to.meta.title ? `${to.meta.title}` : ``;
  document.title = title;
});

router.beforeEach(async (to, from, next) => {
  await validateToken();
  const isValidToken = store.getters["login/getIsValidToken"];
  const userRole = store.getters["login/getLoginSyotenRole"];

  if (!isValidToken) {
    if (
      to.path === "/" ||
      to.path === "/passwordForget" ||
      to.path === "/passwordReset" ||
      to.path === "/passwordMailSubmitted"
    ) {
      next(); //ログイン画面にいる場合は画面遷移許可
    } else {
      alert("セッションがタイムアウトしました。再度ログインしてください。");
      store.dispatch("login/logout");
      next("/"); //ログイン画面に戻る
    }
  } else {
    if (to.path === "/") {
      next("/dashboard"); //ログイン中のログイン画面への遷移は不可
    } else if (to.path === "/admin" && userRole !== "ADMIN") {
      next("/dashboard"); //管理者以外の管理画面表示は不可
    } else {
      next(); //有効なので画面遷移許可
    }
  }
});
export default router;
