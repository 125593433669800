<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">作品をコピーする</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />
      </div>
      <div class="modal__review">
        <div class="modal__message">
          <p>
            <span class="sakuhin_title"
              >「{{ selectedSakuhin.sakuhin_mei }}」</span
            >をコピーしますか？
          </p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_orange" @click="this.copySakuhin">
            コピーする
          </button>
          <button class="btn_gray" @click="hideModal">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedSakuhin: Object,
    copySakuhin: Function,
  },
  emits: ["hideModal"],
  data() {
    return {};
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", "modal_copy");
    },
    stopEvent(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 600px;
    height: 290px;
    &__header {
      height: 20%;
    }
    &__message {
      font-size: 16px;
    }
  }

  .sakuhin_title {
    font-weight: bold;
  }

  .btn_orange,
  .btn_gray {
    width: 150px;
    height: 40px;
    padding: 10px;
  }
}
</style>
