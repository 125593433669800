<template>
  <div id="MultiSelectBar">
    <div class="selectContainer">
      <p class="selectContainer_title">{{ title }}：</p>
      <div
        class="selectContainer_selectArea"
        v-for="(item, index) in selectList"
        :key="index"
      >
        <div>
          <span
            :class="item.selected ? 'selectArea selected' : 'selectArea'"
            @click="selectItem(item, index)"
            >{{ item.label }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ori",
  props: {
    title: String,
    selectList: Array,
  },
  emits: ["selectEvent"],
  methods: {
    selectItem(item, itemIndex) {
      item.selected = !item.selected;
      //変更した項目の変更結果とインデックスを親に送る
      this.$emit("selectEvent", item.selected, itemIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #MultiSelectBar {
    display: inline-block;
  }
  .selectContainer {
    display: flex;
    background: #f2f1f1;
    padding: 2px 10px;
    width: fit-content;
    border-radius: 50px;
    font-size: 14px;
    margin-left: 10px;
  }

  .selectContainer_title {
    font-weight: 800;
    min-width: 85px;
  }
  .selectContainer_selectArea {
    padding: 0 5px;
    display: flex;
  }
  .selectArea {
    cursor: pointer;
    padding: 3px 5px;
    &:hover {
      background: #fac287;
      border-radius: 50px;
      color: #ffffff;
    }
  }
  .selected {
    border-radius: 50px;
    color: #ffffff;
    background: #ff9d33;
    &:hover {
      background: #ff9d33;
    }
  }
}
</style>
