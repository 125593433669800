<script setup>
import { reactive } from "vue";

const props = defineProps({
  selectBarList_props: Object,
});
const emit = defineEmits(["updateSelectValue"]);

let selectBarList = reactive(Object.assign({}, props.selectBarList_props));
const selectItem = (item) => {
  selectBarList.select_list.forEach((select) => {
    //selectFlgを更新
    if (select.name === item.name) {
      select.selectFlg = true;
    } else {
      select.selectFlg = false;
    }
  });
  emit("updateSelectValue", item);
};
</script>

<template>
  <div class="SelectContainer">
    <p class="Title">{{ selectBarList.title }}：</p>
    <div class="SelectArea">
      <span
        v-for="(item, index) in selectBarList.select_list"
        :key="index"
        @click="selectItem(item)"
        class="SelectArea_Item"
        :class="{ IsSelected: item.selectFlg }"
      >
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.SelectContainer {
  display: flex;
  background: #f2f1f1;
  padding: 5px 25px;
  // width: 300px;
  width: fit-content;
  // height: 30px;
  height: fit-content;
  border-radius: 50px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 5px;
}
.Title {
  font-weight: 800;
}
.SelectArea {
  padding: 0 5px;
  &_Item {
    cursor: pointer;
    padding: 3px 10px;
    &:hover {
      background: #ff8000;
      border-radius: 50px;
      color: #ffffff;
    }
  }
}
.IsSelected {
  background: #ff8000;
  border-radius: 50px;
  color: #ffffff;
}
</style>
