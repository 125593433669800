<template>
  <div
    id="PageRangeBikouTale"
    :class="isCompact ? 'print_display_none' : ''"
    v-if="vm_page_range_bikou_array.length"
  >
    <p>【ページ範囲備考情報】</p>
    <table class="daiwari-table" :class="sheet">
      <tr>
        <th class="daiwari-table_th daiwari-table_th_color">色設定</th>
        <th class="daiwari-table_th daiwari-table_th_page">ページ</th>
        <th class="daiwari-table_th daiwari-table_th_bikou">
          ページ範囲備考内容
        </th>
      </tr>
      <template
        v-for="(bikouData, bikouDataIndex) in vm_page_range_bikou_array"
        :key="bikouData.bikouId + bikouDataIndex"
      >
        <tr>
          <td
            class="daiwari-table_td daiwari-table_td_color"
            :class="`bg_${bikouData.color}`"
          ></td>
          <td
            :class="bikouDataIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_page"
          >
            {{ bikouData.firstPage }}~{{ bikouData.lastPage }}
          </td>
          <td
            :class="bikouDataIndex % 2 === 0 ? '' : 'bg-gray'"
            class="daiwari-table_td daiwari-table_td_bikou"
          >
            {{ bikouData.bikou }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { useVModel } from "vue-composable";
export default {
  name: "PageRangeBikouTale",
  props: {
    printSize: String,
    props_daiwariData: Object,
    page_range_bikou_array: Array,
    isCompact: Boolean,
  },
  setup(props) {
    const vm_page_range_bikou_array = useVModel(
      props,
      "page_range_bikou_array"
    );
    return {
      vm_page_range_bikou_array,
    };
  },
  created() {
    //ページ昇順で入れ替える
    this.vm_page_range_bikou_array.sort((a, b) => {
      return a.firstPage < b.lastPage ? -1 : 1;
    });

    // 色順で入れ替える
    // 色の順番用変数
    const toSortColor = [
      {
        id: 1,
        color: "pink",
      },
      {
        id: 2,
        color: "yellow",
      },
      {
        id: 3,
        color: "green",
      },
      {
        id: 4,
        color: "orange",
      },
      {
        id: 5,
        color: "purple",
      },
    ];
    // 色に順番を付けるためcolorIdを付与
    this.vm_page_range_bikou_array.forEach((bikou) => {
      const targetColorInfo = toSortColor.find(
        (sortColor) => sortColor.color === bikou.color
      );
      bikou.colorId = targetColorInfo.id;
    });
    // 並び替え実行
    // eslint-disable-next-line vue/no-mutating-props
    this.vm_page_range_bikou_array.sort((a, b) => {
      return a.colorId < b.colorId ? -1 : 1;
    });
    // colorIdの削除
    this.vm_page_range_bikou_array.forEach((bikou) => delete bikou.colorId);
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
  },
};
</script>
<style lang="scss" scoped>
#daiwari_edit {
  #PageRangeBikouTale {
    width: 100%;
    padding-top: 20px;
  }
  .daiwari-table {
    border-collapse: separate;
    border-right: 1px solid;
    table-layout: fixed;
    width: 100%;
    background: #fff;

    &_td {
      height: 10px;
      border: 1px solid;
      border-top: none;
      border-right: none;
      text-align: center;
      vertical-align: middle;
      &_bikou {
        width: 60%;
      }
      &_color {
        width: 10%;
      }
      &_page {
        width: 30%;
      }
    }
    &_th {
      background-color: #ddd;
      padding: 0px 5px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid;
      border-right: none;
      &_bikou {
        width: 60%;
      }
      &_color {
        width: 10%;
      }
      &_page {
        width: 30%;
      }
    }
  }
  .bg-gray {
    background: #efeeee;
  }
  //ページ範囲備考の背景色
  .bg_pink {
    background-color: #ffd5ec;
    &--preview {
      border: 2px dashed pink;
    }
  }
  .bg_yellow {
    background-color: #ffffaa;
    &--preview {
      border: 2px dashed yellow;
    }
  }
  .bg_green {
    background-color: #e6ffe9;
    &--preview {
      border: 2px dashed green;
    }
  }
  .bg_orange {
    background-color: #ffdbc9;
    &--preview {
      border: 2px dashed orange;
    }
  }
  .bg_purple {
    background-color: #e6e6fa;
    &--preview {
      border: 2px dashed purple;
    }
  }
  //印刷用CSS-------------------------------
  @media print {
    //A4の場合...
    .sheet_A4 {
      // width: 792px;
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }
    .print_display_none {
      display: none;
    }
  }
}
</style>
