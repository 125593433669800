<script setup>
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import { Notyf } from "notyf";
import BtnGreen from "../components/atoms/button/BtnGreen.vue";
import PankuzuHeader from "../components/PankuzuHeader.vue";
import ModalExclusion from "@/components/modal/ModalExclusion.vue";

const props = defineProps({
  isActive_SidebarFlg: { type: Boolean },
});
// Emitの定義
const emit = defineEmits(["showGlobalHeader", "toggleGlobalHeader"]);

// ストア
const store = useStore();

//router定義
const $router = useRouter();
const route = useRoute();

const isLogMode = ref(Number(route.query.isLogMode) === 0 ? false : true); // ログ表示か否か
// ログだったらサイドバーを非表示
if (isLogMode.value) {
  emit("toggleGlobalHeader");
} else {
  emit("showGlobalHeader");
}

//ぱんくずヘッダー
const currentPathList = [
  { name: "受注入力" },
  { name: "調整" },
  { name: "調整詳細" },
];
//調整詳細データ取得
const choseiData = ref({});
const chosei_list = ref([]);
const changeLogList = ref([]);
const getChoseiDetail = async () => {
  try {
    let url = "";
    if (isLogMode.value) {
      url = `${store.getters["getBaseUrl"]}/chosei/orderList-log?uuid=${route.query.uuid}`;
    } else {
      url = `${store.getters["getBaseUrl"]}/chosei/orderList?janCd=${route.query.janCd}`;
    }
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(res.data);
    choseiData.value = res.data;
    res.data.choseiDetailList.forEach((chosei) => {
      chosei_list.value.push({ defaultChoseiSuu: chosei.choseiSuu, ...chosei });
    });
    res.data.changeaLogList.forEach((log) => {
      changeLogList.value.push(log);
    });
    // console.log("choseiData:", choseiData.value);
    // console.log("chosei_list:", chosei_list.value);
    // console.log("changeLogList:", changeLogList.value);
  } catch (error) {
    console.log(error);
  }
};
getChoseiDetail();

//調整対象数
let holdQuantity = "";
const focusQuantity = (e) => {
  holdQuantity = e.target.value;
  e.target.select();
};
const validateQuantity = (e, index) => {
  //符号無し整数を判定
  if (!e.target.value.match(/^[0-9]+$/)) {
    e.target.value = holdQuantity;
    chosei_list.value[index].choseiSuu = holdQuantity;
  }
  // 正しい数字だったら受注数より少ないかバリデーション
  else {
    if (Number(e.target.value) > Number(chosei_list.value[index].juchuSuu)) {
      e.target.value = holdQuantity;
      chosei_list.value[index].choseiSuu = holdQuantity;
      notyf.error("受注数以下で入力してください");
    } else {
      chosei_list.value[index].choseiSuu = e.target.value;
    }
  }
};
const calcTotalChoseiSuu = () => {
  // console.log(chosei_list.value);
  const totalChoseiSuu = chosei_list.value.reduce((sum, item) => {
    return sum + Number(item.choseiSuu);
  }, 0);
  return totalChoseiSuu;
};

//使用可能在庫数
const calcAvailableZaikoAmount = () => {
  const availableZaikoAmount =
    choseiData.value.availableAmount - calcTotalChoseiSuu();
  return availableZaikoAmount;
};

//確定
const notyf = new Notyf();
const kakuteiChoseiData = async () => {
  //バリデーション
  if (calcAvailableZaikoAmount() < 0) {
    notyf.error("使用可能在庫数がマイナス値になっているため確定できません。");
    return;
  }

  if (!window.confirm("確定してもよろしいですか?")) {
    return;
  }
  try {
    const sendData = [];
    chosei_list.value.forEach((item) => {
      let updateFlg =
        item.defaultChoseiSuu === Number(item.choseiSuu) ? false : true;
      sendData.push({
        choseiId: Number(item.choseiId),
        choseiSuu: Number(item.choseiSuu),
        janCd: item.janCd,
        updateFlg: updateFlg,
        version: Number(item.version),
      });
    });
    await axios.patch(
      `${store.getters["getBaseUrl"]}/chosei/orderList`,
      sendData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    notyf.success("確定しました");
    back_to_orderAdjustMent();
  } catch (error) {
    // 409エラーの場合（バージョン不一致の場合）
    if (axios.isAxiosError(error) && error.response?.status === 409) {
      notyf.error({
        duration: 10000,
        message: error.response?.data.userMessage,
      });
    } else {
      console.log(error);
      notyf.error("確定処理に失敗しました");
    }
  }
};

const back_to_orderAdjustMent = () => {
  $router.push("/orderAdjustment");
};

// 排他制御モーダル
const showExclusionModal = ref(false);
const reloadWindow = () => {
  showExclusionModal.value = false;
  chosei_list.value.splice(0);
  getChoseiDetail();
};

// ログの表示---------------------------------------
const selectedUuid = ref({});
const showLog = () => {
  if (Object.keys(selectedUuid.value).length) {
    // 遷移処理
    let resolvedRoute = $router.resolve({
      name: "OrderAdjustmentDetail",
      query: {
        isLogMode: 1,
        uuid: selectedUuid.value.uuid,
      },
    });
    window.open(
      resolvedRoute.href,
      "_blank",
      "width=1000,height=600,scrollbars=1,location=0,menubar=0,toolbar=0,status=1,directories=0,resizable=1,left=" +
        (window.screen.width - 1000) / 2 +
        ",top=" +
        (window.screen.height - 650) / 2
    );
  }
};

// ダウンロード関連--------------------------------------------------
let isDownLoading = ref(false);
const downloadChoseiData = async () => {
  isDownLoading.value = false;
  try {
    isDownLoading.value = true;
    const searchParams = `syosiCd=${choseiData.value.syosiCd}`;
    const res = await axios.get(
      `${store.getters["getBaseUrl"]}/chosei/download-excel?${searchParams}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "arraybuffer",
        responseEncoding: "binary",
      }
    );
    if (res.data === "") {
      isDownLoading.value = false;
      notyf.error("ダウンロードするデータがありません");
    } else {
      downloadFile(res);
    }
  } catch (error) {
    isDownLoading.value = false;
    notyf.error("ダウンロードに失敗しました");
    console.log(error);
  }
};
const downloadFile = (resData) => {
  let blob = new Blob([resData.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `調整詳細_明細.xlsx`;
  link.click();
  isDownLoading.value = false;
};
</script>

<template>
  <ModalExclusion v-if="showExclusionModal" @confirm="reloadWindow" />
  <div
    class="OrderAdjustment"
    :class="{
      SideBar_False: !props.isActive_SidebarFlg,
      SideBar_True: props.isActive_SidebarFlg,
      OrderAdjustment_IsLogMode: isLogMode,
    }"
  >
    <PankuzuHeader
      v-if="!isLogMode"
      :isActive_SidebarFlg="props.isActive_SidebarFlg"
      :currentPathList="currentPathList"
    />
    <div class="OrderAdjustment_Title" v-else>
      調整詳細 変更履歴（{{ choseiData.editDate?.replace(/-/g, "/") }}
      {{ choseiData.numberOfTimes }}回目）
    </div>
    <BtnGreen
      v-if="!isLogMode"
      class="OrderAdjustment_BtnGreen"
      @click="back_to_orderAdjustMent"
      >調整リストに戻る</BtnGreen
    >
    <div class="OrderAdjustment_ListContainer">
      <div>
        <table class="OrderAdjustment_List OrderAdjustment_ListTop">
          <tr>
            <th>書誌CD</th>
            <td>{{ choseiData.syosiCd }}</td>
            <th>ISBN13桁</th>
            <td>{{ choseiData.isbn }}</td>
            <th>出版社書誌CD</th>
            <td>{{ choseiData.syuppanSyosiCd }}</td>
          </tr>
        </table>
        <table class="OrderAdjustment_List">
          <tr>
            <th>商品名</th>
            <td class="OrderAdjustment_List_SyosiMei">
              {{ choseiData.title }}
            </td>
          </tr>
        </table>
        <table class="OrderAdjustment_List">
          <tr>
            <th>商品備考</th>
            <td class="OrderAdjustment_List_Bikou">
              {{ choseiData.itemRemarks }}
            </td>
          </tr>
        </table>
      </div>
      <div class="OrderAdjustment_ListContainer_BtnBlock">
        <button
          class="OrderAdjustment_BtnGray"
          download="sample.txt"
          @click="downloadChoseiData"
          :disabled="isDownLoading"
          :class="{ ReturnSlip_BtnDisabled: isDownLoading }"
        >
          <font-awesome-icon
            icon="download"
            style="display: inline-block"
            v-if="!isDownLoading"
          />
          <span v-if="isDownLoading" class="loading"></span>
          <span style="display: inline-block">ダウンロード</span>
        </button>
      </div>
    </div>
    <div
      class="OrderAdjustment_MainArea"
      :class="{
        OrderAdjustment_MainArea_IsLogMode: isLogMode,
      }"
    >
      <table class="OrderAdjustment_Table">
        <thead>
          <tr class="OrderAdjustment_Table_Tate">
            <th class="OrderAdjustment_Table_HeaderMedium">注文日付</th>
            <th>受注No</th>
            <th>調整No</th>
            <th class="OrderAdjustment_Table_HeaderMedium">
              取次CD<br />取次名
            </th>
            <th
              class="OrderAdjustment_Table_HeaderLong"
              style="border-right: 1px solid #fff"
            >
              書店CD / 搬入先CD<br />
              書店名 / 搬入先名
            </th>
            <th>番線</th>
            <th class="OrderAdjustment_Table_HeaderMedium">在庫イベント区分</th>
            <th>受注数</th>
            <th>調整対象</th>
            <th class="OrderAdjustment_Table_HeaderMedium">イベント名</th>
            <th class="OrderAdjustment_Table_HeaderMedium">短冊区分</th>
            <th class="OrderAdjustment_Table_HeaderMedium">短冊メモ</th>
            <th>確定担当</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in chosei_list" :key="index">
            <td>{{ item.inputDate }}</td>
            <td>{{ item.displayJuchuNumber }}</td>
            <td>{{ item.choseiNumber }}</td>
            <td>{{ item.toritugiCd }}<br />{{ item.toritugiMei }}</td>
            <td>{{ item.hannyuCd }}<br />{{ item.hannyuMei }}</td>
            <td>{{ item.bansen }}</td>
            <td>{{ item.zaikoEventKbn }}</td>
            <td>{{ item.juchuSuu }}</td>
            <td>
              <input
                type="number"
                class="OrderAdjustment_Table_Input"
                :value="item.choseiSuu"
                :disabled="isLogMode"
                @focus="focusQuantity($event, index)"
                @change="validateQuantity($event, index)"
              />
            </td>
            <td>{{ item.eventName }}</td>
            <td>{{ item.tanzakuKbn }}</td>
            <td>{{ item.tanzaku }}</td>
            <td>{{ item.kakuteiId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="OrderAdjustment_ListContainer">
      <table class="OrderAdjustment_List OrderAdjustment_ListBottom">
        <tr>
          <th>受注数</th>
          <td>{{ choseiData.juchuSuu }}</td>
          <th>現在在庫</th>
          <td>{{ choseiData.zaikoSuu }}</td>
          <th>使用<br class="OrderAdjustment_Indent" />可能数</th>
          <td>{{ choseiData.availableAmount }}</td>
          <th>調整<br class="OrderAdjustment_Indent" />対象数</th>
          <td>{{ calcTotalChoseiSuu() }}</td>
          <th>使用可能<br class="OrderAdjustment_Indent" />在庫数</th>
          <td>{{ calcAvailableZaikoAmount() }}</td>
        </tr>
      </table>
    </div>
    <div v-if="!isLogMode" class="OrderAdjustment_BtnBlock">
      <div v-if="changeLogList.length > 0">
        <label for="select-log">変更履歴</label>
        <select id="select-log" v-model="selectedUuid">
          <option
            v-for="(log, logIndex) of changeLogList"
            :key="logIndex"
            :value="log"
          >
            {{ log.numberOfTimes }}回目
          </option>
        </select>
        <button class="btn_orange_small" @click="showLog">表示</button>
      </div>
      <BtnGreen class="OrderAdjustment_BtnGreen" @clickBtn="kakuteiChoseiData"
        >確定</BtnGreen
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.OrderAdjustment {
  position: fixed;
  top: 90px;
  bottom: 110px;
  padding: 0 20px 20px 30px;
  height: 85vh;
  &_IsLogMode {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    padding-top: 10px !important;
  }
  &_Indent {
    display: none;
  }
  &_Pagenation {
    text-align: right;
  }
  &_Title {
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
  }
  &_List {
    tr {
      height: 35px;
    }
    th {
      // font-size: 11px;
      padding: 0 5px;
      border: 1px solid #2a3f54;
      background: #2a3f54;
      color: #fff;
      //幅はすべて固定値で指定
      min-width: 120px;
      max-width: 120px;
    }
    td {
      // font-size: 10px;
      padding: 0 5px;
      border: 1px solid #848484;
      background: #fff;
      text-align: center;
      //幅はすべて固定値で指定
      min-width: 120px;
      max-width: 120px;
      &.OrderAdjustment_List_SyosiMei {
        min-width: 600px;
        max-width: 600px;
      }
      &.OrderAdjustment_List_Bikou {
        min-width: 600px;
        max-width: 600px;
      }
    }
    &Container {
      margin: 10px 5px 0 5px;
      overflow-y: auto;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      &_BtnBlock {
        display: flex;
        align-items: end;
      }
    }
    &Bottom {
      min-width: 90%;
      overflow-x: auto;
    }
  }
  &_MainArea {
    overflow-y: auto;
    overflow-y: auto;
    height: 280px;
    margin: 20px 5px 20px 5px;
    &_IsLogMode {
      height: 400px;
    }
  }
  &_Table {
    border-collapse: separate;
    border-spacing: 0.8px;
    min-width: 100%;
    th {
      // font-size: 11px;
      padding: 0 10px;
      background: #2a3f54;
      color: #fff;
      position: sticky;
      top: 0;
      min-width: 80px;
    }
    td {
      // font-size: 11px;
      padding: 0 5px;
      border-left: 1px solid #848484;
      border-bottom: 1px solid #848484;
      background: #fff;
      text-align: center;
      &:last-child {
        border-right: 1px solid #848484;
      }
    }
    &_Tate {
      height: 40px;
    }
    // 書誌情報の幅は絶対値で指定
    &_Input {
      border: 1px solid #848484;
      width: 40px;
      height: 30px;
    }
    //注文日付の幅は絶対値で指定
    &_Header {
      &Large {
        width: 190px;
      }
      &Medium {
        width: 100px;
      }
      &Long {
        min-width: 210px !important;
        max-width: 210px;
      }
    }
  }
  &_BtnBlock {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    .btn_orange_small {
      @extend .common_btn_animation;
      background: #ff8000;
      color: #fff;
      margin: 2px 0px;
      padding: 0 10px;
      font-size: 12px;
      height: 30px;
      border-radius: 5px;
      font-weight: 800;
      cursor: pointer;
    }
  }
  &_BtnGreen {
    display: inline;
    font-size: 12px;
    height: 35px;
    width: 110px;
    border-radius: 3px;
    margin-left: 5px;
  }
  &_BtnGray {
    @extend .common_btn_animation;
    color: #fff;
    background: #848484;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &_BtnDisabled {
    cursor: not-allowed;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 10px 10px 0px;
    overflow-y: scroll;
    &_Indent {
      display: inline;
    }
    &_Table {
      &Container {
        padding: 5px;
        min-width: 100%;
        min-height: auto;
      }
      &Title {
        width: 79px;
      }
    }
  }
  @media screen and (max-width: 1180px) {
    &_Indent {
      display: inline;
    }
  }
}
</style>
