<template>
  <div
    :id="sheet"
    :class="isCompact || isOnlyDaiwari ? 'DaiwariFormattingLeft_compact' : ''"
  >
    <!-- line(折＋作品)情報 -->
    <div class="mainArea">
      <div
        v-for="(line, index) in lines"
        :key="'line_' + index"
        class="line_container"
        :class="[
          sheet,
          printSize === 'A4' && (index + 1) % 9 == 0 ? '' : '',
          (printSize === 'A3' || printSize === 'B4') && (index + 1) % 10 == 0
            ? ''
            : '',
        ]"
      >
        <div
          v-for="(ori, index) in line.formatOriArray"
          :key="'ori_' + index"
          :class="[
            oriMaximumPage == 8
              ? 'line_8_' + ori.oriTotalPage
              : oriMaximumPage == 16
              ? 'line_16_' + ori.oriTotalPage
              : 'line_32_' + ori.oriTotalPage,
            { last_ori: ori.isLastOri },
          ]"
        >
          <!-- 折情報 -->
          <div
            class="ori_container"
            :class="[
              isCompact || isOnlyDaiwari ? 'ori_container_compact' : '',
              ori.isOdd ? 'odd_ori' : 'even_ori',
            ]"
            :style="{ height: settingOriHeight }"
          >
            <p class="line_clamp_1">
              {{ ori.ori_kbn
              }}<span
                :class="
                  isOnlyDaiwari ||
                  (isCustom && !customOutputList.irokazu.isSelected)
                    ? 'print_display_none'
                    : ''
                "
                >/{{ ori.sikisuu }}</span
              ><span :class="isOnlyDaiwari ? 'print_display_none' : ''"
                >/{{ ori.oriTotalPage }}P</span
              >
            </p>
            <p
              v-if="
                !isOnlyDaiwari &&
                !(isCustom && !customOutputList.yousi.isSelected)
              "
              class="line_clamp_1"
              :class="isCompact || isOnlyDaiwari ? 'print_display_none' : ''"
            >
              {{ hankaku(ori.yousi) }}
            </p>
            <p
              v-if="
                ori.kakou_array.length &&
                !(isCustom && !customOutputList.kakou.isSelected)
              "
              class="line_clamp_1"
              :class="isCompact || isOnlyDaiwari ? 'print_display_none' : ''"
            >
              <span v-for="(kakou, index) in ori.kakou_array" :key="index">
                {{ hankaku(kakou.kbn) }}
                <span
                  v-show="
                    ori.kakou_array.length === 0 ||
                    index !== ori.kakou_array.length - 1
                  "
                  >,</span
                >
              </span>
            </p>
            <p
              :class="'ref_' + ori.ori_kbn"
              class="bikou_ori line_clamp_1"
              ref="ref_ori_bikou"
            >
              {{ ori.ori_bikou }}
            </p>
          </div>

          <!-- 作品情報 -->
          <div
            class="sakuhin_container"
            :class="[
              isCompact
                ? 'sakuhin_container_compact'
                : isOnlyDaiwari
                ? 'sakuhin_container_onlydaiwari'
                : '',
            ]"
            :style="{ height: settingSakuhinHeight(90) }"
          >
            <div
              class="sakuhin_container_address"
              :class="
                isCompact || isOnlyDaiwari
                  ? 'sakuhin_container_address_compact'
                  : ''
              "
            >
              <template v-for="sakuhin of ori.sakuhin_array">
                <template
                  v-for="(
                    address, addressIndex
                  ) in sakuhin.sakuhin_address_computed_array"
                >
                  <span
                    v-if="!props_daiwariData.includesPages_doNotCount"
                    :key="address"
                    ref="address"
                    :class="[
                      oriMaximumPage == 8
                        ? 'address_8_' + ori.oriTotalPage
                        : oriMaximumPage == 16
                        ? 'address_16_' + ori.oriTotalPage
                        : 'address_32_' + ori.oriTotalPage,
                      props_selectBarList[1].selected &&
                      sakuhin.sakuhinPageBikouList.bikou_array[addressIndex] &&
                      sakuhin.sakuhinPageBikouList.bikou_array[addressIndex]
                        .isHighlighted
                        ? 'active_bikou'
                        : '',
                      props_selectBarList[2].selected
                        ? color(sakuhin, addressIndex)
                        : '',
                    ]"
                  >
                    <!-- <span v-show="!sakuhin.pageCountFlg">＊</span> -->
                    {{ address }}</span
                  >
                  <!--カウント対象外の場合 -->
                  <span
                    :key="address"
                    ref="address"
                    v-if="props_daiwariData.includesPages_doNotCount"
                    :class="[
                      oriMaximumPage == 8
                        ? 'address_8_' + ori.oriTotalPage
                        : oriMaximumPage == 16
                        ? 'address_16_' + ori.oriTotalPage
                        : 'address_32_' + ori.oriTotalPage,
                      props_selectBarList[1].selected &&
                      sakuhin.sakuhinPageBikouList.bikou_array[addressIndex] &&
                      sakuhin.sakuhinPageBikouList.bikou_array[addressIndex]
                        .isHighlighted
                        ? 'active_bikou'
                        : '',
                      props_selectBarList[2].selected
                        ? color(sakuhin, addressIndex)
                        : '',
                    ]"
                  >
                    {{
                      sakuhin.sakuhin_address_computed_array_withFlg[
                        addressIndex
                      ]
                    }}
                  </span>
                </template>
              </template>
            </div>

            <div
              class="sakuhin_container_info"
              :class="[
                isCompact
                  ? 'sakuhin_container_info_compact'
                  : isOnlyDaiwari
                  ? 'sakuhin_container_info_onlydaiwari'
                  : '',
              ]"
              :style="{ height: settingSakuhinHeight(70) }"
            >
              <div
                v-for="(sakuhin, index) in ori.sakuhin_array"
                :key="'sakuhin_' + index"
                :style="{ '--page': ori.oriTotalPage }"
                :class="[
                  oriMaximumPage == 16
                    ? 'sakuhin_16_' +
                      sakuhin.sakuhin_address_computed_array.length
                    : 'sakuhin_32_' +
                      sakuhin.sakuhin_address_computed_array.length,
                  props_selectBarList[0].selected
                    ? sakuhinSinkouStatus(sakuhin.sinkou_status)
                    : '',
                ]"
              >
                <span class="sakuhin_mei" ref="ref_sakuhin_mei">
                  {{ sakuhinDisplayName(sakuhin) }}(<span
                    v-show="
                      sakuhin.sakuhin_address_computed_array.length !==
                      sakuhin.total_page
                    "
                    >{{ sakuhin.sakuhin_address_computed_array.length }}p/</span
                  >{{ sakuhin.total_page }}p)
                </span>
                <span
                  class="tantou_mei"
                  ref="ref_tantou"
                  :class="
                    isCompact ||
                    isOnlyDaiwari ||
                    (isCustom && !customOutputList.tantou.isSelected)
                      ? 'print_display_none'
                      : ''
                  "
                >
                  <span v-for="(tantou, index) in sakuhin.tantou" :key="index">
                    {{ tantou.user_last_name }}{{ tantou.user_first_name }}
                    <span
                      v-show="
                        sakuhin.tantou.length === 0 ||
                        index !== sakuhin.tantou.length - 1
                      "
                      >,</span
                    >
                  </span>
                </span>
                <span
                  class="bikou_sakuhin"
                  ref="ref_bikou_sakuhin"
                  :class="
                    isCustom && !customOutputList.sakuhinBiko.isSelected
                      ? 'print_display_none'
                      : ''
                  "
                >
                  {{ sakuhin.sakuhin_bikou }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormatOri, FormatSakuhin } from "@/daiwariJS/DaiwariOutput";
import {
  is_matched_sakuhinId,
  setSakuhinIndexComputedArray,
} from "@/daiwariJS/util";
import { zenkakuToHankaku } from "@/daiwariJS/StringConvertion.js";
import { convertSakuhinMeiFull } from "@/daiwariJS/Sakuhin.js";

export default {
  name: "DaiwariFormattingLeft",
  props: {
    props_daiwariData: Object,
    oriMaximumPage: Number,
    printSize: String,
    props_selectBarList: Array,
    isCompact: Boolean,
    page_range_bikou_array: Array,
    isOnlyDaiwari: Boolean,
    isCustom: Boolean,
    customOutputList: Object,
  },
  emits: ["updateDetailOriArray", "updateDetailSakuhinArray"],
  data() {
    return {
      lines: [], //折と作品をまとめたもの。1lineにつき16p/32p一杯の作品情報とそれに紐づいた折情報が格納されている。
      tantou_list: [{ user_last_name: "担当" }, { user_last_name: "名前" }],
      detailOriArray: [],
      detailSakuhinArray: [],
      nakatojiOriArray: [], //中綴じ用の折配列
      nakatojiOriArray_honbun: [],
      nakatojiOriArray_hyousi: [],
      sakuhin_page_bikou_array: [], //備考のハイライト用
    };
  },
  created() {
    this.format_daiwariData();
    this.format_detailOriArray();
    this.format_detailSakuhinArray();
    this.format_layout();
  },
  methods: {
    format_daiwariData() {
      //新しく区分けした折と作品
      let formatOriArray = [];
      let formatSakuhinArray = [];
      let formatSakuhinAddressArray = [];
      let formatSakuhinArray_withFlg = [];

      //16・32ページのカウント用(16p/32pに達したらlineを切り替える)
      let pageCount = 0;
      let pageCount_withFlg = 0;

      //分断作品の同作品内での折返し回数(備考ハイライトで使用)
      let theNumberOfDividing = 0;
      let pre_orderOfTheSakuhin = 0;
      //分断ページの開始ページ計算用
      let lastPage = 0;
      //折ループ---------------------
      this.props_daiwariData.ori_array.forEach((ori) => {
        //作品ループ---------
        ori.sakuhin_array.forEach((sakuhin) => {
          if (pre_orderOfTheSakuhin !== sakuhin.orderOfTheSakuhin) {
            theNumberOfDividing = 0;
            lastPage = 0;
          }

          for (let i = 0; i < this.addressArray(sakuhin).length; i++) {
            pageCount = pageCount + 1;
            lastPage++;
            formatSakuhinAddressArray.push(pageCount);
            if (sakuhin.sakuhin_address_computed_array_withFlg.length) {
              pageCount_withFlg = pageCount_withFlg + 1;
              formatSakuhinArray_withFlg.push(pageCount_withFlg);
            }
            if (pageCount !== 1 && pageCount % this.oriMaximumPage === 0) {
              //1折の貢数が32pに達したのでここで作品を区切る
              //※貢カウント対象外のアドレスを非表示にするため別途アドレス配列を作成

              //作品追加前に、作品に対しページ備考の情報を追加
              const sakuhinPageBikouList =
                this.props_daiwariData.sakuhin_page_bikou_array[
                  sakuhin.orderOfTheSakuhin - 1
                ];
              sakuhin.sakuhinPageBikouList = sakuhinPageBikouList;
              sakuhin.lastPage = lastPage;
              sakuhin.theNumberOfDividing = theNumberOfDividing++;
              const pushSakuhin = new FormatSakuhin(
                sakuhin,
                formatSakuhinAddressArray,
                formatSakuhinArray_withFlg
              );
              formatSakuhinArray.push(pushSakuhin);
              const pushOri = new FormatOri(ori, formatSakuhinArray);
              formatOriArray.push(pushOri);
              this.lines.push({ formatOriArray: formatOriArray });
              formatOriArray = [];
              formatSakuhinAddressArray = [];
              formatSakuhinArray_withFlg = [];
              formatSakuhinArray = [];
            }
          }

          //作品追加前に、作品に対しページ備考の情報を追加
          const sakuhinPageBikouList =
            this.props_daiwariData.sakuhin_page_bikou_array[
              sakuhin.orderOfTheSakuhin - 1
            ];
          sakuhin.sakuhinPageBikouList = sakuhinPageBikouList;
          sakuhin.theNumberOfDividing = theNumberOfDividing++;
          //最終ページを作品プロパティに保持させる
          sakuhin.lastPage = lastPage;

          //作品が切り替わるのでアドレスのみ新しくした状態でformat後の作品配列にpush
          //※貢カウント対象外のアドレスを非表示にするため別途アドレス配列を作成
          const pushSakuhin = new FormatSakuhin(
            sakuhin,
            formatSakuhinAddressArray,
            formatSakuhinArray_withFlg
          );
          if (pushSakuhin.sakuhin_address_computed_array.length > 0) {
            formatSakuhinArray.push(pushSakuhin);
          }
          formatSakuhinAddressArray = [];
          formatSakuhinArray_withFlg = [];

          pre_orderOfTheSakuhin = sakuhin.orderOfTheSakuhin;
        });
        //折が切り替わるので折配列に折をpush
        const pushOri = new FormatOri(ori, formatSakuhinArray);
        if (pushOri.sakuhin_array.length > 0) {
          formatOriArray.push(pushOri);
        }
        formatSakuhinArray = [];
      });
      this.lines.push({ formatOriArray: formatOriArray });
      formatOriArray = [];

      this.add_pageBikou_highLight();
      //出力前後で台割の構造が異なるため、ページ範囲設定のハイライト用にアドレスを再度計算し直す
      let joinedOriArray = [];
      this.lines.forEach((line) => {
        joinedOriArray = [...joinedOriArray, ...line.formatOriArray];
      });
      setSakuhinIndexComputedArray(joinedOriArray);
    },
    addressArray(sakuhin) {
      //sakuhin_address_computed_array_withFlgとsakuhin_address_computed_arrayのうち有効な方を返す
      if (sakuhin.sakuhin_address_computed_array_withFlg.length) {
        return sakuhin.sakuhin_address_computed_array_withFlg;
      } else {
        return sakuhin.sakuhin_address_computed_array;
      }
    },

    //切り出しテーブル関連---------------------------------------------------
    format_detailOriArray() {
      //折省略テーブル用の配列を生成
      this.$nextTick(() => {
        //lines配列にある全ての折を取り出し1つの配列に格納する
        let joinedOriArray = [];
        this.lines.forEach((line) => {
          joinedOriArray = [...joinedOriArray, ...line.formatOriArray];
        });
        this.$refs.ref_ori_bikou.forEach((refBikou, refBikouIndex) => {
          if (refBikou.scrollHeight > refBikou.offsetHeight) {
            this.detailOriArray.push(joinedOriArray[refBikouIndex]);
          }
        });
        this.$emit("updateDetailOriArray", this.detailOriArray);
      });
    },
    format_detailSakuhinArray() {
      //作品省略テーブル用の配列を生成
      this.$nextTick(() => {
        //lines配列にある全作品を取り出し1つの配列に格納する
        let joinedSakuhinArray = [];
        let ref = this.$refs;
        this.lines.forEach((line) => {
          line.formatOriArray.forEach((ori) => {
            joinedSakuhinArray = [...joinedSakuhinArray, ...ori.sakuhin_array];
          });
        });
        this.pick_detailSakuhin(joinedSakuhinArray, ref.ref_sakuhin_mei);
        this.pick_detailSakuhin(joinedSakuhinArray, ref.ref_bikou_sakuhin);
        this.$emit("updateDetailSakuhinArray", this.detailSakuhinArray);
      });
    },
    pick_detailSakuhin(joinedSakuhinArray, refsArray) {
      //省略対象の作品(作品,担当者,ペンネーム,作品備考)を作品省略テーブルに切り分ける
      refsArray.forEach((ref, refIndex) => {
        if (ref.scrollHeight > ref.offsetHeight) {
          const hasDuplicatedSakuhin = this.detailSakuhinArray.some((sakuhin) =>
            is_matched_sakuhinId(sakuhin, joinedSakuhinArray[refIndex])
          );
          if (!hasDuplicatedSakuhin) {
            //出力用に整形前の台割作品を全て取得する(対象ページを参照するため)
            let sakuhinArray = [];
            this.props_daiwariData.ori_array.forEach((ori) => {
              sakuhinArray = [...sakuhinArray, ...ori.sakuhin_array];
            });
            const target_sameSakuhinArray = sakuhinArray.filter((sakuhin) =>
              is_matched_sakuhinId(sakuhin, joinedSakuhinArray[refIndex])
            );
            const lastSakuhin =
              target_sameSakuhinArray[target_sameSakuhinArray.length - 1];
            //作品のページ範囲(開始ページ,終了ページ)の算出
            const startPage = joinedSakuhinArray[refIndex]
              .sakuhin_address_computed_array_withFlg.length
              ? joinedSakuhinArray[refIndex]
                  .sakuhin_address_computed_array_withFlg[0]
              : "未設定";
            const endPage = lastSakuhin.sakuhin_address_computed_array_withFlg
              .length
              ? lastSakuhin.sakuhin_address_computed_array_withFlg[
                  lastSakuhin.sakuhin_address_computed_array_withFlg.length - 1
                ]
              : "未設定";
            this.detailSakuhinArray.push({
              ...joinedSakuhinArray[refIndex],
              startPage: startPage,
              endPage: endPage,
            });
          }
        }
      });
    },
    add_pageBikou_highLight() {
      //参照渡し回避
      let copy_lines = JSON.parse(JSON.stringify(this.lines));
      copy_lines.forEach((line) => {
        line.formatOriArray.forEach((ori) => {
          ori.sakuhin_array.forEach((sakuhin) => {
            const startPage =
              sakuhin.lastPage - this.addressArray(sakuhin).length;
            sakuhin.sakuhinPageBikouList = sakuhin.sakuhinPageBikouList
              ? sakuhin.sakuhinPageBikouList
              : [];
            sakuhin.sakuhinPageBikouList.bikou_array = sakuhin
              .sakuhinPageBikouList.bikou_array
              ? sakuhin.sakuhinPageBikouList.bikou_array
              : [];
            const slice_bikouArray =
              sakuhin.sakuhinPageBikouList.bikou_array.slice(
                startPage,
                sakuhin.lastPage
              );
            sakuhin.sakuhinPageBikouList.bikou_array = slice_bikouArray;
          });
        });
      });
      this.lines = copy_lines;
    },
    format_layout() {
      let prevInfo = {
        ori_id: "",
        lineIndex: -1,
        oriIndex: -1,
      };
      let count = 1;
      // 一塊の折を判定する
      this.lines.forEach((line, lineIndex) => {
        line.formatOriArray.forEach((ori, oriIndex) => {
          // 背景色を偶数と奇数で分けるためのプロパティ付与
          // 折の区切りに二重線をつけるためのプロパティ付与
          if (prevInfo.ori_id !== "" && prevInfo.ori_id !== ori.ori_id) {
            this.lines[prevInfo.lineIndex].formatOriArray[
              prevInfo.oriIndex
            ].isLastOri = true;
            count++;
          }
          ori.isOdd = count % 2 === 1 ? true : false;
          prevInfo = {
            ori_id: ori.ori_id,
            lineIndex: lineIndex,
            oriIndex: oriIndex,
          };
        });
      });
    },
    // カスタム時の作品の高さ設定
    settingSakuhinHeight(initialHeight) {
      if (this.isCustom) {
        let height = initialHeight;
        if (!this.customOutputList.tantou.isSelected) {
          height -= 20;
        }
        if (!this.customOutputList.sakuhinBiko.isSelected) {
          height -= 20;
        }
        return height + "px";
      } else {
        return "";
      }
    },
  },
  computed: {
    sheet() {
      return "sheet_" + this.printSize;
    },
    hankaku() {
      return (character) => {
        const character_hankaku = zenkakuToHankaku(character);
        return character_hankaku;
      };
    },
    sakuhinSinkouStatus() {
      //進行ステータスに合わせた色付け
      return (status) => {
        if (status == "Ⅰ.企画立案") {
          return "st_kikaku";
        } else if (status == "Ⅱ.原稿制作") {
          return "st_genkou";
        } else if (status == "Ⅲ.組版・製版") {
          return "st_kumihan";
        } else if (status == "Ⅳ.校了") {
          return "st_kouryo";
        } else if (status == "Ⅴ.印刷") {
          return "st_insatu";
        } else if (status == "Ⅵ.原価登録") {
          return "st_genka";
        }
      };
    },
    pageRangeBikouArray() {
      return this.page_range_bikou_array ? this.page_range_bikou_array : [];
    },
    //ページ備考ハイライト
    color() {
      return (sakuhin, index) => {
        const targetIndex = sakuhin.sakuhin_index_computed_array
          ? sakuhin.sakuhin_index_computed_array[index]
          : null;
        let color = "";
        if (this.pageRangeBikouArray.length === 0) {
          return;
        }
        this.pageRangeBikouArray.forEach((bikou) => {
          if (bikou.addressIndexArray.includes(targetIndex)) {
            color = `bg_${bikou.color}`;
          }
        });
        return color;
      };
    },
    //枝台割がcreated()にて作品表示名を設定出来ていない場合があるため、テンプレート上で生成する
    sakuhinDisplayName() {
      return (sakuhin) => {
        return convertSakuhinMeiFull(sakuhin);
      };
    },
    // カスタム時の折の高さ設定
    settingOriHeight() {
      if (this.isCustom) {
        let height = 55;
        if (!this.customOutputList.yousi.isSelected) {
          height -= 13;
        }
        if (!this.customOutputList.kakou.isSelected) {
          height -= 13;
        }
        return height + "px";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  :root {
    --page: 0;
    --maxpage: 0;
  }
  //A4サイズ
  #sheet_A4 {
    width: 1066px;
  }
  //A3サイズ
  #sheet_A3 {
    width: 1200px;
  }
  //B4サイズ
  #sheet_B4 {
    width: 1190px;
  }
  .mainArea {
    padding-top: 10px;
    width: 100%;
  }
  .ori_container {
    width: 100%;
    // display: inline-block;
    border-bottom: 1px solid rgb(0, 0, 0);
    padding-top: 2px;
    height: 55px;
    p {
      font-size: 10px;
      line-height: 11px;
    }
  }
  .odd_ori {
    background: #ddd;
  }
  .even_ori {
    background: #afaeae;
  }
  .sakuhin_container {
    width: 100%;
    height: 90px;
    &_address {
      height: 20px;
    }
    &_info {
      height: 70px;
      padding: 2px 0px 0px 0px;
    }
  }
  .line_container {
    break-inside: avoid;
    width: 100%;
    display: flex;
    border-left: 1px solid rgb(0, 0, 0);
    // &:nth-of-type(1) {
    .ori_container {
      border-top: 1px solid rgb(0, 0, 0);
    }
    // }
  }

  @for $i from 1 through 1000 {
    .address_8_#{$i} {
      margin: 1px;
      display: inline-block;
      vertical-align: top;
      width: 100% / $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin: 0;
      &:nth-of-type(#{$i}) {
        border-right: none;
      }
    }
  }
  @for $i from 1 through 1000 {
    .address_16_#{$i} {
      margin: 1px;
      display: inline-block;
      vertical-align: top;
      width: 100% / $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin: 0;
      &:nth-of-type(#{$i}) {
        border-right: none;
      }
    }
  }
  @for $i from 1 through 1000 {
    .address_32_#{$i} {
      display: inline-block;
      vertical-align: top;
      width: 100% / $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin: 0;
      &:nth-of-type(#{$i}) {
        border-right: none;
      }
    }
  }

  @for $i from 1 through 1000 {
    .line_8_#{$i} {
      display: inline-block;
      vertical-align: top;
      width: 12.5% * $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      margin-bottom: -1px;
      // &:nth-of-type(#{$i}) {
      //   border-right: none;
      // }
    }
  }

  @for $i from 1 through 1000 {
    .line_16_#{$i} {
      display: inline-block;
      vertical-align: top;
      width: 6.25% * $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      margin-bottom: -1px;
      // &:nth-of-type(#{$i}) {
      //   border-right: none;
      // }
    }
  }

  @for $i from 1 through 1000 {
    .line_32_#{$i} {
      display: inline-block;
      vertical-align: top;
      width: 3.125% * $i;
      border-right: 1px solid rgb(0, 0, 0);
      border-bottom: 1px solid rgb(0, 0, 0);
      margin-bottom: -1px;
      // &:nth-of-type(#{$i}) {
      //   border-right: none;
      // }
    }
  }

  @for $i from 1 through 1000 {
    .sakuhin_8_#{$i} {
      font-size: 10px;
      text-align: center;
      width: calc(100% / var(--page) * #{$i});
      height: 100%;
      display: inline-block;
      vertical-align: top;
      flex-shrink: 0;
      border-left: 1px solid rgb(0, 0, 0);
      &:nth-of-type(1) {
        border-left: none;
      }
    }
  }

  @for $i from 1 through 1000 {
    .sakuhin_16_#{$i} {
      font-size: 10px;
      text-align: center;
      width: calc(100% / var(--page) * #{$i});
      height: 100%;
      display: inline-block;
      vertical-align: top;
      flex-shrink: 0;
      border-left: 1px solid rgb(0, 0, 0);
      &:nth-of-type(1) {
        border-left: none;
      }
    }
  }

  @for $i from 1 through 1000 {
    .sakuhin_32_#{$i} {
      font-size: 10px;
      text-align: center;
      width: calc(100% / var(--page) * #{$i});
      height: 100%;
      display: inline-block;
      vertical-align: top;
      flex-shrink: 0;
      border-left: 1px solid rgb(0, 0, 0);
      &:nth-of-type(1) {
        border-left: none;
      }
    }
  }

  .last_ori {
    border-right: 3px double rgb(0, 0, 0);
  }

  .sakuhin_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 13px;
  }
  .tantou_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .author_mei {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 13px;
    &_null {
      display: block;
      height: 10px;
    }
  }
  .line_clamp_1 {
    //1行に収まりきらない場合「…」で省略
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .bikou {
    &_ori {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      width: auto;
    }
    &_sakuhin {
      display: -webkit-box;
      font-size: 8px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 12px;
    }
  }
  .active_bikou {
    border: 2px solid red !important;
  }

  .st_kikaku {
    background-color: #ffccff;
  }

  .st_genkou {
    background-color: #ffffaa;
  }

  .st_kumihan {
    background-color: #e6ffe9;
  }

  .st_kouryo {
    background-color: #ffdbc9;
  }

  .st_insatu {
    background-color: #ffd5ec;
  }

  .st_genka {
    background-color: #e6e6fa;
  }

  //ページ範囲備考の背景色
  .bg_pink {
    background-color: #ffd5ec;
    &--preview {
      border: 2px dashed pink;
    }
  }
  .bg_yellow {
    background-color: #ffffaa;
    &--preview {
      border: 2px dashed yellow;
    }
  }
  .bg_green {
    background-color: #e6ffe9;
    &--preview {
      border: 2px dashed green;
    }
  }
  .bg_orange {
    background-color: #ffdbc9;
    &--preview {
      border: 2px dashed orange;
    }
  }
  .bg_purple {
    background-color: #e6e6fa;
    &--preview {
      border: 2px dashed purple;
    }
  }

  // 出力設定用（印刷用CSSの抜き出し）-----------------------------
  title {
    display: none;
  }
  html,
  body {
    height: auto;
  }
  .DaiwariFormattingLeft_compact {
    line-height: 0 !important;
  }
  //A4サイズ
  .sheet_A4 {
    width: 1066px;
  }
  //A3サイズ
  .sheet_A3 {
    width: 1200px;
  }

  //A4は9行,A3・B4は10行ごとにlineを改行
  .page_next {
    page-break-after: always;
  }
  .page_next + div {
    margin-top: 60px;
    border-top: 1px solid rgb(0, 0, 0);
  }
  /*最後のページは改ページを入れない*/
  #DaiwariFormattingLeft > div:last-child {
    // border: 3px solid blue;
    page-break-after: auto;
  }

  // コンパクト出力
  .sakuhin_container_compact {
    height: 79px;
  }
  .sakuhin_container_info_compact {
    height: 60px;
  }
  .sakuhin_container_address_compact {
    height: 19px;
  }
  .ori_container_compact {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid rgb(0, 0, 0);
    padding-top: 2px;
    height: 26px;
  }

  // 台割のみ出力
  .sakuhin_container_onlydaiwari {
    height: 60px;
  }
  .sakuhin_container_info_onlydaiwari {
    height: 40px;
  }

  .print_display_none {
    display: none;
  }

  //印刷用CSS-------------------------------
  @media print {
    title {
      display: none;
    }
    html,
    body {
      height: auto;
    }
    .DaiwariFormattingLeft_compact {
      line-height: 0 !important;
    }
    //A4サイズ
    .sheet_A4 {
      width: 1066px;
    }
    //A3サイズ
    .sheet_A3 {
      width: 1200px;
    }

    //A4は9行,A3・B4は10行ごとにlineを改行
    .page_next {
      page-break-after: always;
    }
    .page_next + div {
      margin-top: 60px;
      border-top: 1px solid rgb(0, 0, 0);
    }
    /*最後のページは改ページを入れない*/
    #DaiwariFormattingLeft > div:last-child {
      // border: 3px solid blue;
      page-break-after: auto;
    }

    // コンパクト出力
    .sakuhin_container_compact {
      height: 79px;
    }
    .sakuhin_container_info_compact {
      height: 60px;
    }
    .sakuhin_container_address_compact {
      height: 19px;
    }
    .ori_container_compact {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid rgb(0, 0, 0);
      padding-top: 2px;
      height: 26px;
    }

    // 台割のみ出力
    .sakuhin_container_onlydaiwari {
      height: 60px;
    }
    .sakuhin_container_info_onlydaiwari {
      height: 40px;
    }

    .print_display_none {
      display: none;
    }

    // 印刷時に線が出力されないことがあるのでここにも記載
    @for $i from 1 through 1000 {
      .line_8_#{$i} {
        display: inline-block;
        vertical-align: top;
        width: 12.5% * $i;
        border-right: 1px solid rgb(0, 0, 0);
        border-bottom: initial;
        margin-bottom: -1px;
        // &:nth-of-type(#{$i}) {
        //   border-right: none;
        // }
      }
    }

    @for $i from 1 through 1000 {
      .line_16_#{$i} {
        display: inline-block;
        vertical-align: top;
        width: 6.25% * $i;
        border-right: 1px solid rgb(0, 0, 0);
        border-bottom: initial;
        margin-bottom: -1px;
        // &:nth-of-type(#{$i}) {
        //   border-right: none;
        // }
      }
    }

    @for $i from 1 through 1000 {
      .line_32_#{$i} {
        display: inline-block;
        vertical-align: top;
        width: 3.125% * $i;
        border-right: 1px solid rgb(0, 0, 0);
        border-bottom: initial;
        margin-bottom: -1px;
        // &:nth-of-type(#{$i}) {
        //   border-right: none;
        // }
      }
    }
    @for $i from 1 through 1000 {
      .sakuhin_8_#{$i} {
        border-bottom: 1px solid rgb(0, 0, 0);
      }
      .sakuhin_16_#{$i} {
        border-bottom: 1px solid rgb(0, 0, 0);
      }
      .sakuhin_32_#{$i} {
        border-bottom: 1px solid rgb(0, 0, 0);
      }
    }
    .last_ori {
      border-right: 3px double rgb(0, 0, 0);
    }
  }
}
</style>
