<template>
  <div id="overlay_daiwariFistory">
    <div class="overlay_header">
      <!-- 台割「保存」履歴 -->
      <p class="overlay_headerTitle" v-if="!kakuteiRireki_flg">
        台割保存履歴照会<span v-show="rirekiUrl === 'daiwari/daiwari_rireki'"
          >(ユーザー保存)</span
        ><span v-show="rirekiUrl === 'daiwari/daiwari_rireki_auto'"
          >(システム自動保存)</span
        >
        <span class="overlay_headerText">
          <span class="overlay_username" v-show="this.rirekiTorokuUserMei"
            >ユーザー名:{{ this.rirekiTorokuUserMei }}</span
          >
          <span v-show="this.rirekiTorokuDate"
            >/修正日時:{{ this.rirekiTorokuDate }}</span
          >
        </span>
      </p>
      <div class="btn_container" v-if="!kakuteiRireki_flg">
        <button
          @click="restoreDaiwari"
          v-show="!isLockDaiwari"
          class="btn_hukugen"
        >
          復元
        </button>
        <button @click="closeOverlay" class="btn_back">
          台割保存履歴一覧に戻る
        </button>
      </div>
      <!--台割 「確定」履歴 -->
      <p class="overlay_headerTitle" v-else>
        台割確定履歴照会
        <span class="overlay_headerText">
          <span class="overlay_username" v-show="this.rirekiTorokuUserMei"
            >ユーザー名:{{ this.rirekiTorokuUserMei }}</span
          >
          <span v-show="this.rirekiTorokuDate"
            >/修正日時:{{ this.rirekiTorokuDate }}</span
          >
        </span>
      </p>
      <div class="btn_container" v-if="kakuteiRireki_flg">
        <button @click="closeOverlay" class="btn_back">
          台割確定履歴一覧に戻る
        </button>
      </div>
    </div>
    <div class="overlay_content">
      <div v-for="(ori, index) in rireki_oriArray" :key="index" class="row">
        <!-- 折コンポーネントに置き換え -->
        <OriComponents
          :ori="ori"
          :isReferencePage="true"
          @toggleOriBikouFlg="toggleOriBikouFlg"
        />
        <!-- 作品コンポーネントに置き換え -->
        <div class="sakuhin_area">
          <template
            v-for="sakuhin in ori.sakuhin_array"
            :key="sakuhin.sakuhin_address"
          >
            <SakuhinComponents
              :sakuhin="sakuhin"
              :includesPages_doNotCount="includesPages_doNotCount"
              :oriMaximumPage="oriMaximumPage"
              :daiwariAddressArray="daiwariAddressArray"
              :page_range_bikou_array="page_range_bikou_array"
              :previewPageRangeData="previewPageRangeData"
              @toggleSakuhinBikouFlg="toggleSakuhinBikouFlg"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OriComponents from "../Ori.vue";
import SakuhinComponents from "../Sakuhin";
import axios from "axios";

export default {
  name: "DaiwariHistory",
  components: {
    OriComponents,
    SakuhinComponents,
  },
  props: {
    // daiwariRirekiId: Number
    daiwariId: Number,
    overlayId: Number,
    rirekiTorokuUserMei: String,
    rirekiTorokuDate: String,
    url: String,
    kakuteiRireki_flg: Boolean,
    rirekiUrl: String,
    isLockDaiwari: Boolean,
    previewPageRangeData: Object,
  },
  emits: [
    "hideOverlay",
    "toggleSakuhinBikouFlg",
    "toggleOriBikouFlg",
    "closeOverlay",
    "hideModal",
    "restoreDaiwari",
  ],
  data() {
    return {
      rireki_oriArray: [],
      daiwariRirekiId: this.overlayId,
      includesPages_doNotCount: false,
      oriMaximumPage: null,
      target_rireki: null,
      daiwariAddressArray: null,
      page_range_bikou_array: null,
    };
  },
  created() {
    //履歴取得URLの判定(保存履歴or確定履歴)
    const rireki_url = this.kakuteiRireki_flg
      ? `${this.url}/daiwari/get_kakutei_daiwari?daiwariId=${this.daiwariId}&kakuteiDaiwariId=${this.daiwariRirekiId}`
      : `${this.url}/${this.rirekiUrl}?rirekiId=${this.daiwariRirekiId}`;

    //履歴の取得
    axios
      .get(rireki_url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        //  保存履歴or確定履歴でresponse.dataの形が異なるので
        // それぞれのデータの形に合わせてtarget_rirekiにaiwariDataを割り当てる
        const target_rireki = this.kakuteiRireki_flg
          ? JSON.parse(response.data[0].daiwariData)
          : JSON.parse(response.data.daiwariData);
        this.includesPages_doNotCount = target_rireki.includesPages_doNotCount;
        this.oriMaximumPage = target_rireki.oriMaximumPage;
        this.daiwariAddressArray = target_rireki.daiwariAddressArray
          ? target_rireki.daiwariAddressArray
          : [];
        this.page_range_bikou_array = target_rireki.page_range_bikou_array
          ? target_rireki.page_range_bikou_array
          : [];
        this.rireki_oriArray = [...target_rireki.ori_array];
        this.target_rireki = target_rireki;
      })
      .catch((error) => {
        alert("履歴データが取得できませんでした\n" + error);
      });
  },
  methods: {
    closeOverlay() {
      this.$emit("hideOverlay");
    },
    toggleSakuhinBikouFlg(sakuhin, isOpenBikou) {
      this.$emit("toggleSakuhinBikouFlg", sakuhin, isOpenBikou);
    },
    toggleOriBikouFlg(ori, isOpenBikou) {
      this.$emit("toggleOriBikouFlg", ori, isOpenBikou);
    },

    restoreDaiwari() {
      const shouldRestore = window.confirm(
        `履歴ID「${this.daiwariId}」の台割を復元してもよろしいですか?`
      );
      if (shouldRestore == true) {
        this.$emit("closeOverlay");
        //台割一覧モーダルも閉じる
        this.closeOverlay();
        this.$emit("hideModal");
        this.$emit("restoreDaiwari", this.target_rireki);
      }
    },
  },
  filters: {
    truncateDispName(value) {
      const ommision = "...";
      let length = 5;
      // valueが数値の場合がある為、文字列に変換
      value = "" + value;
      if (value.length <= length) {
        return value;
      }
      return value.substring(0, length) + ommision;
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay_daiwariFistory {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
  }
  .overlay_content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px 80px 20px;
  }
  .overlay_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background: #f7f7f7;
    // border-radius: 5px 5px 0 0;
    &Title {
      font-weight: 700;
      font-size: 20px;
      color: rgb(37, 37, 37);
    }
    &Text {
      display: inline-block;
      padding: 0 15px;
      font-weight: normal;
      font-size: 16px;
      color: rgb(37, 37, 37);
    }
  }
  .overlay__close {
    font-size: 25px;
    //color: #fff;
    color: black;
  }

  .btn_back {
    width: 150px;
    padding: 10px 0;
    // background: gray;
  }
  .row {
    display: flex;
    margin-top: 20px;
    white-space: nowrap;
  }

  .rireki_ori_area {
    float: left;
    font-size: 10px;
    text-align: center;
    width: 100px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin-right: 50px;
    flex-shrink: 0;
  }

  .sakuhin_address_container {
    display: flex;
  }
  .sakuhin {
    position: relative;
    text-align: center;
    font-size: 10px;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  .sakuhin_address {
    width: 80px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  .sakuhin_mei {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sakuhin_mei_blanc {
    opacity: 0;
    display: block;
  }

  .sakuhin_address_border_right {
    border-right: solid 1px rgba(0, 0, 0, 0.2);
    &__blanc {
      border-right: solid 0px rgba(0, 0, 0, 0.2);
      background-color: #97185e;
    }
  }
  .btn_hukugen {
    border: 1px solid #ec7211;
    background: #fff;
    color: #ec7211;
    width: 130px;
    //padding: 10px 0;
    font-size: 16px;
  }
  .btn_container {
    display: flex;
    gap: 5px;
  }
  .overlay_username {
    display: inline-block;
    padding-left: 5px;
  }
}
</style>
