<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title"></p>
      </div>
      <div class="modal__review">
        <div class="modal__message">
          <p>項目名を設定してください</p>
        </div>
        <div class="select_container">
          <div class="select_container input_block">
            <input
              list="ori_kbn_select"
              @change="input_oriKbn"
              id="ori_kbn_in"
              name="ori_kbn_in"
              :value="editOri_meisyo"
            />
            <datalist id="ori_kbn_select">
              <option value="スリーブケース"></option>
              <option value="ヒンナップ"></option>
              <option value="シリアルコード"></option>
            </datalist>
          </div>
        </div>
        <div class="modal__btn__block">
          <button class="btn_gray" @click="hideModal(true)">決定</button>
          <button class="btn_gray" @click="hideModal(false)">キャンセル</button>
        </div>
        <button class="btn_red_small" @click="deleteOriInfo()">
          項目を削除する
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oriAddress: Number,
    ori_array: Array,
    editOriMeisyo: String,
  },
  emits: ["hideModal", "count_timeOut", "deleteOriInfo"],
  data() {
    return {
      freeInputKbn: "",
      editOri_meisyo: "",
    };
  },
  mounted() {
    this.editOri_meisyo = this.editOriMeisyo;
    this.freeInputKbn = this.editOriMeisyo;
  },
  methods: {
    hideModal(shouldEditOri) {
      this.$emit("hideModal", shouldEditOri, this.freeInputKbn);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
    //区分の選択
    input_oriKbn(event) {
      this.freeInputKbn = event.target.value;
      if (this.freeInputKbn === "スリーブケース") {
        this.freeInputKbn = "ｽﾘｰﾌﾞｹｰｽ";
      } else if (this.freeInputKbn === "シリアルコード") {
        this.freeInputKbn = "ｼﾘｱﾙｺｰﾄﾞ";
      } else if (this.freeInputKbn === "ヒンナップ") {
        this.freeInputKbn = "ﾋﾝﾅｯﾌﾟ";
      }
    },
    selectKbn(newKbn) {
      this.initialKbn = newKbn;
    },
    //折情報の削除
    deleteOriInfo() {
      this.$emit("deleteOriInfo");
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 50%;
    height: 50%;
    &__message {
      font-size: 18px;
    }
    &__header {
      height: 5%;
      background: #fff;
    }
    &__close {
      color: #000;
    }
  }

  .select_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn_gray {
    width: 140px;
    height: 40px;
    padding: 10px;
  }
  .btn_red_small {
    width: 120px;
    height: 30px;
    background-color: rgb(224, 58, 58);
    color: #fff;
    margin-top: 5px;
  }
  .input_block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
