<template>
  <div
    class="ori_area"
    :class="{
      edit_selected: ori.is_selected_ori,
      ori_kbn_duplicated: isDuplicatedOriKbn,
    }"
  >
    <!-- 備考アイコン -->
    <span
      v-if="'ori_bikou' in ori && ori.ori_bikou !== ''"
      @mouseover="mouseOverAction(ori)"
      @mouseleave="mouseLeaveAction(ori)"
      class="bikou_icon"
    >
      <font-awesome-icon :icon="['fas', 'comment-dots']" />
    </span>
    <!-- 備考テキスト -->
    <div v-if="ori.ori_bikou_active_flg" class="bikou_text">
      <p>{{ ori.ori_bikou }}</p>
    </div>
    <span class="ori_kbn">{{ truncateDispName(ori.ori_kbn) }}</span>
    <br />
    <span class="sikisuu">{{ truncateDispName(ori.sikisuu) }}</span>
    <br />
    <span class="yousi">{{ truncateDispName(ori.yousi) }}</span>
    <br />
    <span class="ori_address" v-show="!isReferencePage"
      >[{{ ori.ori_address_computed }}]</span
    >
    <span class="ori_address" v-show="isReferencePage"
      >[{{ ori.ori_address }}]</span
    >
  </div>
</template>

<script>
export default {
  name: "Ori",
  props: {
    ori: Object,
    isReferencePage: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  emits: ["toggleOriBikouFlg", "toggleOriBikouFlg"],
  data() {
    return {
      isDuplicatedOriKbn: this.ori.is_duplicated_ori_kbn,
    };
  },
  methods: {
    mouseOverAction(ori) {
      this.$emit("toggleOriBikouFlg", ori, true);
    },
    mouseLeaveAction(ori) {
      this.$emit("toggleOriBikouFlg", ori, false);
    },
    reflectDuplicatedOriKbn() {
      this.isDuplicatedOriKbn = this.ori.is_duplicated_ori_kbn;
    },
    truncateDispName(value) {
      const ommision = "...";
      let length = 5;
      // valueが数値の場合がある為、文字列に変換
      value = "" + value;
      if (value.length <= length) {
        return value;
      }
      return value.substring(0, length) + ommision;
    },
  },
};
</script>

<style scoped>
.ori_area {
  position: relative;
  float: left;
  font-size: 10px;
  text-align: center;
  width: 70px;
  height: 90px;
  border: solid 1px rgb(0, 0, 0, 0.2);
  /* margin-right: 50px; */
  margin-right: 20px;
  flex-shrink: 0;
}

.ori_kbn_duplicated {
  background-color: #ffbeda;
}

.bikou_icon {
  position: absolute;
  display: inline-block;
  bottom: -12px;
  right: -12px;
  font-size: 15px;
  width: 30px;
  height: 30px;
  z-index: 900;
}

.bikou_text {
  word-break: break-all;
  white-space: normal;
  position: absolute;
  display: block;
  overflow: auto;
  bottom: 0;
  left: 65px;
  width: 150px;
  height: 80px;
  padding: 2px;
  text-align: left;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #2a3f54;
  z-index: 999;
}

.bikou_text::-webkit-scrollbar {
  width: 5px;
}

.bikou_text::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* box-shadow: inset 0 0 2px rgb(0 0 0 / 30%); */
}

.bikou_text::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
}
</style>
