import axios from "axios";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import store from "..";
import type { RootState } from "..";
import { ToEditMeisai } from "@/types/juchu";

type JuchuState = {
  toEditMeisaiData: ToEditMeisai;
};
// ストアを永続化しているため、初期化のために以下の形で定義
type GetDefaultState = () => JuchuState;
const getDefaultState: GetDefaultState = () => {
  return {
    toEditMeisaiData: {} as ToEditMeisai,
  };
};
export const state: JuchuState = getDefaultState();

const getters: GetterTree<JuchuState, RootState> = {
  getToEditMeisaiData: (state) => state.toEditMeisaiData,
};

const mutations: MutationTree<JuchuState> = {
  // stateを初期化するmutationを定義
  clearAuthData(state) {
    Object.assign(state, getDefaultState());
  },
  setToEditMeisaiData(state, payload) {
    state.toEditMeisaiData = payload;
  },
};

const actions: ActionTree<JuchuState, RootState> = {};

export const JuchuModule: Module<JuchuState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
