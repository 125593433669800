<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title"></p>
      </div>
      <div class="modal__review" v-if="!isLockDaiwari">
        <span>
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="icon__save__filed"
          />
        </span>
        <div class="modal__message">
          <p>
            台割の変更内容が未保存です。
            <br />変更を保存しますか？
          </p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_gray" @click="hideModal('false')">
            保存せず他ページへ移動
          </button>
          <button class="btn_gray" @click="hideModal('true')">保存する</button>
          <button class="btn_gray" @click="hideModal('cancel')">
            キャンセル
          </button>
        </div>
      </div>
      <div class="modal__review" v-if="isLockDaiwari">
        <span>
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="icon__save__filed"
          />
        </span>
        <div class="modal__message">
          <p>
            台割が変更されました。
            <br />未保存のまま他ページへ移動してもよろしいですか？
          </p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_gray" @click="hideModal('false')">
            他ページへ移動
          </button>
          <button class="btn_gray" @click="hideModal('cancel')">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oddCheck_array: {
      type: Array,
    },
    isLockDaiwari: {
      type: Boolean,
    },
  },
  emits: ["hideModal", "count_timeOut"],
  methods: {
    hideModal(shouldSaveSaiwari) {
      this.$emit("hideModal", "modal_saveConfimation", shouldSaveSaiwari);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 50%;
    height: 50%;
    &__message {
      font-size: 18px;
    }
    &__header {
      height: 5%;
      background-color: #fff;
    }
    &__close {
      color: #000;
    }
  }

  .icon__save__filed {
    color: rgb(212, 0, 0);
    width: 100px;
    height: 100px;
  }

  .btn_gray {
    width: 170px;
    height: 40px;
    padding: 10px;
  }
}
</style>
