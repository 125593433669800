<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">作品を操作する</p>
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          @click="hideModal"
          class="modal__close"
        />
      </div>
      <!-- <div class="select_area">
        <span
          v-for="(select, index) in select_list"
          :key="index"
          @click="toggleBadge(select)"
        >
          <span
            v-if="select.display_flg"
            class="select_badge"
            :class="{ isActiveSelect: select.select_flg }"
            >{{ select.name }}</span
          >
        </span>
      </div> -->
      <div class="modal__review">
        <div class="modal__content">
          <div class="modal__content__text">
            <p>
              クリップボードに保存中の作品
              <span class="sakuhin_title"
                >「{{ copiedSakuhin.sakuhin_mei }}」</span
              >
            </p>
            <p>
              選択中の作品
              <span class="sakuhin_title"
                >「{{ selectedSakuhin.sakuhin_mei }}」</span
              >
            </p>
          </div>
          <div class="modal__btn__block">
            <button class="btn_orange" @click="this.copySakuhin">
              選択中の作品をコピー
            </button>
          </div>
          <div v-if="!sameSakuhinCopyFlg" class="radio_group">
            <div v-if="isActiveIrekae">
              <span class="radio_title">操作種別</span>
              <input
                type="radio"
                name="syubetu"
                id="syubetu_paste"
                @click="toggleSyubetu(true)"
                checked
              />
              <label class="label" for="syubetu_paste">貼り付け</label>
              <input
                type="radio"
                name="syubetu"
                id="syubetu_irekae"
                @click="toggleSyubetu(false)"
              />
              <label class="label" for="syubetu_irekae">入れ替え</label>
            </div>
            <div v-if="isActivePaste">
              <span class="radio_title">カット仕様</span>
              <input
                type="radio"
                name="cut"
                id="radio_copy"
                @click="toggleHukuseiFlg(true)"
                checked
              />
              <label class="label" for="radio_copy">コピー</label>
              <input
                type="radio"
                name="cut"
                id="radio_kiritori"
                @click="toggleHukuseiFlg(false)"
              />
              <label class="label" for="radio_kiritori">切り取り</label>
            </div>
            <div v-if="isActivePaste">
              <span class="radio_title">ぺースト仕様</span>
              <input
                type="radio"
                name="paste"
                id="radio_front"
                @click="togglePastePosition('front')"
              />
              <label class="label" for="radio_front">前方貼り付け</label>
              <input
                type="radio"
                name="paste"
                id="radio_back"
                checked
                @click="togglePastePosition('back')"
              />
              <label class="label" for="radio_back">後方貼り付け</label>
            </div>
          </div>
          <div v-else class="radio_group">
            <div v-if="isActivePaste">
              <span class="radio_title">カット仕様</span>
              <input
                type="radio"
                name="cut"
                id="radio_copy"
                @click="toggleHukuseiFlg(true)"
                checked
              />
              <label class="label" for="radio_copy">コピー</label>
            </div>
            <div v-if="isActivePaste">
              <span class="radio_title">ぺースト仕様</span>
              <input
                type="radio"
                name="paste"
                id="radio_front"
                @click="togglePastePosition('front')"
              />
              <label class="label" for="radio_front">前方貼り付け</label>
              <input
                type="radio"
                name="paste"
                id="radio_back"
                checked
                @click="togglePastePosition('back')"
              />
              <label class="label" for="radio_back">後方貼り付け</label>
            </div>
          </div>
          <div class="modal__btn__block">
            <button
              v-if="isActivePaste"
              class="btn_orange"
              @click="selectPaste"
            >
              貼り付けを実行
            </button>
            <button v-else class="btn_orange" @click="replaceSakuhin">
              入れ替えを実行
            </button>
            <button class="btn_gray" @click="hideModal">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Notyf } from "notyf";
// const notyf = new Notyf();
import { is_matched_sakuhinId } from "@/daiwariJS/util.js";
export default {
  props: {
    selectedSakuhin: Object,
    copiedSakuhin: Object,
    copySakuhin: Function,
    replaceSakuhin: Function,
    pasteSakuhin: Function,
  },
  emits: ["hideModal"],
  data() {
    return {
      select_list: [
        { name: "貼り付け", select_flg: true, display_flg: true },
        { name: "入れ替え", select_flg: false, display_flg: true },
        { name: "クリップボード更新", select_flg: false, display_flg: true },
      ],
      paste_list: [
        { name: "前方に貼り付ける", select_flg: false, display_flg: true },
        { name: "後方に貼り付ける", select_flg: true, display_flg: true },
      ],
      isActivePaste: true, //操作種別:貼り付け
      isActiveIrekae: true, //入れ替え可能か否か
      hukusei_flg: true,
      isActiveFront: false,
      sameSakuhinCopyFlg: false,
    };
  },
  created() {
    //★追加箇所:同じ作品を上書きコピーしたい場合----------------------------------------------
    if (is_matched_sakuhinId(this.selectedSakuhin, this.copiedSakuhin)) {
      this.sameSakuhinCopyFlg = true;
    }
    //------------------------------------------------------------------------------------------
    //同じページ数である作品同士のみ入れ替え可能にする
    if (
      !this.sameSakuhinCopyFlg &&
      this.selectedSakuhin.total_page !== this.copiedSakuhin.total_page
    ) {
      this.isActiveIrekae = false;
    }
  },
  methods: {
    selectPaste() {
      this.pasteSakuhin(this.hukusei_flg, this.isActiveFront);
    },
    toggleHukuseiFlg(shouldHukusei) {
      this.hukusei_flg = shouldHukusei;
    },
    toggleSyubetu(flg) {
      this.isActivePaste = flg;
    },
    togglePastePosition(position) {
      this.paste_list.forEach((btn) => {
        btn.select_flg = false;
      });
      if (position === "front") {
        this.isActiveFront = true;
      } else {
        this.isActiveFront = false;
      }
    },
    hideModal() {
      this.$emit("hideModal", "modal_paste");
    },
    stopEvent(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  .modal {
    width: 600px;
    height: 390px;
    &__message {
      font-size: 16px;
    }
  }

  .modal__content {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text {
      text-align: center;
    }
  }

  .btn_gray {
    width: 150px;
    height: 40px;
    padding: 10px;
  }

  .btn_orange {
    width: 150px;
    height: 40px;
  }

  .radio {
    &_group {
      padding: 10px 0;
    }
    &_title {
      font-weight: bold;
      display: inline-block;
      width: 100px;
    }
  }

  .sakuhin_title {
    font-weight: bold;
  }
  .label {
    font-weight: normal;
  }

  // .select_area {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  // .select_badge {
  //   display: inline-block;
  //   border: 1px solid #2a3f54;
  //   background: #fff;
  //   color: #2a3f54;
  //   min-width: 115px;
  //   text-align: center;
  //   border-radius: 50px;
  //   height: 40px;
  //   line-height: 40px;
  //   font-size: 16px;
  //   cursor: pointer;
  //   margin: 0 5px;
  //   padding: 0px 5px;
  // }

  // .isActiveSelect {
  //   display: inline-block;
  //   border: 1px solid #2a3f54;
  //   background: #2a3f54;
  //   color: #fff;
  //   min-width: 115px;
  //   text-align: center;
  //   border-radius: 50px;
  //   height: 40px;
  //   line-height: 40px;
  //   font-size: 16px;
  //   cursor: pointer;
  //   margin: 0 5px;
  // }
}
</style>
