
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    props_selectFlg: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectFlg: false,
    };
  },
  emits: ["change"],
  created() {
    this.selectFlg = this.props_selectFlg;
  },
  methods: {
    toggleSelectBox() {
      //ここ(孫)でのtrue/falseを親に伝える
      this.selectFlg = !this.selectFlg;
      this.$emit("change", this.selectFlg);
    },
  },
});
