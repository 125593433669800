<template>
  <div id="overlay">
    <div class="modal" @click="stopEvent">
      <div class="modal__header">
        <p class="modal__title">ロック解除通知</p>
      </div>
      <div class="modal__review">
        <div class="modal__message">
          <p>『{{ title }}』は編集できるようになりました。</p>
          <p>[編集]を選択すると、開いた台割を編集できます。</p>
        </div>
        <div class="modal__btn__block">
          <button class="btn_orange" @click="hideModal(false)">編集</button>
          <button class="btn_orange" @click="hideModal(true)">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  emits: ["hideModal", "count_timeOut"],
  data() {
    return {};
  },
  methods: {
    hideModal(isLock) {
      this.$emit("hideModal", "modal_daiwariUnlock", isLock);
    },
    stopEvent(event) {
      this.$emit("count_timeOut");
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
#daiwari_edit {
  #overlay {
    z-index: 10000;
  }
  .modal {
    width: 600px;
    height: 460px;
    &__message {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  .btn_orange {
    width: 150px;
    height: 40px;
  }
}
</style>
