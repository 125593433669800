<template>
  <select
    @change="updateValue"
    :value="selectedValue"
    class="selectNormal"
  >
    <option v-for="(option, index) in options" :value="option.value" :key="index">
      {{ option.name }}
    </option>
  </select>
</template>

<script >
export default {
  name: "SelectNormal",
  props: {
    value: { type: [String, Boolean, Object], require: false },
    options: { type: Array, require: false },
    selectInfo: { type: [String, Boolean, Object], require: false },
  },
  data(){
    return {
      selectedValue:''
    }
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
    mountedEmit() {
      //初期
      const initialValue = this.options[0]?this.options[0].value:""
      this.$emit("input", initialValue);
      //propsがあった場合
      if(this.selectInfo){
        const selectedOptionIndex = this.options.findIndex(option=>option.value === this.selectInfo);
        const selectedOption = this.options.find(option=>option.value === this.selectInfo);
        if(selectedOption){//TODO:取次データ連携後、ここのif分のみを削除
          this.selectedValue = selectedOption?selectedOption.value:"";
          this.$emit("input", this.options[selectedOptionIndex].value);
        }
      }
    },
  },
  mounted() {
    this.mountedEmit();
  },
};
</script>

<style scoped lang="scss">
.selectNormal{
  min-width: 100px;
}
</style>